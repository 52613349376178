import DeleteIcon from '@mui/icons-material/Delete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Zoom from '@mui/material/Zoom';
import { FC, useState } from 'react';

import { TEstimatePositionId } from '@/api/estimatePositions';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { UICircleCheckbox } from '@/components/ui/UICircleCheckbox';
import { UIHeaderFilter } from '@/components/ui/UIHeaderFilter';

import { AlertIcon } from '@/shared/SVG/AlertIcon';

import {
    OrdinalCellContainer,
    OrdinalCellDeleteButton,
    OrdinalCellTooltip,
    OrdinalCellValue,
    PositionNameButton,
    PositionNameCellContainer,
} from './TableCell.styles';
import {
    TCellRendererProps,
    TCheckboxCellRendererProps,
    THeaderFilterProps,
    TOrdinalCellRendererProps,
} from './TableCell.types';
import { hasChanges } from './TableCell.utils';

export const TextCellRenderer: FC<TCellRendererProps> = ({ value }) => {
    return (
        <OverflowableTypographyWithTooltip
            maxRows={2}
            TypographyProps={{
                sx: {
                    background: 'transparent !important',
                },
            }}
        >
            {value}
        </OverflowableTypographyWithTooltip>
    );
};

export const PositionNameCellRenderer: FC<TCellRendererProps> = ({ value, data, context }) => {
    return (
        <PositionNameCellContainer>
            <OverflowableTypographyWithTooltip
                maxRows={2}
                TypographyProps={{
                    sx: {
                        background: 'transparent !important',
                    },
                }}
            >
                {value}
            </OverflowableTypographyWithTooltip>

            {hasChanges(data?.changes) && (
                <PositionNameButton onClick={context.onChangeAck(data!.id as TEstimatePositionId)}>
                    <AlertIcon />
                </PositionNameButton>
            )}
        </PositionNameCellContainer>
    );
};

export const HeaderFilter: FC<THeaderFilterProps> = ({
    isApplied,
    column,
    displayName,
    textAlign = 'left',
    onClick,
}) => {
    return (
        <UIHeaderFilter
            width={column.getActualWidth()}
            name={displayName}
            isApplied={isApplied}
            onClick={onClick}
            textProps={{
                sx: {
                    textAlign: `${textAlign} !important`,
                },
            }}
            iconColor='#9aa2b0'
            appliedIconColor='#8edd92'
        />
    );
};

export const CheckboxCellRenderer: FC<TCheckboxCellRendererProps> = ({ isConsolidated, checked, color, onChange }) => {
    if (isConsolidated) return null;

    return (
        <UICircleCheckbox
            checked={checked}
            onChange={onChange}
            checkedIconProps={{
                sx: {
                    color,
                },
            }}
        />
    );
};

export const OrdinalCellRenderer: FC<TOrdinalCellRendererProps> = ({ value, onDelete, data }) => {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleDelete = () => {
        setOpen(false);
        onDelete();
    };

    if (!data?.changes.isDeleted) return <OrdinalCellContainer>{value}</OrdinalCellContainer>;

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <OrdinalCellContainer>
                <OrdinalCellTooltip
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    TransitionComponent={Zoom}
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -12],
                                },
                            },
                        ],
                    }}
                    title={
                        <OrdinalCellDeleteButton onClick={handleDelete}>
                            <DeleteIcon />
                        </OrdinalCellDeleteButton>
                    }
                    placement='right'
                >
                    <OrdinalCellValue onClick={handleTooltipOpen}>{value}</OrdinalCellValue>
                </OrdinalCellTooltip>
            </OrdinalCellContainer>
        </ClickAwayListener>
    );
};
