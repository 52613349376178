import { TTitleByFilterKey } from './FilterDialog.types';

export const titleByFilterKey: TTitleByFilterKey = {
    lsrCode: 'Шифр ЛСР',
    lsrNumber: 'Номер ЛСР',
    chapter: 'Раздел',
    header: 'Заголовок',
    code: 'Код расценки',
    consolidatedName: 'Наименование',
    rawUnit: 'Единица измерения',
};
