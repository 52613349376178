import { Dispatch, SetStateAction } from 'react';

import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';

import { toggleSendSnz } from '@/store/slices/drawersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { iconsVariants } from './RemainingItemsMenu.service';
import MenuItemEl from './components/MenuItemEl';

export default function RemainingItemsMenuMsg({
    setAnchorElRemaining,
}: {
    setAnchorElRemaining: Dispatch<SetStateAction<HTMLElement | null>>;
}) {
    const dispatch = useAppDispatch();
    const { profile } = useTypedSelector(profileSelector);

    return (
        <>
            {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SEND_SNZ_MANUALLY') && (
                <MenuItemEl
                    onClick={() => {
                        setAnchorElRemaining(null);
                        dispatch(toggleSendSnz());
                    }}
                    iconsVar={iconsVariants.autoRenew}
                    title='Ручная отправка СНЗ'
                    description='Отправить письмо о внесении факта.'
                />
            )}
        </>
    );
}
