import NiceModal, { useModal } from '@ebay/nice-modal-react';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import { animated } from '@react-spring/web';
import { FC, useState } from 'react';

import { useBoop } from '@/hooks/useBoop';

import { Direction, Step, dataByStep } from './Guide.config';
import {
    ArrowBackIcon,
    ArrowButton,
    ArrowForwardIcon,
    Buttons,
    CloseButton,
    CloseIconButton,
    Container,
    Content,
    Dialog,
    DoneButton,
    Dot,
    DotsContainer,
    Title,
} from './Guide.styles';
import { ICreateVORGuideProps, IDotsProps } from './Guide.types';
import { getStepByDirection } from './Guide.utils';

const AnimatedCloseIcon = animated(CloseIcon);
const AnimatedArrowForwardIcon = animated(ArrowForwardIcon);
const AnimatedArrowBackIcon = animated(ArrowBackIcon);

const Dots: FC<IDotsProps> = ({ step }) => {
    return (
        <DotsContainer>
            {Object.values(Step).map((value) => (
                <Dot
                    key={value}
                    $isActive={value === step}
                />
            ))}
        </DotsContainer>
    );
};

export const Guide: FC<ICreateVORGuideProps> = ({ onClose }) => {
    const [step, setStep] = useState<Step>(Step.One);

    const data = dataByStep[step];
    const [closeButtonStyle, closeButtonTrigger] = useBoop({ rotation: 20, scale: 1.1, timing: 200 });
    const [forwardButtonStyle, forwardButtonTrigger] = useBoop({ x: 2, timing: 200 });
    const [backButtonStyle, backButtonTrigger] = useBoop({ x: -2, timing: 200 });

    const handleUpdateStep = (direction: Direction) => () => {
        setStep(getStepByDirection(step, direction));
    };

    const BackButton = (
        <ArrowButton
            onClick={handleUpdateStep(Direction.Back)}
            startIcon={<AnimatedArrowBackIcon style={backButtonStyle} />}
            onMouseEnter={backButtonTrigger}
        >
            Назад
        </ArrowButton>
    );

    const ForwardButton = (
        <ArrowButton
            onClick={handleUpdateStep(Direction.Forward)}
            endIcon={<AnimatedArrowForwardIcon style={forwardButtonStyle} />}
            onMouseEnter={forwardButtonTrigger}
        >
            Далее
        </ArrowButton>
    );

    return (
        <Container>
            <CloseIconButton
                onClick={onClose}
                onMouseEnter={closeButtonTrigger}
            >
                <AnimatedCloseIcon style={closeButtonStyle} />
            </CloseIconButton>
            <Content>
                <Stack>{data.image}</Stack>

                <Stack gap={1.5}>
                    <Title>{data.title}</Title>
                    {data.text}
                </Stack>
            </Content>
            <Buttons>
                {
                    {
                        [Step.One]: <CloseButton onClick={onClose}>Закрыть</CloseButton>,
                        [Step.Two]: BackButton,
                        [Step.Three]: BackButton,
                    }[step]
                }

                <Dots step={step} />

                {
                    {
                        [Step.One]: ForwardButton,
                        [Step.Two]: ForwardButton,
                        [Step.Three]: <DoneButton onClick={onClose}>Готово</DoneButton>,
                    }[step]
                }
            </Buttons>
        </Container>
    );
};

export const GuideDialog = NiceModal.create(() => {
    const { visible, hide } = useModal();

    return (
        <Dialog
            open={visible}
            onClose={hide}
        >
            <Guide onClose={hide} />
        </Dialog>
    );
});
