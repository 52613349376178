import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';

import { EMPTY_STRING_PLUG } from '../../global/variables';
import { StatItemTitle, StatItemWrapper } from './StatItem.styles';
import { IStatItemProps } from './StatItem.types';

export function StatItem({ variant, title, value = EMPTY_STRING_PLUG, customValueComponent }: IStatItemProps) {
    return (
        <StatItemWrapper variant={variant}>
            <StatItemTitle size={variant == 'row' ? 'medium' : 'small'}>{title}:</StatItemTitle>
            {(value || value == null) && !customValueComponent && (
                <OverflowableTypographyWithTooltip
                    maxRows={1}
                    TypographyProps={{
                        fontSize: '14px !important',
                    }}
                >
                    {value || EMPTY_STRING_PLUG}
                </OverflowableTypographyWithTooltip>
            )}
            {customValueComponent && customValueComponent}
        </StatItemWrapper>
    );
}
