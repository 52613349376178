import { queryOptions, useMutation } from '@tanstack/react-query';

import { queryKeys } from '@/shared/constants/queryKeys';

import { TCustomHookMutationOptions } from '@/types/query.types';

import { structureApi } from './structure.api';
import {
    TStructureAddAndGroupFolderRequest,
    TStructureAddAndGroupFolderResponse,
    TStructureAddAndGroupPreviewFolderRequest,
    TStructureAddAndGroupPreviewFolderResponse,
    TStructureAddAutoFoldersRequest,
    TStructureAddAutoFoldersResponse,
    TStructureAddFolderRequest,
    TStructureAddFolderResponse,
    TStructureDeleteAllFoldersRequest,
    TStructureDeleteAllFoldersResponse,
    TStructureDeleteFolderRequest,
    TStructureDeleteFolderResponse,
    TStructureInsertFolderPreviewRequest,
    TStructureInsertFolderPreviewResponse,
    TStructureInsertFolderRequest,
    TStructureInsertFolderResponse,
    TStructureListRequest,
    TStructureMoveConsolidatedPriceRequest,
    TStructureMoveConsolidatedPriceResponse,
    TStructureMoveFolderRequest,
    TStructureMoveFolderResponse,
    TStructureTreeRequest,
    TStructureUpdateFolderRequest,
    TStructureUpdateFolderResponse,
} from './structure.types';

export const getStructureListQueryOptions = (props: TStructureListRequest) =>
    queryOptions({
        queryKey: [queryKeys.structure.list, props.projectId],
        queryFn: () => structureApi.fetchList(props),
    });

export const getStructureTreeQueryOptions = (props: TStructureTreeRequest) =>
    queryOptions({
        queryKey: [queryKeys.structure.tree, props.projectId],
        queryFn: () => structureApi.fetchTree(props),
    });

export const getStructureAddAndGroupPreviewFolderQueryOptions = (props: TStructureAddAndGroupPreviewFolderRequest) =>
    queryOptions({
        queryKey: [queryKeys.structure.addAndGroupPreviewFolder, props.projectId, props.body],
        queryFn: () => structureApi.addAndGroupPreviewFolder(props),
    });

export const getStructureInsertFolderPreviewQueryOptions = (props: TStructureInsertFolderPreviewRequest) =>
    queryOptions({
        queryKey: [queryKeys.structure.insertPreviewFolder, props.projectId, props.body],
        queryFn: () => structureApi.insertFolderPreview(props),
    });

export const useAddFolderStructureMutation = (
    options?: TCustomHookMutationOptions<TStructureAddFolderResponse, TStructureAddFolderRequest>
) =>
    useMutation({
        mutationFn: (payload) => structureApi.addFolder(payload),
        ...options,
    });

export const useAddAndGroupFolderStructureMutation = (
    options?: TCustomHookMutationOptions<TStructureAddAndGroupFolderResponse, TStructureAddAndGroupFolderRequest>
) =>
    useMutation({
        mutationFn: (payload) => structureApi.addAndGroupFolder(payload),
        ...options,
    });

export const useAddAndGroupPreviewFolderStructureMutation = (
    options?: TCustomHookMutationOptions<
        TStructureAddAndGroupPreviewFolderResponse,
        TStructureAddAndGroupPreviewFolderRequest
    >
) =>
    useMutation({
        mutationFn: (payload) => structureApi.addAndGroupPreviewFolder(payload),
        ...options,
    });

export const useInsertFolderStructureMutation = (
    options?: TCustomHookMutationOptions<TStructureInsertFolderResponse, TStructureInsertFolderRequest>
) =>
    useMutation({
        mutationFn: (payload) => structureApi.insertFolder(payload),
        ...options,
    });

export const useInsertFolderPreviewStructureMutation = (
    options?: TCustomHookMutationOptions<TStructureInsertFolderPreviewResponse, TStructureInsertFolderPreviewRequest>
) =>
    useMutation({
        mutationFn: (payload) => structureApi.insertFolderPreview(payload),
        ...options,
    });

export const useUpdateFolderStructureMutation = (
    options?: TCustomHookMutationOptions<TStructureUpdateFolderResponse, TStructureUpdateFolderRequest>
) =>
    useMutation({
        mutationFn: (payload) => structureApi.updateFolder(payload),
        ...options,
    });

export const useDeleteFolderStructureMutation = (
    options?: TCustomHookMutationOptions<TStructureDeleteFolderResponse, TStructureDeleteFolderRequest>
) =>
    useMutation({
        mutationFn: (payload) => structureApi.deleteFolder(payload),
        ...options,
    });

export const useMoveConsolidatedPriceStructureMutation = (
    options?: TCustomHookMutationOptions<
        TStructureMoveConsolidatedPriceResponse,
        TStructureMoveConsolidatedPriceRequest
    >
) =>
    useMutation({
        mutationFn: (payload) => structureApi.moveConsolidatedPrice(payload),
        ...options,
    });

export const useMoveFolderStructureMutation = (
    options?: TCustomHookMutationOptions<TStructureMoveFolderResponse, TStructureMoveFolderRequest>
) =>
    useMutation({
        mutationFn: (payload) => structureApi.moveFolder(payload),
        ...options,
    });

export const useAddAutoFoldersStructureMutation = (
    options?: TCustomHookMutationOptions<TStructureAddAutoFoldersResponse, TStructureAddAutoFoldersRequest>
) =>
    useMutation({
        mutationFn: (payload) => structureApi.addAutoFolders(payload),
        ...options,
    });

export const useDeleteAllFoldersStructureMutation = (
    options?: TCustomHookMutationOptions<TStructureDeleteAllFoldersResponse, TStructureDeleteAllFoldersRequest>
) =>
    useMutation({
        mutationFn: (payload) => structureApi.deleteAllFolders(payload),
        ...options,
    });
