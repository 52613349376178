import { Fragment } from 'react';

import { PublicUserProfile } from '@/api/users/users.types';

import UserManagement from '../UserManagement/UserManagement';

function AddUser() {
    return (
        <Fragment>
            <UserManagement profile={{} as PublicUserProfile} />
        </Fragment>
    );
}

export default AddUser;
