import { Stack, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

export const Container = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    gap: '12px',
})`
    height: 100%;
    width: 100%;
`;

export const Left = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    gap: '4px',
})``;

export const Title = styled(Typography).attrs({
    variant: 'h3',
})`
    color: #2b3648;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    height: fit-content;
`;

export const UIFolderContainerCss = css`
    width: 20px;
    height: 20px;
`;

export const Text = styled(Typography).attrs({
    variant: 'body1',
})`
    color: #5c6e8c;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
`;

export const Dots = styled.div`
    display: flex;
`;

export const FileIconContainer = styled.div`
    color: #7890b2;
`;

export const Dot = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #2b36481f;
    }
`;
