import { Typography } from '@mui/material';

import { theme } from '../../../../styles/theme';
import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles';
import { Indicator, StyledFilledBar } from './GantCell.styles';
import { IGantBarProps } from './GantCell.types';

export function GantBar(props: IGantBarProps) {
    const {
        isChosen,
        variant = 'outer',
        size = 'medium',
        hidden,
        placement,
        cellWidth,
        cellHeight,
        bgcolor = theme.palette.primary.light,
        ...rest
    } = props;
    const doesStartIndicatorVisible = variant === 'outer' && (placement === 'start' || placement === 'center');
    const doesEndIndicatorVisible = variant === 'outer' && (placement === 'end' || placement === 'center');

    return (
        <FlexRowWrapper
            justifyContent={'center'}
            gap={0}
        >
            {doesStartIndicatorVisible && (
                <Indicator
                    placement={placement}
                    side={'start'}
                    hidden={props.hidden || rest.startIndicatorProps?.hidden}
                    onClick={(e) => rest.startIndicatorProps?.onClick && rest.startIndicatorProps?.onClick(e)}
                    {...rest.startIndicatorProps?.indicatorProps}
                />
            )}
            {!hidden && (
                <StyledFilledBar
                    variant={variant}
                    size={size}
                    bgcolor={props.bgcolor || bgcolor}
                    {...props}
                >
                    <Typography>{rest.innerText}</Typography>
                </StyledFilledBar>
            )}
            {doesEndIndicatorVisible && (
                <Indicator
                    placement={placement}
                    side={'finish'}
                    hidden={props.hidden || rest.finishIndicatorProps?.hidden}
                    onClick={(e) => rest.finishIndicatorProps?.onClick && rest.finishIndicatorProps?.onClick(e)}
                    {...rest.finishIndicatorProps?.indicatorProps}
                />
            )}
        </FlexRowWrapper>
    );
}
