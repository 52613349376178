import { isAxiosError } from 'axios';
import { match } from 'ts-pattern';

const DEFAULT_ERROR_MESSAGE = 'Произошла ошибка';

export const getImportEstimatePositionsErrorMessage = (error: unknown) => {
    if (!isAxiosError(error)) return DEFAULT_ERROR_MESSAGE;

    // @ts-ignore
    return match(error)
        .with(
            {
                response: {
                    status: 400,
                    data: 'Initial import was already performed',
                },
            },
            () => 'Первоначальный импорт уже был выполнен'
        )
        .with(
            {
                response: {
                    status: 400,
                },
            },
            () => '400 ошибка'
        )
        .with(
            {
                response: {
                    status: 403,
                },
            },
            () => 'Запрещено'
        )
        .with(
            {
                response: {
                    status: 404,
                },
            },
            () => 'Не найдено'
        )
        .with(
            {
                response: {
                    status: 409,
                },
            },
            () => 'Возник конфликт при импорте'
        )
        .with(
            {
                response: {
                    status: 429,
                },
            },
            () => 'Импорт уже инициирован тем же пользователем'
        )
        .otherwise(() => DEFAULT_ERROR_MESSAGE);
};
