import { Close } from '@mui/icons-material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';

import { SearchTextField } from './ConsolidatedPriceFilterDialog.styles';
import { TSearchFieldProps } from './ConsolidatedPriceFilterDialog.types';

export const SearchField: React.FC<TSearchFieldProps> = (props) => {
    return (
        <SearchTextField
            size='small'
            InputProps={{
                startAdornment: (
                    <SearchOutlinedIcon
                        sx={{
                            color: '#7890B2',
                        }}
                    />
                ),
                endAdornment: (
                    <>
                        {(props.value as string).length ? (
                            <FlexRowWrapper
                                alignItems={'center'}
                                onClick={props.onReset}
                            >
                                <Close
                                    sx={{
                                        cursor: 'pointer',
                                        color: '#7890B2',
                                    }}
                                />
                            </FlexRowWrapper>
                        ) : null}
                    </>
                ),
            }}
            {...props}
        />
    );
};
