import { AgGridReact } from 'ag-grid-react';
import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { columnsDefs } from './StructureManagementTable.columnDefs';
import { defaultColDef } from './StructureManagementTable.config';
import { TContext, TData, TStructureManagementTableProps } from './StructureManagementTable.types';
import { Table } from './components/Table';
import { FullWidthCellRenderer } from './components/TableCells';
import {
    useCollapse,
    useConsolidatedSelect,
    useDelete,
    useDrag,
    useEditMenu,
    useGetRowId,
    useIsFullWidthRow,
    useModify,
    useRefreshCellsByRowData,
    useRowClassRules,
    useRowData,
} from './model';

export const StructureManagementTable: React.FC<TStructureManagementTableProps> = () => {
    const { projectId } = useParams();

    const gridRef = useRef<AgGridReact<TData>>(null);

    const useCollapseResult = useCollapse(gridRef);

    const { rowData, isLoading } = useRowData(Number(projectId), useCollapseResult);

    const { consolidatedSelectSet, lastConsolidatedSelectRowId, onConsolidatedSelect, resetConsolidatedSelect } =
        useConsolidatedSelect();

    const { dropZone, overNodeId, onRowDragEnter, onRowDragMove, onRowDragEnd, onRowDragLeave, rowDragText } = useDrag(
        Number(projectId!),
        consolidatedSelectSet
    );

    const { openedEditMenuRowId, onEditMenuOpen, onEditMenuClose } = useEditMenu(gridRef);
    const { onRowCreate, onRowEdit, onRowEditCancel, onRowSave } = useModify(Number(projectId), gridRef);

    const { onAllFoldersDeleteConfirm, onFolderDeleteConfirm } = useDelete(Number(projectId));

    const rowClassRules = useRowClassRules();
    const getRowId = useGetRowId();
    const isFullWidthRow = useIsFullWidthRow();

    const context: TContext = useMemo(() => {
        return {
            consolidatedSelectSet,
            lastConsolidatedSelectRowId,
            overNodeId,
            openedEditMenuRowId,
            collapsedRowIds: useCollapseResult.collapsedRowIds,
            onEditMenuOpen: onEditMenuOpen,
            onEditMenuClose: onEditMenuClose,
            onRowCreate: onRowCreate,
            onRowEdit: onRowEdit,
            onRowCollapse: useCollapseResult.onRowCollapse,
            onFolderDeleteConfirm: onFolderDeleteConfirm,
            onAllFoldersDeleteConfirm: onAllFoldersDeleteConfirm,
            onConsolidatedSelect: onConsolidatedSelect,
            resetConsolidatedSelect: resetConsolidatedSelect,
        };
    }, [
        consolidatedSelectSet,
        lastConsolidatedSelectRowId,
        overNodeId,
        openedEditMenuRowId,
        useCollapseResult.collapsedRowIds,
    ]);

    useRefreshCellsByRowData(rowData, gridRef);

    return (
        <>
            {dropZone}

            <Table
                suppressMovableColumns
                isLoading={isLoading}
                getRowId={getRowId}
                gridRef={gridRef}
                rowData={rowData}
                defaultColDef={defaultColDef}
                columnDefs={columnsDefs}
                context={context}
                headerHeight={40}
                rowHeight={40}
                rowClassRules={rowClassRules}
                animateRows
                rowSelection='multiple'
                suppressRowClickSelection
                isFullWidthRow={isFullWidthRow}
                fullWidthCellRenderer={FullWidthCellRenderer}
                fullWidthCellRendererParams={{
                    projectId: Number(projectId),
                    onSubmit: onRowSave,
                    onCancel: onRowEditCancel,
                }}
                overlayNoRowsTemplate='Нет данных'
                suppressColumnVirtualisation
                onRowDragEnter={onRowDragEnter}
                onRowDragMove={onRowDragMove}
                onRowDragEnd={onRowDragEnd}
                onRowDragLeave={onRowDragLeave}
                rowDragText={rowDragText}
            />
        </>
    );
};
