import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PublicUserProfile } from '../../../../api/users/users.types';
import { theme } from '../../../../styles/theme';
import { FlexColumnWrapper, FlexRowWrapper, OverflowableTypography } from '../components.styles';
import { IProfileInfoProps } from './ProfileInfo.types';

export function ProfileInfo({ profile, AvatarProps, withUserCompanyName }: IProfileInfoProps) {
    const { t } = useTranslation('user');

    function stringAvatar(profile: PublicUserProfile | null | undefined) {
        if (profile) {
            if (profile.firstName && profile.lastName) {
                return profile.firstName[0] + profile.lastName[0];
            }
        } else {
            return null;
        }
    }

    return (
        <FlexRowWrapper width='auto'>
            <Avatar
                color='primary'
                {...AvatarProps}
                sx={{
                    textTransform: 'uppercase',
                    bgcolor: theme.palette.primary.main,
                    ...AvatarProps?.sx,
                }}
            >
                {stringAvatar(profile)}
            </Avatar>
            <FlexColumnWrapper
                sx={{ gap: '8px !important' }}
                width='auto'
            >
                <OverflowableTypography
                    variant='body1'
                    fontWeight={500}
                    color={theme.palette.text.primary}
                >
                    {profile.firstName + ' ' + profile.lastName}
                </OverflowableTypography>
                {withUserCompanyName && (
                    <FlexColumnWrapper
                        sx={{ gap: '0 !important' }}
                        width='auto'
                    >
                        <OverflowableTypography variant='body2'>
                            {profile?.company?.userCompanyName}
                        </OverflowableTypography>
                        <OverflowableTypography variant='body2'>{t(`roles.${profile?.role}`)}</OverflowableTypography>
                    </FlexColumnWrapper>
                )}
            </FlexColumnWrapper>
        </FlexRowWrapper>
    );
}
