import { api } from '@/api/api';

import { setProfile } from '@/store/slices/profileSlice';

import { UserProfile } from '../users/users.types';

export const profileApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProfile: build.query<UserProfile, void>({
            query: () => '/profile/get',
            providesTags: ['Profile'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const result = await queryFulfilled;
                dispatch(setProfile(result.data));
            },
        }),
    }),
    overrideExisting: false,
});
