import { Box, IconButton, Menu, PopperProps, Switch, TextField, Typography } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api';
import { useSetTemplatesStatusMutation } from '@/api/templates/templates.api';
import { useGetProjectIntegrationStatusQuery } from '@/api/works/works.api';

import { inputStylesAddWorkAg } from '@/components/AddLevelKsg/AddLevel.service';
import { useUpdateCurrentTemplate } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.model';

import { GanttLegend } from '@/pages/KSGTable/components/GanttLegend';

import CriticalPathSVG from '@/shared/SVG/CriticalPathSVG';
import { TbLetterS } from '@/shared/SVG/Svg';
import { HtmlTooltip } from '@/shared/components/HtmlTooltip';
import { EPageName } from '@/shared/constants/pages';

import { agGridKsgSelector, onEditAgKsgRowHeight } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { cpgViewSelector } from '@/store/slices/cpgViewSlice';
import { handleChangeCritPathFilter } from '@/store/slices/filtersSlice';
import { projectsSelector } from '@/store/slices/projectsSlice';
import { ISetupViewGrid, getSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { useUpdateGanttModeByCurrentTemplate, useUpdateRowHeight } from './KsgLayout.model';
import s from './TableLayout.module.scss';
import { Option, WrapperButtons, WrapperCell } from './TableLegend.style';
import { CritPathPopper } from './components/CritPathPopper';
import GanttLinks from './components/GanttLinks';
import { OfferControlPanel } from './components/IntegrationWithOffer/ControlPanel';
import { OfferSwitch } from './components/IntegrationWithOffer/Switch';
import MainLinks from './components/MainLinks';
import TopRightButtons from './components/TopRightButtons';
import ViewButtons from './components/ViewButtons/ViewButtons';
import { WorkFilter } from './components/WorkFilter';
import { StyledIconBtn } from './components/styles';

export default function KsgLayout() {
    const { middleVisible } = useTypedSelector(cpgViewSelector);
    const { pageName } = useTypedSelector(projectsSelector);
    const { projectId } = useParams();
    const { rowHeight: globalRowHeight, minRowHeight } = useTypedSelector(agGridKsgSelector);
    const [URLSearchParams, SetURLSearchParams] = useSearchParams();
    const [anchorCritEl, setAnchorCritEl] = useState<PopperProps['anchorEl']>(null);
    const [rowHeightInputValue, setRowHeightInputValue] = useState<number>(globalRowHeight);
    const isGantt = URLSearchParams.has('g') || URLSearchParams.has('gf');

    const isKSGPage = pageName === EPageName.Ksg;
    const isKC6APage = pageName === EPageName.KsgKc6a;

    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
    });
    const isKSGCreated = Boolean(headersCPG?.data?.isCreatedKSG);

    const { data: integrationStatus } = useGetProjectIntegrationStatusQuery(
        { id: Number(projectId) },
        { skip: !isKSGCreated }
    );

    const isVisibleOfferSwitch = integrationStatus?.isImportFromOffer && !isGantt;

    const { update: updateCurrentTemplate } = useUpdateCurrentTemplate();

    /* Блок смены данных о состоянии таблицы в сторе */

    /**
     * Функция отправки запроса к базе на обновление вида таблицы
     * а так же, состояние загрузки для выключения свитчеров, защита от спама
     */
    const [updateSettings, { isLoading }] = useSetTemplatesStatusMutation();

    /**
     * данные с стора о состоянии таблицы
     */
    const { verificationPlan, basicPlan, msgForecast, costDoneMode } = useTypedSelector(getSettings);

    /**
     * прослушивание события клика по свитчу
     */
    const changeStatus = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            const name = event.target.name as keyof ISetupViewGrid;
            updateSettings({
                projectId: Number(projectId),
                body: {
                    [name]: checked,
                },
            });
            //eslint-disable-next-line
        },
        [projectId]
    );

    /**
     * установка якоря, если он есть появится выпадающее меню
     */
    const [anchor, setAnchor] = useState<null | HTMLSpanElement>(null);
    const dispatch = useAppDispatch();

    function switchCriticalPathMode() {
        SetURLSearchParams((params) => {
            if (params.get('criticalPath')) {
                params.delete('criticalPath');
                setAnchorCritEl(() => null);
                dispatch(
                    handleChangeCritPathFilter({
                        enable: false,
                        showParent: true,
                    })
                );
            } else {
                params.set('criticalPath', 'on');
            }
            return params;
        });
    }

    function switchIsChooseCritPathMenuOpen(e: React.SyntheticEvent) {
        URLSearchParams.get('criticalPath') &&
            (anchorCritEl ? setAnchorCritEl(() => null) : setAnchorCritEl(() => e.target as HTMLElement));
    }

    const handleRowHeightInputBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
        const rowHeight = Math.max(Number(e.target.value), minRowHeight);

        dispatch(onEditAgKsgRowHeight({ rowHeight: rowHeight }));
        setRowHeightInputValue(rowHeight);
        updateCurrentTemplate({ rowHeight: rowHeight });
    };

    useEffect(() => {
        setRowHeightInputValue(globalRowHeight);
    }, [globalRowHeight]);

    useUpdateRowHeight();
    useUpdateGanttModeByCurrentTemplate();

    return (
        <main className={s.main}>
            <Menu
                anchorEl={anchor}
                open={!!anchor}
                onClose={() => setAnchor(null)}
            >
                <WrapperButtons>
                    <WrapperCell>
                        <Option
                            control={
                                <Switch
                                    disabled={isLoading}
                                    checked={basicPlan}
                                    onChange={changeStatus}
                                    name={'basicPlan'}
                                />
                            }
                            label={'Базовый план КСГ'}
                            labelPlacement={'start'}
                        />
                    </WrapperCell>
                    <WrapperCell>
                        <Option
                            control={
                                <Switch
                                    disabled={isLoading}
                                    checked={verificationPlan}
                                    onChange={changeStatus}
                                    name={'verificationPlan'}
                                />
                            }
                            label={'Верификация факта'}
                            labelPlacement={'start'}
                        />
                    </WrapperCell>
                    <WrapperCell>
                        <Option
                            control={
                                <Switch
                                    disabled={isLoading}
                                    checked={msgForecast}
                                    onChange={changeStatus}
                                    name={'msgForecast'}
                                />
                            }
                            label={'Прогноз МСГ'}
                            labelPlacement={'start'}
                        />
                    </WrapperCell>
                    <WrapperCell>
                        <Option
                            control={
                                <Switch
                                    disabled={isLoading}
                                    checked={costDoneMode}
                                    onChange={changeStatus}
                                    name={'costDoneMode'}
                                />
                            }
                            label={'Режим отображения свободной стоимости'}
                            labelPlacement={'start'}
                        />
                    </WrapperCell>
                    <WrapperCell>
                        <Box
                            display='flex'
                            alignItems='center'
                        >
                            <Typography>Высота строк</Typography>
                            <TextField
                                InputLabelProps={{ shrink: false }}
                                placeholder={'введите высоту'}
                                type='number'
                                InputProps={{ inputProps: { min: 20, max: 400 } }}
                                value={rowHeightInputValue}
                                onChange={(e) => setRowHeightInputValue(Number(e.target.value))}
                                // TODO:
                                // 1. Убрать onBlur
                                // 2. Сделать onChange через debounce
                                onBlur={handleRowHeightInputBlur}
                                sx={{ ...inputStylesAddWorkAg, width: '3rem', margin: '0 10px' }}
                            />
                        </Box>
                    </WrapperCell>
                </WrapperButtons>
            </Menu>
            {middleVisible ? (
                <div className={s.top}>
                    {integrationStatus?.isImportFromOffer ? (
                        <OfferControlPanel
                            isKC6APage={isKC6APage}
                            integrationStatus={integrationStatus}
                        />
                    ) : (
                        <div />
                    )}
                    <TopRightButtons />
                </div>
            ) : (
                <div></div>
            )}

            {middleVisible ? (
                <div className={s.middle}>
                    <MainLinks />

                    {isGantt ? <GanttLegend /> : <div />}

                    <div className={s.middle_right}>
                        {/*{pageName === pagesNames.ksg && URLSearchParams.has('r') && <PaginationEl />}*/}
                        {/* {pageName === pagesNames.ksg && (
                            <HtmlTooltip title="Новое представление диаграммы Ганта">
                                <IconButton
                                    sx={{
                                        backgroundColor: URLSearchParams.has('DHTMLX') ? '#d81da0' : '#f6f7fb',
                                        color: URLSearchParams.has('DHTMLX') ? '#f6f7fb' : '#d81da0',
                                        borderRadius: '6px',
                                        ':hover': {
                                            backgroundColor: URLSearchParams.has('DHTMLX') ? '#d81da0' : null,
                                        },
                                    }}
                                    color="warning"
                                    component="span"
                                    onClick={() => {
                                        SetURLSearchParams(prevState => {
                                            if (prevState.has('DHTMLX')) {
                                                prevState.delete('DHTMLX')
                                            }
                                            else {
                                                prevState.set('DHTMLX', 'on')
                                            }
                                            return prevState
                                        })
                                    }}
                                >
                                    <NearbyError/>
                                </IconButton>
                            </HtmlTooltip>
                        )} */}
                        {(isKSGPage || pageName === EPageName.ag_grid) && !isGantt && <ViewButtons />}

                        {isVisibleOfferSwitch && <OfferSwitch />}

                        {isKSGPage && isGantt && (
                            <Fragment>
                                <HtmlTooltip
                                    title={
                                        !anchorCritEl && !URLSearchParams.get('criticalPath')
                                            ? 'Включить режим отображения критических путей для самостоятельных работ'
                                            : ''
                                    }
                                    onMouseEnter={(e) => {
                                        anchorCritEl === null && switchIsChooseCritPathMenuOpen(e);
                                    }}
                                    onMouseLeave={(e) => {
                                        if ((e.relatedTarget as HTMLElement)?.id !== 'scene') {
                                            anchorCritEl && switchIsChooseCritPathMenuOpen(e);
                                        }
                                    }}
                                >
                                    <Box>
                                        <StyledIconBtn
                                            onClick={switchCriticalPathMode}
                                            isActive={!!URLSearchParams.get('criticalPath')}
                                            sx={{
                                                zIndex: 999,
                                            }}
                                        >
                                            <CriticalPathSVG isActive={!!URLSearchParams.get('criticalPath')} />
                                        </StyledIconBtn>
                                    </Box>
                                </HtmlTooltip>
                            </Fragment>
                        )}
                        {/* {pageName === pagesNames.ksg && isGantt && (
                            <HtmlTooltip title="Режим показа взаимосвязей ганта">
                                <IconButton
                                    sx={{
                                        backgroundColor: URLSearchParams.has('r') ? '#0044b4' : '#f6f7fb',
                                        color: URLSearchParams.has('r') ? '#f6f7fb' : '#0044b4',
                                        borderRadius: '6px',
                                        ':hover': {
                                            backgroundColor: URLSearchParams.has('r') ? '#1d4ed8' : null,
                                        },
                                    }}
                                    color="primary"
                                    component="span"
                                    onClick={() => {
                                        if (URLSearchParams.has('r')) {
                                            URLSearchParams.delete('r')
                                            SetURLSearchParams(URLSearchParams)
                                        } else {
                                            URLSearchParams.append('r', 'on')
                                            SetURLSearchParams(URLSearchParams)
                                        }
                                    }}
                                >
                                    <ShuffleIcon />
                                </IconButton>
                            </HtmlTooltip>
                        )} */}

                        {isKSGPage && <GanttLinks />}

                        {isKSGPage && <WorkFilter />}

                        {!isKC6APage && (
                            <HtmlTooltip title='Настройки отображения строк'>
                                <IconButton
                                    sx={{
                                        backgroundColor: URLSearchParams.has('b') ? '#0044b4' : '#f6f7fb',
                                        color: URLSearchParams.has('b') ? '#f6f7fb' : '#0044b4',
                                        borderRadius: '6px',
                                        ':hover': {
                                            backgroundColor: URLSearchParams.has('b') ? '#1d4ed8' : null,
                                        },
                                    }}
                                    color='primary'
                                    component='span'
                                    onClick={(event: React.MouseEvent<HTMLSpanElement> | undefined) => {
                                        event && setAnchor(event.currentTarget);
                                    }}
                                >
                                    <TbLetterS />
                                </IconButton>
                            </HtmlTooltip>
                        )}

                        <CritPathPopper
                            open={!!anchorCritEl}
                            anchorEl={anchorCritEl}
                            onMouseLeave={(e) => {
                                const relatedTarget = e.relatedTarget as HTMLElement;
                                if (relatedTarget.tagName === 'BUTTON') {
                                    return;
                                }
                                if (relatedTarget.tagName !== 'SVG') {
                                    anchorCritEl && switchIsChooseCritPathMenuOpen(e);
                                }
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div></div>
            )}

            <Outlet />
        </main>
    );
}
