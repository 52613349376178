import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
    useGetProjectsDesignQuery,
    useGetProjectsOfferQuery,
    useGetProjectsProcurementQuery,
} from '@/api/bingingExternalModules/bindingExternalModules.api';
import { useImportEstimatePositionsMutation } from '@/api/importingData';
import {
    useGetStatusImportMutation,
    useSaveImportSettingsMutation,
    useStartImportSynchronizeMutation,
    useUnbindProjectMutation,
} from '@/api/importingData/importingData.api';
import { pickSyncModuleType } from '@/api/importingData/importingData.types';
import { useLazyGetHeadersCPGQuery } from '@/api/ksg/ksg.api';

import { uploadTypeTemp } from '@/components/UploadDrawer/UploadDrawer.service';

import { EPageName } from '@/shared/constants/pages';

import { toggleTrigger } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { toggleSynchronize, toggleUploadKsg } from '@/store/slices/drawersSlice';
import { useAppDispatch } from '@/store/store';

import { errorSyncType, errorsType } from '../../../SyncDone/SyncDone';
import { ISyncProjectsProps } from './SyncProjects.types';
import { getImportEstimatePositionsErrorMessage } from './SyncProjects.utils';

const errorsText = {
    'cross_module.bind_not_found': `Привязанный проект не найден в модуле`,
    'cross_module.module_unavailable': `Модуль временно недоступен, пожалуйста повторите загрузку позже`,
    'cross_module.module_not_binded': 'Нет привязанного модуля',
};

const useProjectsData = (inputValue: string, moduleName: pickSyncModuleType) => {
    const projectsDesign = useGetProjectsDesignQuery(
        {},
        {
            selectFromResult: (result) => {
                return {
                    isLoading: result.isLoading,
                    data:
                        result.data?.data
                            .filter((f) => {
                                return f.shortName.toLowerCase().includes(inputValue.toLowerCase());
                            })
                            ?.map((v) => ({
                                id: v.id,
                                name: v.shortName,
                            })) || [],
                };
            },
            skip: moduleName !== 'PragmaDesign',
        }
    );

    const projectsProcurement = useGetProjectsProcurementQuery(
        { limit: 9999, offset: 0 },
        {
            selectFromResult: (result) => {
                return {
                    isLoading: result.isLoading,
                    data:
                        result.data?.data.filter((f) => {
                            return f.name.toLowerCase().includes(inputValue.toLowerCase());
                        }) || [],
                };
            },
            skip: moduleName !== 'PragmaProcurement',
        }
    );

    const projectsOffer = useGetProjectsOfferQuery(
        {},
        {
            selectFromResult: (result) => {
                return {
                    isLoading: result.isLoading,
                    data:
                        result.data?.data
                            .filter((f) => {
                                return f.title.toLowerCase().includes(inputValue.toLowerCase());
                            })
                            ?.map((v) => ({
                                id: v.id,
                                name: v.title,
                            })) || [],
                };
            },
            skip: moduleName !== 'PragmaOffer',
        }
    );
    const isListDataLoading = projectsDesign.isLoading || projectsProcurement.isLoading || projectsOffer.isLoading;

    const projects =
        {
            PragmaDesign: projectsDesign,
            PragmaProcurement: projectsProcurement,
            PragmaOffer: projectsOffer,
            Primavera: null,
            DEFAULT: null,
        }[moduleName] ?? null;

    return {
        projects,
        isListDataLoading,
    };
};

export const useSyncProjects = ({ moduleName, sendingReqBool, height, uploadType }: ISyncProjectsProps) => {
    const [project, setProject] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [trigger, setTrigger] = useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation('mutations');
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { projectId } = useParams();
    const { projects, isListDataLoading } = useProjectsData(inputValue, moduleName);

    const [saveImportReq] = useSaveImportSettingsMutation();
    const [startImportSyncReq, { isLoading: isFormSubmitLoading }] = useStartImportSynchronizeMutation();
    const [getHeadersCPG] = useLazyGetHeadersCPGQuery();
    const [statusImportReq, statusImportRes] = useGetStatusImportMutation();
    const [unbindReq] = useUnbindProjectMutation();

    const { mutate: mutateImportEstimatePositions, isPending: isEstimatePositionsFormSubmitLoading } =
        useImportEstimatePositionsMutation({
            onSuccess: (data) => {
                if (!data.success) {
                    enqueueSnackbar('Возникла ошибка в модуле  «Ценообразование»', {
                        variant: 'error',
                    });
                    return;
                }

                dispatch(toggleUploadKsg());
                enqueueSnackbar('Импорт прошел успешно', {
                    variant: 'success',
                });
                navigate(`/${EPageName.Mgp}/${projectId}`);
            },
            onError: (error) => {
                enqueueSnackbar(getImportEstimatePositionsErrorMessage(error), {
                    variant: 'error',
                });
            },
        });

    const moduleProjectId = statusImportRes.data?.moduleProjectId;

    const handleUnbind = () => {
        unbindReq({
            id: Number(projectId),
            body: {
                module: moduleName,
            },
        })
            .unwrap()
            .then(() => {
                handleProjectSelect('');
                setTrigger((prevValue) => !prevValue);
            })
            .catch((e: unknown) => {
                enqueueSnackbar('Ошибка, доп информация в консоли', {
                    variant: 'error',
                });
                console.error(e);
            });
    };

    useEffect(() => {
        sendingReqBool() &&
            statusImportReq({
                id: Number(projectId),
                body: {
                    module: moduleName,
                },
            })
                .unwrap()
                .then((res) => {
                    handleProjectSelect(
                        JSON.stringify({
                            projectId: res.moduleProjectId,
                            projectName: res.moduleProjectName,
                        })
                    );
                })
                .catch((e: unknown) => {
                    console.error(e);
                });
    }, [trigger]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = () => {
        const proj = JSON.parse(project);
        saveImportReq({
            projectId: Number(projectId),
            body: {
                module: moduleName,
                moduleProjectId: proj.projectId,
                moduleProjectName: proj.projectName,
            },
        })
            .unwrap()
            .then(() => {
                if (height) {
                    if (uploadType && (uploadType as uploadTypeTemp) === 'Интеграция с "Ценообразование" New') {
                        mutateImportEstimatePositions({
                            projectId: Number(projectId),
                        });

                        return;
                    }

                    startImportSyncReq({
                        projectId: Number(projectId),
                        body: {
                            module: 'PragmaOffer',
                        },
                    })
                        .unwrap()
                        .then((result) => {
                            enqueueSnackbar(result.description, {
                                variant: 'success',
                            });
                            getHeadersCPG({
                                id: Number(projectId),
                            });
                            dispatch(toggleTrigger());
                            height ? dispatch(toggleUploadKsg()) : dispatch(toggleSynchronize());
                        })
                        .catch((e) => {
                            const err: errorSyncType = e;

                            enqueueSnackbar(errorsText[err?.data as errorsType], {
                                variant: 'error',
                            });
                        });
                }

                if (!height) {
                    enqueueSnackbar(t('import_saved'), {
                        variant: 'success',
                    });
                }
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                    variant: 'error',
                });
                console.error(e);
            });
    };

    const handleProjectSelect = (value: string) => {
        setProject(value);
    };

    const handleClose = () => {
        if (height) {
            dispatch(toggleUploadKsg());
            return;
        }
        dispatch(toggleSynchronize());
    };

    return {
        project,
        inputValue,
        height,
        isListDataLoading,
        moduleName,
        isFormSubmitLoading: isFormSubmitLoading || isEstimatePositionsFormSubmitLoading,
        projects,
        moduleProjectId,
        onSubmit: handleSubmit,
        onInputChange: handleInputChange,
        onProjectSelect: handleProjectSelect,
        onClose: handleClose,
        onUnbind: handleUnbind,
    };
};
