import { Stack } from '@mui/material';
import { FC } from 'react';

import { StyledTooltip } from '@/shared/components/StyledTooltip';

import { Container, Input, Label, NumberInput, Select, SelectItem } from './UIInput.styles';
import { IBaseInputProps, IInputSelectProps, INumberInputProps } from './UIInput.types';
import { getLabelText } from './UIInput.utils';

export const UIInputSelect: FC<IInputSelectProps> = ({ label, options, selectProps = {} }) => {
    return (
        <Container>
            <Label>
                <Stack gap={0.5}>
                    {getLabelText(label)}
                    <Select
                        displayEmpty
                        {...selectProps}
                    >
                        {options.map((option, index) => (
                            <SelectItem
                                key={index}
                                value={option.value}
                            >
                                {option.label}
                            </SelectItem>
                        ))}
                    </Select>
                </Stack>
            </Label>
        </Container>
    );
};

export const UIBaseInput: FC<IBaseInputProps> = ({ label, inputProps = {} }) => {
    return (
        <Container>
            <Label>
                <Stack gap={0.5}>
                    {getLabelText(label)}

                    <StyledTooltip title={inputProps.disabled ? (inputProps.value as string) : ''}>
                        <Stack>
                            <Input
                                InputLabelProps={{ shrink: false }}
                                {...inputProps}
                            />
                        </Stack>
                    </StyledTooltip>
                </Stack>
            </Label>
        </Container>
    );
};

export const UINumberInput: FC<INumberInputProps> = ({ value, label, inputProps = {} }) => {
    return (
        <Container>
            <Label>
                <Stack gap={0.5}>
                    {getLabelText(label)}
                    <StyledTooltip title={inputProps.disabled ? (value as string) : ''}>
                        <Stack>
                            <NumberInput
                                value={value}
                                thousandSeparator={' '}
                                allowedDecimalSeparators={[',', '.']}
                                decimalSeparator={','}
                                decimalScale={2}
                                {...inputProps}
                            />
                        </Stack>
                    </StyledTooltip>
                </Stack>
            </Label>
        </Container>
    );
};
