import { Autorenew } from '@mui/icons-material';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { MenuItem, MenuItemProps, styled } from '@mui/material';
import React from 'react';

import {
    DownloadEisIcon,
    DownloadProjectIcon,
    ManualSendSnzIcon,
    SaveBasePlanIcon,
    UpdateKSGIcon,
} from '@/shared/SVG/Svg';

export const MenuItemStyled = styled(MenuItem)<MenuItemProps>(() => ({
    '&:hover': {
        backgroundColor: '#edf4fb !important',
        color: 'green !important',
    },
}));

export enum iconsVariants {
    cloudSync = 'cloudSync',
    manualSendSnzIcon = 'manualSendSnzIcon',
    saveBasePlanIcon = 'saveBasePlanIcon',
    downloadEisIcon = 'downloadEisIcon',
    downloadProjectIcon = 'downloadProjectIcon',
    autoRenew = 'autoRenew',
    saveAltIcon = 'saveAltIcon',
    updateKSGIcon = 'updateKSGIcon',
}

export const icons = (color: string): Record<iconsVariants, React.ReactElement> => ({
    [iconsVariants.cloudSync]: <CloudSyncIcon sx={{ color: color }} />,
    [iconsVariants.manualSendSnzIcon]: <ManualSendSnzIcon color={color} />,
    [iconsVariants.saveBasePlanIcon]: <SaveBasePlanIcon color={color} />,
    [iconsVariants.downloadEisIcon]: <DownloadEisIcon color={color} />,
    [iconsVariants.downloadProjectIcon]: <DownloadProjectIcon color={color} />,
    [iconsVariants.autoRenew]: <Autorenew sx={{ color: color }} />,
    [iconsVariants.saveAltIcon]: <SaveAltIcon sx={{ color: color }} />,
    [iconsVariants.updateKSGIcon]: <UpdateKSGIcon color={color} />,
});
