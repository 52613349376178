import { Checkbox as MuiCheckbox } from '@mui/material';
import styled from 'styled-components';

export const Checkbox = styled(MuiCheckbox)`
    &.MuiCheckbox-root {
        padding: 1px;
    }

    &:not(.Mui-checked) .MuiSvgIcon-root {
        color: rgba(154, 162, 176, 0.5);
    }

    & .MuiSvgIcon-root {
        font-size: 18px;
        border-radius: 50%;
    }
`;
