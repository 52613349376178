import { RowClassRules } from 'ag-grid-community';
import { useMemo } from 'react';

import { TData } from './Table.types';

export const useRowClassRules = () => {
    const rowClassRules: RowClassRules<TData> = useMemo(() => {
        return {
            'deleted-row': ({ data }) => {
                if (!data) return false;
                if ('isFull' in data) return false;

                return data.changes.isDeleted;
            },
            'changed-row': ({ data }) => {
                if (!data) return false;
                if ('isFull' in data) return false;
                return data.changes.all;
            },
        };
    }, []);

    return rowClassRules;
};
