import { Button as MuiButton, Stack } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Stack).attrs({
    direction: 'row',
    justifyContent: 'space-between',
})`
    padding: 30px 40px 20px;
    min-height: 206px;
`;

export const Left = styled(Stack).attrs({
    direction: 'row',
    gap: '20px',
    flexWrap: 'wrap',
})`
    padding-inline: 10px;
    height: 156px;
    max-width: 1438px;
    padding-bottom: 30px;
`;

export const Right = styled(Stack).attrs({
    gap: '12px',
    justifyContent: 'flex-end',
})`
    height: 156px;
    padding-bottom: 30px;
`;

export const Button = styled(MuiButton).attrs({
    variant: 'contained',
})`
    font-weight: 500;
    font-size: 13px;
    line-height: 169%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #0044b4;
    border-radius: 6px;
    padding: 6px 8px;
    width: 260px;
    height: 36px;
    background: rgba(25, 118, 210, 0.08);
    transition: background 0.3s, color 0.3s;
    box-shadow: none;

    &:hover {
        background: rgba(25, 118, 210, 0.2);
        box-shadow: none;
    }
`;
