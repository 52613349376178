import { FC } from 'react';

import { Box, ChangedCount, Container, DeletedCount, Label } from './ChangeInfo.styles';

interface IProps {
    changedCount: number;
    deletedCount: number;
}

export const ChangeInfo: FC<IProps> = ({ changedCount, deletedCount }) => {
    return (
        <Container>
            <Label>Изменения</Label>
            <Box>
                <ChangedCount>{changedCount}</ChangedCount>
                <DeletedCount>{deletedCount}</DeletedCount>
            </Box>
        </Container>
    );
};
