import { api } from '@/api/api';

import {
    getCompanyExecutorsReq,
    getCompanyExecutorsRes,
    getCompanyNamesRes,
    getCompnayNamesReq,
    getEmploeesReq,
    getEmploeesRes,
    getProjectBrigadesReq,
    getProjectBrigadesRes,
} from './company.types';

export const projectsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEmployees: build.query<getEmploeesRes, getEmploeesReq>({
            query: ({ companyID, roles }) => ({
                url: `/company/${companyID}/employees`,
                params: { roles },
                method: 'GET',
            }),
            providesTags: ['Company'],
        }),

        getCompanyNames: build.query<getCompanyNamesRes, getCompnayNamesReq>({
            query: ({ companyID }) => ({
                url: `/company/${companyID}/company-names`,
                method: 'GET',
            }),
            providesTags: ['Company'],
        }),

        getExecutors: build.query<getCompanyExecutorsRes, getCompanyExecutorsReq>({
            query: ({ companyID }) => ({
                url: `/company/${companyID}/executors`,
                method: 'GET',
            }),
        }),
        getBrigades: build.query<getProjectBrigadesRes, getProjectBrigadesReq>({
            query: ({ projectID }) => ({
                url: `/projects/${projectID}/brigades/short-list`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetEmployeesQuery, useGetCompanyNamesQuery, useGetExecutorsQuery, useGetBrigadesQuery } = projectsApi;
