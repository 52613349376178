export const AlertIcon = () => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M5.99992 14.6663C5.59992 14.6663 5.33325 14.3997 5.33325 13.9997V11.9997H2.66659C1.93325 11.9997 1.33325 11.3997 1.33325 10.6663V2.66634C1.33325 1.93301 1.93325 1.33301 2.66659 1.33301H13.3333C14.0666 1.33301 14.6666 1.93301 14.6666 2.66634V10.6663C14.6666 11.3997 14.0666 11.9997 13.3333 11.9997H9.26658L6.79992 14.4663C6.66658 14.5997 6.53325 14.6663 6.33325 14.6663H5.99992ZM8.66658 7.33301V3.33301H7.33325V7.33301M8.66658 9.99967V8.66634H7.33325V9.99967H8.66658Z'
                fill='currentColor'
            />
        </svg>
    );
};
