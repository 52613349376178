import { Work } from '@/api/ksg/ksg.types';

import { Nullable } from '@/types/common.types';

type TShortWork = {
    id: number;
    level: Nullable<number>;
    [key: string]: any;
};

export const getWorkChildCount = (works: TShortWork[], parent: TShortWork) => {
    let count = 0;
    let searchStatus: 'idle' | 'start' | 'end' = 'idle';

    for (const work of works) {
        const isParent = work.id === parent?.id;

        if (searchStatus === 'idle') {
            if (isParent) {
                searchStatus = 'start';
            }
            continue;
        }

        if (searchStatus === 'start' && work.level! <= parent?.level!) {
            searchStatus = 'end';
            break;
        }

        count += 1;
    }

    return count;
};

export const getWorkChildren = <T extends TShortWork>(works: T[], parent: T) => {
    const childCount = getWorkChildCount(works, parent);
    const parentIndex = works.findIndex((v) => v.id === parent.id);
    const children = works.slice(parentIndex + 1, parentIndex + 1 + childCount);
    return children;
};

export const getParentInfoById = (works: Work[], id: Nullable<number>): [Nullable<Work>, number] => {
    if (!works) return [null, -1];
    if (!id) return [works[0], 0];
    const index = works.findIndex((v) => v.id === id);
    const parent = works[index];
    return [parent, index];
};

const calculateLimitByLastRow = (lastRow: number, defaultLimit: number) =>
    Math.ceil(lastRow / defaultLimit) * defaultLimit;

export const calculateWorksFetchLimit = (lastRow: Nullable<number>, defaultLimit: number) =>
    lastRow ? calculateLimitByLastRow(lastRow, defaultLimit) : defaultLimit;

export const getPaginationInitialParams = (lastRow: Nullable<number>, projectId: string, limit: number) => {
    return {
        limit: calculateWorksFetchLimit(lastRow, limit),
        offset: 0,
        id: Number(projectId),
    };
};
