type TQueryKeys = {
    estimatePositions: {
        list: string;
        filter: string;
        tabCounters: string;
    };
    consolidatedPrices: {
        list: string;
        item: string;
        filter: string;
        estimatePositionsList: string;
        estimatePositionsFilter: string;
    };
    structure: {
        list: string;
        tree: string;
        addAndGroupPreviewFolder: string;
        insertPreviewFolder: string;
    };
};

export const queryKeys: TQueryKeys = {
    estimatePositions: {
        list: 'estimate-positions-list',
        filter: 'estimate-positions-filter',
        tabCounters: 'estimate-positions-tab-counters',
    },
    consolidatedPrices: {
        list: 'consolidated-prices-list',
        item: 'consolidated-price',
        filter: 'consolidated-prices-filter',
        estimatePositionsList: 'consolidated-prices-estimate-positions-list',
        estimatePositionsFilter: 'consolidated-prices-estimate-positions-filter',
    },
    structure: {
        list: 'structure-list',
        tree: 'structure-tree',
        addAndGroupPreviewFolder: 'structure-add-and-group-preview-folder',
        insertPreviewFolder: 'structure-insert-preview-folder',
    },
};
