import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { EditableCallbackParams, ICellRendererParams, IHeaderParams } from 'ag-grid-community';
import { Location } from 'react-router-dom';

import { KsgPeopleI, ksgPeopleMain } from '@/api/ksgPeople/ksgPeople.types';
import { PublicUserProfile } from '@/api/users/users.types';

import { WorkManagmentRecourceCell } from '@/components/WorkManagmentRecourceCell/WorkManagmentRecourceCell';

import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { hasParam } from '@/shared/utils/common.utils';
import { applyStylesPercent } from '@/shared/utils/styles.utils';

import { toggleAddLevelKsgPeople } from '@/store/slices/drawersSlice';
import { AppDispatch } from '@/store/store';

import { ISubmitCellParams } from '../AgGrid/AgGrid.types';
import RowMonthHeaderRenderer from '../AgGrid/components/RowMonthHeaderRenderer';
import { COL_DEF_TEMPLATE } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer';
import { getHeadersMonthsPeq } from './AgGridKsgPeople.service';
import CheckboxEl from './components/CheckboxEl';
import { HeaderFilterPeqEl } from './components/HeaderFilterPeople';
import MonthRendererKsgAg from './components/MonthRendererKsgAg/MonthRendererKsgAg';
import ZeroLevelWrapper from './components/ZeroLevelWrapper';

interface IGetAgGridKSGStaffColDefParams {
    dispatch: AppDispatch;
    profile: PublicUserProfile;
    setWarningModal: React.Dispatch<
        React.SetStateAction<{
            openState: boolean;
            deleteId: number;
        }>
    >;
    deletePeopleRes: any;
    warningModal: {
        openState: boolean;
        deleteId: number;
    };
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    projectId: string | undefined;
    mainRow: ksgPeopleMain | undefined;
    peopleKsgData: any;
    location: Location;
    headers: any;
}

export const getAgGridKSGPeopleColDef = ({
    dispatch,
    profile,
    setWarningModal,
    deletePeopleRes,
    warningModal,
    setOpenModal,
    projectId,
    mainRow,
    peopleKsgData,
    location,
    headers,
}: IGetAgGridKSGStaffColDefParams) => {
    return [
        {
            field: '',
            minWidth: 100,
            maxWidth: 100,
            headerName: '',
            spanHeaderHeight: true,
            headerComponent: () => (
                <Box mx='auto'>
                    {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_KSG_STAFF_ROW') && (
                        <IconButton
                            onClick={() => {
                                dispatch(toggleAddLevelKsgPeople());
                            }}
                            color='primary'
                            component='label'
                        >
                            <AddIcon />
                        </IconButton>
                    )}
                </Box>
            ),
            cellRenderer: (data: ICellRendererParams<KsgPeopleI, any, any>) => {
                if (
                    !data.data ||
                    !DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'DELETE_KSG_STAFF_ROW') ||
                    data.data?.isResource
                )
                    return <></>;
                return (
                    <ZeroLevelWrapper
                        isZero={data.data.id === 4353246543}
                        cellRendererParams={data}
                    >
                        <IconButton
                            onClick={() => {
                                setWarningModal({ deleteId: data.data?.id || 0, openState: true });
                            }}
                            color='primary'
                            component='label'
                        >
                            {deletePeopleRes.isLoading && data.data.id === warningModal.deleteId ? (
                                <CircularProgress
                                    size={32}
                                    color='success'
                                    disableShrink
                                />
                            ) : (
                                <DeleteForeverOutlinedIcon />
                            )}
                        </IconButton>
                    </ZeroLevelWrapper>
                );
            },
        },
        {
            field: 'name',
            colId: 'name',
            minWidth: 250,
            flex: 2,
            headerName: 'Наименование',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<KsgPeopleI, any>) => (
                <HeaderFilterPeqEl
                    onFilterClick={() => {
                        setOpenModal(true);
                    }}
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            editable: (params: EditableCallbackParams<KsgPeopleI>) =>
                params.data?.id !== 4353246543 && !params.data?.isResource,
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA(),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('name'),
            cellRenderer: (params: ICellRendererParams) => {
                if (params.data?.isResource) {
                    return <WorkManagmentRecourceCell>{params.data?.name}</WorkManagmentRecourceCell>;
                }
                if (params.data?.id === 4353246543) {
                    return 'Основной персонал';
                } else {
                    return COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA().cellRenderer(params);
                }
            },
        },
        {
            field: 'category',
            colId: 'category',
            flex: 2,
            minWidth: 250,
            headerName: 'Категория',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams<KsgPeopleI>) =>
                params.data?.id !== 4353246543 && !params.data?.isResource,
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA(),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('category'),
            cellRenderer: (params: ICellRendererParams) => {
                if (params.data?.id === 4353246543) {
                    return '';
                } else {
                    return COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA().cellRenderer(params);
                }
            },
        },
        {
            field: 'isMain',
            colId: 'isMain',
            width: 120,
            headerName: 'Основной персонал',
            spanHeaderHeight: true,
            cellRenderer: (cellRendererParams: ICellRendererParams<KsgPeopleI, any, any>) => {
                if (!cellRendererParams.data) return <></>;
                return (
                    <>
                        {cellRendererParams.data.id !== 4353246543 ? (
                            <CheckboxEl
                                cellRendererParams={cellRendererParams}
                                projectId={projectId}
                            />
                        ) : (
                            <div></div>
                        )}
                    </>
                );
            },
        },
        {
            field: 'avgCount',
            colId: 'avgCount',
            width: 180,
            headerName: 'Количество в месяц',
            spanHeaderHeight: true,
            cellRenderer: (params: ICellRendererParams) => (
                <NumberCellRenderer value={params.data.id === 4353246543 ? mainRow?.avgCount : params.value} />
            ),
        },
        {
            field: 'avgCount90',
            colId: 'avgCount90',
            width: 180,
            headerName: 'Общая средняя потребность, 90 дней',
            spanHeaderHeight: true,
            cellRenderer: (params: ICellRendererParams) => (
                <NumberCellRenderer value={params.data.id === 4353246543 ? mainRow?.avgCount90 : params.value} />
            ),
        },
        {
            headerName: 'Текущая потребность',
            colId: 'current',
            children: [
                {
                    field: 'currentPlan',
                    colId: 'currentPlan',
                    width: 150,
                    headerName: 'План',
                    cellRenderer: (params: ICellRendererParams) => (
                        <NumberCellRenderer
                            value={params.data.id === 4353246543 ? mainRow?.currentPlan : params.value}
                        />
                    ),
                },
                {
                    field: 'currentFact',
                    colId: 'currentFact',
                    width: 150,
                    headerName: 'Факт',
                    cellRenderer: (params: ICellRendererParams) => (
                        <NumberCellRenderer
                            value={params.data.id === 4353246543 ? mainRow?.currentFact : params.value}
                        />
                    ),
                },
                {
                    field: 'currentPercent',
                    colId: 'currentPercent',
                    width: 150,
                    headerName: 'Обеспеченность',
                    cellRenderer: (params: ICellRendererParams) => (
                        <NumberCellRenderer
                            value={params.data.id === 4353246543 ? mainRow?.currentPercent : params.value}
                            NumericFormatProps={{
                                suffix: ' %',
                            }}
                            TypographyProps={{
                                sx: applyStylesPercent(mainRow?.currentPercent || 0),
                            }}
                        />
                    ),
                },
            ],
        },
        {
            headerName: 'Общее количество нарастающим итогом',
            colId: 'total',
            children: [
                {
                    field: 'totalPlan',
                    colId: 'totalPlan',
                    width: 150,
                    headerName: 'План',
                    cellRenderer: (params: ICellRendererParams) => (
                        <NumberCellRenderer value={params.data.id === 4353246543 ? mainRow?.totalPlan : params.value} />
                    ),
                },
                {
                    field: 'totalFact',
                    colId: 'totalFact',
                    width: 150,
                    headerName: 'Факт',
                    cellRenderer: (params: ICellRendererParams) => (
                        <NumberCellRenderer value={params.data.id === 4353246543 ? mainRow?.totalFact : params.value} />
                    ),
                },
                {
                    field: 'totalPercent',
                    colId: 'totalPercent',
                    width: 150,
                    headerName: 'Соответствие',
                    cellRenderer: (params: ICellRendererParams) => (
                        <NumberCellRenderer
                            value={params.data.id === 4353246543 ? mainRow?.totalPercent : params.value}
                            NumericFormatProps={{
                                suffix: ' %',
                            }}
                            TypographyProps={{
                                sx: applyStylesPercent(mainRow?.totalPercent || 0),
                            }}
                        />
                    ),
                },
            ],
        },
        {
            headerName: 'Сравнение производительности труда нарастающим итогом, чел/смен',
            colId: 'intensity',
            children: [
                {
                    field: '',
                    colId: 'intensityNorm',
                    width: 150,
                    headerName: 'Нормативная трудоемкость',
                    cellRenderer: (params: ICellRendererParams) => (
                        <NumberCellRenderer
                            value={params.data.id === 4353246543 ? mainRow?.compareNorm : params.value}
                        />
                    ),
                },
                {
                    field: '',
                    colId: 'intensityFact',
                    width: 150,
                    headerName: 'Фактическая трудоемкость',
                    cellRenderer: (params: ICellRendererParams) => (
                        <NumberCellRenderer
                            value={params.data.id === 4353246543 ? mainRow?.compareFact : params.value}
                        />
                    ),
                },
                {
                    field: '',
                    colId: 'intensityDelta',
                    width: 150,
                    headerName: 'Δ (- перерасход / + оптимизация)',
                    cellRenderer: (params: ICellRendererParams) => (
                        <NumberCellRenderer
                            value={params.data.id === 4353246543 ? mainRow?.compareDeviation : params.value}
                        />
                    ),
                },
            ],
        },
        {
            field: 'verifiedFactTotal',
            colId: 'verifiedFactTotal',
            width: 180,
            spanHeaderHeight: true,
            headerName: 'Вер. факт нарастающим итогом',
            headerClass: 'ag-verFact',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: '',
            width: 120,
            headerName: 'План/Факт',
            spanHeaderHeight: true,
            cellRenderer: (params: ICellRendererParams<KsgPeopleI, any, any>) => {
                if (!params.data) return <></>;
                return (
                    <ZeroLevelWrapper
                        monthRowsHeadersCell
                        isZero={params.data.id === 4353246543}
                        cellRendererParams={params}
                    >
                        <RowMonthHeaderRenderer
                            isGant={hasParam('g', location.search) || hasParam('gf', location.search)}
                            hasChildren={false}
                            cellRendererParams={params}
                            isResourceTable
                        />
                    </ZeroLevelWrapper>
                );
            },
        },

        ...getHeadersMonthsPeq(peopleKsgData.data?.data[0]?.monthlyCharts, (cellRendererParams, chartIndex) => {
            if (!cellRendererParams.data || !cellRendererParams.data?.monthlyCharts?.[chartIndex]) return <></>;
            return (
                <ZeroLevelWrapper
                    chart={mainRow?.monthlyCharts?.[chartIndex]}
                    isZero={cellRendererParams.data.id === 4353246543}
                    cellRendererParams={cellRendererParams}
                    chartIndex={chartIndex}
                >
                    <MonthRendererKsgAg
                        cellRendererParams={cellRendererParams}
                        chartIndex={chartIndex}
                        resourceType={'staff'}
                        headers={headers?.data}
                    />
                </ZeroLevelWrapper>
            );
        }),
    ];
};

export interface ISubmitKSGPeopleData extends ISubmitCellParams {
    request: Promise<any>;
}
