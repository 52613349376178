import slice from 'lodash/slice';
import { useEffect } from 'react';

import { StyledCheckbox } from '@/pages/AgGrid/StyledCheckbox';
import { isShiftKey } from '@/pages/EstimatePositionsTable/EstimatePositionsTable.utils';

import { getRowData } from '@/shared/utils/agGrid.utils';

import { TData } from '../../StructureManagementTable.types';
import { generateRowId } from '../../utils';
import { ConsolidatedCheckboxCellRendererContainer as Container } from './TableCells.styles';
import { TCellRendererProps } from './TableCells.types';

export const ConsolidatedCheckboxCellRenderer: React.FC<TCellRendererProps> = (props) => {
    const isSelected = props.context.consolidatedSelectSet.has(props.node.id!);
    const lastCheckedRowId = props.context.lastConsolidatedSelectRowId;

    useEffect(() => {
        if (!isSelected) return;
        props.node.setSelected(true);
    }, [isSelected]);

    const handleShiftSelection = (checked: boolean) => {
        const rowData = getRowData<TData>(props.api);

        const currentIndex = props.node.rowIndex!;
        const lastIndex = lastCheckedRowId ? props.api.getRowNode(lastCheckedRowId!)?.rowIndex! : 0;

        const [min, max] = [Math.min(currentIndex, lastIndex), Math.max(currentIndex, lastIndex)];

        const range = slice(rowData, min, max + 1).filter((item) => item.rowType === 'b_consolidated');
        const nodes = range.map((item) =>
            props.api.getRowNode(generateRowId({ rowType: 'b_consolidated', entityId: item.entityId }))
        );

        for (const node of nodes) {
            if (!node) continue;
            node.setSelected(checked);
        }

        props.context.onConsolidatedSelect({
            lastId: props.node.id!,
            checked: checked,
            ids: nodes.map((node) => node!.id!),
        });
        setTimeout(() => {
            props.api.refreshCells({
                columns: ['edit'],
                force: true,
            });
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (isShiftKey(event)) return handleShiftSelection(checked);

        props.node.setSelected(checked);
        props.context.onConsolidatedSelect({
            lastId: props.node.id!,
            checked: checked,
            ids: [props.node.id!],
        });

        setTimeout(() => {
            props.api.refreshCells({
                columns: ['edit'],
                force: true,
            });
        });
    };

    return (
        <Container>
            <StyledCheckbox
                checked={isSelected}
                onChange={handleChange}
            />
        </Container>
    );
};
