interface IProps {}

export const FileIcon: React.FC<IProps> = () => {
    return (
        <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.9601 3.66235H4.81327C3.99859 3.66235 3.33203 4.32902 3.33203 5.14383V15.5142C3.33203 16.329 3.99859 16.9957 4.81327 16.9957H15.1819C15.9966 16.9957 16.6632 16.329 16.6632 15.5142V7.36606L12.9601 3.66235ZM6.29451 6.62532H9.9976V8.1068H6.29451V6.62532ZM13.7007 14.0327H6.29451V12.5512H13.7007V14.0327ZM13.7007 11.0698H6.29451V9.58828H13.7007V11.0698ZM12.2195 8.1068V5.14383L15.1819 8.1068H12.2195Z'
                fill='currentColor'
            />
        </svg>
    );
};
