import React from 'react';

import { useToggleSearchParam } from '@/hooks/useToggleSearchParam';

import { FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';

import { IntegrationWithOfferSearchParam } from '@/shared/constants/integrationWithOffer';

import { BasePanel } from './BasePanel';
import { IControlPanelProps } from './ControlPanel.types';
import { SyncStatus } from './SyncStatus';

export const ControlPanel: React.FC<IControlPanelProps> = ({ isKC6APage, integrationStatus }) => {
    const { isActive: isOfferActive } = useToggleSearchParam(IntegrationWithOfferSearchParam);

    const isVisibleBasePanel = isOfferActive;

    return (
        <FlexRowWrapper gap={1}>
            {isVisibleBasePanel ? (
                <BasePanel
                    isKC6APage={isKC6APage}
                    integrationStatus={integrationStatus}
                />
            ) : (
                <SyncStatus />
            )}
        </FlexRowWrapper>
    );
};
