import qs from 'qs';

import { api } from '@/api/api';

import { setTotal } from '@/store/slices/paginationSlice';
import { store } from '@/store/store';

import { uploadExcelReq, uploadExcelRes } from '../works/works.types';
import {
    addKsgEquipReqI,
    getKsgEquipByIdResI,
    getKsgEquipResI,
    updateEquipKsgChartsReqI,
    updateEquipKsgChartsResI,
    updateEquipKsgResI,
    updateKsgEquipReqI,
} from './ksgEquip.types';

export const peopleEquipKsg = api.injectEndpoints({
    endpoints: (build) => ({
        getKsgEquip: build.query<getKsgEquipResI, { id: number; limit?: number; offset?: number; filter?: string }>({
            query: ({ id, limit, offset, filter }) => ({
                url: `/projects/${id}/technics/cpg?${qs.stringify(
                    { title: store.getState().filtersWorkersMim.titlesArr },
                    {
                        indices: false,
                    }
                )}`,
                method: 'GET',
                params: {
                    limit,
                    offset,
                    filter,
                },
            }),
            async onQueryStarted(arg, api) {
                const data = await api.queryFulfilled;

                api.dispatch(setTotal(data.data.data.length ? data.data.total : 1));
            },
            providesTags: ['KsgEquip'],
        }),

        addKsgEquip: build.mutation<updateEquipKsgResI, addKsgEquipReqI>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/technics/add`,
                method: 'POST',
                body,
            }),
            // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: updatedStatus } = await queryFulfilled
            //         dispatch(
            //             peopleEquipKsg.util.updateQueryData(
            //                 'getKsgEquip',
            //                 {
            //                     id,
            //                     limit: 9999,
            //                     offset: 0,
            //                     filter: store.getState().project.headerSearch,
            //                 },
            //                 (draft) => {
            //                     draft.data.unshift(updatedStatus.data)
            //                 }
            //             )
            //         )
            //     } catch {}
            // },
        }),

        updateEquipKsgCharts: build.mutation<updateEquipKsgChartsResI, updateEquipKsgChartsReqI>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/technics/update-charts`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(
                        peopleEquipKsg.util.updateQueryData(
                            'getKsgEquip',
                            {
                                id,
                                limit: 9999,
                                offset: 0,
                                filter: store.getState().project.headerSearch,
                            },
                            (draft) => {
                                const indexChange = draft.data.findIndex((row) => {
                                    return row.id === updatedStatus.data[0].id;
                                });
                                draft.data.splice(indexChange, 1, updatedStatus.data[0]);
                            }
                        )
                    );
                } catch {}
            },
        }),

        getByIdKsgEquip: build.query<
            getKsgEquipByIdResI,
            {
                id: number;
                techID: number;
            }
        >({
            query: ({ id, techID }) => ({
                url: `/projects/${id}/technics/${techID}/get`,
                method: 'GET',
            }),
            providesTags: ['KsgEquip'],
        }),

        updateKsgEquip: build.mutation<updateEquipKsgResI, updateKsgEquipReqI>({
            query: ({ id, techID, body }) => ({
                url: `/projects/${id}/technics/${techID}/update`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(
                        peopleEquipKsg.util.updateQueryData(
                            'getKsgEquip',
                            {
                                id,
                                limit: 9999,
                                offset: 0,
                                filter: store.getState().project.headerSearch,
                            },
                            (draft) => {
                                const indexChange = draft.data.findIndex((row) => {
                                    return row.id === updatedStatus.data.id;
                                });
                                draft.data.splice(indexChange, 1, updatedStatus.data);
                            }
                        )
                    );
                } catch {}
            },
        }),

        deleteKsgEquip: build.mutation<updateEquipKsgResI, { id: number; workId: number }>({
            query: ({ id, workId }) => ({
                url: `/projects/${id}/technics/${workId}/delete`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id, workId, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        peopleEquipKsg.util.updateQueryData(
                            'getKsgEquip',
                            {
                                id,
                                limit: 9999,
                                offset: 0,
                                filter: store.getState().project.headerSearch,
                            },
                            (draft) => {
                                draft.data = draft.data.filter((work) => work.id !== workId);
                            }
                        )
                    );
                } catch {}
            },
        }),

        getExcelLinkEquip: build.query<{ data: string }, void>({
            query: () => ({
                url: `/projects/technics/link-example`,
                method: 'GET',
            }),
        }),

        uploadExcelEquip: build.mutation<uploadExcelRes, uploadExcelReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/technics/upload`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Headers', 'KsgEquip'],
        }),

        downloadExcelEquip: build.mutation<string, { id: number }>({
            query: ({ id }) => ({
                url: `/projects/${id}/technics/export-ksg`,
                method: 'POST',
                headers: {
                    'content-type': 'text/plain',
                },
                responseHandler: 'text',
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetKsgEquipQuery,
    useUpdateEquipKsgChartsMutation,
    useUpdateKsgEquipMutation,
    useDeleteKsgEquipMutation,
    useGetExcelLinkEquipQuery,
    useUploadExcelEquipMutation,
    useAddKsgEquipMutation,
    useDownloadExcelEquipMutation,
} = peopleEquipKsg;
