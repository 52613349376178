import { api } from '@/api/api';

import { TPriceSettings, handleChangePriceSettings } from '@/store/slices/priceSettings';
import { store } from '@/store/store';

import { IGetPriceSettingsReq, ISavePriceSettingsReq } from './priceSettings.types';

export const priceSettingsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPriceSettings: build.mutation<TPriceSettings, IGetPriceSettingsReq>({
            query: ({ projectId, tableType }) => ({
                url: `/projects/${projectId}/settings/cost-view/${tableType}`,
                method: 'GET',
            }),
            async onQueryStarted({ tableType }, api) {
                try {
                    const data = await api.queryFulfilled;
                    api.dispatch(handleChangePriceSettings({ tableType: tableType, [tableType]: data.data }));
                } catch {}
            },
        }),
        savePriceSettings: build.mutation<string, ISavePriceSettingsReq>({
            query: ({ projectId, tableType }) => ({
                url: `/projects/${projectId}/settings/cost-view/${tableType}`,
                method: 'POST',
                body: {
                    ...store.getState().priceSettings[tableType],
                },
            }),
            async onQueryStarted({ priceSettings, tableType }, api) {
                try {
                    api.dispatch(
                        handleChangePriceSettings({
                            tableType: tableType,
                            ...priceSettings,
                        })
                    );
                } catch {}
            },
        }),
    }),
});

export const { useGetPriceSettingsMutation, useSavePriceSettingsMutation } = priceSettingsApi;
