import { LoadingButton } from '@mui/lab';
import { IconButton as MuiIconButton, Stack } from '@mui/material';
import styled from 'styled-components';

import { StyledBtn } from '@/pages/WorkManagment/components/components.styles';

export const Container = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    gap: 1.25,
})``;

const Button = styled(StyledBtn)`
    height: 30px;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 1;
`;

export const FetchPositionButton = styled(LoadingButton).attrs({
    size: 'small',
    variant: 'outlined',
})`
    border-color: #8cbbe8;
`;

export const CreateButton = styled(Button).attrs({
    size: 'small',
    variant: 'outlined',
    color: 'secondary',
})`
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    border-radius: 6px;
    width: 313px;
    height: 30px;
    padding: 0;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s;

    &.Mui-disabled {
        color: #9aa2b0;
        border-color: #9aa2b0;
        background-color: #edeff3;
    }
`;

export const CreateBulkButton = styled(MuiIconButton)`
    background-color: #fff;
    border-radius: 6px;
    padding: 6px;
    width: 30px;
    height: 30px;
    color: #0044b4;
    transition: color 0.3s, background-color 0.3s;

    &.Mui-disabled {
        background-color: rgba(43, 54, 72, 0.12);
        color: #9aa2b0;
    }
`;
