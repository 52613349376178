import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { theme } from '@/styles/theme';

interface IProps extends React.PropsWithChildren {}

export const ThemeProvider: React.FC<IProps> = ({ children }) => {
    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <StyledComponentsThemeProvider theme={theme}>{children}</StyledComponentsThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    );
};
