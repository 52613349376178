import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api';
import { useAddKsgEquipMutation } from '@/api/ksgEquip/ksgEquip.api';
import { useAddKsgPeopleMutation } from '@/api/ksgPeople/ksgPeople.api';

import { ButtonsAndWarningEl } from '@/shared/components/ButtonsAndWarning/ButtonsAndWarning';

import { drawersSelector, toggleAddLevelKsgEquip, toggleAddLevelKsgPeople } from '@/store/slices/drawersSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import s from '../AddLevelKsg/AddLevelKsg.module.scss';
import TextLineEl from '../AddLevelKsg/components/TextLineEl';
import { initialFormValuesAgPeq, initialFormValuesAgPeqI, validationSchemaAgPeq } from './AddLevelKsgPeq.service';

export default function AddLevelKsgPeq({ people }: { people?: boolean }) {
    const [charts, setCharts] = useImmer({
        charts: [
            {
                fact: null,
                month: 0,
                plan: null,
                year: 0,
            },
        ],
    });

    const location = useLocation();

    const { projectId } = useParams();

    const [addWorkPeopleReq] = useAddKsgPeopleMutation();
    const [addWorkEquipReq] = useAddKsgEquipMutation();
    const { AgGrid } = useTypedSelector(drawersSelector);

    const param = people ? { isStaff: true } : { isTech: true };
    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
        ...param,
    });

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    useEffect(() => {
        setCharts((d) => {
            if (!headersCPG.data?.allHeaders) return d;
            d.charts = headersCPG.data.allHeaders.map((header) => {
                return {
                    fact: null,
                    month: header.month,
                    plan: null,
                    year: header.year,
                };
            });
        });
    }, [headersCPG.data?.allHeaders, setCharts]);

    const onSubmit = useCallback(
        (values: initialFormValuesAgPeqI) => {
            (people
                ? addWorkPeopleReq({
                      id: Number(projectId),
                      body: {
                          ...values,
                          ...charts,
                      },
                  })
                : addWorkEquipReq({
                      id: Number(projectId),
                      body: {
                          ...values,
                          ...charts,
                      },
                  })
            )
                .unwrap()
                .then((data) => {
                    AgGrid?.api?.applyTransaction({ add: [{ ...data.data }] });
                })
                .then(() => {
                    enqueueSnackbar(t('work_added'), {
                        variant: 'success',
                    });
                    people ? dispatch(toggleAddLevelKsgPeople()) : dispatch(toggleAddLevelKsgEquip());
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                        variant: 'error',
                    });
                    console.error(e);
                });
        },
        [addWorkEquipReq, addWorkPeopleReq, charts, dispatch, enqueueSnackbar, people, projectId, t]
    );

    const formik = useFormik<initialFormValuesAgPeqI>({
        initialValues: initialFormValuesAgPeq,
        onSubmit,
        validationSchema: validationSchemaAgPeq,
    });

    const isDisabled = () => {
        return !formik.isValid && Object.values(formik.touched).every((item) => item);
    };
    const isMim = useMemo(() => {
        return /\/ksg\/mim\/.+/.test(location.pathname) ? 'Механизм' : 'Персонал';
    }, [location.pathname]);
    return (
        <>
            <h4 className={s.sync_header}>Добавить {isMim}:</h4>
            <div
                className={s.main}
                style={{
                    height: 'calc(100vh - 8rem)',
                }}
            >
                <h4 className={s.main_title}>Заполните обязательные поля</h4>
                <form
                    onSubmit={formik.handleSubmit}
                    className={s.main_form}
                >
                    <TextLineEl
                        title='Наименование'
                        errorTitle={formik.errors.name}
                        touched={formik.touched.name}
                        {...formik.getFieldProps('name')}
                        required
                    />

                    <button
                        type='submit'
                        style={{
                            display: 'none',
                        }}
                    ></button>
                </form>
            </div>
            <div className={s.buttons}>
                <ButtonsAndWarningEl
                    onClose={() => {
                        people ? dispatch(toggleAddLevelKsgPeople()) : dispatch(toggleAddLevelKsgEquip());
                    }}
                    onSubmit={formik.handleSubmit}
                    submitText='сохранить'
                    cancelText='отменить'
                    disabled={isDisabled()}
                />
            </div>
        </>
    );
}
