import { animated } from '@react-spring/web';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useAddAutoFoldersStructureMutation } from '@/api/structure';

import { useBoop } from '@/hooks/useBoop';

import { DownloadDocumentIcon } from '@/shared/SVG/DownloadDocumentIcon';
import { queryClient } from '@/shared/constants/queryClient';
import { queryKeys } from '@/shared/constants/queryKeys';

import { AutoGenerateLevelsButton, Container, CreateKsgButton } from './StructureManagementControls.styles';
import { TStructureManagementControlsProps } from './StructureManagementControls.types';

const AnimatedDownloadDocumentIcon = animated(DownloadDocumentIcon);

export const StructureManagementControls: FC<TStructureManagementControlsProps> = () => {
    const [createKsgButtonStyle, createKsgButtonTrigger] = useBoop({ rotation: 4, timing: 200 });
    const { enqueueSnackbar } = useSnackbar();
    const { projectId } = useParams();

    const { mutate: mutateAutoFoldersAdd } = useAddAutoFoldersStructureMutation({
        onSuccess: () => {
            enqueueSnackbar('Уровни успешно сформированы автоматически', {
                variant: 'success',
            });
        },
        onError: (data) => {
            // @ts-ignore
            const message = match(data).otherwise(() => 'Произошла ошибка при сформировании уровней');

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
        onSettled: async () => {
            queryClient.invalidateQueries({
                queryKey: [queryKeys.structure.list, Number(projectId)],
            });
        },
    });

    const handleAutoGenerateLevels = () => {
        mutateAutoFoldersAdd({ projectId: Number(projectId) });
    };

    const handleCreateKsg = () => {
        console.log('handleCreateKsg');
    };

    return (
        <Container>
            <AutoGenerateLevelsButton onClick={handleAutoGenerateLevels}>
                Сформировать уровни автоматически
            </AutoGenerateLevelsButton>
            <CreateKsgButton
                onClick={handleCreateKsg}
                onMouseEnter={createKsgButtonTrigger}
            >
                <AnimatedDownloadDocumentIcon style={createKsgButtonStyle} />
            </CreateKsgButton>
        </Container>
    );
};
