import { Typography } from '@mui/material';
import { ColDef, ICellEditorParams, ICellRendererParams } from 'ag-grid-community';

import { COL_DEF_TEMPLATE } from '../../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { NumberCellRenderer } from '../../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer';
import { IMSGResourceItem } from '../api/api.types';

interface IMSGResourceTableColDef extends ColDef {
    field?: keyof IMSGResourceItem | 'dailyChart.fact' | 'dailyChart.plan' | 'dailyChart.forecast';
    cellRenderer?: (params: ICellRendererParams<IMSGResourceItem, number | string, any>) => React.ReactNode;
    cellEditor?: ((params: ICellEditorParams<IMSGResourceItem, number | string, any>) => React.ReactNode) | string;
    children?: ColDef<IMSGResourceItem>[];
}

export const MSGResourceTableColDef: IMSGResourceTableColDef[] = [
    {
        headerName: 'Наименование',
        field: 'name',
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left',
        initialSort: 'asc',
        spanHeaderHeight: true,
        minWidth: 200,
    },
    {
        headerName: 'План',
        children: [
            {
                headerName: 'План на сегодня',
                field: 'dailyChart.plan',
                maxWidth: 150,
                ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('volumeDonePlan'),
            },
            {
                headerName: 'Прогноз на сегодня',
                field: 'dailyChart.forecast',
                maxWidth: 170,
                ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('volumeDoneFact'),
            },
            {
                headerName: 'План на месяц',
                field: 'planMonth',
                maxWidth: 150,
                ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('volumeDoneFact'),
            },
        ],
    },
    {
        headerName: 'Факт',
        field: 'dailyChart.fact',
        maxWidth: 100,
        editable: true,
        spanHeaderHeight: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('fact'),
        cellRenderer: (params) => {
            return params.data?.dailyChart.fact == null ? (
                <Typography
                    variant='tooltip'
                    color={'primary'}
                >
                    Введите факт
                </Typography>
            ) : (
                <NumberCellRenderer {...params} />
            );
        },
    },
];

export const HIDDEN_MOBILE_COLUMNS: Required<IMSGResourceTableColDef['field'][]> = [
    'dailyChart.plan',
    'dailyChart.forecast',
    'planMonth',
];
