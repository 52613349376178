import { AgGridReact } from 'ag-grid-react';
import styled, { css } from 'styled-components';

export const AgGridContainerCss = css`
    padding-inline: 20px;

    .ag-header-cell-label {
        justify-content: flex-start;
    }

    .ag-header-cell-center {
        text-align: center;

        .ag-header-cell-label {
            justify-content: center !important;
        }
    }

    .p0 {
        padding: 0;
    }

    .ag-cell-left {
        border-left: 1px solid #edeff3 !important;
    }

    .ag-full-width-container {
        border-left: 1px solid #edeff3;
        border-right: 1px solid #edeff3;
    }
`;

export const AgGrid = styled(AgGridReact)``;
