import { TConsolidatedPrice } from '@/api/consolidatedPrices';

import { TConsolidatedPriceDialogFormValues } from '@/components/ConsolidatedPriceDialog';

import { TSelectedPositionType } from '@/pages/EstimatePositionsTable/EstimatePositionsTable.types';

import { getFormatNumber } from '@/shared/utils/formatNumber.utils';

export const getEstimatePositions = (data: TConsolidatedPrice) => {
    const positions = data.positions ?? [];
    let mainPositionIdData = null;
    const primaryPositionsData = [];
    const secondaryPositionsData = [];

    for (const position of positions) {
        if (position.type === 'main' || position.type === 'primary') {
            const newPosition = { ...position, selectType: 'primary' as TSelectedPositionType };
            primaryPositionsData.push(newPosition);

            if (position.type === 'main') {
                mainPositionIdData = position.id;
            }

            continue;
        }

        const newPosition = { ...position, selectType: 'secondary' as TSelectedPositionType };
        secondaryPositionsData.push(newPosition);
    }

    return {
        mainPositionIdData,
        primaryPositionsData,
        secondaryPositionsData,
    };
};

export const getDefaultFormValues = (data: TConsolidatedPrice): TConsolidatedPriceDialogFormValues => {
    return {
        chapter: data.chapter ?? '',
        consolidatedName: data.consolidatedName ?? '',
        lsrNumber: data.lsrNumber ?? '',
        lsrCode: data.lsrCode ?? '',
        header: data.header ?? '',
        code: data.code ?? '',
        rawUnit: data.unit?.type ?? null,
        quantity: getFormatNumber({ value: data.quantity }) ?? '0,00',
        humanHourCost: getFormatNumber({ value: data.humanHourCost }) ?? '0,00',
        machineHourCost: getFormatNumber({ value: data.machineHourCost }) ?? '0,00',
        costTotal: getFormatNumber({ value: data.costTotal }) ?? '0,00',
    };
};
