import NiceModal from '@ebay/nice-modal-react';
import { GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { TEstimatePositionsFilterKeys } from '@/api/estimatePositions';
import { TEstimatePositionId } from '@/api/estimatePositions/estimatePositions.types';

import { useAgGridLastRowInView } from '@/hooks/useAgGridLastRowInView';
import { useLatest } from '@/hooks/useLatest';

import { consolidatedPricesActions, consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { columnsDef } from './EstimatePositionsTable.columnDefs';
import { defaultColDef } from './EstimatePositionsTable.config';
import { useGetEstimatePositions, useRowClassRules } from './EstimatePositionsTable.model';
import { TContext, TData, TEstimatePositionsTableProps, TSelectedPositionType } from './EstimatePositionsTable.types';
import {
    createCheckboxKey,
    getCheckboxesRange,
    isShiftKey,
    redrawAllRows,
    redrawRows,
    updateCheckboxesMap,
} from './EstimatePositionsTable.utils';
import { FilterDialog } from './components/FilterDialog';
import { Table } from './components/Table';

export const EstimatePositionsTable: React.FC<TEstimatePositionsTableProps> = () => {
    const gridRef = useRef<AgGridReact<TData>>(null);
    const { projectId } = useParams();

    const dispatch = useAppDispatch();

    const { inView: isLastRowInView, reset: resetInView } = useAgGridLastRowInView(gridRef.current?.api);

    const checkedPositionsMap = useTypedSelector(consolidatedPricesSelectors.estimatePositionsCheckedPositionsMap);
    const checkedPositionId = useTypedSelector(consolidatedPricesSelectors.estimatePositionsCheckedPositionId);
    const isNeedRedrawAllRows = useTypedSelector(consolidatedPricesSelectors.estimatePositionsRedrawAllRows);
    const estimatePositionsCheckedPositionsMapForEdit = useTypedSelector(
        consolidatedPricesSelectors.estimatePositionsCheckedPositionsMapForEdit
    );

    const filters = useTypedSelector(consolidatedPricesSelectors.estimatePositionsFilters);

    const { data, isLoading, fetchNextPage } = useGetEstimatePositions({
        projectId: Number(projectId),
    });

    useEffect(() => {
        if (!isLastRowInView) return;

        fetchNextPage().finally(resetInView);
    }, [isLastRowInView]);

    const rowData = useMemo(
        () => data?.positions.filter((position) => !estimatePositionsCheckedPositionsMapForEdit.has(position.id)) ?? [],
        [data, estimatePositionsCheckedPositionsMapForEdit]
    );

    const latestCheckedPositionId = useLatest(checkedPositionId);
    const latestCheckedPositionsMap = useLatest(checkedPositionsMap);
    const latestRowData = useLatest(rowData);

    const rowClassRules = useRowClassRules();

    const handleCheckboxChange = useCallback(
        (id: TEstimatePositionId, type: TSelectedPositionType) =>
            (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                const range = getCheckboxesRange({
                    data: latestRowData.current,
                    id,
                    lastCheckedId: latestCheckedPositionId.current,
                    isShiftKey: isShiftKey(event),
                    type,
                });

                dispatch(
                    consolidatedPricesActions.updateEstimatePositionsCheckedPositionsMap(
                        updateCheckboxesMap(latestCheckedPositionsMap.current, checked, id, range)
                    )
                );
                dispatch(consolidatedPricesActions.setEstimatePositionsCheckedPositionId(id));
                redrawRows(gridRef.current?.api!, range);
            },
        []
    );

    useEffect(() => {
        if (!gridRef.current?.api) return;

        redrawAllRows(gridRef.current.api);
    }, [rowData, isNeedRedrawAllRows]);

    const getRowId = useCallback(({ data }: GetRowIdParams<TData>) => {
        return data?.id.toString();
    }, []);

    const handleFilterVisible = useCallback(
        (key: TEstimatePositionsFilterKeys) => () => {
            NiceModal.show(FilterDialog, {
                filterKey: key,
                projectId: Number(projectId),
            });
        },
        []
    );

    const context: TContext = useMemo(() => {
        return {
            filters,
            checkboxesSet: new Set(
                Array.from(checkedPositionsMap.values()).map((v) => createCheckboxKey(v.id, v.selectType))
            ),
            onCheckboxChange: handleCheckboxChange,
            onFilterVisible: handleFilterVisible,
        };
    }, [filters, checkedPositionsMap, handleCheckboxChange]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        gridRef?.current?.api?.refreshHeader();
    }, [filters]);

    return (
        <Table
            isLoading={isLoading}
            rowClassRules={rowClassRules}
            getRowId={getRowId}
            gridRef={gridRef}
            rowData={rowData}
            columnDefs={columnsDef}
            context={context}
            defaultColDef={defaultColDef}
            headerHeight={40}
            rowHeight={64}
            overlayNoRowsTemplate='Нет данных'
        />
    );
};
