import { CancelOutlined } from '@mui/icons-material';
import { Dialog, Typography } from '@mui/material';

import { FlexRowWrapper } from '../../../../NewExecutorView/components/components.styles';
import { submitKSGMonthlyChart } from '../MonthRenderer.service';
import { CloseIconBtn, DialogBtn, DialogWrapper } from './DeletePlanDialog.styles';
import { IDeletePlanDialogProps } from './DeletePlanDialog.types';

export function DeletePlanDialog(props: IDeletePlanDialogProps) {
    const { isDialogOpen, setIsDialogOpen, setIsFetching } = props;
    return (
        <Dialog open={isDialogOpen}>
            <CloseIconBtn
                size='small'
                onClick={() => {
                    setIsDialogOpen(false);
                    setIsFetching(false);
                }}
            >
                <CancelOutlined />
            </CloseIconBtn>
            <DialogWrapper>
                <Typography
                    color='primary'
                    fontWeight={500}
                >
                    Вы хотите удалить работу из МСГ, или обнулить значение?
                </Typography>
                <Typography
                    fontSize={14}
                    letterSpacing={0.4}
                >
                    При удалении оперативного плана, работа будет удалена из МСГ
                </Typography>

                <FlexRowWrapper width={'100%'}>
                    <DialogBtn
                        color='error'
                        sx={{ color: 'white' }}
                        onClick={() => {
                            submitKSGMonthlyChart({
                                ...props,
                                formData: {
                                    plan: null,
                                },
                            });
                            setIsDialogOpen(false);
                        }}
                    >
                        Удалить
                    </DialogBtn>
                    <DialogBtn
                        color='success'
                        onClick={() => {
                            submitKSGMonthlyChart({
                                ...props,
                                formData: {
                                    plan: 0,
                                },
                            });
                            setIsDialogOpen(false);
                        }}
                    >
                        Обнулить
                    </DialogBtn>
                    <DialogBtn
                        color='primary'
                        onClick={() => {
                            setIsDialogOpen(false);
                            setIsFetching(false);
                        }}
                    >
                        Отменить
                    </DialogBtn>
                </FlexRowWrapper>
            </DialogWrapper>
        </Dialog>
    );
}
