import { WritableDraft } from 'immer/dist/internal';

import { TConsolidatedPricesFilterBodyValue } from '@/api/consolidatedPrices';

export const updateConsolidatedPricesFilterByAllMode = <
    Keys extends string,
    Value extends TConsolidatedPricesFilterBodyValue
>(
    state: WritableDraft<{
        [key in Keys]: Value;
    }>,
    key: Keys,
    checked: boolean,
    newValues: Value['values']
) => {
    const filter = state[key] as Value;

    if (checked) {
        filter.selectAll = true;
        filter.values = newValues;
        return;
    }

    filter.selectAll = false;
    filter.values = [];
};

export const updateConsolidatedPricesFilterBySingleMode = <
    Keys extends string,
    Value extends TConsolidatedPricesFilterBodyValue
>(
    state: WritableDraft<{
        [key in Keys]: Value;
    }>,
    key: Keys,
    checked: boolean,
    newValues: Value['values'],
    length: number
) => {
    const filter = state[key] as Value;
    const values = filter.values;

    if (checked) {
        values.push(...newValues);

        if (values.length === length) {
            filter.selectAll = true;
        }
        return;
    }

    if (values.length === length) {
        filter.selectAll = false;
    }

    const index = values.indexOf(newValues[0]);
    if (index > -1) {
        values.splice(index, 1);
    }
};
