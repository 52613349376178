import { TextField } from '@mui/material';

import { filtersSomSelector, setMaxDelivered, setMinDelivered } from '@/store/slices/filtersSomSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import s from '../CheckboxPopoverSufficiency.module.scss';

export default function FilterDeliveredEl() {
    const { delivered } = useTypedSelector(filtersSomSelector);

    const dispatch = useAppDispatch();

    return (
        <div className={s.inputs_wrapper}>
            <TextField
                id='min'
                label='От'
                size='small'
                variant='outlined'
                value={delivered.min}
                sx={{
                    backgroundColor: '#f6f7fb',
                    '.MuiInputLabel-root': { color: '#5C6E8C' },
                }}
                onChange={(e) => dispatch(setMinDelivered(e.target.value))}
            />
            <TextField
                id='max'
                label='До'
                size='small'
                variant='outlined'
                value={delivered.max}
                sx={{
                    backgroundColor: '#f6f7fb',
                    '.MuiInputLabel-root': { color: '#5C6E8C' },
                }}
                onChange={(e) => dispatch(setMaxDelivered(e.target.value))}
            />
        </div>
    );
}
