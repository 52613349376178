import React from 'react';
import { useParams } from 'react-router-dom';

import { EPageName } from '@/shared/constants/pages';

import { projectsSelector } from '@/store/slices/projectsSlice';
import { useTypedSelector } from '@/store/store';

import { BreadCrumbTitle } from './BreadCrumbTitle';
import ButtonTitleEl from './ButtonTitleEl';
import IconButtonEl from './IconButtonEl';
import { MyBreadCrumbs } from './MyBreadCrumbs';

export default function BreadcrumbsEl({
    projectTitle,
    setOpenPopoverLinks,
}: {
    projectTitle: string;
    setOpenPopoverLinks: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}) {
    const { pageName } = useTypedSelector(projectsSelector);
    const { projectId } = useParams();

    const routeTitles: Partial<Record<EPageName, React.ReactElement>> = {
        [EPageName.Projects]: (
            <MyBreadCrumbs>
                <BreadCrumbTitle>Все проекты</BreadCrumbTitle>
            </MyBreadCrumbs>
        ),
        [EPageName.ProjectsCharts]: (
            <MyBreadCrumbs>
                <BreadCrumbTitle>Все проекты</BreadCrumbTitle>
            </MyBreadCrumbs>
        ),
        [EPageName.Users]: (
            <MyBreadCrumbs>
                <BreadCrumbTitle>Пользователи</BreadCrumbTitle>
            </MyBreadCrumbs>
        ),
        [EPageName.UsersAdd]: (
            <>
                <IconButtonEl />
                <MyBreadCrumbs>
                    <BreadCrumbTitle>Пользователи</BreadCrumbTitle>
                    <BreadCrumbTitle>Управление пользователями</BreadCrumbTitle>
                </MyBreadCrumbs>
            </>
        ),
        [EPageName.UsersEdit]: (
            <>
                <IconButtonEl />
                <MyBreadCrumbs>
                    <BreadCrumbTitle>Пользователи</BreadCrumbTitle>
                    <BreadCrumbTitle>Управление пользователями</BreadCrumbTitle>
                </MyBreadCrumbs>
            </>
        ),
        [EPageName.Ksg]: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.Mgp]: (
            <ButtonTitleEl
                titles={[{ title: 'Укрупненные' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.Mofen]: (
            <ButtonTitleEl
                titles={[{ title: 'Укрупненные' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.Sm]: (
            <ButtonTitleEl
                titles={[{ title: 'Укрупненные' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.KsgPeople]: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }, { title: 'Персонал' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.KsgEquip]: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }, { title: 'Механизмы' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.KsgKc6a]: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }, { title: 'KC-6A' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.Msg]: (
            <ButtonTitleEl
                titles={[{ title: 'МСГ' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.MsgPeople]: (
            <ButtonTitleEl
                titles={[{ title: 'МСГ' }, { title: 'Персонал' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.MsgEquip]: (
            <ButtonTitleEl
                titles={[{ title: 'МСГ' }, { title: 'Механизмы' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.Executor]: <></>,
        [EPageName.ExecutorPeople]: <></>,
        [EPageName.ExecutorEquip]: <></>,
        [EPageName.ag_grid]: <></>,
        [EPageName.ag_grid_msg]: <></>,
        [EPageName.Mto]: (
            <ButtonTitleEl
                titles={[{ title: 'КСГ' }, { title: 'МТО' }]}
                setOpenPopoverLinks={setOpenPopoverLinks}
                projectTitle={projectTitle}
            />
        ),
        [EPageName.WorkManagement]: (
            <ButtonTitleEl
                titles={[{ title: 'Кабинет управления работой' }]}
                projectTitle={projectTitle}
                isProjectTitleNav
            />
        ),
        [EPageName.Brigades]: (
            <ButtonTitleEl
                titles={[{ title: 'Справочник бригад' }]}
                projectTitle={projectTitle}
                isProjectTitleNav
            />
        ),
        [EPageName.BrigadesAdd]: (
            <ButtonTitleEl
                titles={[
                    { title: 'Справочник бригад', link: `/brigades/${projectId}` },
                    { title: 'Добавление бригады' },
                ]}
                projectTitle={projectTitle}
                isProjectTitleNav
            />
        ),
        [EPageName.BrigadesEdit]: (
            <ButtonTitleEl
                titles={[
                    { title: 'Справочник бригад', link: `/brigades/${projectId}` },
                    { title: 'Добавление бригады' },
                ]}
                projectTitle={projectTitle}
                isProjectTitleNav
            />
        ),
    };

    return routeTitles[pageName || EPageName.Projects] || <></>;
}
