import NiceModal from '@ebay/nice-modal-react';
import { GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { TEstimatePositionsFilterKeys } from '@/api/estimatePositions';
import { TEstimatePosition, TEstimatePositionId } from '@/api/estimatePositions/estimatePositions.types';

import { ConfirmDialog } from '@/components/ConfirmDialog';

import { useAgGridLastRowInView } from '@/hooks/useAgGridLastRowInView';
import { useLatest } from '@/hooks/useLatest';

import { redrawAllRows } from '@/shared/utils/agGrid.utils';

import {
    estimatePositionsActions,
    estimatePositionsSelectors,
} from '@/store/slices/estimatePositions/estimatePositionsSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { columnsDefs } from './EstimatePositionsTable.columnDefs';
import { defaultColDef } from './EstimatePositionsTable.config';
import {
    useAckEstimatePositionsChangesMutation,
    useDeleteEstimatePositionByIdMutation,
    useGetEstimatePositions,
    useRowClassRules,
} from './EstimatePositionsTable.model';
import { TContext, TData, TEstimatePositionsTableProps, TSelectedPositionType } from './EstimatePositionsTable.types';
import {
    createCheckboxKey,
    getCheckboxesRange,
    isShiftKey,
    redrawRows,
    updateCheckboxesMap,
} from './EstimatePositionsTable.utils';
import { FilterDialog } from './components/FilterDialog';
import { Table } from './components/Table';

export const EstimatePositionsTable: React.FC<TEstimatePositionsTableProps> = () => {
    const gridRef = useRef<AgGridReact<TData>>(null);
    const { projectId } = useParams();

    const dispatch = useAppDispatch();

    const { mutate: mutateAckEstimatePositionsChanges } = useAckEstimatePositionsChangesMutation();
    const { mutate: mutateDeleteEstimatePositionById } = useDeleteEstimatePositionByIdMutation();

    const { inView: isLastRowInView, reset: resetInView } = useAgGridLastRowInView(gridRef.current?.api);

    const checkedPositionsMap = useTypedSelector(estimatePositionsSelectors.checkedPositionsMap);
    const checkedPositionId = useTypedSelector(estimatePositionsSelectors.checkedPositionId);
    const isNeedRedrawAllRows = useTypedSelector(estimatePositionsSelectors.redrawAllRows);
    const filters = useTypedSelector(estimatePositionsSelectors.filters);

    const { data, isLoading, fetchNextPage } = useGetEstimatePositions({
        projectId: Number(projectId),
    });

    useEffect(() => {
        if (!isLastRowInView) return;

        fetchNextPage().finally(resetInView);
    }, [isLastRowInView]);

    const rowData = useMemo(() => data?.positions ?? [], [data]);

    const latestCheckedPositionId = useLatest(checkedPositionId);
    const latestCheckedPositionsMap = useLatest(checkedPositionsMap);
    const latestRowData = useLatest(rowData);

    const rowClassRules = useRowClassRules();

    const handleCheckboxChange = useCallback(
        (id: TEstimatePositionId, type: TSelectedPositionType) =>
            (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                const range = getCheckboxesRange({
                    data: latestRowData.current,
                    id,
                    lastCheckedId: latestCheckedPositionId.current,
                    isShiftKey: isShiftKey(event),
                    type,
                });

                dispatch(
                    estimatePositionsActions.updateCheckedPositionsMap(
                        updateCheckboxesMap(latestCheckedPositionsMap.current, checked, id, range)
                    )
                );
                dispatch(estimatePositionsActions.setCheckedPositionId(id));
                redrawRows(gridRef.current?.api!, range);
            },
        []
    );

    const handleChangeAck = useCallback(
        (id: TEstimatePositionId) => async () => {
            mutateAckEstimatePositionsChanges({
                projectId: Number(projectId),
                body: {
                    positionIDs: [id],
                },
            });
        },
        []
    );

    useEffect(() => {
        if (!gridRef.current?.api) return;

        redrawAllRows(gridRef.current.api);
    }, [rowData, isNeedRedrawAllRows]);

    const getRowId = useCallback(({ data }: GetRowIdParams<TData>) => {
        return data?.id.toString();
    }, []);

    const handleFilterVisible = useCallback(
        (key: TEstimatePositionsFilterKeys) => () => {
            NiceModal.show(FilterDialog, {
                filterKey: key,
                projectId: Number(projectId),
            });
        },
        []
    );

    const handleDelete = useCallback(
        (position: TEstimatePosition) => () => {
            NiceModal.show(ConfirmDialog, {
                title: 'Вы уверены что хотите удалить позицию?',
                body: `После удаления позиции №${position.ordinal}, эти данные будут безвозвратно утеряны.`,
                onSuccess: () =>
                    mutateDeleteEstimatePositionById({
                        projectId: Number(projectId),
                        id: position.id,
                    }),
            });
        },
        []
    );

    const context: TContext = useMemo(() => {
        return {
            filters,
            checkboxesSet: new Set(
                Array.from(checkedPositionsMap.values()).map((v) => createCheckboxKey(v.id, v.selectType))
            ),
            onCheckboxChange: handleCheckboxChange,
            onChangeAck: handleChangeAck,
            onFilterVisible: handleFilterVisible,
            onDelete: handleDelete,
        };
    }, [filters, checkedPositionsMap, handleCheckboxChange]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        gridRef?.current?.api?.refreshHeader();
    }, [filters]);

    return (
        <Table
            isLoading={isLoading}
            rowClassRules={rowClassRules}
            getRowId={getRowId}
            gridRef={gridRef}
            rowData={rowData}
            columnDefs={columnsDefs}
            context={context}
            defaultColDef={defaultColDef}
            headerHeight={40}
            rowHeight={64}
            overlayNoRowsTemplate='Нет данных'
        />
    );
};
