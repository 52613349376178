import { Work } from '../../../../api/ksg/ksg.types';
import { COLORS, TColors } from '../PercentStatusValue/PercentStatusValue.style';
import { StatItemTitle } from '../StatItem/StatItem.styles';
import { RDStatusBar, RDStatusWrapper } from './RDStatus.style';

interface IRDStatusProps {
    rdStatus: Work['rdStatus'];
}

type IColorStatus = {
    [key in keyof typeof COLORS]: Work['rdStatus'][];
};

export const COLOR_STATUS: IColorStatus = {
    error: ['Не в работе'],
    warning: ['На согласовании', 'Разработка', 'Корректировка'],
    default: ['Согласовано', 'В производстве'],
};

export function getRDColorStatus(rdStatus: Work['rdStatus'], colors: IColorStatus) {
    let targetColor: TColors = 'default';
    for (let key in colors) {
        if (colors[key as TColors].includes(rdStatus)) {
            targetColor = key as TColors;
            break;
        }
    }
    return targetColor;
}

export function RDStatus({ rdStatus }: IRDStatusProps) {
    return (
        <RDStatusWrapper color={getRDColorStatus(rdStatus, COLOR_STATUS)}>
            <RDStatusBar color={getRDColorStatus(rdStatus, COLOR_STATUS)} />
            <StatItemTitle
                size='small'
                fontSize={'10px !important'}
                textTransform={'uppercase'}
            >
                {rdStatus}
            </StatItemTitle>
        </RDStatusWrapper>
    );
}
