import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';

import { TCreateConsolidatedPricesBody, useCreateConsolidatedPriceMutation } from '@/api/consolidatedPrices';
import { TEstimatePositionId, TEstimatePositionUnitType } from '@/api/estimatePositions';

import { ConfirmDialog } from '@/components/ConfirmDialog';
import {
    calculateTotalQuantityForUnitType,
    ConsolidatedPriceDialog,
    consolidatedPriceValidationSchema,
    getDefaultFormValues,
    getEditedFormValues,
    getFormValues,
    getPositionsByUnitDifference,
    getRowData,
} from '@/components/ConsolidatedPriceDialog';
import { TEditedFields, TFormValues } from '@/components/ConsolidatedPriceDialog/ConsolidatedPriceDialog.types';
import { unitTypeEnToRu } from '@/components/ConsolidatedPriceDialog/components/Form/Form.config';

import { useLatest } from '@/hooks/useLatest';

import { EPageName } from '@/shared/constants/pages';
import { queryClient } from '@/shared/constants/queryClient';
import { queryKeys } from '@/shared/constants/queryKeys';
import { getFormatNumber } from '@/shared/utils/formatNumber.utils';

import { estimatePositionsActions, TEstimateCheckedPositionMapValue } from '@/store/slices/estimatePositions';
import { useAppDispatch } from '@/store/store';

import { Nullable } from '@/types/common.types';

import { useGetPositionsBySelectType } from '../../EstimatePositionsTable.model';
import { TSelectedPositionType } from '../../EstimatePositionsTable.types';

interface IProps {
    projectId: number;
    navigate: NavigateFunction;
}

export const CreateDialog = NiceModal.create<IProps>((props) => {
    const { visible, remove } = useModal();
    const [primaryPositionsData, secondaryPositionsData] = useGetPositionsBySelectType({
        projectId: props.projectId,
    });
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: mutateCreateConsolidatedPrice, isPending } = useCreateConsolidatedPriceMutation({
        onSuccess: () => {
            handleConfirmClose();
            enqueueSnackbar('Укрупненная расценка создана', {
                variant: 'success',
            });
            props.navigate(`/${EPageName.Mofen}/${props.projectId}`);

            Promise.all([
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.list],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.filter],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.tabCounters],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.consolidatedPrices.list],
                }),
            ]);
        },
        onError: () => {
            enqueueSnackbar('Произошла ошибка при создании укрупненной расценки', {
                variant: 'error',
            });
        },
    });

    const [primaryPositions, setPrimaryPositions] = useState<TEstimateCheckedPositionMapValue[]>(primaryPositionsData);
    const [secondaryPositions, setSecondaryPositions] =
        useState<TEstimateCheckedPositionMapValue[]>(secondaryPositionsData);
    const [mainPositionId, setMainPositionId] = useState<Nullable<TEstimatePositionId>>(
        primaryPositionsData.length === 1 ? primaryPositionsData[0]?.id : null
    );

    const [editedFields, setEditedFields] = useState<Set<TEditedFields>>(new Set());

    const latestMainPosition = useLatest(mainPositionId);
    const latestPrimaryPositions = useLatest(primaryPositions);
    const latestSecondaryPositions = useLatest(secondaryPositions);
    const latestEditedFields = useLatest(editedFields);

    const methods = useForm<TFormValues>({
        defaultValues: getDefaultFormValues(),
    });

    const consolidatedName = useWatch({ control: methods.control, name: 'consolidatedName' });
    const rawUnit = useWatch({ control: methods.control, name: 'rawUnit' });
    const quantity = useWatch({ control: methods.control, name: 'quantity' });

    const [isValid, setIsValid] = useState(true);

    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        handleValidation();
    }, [mainPositionId, rawUnit, consolidatedName, quantity]);

    const handleSetMainPosition = useCallback((id: TEstimatePositionId) => {
        setMainPositionId(id);
        setIsDirty(true);
    }, []);

    const setQuantityFormValueByRawUnit = (
        unitType: TEstimatePositionUnitType,
        primaryPositions: TEstimateCheckedPositionMapValue[] = []
    ) => {
        const filteredPositions = primaryPositions.filter((pos) => pos.unit?.type === unitType);
        const quantity = getFormatNumber({
            value: calculateTotalQuantityForUnitType(filteredPositions),
        }) as string;
        methods.setValue('quantity', quantity);
    };

    const updateEditedFields = useCallback(
        (field: TEditedFields) => (value: string | TEstimatePositionUnitType) => {
            setIsDirty(true);

            if (field === 'rawUnit' && !latestEditedFields.current.has('quantity')) {
                setQuantityFormValueByRawUnit(value as TEstimatePositionUnitType, latestPrimaryPositions.current);
            }

            const isEdit = Boolean(value);
            if (isEdit) {
                setEditedFields((prev) => new Set(prev).add(field));
                return;
            }

            setEditedFields((prev) => {
                const set = new Set(prev);
                set.delete(field);
                return set;
            });
        },
        []
    );

    const handleDeleteRow = useCallback((id: TEstimatePositionId, selectType: TSelectedPositionType) => {
        if (selectType === 'primary') {
            if (latestPrimaryPositions.current.length === 1) {
                enqueueSnackbar('Должна быть минимум одна основная позиция', {
                    variant: 'error',
                });
                return;
            }

            setPrimaryPositions((prev) => prev.filter((v) => v.id !== id));
            setIsDirty(true);

            if (id === latestMainPosition.current) {
                setMainPositionId(null);
            }
            return;
        }

        setSecondaryPositions((prev) => prev.filter((v) => v.id !== id));
        setIsDirty(true);
    }, []);

    const positionsByUnitDifference = useMemo(() => {
        return getPositionsByUnitDifference(primaryPositions, rawUnit);
    }, [primaryPositions, rawUnit]);

    const rowData = useMemo(() => {
        return getRowData(primaryPositions, secondaryPositions, positionsByUnitDifference);
    }, [primaryPositions, secondaryPositions, positionsByUnitDifference]);

    useEffect(() => {
        const editedFormValues = getEditedFormValues(mainPositionId, primaryPositions, latestEditedFields.current);
        const formValues = getFormValues(primaryPositions, secondaryPositions);

        for (const [key, value] of Object.entries({
            ...editedFormValues,
            ...formValues,
        })) {
            methods.setValue(key as keyof TFormValues, value);
        }
    }, [primaryPositions, secondaryPositions, mainPositionId]);

    const handleSubmit = useCallback(async () => {
        const formValues = methods.getValues();

        try {
            const validatedData = await consolidatedPriceValidationSchema.validate({
                ...formValues,
                rawUnit: formValues.rawUnit ? unitTypeEnToRu[formValues.rawUnit] : null,
                positionIDs: {
                    main: latestMainPosition.current,
                    primary: latestPrimaryPositions.current.map((v) => v.id),
                    secondary: latestSecondaryPositions.current.map((v) => v.id),
                },
            });
            mutateCreateConsolidatedPrice({
                projectId: props.projectId,
                body: validatedData as TCreateConsolidatedPricesBody,
            });
        } catch (error) {
            console.log('error', error);
        }
    }, []);

    const handleValidation = async () => {
        try {
            const formValues = methods.getValues();

            await consolidatedPriceValidationSchema.validate({
                ...formValues,
                rawUnit: formValues.rawUnit ? unitTypeEnToRu[formValues.rawUnit] : null,
                positionIDs: {
                    main: latestMainPosition.current,
                    primary: latestPrimaryPositions.current.map((v) => v.id),
                    secondary: latestSecondaryPositions.current.map((v) => v.id),
                },
            });

            setIsValid(true);
        } catch (error) {
            setIsValid(false);
        }
    };

    const handleConfirmClose = () => {
        dispatch(estimatePositionsActions.reset());
        remove();
    };

    const handleClose = useCallback(() => {
        isDirty ? NiceModal.show(ConfirmDialog, { onSuccess: handleConfirmClose }) : handleConfirmClose();
    }, [isDirty]);

    const isSaveButtonDisabled = !isValid || isPending;

    return (
        <FormProvider {...methods}>
            <ConsolidatedPriceDialog
                mode='create'
                rowData={rowData}
                mainPositionId={mainPositionId}
                isVisible={visible}
                isSaveButtonDisabled={isSaveButtonDisabled}
                positionsByUnitDifference={positionsByUnitDifference}
                onSubmit={handleSubmit}
                onClose={handleClose}
                updateEditedFields={updateEditedFields}
                onDeleteRow={handleDeleteRow}
                onSetMainPosition={handleSetMainPosition}
            />
        </FormProvider>
    );
});
