import NiceModal from '@ebay/nice-modal-react';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { EditDialog } from '@/pages/ConsolidatedPricesTable/components/EditDialog';

import { consolidatedPricesActions, consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { CancelButton, Container, CreateButton } from './ConsolidatedPricesControls.styles';
import { TConsolidatedPricesControlsProps } from './ConsolidatedPricesControls.types';

export const ConsolidatedPricesControls: FC<TConsolidatedPricesControlsProps> = () => {
    const { projectId } = useParams();
    const dispatch = useAppDispatch();

    const editConsolidatedPriceId = useTypedSelector(consolidatedPricesSelectors.editConsolidatedPriceId);
    const estimatePositionsCheckedPositionsMap = useTypedSelector(
        consolidatedPricesSelectors.estimatePositionsCheckedPositionsMap
    );
    const estimatePositionsCheckedPositionsMapForEdit = useTypedSelector(
        consolidatedPricesSelectors.estimatePositionsCheckedPositionsMapForEdit
    );

    const handleCancel = () => {
        dispatch(consolidatedPricesActions.resetEstimatePositions());
        dispatch(consolidatedPricesActions.setEditDialogMode('edit'));
        NiceModal.show(EditDialog, {
            projectId: Number(projectId),
            consolidatedPriceId: editConsolidatedPriceId!,
        });
    };

    const handleAdd = () => {
        const map = new Map(estimatePositionsCheckedPositionsMapForEdit);

        estimatePositionsCheckedPositionsMap.forEach((value, key) => {
            map.set(key, value);
        });
        dispatch(consolidatedPricesActions.updateEstimatePositionsCheckedPositionsMapForEdit(map));
        dispatch(consolidatedPricesActions.resetEstimatePositions());
        dispatch(consolidatedPricesActions.setEditDialogMode('edit'));

        NiceModal.show(EditDialog, {
            projectId: Number(projectId),
            consolidatedPriceId: editConsolidatedPriceId!,
        });
    };

    return (
        <Container>
            <CancelButton onClick={handleCancel}>Отмена</CancelButton>
            <CreateButton
                disabled={estimatePositionsCheckedPositionsMap.size === 0}
                onClick={handleAdd}
            >
                Добавить
            </CreateButton>
        </Container>
    );
};
