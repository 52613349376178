import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { toggleUploadKsgEquip, toggleUploadKsgPeople } from './drawersSlice';

interface initialStateI {
    month: number;
    year: number;
}

const initialState: initialStateI = {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
};

const slice = createSlice({
    name: 'monthMsg',
    initialState,
    reducers: {
        monthUpMsg(state) {
            if (state.month === 11) {
                state.month = 0;
                state.year += 1;
            } else {
                state.month += 1;
            }
        },

        monthDownMsg(state) {
            if (state.month === 0) {
                state.month = 11;
                state.year -= 1;
            } else {
                state.month -= 1;
            }
        },
        setMonthTerm(state, action: PayloadAction<initialStateI>) {
            state.month = action.payload.month;
            state.year = action.payload.year;
        },
    },
    extraReducers: (builder) => {
        return (
            builder.addCase(toggleUploadKsgEquip, () => {
                return initialState;
            }),
            builder.addCase(toggleUploadKsgPeople, () => {
                return initialState;
            })
        );
    },
});

export const { monthUpMsg, monthDownMsg, setMonthTerm } = slice.actions;
export default slice.reducer;

export const monthMsgSelector = (state: RootState) => state.monthMsg;
