import { ArrowBack } from '@mui/icons-material';
import DownloadingSharpIcon from '@mui/icons-material/DownloadingSharp';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import { GetRowIdParams, RowClassParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api';
import { Work, WorkOrWorkPosition } from '@/api/ksg/ksg.types';

import EmptyPlaceholder from '@/shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { IntegrationWithOfferSearchParam } from '@/shared/constants/integrationWithOffer';
import useProjectUploadProgress from '@/shared/hooks/useProjectUploadProgress';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { modalStyles } from '@/shared/utils';
import { hasParam } from '@/shared/utils/common.utils';

import { agGridKsgSelector, agGridListMode } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { IGetParamsKSG, getWorksAgGrid, getWorksAgGridOrphan } from '@/store/slices/agGridKsgMsgSlices/ksgThunks';
import { authSelector } from '@/store/slices/authSlice';
import { drawersSelector, setAgGrid, toggleUploadKsg, toggleUploadProgress } from '@/store/slices/drawersSlice';
import { filtersSelector } from '@/store/slices/filtersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import CheckboxPopover from '../../components/CheckboxPopover/CheckboxPopover';
import GanttRelations from '../../components/GanttRelations/GanttRelations';
import { useToggleSearchParam } from '../../hooks/useToggleSearchParam';
import { useVerticalPagination } from '../../hooks/useVerticalPagination';
import { useGetProject } from '../../layouts/TableLayout/KsgLayout.model';
import { AG_GRID_DEFAULT_PARAMS_KSG } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { KSGColDefs } from './AgGrid.colDef';
import './AgGrid.scss';
import { transformWorkPositionToWork } from './AgGrid.service';

/* константа лимита для запросов к базе */
export const LIMIT = 1000;

// TODO:
// 1. Удалить
export default function AgGrid() {
    const [hiddenRowsIds, setHiddenRowsIds] = useImmer<{ id: number; code: string }[]>([]);
    const [mutationsLoading, setMutationsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [_isOpenAddLevel, setIsOpenAddLevel] = useState(false);
    const [_addLevelData, setAddLevelData] = useState<{
        id: number | null;
        index: number;
        level: number | null;
    }>({
        id: 0,
        index: 0,
        level: null,
    });
    const [relationsOpened, setRelationsOpened] = useState<boolean>(false);
    const [relationData, setRelationData] = useState<null | {
        workName: string;
        startDate: string | null;
        endDate: string | null;
        id: number;
    }>(null);

    const { filters } = useTypedSelector(filtersSelector);
    const { worksList, isKSGUpdatedByExcel, isKSGUploaded } = useTypedSelector(agGridKsgSelector);
    const { token } = useTypedSelector(authSelector);
    const { profile } = useTypedSelector(profileSelector);
    const navigate = useNavigate();
    const listMode = useTypedSelector(agGridListMode);
    const location = useLocation();
    const { projectId } = useParams();
    const { isActive } = useToggleSearchParam(IntegrationWithOfferSearchParam);
    const project = useGetProject(Number(projectId));
    const isOfferActive = project?.isImportFromOffer && isActive;

    /* Данные с стора для базового плана */

    const { syncModuleData, triggerIfBackupRestored, uploadKsg, updateKSG } = useTypedSelector(drawersSelector);

    const dispatch = useAppDispatch();

    const gridRef = useRef<AgGridReact>(null);

    if (gridRef?.current) {
        dispatch(setAgGrid(gridRef?.current));
    }

    const [urlSearchParams] = useSearchParams();

    const [indexClick, setIndexClick] = useImmer<{ month: number; work: number } | null>(null);

    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
    });

    const isKSGCreated = !!headersCPG?.data?.isCreatedKSG;

    const { activeTask, setTriggerUpdateStatus } = useProjectUploadProgress({
        supressOnClose: !isKSGCreated,
        supressWatchBtn: !isKSGCreated,
    });

    useEffect(() => {
        !activeTask && setTriggerUpdateStatus(true);
    }, [activeTask, headersCPG?.data]);

    useEffect(() => {
        setTriggerUpdateStatus(!uploadKsg);
    }, [uploadKsg]);

    useEffect(() => {
        setTriggerUpdateStatus(!updateKSG);
    }, [updateKSG]);

    // useEffect(() => {
    //     if (!result && !!activeTask && headersCPG?.data) {
    //         dispatch(
    //             toggleUploadProgress({
    //                 openDialog: !!activeTask,
    //                 supressOnClose: !headersCPG?.data.isCreatedKSG,
    //                 supressWatchBtn: !headersCPG?.data.isCreatedKSG,
    //             })
    //         )
    //     }
    //     if (!!result) {
    //         dispatch(
    //             toggleUploadProgress({
    //                 openDialog: false,
    //                 supressOnClose: false,
    //                 supressWatchBtn: false,
    //             })
    //         )
    //         dispatch(onKSGUpload())
    //     }
    // }, [activeTask, result, headersCPG?.data])

    useEffect(() => {
        return () => {
            setTriggerUpdateStatus(false);
            dispatch(
                toggleUploadProgress({
                    openDialog: false,
                    supressOnClose: false,
                    supressWatchBtn: false,
                    activeTask: undefined,
                })
            );
            dispatch(setAgGrid(null));
        };
    }, [location.pathname, projectId]);

    useEffect(() => {
        mutationsLoading && console.info('loading');
    }, [mutationsLoading]);

    /* возвращает сразу массив данных учитывая фильтры либо null */
    const [filteredWorks, setFilterWorks] = useState<Work[] | null>([]);

    const data = useMemo(() => {
        if (!isOfferActive) return filteredWorks;

        return filteredWorks?.flatMap((item) => {
            if (!item.workPosition) return item;

            if (!hiddenRowsIds.some((v) => isEqual(v, { id: item.id, code: item.code }))) {
                return item;
            }

            return [item, ...item?.workPosition?.map((position) => transformWorkPositionToWork(item, position))];
        });
    }, [filteredWorks, isOfferActive]) as WorkOrWorkPosition[];

    useEffect(() => {
        setFilterWorks(() => {
            if (worksList) {
                const filtArr = worksList?.data.reduce((acc: Work[], prev) => {
                    const idx = hiddenRowsIds.every((filItem) => {
                        if (prev.code === filItem.code) {
                            return true;
                        }

                        return !filItem.code.split('-').every((v) => prev.code.split('-').includes(v));
                    });

                    return idx ? [...acc, prev] : acc;
                }, []);
                // if (!filtArr?.length || !worksList.data.length) {
                //     gridRef.current?.api?.showNoRowsOverlay()
                // }
                return filtArr?.length ? filtArr : worksList.data;
            } else {
                return null;
            }
        });
        return () => {
            setFilterWorks(null);
        };
    }, [hiddenRowsIds, urlSearchParams, worksList]);

    const getHiddenIds = (): { id: number; code: string }[] | undefined => {
        return worksList?.data
            .filter((work) => !filteredWorks?.find((v) => v.id === work.id))
            ?.map((w) => {
                return {
                    id: w.id,
                    code: w.code,
                };
            });
    };

    // PAGINATION
    /**
     * устанавливается нужный экшен по условию, для диспатча события внутри useVerticalPagination
     */
    const getData = useCallback(
        (params: IGetParamsKSG) => {
            const sendingFunc = listMode ? getWorksAgGridOrphan : getWorksAgGrid;
            gridRef.current?.api?.showLoadingOverlay();
            return sendingFunc(params);
        },
        [listMode]
    );

    /**
     * общее количество записей в БД
     */
    const [totalRows, setTotalRows] = useState(0);

    /**
     * Эффект следит за изменением projectId
     * и используя Api AgGrid управляет оверлэем таблицы
     * если workList не null отрисовывает таблицу,
     * в ином случае показывает что идет загрузка
     */
    useEffect(() => {
        if (worksList) {
            setTotalRows(worksList.total);
            // worksList?.activeUpload && setTriggerUpdateStatus(true)
        }
    }, [worksList]);

    /**
     *  добавил в массив resetToInitialDeps зависимость от projectId
     *  чтобы при смене проекта был перезапрос за данными
     */

    const scrollParams = JSON.parse(localStorage.getItem('scrollParams')!);
    const lastRenderedRow = scrollParams && scrollParams[location.pathname]?.lastRenderedRow;

    const { scrollHandlerFoAG } = useVerticalPagination<IGetParamsKSG>({
        initialParams: {
            limit: lastRenderedRow ? Math.ceil(lastRenderedRow / LIMIT) * LIMIT : LIMIT,
            offset: 0,
            id: Number(projectId),
        },
        sendFn: getData,
        thenFn: () => {
            gridRef.current?.api?.hideOverlay();
        },
        catchFn: () => {
            gridRef.current?.api?.hideOverlay();
        },
        totalCount: totalRows,
        requiredDeps: [projectId, totalRows !== null, token],
        resetToInitialDeps: [
            filters,
            listMode,
            projectId,
            isKSGUpdatedByExcel,
            isKSGUploaded,
            syncModuleData,
            triggerIfBackupRestored,
        ],
    });

    //==========================

    // /**
    //  * Эффект следит за сменой проекта
    //  * при смене очищаются workList
    //  * и очищаются примененные фильтры
    //  */
    // useEffect(() => {
    //     // if (!projectId) {
    //         dispatch(refreshFilters())
    //         dispatch(clearWorks())
    //         dispatch(changeListMode(false))
    //     // }
    //     // }
    //     //eslint-disable-next-line
    // }, [projectId])

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params?.data?.id;
    }, []);

    const locationSearch = location.search;
    /**
     * TODO
     * Старая функция перенесенная со старой таблицы ганта
     * Требует пересмотра и прокачки
     */
    // eslint-disable-next-line
    const handleClickChart = useCallback(
        (idx: number, work: Work) => {
            if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_LINE_GANT_SMR')) {
                return;
            }
            const newWork = { ...work, monthlyCharts: [...work.monthlyCharts] };
            if (work.volumeTotal === null && profile.role !== 'client' && hasParam('g', locationSearch)) {
                return;
            }
            if (indexClick === null) {
                setIndexClick({ month: idx, work: work.id });
            } else {
                setIndexClick(null);
                const min = idx < indexClick.month ? idx : indexClick.month;
                const max = idx > indexClick.month ? idx : indexClick.month;
                for (let i = min; i <= max; i++) {
                    setIndexClick(null);
                    // if (!newWork.monthlyCharts) return
                    if (newWork.monthlyCharts[indexClick.month].drawPlan || newWork.monthlyCharts[idx].drawPlan) {
                        setFilterWorks((prevState) => {
                            if (!prevState) return prevState;
                            return prevState.map((_) =>
                                _.id === work.id
                                    ? {
                                          ..._,
                                          monthlyCharts: _.monthlyCharts.map((month, monthIndex) =>
                                              monthIndex === i ? { ...month, drawPlan: false } : month
                                          ),
                                      }
                                    : _
                            );
                        });
                    } else {
                        setFilterWorks((prevState) => {
                            if (!prevState) return prevState;
                            return prevState.map((_) =>
                                _.id === work.id
                                    ? {
                                          ..._,
                                          monthlyCharts: _.monthlyCharts.map((month, monthIndex) =>
                                              monthIndex === i ? { ...month, drawPlan: true } : month
                                          ),
                                      }
                                    : _
                            );
                        });
                    }
                }
            }
        },
        //eslint-disable-next-line
        [indexClick, profile.role, locationSearch]
    );
    const getRowClass = useCallback((params: RowClassParams<Work, any>) => {
        const stylesByLevel = {
            0: 'level_zero',
            1: 'level_one',
            2: 'level_two',
            3: 'level_three',
            4: 'level_four',
            5: 'level_five',
            6: 'level_six',
            7: 'level_seven',
        };

        return params.data?.hasChildren
            ? stylesByLevel[params.data?.level as keyof typeof stylesByLevel]
            : stylesByLevel[7];
    }, []);

    useEffect(() => {
        const container = document.createElement('span');
        container.style.position = 'absolute';
        container.style.right = '0';
        container.style.top = '0';
        container.style.zIndex = '3';

        const lines = gridRef.current && hasParam('r', locationSearch) && ReactDOM.createRoot(container!);
        if (gridRef.current && hasParam('r', locationSearch) && lines) {
            const reactElement = null;

            lines.render(reactElement);
            const targetNode = document.querySelector('.ag-center-cols-container');

            targetNode?.appendChild(container);
            gridRef.current.forceUpdate(() => {});
        } else {
            lines && lines.unmount();
        }
        return () => (lines ? lines.unmount() : undefined);
    }, [filteredWorks, locationSearch, headersCPG?.data?.allHeaders, location.search, getHiddenIds]);

    useEffect(() => {
        headersCPG.refetch();
    }, [isKSGUploaded]);

    if (!headersCPG.data?.isCreatedKSG) {
        if (headersCPG.isFetching) {
            return <CircularProgress />;
        }
        if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_KSG_SMR')) {
            return (
                <EmptyPlaceholder
                    text={
                        <Typography>
                            Здравствуйте, {profile?.firstName}, КСГ еще не был создан. <br />
                            Создание КСГ не доступно для пользователей с Вашими правами. <br /> Обратитесь к
                            администратору
                        </Typography>
                    }
                    onClick={() => navigate(-1)}
                    icon={<ArrowBack />}
                    buttonText='Назад'
                />
            );
        }
        return (
            <EmptyPlaceholder
                text={`Здравствуйте, ${profile?.firstName}, у вас еще нет целевого план-графика реализации проекта.
Давайте создадим ваш первый КСГ.`}
                onClick={() => dispatch(toggleUploadKsg())}
                icon={<DownloadingSharpIcon />}
                buttonText='загрузить'
            />
        );
    }

    return (
        <>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Box sx={{ ...modalStyles, width: 373 }}>
                    <CheckboxPopover />
                </Box>
            </Modal>
            <Modal
                open={relationsOpened}
                onClose={() => {
                    setRelationsOpened(false);
                    setRelationData(null);
                }}
            >
                <span>
                    <GanttRelations
                        setRelationsOpened={setRelationsOpened}
                        increaseRelationsCount={() => {}}
                        initialWorkData={relationData!}
                    />
                </span>
            </Modal>

            <div
                className='ag-theme-alpine'
                style={{ width: '100%', paddingRight: '0.5rem' }}
                onClickCapture={(e) => {
                    if (!!activeTask) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                }}
            >
                <AgGridReact
                    ref={gridRef}
                    onBodyScrollEnd={scrollHandlerFoAG}
                    getRowId={getRowId}
                    getRowClass={getRowClass}
                    rowData={data}
                    columnDefs={KSGColDefs({
                        setAddLevelData: setAddLevelData,
                        setIsOpenAddLevel: setIsOpenAddLevel,
                        location: location,
                        profile: profile,
                        hiddenRowsIds: hiddenRowsIds,
                        setHiddenRowsIds: setHiddenRowsIds,
                        gridRef: gridRef,
                        setRelationsOpened: setRelationsOpened,
                        setRelationData: setRelationData,
                        isVisibleGantt: false,
                        headersCPG: headersCPG,
                        costDoneMode: false,
                        setMutationsLoading: setMutationsLoading,
                        isOfferActive: isOfferActive,
                        refetchCollectionOfFactOfAct: () => {},
                        columnWidths: new Map(),
                    })}
                    {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                    onCellClicked={(params) => {
                        params.event?.preventDefault();
                        params.event?.stopImmediatePropagation();
                    }}
                    {...((!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_KSG') || !!activeTask) && {
                        suppressClickEdit: true,
                    })}
                ></AgGridReact>
            </div>
        </>
    );
}
