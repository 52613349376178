import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import {
    getConsolidatedPriceQueryOptions,
    TConsolidatedPriceId,
    TCreateConsolidatedPricesBody,
} from '@/api/consolidatedPrices';
import { TEstimatePositionId, TEstimatePositionUnitType } from '@/api/estimatePositions';

import { ConfirmDialog } from '@/components/ConfirmDialog';
import {
    calculateTotalQuantityForUnitType,
    ConsolidatedPriceDialog,
    consolidatedPriceValidationSchema,
    getEditedFormValues,
    getFormValues,
    getPositionsByUnitDifference,
    getRowData,
} from '@/components/ConsolidatedPriceDialog';
import { TEditedFields, TFormValues } from '@/components/ConsolidatedPriceDialog/ConsolidatedPriceDialog.types';
import { unitTypeEnToRu } from '@/components/ConsolidatedPriceDialog/components/Form/Form.config';

import { useLatest } from '@/hooks/useLatest';

import { TSelectedPositionType } from '@/pages/EstimatePositionsTable/EstimatePositionsTable.types';

import { getFormatNumber } from '@/shared/utils/formatNumber.utils';

import { consolidatedPricesActions, consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { TEstimateCheckedPositionMapValue } from '@/store/slices/estimatePositions';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { Nullable } from '@/types/common.types';

import { useGetPositionsBySelectType } from '../EstimatePositionsTable/EstimatePositionsTable.model';
import { useDeleteConsolidatedPrice, useUpdateConsolidatedPrice } from './EditDialog.model';
import { getDefaultFormValues, getEstimatePositions } from './EditDialog.utils';

interface IProps {
    projectId: number;
    consolidatedPriceId: TConsolidatedPriceId;
}

export const EditDialog = NiceModal.create<IProps>((props) => {
    const { visible, remove, hide } = useModal();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const handleConfirmClose = () => {
        remove();
        dispatch(consolidatedPricesActions.setEditDialogMode('hide'));
        dispatch(consolidatedPricesActions.setEditConsolidatedPriceId(null));
        dispatch(consolidatedPricesActions.resetEstimatePositions());
        dispatch(consolidatedPricesActions.updateEstimatePositionsCheckedPositionsMapForEdit(new Map()));
    };

    const { data, isLoading } = useQuery({
        ...getConsolidatedPriceQueryOptions({ projectId: props.projectId, id: props.consolidatedPriceId }),
    });

    const { mutate: mutateDeleteConsolidatedPrice } = useDeleteConsolidatedPrice({
        projectId: props.projectId,
        consolidatedId: props.consolidatedPriceId,
        onClose: handleConfirmClose,
    });

    const { mutate: mutateUpdateConsolidatedPrice, isPending } = useUpdateConsolidatedPrice({
        projectId: props.projectId,
        consolidatedId: props.consolidatedPriceId,
        onClose: handleConfirmClose,
    });

    const [primaryPositionsData, secondaryPositionsData] = useGetPositionsBySelectType({
        projectId: props.projectId,
    });

    const estimatePositionsCheckedPositionsMapForEdit = useTypedSelector(
        consolidatedPricesSelectors.estimatePositionsCheckedPositionsMapForEdit
    );

    const [mainPositionId, setMainPositionId] = useState<Nullable<TEstimatePositionId>>(null);
    const [editedFields, setEditedFields] = useState<Set<TEditedFields>>(new Set());
    const [isValid, setIsValid] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const [deletedIds, setDeletedIds] = useState<Set<TEstimatePositionId>>(new Set());

    const primaryPositions = useMemo(() => {
        if (!data) return [];

        const { primaryPositionsData: primaryPositions } = getEstimatePositions(data);

        return [...primaryPositions, ...primaryPositionsData].filter((position) => !deletedIds.has(position.id));
    }, [data, primaryPositionsData, deletedIds]);

    const secondaryPositions = useMemo(() => {
        if (!data) return [];

        const { secondaryPositionsData: secondaryPositions } = getEstimatePositions(data);

        return [...secondaryPositions, ...secondaryPositionsData].filter((position) => !deletedIds.has(position.id));
    }, [data, secondaryPositionsData, deletedIds]);

    const isSaveButtonDisabled = !isDirty || !isValid || isPending;

    const latestMainPosition = useLatest(mainPositionId);
    const latestPrimaryPositions = useLatest(primaryPositions);
    const latestSecondaryPositions = useLatest(secondaryPositions);
    const latestEditedFields = useLatest(editedFields);
    const latestEstimatePositionsCheckedPositionsMapForEdit = useLatest(estimatePositionsCheckedPositionsMapForEdit);

    const methods = useForm<TFormValues>({});

    useEffect(() => {
        if (!data) return;

        const { mainPositionIdData } = getEstimatePositions(data);
        setMainPositionId(mainPositionIdData);

        const formValues = getDefaultFormValues(data);

        for (const [key, value] of Object.entries(formValues)) {
            methods.setValue(key as keyof TFormValues, value ?? '');
        }
    }, [data]);

    useEffect(() => {
        if (estimatePositionsCheckedPositionsMapForEdit.size === 0) return;

        setIsDirty(true);
    }, [estimatePositionsCheckedPositionsMapForEdit]);

    const consolidatedName = useWatch({ control: methods.control, name: 'consolidatedName' });
    const rawUnit = useWatch({ control: methods.control, name: 'rawUnit' });
    const quantity = useWatch({ control: methods.control, name: 'quantity' });

    useEffect(() => {
        handleValidation();
    }, [mainPositionId, rawUnit, consolidatedName, quantity]);

    const handleSetMainPosition = useCallback((id: TEstimatePositionId) => {
        setMainPositionId(id);
        setIsDirty(true);
    }, []);

    const setQuantityFormValueByRawUnit = (
        unitType: TEstimatePositionUnitType,
        primaryPositions: TEstimateCheckedPositionMapValue[] = []
    ) => {
        const filteredPositions = primaryPositions.filter((pos) => pos.unit?.type === unitType);
        const quantity = getFormatNumber({
            value: calculateTotalQuantityForUnitType(filteredPositions),
        }) as string;
        methods.setValue('quantity', quantity);
    };

    const updateEditedFields = useCallback(
        (field: TEditedFields) => (value: string | TEstimatePositionUnitType) => {
            setIsDirty(true);
            if (field === 'rawUnit' && !latestEditedFields.current.has('quantity')) {
                setQuantityFormValueByRawUnit(value as TEstimatePositionUnitType, latestPrimaryPositions.current);
            }
            const isEdit = Boolean(value);
            if (isEdit) {
                setEditedFields((prev) => new Set(prev).add(field));
                return;
            }
            setEditedFields((prev) => {
                const set = new Set(prev);
                set.delete(field);
                return set;
            });
        },
        []
    );

    const handleDeleteRow = useCallback((id: TEstimatePositionId, selectType: TSelectedPositionType) => {
        const has = latestEstimatePositionsCheckedPositionsMapForEdit.current.has(id);

        if (selectType === 'primary') {
            if (latestPrimaryPositions.current.length === 1) {
                enqueueSnackbar('Должна быть минимум одна основная позиция', {
                    variant: 'error',
                });
                return;
            }

            if (has) {
                const map = new Map(latestEstimatePositionsCheckedPositionsMapForEdit.current);
                map.delete(id);

                dispatch(consolidatedPricesActions.updateEstimatePositionsCheckedPositionsMapForEdit(map));
            } else {
                setDeletedIds((prev) => new Set(prev).add(id));
            }
            setIsDirty(true);

            if (id === latestMainPosition.current) {
                setMainPositionId(null);
            }
            return;
        }

        if (has) {
            const map = new Map(latestEstimatePositionsCheckedPositionsMapForEdit.current);
            map.delete(id);

            dispatch(consolidatedPricesActions.updateEstimatePositionsCheckedPositionsMapForEdit(map));
        } else {
            setDeletedIds((prev) => new Set(prev).add(id));
        }

        setIsDirty(true);
    }, []);

    const positionsByUnitDifference = useMemo(() => {
        return getPositionsByUnitDifference(primaryPositions, rawUnit);
    }, [primaryPositions, rawUnit]);

    const rowData = useMemo(() => {
        return getRowData(primaryPositions, secondaryPositions, positionsByUnitDifference);
    }, [primaryPositions, secondaryPositions, positionsByUnitDifference]);

    useEffect(() => {
        const editedFormValues = getEditedFormValues(mainPositionId, primaryPositions, latestEditedFields.current);
        const formValues = getFormValues(primaryPositions, secondaryPositions);

        for (const [key, value] of Object.entries({
            ...editedFormValues,
            ...formValues,
        })) {
            methods.setValue(key as keyof TFormValues, value);
        }
    }, [primaryPositions, secondaryPositions, mainPositionId]);

    const handleSubmit = useCallback(async () => {
        const formValues = methods.getValues();

        try {
            const validatedData = await consolidatedPriceValidationSchema.validate({
                ...formValues,
                rawUnit: formValues.rawUnit ? unitTypeEnToRu[formValues.rawUnit] : null,
                positionIDs: {
                    main: latestMainPosition.current,
                    primary: latestPrimaryPositions.current.map((v) => v.id),
                    secondary: latestSecondaryPositions.current.map((v) => v.id),
                },
            });

            mutateUpdateConsolidatedPrice({
                projectId: Number(props.projectId),
                id: Number(props.consolidatedPriceId),
                body: validatedData as TCreateConsolidatedPricesBody,
            });
        } catch (error) {
            console.log('error', error);
        }
    }, []);

    const handleValidation = async () => {
        try {
            const formValues = methods.getValues();

            await consolidatedPriceValidationSchema.validate({
                ...formValues,
                rawUnit: formValues.rawUnit ? unitTypeEnToRu[formValues.rawUnit] : null,
                positionIDs: {
                    main: latestMainPosition.current,
                    primary: latestPrimaryPositions.current.map((v) => v.id),
                    secondary: latestSecondaryPositions.current.map((v) => v.id),
                },
            });

            setIsValid(true);
        } catch (error) {
            console.log('error', error);

            setIsValid(false);
        }
    };

    const handleClose = useCallback(() => {
        isDirty ? NiceModal.show(ConfirmDialog, { onSuccess: handleConfirmClose }) : handleConfirmClose();
    }, [isDirty]);

    const handleConfirmDelete = () => {
        mutateDeleteConsolidatedPrice({
            projectId: Number(props.projectId),
            id: props.consolidatedPriceId,
        });
    };

    const handleAddPositions = useCallback(() => {
        hide();
        dispatch(consolidatedPricesActions.setEditDialogMode('addPositions'));
    }, []);

    const handleDelete = useCallback(() => {
        NiceModal.show(ConfirmDialog, {
            title: 'Подтвердить удаление укрупненной расценки?',
            body: 'Укрупненная расценка будет удалена безвозвратно.',
            onSuccess: handleConfirmDelete,
        });
    }, []);

    return (
        <FormProvider {...methods}>
            <ConsolidatedPriceDialog
                mode='edit'
                rowData={rowData}
                mainPositionId={mainPositionId}
                isVisible={visible}
                isLoading={isLoading}
                changesSummary={data?.changesSummary}
                isSaveButtonDisabled={isSaveButtonDisabled}
                positionsByUnitDifference={positionsByUnitDifference}
                onSubmit={handleSubmit}
                onClose={handleClose}
                updateEditedFields={updateEditedFields}
                onDeleteRow={handleDeleteRow}
                onDelete={handleDelete}
                onSetMainPosition={handleSetMainPosition}
                onAddPositions={handleAddPositions}
            />
        </FormProvider>
    );
});
