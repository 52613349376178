import AddIcon from '@mui/icons-material/Add';
import { FormControl, Stack, Typography } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';

import { useGetProjectsQuery } from '@/api/projects/projects.api';
import { GetProjectsResponse } from '@/api/projects/projects.types';
import { TVerFactPermTerms, UserProjectAccessInfo } from '@/api/users/users.types';

import { theme } from '../../../../styles/theme';
import { VER_FACT_TERMS } from '../../ChooseVerFactPerms/ChooseVerFactPerms.service';
import AccessCard from '../AccessCard/AccessCard';
import Button from '../Button';
import FieldForm from '../FieldForm';
import { SelectMenuProps } from '../FilterSelect';
import { AccessStatusBlock } from '../UserAccesses/styles';
import { UserFormData } from '../UserForm/UserForm.types';
import { DisabledSelectMenuItem, StyledSelectMenuItem } from '../UserRoleSelection/styles';
import { UserAccessesSelectionProps } from './UserAccessesSelection.types';

function UserAccessesSelection({ children }: UserAccessesSelectionProps) {
    const { values: formValues, setFieldValue } = useFormikContext<UserFormData>();
    const [selectHidden, setSelectHidden] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const { data, isFetching, isLoading } = useGetProjectsQuery({
        limit: 999,
        offset: 0,
    });
    const { data: projects } = data || ({} as GetProjectsResponse);
    const projectForSelect = useMemo(() => {
        if (projects) {
            const copy = [...projects];
            formValues?.projects?.forEach((project) => {
                const selectedProjectId = copy.findIndex((rootProject) => rootProject.id === project.id);
                copy.splice(selectedProjectId, 1);
            });

            return copy;
        }
    }, [projects, formValues.projects]);

    const onAddClick = useCallback(() => {
        setSelectHidden(false);
        setOpen(true);
    }, []);

    const onSelectOpen = useCallback(() => {
        setOpen(true);
        setSelectHidden(false);
    }, []);

    const onSelectClose = useCallback(() => {
        setOpen(false);
        if (formValues?.projects?.length) {
            setSelectHidden(true);
        }
    }, []);

    const isDataLoading = isFetching || isLoading;

    if (children) {
        return <>{children}</>;
    }
    return (
        <Stack
            alignItems='flex-end'
            spacing={4.375}
        >
            <FieldArray
                name='projects'
                validateOnChange={false}
                render={({ push, remove }) => {
                    return (
                        <>
                            {formValues.projects
                                .filter((project) => !isNaN(project.id))
                                .map((project, index) => (
                                    <AccessCard
                                        project={project}
                                        onAccessItemDelete={() => remove(index)}
                                        key={project.id}
                                    />
                                ))}

                            {projectForSelect?.length ? (
                                formValues.projects.length && selectHidden ? (
                                    <Button
                                        startIcon={<AddIcon />}
                                        variant='text'
                                        bgColor={theme.palette.bg.shades}
                                        onClick={onAddClick}
                                        style={{ maxWidth: '276px', width: '100%', height: '44px' }}
                                        sx={{
                                            '& .MuiButton-startIcon': { paddingTop: '1px' },
                                        }}
                                    >
                                        Добавить проект
                                    </Button>
                                ) : (
                                    <FormControl style={{ width: '100%', maxWidth: '276px' }}>
                                        <FieldForm
                                            version='select'
                                            name='helper'
                                            onChange={(e) => {
                                                setSelectHidden(true);
                                                const value: string | 'all' = e.target.value;
                                                const alreadyChosenProjectIDs = formValues.projects.map(
                                                    (project) => project.id
                                                );
                                                if (value === 'all') {
                                                    setFieldValue(
                                                        'projects',
                                                        formValues.projects.concat(
                                                            projectForSelect
                                                                .filter(
                                                                    (project) =>
                                                                        !alreadyChosenProjectIDs.includes(project.id)
                                                                )
                                                                .map((project) => ({
                                                                    id: project.id,
                                                                    name: project.title,
                                                                    verFactPerms: Object.fromEntries(
                                                                        VER_FACT_TERMS.map(
                                                                            (term: TVerFactPermTerms) => [term, false]
                                                                        )
                                                                    ) as UserProjectAccessInfo['verFactPerms'],
                                                                }))
                                                        )
                                                    );
                                                }

                                                const projectName = projectForSelect?.find(
                                                    (project) => project.id === parseInt(value)
                                                )?.title;
                                                push({
                                                    id: parseInt(value),
                                                    name: projectName,
                                                });
                                            }}
                                            SelectProps={{
                                                open: open,
                                                onOpen: onSelectOpen,
                                                onClose: onSelectClose,
                                                MenuProps: SelectMenuProps,
                                                value: 'none',
                                            }}
                                        >
                                            <DisabledSelectMenuItem
                                                value='none'
                                                disabled
                                            >
                                                Выберите проект
                                            </DisabledSelectMenuItem>
                                            <StyledSelectMenuItem value='all'>Все проекты</StyledSelectMenuItem>

                                            {!isDataLoading &&
                                                projectForSelect.map((project) => (
                                                    <StyledSelectMenuItem
                                                        value={project.id}
                                                        key={project.id}
                                                    >
                                                        {project.title}
                                                    </StyledSelectMenuItem>
                                                ))}
                                        </FieldForm>
                                    </FormControl>
                                )
                            ) : (
                                <AccessStatusBlock color={theme.palette.text.dark}>
                                    <Typography variant='body1'>Проекты отсутствуют</Typography>
                                </AccessStatusBlock>
                            )}
                        </>
                    );
                }}
            />
        </Stack>
    );
}

export default UserAccessesSelection;
