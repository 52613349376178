import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useCallback } from 'react';

import { ConsolidatedPriceFilterDialog } from '@/components/ConsolidatedPriceFilterDialog';

import { useLatest } from '@/hooks/useLatest';

import { consolidatedPricesActions, consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { titleByFilterKey } from './FilterDialog.config';
import { useGetEstimatePositionsFilter } from './FilterDialog.model';
import { TFilterDialogProps } from './FilterDialog.types';

export const FilterDialog = NiceModal.create<TFilterDialogProps>((props) => {
    const filters = useTypedSelector(consolidatedPricesSelectors.estimatePositionsFilters);
    const filter = filters[props.filterKey];
    const dispatch = useAppDispatch();

    const { visible, remove } = useModal();
    const title = titleByFilterKey[props.filterKey];

    const { data, isLoading } = useGetEstimatePositionsFilter(props);

    const values = data?.values ?? [];

    const latestValues = useLatest(values);

    const handleReset = () => {
        dispatch(consolidatedPricesActions.resetEstimatePositionsFilter(props.filterKey));
    };

    const handleCheckboxChange = useCallback((value: string, checked: boolean) => {
        dispatch(
            consolidatedPricesActions.setEstimatePositionsFilter({
                key: props.filterKey,
                values: [value],
                checked: checked,
                mode: 'single',
                length: latestValues.current.length,
            })
        );
    }, []);

    const handleAllCheckboxChange = useCallback((checked: boolean) => {
        dispatch(
            consolidatedPricesActions.setEstimatePositionsFilter({
                key: props.filterKey,
                values: checked ? latestValues.current : [],
                checked: checked,
                mode: 'all',
                length: latestValues.current.length,
            })
        );
    }, []);

    return (
        <ConsolidatedPriceFilterDialog
            projectId={props.projectId}
            values={values}
            filter={filter}
            isLoading={isLoading}
            title={title}
            isVisible={visible}
            onReset={handleReset}
            onClose={remove}
            onAllCheckboxChange={handleAllCheckboxChange}
            onCheckboxChange={handleCheckboxChange}
        />
    );
});
