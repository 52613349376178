import { api } from '@/api/api';

import {
    CreateProjectRequest,
    DeleteProjectRequest,
    DeleteProjectResponse,
    EditProjectRequest,
    GetProjectByIdRequest,
    GetProjectsRequest,
    GetProjectsResponse,
    ProjectBaseResponse,
} from './projects.types';

export const projectsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProjects: build.query<GetProjectsResponse, GetProjectsRequest>({
            query: () => ({
                url: '/projects/list',
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const projects = await queryFulfilled;
                    const array: { id: number; date: Date }[] = JSON.parse(localStorage.getItem('seenAlerts') || '');
                    if (array.length) {
                        let filteredArray = array.filter((storage) => {
                            return (
                                projects.data?.data.filter((project) => {
                                    return project.id === storage.id;
                                }).length !== 0
                            );
                        });
                        localStorage.setItem('seenAlerts', JSON.stringify(filteredArray));
                    }
                } catch {}
            },
            providesTags: ['Projects'],
        }),

        getGraphProjects: build.query<GetProjectsResponse, GetProjectsRequest>({
            query: () => ({
                url: '/projects/list-graph',
                method: 'GET',
            }),
        }),

        createProject: build.mutation<ProjectBaseResponse, CreateProjectRequest>({
            query: (body) => ({
                url: `/projects/add`,
                method: 'POST',
                body,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;

                    dispatch(
                        projectsApi.util.updateQueryData('getProjects', {}, (draft) => {
                            if (!updatedStatus.data) return;
                            draft.data.push(updatedStatus.data);
                        })
                    );
                } catch {}
            },
        }),

        getProjectById: build.query<ProjectBaseResponse, GetProjectByIdRequest>({
            query: ({ id }) => ({
                url: `/projects/${id}/get`,
                method: 'GET',
            }),
        }),

        editProject: build.mutation<ProjectBaseResponse, EditProjectRequest>({
            query: ({ id, ...body }) => ({
                url: `/projects/${id}/update`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(
                        projectsApi.util.updateQueryData('getProjects', {}, (draft) => {
                            const indexChange = draft.data.findIndex((project) => {
                                return project.id === updatedStatus.data.id;
                            });
                            draft.data.splice(indexChange, 1, updatedStatus.data);
                        })
                    );
                    dispatch(
                        projectsApi.util.updateQueryData('getProjectById', { id }, (draft) => {
                            Object.assign(draft.data, updatedStatus.data);
                        })
                    );
                } catch {}
            },
        }),

        deleteProject: build.mutation<DeleteProjectResponse, DeleteProjectRequest>({
            query: ({ id }) => ({
                url: `/projects/${id}/delete`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;

                    dispatch(
                        projectsApi.util.updateQueryData('getProjects', {}, (draft) => {
                            draft.data = draft.data.filter((project) => project.id !== updatedStatus.data);
                        })
                    );
                } catch {}
            },
        }),

        getExcel: build.query<{ data: string }, void>({
            query: () => ({
                url: '/projects/link-example',
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetProjectsQuery,
    useGetGraphProjectsQuery,
    useCreateProjectMutation,
    useGetProjectByIdQuery,
    useEditProjectMutation,
    useDeleteProjectMutation,
    useGetExcelQuery,
} = projectsApi;
