import { Button, Stack } from '@mui/material';

import { req } from '@/pages/WorkManagment/api/api';

import { isOfferResynchronized } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { useAppDispatch } from '@/store/store';

import {
    FiltersMenuItem as Item,
    FiltersMenuList as List,
    FiltersMenu as Menu,
    SwitchButton,
    SwitchCircle,
    SwitchIcon,
    SwitchText,
} from './ControlPanel.styles';
import { IFiltersMenuProps, ISwitchProps } from './ControlPanel.types';

const Switch: React.FC<ISwitchProps> = ({ isActive, text, onChange }) => {
    const handleToggle = () => onChange(!isActive);

    return (
        <SwitchButton onClick={handleToggle}>
            <SwitchIcon isActive={isActive}>
                <SwitchCircle isActive={isActive} />
            </SwitchIcon>
            <SwitchText isActive={isActive}>{text}</SwitchText>
        </SwitchButton>
    );
};

export const FiltersMenu: React.FC<IFiltersMenuProps> = ({ projectId, filters, anchorEl, onClose, updateFilters }) => {
    const dispatch = useAppDispatch();

    const handleSync = async () => {
        try {
            await req.get(`/cross-module/offer/sync-acts-data?rmProjectID=${projectId}`);
            dispatch(isOfferResynchronized());
            onClose();
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            <Stack gap={1}>
                <List>
                    <Item>
                        <Switch
                            text='скрыть заполненные'
                            isActive={filters.hideFilled}
                            onChange={updateFilters('hideFilled')}
                        />
                    </Item>
                    {/* <Item>
                    <Switch
                        text="показать стоимость"
                        isActive={filters.visibleCost}
                        onChange={updateFilters('visibleCost')}
                    />
                </Item> */}
                </List>

                <Button
                    variant='contained'
                    size='small'
                    onClick={handleSync}
                >
                    Синхронизация
                </Button>
            </Stack>
        </Menu>
    );
};
