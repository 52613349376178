import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { useBasePlanKsgMutation } from '@/api/basePlan/basePlan.api';
import { useDownloadExcelMutation } from '@/api/ksg/ksg.api';

import { EPageName } from '@/shared/constants/pages';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { formDownloadLink } from '@/shared/utils';

import {
    drawersSelector,
    toggleEisReports,
    toggleProjectBackup,
    toggleSendSnz,
    toggleSynchronize,
    toggleUpdateKSG,
} from '@/store/slices/drawersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { projectsSelector } from '@/store/slices/projectsSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { iconsVariants } from './RemainingItemsMenu.service';
import MenuItemEl from './components/MenuItemEl';

export default function RemainingItemsMenu({
    setAnchorElRemaining,
}: {
    setAnchorElRemaining: Dispatch<SetStateAction<HTMLElement | null>>;
}) {
    const dispatch = useAppDispatch();
    const { uploadProgress } = useTypedSelector(drawersSelector);

    const [basePlanKsgReq] = useBasePlanKsgMutation();
    const [exReq] = useDownloadExcelMutation();

    const { pageName } = useTypedSelector(projectsSelector);
    const { profile } = useTypedSelector(profileSelector);

    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');
    const location = useLocation();

    const locationSplit = location.pathname.split('/');

    const saveBasePlan = () => {
        basePlanKsgReq({
            projectId: Number(projectId),
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('base_plan_saved'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
    };

    const downloadProject = () => {
        setAnchorElRemaining(null);
        exReq({ id: Number(projectId) })
            .unwrap()
            .then((res) => {
                formDownloadLink(res, locationSplit, projectId);
                enqueueSnackbar(t('download_ksg'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
    };

    return (
        <>
            {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SYNC_SETTINGS') && (
                <MenuItemEl
                    onClick={() => {
                        setAnchorElRemaining(null);
                        dispatch(toggleSynchronize());
                    }}
                    iconsVar={iconsVariants.cloudSync}
                    title='Настройка синхронизации'
                    description='Позволяет получить данные по работам из другого модуля.'
                />
            )}

            {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SEND_SNZ_MANUALLY') && (
                <MenuItemEl
                    onClick={() => {
                        setAnchorElRemaining(null);
                        dispatch(toggleSendSnz());
                    }}
                    iconsVar={iconsVariants.manualSendSnzIcon}
                    title='Ручная отправка СНЗ'
                    description='Отправить письмо о внесении факта.'
                />
            )}

            {pageName === EPageName.Ksg && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SAVE_BASE_PLAN') && (
                <MenuItemEl
                    onClick={() => {
                        setAnchorElRemaining(null);
                        saveBasePlan();
                    }}
                    iconsVar={iconsVariants.saveBasePlanIcon}
                    title='Сохранить базовый план'
                    description='Зафиксировать объем проекта.'
                />
            )}

            {pageName === EPageName.Ksg &&
                profile?.futures?.reportEIS &&
                DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EIS_REPORT') && (
                    <MenuItemEl
                        onClick={() => {
                            dispatch(toggleEisReports());
                            setAnchorElRemaining(null);
                        }}
                        iconsVar={iconsVariants.downloadEisIcon}
                        title='Отчеты для ЕИС'
                        description='Выгрузка фактически выполненных объёмов работ для обновления в «1С ЕИС».'
                    />
                )}

            {pageName === EPageName.Ksg && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'DOWNLOAD_XLS') && (
                <MenuItemEl
                    onClick={downloadProject}
                    iconsVar={iconsVariants.downloadEisIcon}
                    title='Скачать проект .XLS'
                    description='Выгрузить проект в excel файл.'
                />
            )}

            {pageName === EPageName.Ksg && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'UPDATE_KSG') && (
                <MenuItemEl
                    onClick={() => {
                        setAnchorElRemaining(null);
                        dispatch(toggleUpdateKSG());
                    }}
                    iconsVar={iconsVariants.updateKSGIcon}
                    title='Обновить КСГ'
                    description='Позволяет обновить проект из Exсel файла.'
                    disabled={!!uploadProgress.activeTask}
                />
            )}
            {pageName === EPageName.Ksg && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'PROJECT_BACKUPS') && (
                <MenuItemEl
                    onClick={() => {
                        setAnchorElRemaining(null);
                        dispatch(toggleProjectBackup());
                    }}
                    iconsVar={iconsVariants.updateKSGIcon}
                    title='Резервное копирование'
                    description='Позволяет сохранить состояние проекта на определенный момент времени.'
                />
            )}
        </>
    );
}
