interface IProps {}

export const DragIndicatorIcon: React.FC<IProps> = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
        >
            <g clipPath='url(#clip0_4064_11688)'>
                <path
                    d='M9.16634 15C9.16634 15.9167 8.41634 16.6667 7.49967 16.6667C6.58301 16.6667 5.83301 15.9167 5.83301 15C5.83301 14.0834 6.58301 13.3334 7.49967 13.3334C8.41634 13.3334 9.16634 14.0834 9.16634 15ZM7.49967 8.33337C6.58301 8.33337 5.83301 9.08337 5.83301 10C5.83301 10.9167 6.58301 11.6667 7.49967 11.6667C8.41634 11.6667 9.16634 10.9167 9.16634 10C9.16634 9.08337 8.41634 8.33337 7.49967 8.33337ZM7.49967 3.33337C6.58301 3.33337 5.83301 4.08337 5.83301 5.00004C5.83301 5.91671 6.58301 6.66671 7.49967 6.66671C8.41634 6.66671 9.16634 5.91671 9.16634 5.00004C9.16634 4.08337 8.41634 3.33337 7.49967 3.33337ZM12.4997 6.66671C13.4163 6.66671 14.1663 5.91671 14.1663 5.00004C14.1663 4.08337 13.4163 3.33337 12.4997 3.33337C11.583 3.33337 10.833 4.08337 10.833 5.00004C10.833 5.91671 11.583 6.66671 12.4997 6.66671ZM12.4997 8.33337C11.583 8.33337 10.833 9.08337 10.833 10C10.833 10.9167 11.583 11.6667 12.4997 11.6667C13.4163 11.6667 14.1663 10.9167 14.1663 10C14.1663 9.08337 13.4163 8.33337 12.4997 8.33337ZM12.4997 13.3334C11.583 13.3334 10.833 14.0834 10.833 15C10.833 15.9167 11.583 16.6667 12.4997 16.6667C13.4163 16.6667 14.1663 15.9167 14.1663 15C14.1663 14.0834 13.4163 13.3334 12.4997 13.3334Z'
                    fill='currentColor'
                />
            </g>
            <defs>
                <clipPath id='clip0_4064_11688'>
                    <rect
                        width='20'
                        height='20'
                        fill='white'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
