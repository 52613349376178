import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AgGridReact } from 'ag-grid-react';
import { WritableDraft } from 'immer/dist/internal';
import { Dispatch, SetStateAction } from 'react';

import { pickSyncModuleType } from '@/api/importingData/importingData.types';

import { IActiveUploadTask, IDistributionTask } from '@/types/common.types';
import { TShiftErrorsData } from '@/types/error.types';

import { RootState } from '../store';

type syncModuleDataType = {
    module: pickSyncModuleType;
    moduleProjectName: string;
    lastTime: string | null;
} | null;

interface IInitialState {
    changeProject: {
        id: number;
        open: boolean;
        isImportFromOffer: boolean;
    };
    addWorks: boolean;
    addProject: boolean;
    createCpgTemplate: boolean;
    sendSnz: boolean;
    changeCpgTemplate: boolean;
    changeMsgTemplate: boolean;
    createMsgTemplate: boolean;
    synchronize: boolean;
    syncModuleData: syncModuleDataType;
    autoSendSnz: boolean;
    notificationsCenter: boolean;
    eisReports: boolean;
    syncModule: boolean;
    uploadKsg: boolean;
    uploadKsgEquip: boolean;
    uploadKsgPeople: boolean;
    addLevelKsgPeople: boolean;
    addLevelKsgEquip: boolean;
    updateKSG: boolean;
    projectBackup: boolean;
    workManagmentResourcesGridRefs: {
        staff: AgGridReact<any> | null;
        mim: AgGridReact<any> | null;
        mto: AgGridReact<any> | null;
        setTriggerRefresh: Dispatch<SetStateAction<boolean>> | null;
    };
    AgGrid: AgGridReact<any> | null;
    triggerIfBackupRestored: boolean;
    uploadProgress: {
        openDialog: boolean | 'wrapped';
        supressOnClose: boolean;
        supressWatchBtn: boolean;
        activeTask?: IActiveUploadTask;
    };
    distributionProgress: {
        open: boolean;
        distributionTask?: IDistributionTask;
    };
    deleteZeroValueFact: {
        open: boolean;
        data: TShiftErrorsData['zeroFactBlockers'] | null;
        cancelCallback?: () => void;
    };
}

const initialState: IInitialState = {
    changeProject: {
        id: 0,
        open: false,
        isImportFromOffer: false,
    },
    addWorks: false,
    addProject: false,
    createCpgTemplate: false,
    sendSnz: false,
    changeCpgTemplate: false,
    changeMsgTemplate: false,
    createMsgTemplate: false,
    synchronize: false,
    syncModuleData: null,
    autoSendSnz: false,
    notificationsCenter: false,
    eisReports: false,
    syncModule: false,
    uploadKsg: false,
    uploadKsgEquip: false,
    uploadKsgPeople: false,
    addLevelKsgPeople: false,
    addLevelKsgEquip: false,
    updateKSG: false,
    projectBackup: false,
    workManagmentResourcesGridRefs: {
        staff: null,
        mim: null,
        mto: null,
        setTriggerRefresh: null,
    },
    AgGrid: null,
    triggerIfBackupRestored: false,
    uploadProgress: {
        openDialog: false,
        supressOnClose: false,
        supressWatchBtn: false,
    },
    distributionProgress: {
        open: false,
    },
    deleteZeroValueFact: {
        open: false,
        data: null,
    },
};

const drawersSlice = createSlice({
    name: 'drawers',
    initialState,
    reducers: {
        toggleAddWorks: (state) => {
            state.addWorks = !state.addWorks;
        },

        toggleAddProject(state) {
            state.addProject = !state.addProject;
        },

        setChangeProjectId(
            state,
            action: PayloadAction<{
                id: number;
                isImportFromOffer: boolean;
            }>
        ) {
            state.changeProject.id = action.payload.id;
            state.changeProject.isImportFromOffer = action.payload.isImportFromOffer;
        },

        toggleChangeProject(state) {
            state.changeProject.open = !state.changeProject.open;
        },

        toggleCreateCpgTemplate(state) {
            state.createCpgTemplate = !state.createCpgTemplate;
        },

        toggleChangeCpgTemplate(state) {
            state.changeCpgTemplate = !state.changeCpgTemplate;
        },

        toggleCreateMsgTemplate(state) {
            state.createMsgTemplate = !state.createMsgTemplate;
        },

        toggleChangeMsgTemplate(state) {
            state.changeMsgTemplate = !state.changeMsgTemplate;
        },

        toggleSendSnz(state) {
            state.sendSnz = !state.sendSnz;
        },

        toggleSynchronize(state) {
            state.synchronize = !state.synchronize;
        },

        toggleNotificationsCenter(state) {
            state.notificationsCenter = !state.notificationsCenter;
        },

        toggleAutoSendSnz(state) {
            state.autoSendSnz = !state.autoSendSnz;
        },

        setSyncModuleData(state, action: PayloadAction<syncModuleDataType>) {
            state.syncModuleData = action.payload;
        },

        toggleEisReports(state) {
            state.eisReports = !state.eisReports;
        },

        toggleSyncModule(state) {
            state.syncModule = !state.syncModule;
        },

        toggleUploadKsg(state) {
            state.uploadKsg = !state.uploadKsg;
        },

        toggleUploadKsgPeople(state) {
            state.uploadKsgPeople = !state.uploadKsgPeople;
        },

        toggleUploadKsgEquip(state) {
            state.uploadKsgEquip = !state.uploadKsgEquip;
        },
        toggleAddLevelKsgPeople(state) {
            state.addLevelKsgPeople = !state.addLevelKsgPeople;
        },

        toggleAddLevelKsgEquip(state) {
            state.addLevelKsgEquip = !state.addLevelKsgEquip;
        },
        toggleUpdateKSG(state) {
            state.updateKSG = !state.updateKSG;
        },
        toggleProjectBackup(state) {
            state.projectBackup = !state.projectBackup;
        },
        setWorkManagmetResourceGridApi(
            state,
            action: PayloadAction<WritableDraft<Partial<IInitialState['workManagmentResourcesGridRefs']>>>
        ) {
            state.workManagmentResourcesGridRefs = { ...state.workManagmentResourcesGridRefs, ...action.payload };
        },
        setAgGrid(state, action: PayloadAction<IInitialState['AgGrid']>) {
            state.AgGrid = action.payload as WritableDraft<IInitialState['AgGrid']>;
        },
        setTriggerIfBackupRestored(state) {
            state.triggerIfBackupRestored = !state.triggerIfBackupRestored;
        },
        toggleUploadProgress(state, action: PayloadAction<Partial<IInitialState['uploadProgress']>>) {
            state.uploadProgress = { ...state.uploadProgress, ...action.payload };
        },
        toggleDistributionProgress(state, action: PayloadAction<Partial<IInitialState['distributionProgress']>>) {
            state.distributionProgress = { ...state.distributionProgress, ...action.payload };
        },
        toggleDeleteZeroValueFact(state, action: PayloadAction<Partial<IInitialState['deleteZeroValueFact']>>) {
            state.deleteZeroValueFact = { ...state.deleteZeroValueFact, ...action.payload };
        },
    },
});

export const {
    toggleAddProject,
    setChangeProjectId,
    toggleChangeProject,
    toggleCreateCpgTemplate,
    toggleSendSnz,
    toggleChangeCpgTemplate,
    toggleCreateMsgTemplate,
    toggleChangeMsgTemplate,
    toggleAddWorks,
    toggleSynchronize,
    setSyncModuleData,
    toggleAutoSendSnz,
    toggleNotificationsCenter,
    toggleSyncModule,
    toggleEisReports,
    toggleUploadKsg,
    toggleUploadKsgPeople,
    toggleUploadKsgEquip,
    toggleAddLevelKsgPeople,
    toggleAddLevelKsgEquip,
    toggleUpdateKSG,
    toggleProjectBackup,
    setWorkManagmetResourceGridApi,
    setAgGrid,
    setTriggerIfBackupRestored,
    toggleUploadProgress,
    toggleDistributionProgress,
    toggleDeleteZeroValueFact,
} = drawersSlice.actions;

export default drawersSlice.reducer;

export const drawersSelector = (state: RootState) => state.drawers;
export const deleteZeroValueFactDrawerSelector = (state: RootState) => state.drawers.deleteZeroValueFact;
