import { animated, SpringValue, useSpring } from '@react-spring/web';
import React from 'react';

type TUseBoopProps = {
    x?: number;
    y?: number;
    rotation?: number;
    scale?: number;
    timing?: number;
    springConfig?: {
        tension?: number;
        friction?: number;
    };
};

type TUseBoopReturn = [Record<string, SpringValue<string>> | {}, () => void];

type TBoopProps = {
    children: React.ReactNode;
    boopConfig: TUseBoopProps;
};

export const useBoop = ({
    x = 0,
    y = 0,
    rotation = 0,
    scale = 1,
    timing = 150,
    springConfig = {
        tension: 200,
        friction: 6,
    },
}: TUseBoopProps): TUseBoopReturn => {
    const [isBooped, setIsBooped] = React.useState(false);

    const style = useSpring({
        transform: isBooped
            ? `translate(${x}px, ${y}px) rotate(${rotation}deg) scale(${scale})`
            : `translate(0px, 0px) rotate(0deg) scale(1)`,
        config: springConfig,
    });

    React.useEffect(() => {
        if (!isBooped) return;

        const timeoutId = window.setTimeout(() => {
            setIsBooped(false);
        }, timing);

        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [isBooped, timing]);

    const trigger = React.useCallback(() => {
        setIsBooped(true);
    }, []);

    return [style, trigger];
};

export const Boop: React.FC<TBoopProps> = ({ children, boopConfig }) => {
    const [style, trigger] = useBoop(boopConfig);
    return (
        <animated.span
            onMouseEnter={trigger}
            style={style}
        >
            {children}
        </animated.span>
    );
};
