import { animated } from '@react-spring/web';
import { throttle } from 'lodash';
import { FC, useEffect, useState } from 'react';

import { UICircleCheckbox } from '@/components/ui/UICircleCheckbox';

import { useBoop } from '@/hooks/useBoop';
import { usePortal } from '@/hooks/usePortal';

import { CollapseArrowIcon } from '@/shared/SVG/CollapseArrowIcon';
import { PlusIcon } from '@/shared/SVG/PlusIcon';

import { generateRowId } from '../../utils';
import { calculateCreateButtonPosition, getCreateButtonPosition } from './CollapseCellRenderer.utils';
import {
    CheckboxCellRendererContainer,
    CollapseCellRenderButton,
    CollapseCellRendererContainer,
    CollapseCellRendererIconContainer,
    CreateRowButton,
    CreateRowButtonLine,
} from './TableCells.styles';
import { TCheckboxCellRendererProps, TCollapseCellRendererProps } from './TableCells.types';

const AnimatedPlusIcon = animated(PlusIcon);

export const CollapseCellRenderer: FC<TCollapseCellRendererProps> = (props) => {
    const rowId = generateRowId({ rowType: props.data!?.rowType, entityId: props.data!?.entityId });
    const rowNode = props.api.getRowNode(rowId);
    const Portal = usePortal(document.body);

    const [createRowButtonStyle, createRowButtonTrigger] = useBoop({ rotation: 4, timing: 200 });
    const [isRowHovered, setIsRowHovered] = useState(false);
    const [isButtonHovered, setIsButtonHovered] = useState(false);

    const [position, setPosition] = useState(getCreateButtonPosition(props.eGridCell));

    const isHovered = isRowHovered || isButtonHovered;

    const isArrowVisible = props.data?.hasChildren;

    const eGridCellRect = props.eGridCell.getBoundingClientRect();
    const cellTop = eGridCellRect.top;
    const cellLeft = eGridCellRect.left;
    const cellWidth = eGridCellRect.width;

    const { y: createButtonPositionTop } = getCreateButtonPosition(props.eGridCell);

    useEffect(() => {
        setPosition(calculateCreateButtonPosition(cellLeft, cellWidth, cellTop));
    }, [cellTop, cellLeft, cellWidth]);

    useEffect(() => {
        if (!rowNode) return;

        const handleMouseEnter = () => setIsRowHovered(true);
        const handleMouseLeave = () => setIsRowHovered(false);

        rowNode.addEventListener('mouseEnter', handleMouseEnter);
        rowNode.addEventListener('mouseLeave', handleMouseLeave);

        props.api.addRenderedRowListener('virtualRowRemoved', props.rowIndex, () => {
            rowNode.removeEventListener('mouseEnter', handleMouseEnter);
            rowNode.removeEventListener('mouseLeave', handleMouseLeave);
        });
    }, [rowNode]);

    useEffect(() => {
        const handleBodyScroll = () => setPosition(getCreateButtonPosition(props.eGridCell));
        const throttleBodyScroll = throttle(handleBodyScroll, 50);

        props.api.addEventListener('bodyScroll', throttleBodyScroll);
        return () => props.api.removeEventListener('bodyScroll', throttleBodyScroll);
    }, []);

    const handleCollapse = () => {
        props.onCollapse();
    };

    const handleRowCreate = () => {
        props.onRowCreate();
        setIsButtonHovered(false);
    };

    const handleCreateButtonMouseEnter = () => {
        setIsButtonHovered(true);
    };

    const handleCreateButtonMouseLeave = () => {
        setIsButtonHovered(false);
    };

    return (
        <CollapseCellRendererContainer>
            {isHovered && (
                <Portal>
                    <CreateRowButtonLine
                        style={{
                            top: cellTop,
                        }}
                        onMouseEnter={handleCreateButtonMouseEnter}
                        onMouseLeave={handleCreateButtonMouseLeave}
                    />
                    <CreateRowButton
                        onClick={handleRowCreate}
                        onMouseEnter={() => {
                            handleCreateButtonMouseEnter();
                            createRowButtonTrigger();
                        }}
                        onMouseLeave={handleCreateButtonMouseLeave}
                        style={{
                            left: position.x,
                            top: createButtonPositionTop,
                        }}
                    >
                        <AnimatedPlusIcon style={createRowButtonStyle} />
                    </CreateRowButton>
                </Portal>
            )}

            {isArrowVisible && (
                <CollapseCellRenderButton onClick={handleCollapse}>
                    <CollapseCellRendererIconContainer>
                        <CollapseArrowIcon />
                    </CollapseCellRendererIconContainer>
                </CollapseCellRenderButton>
            )}
        </CollapseCellRendererContainer>
    );
};

export const CheckboxCellRenderer: FC<TCheckboxCellRendererProps> = (props) => {
    const color = props.data?.positionType === 'secondary' ? '#ED6C02' : '#0288D1';

    return (
        <CheckboxCellRendererContainer>
            <UICircleCheckbox
                checked
                onChange={() => {}}
                checkedIconProps={{ sx: { color } }}
                checkboxProps={{ sx: { pointerEvents: 'none' } }}
            />
        </CheckboxCellRendererContainer>
    );
};
