import { DeleteOutline, RestorePageOutlined, SaveOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HtmlTooltip } from '@/shared/components/HtmlTooltip';

import { FlexColumnWrapper, FlexRowWrapper } from '../../pages/NewExecutorView/components/components.styles';
import { ConfirmDialog } from '../../pages/WorkManagment/components/ConfirmDialog/ConfirmDialog';
import { StyledIconBtn } from '../ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.styles';
import { IBackupItemProps } from './BackupDrawer.types';

export function BackupItem({ index, backup, onRestore, onSwitchType, onDeleteBackup, isFetching }: IBackupItemProps) {
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [isConfirmRestoreDialogOpen, setIsConfirmRestoreDialogOpen] = useState(false);
    const [isSwitchBackupTypeDialogOpen, setIsSwitchBackupTypeDialogOpen] = useState(false);

    const { t } = useTranslation('projectBackup');

    return (
        <FlexRowWrapper
            key={backup.id}
            gap={1}
            width={'100%'}
        >
            {backup.id}
            <FlexRowWrapper
                bgcolor={(theme) => theme.palette.bg.gray}
                minWidth={(theme) => theme.spacing(6)}
                minHeight={(theme) => theme.spacing(5)}
                alignItems={'center'}
                justifyContent={'center'}
                borderRadius={(theme) => theme.spacing(0.75)}
            >
                {index + 1}
            </FlexRowWrapper>
            <FlexColumnWrapper
                gap={0}
                justifyContent={'space-between'}
                width={'100%'}
                height={'100%'}
            >
                <Typography
                    textAlign={'left'}
                    color={(theme) => theme.palette.text.primary}
                    variant='body1'
                    fontSize={'14px'}
                    fontWeight={500}
                >
                    {t('type.' + backup.type)}
                </Typography>
                <Typography
                    variant='body1'
                    textAlign={'left'}
                    color={(theme) => theme.palette.text.secondary}
                    fontSize={'14px'}
                >
                    {format(new Date(backup?.createdAt), 'dd.MM.yyyy HH:mm')}
                </Typography>
            </FlexColumnWrapper>
            <HtmlTooltip
                title='Применить резервную копию проекта'
                onClick={() => setIsConfirmRestoreDialogOpen(() => true)}
            >
                <StyledIconBtn sx={{ background: 'rgba(246, 247, 251, 1)' }}>
                    <RestorePageOutlined color='primary' />
                </StyledIconBtn>
            </HtmlTooltip>
            {backup.type === 'auto' && (
                <HtmlTooltip title='Превращает автоматическое сохранение в ручное'>
                    <StyledIconBtn sx={{ background: 'rgba(246, 247, 251, 1)' }}>
                        <SaveOutlined
                            color='primary'
                            onClick={() => setIsSwitchBackupTypeDialogOpen(() => true)}
                        />
                    </StyledIconBtn>
                </HtmlTooltip>
            )}
            {backup.type === 'manual' && (
                <HtmlTooltip title='Удалить backup'>
                    <StyledIconBtn
                        sx={{ background: 'rgba(246, 247, 251, 1)' }}
                        onClick={() => setIsConfirmDeleteDialogOpen(() => true)}
                    >
                        <DeleteOutline color='primary' />
                    </StyledIconBtn>
                </HtmlTooltip>
            )}
            <ConfirmDialog
                key={'deleteConfirm' + backup.id}
                open={isConfirmDeleteDialogOpen}
                title={'Вы уверены, что хотите удалить резервную копию?'}
                onYes={{
                    action: () => onDeleteBackup(backup.id, setIsSwitchBackupTypeDialogOpen),
                    btnText: 'Удалить',
                }}
                onNo={{
                    action: (e) => setIsConfirmDeleteDialogOpen(() => false),
                    btnText: 'Отменить',
                }}
            />
            <ConfirmDialog
                key={'restoreConfirm' + backup.id}
                open={isConfirmRestoreDialogOpen}
                title={'Вы уверены, что хотите применить резервную копию?'}
                message='Все данные будут перезаписаны'
                onYes={{
                    disabled: isFetching,
                    action: () => onRestore(backup.id, setIsConfirmRestoreDialogOpen),
                    btnText: 'Применить',
                }}
                onNo={{
                    disabled: isFetching,
                    action: (e) => {
                        setIsConfirmRestoreDialogOpen(() => false);
                    },
                    btnText: 'Отменить',
                }}
            />
            <ConfirmDialog
                key={'switchTypeConfirm' + backup.id}
                open={isSwitchBackupTypeDialogOpen}
                title={'Вы уверены, что хотите превратить автоматическое сохранение в ручное?'}
                onYes={{
                    disabled: isFetching,
                    action: () => onSwitchType(backup.id, setIsSwitchBackupTypeDialogOpen),
                    btnText: 'Да',
                }}
                onNo={{
                    disabled: isFetching,
                    action: (e) => setIsSwitchBackupTypeDialogOpen(() => false),
                    btnText: 'Отменить',
                }}
            />
        </FlexRowWrapper>
    );
}
