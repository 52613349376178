import { createInstance } from '@/api/apiInstance';

import {
    TConsolidatedPricesRequest,
    TConsolidatedPricesResponse,
    TCreateBulkConsolidatedPricesRequest,
    TCreateBulkConsolidatedPricesResponse,
    TCreateConsolidatedPricesRequest,
    TCreateConsolidatedPricesResponse,
    TDeleteByIdRequest,
    TDeleteByIdResponse,
    TFetchByIdRequest,
    TFetchByIdResponse,
    TFilterConsolidatedPricesRequest,
    TFilterConsolidatedPricesResponse,
    TUpdateConsolidatedPricesRequest,
    TUpdateConsolidatedPricesResponse,
} from "./consolidatedPrices.types";

export const consolidatedPricesApi = {
    fetchList: async ({ body, limit, offset, projectId }: TConsolidatedPricesRequest) => {
        const data = await createInstance<TConsolidatedPricesResponse>({
            url: `/projects/${projectId}/consolidated/list`,
            method: 'post',
            data: body,
            params: {
                limit,
                offset,
            },
        });
        return data;
    },

    fetchFilters: async ({ body, limit, offset, projectId, search, column }: TFilterConsolidatedPricesRequest) => {
        const data = await createInstance<TFilterConsolidatedPricesResponse>({
            url: `/projects/${projectId}/consolidated/filters`,
            method: 'post',
            data: body,
            params: {
                limit,
                offset,
                search,
                column,
            },
        });
        return data;
    },

    fetchById: async ({ id, projectId }: TFetchByIdRequest) => {
        const data = await createInstance<TFetchByIdResponse>({
            url: `/projects/${projectId}/consolidated/${id}/get`,
            method: 'get',
        });
        return data;
    },

    create: async ({ projectId, body }: TCreateConsolidatedPricesRequest) => {
        const data = await createInstance<TCreateConsolidatedPricesResponse>({
            url: `/projects/${projectId}/consolidated/create`,
            method: 'post',
            data: body,
        });
        return data;
    },
    createBulk: async ({ projectId, body }: TCreateBulkConsolidatedPricesRequest) => {
        const data = await createInstance<TCreateBulkConsolidatedPricesResponse>({
            url: `/projects/${projectId}/consolidated/create/bulk`,
            method: 'post',
            data: body,
        });
        return data;
    },

    update: async ({ id, projectId, body }: TUpdateConsolidatedPricesRequest) => {
        const data = await createInstance<TUpdateConsolidatedPricesResponse>({
            url: `/projects/${projectId}/consolidated/${id}/update`,
            method: 'put',
            data: body,
        });
        return data;
    },

    deleteById: async ({ id, projectId }: TDeleteByIdRequest) => {
        const data = await createInstance<TDeleteByIdResponse>({
            url: `/projects/${projectId}/consolidated/${id}/delete`,
            method: 'delete',
        });
        return data;
    },
};
