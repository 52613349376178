import { Box, MenuItem, Select as MuiSelect, TextField, Typography } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import styled, { css } from 'styled-components';

export const Container = styled(Box)`
    height: fit-content;
`;

export const Label = styled('label')``;

export const LabelText = styled(Typography)`
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #5c6e8c;
    padding-left: 8px;
`;

const InputCSS = css`
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.01em;
    color: #2b3648;
    height: 36px;
    box-sizing: border-box;
    min-width: 0;
    background-color: #f6f7fb;
    text-overflow: ellipsis;
    outline: none;
`;

const InputPlaceholderCSS = css`
    color: #9aa2b0;
`;

export const Input = styled(TextField)`
    & .MuiOutlinedInput-input {
        ${InputCSS}
    }

    & .MuiOutlinedInput-input::placeholder {
        ${InputPlaceholderCSS}
    }

    & .MuiInputBase-root {
        border-radius: 8px;
        overflow: hidden;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(120, 144, 178, 0.1);
    }

    & .Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(120, 144, 178, 0.1);
    }

    & .Mui-disabled {
        .MuiOutlinedInput-input {
            color: #9aa2b0;
        }

        & .MuiOutlinedInput-notchedOutline {
            border: 1px solid rgba(92, 110, 140, 0.1);
        }
    }
`;

export const NumberInput = styled((props: NumericFormatProps) => <NumericFormat {...props} />)<NumericFormatProps>`
    ${InputCSS}
    border: 1px solid rgba(120, 144, 178, 0.1);
    border-radius: 8px;
    overflow: hidden;

    &::placeholder {
        ${InputPlaceholderCSS}
    }

    &:disabled {
        color: #9aa2b0;
        border: 1px solid rgba(92, 110, 140, 0.1);
    }
`;

export const Select = styled(MuiSelect)`
    min-height: 36px;
    background-color: #f6f7fb;

    .MuiInputBase-root {
        min-height: 36px;
        box-sizing: border-box;
        min-width: 0;
        width: fit-content;
        text-overflow: ellipsis;
        outline: none;
    }

    .MuiSelect-select {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-radius: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.01em;
        color: #2b3648;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(120, 144, 178, 0.1);
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(120, 144, 178, 0.1);
    }

    & .Mui-disabled {
        .MuiOutlinedInput-input {
            color: #9aa2b0;
        }

        & .MuiOutlinedInput-notchedOutline {
            border: 1px solid rgba(92, 110, 140, 0.1);
        }
    }
`;

export const SelectItem = styled(MenuItem)``;
