import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

import { TEstimatePosition } from '@/api/estimatePositions';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { UICircleCheckbox } from '@/components/ui/UICircleCheckbox';
import { UITableDeleteButton } from '@/components/ui/UITableDeleteButton';

import { AlertIcon } from '@/shared/SVG/AlertIcon';

import { AlertIconContainer, AlertText, Bold, FullWidthCell } from './TableCell.styles';
import {
    TCheckboxCellRendererProps,
    TDeleteCellRendererProps,
    TTextCellRendererProps,
    TUnitCellRendererProps,
} from './TableCell.types';

export const TextCellRenderer: FC<TTextCellRendererProps> = ({ value }) => {
    return (
        <OverflowableTypographyWithTooltip
            maxRows={2}
            TypographyProps={{
                sx: {
                    background: 'transparent !important',
                },
            }}
        >
            {value}
        </OverflowableTypographyWithTooltip>
    );
};

export const UnitCellRenderer: FC<TUnitCellRendererProps> = ({ value, context, data }) => {
    if (!data) return null;

    const ids = context.positionsByUnitDifference.map((v) => v.id);
    const isVisible = ids.includes(data.id);

    return (
        <>
            <OverflowableTypographyWithTooltip
                maxRows={2}
                TypographyProps={{
                    sx: {
                        background: 'transparent !important',
                    },
                }}
            >
                {value}
            </OverflowableTypographyWithTooltip>
            <AlertIconContainer $isVisible={isVisible}>
                <AlertIcon />
            </AlertIconContainer>
        </>
    );
};

export const CheckboxCellRenderer: FC<TCheckboxCellRendererProps> = ({ checked, data, color, onChange }) => {
    if (data?.selectType === 'secondary') return null;

    return (
        <UICircleCheckbox
            checked={checked}
            onChange={onChange}
            checkedIconProps={{
                sx: {
                    color,
                },
            }}
        />
    );
};

export const DeleteCellRenderer: FC<TDeleteCellRendererProps> = ({ onClick }) => {
    return <UITableDeleteButton onClick={onClick} />;
};

export const FullWidthCellRenderer: FC<ICellRendererParams> = ({ data }) => {
    const tooltipTitle = data.ordinals.length > 0 ? data.ordinals.join(', ') : '';

    return (
        <FullWidthCell>
            {`${data.title} (${data.positionsCount})`}

            {data.ordinals.length > 0 && (
                <AlertText>
                    Внимание! Работы под номером:{' '}
                    <OverflowableTypographyWithTooltip
                        maxRows={1}
                        TooltipProps={{
                            title: tooltipTitle,
                        }}
                        TypographyProps={{
                            sx: {
                                color: '#ed6c02',
                                display: 'inline-block !important',
                                maxWidth: '40%',
                                flexShrink: 0,
                            },
                        }}
                    >
                        {data.ordinals.map(
                            (
                                value: TEstimatePosition['ordinal'],
                                index: number,
                                arr: TEstimatePosition['ordinal'][]
                            ) => (
                                <>
                                    <Bold key={value}>
                                        {' '}
                                        {value}
                                        {arr.length - 1 !== index ? ',' : ''}
                                    </Bold>
                                </>
                            )
                        )}{' '}
                    </OverflowableTypographyWithTooltip>
                    содержат иную единицу измерения
                </AlertText>
            )}
        </FullWidthCell>
    );
};
