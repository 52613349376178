import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { useGetProjectByIdQuery } from '@/api/projects/projects.api';
import { useAutoSendSnzToExecutorsMutation, useGetStatusAutoSendingQuery } from '@/api/snz/snz.api';

import { ButtonsAndWarningEl } from '@/shared/components/ButtonsAndWarning/ButtonsAndWarning';

import { toggleAutoSendSnz } from '@/store/slices/drawersSlice';
import { useAppDispatch } from '@/store/store';

import { timezones } from '../../pages/Projects/Projects.service';
import s from './AutoSendSnz.module.scss';
import { hourArray, minuteArray } from './AutoSendSnz.service';

const SelectEl = ({
    selectValue,
    onChange,
    array,
    placeholder,
}: {
    selectValue: string;
    onChange: (e: SelectChangeEvent) => void;
    array: string[];
    placeholder: string;
}) => {
    return (
        <FormControl
            sx={{
                '.MuiInput-root': {
                    backgroundColor: 'rgb(246 247 251)',
                    border: '1px solid rgba(120, 144, 178, 0.1)',
                    fontSize: '14px',
                    borderRadius: '6px',
                },
                '.MuiSvgIcon-root': {
                    top: 'calc(50% - 0.55em)',
                },
                '.MuiInput-input': {
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                },
                '.MuiSelect-select': {
                    padding: '0.25rem 1rem',
                },
            }}
            size='small'
        >
            <Select
                disableUnderline
                variant='standard'
                displayEmpty
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 'calc(60% - 32px)',
                            '::-webkit-scrollbar': {
                                width: 0,
                            },
                        },
                    },
                }}
                renderValue={selectValue !== '' ? undefined : () => placeholder}
                value={selectValue}
                onChange={onChange}
            >
                <MenuItem
                    sx={{
                        color: '#5c6e8c',
                    }}
                    value={''}
                ></MenuItem>
                {array.map((item) => (
                    <MenuItem
                        key={item}
                        value={item}
                    >
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default function AutoSendSnzDrawer() {
    const [selectValue, setSelectValue] = useImmer({
        morningMinute: '',
        morningHour: '',
        nightMinute: '',
        nightHour: '',
    });

    const { projectId } = useParams();

    const project = useGetProjectByIdQuery({
        id: Number(projectId),
    });
    const statusAutoSendingQuery = useGetStatusAutoSendingQuery({
        projectId: Number(projectId),
    });
    const [autoSendReq] = useAutoSendSnzToExecutorsMutation();

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    useMemo(() => {
        const morning = statusAutoSendingQuery.data?.timeMorning?.split(':');
        const night = statusAutoSendingQuery.data?.timeNight?.split(':');
        setSelectValue({
            morningMinute: morning ? `${morning[1]}м.` : '',
            morningHour: morning ? `${morning[0]}ч.` : '',
            nightMinute: night ? `${night[1]}м.` : '',
            nightHour: night ? `${night[0]}ч.` : '',
        });
    }, [setSelectValue, statusAutoSendingQuery.data?.timeMorning, statusAutoSendingQuery.data?.timeNight]);

    const onSubmit = () => {
        dispatch(toggleAutoSendSnz());
        autoSendReq({
            id: Number(projectId),
            body: {
                autoSendingSNZ: true,
                timeMorning: `${selectValue.morningHour.substring(0, 2)}:${selectValue.morningMinute.substring(0, 2)}`,
                timeNight: `${selectValue.nightHour.substring(0, 2)}:${selectValue.nightMinute.substring(0, 2)}`,
            },
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('auto_sending_snz_saved'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
    };

    return (
        <>
            <h4 className={s.time_header}>Настройка автоотправки СНЗ</h4>
            <section className={s.main}>
                <p className={s.top_description}>
                    Для настройки автоматической отправки укажите время, в которое будет отправляться СНЗ с учетом
                    выбранного региона.
                </p>
                <div className={s.time_zone}>
                    <div className={s.timezone_left}>
                        <FmdGoodIcon
                            sx={{
                                fontSize: '1rem',
                                color: '#5C6E8C',
                            }}
                        />
                        <p>Регион РФ:</p>
                    </div>
                    <div className={s.fake_select}>
                        {timezones.find((v) => v.offset === project.data?.data.timezone)?.title}
                    </div>
                </div>

                <div className={s.time_line}>
                    <p className={s.letter_title}>Суточное наряд задание</p>
                    <div className={s.time_grid}>
                        <div className={s.time_left}>
                            <AccessTimeIcon
                                sx={{
                                    fontSize: '1rem',
                                    color: '#5C6E8C',
                                }}
                            />
                            <p>Время:</p>
                        </div>

                        <SelectEl
                            selectValue={selectValue.morningHour}
                            onChange={(e) =>
                                setSelectValue((d) => {
                                    d.morningHour = e.target.value;
                                })
                            }
                            array={hourArray()}
                            placeholder='Часы'
                        />

                        <SelectEl
                            selectValue={selectValue.morningMinute}
                            onChange={(e) =>
                                setSelectValue((d) => {
                                    d.morningMinute = e.target.value;
                                })
                            }
                            array={minuteArray()}
                            placeholder='Минуты'
                        />
                    </div>
                </div>

                <div className={s.time_line}>
                    <p className={s.letter_title}>Внесение факта</p>
                    <div className={s.time_grid}>
                        <div className={s.time_left}>
                            <AccessTimeIcon
                                sx={{
                                    fontSize: '1rem',
                                    color: '#5C6E8C',
                                }}
                            />
                            <p>Время:</p>
                        </div>
                        <SelectEl
                            selectValue={selectValue.nightHour}
                            onChange={(e) =>
                                setSelectValue((d) => {
                                    d.nightHour = e.target.value;
                                })
                            }
                            array={hourArray()}
                            placeholder='Часы'
                        />

                        <SelectEl
                            selectValue={selectValue.nightMinute}
                            onChange={(e) =>
                                setSelectValue((d) => {
                                    d.nightMinute = e.target.value;
                                })
                            }
                            array={minuteArray()}
                            placeholder='Минуты'
                        />
                    </div>
                </div>
                <div></div>
                <ButtonsAndWarningEl
                    onClose={() => dispatch(toggleAutoSendSnz())}
                    onSubmit={onSubmit}
                    submitText='сохранить'
                    cancelText='отменить'
                />
            </section>
        </>
    );
}
