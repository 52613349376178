import { ICellRendererParams, IHeaderParams } from 'ag-grid-community';
import React from 'react';

import { Work } from '@/api/ksg/ksg.types';
import { dailyChart } from '@/api/msg/msg.types';
import { msgPeopleEquipI } from '@/api/msgEquip/msgEquip.types';

import { monthsList } from '@/shared/constants/date';

import { store } from '@/store/store';

export const getHeadersMonthsMsgEquip = (
    daysArr: Omit<dailyChart, 'drawFact' | 'drawPlan'>[] | undefined,
    callback: (data: ICellRendererParams<msgPeopleEquipI, any, any>, index: number) => React.ReactElement
) => {
    const header = monthsList[store.getState().monthMsg.month] + ', ' + store.getState().monthMsg.year;

    const finalObj: {
        headerName: string;
        suppressMovable: boolean;
        children: {
            field: 'dailyCharts';
            minWidth: 120;
            maxWidth: 120;
            headerName: number;
            suppressMovable: boolean;
            headerComponent: (data: IHeaderParams<Work, any>) => React.ReactElement;
            cellRenderer: (data: ICellRendererParams<msgPeopleEquipI, any, any>) => any;
        }[];
    } = {
        headerName: header,
        suppressMovable: true,
        children: [],
    };
    daysArr?.forEach((obj, index) => {
        finalObj.children.push({
            field: 'dailyCharts',
            minWidth: 120,
            maxWidth: 120,
            headerName: index + 1,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        borderRadius: '0.5rem',
                        padding: '0.5rem 0',
                        backgroundColor:
                            index + 1 === new Date().getDate() &&
                            store.getState().monthMsg.month === new Date().getMonth()
                                ? '#3e5a81'
                                : undefined,
                    }}
                >
                    {data.displayName}
                </div>
            ),
            suppressMovable: true,
            cellRenderer: (data: ICellRendererParams<msgPeopleEquipI, any, any>) => callback(data, index),
        });
    });
    return finalObj;
};
