import '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getEstimatePositionsTabCountersQueryOptions } from '@/api/estimatePositions';

import { EPageName } from '@/shared/constants/pages';

import { consolidatedPricesDefaultFilters, consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { estimatePositionsSelectors } from '@/store/slices/estimatePositions';
import { projectsSelector } from '@/store/slices/projectsSlice';
import { useTypedSelector } from '@/store/store';

import { Nullable } from '@/types/common.types';

import { tabsData, tabsDataIfAddPositions } from './PageTabs.config';
import { Tab, Tabs } from './PageTabs.styles';
import { IPageTabsProps } from './PageTabs.types';

export const ConsolidatedPriceEditPageTabs: FC<IPageTabsProps> = () => {
    const { projectId } = useParams();
    const positionFilters = useTypedSelector(consolidatedPricesSelectors.estimatePositionsFilters);
    const projects = useTypedSelector(projectsSelector);

    const { data: tabCounters } = useQuery({
        ...getEstimatePositionsTabCountersQueryOptions({
            projectId: Number(projectId),
            body: {
                consolidatedFilters: consolidatedPricesDefaultFilters,
                positionFilters,
            },
        }),
        placeholderData: (prev) => prev,
    });

    const positionsCount = tabCounters?.available ?? 0;

    const tabs = useMemo(() => {
        return tabsDataIfAddPositions.map((tab) => ({
            ...tab,
            label: `${tab.label} ${positionsCount ? `(${positionsCount})` : ''}`,
        }));
    }, [positionsCount]);

    return (
        <Tabs value={projects.pageName}>
            {tabs.map(({ value, label }) => (
                <Tab
                    key={value}
                    value={value}
                    label={label}
                />
            ))}
        </Tabs>
    );
};

export const PageTabs: FC<IPageTabsProps> = () => {
    const { projectId } = useParams();
    const positionFilters = useTypedSelector(estimatePositionsSelectors.filters);
    const listType = useTypedSelector(estimatePositionsSelectors.listType);
    const consolidatedFilters = useTypedSelector(consolidatedPricesSelectors.filters);
    const projects = useTypedSelector(projectsSelector);
    const navigate = useNavigate();

    const [pageName, setPageName] = useState<Nullable<EPageName>>(projects.pageName);

    const { data: tabCounters } = useQuery({
        ...getEstimatePositionsTabCountersQueryOptions({
            projectId: Number(projectId),
            body: {
                consolidatedFilters,
                positionFilters,
            },
        }),
        placeholderData: (prev) => prev,
    });

    const positionsCount = listType === 'available' ? tabCounters?.available ?? 0 : tabCounters?.all ?? 0;
    const consolidatedCount = tabCounters?.consolidated ?? 0;

    const counters = useMemo(() => {
        return [positionsCount, consolidatedCount];
    }, [positionsCount, consolidatedCount]);

    const tabs = useMemo(() => {
        return tabsData.map((tab, index) => ({
            ...tab,
            label: `${tab.label} ${counters[index] ? `(${counters[index]})` : ''}`,
        }));
    }, [counters]);

    const handleChangeTab = (value: EPageName) => {
        setPageName(value);
        navigate(`/${value}/${projectId}`);
    };

    return (
        <Tabs
            value={pageName}
            onChange={(_, value) => handleChangeTab(value)}
        >
            {tabs.map(({ value, label }) => (
                <Tab
                    key={value}
                    value={value}
                    label={label}
                />
            ))}
        </Tabs>
    );
};
