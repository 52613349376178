import { AgGridReact } from 'ag-grid-react';
import isNumber from 'lodash/isNumber';
import { MutableRefObject, useCallback } from 'react';

import { TStructureAddAndGroupFolderBody, TStructureInsertFolderBody, TStructureRowType } from '@/api/structure';

import { redrawRow } from '@/shared/utils/agGrid.utils';

import { TCreateSavePayload, TData, TEditSavePayload, TModifyMode, TRowId } from '../StructureManagementTable.types';
import { generateRowId, getNewFolder } from '../utils';
import { useAddAndGroupFolderMutation, useInsertFolderMutation, useUpdateFolderMutation } from './useQueries';

export const useModify = (projectId: number, gridRef: MutableRefObject<AgGridReact<TData> | null>) => {
    const { mutate: mutateFolderAddAndGroup } = useAddAndGroupFolderMutation(Number(projectId)!);
    const { mutate: mutateFolderInsert } = useInsertFolderMutation(Number(projectId)!);
    const { mutate: mutateFolderUpdate } = useUpdateFolderMutation(Number(projectId)!);

    const handleRowCreate = useCallback(
        (entityId: TRowId, type: TStructureRowType) => () => {
            const rowNode = gridRef?.current?.api?.getRowNode(generateRowId({ rowType: type, entityId: entityId }));

            if (!rowNode) {
                throw Error('rowNode is undefined');
            }

            if (!isNumber(rowNode.rowIndex)) {
                throw Error('rowNode.rowIndex is not number');
            }

            gridRef?.current!.api?.applyTransaction({
                add: [getNewFolder(rowNode.id!)],
                addIndex: rowNode.rowIndex,
            });
        },
        []
    );

    const handleRowEdit = useCallback(
        (entityId: TRowId) => () => {
            const rowNode = gridRef?.current?.api?.getRowNode(
                generateRowId({ rowType: 'a_folder', entityId: entityId })
            );

            if (!rowNode) {
                throw Error('rowNode is undefined');
            }

            gridRef?.current!.api?.applyTransaction({
                update: [{ ...rowNode?.data, modifyMode: 'edit' } as TData],
            });
            redrawRow(gridRef?.current?.api, generateRowId({ rowType: 'a_folder', entityId: entityId }));
        },
        []
    );

    const handleRowEditCancel = useCallback((entityId: TRowId) => {
        const rowId = generateRowId({ rowType: 'a_folder', entityId });
        const rowNode = gridRef?.current?.api?.getRowNode(rowId);

        if (!rowNode) {
            throw Error('rowNode is undefined');
        }

        const data = rowNode?.data;

        if (!data) {
            throw Error('data is undefined');
        }

        if (entityId === 'new') {
            gridRef?.current!.api?.applyTransaction({
                remove: [data],
            });
            return;
        }

        if (!('modifyMode' in data)) return;

        const { modifyMode, ...rest } = data;

        gridRef?.current!.api?.applyTransaction({
            update: [rest],
        });
        redrawRow(gridRef?.current?.api, rowId);
    }, []);

    const handleFolderAdd = useCallback(
        (payload: TCreateSavePayload) => {
            const referenceRow = payload?.referenceId ? gridRef.current!?.api.getRowNode(payload.referenceId) : null;

            if (!referenceRow) return;

            if (referenceRow.data?.rowType === 'a_folder') {
                const body: TStructureInsertFolderBody = {
                    name: payload.name,
                    replacedFolderId: Number(referenceRow.data.entityId),
                };
                mutateFolderInsert({ projectId: Number(projectId), body });
                return;
            }

            const body: TStructureAddAndGroupFolderBody = {
                name: payload.name,
                folderRow: payload.folderRow,
                parentId: payload.parentId,
            };
            mutateFolderAddAndGroup({ projectId: Number(projectId), body });
        },
        [projectId]
    );

    const handleFolderUpdate = useCallback(
        (payload: TEditSavePayload) => {
            const body = {
                name: payload.name,
            };

            mutateFolderUpdate({ projectId: Number(projectId), folderId: payload.folderId, body });
        },
        [projectId]
    );

    const handleRowSave = useCallback((mode: TModifyMode, payload: TCreateSavePayload | TEditSavePayload) => {
        if (mode === 'create') {
            handleFolderAdd(payload as TCreateSavePayload);
            return;
        }
        handleFolderUpdate(payload as TEditSavePayload);
    }, []);

    return {
        onRowCreate: handleRowCreate,
        onRowEdit: handleRowEdit,
        onRowEditCancel: handleRowEditCancel,
        onRowSave: handleRowSave,
    };
};
