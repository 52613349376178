import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IColumnTemplate } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.types';

import { Nullable } from '@/types/common.types';

import { RootState } from '../store';

type TInitialState = {
    currentTemplate: Nullable<IColumnTemplate>;
    isRefreshList: boolean;
};

const initialState: TInitialState = {
    currentTemplate: null,
    isRefreshList: false,
};

const slice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        setCurrentTemplate(state, action: PayloadAction<Nullable<IColumnTemplate>>) {
            state.currentTemplate = action.payload;
        },
        refreshList(state) {
            state.isRefreshList = !state.isRefreshList;
        },
    },
});

export const { setCurrentTemplate, refreshList } = slice.actions;
export default slice.reducer;

export const templatesSelector = (state: RootState) => state.templates;
