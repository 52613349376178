import { InfiniteData, QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { RowClassRules } from 'ag-grid-community';
import { useMemo } from 'react';

import { estimatePositionsApi } from '@/api/estimatePositions/estimatePositions.api';
import { TEstimatePositionsResponse } from '@/api/estimatePositions/estimatePositions.types';

import { queryClient } from '@/shared/constants/queryClient';
import { queryKeys } from '@/shared/constants/queryKeys';

import { consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { useTypedSelector } from '@/store/store';

import { LIMIT } from './EstimatePositionsTable.config';
import {
    TData,
    TUseDataProps,
    TUseGetEstimatePositionsProps,
    TUseGetPositionsBySelectType,
} from './EstimatePositionsTable.types';

export const useGetEstimatePositions = ({ projectId, limit = LIMIT }: TUseGetEstimatePositionsProps) => {
    const filters = useTypedSelector(consolidatedPricesSelectors.estimatePositionsFilters);

    return useInfiniteQuery({
        queryKey: [queryKeys.consolidatedPrices.estimatePositionsList, projectId, filters],
        queryFn: ({ pageParam = 0 }) => {
            return estimatePositionsApi.fetchList({
                body: filters,
                limit,
                offset: pageParam,
                projectId: projectId,
                listType: 'available',
            });
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, _, pageParam) => {
            const total = lastPage.total;
            const nextPageParam = pageParam + limit;

            return nextPageParam <= total ? nextPageParam : undefined;
        },
        select: (data) => {
            const positions = data.pages.flatMap((page) => page.positions);
            return {
                ...data,
                positions,
                total: data.pages[0].total,
                requireAttention: data.pages[0].requireAttention,
            };
        },
    });
};

export const useRowClassRules = () => {
    const rowClassRules: RowClassRules<TData> = useMemo(() => {
        return {
            'deleted-row': ({ data }) => {
                if (!data) return false;
                return data.changes.isDeleted;
            },
            'changed-row': ({ data }) => {
                if (!data) return false;
                return data.changes.all;
            },
        };
    }, []);

    return rowClassRules;
};

const useGetAllPositions = () => {
    const keys = queryClient
        .getQueryCache()
        .getAll()
        .map((query) => query.queryKey)
        .filter((key) => (key[0] as string).startsWith(queryKeys.consolidatedPrices.estimatePositionsList));

    const key = keys[0] as QueryKey;
    const data = queryClient.getQueryData(key) as InfiniteData<TEstimatePositionsResponse>;

    return useMemo(() => {
        if (!data) return [];

        const pages = data?.pages ?? [];
        return pages.flatMap((page) => page.positions);
    }, [data]);
};

const useData = ({ projectId }: TUseDataProps) => {
    const checkedPositionsMap = useTypedSelector(
        consolidatedPricesSelectors.estimatePositionsCheckedPositionsMapForEdit
    );
    const positions = useGetAllPositions();

    return useMemo(() => {
        return positions
            .filter((position) => checkedPositionsMap.has(position.id))
            .map((position) => {
                return {
                    ...position,
                    selectType: checkedPositionsMap.get(position.id)!.selectType,
                };
            });
    }, [positions, checkedPositionsMap]);
};

export const useGetPositionsBySelectType = ({ projectId }: TUseGetPositionsBySelectType) => {
    const positions = useData({
        projectId,
    });

    return useMemo(() => {
        const primaryPositions = [];
        const secondaryPositions = [];
        const sortedPositions = positions.sort((a, b) => a.ordinal - b.ordinal);

        for (const position of sortedPositions) {
            if (position.selectType === 'primary') {
                primaryPositions.push(position);
            } else {
                secondaryPositions.push(position);
            }
        }

        return [primaryPositions, secondaryPositions];
    }, [positions]);
};
