import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';

import { monthlyChart, Work } from '@/api/ksg/ksg.types';
import { WorkMsg } from '@/api/msg/msg.types';
import { TCriticalPath } from '@/api/relations/relations.types';

import { TKC6AWork } from '@/pages/AgGridKsgKC6A/AgGridKsgKC6A.types';

import { MIN_ROW_HEIGHT } from '@/shared/constants/agGrid';
import { updateItemById } from '@/shared/utils/common.utils';
import { getParentInfoById, getWorkChildCount } from '@/shared/utils/works.utils';

import { IActiveUploadTaskShortly, Nullable } from '@/types/common.types';

import { RootState } from '../../store';
import { getWorksKC6A } from './ksgKC6AThunks';
import { getWorksAgGrid, getWorksAgGridOrphan } from './ksgThunks';
import { getWorksAgGrid as getWorksAgGridMSG, getWorksAgGridOrphan as getWorksAgGridOrphanMSG } from './msgThunks';

interface IWorksState {
    ksg: {
        worksList: { data: Work[]; total: number; activeUpload?: IActiveUploadTaskShortly } | null;
        trigger: boolean;
        isKSGUpdatedByExcel: boolean;
        isKSGUploaded: boolean;
        rowHeight: number;
        minRowHeight: number;
        isOfferResynchronized: boolean;
        criticalPath: TCriticalPath | null;
    };
    msg: {
        worksList: { data: WorkMsg[]; total: number } | null;
        trigger: boolean;
        isKSGUploaded: boolean;
    };
    kc6a: {
        worksList: { data: TKC6AWork[]; total: number } | null;
        trigger: boolean;
    };
    listMode: boolean;
}

// interface initialStateI {
//     ksg: IWorksState
//     msg:Omit<IWorksState,'isKSGUpdatedByExcel'>
// }

const initialState: IWorksState = {
    ksg: {
        worksList: null,
        trigger: false,
        isKSGUpdatedByExcel: false,
        isKSGUploaded: false,
        rowHeight: MIN_ROW_HEIGHT,
        minRowHeight: MIN_ROW_HEIGHT,
        isOfferResynchronized: false,
        criticalPath: null,
    },
    msg: {
        worksList: null,
        trigger: false,
        isKSGUploaded: false,
    },
    kc6a: {
        worksList: null,
        trigger: false,
    },
    listMode: false,
};

const agGridKsgSlice = createSlice({
    name: 'ag_grid',
    initialState,
    reducers: {
        /* common */

        changeListMode: (state, action: PayloadAction<boolean | undefined>) => {
            if (action.payload !== undefined) {
                state.listMode = action.payload;
            } else {
                state.listMode = !state.listMode;
            }
        },
        /* добавил action для очистки workList */
        clearWorks: (state) => {
            state.msg.worksList = { data: [], total: 0 };
            state.ksg.worksList = { ...state.ksg.worksList, data: [], total: 0 };
        },

        /* KSG */

        onEditAgKsg: (state, action: PayloadAction<Work[]>) => {
            state.ksg.trigger = !state.ksg.trigger;
            action.payload.forEach((work) => {
                if (!state.ksg.worksList) return;
                updateItemById(state.ksg.worksList.data, work);
            });
        },
        updateKsgWorksOrder: (state, action: PayloadAction<Work[]>) => {
            if (!state.ksg.worksList?.data) return;

            state.ksg.worksList.data = action.payload;
        },
        updateKsgWorksCriticalPath: (state, action: PayloadAction<Work['id'][]>) => {
            const newCriticalPath = action.payload;
            const oldCriticalPath = state.ksg.criticalPath;

            if (isEqual(newCriticalPath, oldCriticalPath)) return;
            state.ksg.criticalPath = action.payload;
            const set = new Set(action.payload);

            state.ksg.worksList?.data.forEach((work) => {
                if (set.has(work.id)) {
                    work.critical = true;
                } else {
                    work.critical = false;
                }
            });
        },
        onDeleteRowKsgAg: (state, action: PayloadAction<{ id: number; works: Work[] }>) => {
            state.ksg.trigger = !state.ksg.trigger;
            if (!state.ksg.worksList) return;

            // Обновляем родительские уровни, которые пришли в ответе запроса удаления
            action.payload.works.forEach((work) => {
                if (!state.ksg.worksList) return;
                updateItemById(state.ksg.worksList.data, work);
            });

            // Удаляем родительский уровень с children
            const works = current(state.ksg.worksList.data);
            const [parent, parentIndex] = getParentInfoById(works, action.payload.id);
            const childCount = getWorkChildCount(works, parent!);
            const deleteCount = childCount + 1;
            const start = parentIndex === 0 ? parentIndex + 1 : parentIndex;
            state.ksg.worksList.data.splice(start, deleteCount);
            state.ksg.worksList.total = state.ksg.worksList.data.length;

            if (parentIndex === 0) return;

            // Перерасчет numOrder для нижележащих уровней
            state.ksg.worksList.data = state.ksg.worksList.data.map((work, index) => {
                if (parentIndex > index) return work;

                return {
                    ...work,
                    numOrder: work.numOrder! - deleteCount,
                };
            });
        },

        onCheckTagKsgAg: (state, action: PayloadAction<{ bool: boolean; workId: number | undefined }>) => {
            if (!state.ksg.worksList || !action.payload.workId) return;
            const index = state.ksg.worksList.data.findIndex((f) => f.id === action.payload.workId);
            state.ksg.worksList.data[index].tag = action.payload.bool;
        },

        onKsgInit: (state, action: PayloadAction<{ works: Work[] }>) => {
            state.ksg.trigger = !state.ksg.trigger;
            state.ksg.worksList = {
                data: action.payload.works,
                total: action.payload.works.length,
            };
        },

        onAddWorkKsgAg: (state, action: PayloadAction<{ works: Work[]; parentId: Nullable<number> }>) => {
            state.ksg.trigger = !state.ksg.trigger;

            const updatedWorks = action.payload.works;

            if (!state.ksg.worksList || !updatedWorks) return;

            const works = state.ksg.worksList.data;
            const [parent, parentIndex] = getParentInfoById(current(works), action.payload.parentId);
            const childCount = getWorkChildCount(current(works), parent!);
            const newWork = action.payload.works.at(-1);

            // const parentIndex = works.findIndex((v) => v.id === parent?.id);
            const workIndex = parentIndex + 1 + childCount || 0;

            state.ksg.worksList.data.splice(workIndex, 0, newWork!);
            state.ksg.worksList.total += 1;

            action.payload.works.forEach((work) => {
                if (!state.ksg.worksList) return;
                updateItemById(state.ksg.worksList.data, work);
            });

            state.ksg.worksList.data = state.ksg.worksList.data.map((work, index) => {
                if (index <= workIndex) return work;

                return {
                    ...work,
                    numOrder: work.numOrder! + 1,
                };
            });
        },

        updateKs3KsgAg: (state, action: PayloadAction<monthlyChart[]>) => {
            state.ksg.trigger = !state.ksg.trigger;
            if (!state.ksg.worksList) return;

            state.ksg.worksList.data[0].monthlyCharts = state.ksg.worksList.data[0].monthlyCharts.map((v, index) => {
                return { ...v, kc3: action.payload[index].kc3 };
            });
        },
        toggleTrigger: (state) => {
            state.ksg.trigger = !state.ksg.trigger;
        },
        isOfferResynchronized: (state) => {
            state.ksg.isOfferResynchronized = !state.ksg.isOfferResynchronized;
        },
        onKSGUpdate: (state) => {
            state.ksg.isKSGUpdatedByExcel = !state.ksg.isKSGUpdatedByExcel;
        },
        onKSGUpload: (state) => {
            state.ksg.isKSGUploaded = !state.ksg.isKSGUploaded;
        },
        onEditAgKsgRowHeight: (state, action: PayloadAction<{ rowHeight: number }>) => {
            state.ksg.rowHeight = action.payload.rowHeight;
        },
        onEditAgKsgMinRowHeight: (state, action: PayloadAction<{ minRowHeight: number }>) => {
            state.ksg.minRowHeight = action.payload.minRowHeight;
        },

        /* --- */

        /* MSG */

        onEditAgMSG: (state, action: PayloadAction<WorkMsg[]>) => {
            state.msg.trigger = !state.msg.trigger;
            action.payload.forEach((work) => {
                if (!state.ksg.worksList) return;
                updateItemById(state.msg.worksList!.data, work);
            });
        },

        onDeleteRowMSGAg: (state, action: PayloadAction<{ id: number; works: WorkMsg[] }>) => {
            state.msg.trigger = !state.msg.trigger;
            if (!state.msg.worksList) return;

            action.payload.works.forEach((work) => {
                if (!state.msg.worksList) return;
                updateItemById(state.msg.worksList!.data, work);
            });

            const findItem = state.msg.worksList.data.find((v) => v.id === action.payload.id);
            if (!findItem) return;

            const result = state.msg.worksList.data.filter((item) => {
                return !findItem.code.split('-').every((v) => item.code.split('-').includes(v));
            });
            state.msg.worksList.data = result;
            state.msg.worksList.total = result.length;
        },

        onCheckTagMSGAg: (state, action: PayloadAction<{ bool: boolean; workId: number | undefined }>) => {
            // if (!state.msg.worksList || !action.payload.workId) return
            // const index = state.msg.worksList.data.findIndex((f) => f.id === action.payload.workId)
            // state.msg.worksList.data[index].tag = action.payload.bool
        },

        onAddWorkMSGAg: (state, action: PayloadAction<{ works: WorkMsg[]; id: number | null }>) => {
            state.msg.trigger = !state.msg.trigger;
            if (!state.msg.worksList || !action.payload.works) return;

            const workToAdd = action.payload.works.filter((work) => {
                return (
                    state.msg.worksList &&
                    !state.msg.worksList.data.find((w) => {
                        return work.id === w.id;
                    })
                );
            });

            state.msg.worksList.data.splice(action.payload?.id || 0, 0, workToAdd[0]);
            state.msg.worksList.total += 1;

            action.payload.works.forEach((work) => {
                if (!state.msg.worksList) return;
                updateItemById(state.msg.worksList!.data, work);
            });
        },

        updateKs3MSGAg: (state, action: PayloadAction<monthlyChart[]>) => {
            state.msg.trigger = !state.msg.trigger;
            if (!state.msg.worksList) return;

            state.msg.worksList.data[0].dailyCharts = state.msg.worksList.data[0].dailyCharts.map((v, index) => {
                return { ...v, kc3: action.payload[index].kc3 };
            });
        },
        toggleTriggerMSG: (state) => {
            state.msg.trigger = !state.msg.trigger;
        },
        onMSGUpdate: (state) => {
            // state.msg.isKSGUpdatedByExcel = !state.msg.isKSGUpdatedByExcel
        },
        onMSGUpload: (state) => {
            state.msg.isKSGUploaded = !state.msg.isKSGUploaded;
        },
        /* --- */
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWorksAgGrid.fulfilled, (state, action) => {
                state.ksg.worksList = action.payload;
            })
            .addCase(getWorksAgGridOrphan.fulfilled, (state, action) => {
                state.ksg.worksList = action.payload;
            })
            .addCase(getWorksAgGridMSG.fulfilled, (state, action) => {
                state.msg.worksList = action.payload;
            })
            .addCase(getWorksAgGridOrphanMSG.fulfilled, (state, action) => {
                state.msg.worksList = action.payload;
            })
            .addCase(getWorksKC6A.fulfilled, (state, action) => {
                state.kc6a.worksList = action.payload;
            });
    },
});

export const {
    onKsgInit,
    onEditAgKsg,
    updateKsgWorksOrder,
    updateKsgWorksCriticalPath,
    changeListMode,
    clearWorks,
    onDeleteRowKsgAg,
    onCheckTagKsgAg,
    onAddWorkKsgAg,
    updateKs3KsgAg,
    toggleTrigger,
    isOfferResynchronized,
    onKSGUpdate,
    onKSGUpload,
    onEditAgMSG,
    onEditAgKsgRowHeight,
    onEditAgKsgMinRowHeight,
} = agGridKsgSlice.actions;
export default agGridKsgSlice.reducer;

export const agGridKsgSelector = (state: RootState) => state.ag_grid_ksg.ksg;
export const agGridMsgSelector = (state: RootState) => state.ag_grid_ksg.msg;
export const agGridKC6ASelector = (state: RootState) => state.ag_grid_ksg.kc6a;
export const agGridListMode = (state: RootState) => state.ag_grid_ksg.listMode;
