import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { authSelector } from '@/store/slices/authSlice';
import { useTypedSelector } from '@/store/store';

export default function SocketTest() {
    const [messageHistory, setMessageHistory] = useState<any>(null);
    const { token } = useTypedSelector(authSelector);
    const { id } = useParams();

    const instan = useWebSocket('wss://dev.api.rm.pragma.info/single-task');

    useEffect(() => {
        if (instan.lastMessage !== null) {
            setMessageHistory(JSON.parse(instan.lastMessage?.data || '{}'));
        }
    }, [instan.lastMessage, setMessageHistory]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[instan.readyState];

    return (
        <div>
            <button
                onClick={() => {
                    instan.sendMessage(
                        JSON.stringify({
                            type: 'auth',
                            id: 1,
                            bodyJson: JSON.stringify({ token: String(token) }),
                        })
                    );
                }}
            >
                auth
            </button>

            <button
                onClick={() => {
                    instan.sendMessage(
                        JSON.stringify({
                            type: 'get_ksg_part',
                            id: 1,
                            bodyJson: JSON.stringify({ projectID: String(id), partSize: 500 }),
                        })
                    );
                }}
            >
                ksg
            </button>
            <span>The WebSocket is currently {connectionStatus}</span>
            <p>{JSON.stringify(messageHistory)}</p>

            <ul></ul>
        </div>
    );
}
