import { LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { Checkbox, Switch } from '@mui/material';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { useState } from 'react';

import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';

import { isColGroupDef } from '@/shared/guards/common.guards';
import { extractColIds } from '@/shared/utils/agGrid.utils';

import { StyledRowWrapper } from './StyledColumnSettingsRow.styles';
import {
    IStyledColumnSettingsRowProps,
    TIsColumnState,
    TSetAgGridColumnState,
    TSetColumnState,
} from './StyledColumnSettingsRow.types';

export function StyledColumnSettingsRow({
    colDef,
    BoxProps,
    AgGrid,
    setParentRefresh,
    setIsFormChanged,
}: IStyledColumnSettingsRowProps) {
    const [_refresh, setRefresh] = useState(false);

    const isGroup = isColGroupDef(colDef);

    const setColumnState: TSetColumnState = (setAgGridColumnState) => (_, checked) => {
        setIsFormChanged(() => true);
        setAgGridColumnState(extractColIds(colDef), checked);
        setParentRefresh && setParentRefresh((prevState) => !prevState);
        setRefresh((prevState) => !prevState);
    };

    const setColumnVisible: TSetAgGridColumnState = (keys, visible) =>
        AgGrid?.columnApi?.setColumnsVisible(keys, visible);
    const setColumnPinned: TSetAgGridColumnState = (keys, pinned) => AgGrid?.columnApi?.setColumnsPinned(keys, pinned);

    const isVisible: TIsColumnState = (colId) => AgGrid?.columnApi?.getColumn(colId)?.isVisible() === true;
    const isPinned: TIsColumnState = (colId) => AgGrid?.columnApi?.getColumn(colId)?.isPinned() === true;

    const checkGroup = (colIds: string[], checkFn: TIsColumnState) => colIds.some((v) => checkFn(v));

    const isChecked = (checkFn: TIsColumnState) =>
        isGroup ? checkGroup(extractColIds(colDef), checkFn) : checkFn(colDef.colId as string);

    return (
        <FlexColumnWrapper
            p={0}
            width='100%'
            {...BoxProps}
        >
            <StyledRowWrapper>
                <Switch
                    size='small'
                    checked={isChecked(isVisible)}
                    onChange={setColumnState(setColumnVisible)}
                />
                {colDef.headerName}

                <FlexRowWrapper ml={'auto'}>
                    <Checkbox
                        disableRipple
                        checked={isChecked(isPinned)}
                        onChange={setColumnState(setColumnPinned)}
                        sx={{ p: 0 }}
                        icon={<LockOpenOutlined />}
                        checkedIcon={<LockOutlined />}
                    />
                </FlexRowWrapper>
            </StyledRowWrapper>
            {(colDef as ColGroupDef)?.children?.map((childColDef) => {
                return (
                    <StyledColumnSettingsRow
                        setIsFormChanged={setIsFormChanged}
                        key={(childColDef as ColDef).colId}
                        setParentRefresh={setRefresh}
                        parentColDef={colDef}
                        colDef={childColDef}
                        BoxProps={{ pl: 2 }}
                        AgGrid={AgGrid}
                    />
                );
            })}
        </FlexColumnWrapper>
    );
}
