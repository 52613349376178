import { AgGridReact } from 'ag-grid-react';
import styled, { css } from 'styled-components';

export const AgGridContainerCSS = css`
    --ag-selected-row-background-color: rgba(25, 118, 210, 0.08);

    .p0 {
        padding: 0;
    }

    .ag-row-hover {
        &::before {
            background-color: transparent;
        }
    }

    .ag-row-folder {
        .ag-cell-without-border {
            border-right-color: transparent;
        }
    }

    .ag-cell-hover-over {
        background-color: #2196f3;
        background-color: rgba(33, 150, 243, 0.1);
    }

    .ag-row-consolidated-has-parent {
        background-color: #f6f7fb;
    }
`;

export const AgGrid = styled(AgGridReact)``;
