import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material';
import { GetRowIdParams, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import {
    StyledDrawer,
    StyledDrawerAlert,
    StyledDrawerContent,
    StyledDrawerSearch,
} from '@/components/StyledDrawer/StyledDrawer.styles';

import { StyledTooltip } from '@/shared/components/StyledTooltip';

import { drawersSelector } from '@/store/slices/drawersSlice';
import { useTypedSelector } from '@/store/store';

import { theme } from '@/styles/theme';

import { FlexColumnWrapper, FlexRowWrapper } from '../../../NewExecutorView/components/components.styles';
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog';
import { COPY_MODE_PARAMS, onSubmitRejected } from './CopyResourcesDrawer.service';
import { ICopyResourcesDrawerProps, IWorkForCopy } from './CopyResourcesDrawer.types';
import { StyledTextBtn, StyledWorkListCopyAgGrid } from './WorkList/WorkListStyles';
import { workListColDef } from './WorkList/WorksList.colDef';

export function CopyResourcesDrawer({
    DrawerProps,
    title,
    descriptionSection,
    warningSection,
    copyMode,
    getWorkListMethod,
    onCopy,
}: ICopyResourcesDrawerProps) {
    const { projectId, workID } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [workList, setWorkList] = useState<IWorkForCopy[] | null>(null);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const gridRef = useRef<AgGridReact>(null);
    const [isCopyCosts, setIsCopyCosts] = useState<boolean>(false);

    const {
        workManagmentResourcesGridRefs: { setTriggerRefresh },
    } = useTypedSelector(drawersSelector);

    function extractChosenRowData() {
        let extractedData: Partial<IWorkForCopy>[] = [];
        gridRef?.current?.api?.forEachNode((node) => {
            node?.data?.checked && extractedData?.push(node.data);
        });
        return extractedData.map(
            (work) =>
                ({
                    choice: {
                        ...work.choice,
                    },
                    workId: work.workId,
                } as Partial<IWorkForCopy>)
        );
    }

    const redrawRows = () => {
        gridRef?.current?.api.forEachNode((row) => {
            gridRef?.current?.api.redrawRows({ rowNodes: [row] });
        });
    };

    const toggleIsCopyCosts = (checked: boolean) => {
        setIsCopyCosts(() => checked);

        redrawRows();

        if (!checked) return;

        let checkedRowCount = 0;

        gridRef?.current?.api.forEachNodeAfterFilter((row) => {
            if (row.data?.checked) {
                checkedRowCount += 1;
            }
        });

        if (checkedRowCount <= 1) return;

        gridRef?.current?.api.forEachNodeAfterFilter((row) => {
            gridRef?.current?.api.applyTransaction({ update: [{ ...row.data, checked: false }] });
            gridRef?.current?.api.redrawRows({ rowNodes: [row] });
        });
    };

    const isButtonsDisabled = !workList || workList?.length === 0;
    const isCheckAllButtonDisabled = isButtonsDisabled || isCopyCosts;

    function onSubmit() {
        const body = extractChosenRowData();
        if (body.length === 0) {
            return enqueueSnackbar('Выберите работу', { variant: 'error' });
        }
        if (onCopy) {
            onCopy(extractChosenRowData(), setIsFetching);
            return;
        }
        setIsFetching(() => true);

        COPY_MODE_PARAMS[copyMode]
            .submitReq<{ copyCosts?: boolean }>({
                projectId: projectId as string,
                workID: workID as string,
                body: body,
                copyCosts: isCopyCosts,
            })
            .then(() => {
                DrawerProps.onClose && DrawerProps.onClose({}, 'backdropClick');
                setIsCopyCosts(() => false);
                copyMode !== 'into' && setTriggerRefresh && setTriggerRefresh((prevState) => !prevState);
                setIsFetching(() => false);
            })
            .catch((e) => {
                onSubmitRejected({
                    error: e,
                    enqueueSnackbar: enqueueSnackbar,
                    setIsFetching: setIsFetching,
                });
            });
    }

    function onSearch(value: string) {
        gridRef.current?.api.setQuickFilter(value);
    }

    function triggerOnClose() {
        let hasCheckedWorks = false;
        gridRef.current?.api.forEachNode((node) => {
            if (node.data.checked) {
                hasCheckedWorks = true;
            }
        });
        if (hasCheckedWorks) {
            setIsConfirmDialogOpen(() => true);
        } else {
            DrawerProps.onClose && DrawerProps.onClose({}, 'backdropClick');
        }
        setIsCopyCosts(() => false);
    }

    function checkAll() {
        if (isCheckAllButtonDisabled) return;

        reset();
        gridRef?.current?.api.forEachNodeAfterFilter((row) => {
            gridRef?.current?.api.applyTransaction({ update: [{ ...row.data, checked: true }] });
            gridRef?.current?.api.redrawRows({ rowNodes: [row] });
        });
    }
    function reset() {
        gridRef?.current?.api.forEachNode((row) => {
            gridRef?.current?.api.applyTransaction({
                update: [
                    {
                        ...row.data,
                        checked: false,
                        choice: {
                            ...row.data?.choice,
                            ...row.data.initialChoice,
                        },
                    },
                ],
            });
            gridRef?.current?.api.redrawRows({ rowNodes: [row] });
        });
    }

    useEffect(() => {
        const targetReq = getWorkListMethod
            ? getWorkListMethod()
            : COPY_MODE_PARAMS[copyMode].getListReq({
                  projectId: projectId as string,
                  workID: workID as string,
              });
        if (DrawerProps.open === true) {
            targetReq.then(({ data }: any) => {
                setWorkList((prevState) =>
                    data.data.map((work: IWorkForCopy) => ({
                        ...work,
                        checked: false,
                        initialChoice: {
                            ...work.choice,
                        },
                    }))
                );
            });
        }
        return () => {
            setWorkList(() => null);
        };
    }, [DrawerProps.open, copyMode, projectId, workID]);

    return (
        <StyledDrawer
            {...DrawerProps}
            onClose={triggerOnClose}
            width={800}
            sx={{ position: 'relative' }}
        >
            {title ? title : COPY_MODE_PARAMS[copyMode].title}
            <StyledDrawerContent sx={{ pointerEvents: isFetching ? 'none' : 'auto' }}>
                {descriptionSection ? descriptionSection : COPY_MODE_PARAMS[copyMode].descriptionSection}
                <FlexColumnWrapper gap={0}>
                    <StyledDrawerAlert>ВНИМАНИЕ!</StyledDrawerAlert>
                    {warningSection ? warningSection : COPY_MODE_PARAMS[copyMode].warningSection}
                </FlexColumnWrapper>

                <StyledDrawerSearch
                    placeholder='Поиск'
                    onChange={(e) => onSearch(e.target.value)}
                    disabled={isButtonsDisabled}
                />
                <FlexRowWrapper
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    width={'100%'}
                >
                    <StyledTooltip title={isCopyCosts ? 'Можно выбрать только одну работу' : ''}>
                        <StyledTextBtn
                            onClick={() => checkAll()}
                            disabled={isCheckAllButtonDisabled}
                            sx={{
                                '&.Mui-disabled': {
                                    pointerEvents: 'auto',
                                },
                            }}
                        >
                            Выбрать все
                        </StyledTextBtn>
                    </StyledTooltip>
                    <StyledTextBtn
                        onClick={() => reset()}
                        disabled={isButtonsDisabled}
                    >
                        Сбросить
                    </StyledTextBtn>

                    {copyMode === 'from' && (
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={1}
                            marginLeft={'auto'}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{ p: 0 }}
                                        disableRipple
                                        checked={isCopyCosts}
                                        size='small'
                                        onChange={(e, checked) => toggleIsCopyCosts(checked)}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            color: theme.palette.primary.main,
                                            letterSpacing: '0.02857em',
                                            textTransform: 'uppercase',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            userSelect: 'none',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Со стоимостью
                                    </Typography>
                                }
                                sx={{
                                    gap: 1,
                                    alignItems: 'center',
                                }}
                            />
                        </Stack>
                    )}
                </FlexRowWrapper>

                <Box style={{ height: '100%', width: '100%', paddingRight: '0.5rem' }}>
                    <StyledWorkListCopyAgGrid
                        ref={gridRef}
                        columnDefs={workListColDef}
                        rowData={workList}
                        headerHeight={40}
                        rowHeight={50}
                        defaultColDef={{
                            cellRenderer: (params: ICellRendererParams) => (
                                <OverflowableTypographyWithTooltip
                                    maxRows={2}
                                    TooltipProps={{
                                        leaveDelay: 0,
                                        placement: 'right',
                                        TransitionProps: {
                                            timeout: 0,
                                        },
                                    }}
                                >
                                    {params.value}
                                </OverflowableTypographyWithTooltip>
                            ),
                        }}
                        context={{
                            copyMode,
                            isCopyCosts,
                        }}
                        getRowId={(params: GetRowIdParams<any>) => {
                            return params.data?.workId;
                        }}
                        overlayNoRowsTemplate={'Нет данных'}
                    />
                </Box>
                <FlexRowWrapper
                    width={'60%'}
                    mt={'auto'}
                >
                    {copyMode !== 'delete' ? (
                        <Button
                            size='medium'
                            color={'success'}
                            variant='contained'
                            fullWidth
                            onClick={(e) => onSubmit()}
                            disabled={isButtonsDisabled || isFetching}
                            startIcon={isFetching && <CircularProgress size={20} />}
                        >
                            Скопировать
                        </Button>
                    ) : (
                        <Button
                            size='medium'
                            color={'error'}
                            variant='contained'
                            fullWidth
                            onClick={(e) => onSubmit()}
                            disabled={isButtonsDisabled || isFetching}
                            startIcon={isFetching && <CircularProgress size={20} />}
                            sx={{ color: 'white' }}
                        >
                            Удалить ресурсы
                        </Button>
                    )}
                    <Button
                        size='medium'
                        variant='contained'
                        fullWidth
                        onClick={triggerOnClose}
                        disabled={isFetching}
                    >
                        Отменить
                    </Button>
                </FlexRowWrapper>
            </StyledDrawerContent>
            <ConfirmDialog
                open={isConfirmDialogOpen}
                title={'Вы уверены, что хотите завершить копирование'}
                message={'Все несохраненные данные будут удалены'}
                onYes={{
                    action: (e) => {
                        DrawerProps.onClose && DrawerProps.onClose({}, 'backdropClick');
                        setIsConfirmDialogOpen(() => false);
                    },
                }}
                onNo={{
                    action: (e) => {
                        setIsConfirmDialogOpen(() => false);
                    },
                }}
            />
        </StyledDrawer>
    );
}
