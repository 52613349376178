import styled from 'styled-components';

import { ResetButton } from '../UIButton';

export const Button = styled(ResetButton)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 177, 177, 0.15);
    margin-inline: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &::before {
        content: '';
        background-color: #f46b6b;
        width: 12px;
        height: 2px;
    }

    &:hover {
        background-color: rgba(255, 177, 177, 0.3);
    }
`;
