import { AgGridReact } from 'ag-grid-react';
import { MutableRefObject, useCallback, useState } from 'react';

import { TStructureRowType } from '@/api/structure';

import { refreshCell } from '@/shared/utils/agGrid.utils';

import { TData, TRowId } from '../StructureManagementTable.types';
import { generateRowId } from '../utils';

export const useCollapse = (gridRef: MutableRefObject<AgGridReact<TData> | null>) => {
    const [collapsedRowIds, setCollapsedRowIds] = useState<Set<TRowId>>(new Set());

    const handleRowCollapse = useCallback(
        (type: TStructureRowType, entityId: TRowId) => () => {
            const key = generateRowId({ rowType: type!, entityId });

            setCollapsedRowIds((prev) => {
                const set = new Set(prev);

                if (set.has(key)) {
                    set.delete(key);
                } else {
                    set.add(key);
                }

                return set;
            });
            setTimeout(() => refreshCell(gridRef?.current?.api, key, 'collapse'));
        },
        []
    );

    const addCollapsedRowIds = (keys: string[]) => {
        setCollapsedRowIds((prev) => {
            const set = new Set(prev);
            keys.forEach((v) => set.add(v));
            return set;
        });
        setTimeout(() => refreshCell(gridRef?.current?.api, null, 'collapse'));
    };

    return {
        collapsedRowIds,
        onRowCollapse: handleRowCollapse,
        addCollapsedRowIds: addCollapsedRowIds,
    };
};
