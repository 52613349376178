import { CellValueChangedEvent, ICellRendererParams, IHeaderParams } from 'ag-grid-community';

import { isAutocompleteOption } from '@/shared/guards/common.guards';

import { CollectionsAPI } from '../../api/CollectionsAPI';
import { ResourceAPI } from '../../api/ResourcesAPI';
import { IProff } from '../../api/api.types';
import { IAutocompleteOption } from "../../components/CellEditors/CellEditor/CellEditor.types";
import { IProfAutocompleteOption, IStaffResource } from './ResoursePage.types';

export function addNewRow(templateRow: any, params: IHeaderParams) {
    let rowDataCurrent: any[] = [];
    params.api.forEachNode((node) => {
        rowDataCurrent.push(node.data);
    });
    ResourceAPI.addNewItem({
        resourceType: params.context.resourceType,
        projectID: params.context.projectID,
        workID: params.context.workID,
        body: templateRow,
    }).then((data) => {
        rowDataCurrent.push(data);
        params.api.setRowData(rowDataCurrent);
    });
}

export function removeRow(params: ICellRendererParams) {
    ResourceAPI.deleteItem({
        resourceType: params.context.resourceType,
        projectID: params.context.projectID,
        workID: params.context.workID,
        resourceID: params.data.id,
    }).then(() => params.api.applyTransaction({ remove: [params.data] }));
}

export function addNewStaff(event: CellValueChangedEvent, resource?: Partial<IStaffResource>) {
    event.api.showLoadingOverlay();
    ResourceAPI.addNewItem({
        resourceType: event.context.resourceType,
        projectID: event.context.projectID,
        workID: event.context.workID,
        resourceID: event.data.id,
        body: resource || {
            nameProf: event.newValue.label,
            profId: event.newValue.value,
            plan: 0,
        },
    })
        .then((data) => {
            const editingCells = event.api.getEditingCells();
            event.api.applyTransaction({
                remove: [{ id: 'draft' }],
                add: [data],
                addIndex: event.rowIndex,
            });
            editingCells.length > 0 &&
                event.api.startEditingCell({
                    rowIndex: editingCells[0].rowIndex,
                    colKey: editingCells[0].column.getColId(),
                });
            event.api?.hideOverlay();
        })
        .catch((e) => {
            event.api?.hideOverlay();
        });
}

export function updateNameProf(profID: number, event: CellValueChangedEvent) {
    ResourceAPI.updateItem({
        resourceType: event.context.resourceType,
        projectID: event.context.projectID,
        workID: event.context.workID,
        resourceID: event.data.id,
        body: { ...event.data, profId: profID },
    }).then((data) => {
        event.api.applyTransactionAsync({
            update: [data],
        });
    });
}

export function addNewProfName(event: CellValueChangedEvent, createResource?: 'createResource') {
    CollectionsAPI.addNewProff(event.newValue).then((data) => {
        event.api.applyTransactionAsync({
            update: [{ nameProf: data.name }],
        });
        createResource &&
            addNewStaff(event, {
                nameProf: data.name,
                profId: data.id,
                plan: 0,
            });
    });
}

const isProf = (data: any): data is IProff => {
    return data && typeof data === 'object' && typeof data?.id === 'number' && typeof data?.name === 'string';
};

const isProfAutocompleteOption = (option: any): option is IProfAutocompleteOption => {
    if (!isAutocompleteOption(option)) return false;
    if (!('data' in (option as IAutocompleteOption))) return false;
    if (!isProf(option?.data)) return false;
    return true;
};

export const getOrCreateProf = async (
    selectedOption: IAutocompleteOption | IProfAutocompleteOption | string,
    list: IAutocompleteOption[]
): Promise<IProff | null> => {
    if (isProfAutocompleteOption(selectedOption)) return selectedOption.data;

    const selectedProf: string = isAutocompleteOption(selectedOption) ? selectedOption.label : selectedOption;
    const matchedProf = list.find((prof: IAutocompleteOption) => prof.label === selectedProf);
    if (isProfAutocompleteOption(matchedProf)) return matchedProf.data;

    try {
        return await CollectionsAPI.addNewProff(selectedProf);
    } catch (e) {
        return null;
    }
};

export function addNewMim(event: CellValueChangedEvent, resource?: Partial<IStaffResource>) {
    event.api.showLoadingOverlay();
    ResourceAPI.addNewItem({
        resourceType: event.context.resourceType,
        projectID: event.context.projectID,
        workID: event.context.workID,
        resourceID: event.data.id,
        body: resource || {
            name: event.newValue,
            plan: 0,
        },
    })
        .then((data) => {
            const editingCells = event.api.getEditingCells();
            event.api.applyTransaction({
                remove: [{ id: 'draft' }],
                add: [data],
                addIndex: event.rowIndex,
            });
            editingCells.length > 0 &&
                event.api.startEditingCell({
                    rowIndex: editingCells[0].rowIndex,
                    colKey: editingCells[0].column.getColId(),
                });
            event.api?.hideOverlay();
        })
        .catch((e) => {
            event.api?.hideOverlay();
        });
}
