import { TEstimatePositionsListType } from '@/store/slices/estimatePositions';

export const data = [
    {
        label: 'Актуальные',
        value: 'available' as TEstimatePositionsListType,
    },
    {
        label: 'Все',
        value: 'all' as TEstimatePositionsListType,
    },
];
