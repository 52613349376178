import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Stack)`
    gap: 4px;
    height: 53px;
`;

export const Label = styled(Typography)`
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #5c6e8c;
    padding-left: 8px;
`;

export const Box = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'row',
    gap: '8px',
})`
    border-radius: 8px;
    padding: 8px 16px;
    width: 120px;
    height: 36px;
    background: rgba(43, 54, 72, 0.04);
`;

export const Count = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center',
})`
    border-radius: 50%;
    padding: 2px 5px;
    height: 20px;
    min-width: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    border: 1px solid transparent;
`;

export const ChangedCount = styled(Count)`
    background: #fef08a;
    color: #2b3648;
    border-color: #fef08a;
`;

export const DeletedCount = styled(Count)`
    background: #9aa2b0;
    color: #fff;
    border-color: #9aa2b0;
`;
