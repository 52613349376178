import { TEstimatePositionUnitType, TEstimatePositionUnitTypeValue } from '@/api/estimatePositions';

export const unitTypeEnToRu: Record<TEstimatePositionUnitType, TEstimatePositionUnitTypeValue> = {
    piece: 'шт',
    meter: 'м',
    square_meter: 'м2',
    cubic_meter: 'м3',
    kg: 'кг',
    tonne: 'т',
};

export const options = Object.entries(unitTypeEnToRu).map(([key, value]) => ({ value: key, label: value }));
