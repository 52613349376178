import { ArrowBack as MuiArrowBack } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

export const Container = styled('header')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    box-shadow: inset 0 -1px 0 0 #d1d8fa;
    height: 64px;
`;

export const Left = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    gap: '40px',
})``;

export const Right = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    gap: '12px',
})``;

export const BackButton = styled(Button).attrs({
    variant: 'text',
    type: 'button',
    disableRipple: true,
})`
    font-weight: 500;
    font-size: 13px;
    line-height: 169%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #0044b4;
    width: 79px;
    height: 30px;
`;

export const ArrowBackIcon = styled(MuiArrowBack)`
    font-size: 16px !important;
`;

export const Title = styled(Typography).attrs({
    variant: 'h3',
})`
    font-weight: 500;
    font-size: 16px;
    color: #2b3648;
`;

export const HelpButton = styled(IconButton)`
    border-radius: 6px;
    width: 36px;
    height: 36px;
    background: rgba(25, 118, 210, 0.08);
    color: #0044b4;
`;

export const HelpIcon = styled(HelpOutlineIcon)``;

export const SaveButton = styled(Button).attrs({
    variant: 'contained',
})`
    font-weight: 500;
    font-size: 13px;
    line-height: 169%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #fff;
    border-radius: 6px;
    padding: 6px 8px;
    width: 180px;
    height: 36px;
    background: #2e7d32;
    transition: background 0.3s, color 0.3s;
    box-shadow: none;

    &:hover {
        background-color: #3a993e;
        box-shadow: none;
    }

    &.Mui-disabled {
        background: #9aa2b0;
        color: #f6f7fb;
    }
`;
