import { isAfter, isBefore, isValid } from 'date-fns';

import { TInputType } from '../../components.types';
import { ICustomCellEditorParams } from "./CellEditor.types";

export const MIN_DATE = new Date(1999, 11, 31);
export const MAX_DATE = new Date(2050, 0, 1);

export function getDefaultValidationRules(dataType: TInputType) {
    const defaultValidationrules = {
        required: {
            value: true,
            message: 'Заполните поле',
        },
    };

    let specificDefaultRules = {} as ICustomCellEditorParams['validation'];
    switch (dataType) {
        case 'number':
            specificDefaultRules = {
                validate: {
                    maxValue: (v: any) => {
                        return parseFloat(v) <= 9999 || 'Макс. 9999';
                    },
                },
            };
            break;
        case 'textarea':
            specificDefaultRules = {
                maxLength: {
                    value: 1000,
                    message: 'Макс. 1000 символов',
                },
            };
            break;
        case 'text':
            specificDefaultRules = {
                maxLength: {
                    value: 1000,
                    message: 'Макс. 1000 символов',
                },
            };
            break;
        case 'date':
            specificDefaultRules = {
                validate: {
                    invalidDate: (v) => {
                        return (v && isValid(v)) || 'Неправильный формат даты';
                    },
                    maxDate: (v) => {
                        return isBefore(v, MAX_DATE) || 'Макс. 01.01.2050';
                    },
                    minDate: (v) => {
                        return isAfter(v, MIN_DATE) || 'Мин. 01.01.2000';
                    },
                },
            };
            break;
        case 'autocomplete':
            specificDefaultRules = {
                required: false,
            };
            break;
    }

    return {
        ...defaultValidationrules,
        ...specificDefaultRules,
    };
}

export const getTooltipProps = (errorMessage: string, infoMessage: string | undefined) => {
    if (errorMessage) {
        return {
            title: errorMessage,
            color: 'error',
            open: true,
        };
    }

    if (infoMessage) {
        return {
            title: infoMessage,
            color: 'info',
            open: true,
        };
    }

    return {
        title: '',
        color: 'error',
        open: false,
    };
};
