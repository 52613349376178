import { ArrowDropDown, Delete } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, CircularProgress, ClickAwayListener, Typography } from '@mui/material';
import { CellEditingStoppedEvent, GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetExecutorsQuery } from '@/api/company/company.api';

import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';

import { profileSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

import { AutocompleteInput } from '../../../../components/ControlableInputs/AutocompleteInput';
import { TextInput } from '../../../../components/ControlableInputs/TextInput';
import { FEInputWrapper, FELabel } from '../../../../components/FormElements/FormElements.styles';
import { OverflowableTypographyWithTooltip } from '../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { useDidMountEffect } from '../../../../hooks/useDidMountEffect';
import { FlexColumnWrapper, FlexRowWrapper } from '../../../NewExecutorView/components/components.styles';
import { CollectionsAPI } from '../../../WorkManagment/api/CollectionsAPI';
import { req } from '../../../WorkManagment/api/api';
import { TResourceType } from '../../../WorkManagment/api/api.types';
import { StyledAgGrid } from '../../../WorkManagment/components/AgGridService/AgGrid.styles';
import {
    AG_GRID_DEFAULT_PARAMS,
    disableAddAndDelete,
} from '../../../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { IAutocompleteOption } from '../../../WorkManagment/components/CellEditors/CellEditor/CellEditor.types';
import { ConfirmDialog } from '../../../WorkManagment/components/ConfirmDialog/ConfirmDialog';
import { CopyResourcesDrawer } from '../../../WorkManagment/components/CopyResourcesDrawer/CopyResourcesDrawer';
import { onSubmitRejected } from '../../../WorkManagment/components/CopyResourcesDrawer/CopyResourcesDrawer.service';
import { IWorkForCopy } from '../../../WorkManagment/components/CopyResourcesDrawer/CopyResourcesDrawer.types';
import { StyledBtn } from '../../../WorkManagment/components/components.styles';
import { addNewProfName } from '../../../WorkManagment/subPages/Resources/ResourcePage.service';
import { StyledLoadingOverlay } from '../../../WorkManagment/subPages/Resources/ResourcesPage.styles';
import { IMIMResource, IResource, IStaffResource } from '../../../WorkManagment/subPages/Resources/ResoursePage.types';
import { PRESET_COLORS_V3 } from '../../BrigadesPage.service';
import { AddBrigadePageWrapper, StyledCard } from '../../BrigadesPage.styles';
import { IBrigade, IBrigadeMim, IBrigadeStaff } from '../../BrigadesPage.types';
import { ColorPickerPopper, ColorPickerWrapper } from './AddBrigadePage.styles';
import { mimTableColumnDef } from './Mim.colDef';
import { staffTableColumnDef } from './Staff.colDef';

export function AddBrigadePage() {
    const { profile } = useTypedSelector(profileSelector);
    const { projectId, brigadeID } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [brigadeToEdit, setBrigadeToEdit] = useState<null | IBrigade>(null);
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const [isCopyDrawerOpen, setIsCopyDrawerOpen] = useState(false);
    const [executors, setExecutors] = useState<null | IAutocompleteOption[]>(null);
    const [proffList, setProffList] = useState<IAutocompleteOption[]>([]);
    const [staffList, setStaffList] = useState<IBrigadeStaff[]>([]);
    const [mimList, setMimList] = useState<IBrigadeMim[]>([]);
    const [isConfirmDeleteRowDialogOpen, setIsConfirmDeleteRowDialogOpen] = useState<boolean>(false);
    const [isConfirmLeavingDialogOpen, setIsConfirmLeavingDialogOpen] = useState<boolean>(false);
    const [isConfirmDeleteBrigadeDialogOpen, setIsConfirmDeleteBrigadeDialogOpen] = useState<boolean>(false);
    const [cellEditingStoppedEvent, setCellEditingStoppedEvent] = useState<CellEditingStoppedEvent | null>(null);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const staffGridRef = useRef<AgGridReact>(null);
    const mimGridRef = useRef<AgGridReact>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const getExecutors = useGetExecutorsQuery(
        {
            companyID: profile?.company?.companyID,
        },
        {
            skip: !profile?.company?.companyID,
        }
    );

    const methods = useForm({
        mode: 'all',
        defaultValues: {
            name: brigadeToEdit?.name || '',
            executor: executors?.find((executor) => executor?.name === brigadeToEdit?.executor?.name) || {
                label: '',
                value: '',
            },
            employeeID: brigadeToEdit?.executor?.id || '',
            executorCompany: brigadeToEdit?.executor?.company || '',
            color: brigadeToEdit?.color || PRESET_COLORS_V3[4],
            description: brigadeToEdit?.description || '',
        },
    });

    useEffect(() => {
        setExecutors(
            () =>
                getExecutors?.data?.data?.map((executor: any) => ({
                    label: executor.name,
                    value: executor.employeeID,
                    ...executor,
                })) || []
        );
    }, [getExecutors.data]);

    useEffect(() => {
        CollectionsAPI.getProffList({
            limit: 9999,
            offset: 0,
            filter: '',
        }).then(({ data }) => {
            setProffList((prevState) =>
                data.map((proff) => ({
                    label: proff.name,
                    value: proff.id.toString(),
                }))
            );
        });
    }, []);

    useEffect(() => {
        brigadeID &&
            req
                .get(`/projects/${projectId}/brigades/${brigadeID}/get`)
                .then(({ data }) => setBrigadeToEdit(() => data))
                .catch((e) => enqueueSnackbar('Ошибка', { variant: 'error' }));
    }, [brigadeID]);

    useDidMountEffect(() => {
        methods.formState.isDirty && setIsFormDirty(() => true);
    }, [methods.formState.isDirty]);

    useEffect(() => {
        if (brigadeToEdit) {
            methods.reset({
                name: brigadeToEdit?.name || '',
                executor: brigadeToEdit?.executor?.name
                    ? { label: brigadeToEdit?.executor?.name }
                    : { label: '', value: '' },
                employeeID: brigadeToEdit?.executor?.id || '',
                executorCompany: brigadeToEdit?.executor?.company || '',
                color: brigadeToEdit?.color || '',
                description: brigadeToEdit?.description || '',
            });
            setStaffList(() =>
                brigadeToEdit.staffArr.map((staffName) => {
                    return {
                        id: staffName,
                        nameProf: {
                            label: staffName,
                            value: proffList.find((prof) => prof.label === staffName)?.value || '',
                        },
                    };
                })
            );
            setMimList(() =>
                brigadeToEdit.mimArr.map((mimName) => {
                    return {
                        id: mimName,
                        name: mimName,
                    };
                })
            );
        }
    }, [brigadeToEdit]);

    function onSubmit(data: any) {
        const body = {
            name: data.name,
            color: data.color,
            description: data.description,
            executor: data.employeeID
                ? {
                      id: data.employeeID,
                      company: data.executorCompany,
                      name: data.executor.label,
                  }
                : null,
            staffArr: getResourceArrForReqBody(staffGridRef?.current),
            mimArr: getResourceArrForReqBody(mimGridRef?.current),
        };
        const addReqURL = `/projects/${projectId}/brigades/add`;
        const editReqURL = `/projects/${projectId}/brigades/${brigadeID}/update`;

        brigadeToEdit
            ? req
                  .post(editReqURL, body)
                  .then(() => enqueueSnackbar('Бригада успешно изменена', { variant: 'success' }))
                  .then(() => navigate(`/brigades/${projectId}`))
                  .catch((e) => enqueueSnackbar('Ошибка', { variant: 'error' }))
            : req
                  .post(addReqURL, body)
                  .then(() => enqueueSnackbar('Бригада успешно создана', { variant: 'success' }))
                  .then(() => navigate(`/brigades/${projectId}`))
                  .catch((e) => enqueueSnackbar('Ошибка', { variant: 'error' }));
    }

    function getResourceArrForReqBody(AgGrid: AgGridReact | null) {
        let targetArr: any[] = [];
        AgGrid?.api?.forEachNode((node) => {
            targetArr.push(node.data.id);
        });
        return targetArr;
    }

    function getUniqueResourcesFromServerResponse(
        arr: string[],
        resourceType: TResourceType,
        AgGrid: AgGridReact | null
    ) {
        let uniqueResources: Array<IBrigadeStaff | IBrigadeMim> = [];
        let currentResources: Array<IBrigadeStaff | IBrigadeMim> = [];
        AgGrid?.api?.forEachNode((node) => {
            currentResources.push(node.data);
        });
        if (resourceType === 'staff') {
            uniqueResources = arr.map((resourceName) => {
                return {
                    id: resourceName,
                    nameProf: {
                        label: resourceName,
                        value: Number(proffList?.filter((proff) => proff.label === resourceName)[0]?.value),
                    },
                } as unknown as IBrigadeStaff;
            });
        } else {
            uniqueResources = arr.map((resourceName) => {
                return {
                    id: resourceName,
                    name: resourceName,
                };
            });
        }
        uniqueResources = uniqueResources.filter((uniqueResource: IBrigadeMim | IBrigadeStaff) => {
            return !currentResources.find((currentResource) => {
                return currentResource.id === uniqueResource.id;
            });
        }) as IBrigadeMim[];
        return uniqueResources;
    }

    function getResourcesFromChosenWorks(
        works: Partial<IWorkForCopy>[],
        setIsFetching: Dispatch<SetStateAction<boolean>>
    ) {
        setIsFetching(() => true);
        req.post(`/projects/${projectId}/brigades/copy/get`, {
            data: works.map((work) => {
                return {
                    choice: {
                        ...work.choice,
                    },
                    workId: work.workId,
                };
            }),
        })
            .then(({ data }) => {
                const { mimArr, staffArr } = data;
                const uniqueStaff = getUniqueResourcesFromServerResponse(staffArr, 'staff', staffGridRef?.current);
                const uniqueMim = getUniqueResourcesFromServerResponse(mimArr, 'mim', mimGridRef?.current);
                staffGridRef?.current?.api.applyTransaction({ add: uniqueStaff });
                mimGridRef?.current?.api.applyTransaction({ add: uniqueMim });
                staffGridRef?.current?.api.redrawRows();
                mimGridRef?.current?.api.redrawRows();
                setIsFetching(() => false);
                setIsCopyDrawerOpen(() => false);
                enqueueSnackbar('Ресурсы были скопированы, дубликаты удалены', { variant: 'success' });
            })
            .catch((e) => {
                onSubmitRejected({
                    error: e,
                    enqueueSnackbar: enqueueSnackbar,
                    setIsFetching: setIsFetching,
                });
            });
    }

    function leavePageWithConfirm() {
        if (isFormDirty) {
            setIsConfirmLeavingDialogOpen(() => true);
        } else {
            navigate(`/brigades/${projectId}`, { state: { prevRoute: location.state?.prevRoute ?? null } });
        }
    }

    if (brigadeID && !brigadeToEdit) {
        return <CircularProgress />;
    }

    function deleteBrigade() {
        req.delete(`/projects/${projectId}/brigades/${brigadeID}/delete`)
            .then(() => enqueueSnackbar('Бригада успешно удалена', { variant: 'success' }))
            .catch((e) => enqueueSnackbar('Ошибка', { variant: 'error' }));
    }

    return (
        <AddBrigadePageWrapper>
            <FlexRowWrapper
                gridColumn={'1/4'}
                gridRow={'1/1'}
                justifyContent={'space-between'}
            >
                <StyledBtn
                    variant='text'
                    startIcon={<ArrowBackIcon />}
                    sx={{ lineHeight: '20px' }}
                    onClick={leavePageWithConfirm}
                >
                    Назад
                </StyledBtn>
                <FlexRowWrapper>
                    <StyledBtn
                        variant='contained'
                        color='primary'
                        onClick={leavePageWithConfirm}
                        disabled={
                            (!methods.formState.isValid || !isFormDirty) &&
                            DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE')
                        }
                    >
                        Отменить
                    </StyledBtn>
                    {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE') && (
                        <StyledBtn
                            variant='contained'
                            color='success'
                            form={'brigade'}
                            type='submit'
                            disabled={!methods.formState.isValid || !isFormDirty}
                        >
                            {brigadeToEdit ? 'Сохранить' : 'Создать бригаду'}
                        </StyledBtn>
                    )}
                </FlexRowWrapper>
            </FlexRowWrapper>

            <StyledCard>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(onSubmit)}
                        id={'brigade'}
                    >
                        <FlexRowWrapper
                            justifyContent={'space-between'}
                            mb={2.5}
                        >
                            <Typography color={'primary'}>О бригаде</Typography>
                            {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE') && (
                                <StyledBtn
                                    size='small'
                                    color={'secondary'}
                                    variant='outlined'
                                    onClick={(e) => setIsCopyDrawerOpen(() => true)}
                                >
                                    Скопировать из
                                </StyledBtn>
                            )}
                        </FlexRowWrapper>
                        <FlexColumnWrapper>
                            <FEInputWrapper>
                                <FELabel
                                    minWidth={136}
                                    required
                                >
                                    Название бригады:
                                </FELabel>
                                <TextInput
                                    fieldName={'name'}
                                    placeholder='Введите текст'
                                    validation={{
                                        maxLength: {
                                            value: 500,
                                            message: 'Не более 500 символов',
                                        },
                                    }}
                                    disabled={!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE')}
                                />
                            </FEInputWrapper>
                            <FEInputWrapper>
                                <FELabel minWidth={136}>Исполнитель:</FELabel>
                                <AutocompleteInput
                                    fieldName={'executor'}
                                    placeholder='Введите текст'
                                    options={executors || []}
                                    onChange={(e, newValue) => {
                                        methods.setValue('executorCompany', newValue.company);
                                        methods.setValue('employeeID', newValue.employeeID);
                                    }}
                                    disabled={!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE')}
                                />
                            </FEInputWrapper>
                            <FEInputWrapper>
                                <FELabel minWidth={136}>Подрядчик:</FELabel>
                                <OverflowableTypographyWithTooltip maxRows={1}>
                                    {methods.watch('executorCompany')}
                                </OverflowableTypographyWithTooltip>
                            </FEInputWrapper>
                            <FEInputWrapper
                                sx={{ position: 'relative' }}
                                id='colorPicker'
                            >
                                <FELabel minWidth={136}>Цвет</FELabel>
                                <ColorPickerWrapper
                                    onClick={() =>
                                        DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE') &&
                                        setIsColorPickerOpen((prevState) => !prevState)
                                    }
                                >
                                    <FlexRowWrapper
                                        bgcolor={methods.getValues('color')}
                                        flex={'2 0 0'}
                                        height={'100%'}
                                        borderRadius={0.5}
                                    ></FlexRowWrapper>
                                    <FlexRowWrapper pl={1}>
                                        <ArrowDropDown color='primary' />
                                    </FlexRowWrapper>
                                </ColorPickerWrapper>

                                <ColorPickerPopper
                                    open={isColorPickerOpen}
                                    anchorEl={document.getElementById('colorPicker')}
                                    placement='bottom-end'
                                    disablePortal={true}
                                >
                                    <ClickAwayListener
                                        onClickAway={(event) => {
                                            event.preventDefault();
                                            setIsColorPickerOpen(() => false);
                                        }}
                                    >
                                        <Box>
                                            <SketchPicker
                                                color={methods.watch('color')}
                                                styles={{
                                                    default: {
                                                        color: {
                                                            display: 'none',
                                                        },
                                                        activeColor: {
                                                            display: 'none',
                                                        },
                                                        sliders: {
                                                            display: 'none',
                                                        },
                                                        saturation: {
                                                            display: 'none',
                                                        },
                                                    },
                                                }}
                                                presetColors={PRESET_COLORS_V3}
                                                {...(DOES_ROLE_HAS_ACCESS_TO_FEATURE(
                                                    profile.role,
                                                    'CREATE_BRIGADE'
                                                ) && {
                                                    onChange: (color, event) => {
                                                        const { a, b, r, g } = color.rgb;
                                                        methods.setValue('color', `rgba(${r},${g},${b},${a})`);
                                                        setIsFormDirty(() => true);
                                                        setIsColorPickerOpen(() => false);
                                                    },
                                                })}
                                            />
                                        </Box>
                                    </ClickAwayListener>
                                </ColorPickerPopper>
                            </FEInputWrapper>
                            <FEInputWrapper
                                flexDirection={'column'}
                                sx={{ gap: 1 }}
                            >
                                <FELabel>Описание</FELabel>
                                <TextInput
                                    fieldName={'description'}
                                    placeholder='Введите текст'
                                    multiline
                                    rows={4}
                                    validation={{
                                        required: false,
                                    }}
                                    disabled={!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE')}
                                />
                            </FEInputWrapper>
                        </FlexColumnWrapper>
                    </form>
                </FormProvider>
                {brigadeToEdit && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'DELETE_BRIGADE') && (
                    <Button
                        sx={{ marginTop: 'auto' }}
                        startIcon={<Delete />}
                        color={'error'}
                        onClick={(e) => setIsConfirmDeleteBrigadeDialogOpen(() => true)}
                    >
                        Удалить бригаду
                    </Button>
                )}
            </StyledCard>

            <StyledCard>
                <Typography color={'primary'}>Трудозатраты</Typography>
                <StyledAgGrid
                    ref={staffGridRef}
                    rowData={staffList}
                    columnDefs={staffTableColumnDef}
                    context={{
                        projectID: projectId,
                        proffList: proffList,
                        setProffList: setProffList,
                    }}
                    getRowId={(params: GetRowIdParams<any, IBrigadeStaff>) => params.data?.id}
                    animateRows
                    {...AG_GRID_DEFAULT_PARAMS()}
                    onCellEditingStopped={(event) => {
                        if (event.newValue === '' || !event.newValue) {
                            setIsConfirmDeleteRowDialogOpen(true);
                            setCellEditingStoppedEvent(event);
                        }
                    }}
                    onCellValueChanged={(event) => {
                        setIsFormDirty(() => true);
                        if (event.oldValue === '') {
                            const profID = event.context.proffList?.find(
                                (prof: IAutocompleteOption) => prof.label === event.newValue?.label
                            )?.value;
                            try {
                                if (!profID) {
                                    addNewProfName(event);
                                } else {
                                    event.api.applyTransaction({
                                        remove: [{ id: 'draft' }],
                                        add: [{ ...(event.data as IStaffResource), id: event.newValue.label }],
                                        addIndex: event.rowIndex,
                                    });
                                }
                            } catch (e) {}
                        }
                    }}
                    onRowDataUpdated={(event) => {
                        event.context.proffList.length && setIsFormDirty(() => true);
                    }}
                    loadingOverlayComponent={() => (
                        <StyledLoadingOverlay>
                            <CircularProgress />
                        </StyledLoadingOverlay>
                    )}
                    {...(!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE') && {
                        suppressClickEdit: true,
                        ...disableAddAndDelete(true),
                        overlayNoRowsTemplate: 'Не указано ни одной специальности',
                    })}
                />
            </StyledCard>

            <StyledCard>
                <Typography color={'primary'}>Механизмы</Typography>
                <StyledAgGrid
                    ref={mimGridRef}
                    rowData={mimList}
                    context={{
                        projectID: projectId,
                        proffList: proffList,
                        setProffList: setProffList,
                    }}
                    getRowId={(params: GetRowIdParams<any, IResource>) => params.data?.id}
                    animateRows
                    {...AG_GRID_DEFAULT_PARAMS()}
                    columnDefs={mimGridRef.current && mimTableColumnDef(mimGridRef.current, profile.role)}
                    onCellEditingStopped={(event) => {
                        if (
                            event.newValue === '' ||
                            (!event.newValue && (event.data as Partial<IMIMResource>).id === 'draft')
                        ) {
                            setIsConfirmDeleteRowDialogOpen(true);
                            setCellEditingStoppedEvent(event);
                        }
                    }}
                    onCellValueChanged={(event) => {
                        setIsFormDirty(() => true);
                        if (event.newValue === '') {
                            return;
                        }
                        event.api.applyTransaction({
                            remove: [{ id: 'draft' }],
                            add: [{ ...(event.data as IStaffResource), id: event.newValue }],
                            addIndex: event.rowIndex,
                        });
                    }}
                    onRowDataUpdated={(event) => {
                        event.context.proffList.length && setIsFormDirty(() => true);
                    }}
                    {...(!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_BRIGADE') && {
                        suppressClickEdit: true,
                        overlayNoRowsTemplate: 'Не указано ни одной техники',
                    })}
                />
            </StyledCard>

            <CopyResourcesDrawer
                DrawerProps={{
                    open: isCopyDrawerOpen,
                    onClose: () => setIsCopyDrawerOpen(() => false),
                }}
                copyMode={'from'}
                getWorkListMethod={() => req.get(`/projects/${projectId}/brigades/copy/list`)}
                onCopy={(chosenWorks, setIsFetching) => {
                    getResourcesFromChosenWorks(chosenWorks, setIsFetching);
                }}
            />

            <ConfirmDialog
                open={isConfirmDeleteRowDialogOpen}
                title={'Удалить строку?'}
                message='Вы не указали наименование ресурса, или пытаетесь добавить уже имеющийся ресурс. Строка будет удалена'
                onYes={{
                    action: (e) => {
                        cellEditingStoppedEvent?.api.applyTransaction({ remove: [{ id: 'draft' }] });
                        setIsConfirmDeleteRowDialogOpen(() => false);
                    },
                    btnText: 'Удалить',
                }}
                onNo={{
                    action: (e) => {
                        setIsConfirmDeleteRowDialogOpen(() => false);
                        cellEditingStoppedEvent?.api.setFocusedCell(
                            cellEditingStoppedEvent.rowIndex as number,
                            'nameProf'
                        );
                        cellEditingStoppedEvent?.api.startEditingCell({
                            rowIndex: cellEditingStoppedEvent.rowIndex as number,
                            colKey: 'nameProf',
                        });
                    },
                    btnText: 'Отменить',
                }}
            />
            <ConfirmDialog
                open={isConfirmLeavingDialogOpen}
                title={'Вы уверены, что хотите отменить изменения?'}
                message='Все несохраненные данные будут удалены'
                onYes={{
                    action: (e) => {
                        navigate(`/brigades/${projectId}`);
                    },
                    btnText: 'Выйти',
                }}
                onNo={{
                    action: (e) => {
                        setIsConfirmLeavingDialogOpen(() => false);
                    },
                    btnText: 'Вернуться',
                }}
            />
            <ConfirmDialog
                open={isConfirmDeleteBrigadeDialogOpen}
                title={'Вы уверены, что хотите отменить изменения?'}
                message='Все несохраненные данные будут удалены'
                onYes={{
                    action: (e) => {
                        deleteBrigade();
                        setTimeout(() => navigate(`/brigades/${projectId}`), 100);
                        setIsConfirmDeleteBrigadeDialogOpen(() => false);
                    },
                    btnText: 'Удалить',
                }}
                onNo={{
                    action: (e) => {
                        setIsConfirmDeleteBrigadeDialogOpen(() => false);
                    },
                    btnText: 'Вернуться',
                }}
            />
        </AddBrigadePageWrapper>
    );
}
