import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface IState {
    render: React.FC<{ children: React.ReactNode }>;
    remove: () => void;
}

export const usePortal = (el: HTMLElement | null) => {
    const [portal, setPortal] = useState<IState>({
        render: () => null,
        remove: () => null,
    });

    const createPortal = useCallback((el: HTMLElement | null) => {
        const Portal: React.FC<{ children: React.ReactNode }> = ({ children }) =>
            ReactDOM.createPortal(children, el as HTMLElement);
        const remove = () => ReactDOM.unmountComponentAtNode(el as HTMLElement);
        return { render: Portal, remove };
    }, []);

    useEffect(() => {
        if (el) portal.remove();
        const newPortal = createPortal(el);
        setPortal(newPortal);

        return () => {
            newPortal.remove();
        };
    }, [el]);

    return portal.render;
};
