import { FC } from 'react';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { UICircleCheckbox } from '@/components/ui/UICircleCheckbox';
import { UIHeaderFilter } from '@/components/ui/UIHeaderFilter';

import { TCellRendererProps, TCheckboxCellRendererProps, THeaderFilterProps } from './TableCell.types';

export const TextCellRenderer: FC<TCellRendererProps> = ({ value }) => {
    return (
        <OverflowableTypographyWithTooltip
            maxRows={2}
            TypographyProps={{
                sx: {
                    background: 'transparent !important',
                },
            }}
        >
            {value}
        </OverflowableTypographyWithTooltip>
    );
};

export const HeaderFilter: FC<THeaderFilterProps> = ({
    isApplied,
    column,
    displayName,
    textAlign = 'left',
    onClick,
}) => {
    return (
        <UIHeaderFilter
            width={column.getActualWidth()}
            name={displayName}
            isApplied={isApplied}
            onClick={onClick}
            textProps={{
                sx: {
                    textAlign: `${textAlign} !important`,
                },
            }}
            iconColor='#9aa2b0'
            appliedIconColor='#8edd92'
        />
    );
};

export const CheckboxCellRenderer: FC<TCheckboxCellRendererProps> = ({ isConsolidated, checked, color, onChange }) => {
    if (isConsolidated) return null;

    return (
        <UICircleCheckbox
            checked={checked}
            onChange={onChange}
            checkedIconProps={{
                sx: {
                    color,
                },
            }}
        />
    );
};
