import { InfiniteData, QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { RowClassRules } from 'ag-grid-community';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import { useAckEstimatePositionsChanges, useDeleteEstimatePositionById } from '@/api/estimatePositions';
import { estimatePositionsApi } from '@/api/estimatePositions/estimatePositions.api';
import { TEstimatePositionsResponse } from "@/api/estimatePositions/estimatePositions.types";

import { queryClient } from '@/shared/constants/queryClient';
import { queryKeys } from '@/shared/constants/queryKeys';

import { estimatePositionsSelectors } from '@/store/slices/estimatePositions';
import { useTypedSelector } from '@/store/store';

import { LIMIT } from './EstimatePositionsTable.config';
import {
    TData,
    TUseDataProps,
    TUseGetEstimatePositionsProps,
    TUseGetPositionsBySelectType,
    TUseHasSelectEstimatePositionsProps,
} from './EstimatePositionsTable.types';

export const useGetEstimatePositions = ({ projectId, limit = LIMIT }: TUseGetEstimatePositionsProps) => {
    const listType = useTypedSelector(estimatePositionsSelectors.listType);
    const filters = useTypedSelector(estimatePositionsSelectors.filters);

    return useInfiniteQuery({
        queryKey: [queryKeys.estimatePositions.list, projectId, listType, filters],
        queryFn: ({ pageParam = 0 }) => {
            return estimatePositionsApi.fetchList({
                body: filters,
                limit,
                offset: pageParam,
                projectId: projectId,
                listType,
            });
        },
        initialPageParam: 0,

        getNextPageParam: (lastPage, _, pageParam) => {
            const total = lastPage.total;
            const nextPageParam = pageParam + limit;

            return nextPageParam <= total ? nextPageParam : undefined;
        },
        select: (data) => {
            const positions = data.pages.flatMap((page) => page.positions);
            return {
                ...data,
                positions,
                total: data.pages[0].total,
                requireAttention: data.pages[0].requireAttention,
            };
        },
    });
};

export const useRowClassRules = () => {
    const rowClassRules: RowClassRules<TData> = useMemo(() => {
        return {
            'deleted-row': ({ data }) => {
                if (!data) return false;
                return data.changes.isDeleted;
            },
            'changed-row': ({ data }) => {
                if (!data) return false;
                return data.changes.all;
            },
        };
    }, []);

    return rowClassRules;
};

export const useAckEstimatePositionsChangesMutation = () => {
    return useAckEstimatePositionsChanges({
        onMutate: async (data) => {
            await queryClient.cancelQueries({ queryKey: [queryKeys.estimatePositions.list] });

            const estimatePositionKeys = queryClient
                .getQueryCache()
                .findAll()
                .map((query) => query.queryKey)
                .filter((key) => Array.isArray(key) && key[0] === queryKeys.estimatePositions.list);
            const key = estimatePositionKeys.at(-1);

            if (!key) return;

            const oldData = queryClient.getQueryData(key);

            queryClient.setQueryData(key, (old: InfiniteData<TEstimatePositionsResponse>) => {
                if (!old) return undefined;

                const pages = old.pages.map((page) => {
                    const positions = page.positions.map((position) => {
                        if (position.id !== data.body.positionIDs[0]) {
                            return position;
                        }

                        return {
                            ...position,
                            changes: {
                                ...position.changes,
                                all: false,
                                lsrCode: false,
                                lsrNumber: false,
                                chapter: false,
                                header: false,
                                code: false,
                                positionName: false,
                                rawUnit: false,
                                quantity: false,
                                humanHourCost: false,
                                machineHourCost: false,
                                costTotal: false,
                            },
                        };
                    });

                    return {
                        ...page,
                        positions,
                        requireAttention: {
                            ...page.requireAttention,
                            changed: page.requireAttention.changed - 1,
                        },
                    };
                });

                return {
                    ...old,
                    pages,
                };
            });

            return {
                key,
                oldData,
            };
        },

        onError: (_err, _data, c) => {
            const context = c as { key: QueryKey; oldData: InfiniteData<TEstimatePositionsResponse> };
            queryClient.setQueryData(context.key, context.oldData);
        },

        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.estimatePositions.list] });
        },
    });
};

const getAllPositions = () => {
    const keys = queryClient
        .getQueryCache()
        .getAll()
        .map((query) => query.queryKey)
        .filter((key) => (key[0] as string).startsWith(queryKeys.estimatePositions.list));

    const key = keys[0] as QueryKey;
    if (!key) return [];

    const data = queryClient.getQueryData(key) as InfiniteData<TEstimatePositionsResponse>;
    const pages = data?.pages ?? [];
    return pages.flatMap((page) => page.positions);
};

const useData = (_props: TUseDataProps) => {
    const checkedPositionsMap = useTypedSelector(estimatePositionsSelectors.checkedPositionsMap);
    const positions = getAllPositions();

    return positions
        .filter((position) => checkedPositionsMap.has(position.id))
        .map((position) => {
            return {
                ...position,
                selectType: checkedPositionsMap.get(position.id)!.selectType,
            };
        });
};

export const useHasSelectEstimatePositions = ({ projectId }: TUseHasSelectEstimatePositionsProps) => {
    const positions = useData({
        projectId,
    });

    const set = new Set(positions.map((position) => position.selectType));

    return {
        hasPrimary: set.has('primary'),
        hasSecondary: set.has('secondary'),
    };
};

export const useGetPositionsBySelectType = ({ projectId }: TUseGetPositionsBySelectType) => {
    const primaryPositions = [];
    const secondaryPositions = [];

    const positions = useData({
        projectId,
    });
    const sortedPositions = positions.sort((a, b) => a.ordinal - b.ordinal);

    for (const position of sortedPositions) {
        if (position.selectType === 'primary') {
            primaryPositions.push(position);
        } else {
            secondaryPositions.push(position);
        }
    }

    return [primaryPositions, secondaryPositions];
};

export const useDeleteEstimatePositionByIdMutation = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useDeleteEstimatePositionById({
        onSuccess: () => {
            enqueueSnackbar('Позиция удалена', {
                variant: 'success',
            });
            Promise.all([
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.list],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.filter],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.tabCounters],
                }),
            ]);
        },
        onError: (data) => {
            // @ts-ignore
            const message = match(data)
                .with(
                    {
                        response: {
                            data: 'position_not_marked_deleted',
                            status: 400,
                        },
                    },
                    () => 'Нельзя удалить позицию сметы, которая входит в укрупненную'
                )
                .otherwise(() => 'Произошла ошибка при удалении позиции');

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
    });
};
