import { Stack, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import { ResetButton } from '@/components/ui/UIButton';

export const Container = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    gap: '20px',
})`
    height: 100%;
    width: 100%;
    background: rgba(25, 118, 210, 0.08);
    padding-inline: 144px 20px;
`;

export const Form = styled('form')`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const Label = styled('label')`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const Title = styled(Typography).attrs({
    variant: 'h3',
})`
    color: #2b3648;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    height: fit-content;
`;

export const UIFolderContainerCss = css`
    width: 20px;
    height: 20px;
`;

export const Input = styled('input')`
    display: flex;
    width: 420px;
    height: 28px;
    padding: 2px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid rgba(92, 110, 140, 0.1);
    background: #f6f7fb;
    color: #2b3648;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17px;

    &::placeholder {
        color: #9aa2b0;
    }
`;

export const Text = styled(Typography).attrs({
    variant: 'body1',
})`
    color: #5c6e8c;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const Buttons = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px',
})``;

const BaseButton = styled(ResetButton)`
    color: #fff;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    transition: background 0.3s, filter 0.3s;

    &:hover {
        filter: brightness(90%);
    }
`;

export const SaveButton = styled(BaseButton)`
    background: #2e7d32;

    &:disabled {
        background: #9aa2b0;
        pointer-events: none;
    }
`;

export const CancelButton = styled(BaseButton)`
    background: #0044b4;
`;
