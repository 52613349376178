import { createInstance } from '@/api/apiInstance';

import {
    TStructureAddAndGroupFolderRequest,
    TStructureAddAndGroupFolderResponse,
    TStructureAddAndGroupPreviewFolderRequest,
    TStructureAddAndGroupPreviewFolderResponse,
    TStructureAddAutoFoldersRequest,
    TStructureAddAutoFoldersResponse,
    TStructureAddFolderRequest,
    TStructureAddFolderResponse,
    TStructureDeleteAllFoldersRequest,
    TStructureDeleteAllFoldersResponse,
    TStructureDeleteFolderRequest,
    TStructureDeleteFolderResponse,
    TStructureInsertFolderPreviewRequest,
    TStructureInsertFolderPreviewResponse,
    TStructureInsertFolderRequest,
    TStructureInsertFolderResponse,
    TStructureListRequest,
    TStructureListResponse,
    TStructureMoveConsolidatedPriceRequest,
    TStructureMoveConsolidatedPriceResponse,
    TStructureMoveFolderRequest,
    TStructureMoveFolderResponse,
    TStructureTreeRequest,
    TStructureTreeResponse,
    TStructureUpdateFolderRequest,
    TStructureUpdateFolderResponse,
} from './structure.types';

export const structureApi = {
    fetchList: async ({ limit, offset, projectId }: TStructureListRequest) => {
        const data = await createInstance<TStructureListResponse>({
            url: `/projects/${projectId}/structure/list`,
            method: 'get',
            params: {
                limit,
                offset,
            },
        });
        return data;
    },
    fetchTree: async ({ projectId }: TStructureTreeRequest) => {
        const data = await createInstance<TStructureTreeResponse>({
            url: `/projects/${projectId}/structure/tree`,
            method: 'get',
        });
        return data;
    },
    addFolder: async ({ projectId, body }: TStructureAddFolderRequest) => {
        const data = await createInstance<TStructureAddFolderResponse>({
            url: `/projects/${projectId}/structure/folder/add`,
            method: 'post',
            data: body,
        });
        return data;
    },
    insertFolder: async ({ projectId, body }: TStructureInsertFolderRequest) => {
        const data = await createInstance<TStructureInsertFolderResponse>({
            url: `/projects/${projectId}/structure/folder/insert`,
            method: 'post',
            data: body,
        });
        return data;
    },
    insertFolderPreview: async ({ projectId, body }: TStructureInsertFolderPreviewRequest) => {
        const data = await createInstance<TStructureInsertFolderPreviewResponse>({
            url: `/projects/${projectId}/structure/folder/insert/preview`,
            method: 'post',
            data: body,
        });
        return data;
    },
    addAndGroupFolder: async ({ projectId, body }: TStructureAddAndGroupFolderRequest) => {
        const data = await createInstance<TStructureAddAndGroupFolderResponse>({
            url: `/projects/${projectId}/structure/folder/add-and-group`,
            method: 'post',
            data: body,
        });
        return data;
    },
    addAndGroupPreviewFolder: async ({ projectId, body }: TStructureAddAndGroupPreviewFolderRequest) => {
        const data = await createInstance<TStructureAddAndGroupPreviewFolderResponse>({
            url: `/projects/${projectId}/structure/folder/add-and-group/preview`,
            method: 'post',
            data: body,
        });
        return data;
    },
    updateFolder: async ({ projectId, folderId, body }: TStructureUpdateFolderRequest) => {
        const data = await createInstance<TStructureUpdateFolderResponse>({
            url: `/projects/${projectId}/structure/folder/${folderId}/update`,
            method: 'put',
            data: body,
        });
        return data;
    },
    deleteFolder: async ({ projectId, folderId }: TStructureDeleteFolderRequest) => {
        const data = await createInstance<TStructureDeleteFolderResponse>({
            url: `/projects/${projectId}/structure/folder/${folderId}/delete`,
            method: 'delete',
        });
        return data;
    },
    moveFolder: async ({ projectId, folderId, body }: TStructureMoveFolderRequest) => {
        const data = await createInstance<TStructureMoveFolderResponse>({
            url: `/projects/${projectId}/structure/folder/${folderId}/move`,
            method: 'post',
            data: body,
        });
        return data;
    },
    moveConsolidatedPrice: async ({ projectId, body }: TStructureMoveConsolidatedPriceRequest) => {
        const data = await createInstance<TStructureMoveConsolidatedPriceResponse>({
            url: `/projects/${projectId}/structure/consolidated/move`,
            method: 'post',
            data: body,
        });
        return data;
    },
    addAutoFolders: async ({ projectId }: TStructureAddAutoFoldersRequest) => {
        const data = await createInstance<TStructureAddAutoFoldersResponse>({
            url: `/projects/${projectId}/structure/folder/add-auto`,
            method: 'post',
        });
        return data;
    },
    deleteAllFolders: async ({ projectId }: TStructureDeleteAllFoldersRequest) => {
        const data = await createInstance<TStructureDeleteAllFoldersResponse>({
            url: `/projects/${projectId}/structure/folder/delete-all`,
            method: 'post',
        });
        return data;
    },
};
