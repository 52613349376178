import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { ReactElement } from 'react';

import { StyledDialogActions } from './ConfirmDialog.styles';
import { ConfirmDialogProps } from './ConfirmDialog.types';

export const ConfirmDialog = NiceModal.create<ConfirmDialogProps>(
    ({
        maxWidth = 400,
        title,
        body = 'Форма будет закрыта, а все введенные данные безвозвратно утеряны.',
        confirmButtonText,
        denyButtonText,
        actionButtons,
        onSuccess,
    }): ReactElement | null => {
        const { visible, hide } = useModal();

        const successClickHandler = () => {
            closeHandler();
            onSuccess?.();
        };

        const closeHandler = () => {
            hide();
        };

        return (
            <Dialog
                PaperProps={{
                    sx: {
                        width: maxWidth,
                        textAlign: 'center',
                    },
                }}
                open={visible}
                onClose={closeHandler}
            >
                <DialogTitle
                    sx={{ pt: 3.75, pb: 1.5 }}
                    variant='subtitle1'
                    justifyContent='center'
                >
                    {title || 'Подтвердить закрытие формы?'}
                </DialogTitle>
                <DialogContent>
                    {isString(body) || isNumber(body) ? (
                        <Typography
                            sx={{ wordBreak: 'break-word' }}
                            variant='body2'
                            color='text.dark'
                        >
                            {body || 'Форма будет закрыта, а все введенные данные безвозвратно утеряны.'}
                        </Typography>
                    ) : (
                        body
                    )}
                </DialogContent>
                <StyledDialogActions>
                    {actionButtons ? (
                        actionButtons.map(({ onClick, ...buttonProps }, index) => (
                            <Button
                                key={index}
                                variant='contained'
                                fullWidth
                                onClick={(e) => {
                                    onClick?.(e);
                                    closeHandler();
                                }}
                                {...buttonProps}
                            />
                        ))
                    ) : (
                        <Button
                            color='success'
                            variant='contained'
                            autoFocus
                            fullWidth
                            onClick={successClickHandler}
                        >
                            {confirmButtonText || 'Да'}
                        </Button>
                    )}
                    <Button
                        variant='contained'
                        fullWidth
                        onClick={closeHandler}
                    >
                        {denyButtonText || 'Отмена'}
                    </Button>
                </StyledDialogActions>
            </Dialog>
        );
    }
);
