import { ru } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { filtersSomSelector, setMaxDateDel, setMinDateDel } from '@/store/slices/filtersSomSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import s from '../CheckboxPopoverSufficiency.module.scss';
import '../DatePickerStyles.css';

registerLocale('ru', ru);

export default function FilterDatePickerEl() {
    const { dateDel } = useTypedSelector(filtersSomSelector);

    const dispatch = useAppDispatch();

    const onChange = (dates: (Date | null)[]) => {
        const [start, end] = dates;
        dispatch(setMinDateDel(start));
        dispatch(setMaxDateDel(end));
    };

    return (
        <div className={s.date_picker_wrapper}>
            <DatePicker
                selected={dateDel.min}
                onChange={onChange}
                startDate={dateDel.min}
                endDate={dateDel.max}
                selectsRange
                inline
                locale='ru'
            />
        </div>
    );
}
