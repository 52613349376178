import { useMemo } from 'react';

import { Work } from "@/api/ksg/ksg.types";

export const useGetMinMaxLevels = (works: Work[] | undefined, listMode: boolean) => {
    const [maxLevel, minLevel] = useMemo(() => {
        if (!works) return [null, null];

        const levels = works.map((v) => v.level) as number[];
        return [Math.max(...levels), Math.min(...levels)];
    }, [works]);

    const minLevelByListMode = listMode ? minLevel : null;

    return {
        maxLevel: maxLevel,
        minLevel: minLevel,
        minLevelByListMode: minLevelByListMode,
    };
};
