import { useNavigate } from 'react-router-dom';

import { FlexColumnWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { StyledBtn } from '@/pages/WorkManagment/components/components.styles';

import PageNotFoundIcon from '@/shared/SVG/PageNotFoundIcon';

import { theme } from '@/styles/theme';

import { IPageNotFoundProps } from './PageNotFound.types';

export function PageNotFound({ children, hideNavBtn, IconComponent }: IPageNotFoundProps) {
    const navigate = useNavigate();

    return (
        <FlexColumnWrapper
            gap={5}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={10}
            bgcolor={theme.palette.bg.gray}
            height={'100%'}
        >
            {IconComponent || <PageNotFoundIcon />}
            {children}
            {!hideNavBtn && (
                <StyledBtn
                    variant='outlined'
                    onClick={() => navigate('/')}
                >
                    На главную
                </StyledBtn>
            )}
        </FlexColumnWrapper>
    );
}
