import { GetRowIdParams, IsFullWidthRowParams, RowClassRules } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';

import { TContext, TData } from '../StructureManagementTable.types';
import { generateRowId, getFolderParentIdByPathArray } from '../utils';

export const useRowClassRules = () => {
    const rowClassRules: RowClassRules<TData> = useMemo(() => {
        return {
            'ag-row-folder': ({ data }) => {
                if (!data) return false;
                return data.rowType === 'a_folder';
            },
            'ag-row-consolidated-has-parent': ({ data }) => {
                if (!data) return false;
                if (data.rowType === 'a_folder') return false;
                if (
                    !getFolderParentIdByPathArray(
                        data.pathArray,
                        generateRowId({ rowType: data.rowType, entityId: data.entityId })
                    )
                )
                    return false;
                return true;
            },
        };
    }, []);

    return rowClassRules;
};

export const useGetRowId = () => useCallback(({ data }: GetRowIdParams<TData>) => generateRowId(data), []);

export const useIsFullWidthRow = () =>
    useCallback((params: IsFullWidthRowParams<TData, TContext>) => {
        if (!params.rowNode.data) return true;
        if ('modifyMode' in params.rowNode.data) return true;
        return false;
    }, []);
