import { Box, Tabs as MuiTabs } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)``;

export const Tabs = styled(MuiTabs)`
    .MuiTabs-flexContainer {
        gap: 0 !important;
    }

    .MuiTabs-indicator {
        display: none;
    }
    min-height: 24px !important;
    border-radius: ${(props) => props.theme.spacing(0.5)};

    .MuiTab-root {
        border-width: 1px;
        border-style: solid;

        &:first-of-type {
            border-radius: ${(props) => props.theme.spacing(0.75, 0, 0, 0.75)};
        }

        &:last-of-type {
            border-right: ${(props) => `1px solid #8cbbe8`};
            border-radius: ${(props) => props.theme.spacing(0, 0.75, 0.75, 0)};
            border-left: none;
        }

        .MuiTabs-flexContainer {
            width: 184px;
        }

        &.MuiButtonBase-root {
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
            min-height: 30px !important;
            text-transform: capitalize;
            padding: 0;
            min-width: 86px;
            padding-inline: 6px;

            color: #5c6e8c;
            border-color: #8cbbe8;
        }

        &.Mui-selected {
            color: #0044b4;
            background: rgba(25, 118, 210, 0.08);
        }

        &.Mui-disabled {
            pointer-events: none;
            color: ${(props) => props.theme.palette.legends!.disabled};
            border-color: ${(props) => props.theme.palette.legends!.disabled};
        }

        /* &[aria-selected='true'] {
            &.Mui-disabled {
                background-color: ${(props) => props.theme.palette.bg!.gray};
            }
            background-color: #f2ebfe;
        } */
    }
`;
