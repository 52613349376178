import { Button, Dialog } from '@mui/material';

import g from './ConfirmModal.module.scss';
import { ConfirmationModalI } from './ConfirmationModal.types';

export default function ConfirmationModal(props: ConfirmationModalI) {
    return (
        <Dialog
            open={props.openState}
            onClose={props.onCancel}
        >
            <div className={g.modal}>
                <h4>{props.title}</h4>

                <p>{props.content}</p>

                <div className={g.buttons}>
                    <Button
                        variant='contained'
                        color='success'
                        sx={{
                            width: '10rem',
                        }}
                        onClick={props.onConfirm}
                    >
                        Подтвердить
                    </Button>

                    <Button
                        variant='contained'
                        sx={{
                            width: '10rem',
                        }}
                        onClick={props.onCancel}
                    >
                        Отмена
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
