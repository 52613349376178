import { useEffect } from 'react';

import { FileIcon } from '@/shared/SVG/FileIcon';

import { TCellRendererProps } from '../TableCells.types';
import { Container, Dot, Dots, FileIconContainer, Left, Title } from './OrdinalCellRenderer.styles';
import { calculateDotsCountForConsolidated, calculateMinColumnWidthForConsolidated } from './OrdinalCellRenderer.utils';

export const ConsolidatedOrdinalCellRenderer: React.FC<TCellRendererProps> = (props) => {
    const dotsCount = calculateDotsCountForConsolidated(props.data!);
    const minColumnWidth = calculateMinColumnWidthForConsolidated(dotsCount);

    useEffect(() => {
        const columnState = props.columnApi.getColumnState();
        const ordinalColumn = columnState.find((col) => col.colId === 'ordinal');
        const ordinalColumnWidth = ordinalColumn?.width ?? 0;

        if (minColumnWidth > ordinalColumnWidth) {
            props.columnApi.setColumnWidth('ordinal', minColumnWidth);
        }
    }, [minColumnWidth]);

    return (
        <Container>
            <Left>
                <Dots>
                    {Array(dotsCount)
                        .fill(null)
                        .map((_, index) => (
                            <Dot key={index} />
                        ))}
                </Dots>
                <FileIconContainer>
                    <FileIcon />
                </FileIconContainer>
            </Left>
            {props.value && <Title>{props.value}</Title>}
        </Container>
    );
};
