import { Stack } from '@mui/material';
import styled from 'styled-components';

export const FullWidthCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 44px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #2b3648;
    height: 100%;
`;

export const AlertText = styled(Stack).attrs({
    gap: '2px',
    justifyContent: 'flex-end',
    direction: 'row',
})`
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.01em;
    color: #ed6c02;
    max-width: 50%;
`;

export const Bold = styled.b`
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.01em;
    font-size: 14px;
    color: #ed6c02;
`;

export const AlertIconContainer = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center',
})<{ $isVisible: boolean }>`
    position: absolute;
    top: 50%;
    transform: translate(-10px, -50%);
    right: 0;
    height: 16;
    width: 16;
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    transition: opacity 0.3;
    color: #ed6c02;
`;
