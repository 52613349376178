import { Edit } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { IconButton } from '@mui/material';
import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Project } from '@/api/projects/projects.types';

import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';

import { setChangeProjectId, toggleChangeProject } from '@/store/slices/drawersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

import s from '../Projects.module.scss';

export default function AdminCard({ project, ag_grid }: { project: Project; ag_grid?: boolean }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { profile } = useTypedSelector(profileSelector);

    const navigateFromProjects = () => {
        !ag_grid && navigate(`/ksg/${project.id}`);
    };

    function getColor(v: string) {
        let color = '';
        if (v === 'Активный') color = '#6FCCBC';
        if (v === 'Приостановлен') color = '#6D9ADC';
        if (v === 'Завершенный') color = '#7890B2';
        return { backgroundColor: color };
    }

    return (
        <section
            onClick={navigateFromProjects}
            className={s.admin_card}
        >
            <div className={s.admin_top}>
                <h4 style={getColor(project.status)}>{project.status}</h4>
                {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_PROJECT') && (
                    <IconButton
                        color='primary'
                        component='span'
                        onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            dispatch(toggleChangeProject());
                            dispatch(
                                setChangeProjectId({ id: project.id, isImportFromOffer: project.isImportFromOffer })
                            );
                        }}
                    >
                        <Edit />
                    </IconButton>
                )}
            </div>
            <div className={s.admin_middle_container}>
                <div className={s.admin_middle}>
                    <h4>{project.title}</h4>
                    <p>{project.description}</p>
                </div>
            </div>
            <div className={s.admin_bottom}>
                <h3>Сроки реализации:</h3>
                <div className={s.admin_bottom_row}>
                    <div>
                        <CalendarMonthOutlinedIcon />
                        <p>Дата начала:</p>
                    </div>
                    <h4>{project.startDate}</h4>
                </div>
                <div className={s.admin_bottom_row}>
                    <div>
                        <EventAvailableIcon
                            sx={{
                                color: '#5c6e8c',
                            }}
                        />
                        <p>Дата окончания:</p>
                    </div>
                    <h4>{project.endDate}</h4>
                </div>
            </div>
        </section>
    );
}
