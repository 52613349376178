import { api } from '@/api/api';

import {
    IGetGanttLinksReq,
    IGetGanttLinksRes,
    getRelationsResI,
    getWorksNamesReqI,
    getWorksNamesResI,
    setRelationsReqI,
} from './relations.types';

export const relationsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getWorksNames: build.query<getWorksNamesResI, getWorksNamesReqI>({
            query: ({ projectId, limit, offset, filter }) => ({
                url: `/projects/${projectId}/dependencies/works/ksg/names`,
                params: {
                    limit,
                    offset,
                    filter,
                },
                method: 'GET',
            }),
        }),

        getLinks: build.mutation<IGetGanttLinksRes, IGetGanttLinksReq>({
            query: ({ projectId, payload }) => ({
                url: `/projects/${projectId}/dependencies/works/ksg/links`,
                method: 'POST',
                body: payload,
            }),
        }),

        setRelations: build.mutation<getRelationsResI, setRelationsReqI>({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/dependencies/works/ksg/bind`,
                body,
                method: 'POST',
            }),
        }),

        getRelations: build.query<
            getRelationsResI,
            {
                projectId: number;
                workId: number;
            }
        >({
            query: ({ projectId, workId }) => ({
                url: `/projects/${projectId}/dependencies/works/ksg/${workId}/bonds`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetWorksNamesQuery, useGetLinksMutation, useSetRelationsMutation, useGetRelationsQuery } =
    relationsApi;
