import { FilterOptionCellRenderer } from './FilterOptionCellRenderer';

export const columnDefs = [
    {
        cellStyle: {
            placeContent: 'unset',
        },
        cellClass: 'ag-cell-left',
        headerName: 'label',
        field: 'label',
        flex: 2,
        cellRenderer: FilterOptionCellRenderer,
    },
];
