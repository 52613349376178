import { InfiniteData, QueryKey } from '@tanstack/react-query';

import { useAckEstimatePositionsChanges } from '@/api/estimatePositions';
import { TEstimatePositionsResponse } from '@/api/estimatePositions/estimatePositions.types';

import { queryClient } from '@/shared/constants/queryClient';
import { queryKeys } from '@/shared/constants/queryKeys';

export const useAckEstimatePositionsChangesMutation = () => {
    return useAckEstimatePositionsChanges({
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [queryKeys.estimatePositions.list] });

            const estimatePositionKeys = queryClient
                .getQueryCache()
                .findAll()
                .map((query) => query.queryKey)
                .filter((key) => Array.isArray(key) && key[0] === queryKeys.estimatePositions.list);
            const key = estimatePositionKeys.at(-1);

            if (!key) return;

            const oldData = queryClient.getQueryData(key);

            queryClient.setQueryData(key, (old: InfiniteData<TEstimatePositionsResponse>) => {
                if (!old) return undefined;

                const pages = old.pages.map((page) => {
                    const positions = page.positions.map((position) => {
                        return {
                            ...position,
                            changes: {
                                ...position.changes,
                                all: false,
                                lsrCode: false,
                                lsrNumber: false,
                                chapter: false,
                                header: false,
                                code: false,
                                positionName: false,
                                rawUnit: false,
                                quantity: false,
                                humanHourCost: false,
                                machineHourCost: false,
                                costTotal: false,
                            },
                        };
                    });

                    return {
                        ...page,
                        positions,
                        requireAttention: {
                            ...page.requireAttention,
                            changed: 0,
                        },
                    };
                });

                return {
                    ...old,
                    pages,
                };
            });

            return {
                key,
                oldData,
            };
        },
        onError: (_err, _data, c) => {
            const context = c as { key: QueryKey; oldData: InfiniteData<TEstimatePositionsResponse> };
            queryClient.setQueryData(context.key, context.oldData);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.estimatePositions.list] });
        },
    });
};
