import { TFolderSVGColors } from "./UIFolder.types"

export const COLORS_FOR_FOLDER_SVG_BY_LEVEL: Record<number, TFolderSVGColors> = {
    // 0: {
    //     base: '#FDE047',
    //     baseStroke: '#E8C71E',
    //     handle: '#FFE768',
    //     back: '#ffffff',
    //     color: '#988d22',
    // },
    0: {
        base: '#0044B4',
        baseStroke: '#0044B4',
        handle: '#6DA0F4',
        back: '#ffffff',
        color: '#ffffff',
    },
    1: {
        base: '#0044B4',
        baseStroke: '#0044B4',
        handle: '#6DA0F4',
        back: '#ffffff',
        color: '#ffffff',
    },
    2: {
        base: '#1360E0',
        baseStroke: '#0044B4',
        handle: '#72A7FF ',
        back: 'transparent',
        color: '#ffffff',
    },
    3: {
        base: '#448AFF',
        baseStroke: '#1360E0',
        handle: '#99BFFF ',
        back: '#ffffff',
        color: '#ffffff',
    },
    4: {
        base: '#72A7FF',
        baseStroke: '#448AFF',
        handle: '#AFCDFF ',
        back: '#ffffff',
        color: '#ffffff',
    },
    5: {
        base: '#9FC3FF',
        baseStroke: '#72A7FF',
        handle: '#C8DCFF ',
        back: '#ffffff',
        color: '#0044B4',
    },
    16: {
        base: '#14B8A6',
        baseStroke: '#169F90',
        handle: '#8DD4C8',
        back: '#ffffff',
        color: '#ffffff',
    },
}
