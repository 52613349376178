import { NavLink, useParams } from 'react-router-dom';

import { EPageName } from '@/shared/constants/pages';

import { projectsSelector } from '@/store/slices/projectsSlice';
import { useTypedSelector } from '@/store/store';

import s from '../TableLayout.module.scss';

export default function MainLinks() {
    const { pageName } = useTypedSelector(projectsSelector);

    const { projectId } = useParams();

    const pageLink = () => {
        if (!pageName) return '';
        const locationsObj = {
            ksg: `/msg/${Number(projectId)}`,
            ksg_people: `/msg/workers/${Number(projectId)}`,
            ksg_equip: `/msg/mim/${Number(projectId)}`,
        };
        return locationsObj[pageName as 'ksg' | 'ksg_people' | 'ksg_equip'];
    };

    return (
        <>
            <div className={s.glass_buttons}>
                <NavLink
                    className={
                        pageName === EPageName.Ksg ||
                        pageName === EPageName.KsgEquip ||
                        pageName === EPageName.KsgPeople ||
                        pageName === EPageName.KsgKc6a
                            ? s.act
                            : ''
                    }
                    to={`/ksg/${Number(projectId)}`}
                >
                    КСГ
                </NavLink>
                <NavLink to={pageLink()}>МСГ</NavLink>
            </div>

            <div className={s.middle_middle}>
                <NavLink to={`${Number(projectId)}`}>СМР</NavLink>
                <NavLink to={`workers/${Number(projectId)}`}>Персонал</NavLink>
                <NavLink to={`mim/${Number(projectId)}`}>Механизмы</NavLink>
            </div>
        </>
    );
}
