export const Step1Image = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='160'
            height='160'
            fill='none'
        >
            <path
                fill='#F1FAFF'
                fillRule='evenodd'
                d='M70.62.114c38.487-1.876 78.814 19.55 88.179 58.426 8.249 34.243-27.695 56.369-55.735 76.254-24.274 17.212-53.343 35.427-77.73 18.389C-1.06 134.744-4.68 97.662 4.672 66.053 14.587 32.538 36.947 1.755 70.62.113'
                clipRule='evenodd'
            />
            <path
                fill='#303267'
                d='M87.772 131.074s4.412 2.101 4.921 2.273 1.467.442 1.503 1.13c.037.688-.303 1.228-.97 1.228H82.246c-.376 0-.63-.724-.667-2.014-.036-1.29 1.09-2.224 1.127-2.998 0-.54.667-1.892 3.455-1.228.764.221.776 1.314 1.612 1.609'
            />
            <path
                fill='#444789'
                d='M86.051 73.47c.63 3.452 4.945 22.395 5.515 26.093s.146 6.683-2.424 7.371-4.703 1.83-7.503-5.7c-1.297-3.477-3.03-7.973-4.521-12.285-1.588-4.595-2.873-8.968-3.006-11.622a7 7 0 0 1 0-.725c.388-7.911 11.297-6.597 11.939-3.133'
            />
            <path
                fill='#444789'
                d='M82.681 130.914a9.4 9.4 0 0 0 5.297-.172.96.96 0 0 0 .643-.749c.678-4.177 2.666-18.624 3.103-20.492a26.3 26.3 0 0 0 0-8.882c-1.128-4.803-9.455-2.457-8.728 3.563.497 4.177-2.109 6.548-1.212 16.118.267 3.219.368 6.451.303 9.681a.93.93 0 0 0 .143.567.9.9 0 0 0 .451.366'
            />
            <path
                fill='#303267'
                d='M63.906 131.074s4.412 2.101 4.92 2.273c.51.172 1.48.442 1.504 1.13s-.303 1.228-.97 1.228H58.38c-.376 0-.63-.724-.667-2.014s1.103-2.298 1.272-3.047c.34-1.486.534-1.843 3.31-1.155.763.197.775 1.29 1.612 1.585'
            />
            <path
                fill='#5C60B9'
                d='M81.76 77.425c.582 6.585-2.243 20.664-3.564 26.45-.836 3.685-2.121 6.327-4.74 6.142-2.617-.184-5.042.099-5.139-7.96 0-3.686-.17-8.6-.12-13.084 0-4.914.302-9.422 1.078-11.965q.095-.346.23-.676c3.006-7.371 11.952-2.408 12.255 1.093'
            />
            <path
                fill='#5C60B9'
                d='M59.275 130.312a9.44 9.44 0 0 0 5.055 1.659.92.92 0 0 0 .848-.479c2.049-3.686 8.776-16.585 9.806-18.182a26.2 26.2 0 0 0 2.958-8.366c.545-4.914-9.297-7.26-9.564-1.229-.194 4.668-4.351 7.039-6.448 16.265a67 67 0 0 1-2.97 9.214.934.934 0 0 0 .315 1.118'
            />
            <path
                fill='#444789'
                d='M71.76 80.607a27.6 27.6 0 0 0-3.564 8.341c0-4.913.303-9.422 1.08-11.965z'
            />
            <path
                fill='#DA6F5B'
                d='M76.524 46.148s6.812-.553 14.69-.676a2.4 2.4 0 0 0 1.77-.823c3.048-3.579 5.9-7.323 8.546-11.217a1.32 1.32 0 0 1 .707-.511 1.3 1.3 0 0 1 1.316.348 1.345 1.345 0 0 1 .243 1.527l-6.751 13.809a4.7 4.7 0 0 1-1.027 1.553 4.6 4.6 0 0 1-1.543 1.026 55.3 55.3 0 0 1-15.6 2.666c-3.467.16-5.552-1.634-5.515-3.39.048-2.101 1.163-4.091 3.163-4.312'
            />
            <path
                fill='#358F99'
                d='M74.657 46.725a7.1 7.1 0 0 1 2.921-.798c1.637-.148 5.26-.529 8.485-.7 1.212 0 2.424.11 3.31.098a.34.34 0 0 1 .326.196 12.26 12.26 0 0 1 1.103 6.77.36.36 0 0 1-.266.282c-1.03.22-2.11.602-3.188.786a51 51 0 0 1-6.303.651c-2.279 0-6.594.184-7.83-2.838a4.44 4.44 0 0 1 .086-2.435 4.4 4.4 0 0 1 1.356-2.012'
            />
            <path
                fill='#FF9D84'
                d='M99.687 30.325c.776 1.904.57 3.918 1.637 4.717 1.066.799 2.484.983 4.157-.516s1.443-2.604 2.037-3.968c.69-1.61.775-2.125.703-2.297-.207-.528-.691.073-.958.528-.267.454 0-.48.085-.885.327-1.228.363-1.535.17-1.707-.194-.172-.558.32-.485 0 0-.062.109-.307 0-.369-.303-.209-.776.123-1.612 1.07-.085.073-.11 0-.085 0 .775-.935.169-1.07 0-1.008a7.1 7.1 0 0 0-2.146 2.383c-.751 1.02-1.285 2.912-1.927 1.966s-1.71-2.076-2.291-1.83c-.582.245.364 1.08.715 1.916'
            />
            <path
                fill='#83CEDA'
                d='M86.79 77.032s-2.206 2.789-8.485 3.477c-2.202.26-4.425.272-6.63.037a13 13 0 0 1-2.849-.602 31 31 0 0 1 .425-3.084c.848-3.685-.4-8.034-3.528-11.806-2.533-3.17-10.4-5.565-7.381-11.744 3.151-6.745 16.194-7.949 18.545-7.162a7.6 7.6 0 0 1 3.382 2.174c2.06 2.347 2.776 6.29 4.109 12.96 1.77 8.883 2.412 15.75 2.412 15.75'
            />
            <path
                fill='#65BDCC'
                d='M69.154 55.976c-.243 5.196 6.618 15.676 2.497 24.57a13 13 0 0 1-2.849-.602q.146-1.553.449-3.084c.848-3.685-.4-8.034-3.528-11.806-2.533-3.17-10.4-5.565-7.381-11.744 3.151-6.745 16.194-7.949 18.545-7.162a7.6 7.6 0 0 1 3.382 2.174c-3.746-.946-10.897 2.519-11.115 7.654'
            />
            <path
                fill='#FFA083'
                d='M69.166 77.462a17 17 0 0 1-1.309 2.9 2.06 2.06 0 0 1-.933.853 2.02 2.02 0 0 1-1.249.141L63.942 81a1.27 1.27 0 0 1-.645-.334 1.3 1.3 0 0 1-.361-.636l-.097-.43a.224.224 0 0 1 .122-.25.2.2 0 0 1 .096-.02h.933a.73.73 0 0 0 .71-.437.8.8 0 0 0 .066-.288l.097-1.72a.76.76 0 0 0-.4-.737 131 131 0 0 1-12.242-7.371 3.57 3.57 0 0 1-1.199-2.104 3.6 3.6 0 0 1 .41-2.393c.776-1.535 1.952-3.587 3.334-5.835.582-.958 1.212-1.941 1.843-2.936a39 39 0 0 1 3.418-4.644 4.3 4.3 0 0 1 2.284-1.396 4.25 4.25 0 0 1 2.657.237 4.32 4.32 0 0 1 2.01 1.778c.466.793.672 1.717.588 2.637a4.54 4.54 0 0 1-.86 2.223c-.994 1.327-1.867 2.347-3.104 3.686-.787.896-1.6 1.843-2.315 2.678l-1.818 2.138a.804.804 0 0 0 0 1.093c1.721 1.585 6.934 6.806 8.121 7.518 1.503.946 2.328 1.892 1.576 4.005'
            />
            <path
                fill='#83CEDA'
                d='M67.942 54.06a4.7 4.7 0 0 1-.897 2.37c-.982 1.34-4.728 6.032-5.806 7.371a.35.35 0 0 1-.412.11 15.54 15.54 0 0 1-6.594-4.655.4.4 0 0 1 0-.455c.484-.737 1.212-1.806 1.842-2.764a50 50 0 0 1 3.988-5.405 4.418 4.418 0 0 1 6.182-.663 4.6 4.6 0 0 1 1.363 1.825c.296.715.411 1.494.334 2.265'
            />
            <path
                fill='#DA6F5B'
                d='M74.1 41.05c-.522.614-2.425 1.228-2.57 2.456a4.8 4.8 0 0 0 .872 2.9 2.2 2.2 0 0 1-.219 1.472c-.241.45-.632.798-1.102.985a2.8 2.8 0 0 1-1.739.086 2.83 2.83 0 0 1-1.461-.959c-.509-.835-.145-4.914-2.812-5.491s-3.164-2.654.933-3.919 10.328-.086 8.097 2.47'
            />
            <path
                fill='#358F99'
                d='M67.675 46.922a3.495 3.495 0 0 0 3.552.614c1.454-.553 1.006-1.671 1.006-1.671l.776.491c.184.36.224.779.11 1.168-.112.39-.37.72-.716.92a5.27 5.27 0 0 1-3.564.603 3.13 3.13 0 0 1-1.782-1.229z'
            />
            <path
                fill='#FF9E85'
                d='M72.475 29.71c3.709 3.182 4.667 8.207 2.157 11.216-2.509 3.01-9.696 2.531-13.515-.454-4.763-3.686-2.424-8.059.085-11.057 2.51-2.997 7.564-2.923 11.273.295'
            />
            <path
                fill='#444789'
                d='M64.087 42.388c.68-.687.158-2.039-.909-3.267a4 4 0 0 1-1.07-1.83 4 4 0 0 1 .016-2.126c.509-1.229 1.709-1.352 3.43-2.015s4.073-2.457 4.279-4.287a5.06 5.06 0 0 0-.514-2.545 5 5 0 0 0-1.704-1.94c-1.212-.823-3.721-.638-6.897 2.09-2.315 1.99-5.443 5.441-5.091 8.697a8.8 8.8 0 0 0 1.628 3.972 8.6 8.6 0 0 0 3.305 2.699c2.473 1.376 3.2.909 3.527.552'
            />
            <path
                fill='#83CEDA'
                d='M155 17a1 1 0 0 0-1-1h-18a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1zM155 37a1 1 0 0 0-1-1h-18a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1zM155 57a1 1 0 0 0-1-1h-18a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1zM155 79a1 1 0 0 0-1-1h-18a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1zM145 24a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1zM145 44a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1zM145 64a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1zM145 86a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1z'
            />
            <rect
                width='11'
                height='11'
                x='116'
                y='16'
                fill='#fff'
                stroke='#83CEDA'
                strokeWidth='2'
                rx='5.5'
            />
            <rect
                width='11'
                height='11'
                x='116'
                y='36'
                fill='#fff'
                stroke='#83CEDA'
                strokeWidth='2'
                rx='5.5'
            />
            <rect
                width='11'
                height='11'
                x='116'
                y='56'
                fill='#fff'
                stroke='#83CEDA'
                strokeWidth='2'
                rx='5.5'
            />
            <rect
                width='11'
                height='11'
                x='116'
                y='78'
                fill='#fff'
                stroke='#83CEDA'
                strokeWidth='2'
                rx='5.5'
            />
            <rect
                width='5'
                height='5'
                x='119'
                y='19'
                fill='#83CEDA'
                rx='2.5'
            />
        </svg>
    );
};

export const Step2Image = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='160'
            height='160'
            fill='none'
        >
            <path
                fill='#F1FAFF'
                fillRule='evenodd'
                d='M70.62.114c38.487-1.876 78.814 19.55 88.179 58.426 8.249 34.243-27.695 56.369-55.735 76.254-24.274 17.212-53.343 35.427-77.73 18.389C-1.06 134.744-4.68 97.662 4.672 66.053 14.587 32.538 36.947 1.755 70.62.113'
                clipRule='evenodd'
            />
            <path
                fill='#303267'
                d='M99.287 127.947s5.267 2.465 5.807 2.637c.54.171 1.751.514 1.791 1.318.039.804-.356 1.437-1.159 1.397-.803-.039-12.536 0-12.97 0s-.751-.843-.79-2.346 1.316-2.637 1.316-3.507c0-.62.803-2.202 4.095-1.411.909.172.922 1.503 1.91 1.912'
            />
            <path
                fill='#444789'
                d='M71.594 76.715c2.818 2.057 17.87 11.167 21.964 15.122 2.634 2.637 4.464 4.575 3.016 7.066-1.449 2.492-2.318 4.997-9.534 1.635-3.332-1.556-7.9-2.637-11.851-4.693a34.5 34.5 0 0 1-9.942-7.014 21 21 0 0 1-1.923-2.637c-4.32-7.422 5.517-11.522 8.27-9.479'
            />
            <path
                fill='#444789'
                d='M93.15 128.105a10.15 10.15 0 0 0 5.4-1.318 1.01 1.01 0 0 0 .513-.884c-.092-4.297-.803-19.129-.711-21.093a26.2 26.2 0 0 0-1.725-8.86c-2.068-4.548-11.048-1.727-9.218 4.127 1.317 4.443 1.172 12.392 2.726 17.613a81 81 0 0 1 2.146 9.598.95.95 0 0 0 .87.817'
            />
            <path
                fill='#DA6F5B'
                d='M73.911 51.693s3.766 7.91 8.296 15.16c.267.425.645.767 1.093.99 5.268 2.636 11.852 5.431 14.933 7.303.632.383 1.317 1.068 1.133 1.793-.185.725-.448 1.319-2.16 1.12-3.753-.302-14.683-3.044-14.683-3.044a9.3 9.3 0 0 1-4.622-2.308 64.3 64.3 0 0 1-10.626-14.633c-1.936-3.48-1.172-6.526.618-7.37 2.147-1.068 4.741-.923 6.018.989'
            />
            <path
                fill='#3BA2AD'
                d='M72.147 49.808a14.1 14.1 0 0 1 3.397 3.124 49.6 49.6 0 0 1 3.951 7.528c.527 1.318 1.067 2.927 1.527 3.81a.38.38 0 0 1 0 .422 13.58 13.58 0 0 1-6.347 4.522.37.37 0 0 1-.421-.132c-.75-.936-1.686-1.859-2.423-2.887a56.5 56.5 0 0 1-3.858-6.144c-1.225-2.307-3.556-6.67-1.12-9.452a4.94 4.94 0 0 1 5.294-.791'
            />
            <path
                fill='#5C60B9'
                d='M81.417 105.64q-.279.316-.606.58c-2.357 1.872-4.2 3.955-9.613-1.806-.408-.448-.869-.896-1.316-1.318-1.936-1.82-4.306-3.705-6.453-5.854a31 31 0 0 1-1.422-1.49l-.935-1.08c-.119-.132-.224-.278-.33-.41a41 41 0 0 1-3.304-4.548 19.2 19.2 0 0 1-1.989-4.126 22 22 0 0 1-.75-3.19c-1.028-8.636 9.797-9.097 11.614-6.131.75 1.213 2.936 4.272 5.412 7.844a286 286 0 0 1 3.2 4.667l.066.093c.645.962 1.317 1.911 1.896 2.834a58 58 0 0 1 3.95 6.776c1.251 3.006 2.107 5.353.58 7.159'
            />
            <path
                fill='#303267'
                d='M60.19 127.947s.658 1.041 1.449 2.202q.122.145.29.237 1.045.596 2.159 1.055c.553.25 1.659.58 1.659 1.318s-.316.962-1.317 1.134a18.2 18.2 0 0 1-3.95 0 5.1 5.1 0 0 1-2.08-.739c-2.937-1.845-6.164-3.955-6.387-4.06-.224-.106-.211-1.068.5-2.347a3.83 3.83 0 0 1 3.002-1.938c1.027 0 2.002-.58 3.292.765a3.76 3.76 0 0 1 1.383 2.373'
            />
            <path
                fill='#5C60B9'
                d='M54.357 124.941a10.9 10.9 0 0 0 4.99 3.467 1 1 0 0 0 .584.051 1 1 0 0 0 .51-.288c3.515-4.601 13.813-14.133 15.512-15.478a19.6 19.6 0 0 0 6.057-8.081c2.2-5.129-9.218-8.424-11.285-1.925-.961 2.98-4.662 2.795-9.442 11.114a88 88 0 0 1-6.847 9.835 1.055 1.055 0 0 0-.08 1.305'
            />
            <path
                fill='#444789'
                d='M78.612 93.67a27.9 27.9 0 0 0-17.935.54 32.6 32.6 0 0 1-4.635-7.132l14.116-5.524c1.028 1.569 7.401 10.6 8.454 12.115'
            />
            <path
                fill='#65BDCC'
                d='M76.61 59.194c-1.132 6.13-3.765 6.935-5.464 11.865s-1.462 8.623-.264 10.64c.159.263-3.87-1.055-6.689-1.319-5.267-.422-8.362-.474-11.483.449a60.2 60.2 0 0 1-.303-19.908c1.436-7.594 5.795-10.982 10.153-11.865 1.638-.29 3.303-.401 4.965-.33 5.596.211 10.363 3.547 9.086 10.468'
            />
            <path
                fill='#DA6F5B'
                d='M78.257 49.28a28 28 0 0 0-3.556-.33c-1.092.264-1.975 1.886-2.422 2.901-.132.29-.198.475-.264.607-.29.804-2.502 1.094-3.37.303-1.318-1.213-1.713-2.07-1.318-3.112.395-1.041 4.293-3.73 3.2-6.592-1.225-3.348-.566-4.508 3.543-1.845 4.108 2.663 8.006 8.121 4.187 8.068'
            />
            <path
                fill='#3BA2AD'
                d='M68.012 48.687c-.408.66.25 2.175 1.87 3.401 1.896 1.437 2.396-.237 2.396-.237l.343.976s-.158 1.872-2.634.83a6.07 6.07 0 0 1-2.884-2.808 2.22 2.22 0 0 1-.158-2.004z'
            />
            <path
                fill='#FF9E85'
                d='M85.355 37.995c1.198 5.432-1.12 10.547-5.175 11.457-4.056.91-9.877-4.562-11.338-9.94-1.804-6.593 2.963-8.86 7.005-9.757 4.043-.896 8.31 2.795 9.508 8.24'
            />
            <path
                fill='#444789'
                d='M70.685 43.44c2.305.606 3.042-2.017 2.924-3.85-.119-1.832-.606-4.034.79-4.456a6.8 6.8 0 0 1 4.227.172c2.014.593 4.2.777 5.675-.778a5.66 5.66 0 0 0 1.054-5.59c-.593-1.609-2.95-3.151-7.717-2.914-3.476.185-8.678 1.12-10.535 4.298s-1.053 5.656.25 8.622c1.304 2.966 2.806 4.364 3.332 4.496'
            />
            <path
                fill='#624799'
                d='m37.36 87.75-.376-1.414a2.49 2.49 0 0 0-3.046-1.766l-7.533 2.01a2.49 2.49 0 0 0-1.764 3.049l.376 1.414a2.49 2.49 0 0 0 3.046 1.766l7.533-2.01a2.49 2.49 0 0 0 1.764-3.049'
            />
            <path
                fill='#9485DE'
                d='M124.148 72.022 36.053 95.528a2.63 2.63 0 0 1-3.227-1.859l-2.712-10.177a2.64 2.64 0 0 1 .932-2.786c.275-.212.59-.367.924-.457l88.228-23.494L136 61.145l-11.759 10.85z'
            />
            <path
                fill='#624799'
                d='m124.241 71.983-4.056-15.24L136 61.132z'
            />
            <path
                fill='#B9B1DE'
                d='M117.749 60.803a1.084 1.084 0 0 1-.817 1.054L63.982 75.99a1.08 1.08 0 0 1-.974-.21l-1.317-1.161c-.263-.211.066-.303.066-.303l54.649-14.581a1.1 1.1 0 0 1 .907.226 1.12 1.12 0 0 1 .41.842zM46.785 79.892a.436.436 0 0 1-.197.739l-11.575 3.085a1.078 1.078 0 0 1-1.197-1.596c.145-.246.38-.426.657-.5l11.074-2.94a.42.42 0 0 1 .488.197q.33.541.75 1.015'
            />
            <path
                fill='#FFA083'
                d='M61.165 52.47c4.424 1.965 2.857 6.79 1.106 10.337s-7.005 13.856-7.65 15.042a3.86 3.86 0 0 1-4.596 1.437c-2.094-.975-3.095-2.518-2.213-5.036.672-1.925 5.268-15.979 6.216-17.903.948-1.925 3.2-5.63 7.137-3.876'
            />
            <path
                fill='#FFA083'
                d='M49.616 78.917c2.49 1.859 14.696 8.292 18.436 9.413 3.16.95 4.082-2.294 1.396-4.113S59.203 75.924 56.74 74.026c-2.462-1.899-6.189-3.665-8.204-.91a3.957 3.957 0 0 0 1.08 5.8'
            />
            <path
                fill='#83CEDA'
                d='M60.94 51.957a5.16 5.16 0 0 1 3.027 2.657 5.18 5.18 0 0 1 .2 4.027 121 121 0 0 1-5.268 11.693.54.54 0 0 1-.513.304c-1.7-.16-3.36-.605-4.912-1.319a8.44 8.44 0 0 1-3.608-2.162.45.45 0 0 1 0-.369c.263-.975 2.054-6.592 3.305-9.888 1.37-3.612 3.885-6.5 7.77-4.943'
            />
            <path
                fill='#FFA083'
                d='M66.906 87.856c1.159 1.041 5.741 1.66 8.533.553a9.37 9.37 0 0 0 5.267-4.772s-4.319 1.12-5.056 1.318c-.672 0-.567-1.24.526-1.727s1.83-2.004 1.317-2.465c-.513-.462-.553.079-1.317.633-.763.553-4.687 1.107-5.372 2.267-.514.87-1.027 1.015-1.317.712a1.79 1.79 0 0 0-2.634 1.042c-.79 1.66-.54 1.911.053 2.439'
            />
            <path
                fill='#FF9D84'
                d='M102.091 76.096c-1.869-1.134-3.687-1.015-3.581-.264.105.752-.83-.395-1.567.422-.738.817-1.62 1.463-1.225 2.228s-.79.382-.83.356-1.435 0-.395 1.147 2.634 1.569 3.28.633 1.08 0 2.146-.87 2.107-.449 3.042-1.319-.211-1.898-.87-2.333'
            />
        </svg>
    );
};

export const Step3Image = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='160'
            height='160'
            fill='none'
        >
            <path
                fill='#F1FAFF'
                fillRule='evenodd'
                d='M70.62.114c38.487-1.876 78.814 19.55 88.179 58.426 8.249 34.243-27.695 56.369-55.735 76.254-24.274 17.212-53.343 35.427-77.73 18.389C-1.06 134.744-4.68 97.662 4.672 66.053 14.587 32.538 36.947 1.755 70.62.113'
                clipRule='evenodd'
            />
            <path
                fill='#F58B8B'
                d='M45 142.077c0 4.491 3.597 8.166 8.141 8.166h33.052c4.545 0 8.263-3.675 8.263-8.166V89H45z'
            />
            <path
                fill='#F58B8B'
                d='M98.84 82.877a2 2 0 0 0-2-2H85.202a2 2 0 0 1-1.406-.578l-2.963-2.928a2 2 0 0 0-1.406-.577H60.413a2 2 0 0 0-1.406.577L56.044 80.3a2 2 0 0 1-1.405.577H43a2 2 0 0 0-2 2v4.166a2 2 0 0 0 2 2h53.84a2 2 0 0 0 2-2z'
            />
            <path
                fill='#A76363'
                d='M64 77h16.323L87 89H75.871c-1.484 0-3.71-4.286-6.677-5.143C66.819 83.171 64.742 79 64 77'
            />
            <path
                fill='#A76363'
                d='M98.84 82.877a2 2 0 0 0-2-2H85.203a2 2 0 0 1-1.406-.578l-2.162-2.137a2 2 0 0 0-2.79-.02l-.062.059a2 2 0 0 0-.282 2.549l.777 1.171 5.954 7.121h11.61a2 2 0 0 0 2-2z'
            />
            <path
                fill='#D27D7D'
                d='M93 89H45v8z'
            />
            <path
                fill='#303267'
                d='M112.798 96.788s4.968 2.914 5.548 3.176 1.691.68 1.653 1.476-.484 1.389-1.266 1.282c-.783-.107-12.469-1.136-12.904-1.175s-.677-.903-.58-2.418a3 3 0 0 1 .649-1.647 3 3 0 0 1 1.458-.995 6.57 6.57 0 0 1 5.442.301'
            />
            <path
                fill='#444789'
                d='M111.58 95.623c-1.044-3.554-5.094-15.829-5.432-17.48a19.3 19.3 0 0 0-1.643-4.486c-.174-2.495-2.62-3.467-5.974-4.564-5.587-1.826-23.633-3.476-27.17-4.098s-10.74 7.623-3.403 12.624q1.427.882 2.958 1.564c2.474 1.107 6.766.97 11.696.97 4.62 0 9.665-1.35 13.435-1.417 1.073 2.632.967 5.603 4.591 11.332 1.933 3.02 3.789 6.089 4.34 7.953a.906.906 0 0 0 1.025.476 12 12 0 0 0 5.21-2.03.755.755 0 0 0 .367-.844'
            />
            <path
                fill='#DA6F5B'
                d='M74.454 36.62a165 165 0 0 0 14.711 9.537c.454.262 3.373 1.709 3.228 3.884a3.77 3.77 0 0 1-1.528 2.935 3.73 3.73 0 0 1-3.247.58c-4.291-1.32-7.868-2.67-16.074-7.904-3.567-2.273-4.36-5.827-3.064-7.517a4.54 4.54 0 0 1 2.692-1.93 4.53 4.53 0 0 1 3.282.416'
            />
            <path
                fill='#DA6F5B'
                d='M88.556 46.72c.82-.142 1.623-.366 2.397-.67 5.8-2.564 13.31-5.934 16.084-6.953.754-.272 2.204 3.66 2.204 3.66l-15.147 9.41a16.5 16.5 0 0 1-4.456 1.826 3.3 3.3 0 0 1-1.988-.428 3.33 3.33 0 0 1-1.366-1.514 3.76 3.76 0 0 1 .838-4.539 3.7 3.7 0 0 1 1.434-.792'
            />
            <path
                fill='#3BA2AD'
                d='M72.124 35.717a6 6 0 0 1 2.803 1.03c1.662.97 6.466 4.292 8.7 5.622 1.246.738 2.677 1.729 3.508 2.311a.42.42 0 0 1 .164.418 14.17 14.17 0 0 1-3.644 7.196.4.4 0 0 1-.444.077c-1.131-.505-2.436-.893-3.587-1.486a58.5 58.5 0 0 1-6.456-3.767c-2.223-1.535-6.438-4.419-5.539-8.138.454-1.767 2.803-3.243 4.495-3.263'
            />
            <path
                fill='#303267'
                d='M102.919 116.122s3.644 2.914 4.833 4.069c.435.447 1.44 1.379 1.266 2.166a1.19 1.19 0 0 1-.495.852 1.19 1.19 0 0 1-.964.187c-3.605-.466-11.358-2.69-12.227-3.409-.339-.281-.803-.971-.455-2.418a3.02 3.02 0 0 1 2.523-2.253 6.49 6.49 0 0 1 5.519.806'
            />
            <path
                fill='#5C60B9'
                d='M101.208 114.559c-.841-4.855-3.866-17.839-4.137-20.072a30.5 30.5 0 0 0-3.49-9.798 4.4 4.4 0 0 0-1.062-1.156l-.242-.32c-3.422-4.7-17.06-16.237-19.583-18.752s-12.566-2.767-10.826 6.002a17.5 17.5 0 0 0 2.156 5.981c1.363 2.35 4.214 3.817 8.206 6.633 3.74 2.622 8.786 6.438 11.88 8.497l.27.165c1.324 3.593 1.16 7.836 3.567 13.041 2.049 4.438 4.697 7.419 5.925 12.362.069.239.218.447.423.587s.451.204.698.18a11.74 11.74 0 0 0 5.993-2.282c.329-.252.329-.66.222-1.068'
            />
            <path
                fill='#83CEDA'
                d='M79.76 45.952c-.174 3.38-1.933 4.943-2.9 9.575-.077.301-.135.612-.193.971-.87 5.38.164 8.536 1.198 9.342.252.204-.212.855-1.16 1.68-2.831 2.457-9.945 6.35-14.624 3.884a54.3 54.3 0 0 1-3.673-19.237 22.4 22.4 0 0 1 2.146-10.05 14.1 14.1 0 0 1 4.33-5.04 9.42 9.42 0 0 1 6.824-1.399c5.78 1.185 8.342 4.632 8.052 10.274'
            />
            <path
                fill='#65BDCC'
                d='M79.76 45.953c-.174 3.379-1.933 4.942-2.9 9.574-.077.301-.135.612-.193.971-.87 5.38.164 8.536 1.198 9.342.252.204-.135.971-1.507 1.942-3.432-2.641-8.042-5.826-10.13-15.886-.967-4.535 4.475-10.362 0-11.75-1.556-.467-3.983.35-5.655 1.99a14.1 14.1 0 0 1 4.33-5.04 9.88 9.88 0 0 1 7.057-1.466c5.732 1.437 8.09 4.68 7.8 10.322'
            />
            <path
                fill='#DA6F5B'
                d='M79.74 30.842c-.879.34-4.368.904-5.344 1.943-.977 1.039-1.402 3.971-1.44 4.758-.04.786-1.934 1.942-3.045 1.554-1.75-.651-2.436-1.321-2.513-2.467-.078-1.146 2.493-3.884.444-7.176-1.71-2.739-2.252-4.545 2.707-3.593 4.958.951 12.855 3.583 9.192 4.981'
            />
            <path
                fill='#FF9E85'
                d='M81.983 23.414c1.257 5.622-1.102 10.944-5.268 11.886s-9.124-4.904-10.632-10.45c-1.933-6.845 1.933-8.962 6.09-9.904 4.156-.942 8.505 2.845 9.81 8.468'
            />
            <path
                fill='#444789'
                d='M66.837 29.124c2.387.621 3.131-2.098 2.996-4.001s-.638-4.176.802-4.613a7.06 7.06 0 0 1 4.36.165c2.078.602 4.32.777 5.8-.844a5.852 5.852 0 0 0 1.063-5.827c-.62-1.66-3.065-3.253-7.975-2.971-3.576.204-8.94 1.204-10.884 4.486-1.943 3.282-1.063 5.826.3 8.914s2.996 4.555 3.538 4.69'
            />
            <path
                fill='#3BA2AD'
                d='M67.726 35.591c.226.66.64 1.238 1.193 1.661a3.5 3.5 0 0 0 1.91.718c2.184.272 2.204-1.155 2.204-1.155l.802.67s.174 1.748-2.34 1.942a5.1 5.1 0 0 1-3.778-1.127 3.3 3.3 0 0 1-1.112-2.146z'
            />
            <path
                fill='#5C60B9'
                d='m97.623 39.776-2.108 18.082a.68.68 0 0 0 .406.709c.194.087 1.315.65 1.528.495l10.284-8.39a1.19 1.19 0 0 0 .483-.796l3.335-17.606c0-.32-1.054-.641-1.44-.787a.69.69 0 0 0-.667 0l-11.27 7.43a1.11 1.11 0 0 0-.551.863'
            />
            <path
                fill='#303267'
                d='m98.744 40.243-2.146 18.081a.68.68 0 0 0 .321.661.68.68 0 0 0 .733-.02l11.164-7.827a1.22 1.22 0 0 0 .483-.806l2.271-17.693a.679.679 0 0 0-1.044-.65l-11.27 7.428a1.11 1.11 0 0 0-.512.826'
            />
            <path
                fill='#FF9D84'
                d='M111.174 35.27c-1.788.544-2.764 1.778-2.233 2.137.532.36-.715.3-.618 1.253.096.951 0 1.884.705 2.068.706.184-.203.728-.241.738-.039.01-.793.971.502.971 1.296 0 2.475-.748 2.291-1.748s.609-.69.677-1.874c.067-1.185.966-1.612.87-2.758-.097-1.146-1.315-.961-1.953-.787M103.673 48.866c.967-.865.967-1.34.851-1.612s-.416-.068-.358-.389c0-.068 0-.34-.145-.398-.29-.165-.908.107-.966.136s0 0 0-.068c.154-.573-.348-.64-.725-.534-1.131.33-1.643.505-2.194.7-1.402.494-2.407 1.116-2.253.388.3-1.36.484-3.185-.241-3.506s-.648 1.243-1.102 2.243c-.967 2.03-2.774 3.263-3.035 4.642-3.229 1.117-8.071 2.913-12.691 4.73q-.417.162-.851.271a166 166 0 0 1-11.328-13.178 4.53 4.53 0 0 0-6.157-.223c-1.721 1.253-1.934 4.855.821 8.08 6.399 7.35 9.453 9.71 13.204 12.148.277.18.58.317.898.407a3.13 3.13 0 0 0 1.779.399c1.578-.336 3.1-.902 4.514-1.68l12.788-7.254q.416.03.831 0c2.33-.097 3.808-1.942 5.094-2.913 1.46-1.069 1.576-1.816 1.605-2.01.116-.496-.648-.078-.339-.38'
            />
            <path
                fill='#83CEDA'
                d='M65.996 40.126a6.76 6.76 0 0 1 4.31 2.68c1.15 1.65 3.345 4.321 4.95 6.642.811 1.175 1.643 1.942 2.677 3.224.106.126.164.243.077.389a16.47 16.47 0 0 1-6.263 6.282.46.46 0 0 1-.483 0c-.967-.825-.455-.35-1.44-1.252a60 60 0 0 1-5.394-5.545c-1.788-2.136-4.833-6.632-2.9-10.041a4.7 4.7 0 0 1 1.886-1.848 4.66 4.66 0 0 1 2.58-.531'
            />
        </svg>
    );
};
