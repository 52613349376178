import { api } from '@/api/api';

import { setMiddleVisible } from '@/store/slices/cpgViewSlice';

import {
    addWorkRes,
    getHeadersRes,
    getWorksPeriodsReqI,
    getWorksPeriodsResI,
    setTagReq,
    TUpdatePlanningModeRequest,
    TUpdatePlanningModeResponse,
    updateChartsCPGReq,
    updateChartsKs3I,
} from './ksg.types';

export const ksgApi = api.injectEndpoints({
    endpoints: (build) => ({
        getHeadersCPG: build.query<getHeadersRes, { id: number; isStaff?: boolean; isTech?: boolean }>({
            query: ({ id, isStaff, isTech }) => ({
                url: `/projects/${id}/headers`,
                method: 'GET',
                params: {
                    isStaff,
                    isTech,
                },
            }),
            async onQueryStarted(arg, api) {
                try {
                    if (arg.isStaff || arg.isTech) return;

                    const data = await api.queryFulfilled;
                    api.dispatch(setMiddleVisible(data.data.isCreatedKSG));
                } catch {}
            },
            providesTags: ['Headers'],
        }),

        downloadExcel: build.mutation<string, { id: number }>({
            query: ({ id }) => ({
                url: `/projects/${id}/works/export-ksg?advMode=true`,
                method: 'POST',
                headers: {
                    'content-type': 'text/plain',
                },
                responseHandler: 'text',
            }),
        }),

        updateChartsCPG: build.mutation<addWorkRes, updateChartsCPGReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/works/update-charts`,
                method: 'POST',
                body,
            }),
        }),

        updateChartsKs3: build.mutation<unknown, updateChartsKs3I>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/works/update-charts-kc3`,
                method: 'POST',
                body,
            }),
        }),

        setTag: build.mutation<unknown, setTagReq>({
            query: ({ id, workId, body }) => ({
                url: `/projects/${id}/works/${workId}/set-tag`,
                method: 'POST',
                body,
            }),
        }),

        getWorkPeriods: build.query<getWorksPeriodsResI, getWorksPeriodsReqI>({
            query: ({ projectId, workId }) => ({
                url: `/projects/${projectId}/works/${workId}/periods`,
                method: 'GET',
            }),
        }),

        updatePlanningMode: build.mutation<TUpdatePlanningModeResponse, TUpdatePlanningModeRequest>({
            query: ({ projectId, workId, patch }) => ({
                url: `/projects/${projectId}/dependencies/works/ksg/${workId}/planning-mode`,
                method: 'PATCH',
                body: patch,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetWorkPeriodsQuery,
    useGetHeadersCPGQuery,
    useLazyGetHeadersCPGQuery,
    useUpdateChartsCPGMutation,
    useDownloadExcelMutation,
    useSetTagMutation,
    useUpdateChartsKs3Mutation,
    useUpdatePlanningModeMutation,
} = ksgApi;
