import { api } from '@/api/api';

import { filtersKsgUnlimitedI, levelsUnlimitedI } from '@/store/slices/filtersSlice';

export type queryTermsType =
    | 'contractorCompany'
    | 'objName'
    | 'objTitle'
    | 'rdCode'
    | 'rdStatus'
    | 'workName'
    | 'workGroup';
export type queryTermsRequestType = 'contractor' | 'obj' | 'title' | 'rd' | 'status' | 'work' | 'group';

export const filtersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getFilterListUnlimited: build.query<
            { data: levelsUnlimitedI },
            { id: number; filters?: filtersKsgUnlimitedI & { filter?: string }; term: queryTermsRequestType }
        >({
            query: ({ id, filters, term }) => ({
                url: `/projects/${id}/works/mega-filter-unlimited/${term}`,
                method: 'POST',
                body: filters,
            }),
        }),

        getFilterListUnlimitedV2: build.query<
            { data: string[] },
            { id: number; filters?: filtersKsgUnlimitedI & { filter?: string }; endpoint: string }
        >({
            query: ({ id, filters, endpoint }) => ({
                url: `/projects/${id}/works/mega-filter-unlimited/${endpoint}/v2`,
                method: 'POST',
                body: filters,
            }),
        }),

        getFilterListMsgUnlimited: build.query<
            { data: levelsUnlimitedI },
            {
                id: number;
                filters?: filtersKsgUnlimitedI & { filter?: string };
                term: queryTermsRequestType;
                year: number;
                month: number;
            }
        >({
            query: ({ id, filters, term, year, month }) => ({
                url: `/projects/${id}/works/mega-filter-unlimited/msg/${term}`,
                method: 'POST',
                params: { year, month },
                body: filters,
            }),
        }),

        getFilterListMsgUnlimitedV2: build.query<
            { data: string[] },
            {
                id: number;
                filters?: filtersKsgUnlimitedI & { filter?: string };
                endpoint: string;
                year: number;
                month: number;
            }
        >({
            query: ({ id, filters, endpoint, year, month }) => ({
                url: `/projects/${id}/works/mega-filter-unlimited/msg/${endpoint}/v2`,
                method: 'POST',
                params: { year, month },
                body: filters,
            }),
        }),

        getFiltersListPeople: build.query<{ data: string[] }, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/staff/filter/title`,
                method: 'POST',
            }),
        }),

        getFiltersListEquip: build.query<{ data: string[] }, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/tech/filter/title`,
                method: 'POST',
            }),
        }),

        getFiltersListMsgPeople: build.query<{ data: string[] }, { projectId: number; year: number; month: number }>({
            query: ({ projectId, year, month }) => ({
                url: `/projects/${projectId}/staff/filter/msg/title`,
                params: { year, month },
                method: 'POST',
            }),
        }),

        getFiltersListMsgEquip: build.query<{ data: string[] }, { projectId: number; year: number; month: number }>({
            query: ({ projectId, year, month }) => ({
                url: `/projects/${projectId}/tech/filter/msg/title`,
                params: { year, month },
                method: 'POST',
            }),
        }),

        getFilterBrigadesListMsg: build.query<
            { data: string[] },
            {
                id: number;
                filters?: filtersKsgUnlimitedI & { filter?: string };
                year: number;
                month: number;
            }
        >({
            query: ({ id, filters, year, month }) => ({
                url: `/projects/${id}/works/filter/msg/brigades`,
                method: 'POST',
                params: { year, month },
                body: filters,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetFilterListMsgUnlimitedQuery,
    useGetFilterListMsgUnlimitedV2Query,
    useGetFilterBrigadesListMsgQuery,
    useGetFiltersListEquipQuery,
    useGetFiltersListPeopleQuery,
    useGetFiltersListMsgEquipQuery,
    useGetFiltersListMsgPeopleQuery,
    useGetFilterListUnlimitedQuery,
    useGetFilterListUnlimitedV2Query,
} = filtersApi;
export default filtersApi;
