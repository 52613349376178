import { ICellRendererParams, IHeaderParams } from 'ag-grid-community';

import { ITotalRow } from '@/shared/components/AgGrid/AgGrid.types';

import { EstimateAPI } from '../../../../api/EstimateAPI';
import { COL_DEF_TEMPLATE } from '../../../../components/AgGridService/AgGridColumnDef.service';
import { DeleteRowRenderer } from '../../../../components/CellRenderers/DeleteRowRenderer/DeleteRowRenderer';
import { NumberCellRenderer } from '../../../../components/CellRenderers/NumberCellRenderer/NumberCellRenderer';
import { IEstimatePosition, IEstimatePositionsColDef } from './EstimatePositionsPage.types';

export const estimatePositionsColDef: IEstimatePositionsColDef[] = [
    {
        ...COL_DEF_TEMPLATE.ADD_NEW_ROW_TABLE_COL((params) => addNewPositionRow(getNewEstimatePositionRow(), params)),
    },
    {
        headerName: 'Тип',
        field: 'name',
        editable: (params) => !params.data.totalRow,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left ag-cell-editable',
    },
    {
        headerName: 'Шифр расценки',
        field: 'code',
        maxWidth: 200,
        editable: (params) => !params.data.totalRow,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
    },
    {
        headerName: 'Ед. изм.',
        field: 'unit',
        maxWidth: 150,
        editable: (params) => !params.data.totalRow,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
    },
    {
        headerName: 'Кол-во',
        field: 'quantity',
        maxWidth: 150,
        editable: (params) => !params.data.totalRow,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('quantity'),
        cellRenderer: (params) =>
            !params.data?.totalRow ? <NumberCellRenderer {...params} /> : params.colDef?.cellRenderer,
    },
    {
        headerName: 'Стоимость за ед., р.',
        field: 'cost',
        maxWidth: 150,
        editable: (params) => !params.data.totalRow,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('cost', 999999999),
    },
    {
        headerName: 'Стоимость всего, р.',
        field: 'costTotal',
        maxWidth: 150,
        valueGetter: (params) => {
            const cost = params.getValue('cost') || 0;
            const quantity = params.getValue('quantity') || 0;
            return cost * quantity;
        },
    },
    {
        ...COL_DEF_TEMPLATE.DELETE_ROW_TABLE_COL((params) => removePositionRow(params)),
        cellRenderer: (params: ICellRendererParams) =>
            params.data.totalRow ? (
                <></>
            ) : (
                <DeleteRowRenderer
                    params={params}
                    deleteCallBack={removePositionRow}
                />
            ),
    },
];

export const newEstimatePositionRow: IEstimatePosition = {
    id: 0,
    name: 'Новая строка',
    code: '',
    unit: '',
    quantity: 0,
    cost: 0,
    costTotal: 0,
};

function getNewEstimatePositionRow() {
    return {
        ...newEstimatePositionRow,
        id: Math.random(),
    };
}

export function addNewPositionRow(templateRow: any, params: IHeaderParams) {
    EstimateAPI.addNewItem({
        estimateType: params.context.estimateType,
        projectID: params.context.projectID,
        workID: params.context.workID,
        body: templateRow,
    }).then((data) => {
        params.api.applyTransactionAsync({ add: [data] });
    });
}

export function removePositionRow(params: ICellRendererParams) {
    EstimateAPI.deleteItem({
        estimateType: params.context.estimateType,
        projectID: params.context.projectID,
        workID: params.context.workID,
        estimateItemID: params.data.id,
    }).then(() => {
        params.api.applyTransactionAsync({ remove: [params.data] });
    });
}

export const TOTAL_ROW = {
    id: 'totalRow',
    name: 'ИТОГО',
    unit: '',
    quantity: 0,
    cost: 0,
    code: '',
} as ITotalRow<IEstimatePosition>;
