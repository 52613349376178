import { createInstance } from '@/api/apiInstance';

import {
    TAckEstimatePositionsChangesRequest,
    TAckEstimatePositionsChangesResponse,
    TDeleteByIdRequest,
    TDeleteByIdResponse,
    TEstimatePositionsRequest,
    TEstimatePositionsResponse,
    TFilterEstimatePositionsRequest,
    TFilterEstimatePositionsResponse,
    TRefreshEstimatePositionsRequest,
    TRefreshEstimatePositionsResponse,
    TTabCountersRequest,
    TTabCountersResponse,
} from "./estimatePositions.types";

export const estimatePositionsApi = {
    fetchList: async ({ body, limit, offset, projectId, listType }: TEstimatePositionsRequest) => {
        const data = await createInstance<TEstimatePositionsResponse>({
            url: `/projects/${projectId}/positions`,
            method: 'post',
            data: body,
            params: {
                limit,
                listType,
                offset,
            },
        });
        return data;
    },
    fetchFilters: async ({
        body,
        limit,
        offset,
        projectId,
        search,
        column,
        listType,
    }: TFilterEstimatePositionsRequest) => {
        const data = await createInstance<TFilterEstimatePositionsResponse>({
            url: `/projects/${projectId}/positions/filters`,
            method: 'post',
            data: body,
            params: {
                limit,
                offset,
                search,
                column,
                listType,
            },
        });
        return data;
    },
    ackChanges: async ({ projectId, body }: TAckEstimatePositionsChangesRequest) => {
        const data = await createInstance<TAckEstimatePositionsChangesResponse>({
            url: `/projects/${projectId}/positions/changes/ack`,
            method: 'post',
            data: body,
        });
        return data;
    },
    refreshFromOffer: async ({ projectId }: TRefreshEstimatePositionsRequest) => {
        const data = await createInstance<TRefreshEstimatePositionsResponse>({
            url: `/projects/${projectId}/positions/refresh-from-offer`,
            method: 'post',
        });
        return data;
    },
    fetchTabCounters: async ({ body, projectId }: TTabCountersRequest) => {
        const data = await createInstance<TTabCountersResponse>({
            url: `/projects/${projectId}/positions/tab-counters`,
            method: 'post',
            data: body,
        });
        return data;
    },
    deleteById: async ({ id, projectId }: TDeleteByIdRequest) => {
        const data = await createInstance<TDeleteByIdResponse>({
            url: `/projects/${projectId}/positions/${id}`,
            method: 'delete',
        });
        return data;
    },
};
