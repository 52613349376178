import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { templateBody } from "../../api/templates/templates.types"
import { fixShow } from './cpgViewSlice'

const columnTerm = {
    fix: false,
    show: true,
}

export const initCpgTemplateState = {
    name: '',
    level: columnTerm,
    code: columnTerm,
    objTitle: columnTerm,
    objName: columnTerm,
    numOrder: columnTerm,
    rdGroup: columnTerm,
    rdCode: columnTerm,
    rdStatus: columnTerm,
    rdDateApproval: columnTerm,
    rdVersion: columnTerm,
    rdDate: columnTerm,
    workGroup: columnTerm,
    contractorCompany: columnTerm,
    workName: columnTerm,
    unit: columnTerm,
    volumeTotal: columnTerm,
    volumeDone: columnTerm,
    volumeDonePlan: columnTerm,
    volumeDoneFact: columnTerm,
    basePlan: columnTerm,
    startDate: columnTerm,
    endDate: columnTerm,
    operPlan: columnTerm,
    operationalStartDate: columnTerm,
    operationalEndDate: columnTerm,
    humanHourCost: columnTerm,
    humanHoursTotal: columnTerm,
    humanHoursProgressTZ: columnTerm,
    humanHours: columnTerm,
    humanHoursProgressFact: columnTerm,
    humanHoursProgressPlan: columnTerm,
    humanHoursProgressPercent: columnTerm,
    costER: columnTerm,
    costTotal: columnTerm,
    costRemaining: columnTerm,
    machineHourCost: columnTerm,
    machineHoursTotal: columnTerm,
    costTotalProgress: columnTerm,
    costProgress: columnTerm,
    costProgressFact: columnTerm,
    costProgressPlan: columnTerm,
    costProgressPercent: columnTerm,
    costDone: columnTerm,
    costNZP: columnTerm,
    tag: columnTerm,
    workPeriod: columnTerm,
    prStart: columnTerm,
    prEnd: columnTerm,
    prProvision: columnTerm,
}

export type initCpgTemplateStateType = typeof initCpgTemplateState

interface initialStateI {
    templateCpgState: initCpgTemplateStateType
    templateCpgStateView: initCpgTemplateStateType
}

const initialState: initialStateI = {
    templateCpgState: initCpgTemplateState,
    templateCpgStateView: initCpgTemplateState,
}

const slice = createSlice({
    name: 'ksgTemplates',
    initialState,
    reducers: {
        setTemplateCpgState(state, action: PayloadAction<templateBody | null>) {
            const terms = action.payload
            if (!terms) return
            for (let k in state.templateCpgState) {
                if (k === 'volumeDone' || k === 'humanHours' || k === 'costProgress' || k === 'basePlan' || k === 'operPlan') {
                    if (k === 'volumeDone') {
                        state.templateCpgState.volumeDone = {
                            fix: terms.volumeDoneFact.fix || terms.volumeDonePlan.fix,
                            show: terms.volumeDoneFact.show || terms.volumeDonePlan.show || false
                        }
                        state.templateCpgState.volumeDoneFact = terms.volumeDoneFact
                        state.templateCpgState.volumeDonePlan = terms.volumeDonePlan
                    }
                    if (k === 'humanHours') {
                        state.templateCpgState.humanHours = {
                            show: terms.humanHoursProgressFact.show || terms.humanHoursProgressPlan.show || terms.humanHoursProgressPercent.show || false,
                            fix: terms.humanHoursProgressFact.fix || terms.humanHoursProgressPlan.fix || terms.humanHoursProgressPercent.fix
                        }
                        state.templateCpgState.humanHoursProgressFact = terms.humanHoursProgressFact;
                        state.templateCpgState.humanHoursProgressPlan = terms.humanHoursProgressPlan;
                        state.templateCpgState.humanHoursProgressPercent = terms.humanHoursProgressPercent;
                    }
                    if (k === 'costProgress') {
                        state.templateCpgState.costProgress = {
                            show: terms.costProgressFact.show || terms.costProgressPlan.show || terms.costProgressPercent.show || false,
                            fix: terms.costProgressFact.fix || terms.costProgressPlan.fix || terms.costProgressPercent.fix
                        }
                        state.templateCpgState.costProgressFact = terms.costProgressFact;
                        state.templateCpgState.costProgressPlan = terms.costProgressPlan;
                        state.templateCpgState.costProgressPercent = terms.costProgressPercent;
                    }
                    if (k === 'basePlan') {
                        state.templateCpgState.basePlan = {
                            show: terms.startDate.show || terms.endDate.show || false,
                            fix: terms.startDate.fix || terms.endDate.fix
                        }
                        state.templateCpgState.startDate = terms.startDate;
                        state.templateCpgState.endDate = terms.endDate;
                    }
                    if (k === 'operPlan') {
                        state.templateCpgState.operPlan = {
                            show: terms.operationalStartDate.show || terms.operationalEndDate.show || false,
                            fix: terms.operationalStartDate.fix || terms.operationalEndDate.fix
                        }
                        state.templateCpgState.operationalStartDate = terms.operationalStartDate;
                        state.templateCpgState.operationalEndDate = terms.operationalEndDate;
                    }

                } else if (k === 'name') {
                    state.templateCpgState.name = terms.name
                } else {
                    state.templateCpgState[k as keyof Omit<initCpgTemplateStateType, 'name'>] =
                        terms[
                        k as keyof Omit<
                            initCpgTemplateStateType,
                            'volumeDone' | 'humanHours' | 'costProgress' | 'name' | 'numOrder' | 'basePlan' | 'operPlan'
                        >
                        ]
                }
            }
            if (!state.templateCpgState.volumeDonePlan.show && !state.templateCpgState.volumeDoneFact.show) {
                state.templateCpgState.volumeDone.show = false
                state.templateCpgState.volumeDone.fix = false
            }
            if (state.templateCpgState.volumeDonePlan.show || state.templateCpgState.volumeDoneFact.show) {
                state.templateCpgState.volumeDone.show = true
            }
            if (!state.templateCpgState.humanHoursProgressFact.show && !state.templateCpgState.humanHoursProgressPlan.show && !state.templateCpgState.humanHoursProgressPercent.show) {
                state.templateCpgState.humanHours.show = false
                state.templateCpgState.humanHours.fix = false
            }
            if (state.templateCpgState.humanHoursProgressFact.show || state.templateCpgState.humanHoursProgressPlan.show || state.templateCpgState.humanHoursProgressPercent.show) {
                state.templateCpgState.humanHours.show = true
            }

            if (!state.templateCpgState.costProgressFact.show && !state.templateCpgState.costProgressPlan.show && !state.templateCpgState.costProgressPercent.show) {
                state.templateCpgState.costProgress.show = false
                state.templateCpgState.costProgress.fix = false
            }
            if (state.templateCpgState.costProgressFact.show || state.templateCpgState.costProgressPlan.show || state.templateCpgState.costProgressPercent.show) {
                state.templateCpgState.costProgress.show = true
            }
            if (!state.templateCpgState.startDate.show && !state.templateCpgState.endDate.show) {
                state.templateCpgState.basePlan.show = false
                state.templateCpgState.basePlan.fix = false
            }
            if (state.templateCpgState.startDate.show || state.templateCpgState.endDate.show) {
                state.templateCpgState.basePlan.show = true
            }

            if (!state.templateCpgState.operationalStartDate.show && !state.templateCpgState.operationalEndDate.show) {
                state.templateCpgState.operPlan.show = false
                state.templateCpgState.operPlan.fix = false
            }
            if (state.templateCpgState.operationalStartDate.show || state.templateCpgState.operationalEndDate.show) {
                state.templateCpgState.operPlan.show = true
            }

        },

        checkTemplateCpgItem: {
            reducer: (
                state,
                action: PayloadAction<{
                    term: keyof Omit<initCpgTemplateStateType, 'name'>
                    checked: boolean
                    type: fixShow
                }>
            ) => {
                const { term, checked, type } = action.payload
                if (type === 'show') {
                    state.templateCpgState[term as keyof Omit<initCpgTemplateStateType, 'name'>].show = checked
                    if (!checked) {
                        state.templateCpgState[term as keyof Omit<initCpgTemplateStateType, 'name'>].fix = false
                    }
                } else {
                    if (state.templateCpgState[term as keyof Omit<initCpgTemplateStateType, 'name'>].show === true) {
                        state.templateCpgState[term as keyof Omit<initCpgTemplateStateType, 'name'>].fix = checked
                    }
                }
            },
            prepare: (term: keyof Omit<initCpgTemplateStateType, 'name'>, checked: boolean, type: fixShow) => {
                return { payload: { term, checked, type } }
            },
        },

        setTemplateCpgStateView(state, action: PayloadAction<templateBody | null>) {
            const terms = action.payload
            if (!terms) return
            for (let k in state.templateCpgStateView) {
                if (k === 'volumeDone' || k === 'humanHours' || k === 'costProgress' || k === 'basePlan' || k === 'operPlan') {
                    if (k === 'volumeDone') {
                        state.templateCpgStateView.volumeDone = {
                            fix: terms.volumeDoneFact.fix || terms.volumeDonePlan.fix,
                            show: terms.volumeDoneFact.show || terms.volumeDonePlan.show || false
                        }
                        state.templateCpgStateView.volumeDoneFact = terms.volumeDoneFact
                        state.templateCpgStateView.volumeDonePlan = terms.volumeDonePlan
                    }
                    if (k === 'humanHours') {
                        state.templateCpgStateView.humanHours = {
                            show: terms.humanHoursProgressFact.show || terms.humanHoursProgressPlan.show || terms.humanHoursProgressPercent.show || false,
                            fix: terms.humanHoursProgressFact.fix || terms.humanHoursProgressPlan.fix || terms.humanHoursProgressPercent.fix
                        }
                        state.templateCpgStateView.humanHoursProgressFact = terms.humanHoursProgressFact;
                        state.templateCpgStateView.humanHoursProgressPlan = terms.humanHoursProgressPlan;
                        state.templateCpgStateView.humanHoursProgressPercent = terms.humanHoursProgressPercent;
                    }
                    if (k === 'costProgress') {
                        state.templateCpgStateView.costProgress = {
                            show: terms.costProgressFact.show || terms.costProgressPlan.show || terms.costProgressPercent.show || false,
                            fix: terms.costProgressFact.fix || terms.costProgressPlan.fix || terms.costProgressPercent.fix
                        }
                        state.templateCpgStateView.costProgressFact = terms.costProgressFact;
                        state.templateCpgStateView.costProgressPlan = terms.costProgressPlan;
                        state.templateCpgStateView.costProgressPercent = terms.costProgressPercent;
                    }
                    if (k === 'basePlan') {
                        state.templateCpgStateView.basePlan = {
                            show: terms.startDate.show || terms.endDate.show || false,
                            fix: terms.startDate.fix || terms.endDate.fix
                        }
                        state.templateCpgStateView.startDate = terms.startDate;
                        state.templateCpgStateView.endDate = terms.endDate;
                    }
                    if (k === 'operPlan') {
                        state.templateCpgStateView.operPlan = {
                            show: terms.operationalStartDate.show || terms.operationalEndDate.show || false,
                            fix: terms.operationalStartDate.fix || terms.operationalEndDate.fix
                        }
                        state.templateCpgStateView.startDate = terms.startDate;
                        state.templateCpgStateView.endDate = terms.endDate;
                    }
                } else if (k === 'name') {
                    state.templateCpgStateView.name = terms.name
                } else {
                    state.templateCpgStateView[k as keyof Omit<initCpgTemplateStateType, 'name'>] =
                        terms[
                        k as keyof Omit<
                            initCpgTemplateStateType,
                            'volumeDone' | 'humanHours' | 'costProgress' | 'name' | 'numOrder' | 'basePlan' | 'operPlan'
                        >
                        ]
                }
            }
            if (!state.templateCpgState.volumeDonePlan.show && !state.templateCpgState.volumeDoneFact.show) {
                state.templateCpgState.volumeDone.show = false
                state.templateCpgState.volumeDone.fix = false
            }
            if (state.templateCpgState.volumeDonePlan.show || state.templateCpgState.volumeDoneFact.show) {
                state.templateCpgState.volumeDone.show = true
            }
            if (!state.templateCpgState.humanHoursProgressFact.show && !state.templateCpgState.humanHoursProgressPlan.show && !state.templateCpgState.humanHoursProgressPercent.show) {
                state.templateCpgState.humanHours.show = false
                state.templateCpgState.humanHours.fix = false
            }
            if (state.templateCpgState.humanHoursProgressFact.show || state.templateCpgState.humanHoursProgressPlan.show || state.templateCpgState.humanHoursProgressPercent.show) {
                state.templateCpgState.humanHours.show = true
            }

            if (!state.templateCpgState.costProgressFact.show && !state.templateCpgState.costProgressPlan.show && !state.templateCpgState.costProgressPercent.show) {
                state.templateCpgState.costProgress.show = false
                state.templateCpgState.costProgress.fix = false
            }
            if (state.templateCpgState.costProgressFact.show || state.templateCpgState.costProgressPlan.show || state.templateCpgState.costProgressPercent.show) {
                state.templateCpgState.costProgress.show = true
            }

            if (!state.templateCpgState.startDate.show && !state.templateCpgState.endDate.show) {
                state.templateCpgState.basePlan.show = false
                state.templateCpgState.basePlan.fix = false
            }
            if (state.templateCpgState.startDate.show || state.templateCpgState.endDate.show) {
                state.templateCpgState.basePlan.show = true
            }

            if (!state.templateCpgState.operationalStartDate.show && !state.templateCpgState.operationalEndDate.show) {
                state.templateCpgState.operPlan.show = false
                state.templateCpgState.operPlan.fix = false
            }
            if (state.templateCpgState.operationalStartDate.show || state.templateCpgState.operationalEndDate.show) {
                state.templateCpgState.operPlan.show = true
            }
        },

        checkTemplateCpgItemView: {
            reducer: (
                state,
                action: PayloadAction<{
                    term: keyof Omit<initCpgTemplateStateType, 'name'>
                    checked: boolean
                    type: fixShow
                }>
            ) => {
                const { term, checked, type } = action.payload
                const templateItem = state.templateCpgStateView[term as keyof Omit<initCpgTemplateStateType, 'name'>];
                if (type === 'show') {
                    templateItem.show = checked
                    if (term === 'volumeDone') {
                        state.templateCpgStateView.volumeDonePlan.show = checked;
                        state.templateCpgStateView.volumeDoneFact.show = checked;
                    }
                    if (term === 'humanHours') {
                        state.templateCpgStateView.humanHoursProgressPercent.show = checked;
                        state.templateCpgStateView.humanHoursProgressPlan.show = checked;
                        state.templateCpgStateView.humanHoursProgressFact.show = checked;
                    }
                    if (term === 'costProgress') {
                        state.templateCpgStateView.costProgressPercent.show = checked;
                        state.templateCpgStateView.costProgressPlan.show = checked;
                        state.templateCpgStateView.costProgressFact.show = checked;
                    }
                    if (term === 'basePlan') {
                        state.templateCpgStateView.startDate.show = checked;
                        state.templateCpgStateView.endDate.show = checked;
                    }
                    if (term === 'operPlan') {
                        state.templateCpgStateView.operationalStartDate.show = checked;
                        state.templateCpgStateView.operationalEndDate.show = checked;
                    }
                    if (!checked) {
                        templateItem.fix = false
                        if (term === 'volumeDone') {
                            state.templateCpgStateView.volumeDoneFact.show = checked
                            state.templateCpgStateView.volumeDonePlan.show = checked
                        }
                        if (term === 'humanHours') {
                            state.templateCpgStateView.humanHoursProgressPercent.show = checked;
                            state.templateCpgStateView.humanHoursProgressPlan.show = checked;
                            state.templateCpgStateView.humanHoursProgressFact.show = checked;
                        }
                        if (term === 'costProgress') {
                            state.templateCpgStateView.costProgressPercent.show = checked;
                            state.templateCpgStateView.costProgressPlan.show = checked;
                            state.templateCpgStateView.costProgressFact.show = checked;
                        }
                        if (term === 'basePlan') {
                            state.templateCpgStateView.startDate.show = checked;
                            state.templateCpgStateView.endDate.show = checked;
                        }
                        if (term === 'operPlan') {
                            state.templateCpgStateView.operationalStartDate.show = checked;
                            state.templateCpgStateView.operationalEndDate.show = checked;
                        }
                    }
                } else {
                    if (
                        templateItem.show === true
                    ) {
                        templateItem.fix = checked
                        if (term === 'volumeDone') {
                            state.templateCpgStateView.volumeDoneFact.fix = checked;
                            state.templateCpgStateView.volumeDonePlan.fix = checked;
                        }
                        if (term === 'humanHours') {
                            state.templateCpgStateView.humanHoursProgressFact.fix = checked;
                            state.templateCpgStateView.humanHoursProgressPercent.fix = checked;
                            state.templateCpgStateView.humanHoursProgressPlan.fix = checked;
                        }
                        if (term === 'costProgress') {
                            state.templateCpgStateView.costProgressFact.fix = checked;
                            state.templateCpgStateView.costProgressPercent.fix = checked;
                            state.templateCpgStateView.costProgressPlan.fix = checked;
                        }
                        if (term === 'basePlan') {
                            state.templateCpgStateView.startDate.fix = checked;
                            state.templateCpgStateView.endDate.fix = checked;
                        }
                        if (term === 'operPlan') {
                            state.templateCpgStateView.operationalStartDate.fix = checked;
                            state.templateCpgStateView.operationalEndDate.fix = checked;
                        }
                    }
                }

                if (!state.templateCpgStateView.volumeDonePlan.show && !state.templateCpgStateView.volumeDoneFact.show) {
                    state.templateCpgStateView.volumeDone.show = false
                    state.templateCpgStateView.volumeDone.fix = false
                }
                if (state.templateCpgStateView.volumeDonePlan.show || state.templateCpgStateView.volumeDoneFact.show) {
                    state.templateCpgStateView.volumeDone.show = true
                }

                if (!state.templateCpgStateView.humanHoursProgressFact.show && !state.templateCpgStateView.humanHoursProgressPlan.show && !state.templateCpgStateView.humanHoursProgressPercent.show) {
                    state.templateCpgStateView.humanHours.show = false
                    state.templateCpgStateView.humanHours.fix = false
                }
                if (state.templateCpgStateView.humanHoursProgressFact.show || state.templateCpgStateView.humanHoursProgressPlan.show || state.templateCpgStateView.humanHoursProgressPercent.show) {
                    state.templateCpgStateView.humanHours.show = true
                }

                if (!state.templateCpgStateView.costProgressFact.show && !state.templateCpgStateView.costProgressPlan.show && !state.templateCpgStateView.costProgressPercent.show) {
                    state.templateCpgStateView.costProgress.show = false
                    state.templateCpgStateView.costProgress.fix = false
                }
                if (state.templateCpgStateView.costProgressFact.show || state.templateCpgStateView.costProgressPlan.show || state.templateCpgStateView.costProgressPercent.show) {
                    state.templateCpgStateView.costProgress.show = true
                }
                if (!state.templateCpgStateView.startDate.show && !state.templateCpgStateView.endDate.show) {
                    state.templateCpgStateView.basePlan.show = false
                    state.templateCpgStateView.basePlan.fix = false
                }
                if (state.templateCpgStateView.startDate.show || state.templateCpgStateView.endDate.show) {
                    state.templateCpgStateView.basePlan.show = true
                }

                if (!state.templateCpgStateView.operationalStartDate.show && !state.templateCpgStateView.operationalEndDate.show) {
                    state.templateCpgStateView.operPlan.show = false
                    state.templateCpgStateView.operPlan.fix = false
                }
                if (state.templateCpgStateView.operationalStartDate.show || state.templateCpgStateView.operationalEndDate.show) {
                    state.templateCpgStateView.operPlan.show = true
                }
            },
            prepare: (term: keyof Omit<initCpgTemplateStateType, 'name'>, checked: boolean, type: fixShow) => {
                return { payload: { term, checked, type } }
            },
        },

        applyTemplateKsg: (state) => {
            state.templateCpgState = state.templateCpgStateView
        },
        applyTemplateMsg: (state) => {
            state.templateCpgState = state.templateCpgStateView
        },

        refreshTemplateCpg(state) {
            state.templateCpgState = initCpgTemplateState
        },

        refreshTemplateCpgView(state) {
            state.templateCpgStateView = initCpgTemplateState
        },
    },
})

export const {
    setTemplateCpgState,
    checkTemplateCpgItem,
    refreshTemplateCpg,
    setTemplateCpgStateView,
    checkTemplateCpgItemView,
    applyTemplateKsg,
    applyTemplateMsg,
    refreshTemplateCpgView,
} = slice.actions
export default slice.reducer

export const ksgTemplatesSelector = (state: RootState) => state.ksgTemplates
