import { Tab } from '@mui/material';
import { FC } from 'react';

import {
    estimatePositionsActions,
    estimatePositionsSelectors,
    TEstimatePositionsListType,
} from '@/store/slices/estimatePositions';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { data } from './EstimatePositionsSwitcher.config';
import { Container, Tabs } from './EstimatePositionsSwitcher.styles';

interface IProps {}

export const EstimatePositionsSwitcher: FC<IProps> = () => {
    const listType = useTypedSelector(estimatePositionsSelectors.listType);

    const dispatch = useAppDispatch();

    const handleUpdate = (type: TEstimatePositionsListType) => {
        dispatch(estimatePositionsActions.setListType(type));
    };

    return (
        <Container>
            <Tabs
                value={listType}
                onChange={(_, value) => handleUpdate(value)}
            >
                {data.map(({ label, value }) => {
                    return (
                        <Tab
                            key={value}
                            label={label}
                            value={value}
                        />
                    );
                })}
            </Tabs>
        </Container>
    );
};
