import { AgGridReact } from 'ag-grid-react';
import styled, { CSSProp } from 'styled-components';

export const Container = styled.div<{ customStyles?: CSSProp }>`
    height: 100%;

    --ag-border-color: transparent !important;
    --ag-header-cell-hover-background-color: #f6f7fb !important;
    --ag-cell-horizontal-border: solid #edeff3;
    --ag-header-column-separator-display: block;
    --ag-header-column-separator-color: red !important;
    --ag-header-column-separator-width: 1px;
    --ag-row-border-color: #edeff3;
    --ag-odd-row-background-color: #fff;

    .ag-root-wrapper {
        border-radius: 6px 6px 0 0;
        border: none;
    }

    .ag-header-cell-label {
        justify-content: center;
    }

    .ag-row {
        font-weight: 400;
        text-align: center;
        text-align: left;
        font-size: 14px;
        line-height: 140%;
        color: #2b3648;
    }

    .ag-header-container {
        white-space: normal;
        text-align: center;
    }

    .ag-header-cell-resize {
        background-color: transparent;
        &::after {
            background-color: transparent;
            display: none;
        }
    }

    .ag-cell-label-container {
        padding: 0;
    }

    .ag-header-cell {
        background-color: #f6f7fb;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        color: #5c6e8c;
        border-right: 1px solid #edeff3;
        padding: 12px;
    }

    .ag-cell {
        padding: 6px 12px;
        text-align: left;
        display: block !important;
    }

    .ag-cell-value {
        text-overflow: initial;
    }

    .ag-cell-center {
        text-align: center;
    }

    .ag-cell-right {
        text-align: right;
    }

    .ag-header-row {
        background-color: #f6f7fb;
    }

    .ag-header-cell-center {
        padding: 0;
        text-align: center;
    }

    .ag-cell-focus {
        border: none !important;
        border-right: 1px solid #edeff3 !important;
    }

    .ag-header-cell::after,
    .ag-header-group-cell::after {
        display: none;
    }

    .deleted-row {
        background-color: rgba(43, 54, 72, 0.12);
    }

    .changed-row {
        background-color: rgba(255, 246, 0, 0.15);
    }

    .changed-cell {
        background-color: rgba(255, 246, 0, 0.15);
    }

    ${(props) => props.customStyles}
`;

export const AgGrid = styled(AgGridReact)``;
