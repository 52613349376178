import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { theme } from '../../../../styles/theme';
import { ChooseVerFactPerms } from '../../ChooseVerFactPerms/ChooseVerFactPerms';
import { UserFormData } from '../UserForm/UserForm.types';
import { AccessCardProps } from './AccessCard.types';
import { AccessCardItem, AccessCardWrapper } from './styles';

function AccessCard({ project, onAccessItemDelete }: AccessCardProps) {
    const { name } = project;
    const { values: formValues } = useFormikContext<UserFormData>();
    const role = formValues.role;

    return (
        <AccessCardWrapper
            style={{ width: '100%' }}
            spacing={1}
        >
            <Box style={{ alignSelf: 'flex-end' }}>
                <IconButton onClick={() => onAccessItemDelete()}>
                    <DeleteIcon
                        color='error'
                        style={{ fontSize: 20 }}
                    />
                </IconButton>
            </Box>

            <Stack
                spacing={2}
                pr={1.25}
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={2}
                >
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing={1}
                    >
                        <DescriptionOutlinedIcon style={{ fontSize: 20, color: theme.palette.secondary.dark }} />
                        <Typography
                            variant='subtitle1'
                            color={theme.palette.secondary.dark}
                            fontWeight={500}
                            component='span'
                        >
                            Проект:
                        </Typography>
                    </Stack>
                    <AccessCardItem>
                        <Typography
                            variant='subtitle2'
                            component='span'
                        >
                            {name}
                        </Typography>
                    </AccessCardItem>
                </Stack>

                {role !== 'controller' && (
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}
                    >
                        <Stack
                            direction='row'
                            alignItems='center'
                            spacing={1}
                            style={{ alignSelf: 'flex-start', height: 40 }}
                        >
                            <LockOpenIcon style={{ fontSize: 20, color: theme.palette.secondary.dark }} />
                            <Typography
                                variant='subtitle1'
                                color={theme.palette.secondary.dark}
                                fontWeight={500}
                                component='span'
                            >
                                Доступ:
                            </Typography>
                        </Stack>

                        <AccessCardItem>
                            <Typography
                                variant='subtitle2'
                                component='span'
                                style={{ color: theme.palette.primary.main }}
                            >
                                Доступ ко всем группам работ
                            </Typography>
                        </AccessCardItem>
                    </Stack>
                )}
                {role === 'controller' && <ChooseVerFactPerms project={project} />}
            </Stack>
        </AccessCardWrapper>
    );
}

export default AccessCard;
