import Big from 'big.js';
import { array, mixed, number, object, string } from 'yup';

import { TEstimatePositionUnitTypeValue } from "@/api/estimatePositions/estimatePositions.types";

import { unitTypeEnToRu } from './components/Form/Form.config';

const transformToBigNumber = (value: any, originalValue: any): number => {
    if (typeof originalValue === 'string') {
        try {
            const cleanedValue = originalValue.replace(/\s+/g, '').replace(',', '.');
            return new Big(cleanedValue).toNumber();
        } catch (e) {
            return NaN;
        }
    }
    return value;
};

const positionIdsValidationSchema = object({
    main: number().required(),
    primary: array(number()).required(),
    secondary: array(number()).required(),
});

export const consolidatedPriceValidationSchema = object({
    chapter: string().nullable(),
    code: string().nullable(),
    consolidatedName: string().required(),
    costTotal: number().required().transform(transformToBigNumber),
    header: string().nullable(),
    humanHourCost: number().required().transform(transformToBigNumber),
    lsrCode: string().nullable(),
    lsrNumber: string().nullable(),
    machineHourCost: number().required().transform(transformToBigNumber),
    positionIDs: positionIdsValidationSchema.required(),
    quantity: number().required().transform(transformToBigNumber),
    rawUnit: mixed<TEstimatePositionUnitTypeValue>().oneOf(Object.values(unitTypeEnToRu)).required(),
});
