import { UIFolder } from '@/components/ui/UIFolder';

import { TCellRendererProps } from '../TableCells.types';
import { Container, Dot, Dots, Left, Text, Title, UIFolderContainerCss } from './OrdinalCellRenderer.styles';
import {
    calculateDotsCountForFolder,
    canFolderContentVisible,
    getFolderContentMessage,
} from './OrdinalCellRenderer.utils';

export const FolderOrdinalCellRenderer: React.FC<TCellRendererProps> = (props) => {
    const dotsCount = calculateDotsCountForFolder(props.data!);
    const folderContent = props.data?.folderContent!;

    return (
        <Container>
            <Left>
                <Dots>
                    {Array(dotsCount)
                        .fill(null)
                        .map((_, index) => (
                            <Dot key={index} />
                        ))}
                </Dots>
                <UIFolder
                    level={props.data?.level!}
                    hasChildren
                    containerStyles={UIFolderContainerCss}
                />
            </Left>
            <Title>{props.data?.name}</Title>
            {canFolderContentVisible(folderContent) && (
                <Text>
                    Сгруппировано: {getFolderContentMessage(folderContent?.folders, folderContent?.consolidated)}
                </Text>
            )}
        </Container>
    );
};
