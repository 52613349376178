const CREATE_ROW_BUTTON_SIZE = 28;

export const calculateCreateButtonPosition = (left: number, width: number, top: number) => {
    const x = left + width / 2;
    const y = top - CREATE_ROW_BUTTON_SIZE / 2;
    return { x, y };
};

export const getCreateButtonPosition = (element: Element) => {
    const rect = element.getBoundingClientRect();
    return calculateCreateButtonPosition(rect.left, rect.width, rect.top);
};
