import { getSnzListRes, snzListPeqItem } from "../../api/snz/snz.types"

export const inputSnzStyles = {
    padding: '6px',
    fontSize: '14px',
    color: '#2b3648',
    letterSpacing: '0.17px',
    border: '1px solid rgba(92, 110, 140, 0.1)',
    backgroundColor: '#F6F7FB',
}

export interface PeopleMachinesItemProps {
    person?: boolean
    data?: snzListPeqItem[]
}

export type tabSnzTerms = 'works' | 'people' | 'equip'

export interface WorksContainerProps {
    tabTerm: tabSnzTerms
    works?: getSnzListRes
    contractor: string
}
