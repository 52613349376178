import { LoadingButton } from '@mui/lab';
import { IconButton as MuiIconButton, Stack } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 1.25,
    flex: 1,
})``;

export const AutoGenerateLevelsButton = styled(LoadingButton).attrs({
    size: 'small',
    variant: 'outlined',
})`
    border-color: #8cbbe8;
    border-radius: 6px;
    height: 30px;
    padding: 2px 10px;
    line-height: 1;
`;

export const CreateKsgButton = styled(MuiIconButton)`
    background-color: #fff;
    border-radius: 6px;
    padding: 6px;
    width: 30px;
    height: 30px;
    color: #0044b4;
    transition: color 0.3s, background-color 0.3s;

    &.Mui-disabled {
        background-color: rgba(43, 54, 72, 0.12);
        color: #9aa2b0;
    }
`;
