import { api } from '@/api/api';

import { createEisReqI, EisReportI, getEisMonthsResI, repeatEisReqI, setStatusEisReqI } from './eis.types';

export const eisApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEisReports: build.query<{ data: EisReportI[] }, { id: number; year: number; month: number }>({
            query: ({ id, year, month }) => ({
                url: `/projects/${id}/reports/eis/${year}/${month}/list`,
                method: 'GET',
            }),
            providesTags: ['Eis'],
        }),

        getEisMonths: build.query<getEisMonthsResI, { id: number }>({
            query: ({ id }) => ({
                url: `/projects/${id}/reports/eis/menu`,
                method: 'GET',
            }),
            providesTags: ['Eis'],
        }),

        downloadReportEis: build.mutation<string, { id: number; reportId: number }>({
            query: ({ id, reportId }) => ({
                url: `/projects/${id}/reports/eis/${reportId}/download`,
                method: 'POST',
                headers: {
                    'content-type': 'text/plain',
                },
                responseHandler: 'text',
            }),
        }),

        setStatusEis: build.mutation<unknown, setStatusEisReqI>({
            query: ({ id, reportId, body }) => ({
                url: `/projects/${id}/reports/eis/${reportId}/status`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Eis'],
        }),

        createReport: build.mutation<unknown, createEisReqI>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/reports/eis/create`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Eis'],
        }),

        repeatEis: build.mutation<unknown, repeatEisReqI>({
            query: ({ id, reportId }) => ({
                url: `/projects/${id}/reports/eis/${reportId}/repeat`,
                method: 'POST',
            }),
            invalidatesTags: ['Eis'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetEisReportsQuery,
    useDownloadReportEisMutation,
    useSetStatusEisMutation,
    useRepeatEisMutation,
    useGetEisMonthsQuery,
    useCreateReportMutation,
} = eisApi;
