import { ICellRendererParams } from 'ag-grid-community';
import { useParams } from 'react-router-dom';

import { DOES_ROLE_HAS_ACCESS_TO_PROJECT_TABLE, PERMISSIONS } from '@/shared/rolePermissions';

import { profileSelector } from '@/store/slices/profileSlice';
import { getSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { useTypedSelector } from '@/store/store';

import { EditChartCell } from '../../../../components/EditChartCell/EditChartCell';
import { submitKSGResourceMonthlyChart } from './MonthRendererKsgAg.service';

export default function MonthRendererKsgAg({
    cellRendererParams,
    chartIndex,
    resourceType,
    headers,
}: {
    cellRendererParams: ICellRendererParams;
    chartIndex: number;
    resourceType: 'staff' | 'mim';
    headers: any;
}) {
    const { profile } = useTypedSelector(profileSelector);

    const { projectId } = useParams();

    const { verificationPlan } = useTypedSelector(getSettings);

    const isCreatedMSG = !!headers?.allHeaders[chartIndex]?.isCreatedMSG;
    const year = cellRendererParams.data?.monthlyCharts[chartIndex]?.year || 0;
    const month = cellRendererParams.data?.monthlyCharts[chartIndex]?.month || 0;
    const isPreviousMonth = (month || 0) < new Date().getMonth() + 1;
    const isPreviousYear = (year || 0) < new Date().getFullYear();

    return (
        <div className='month_container'>
            <EditChartCell
                cellRendererParams={cellRendererParams}
                chartValueKey={'plan'}
                chartIndex={chartIndex}
                isEditable={
                    !cellRendererParams.data?.isResource &&
                    PERMISSIONS.ksg[resourceType]?.plan?.roles?.includes(profile?.role)
                }
                defaultValue={cellRendererParams.data?.monthlyCharts[chartIndex]?.plan}
                onSubmitData={(args) => submitKSGResourceMonthlyChart({ ...args, resourceType: resourceType })}
            />
            <EditChartCell
                cellRendererParams={cellRendererParams}
                chartValueKey={'fact'}
                chartIndex={chartIndex}
                isEditable={
                    !cellRendererParams.data?.isResource &&
                    PERMISSIONS.ksg[resourceType]?.fact?.roles?.includes(profile?.role)
                }
                defaultValue={cellRendererParams.data?.monthlyCharts[chartIndex]?.fact}
                onSubmitData={(args) => submitKSGResourceMonthlyChart({ ...args, resourceType: resourceType })}
            />
            {verificationPlan && (
                <EditChartCell
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'verifiedFact'}
                    chartIndex={chartIndex}
                    defaultValue={
                        cellRendererParams.api.getValue('monthlyCharts', cellRendererParams.node)[chartIndex]
                            .verifiedFact
                    }
                    isEditable={
                        !isCreatedMSG &&
                        (isPreviousYear || isPreviousMonth) &&
                        !cellRendererParams.data?.isResource &&
                        DOES_ROLE_HAS_ACCESS_TO_PROJECT_TABLE(projectId as string, profile, resourceType) &&
                        PERMISSIONS.ksg[resourceType]?.verifiedFact?.roles?.includes(profile?.role)
                    }
                    onSubmitData={(args) => submitKSGResourceMonthlyChart({ ...args, resourceType: resourceType })}
                />
            )}
        </div>
    );
}
