import { FC, useEffect, useRef } from 'react';

import { DragIndicatorIcon } from '@/shared/SVG/DragIndicatorIcon';

import { DragCellRenderButton } from './TableCells.styles';
import { TCellRendererProps } from './TableCells.types';

export const DragCellRenderer: FC<TCellRendererProps> = (props) => {
    const buttonRef = useRef(null);

    useEffect(() => {
        props.registerRowDragger(buttonRef.current!);
    });

    return (
        <DragCellRenderButton ref={buttonRef}>
            <DragIndicatorIcon />
        </DragCellRenderButton>
    );
};
