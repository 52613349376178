import Big from 'big.js';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { match } from 'ts-pattern';

import { TEstimatePosition, TEstimatePositionId, TEstimatePositionUnitType } from '@/api/estimatePositions';

import { getFormatNumber } from '@/shared/utils/formatNumber.utils';

import { TEstimateCheckedPositionMapValue } from '@/store/slices/estimatePositions';

import { Nullable } from '@/types/common.types';

import { TEditedFields, TFormValues, TGetEditedFormValuesResult } from './ConsolidatedPriceDialog.types';
import { TTableData } from './components/Table';

export const getPositionsByUnitDifference = (
    mainPositions: TEstimateCheckedPositionMapValue[],
    unit: Nullable<TEstimatePositionUnitType>
) => {
    if (!unit) return [];
    return mainPositions.filter((pos) => pos.unit?.type !== unit);
};

export const filterPositionByUnit =
    (unit: TEstimatePositionUnitType, shouldMatchUnit = true) =>
    (position: TEstimateCheckedPositionMapValue) => {
        if (!position.unit?.type) return !shouldMatchUnit;

        const isEqual = position.unit.type === unit;

        // @ts-ignore
        const isWeightUnit = match([unit, position.unit.type])
            .with(['tonne', 'kg'], () => true)
            .with(['kg', 'tonne'], () => true)
            .otherwise(() => false);

        return shouldMatchUnit ? isEqual || isWeightUnit : !isEqual && !isWeightUnit;
    };

export const getRowData = (
    mainPositions: TEstimateCheckedPositionMapValue[],
    extraPositions: TEstimateCheckedPositionMapValue[],
    positionsByUnitDifference: TEstimateCheckedPositionMapValue[]
): TTableData[] => {
    const data = [
        {
            isFull: true,
            id: 'main',
            title: 'Основные позиции',
            positionsCount: mainPositions.length,
            ordinals: positionsByUnitDifference.map((v) => v.ordinal),
        },
        ...mainPositions,
        {
            isFull: true,
            id: 'extra',
            title: 'Вспомогательные позиции',
            positionsCount: extraPositions.length,
            ordinals: [],
        },
        ...extraPositions,
    ];

    return data;
};

const joinText = (array: string[]) => {
    return Array.from(new Set(array)).join('; ');
};

const sum = (array: number[]) => {
    return array
        .reduce((acc, num) => {
            return acc.plus(Big(num));
        }, Big(0))
        .toNumber();
};

export const getFormValues = (
    mainPositions: TEstimateCheckedPositionMapValue[],
    extraPositions: TEstimateCheckedPositionMapValue[]
): Omit<TFormValues, TEditedFields> => {
    const chapter = [];
    const lsrNumber = [];
    const lsrCode = [];
    const header = [];
    const code = [];
    const humanHourCost = [];
    const machineHourCost = [];
    const costTotal = [];

    for (const position of mainPositions) {
        isString(position.chapter) && chapter.push(position.chapter);
        isString(position.lsrNumber) && lsrNumber.push(position.lsrNumber);
        isString(position.lsrCode) && lsrCode.push(position.lsrCode);
        isString(position.header) && header.push(position.header);
        isString(position.code) && code.push(position.code);

        isNumber(position.humanHourCost) && humanHourCost.push(position.humanHourCost);
        isNumber(position.machineHourCost) && machineHourCost.push(position.machineHourCost);
        isNumber(position.costTotal) && costTotal.push(position.costTotal);
    }

    for (const position of extraPositions) {
        isNumber(position.humanHourCost) && humanHourCost.push(position.humanHourCost);
        isNumber(position.machineHourCost) && machineHourCost.push(position.machineHourCost);
        isNumber(position.costTotal) && costTotal.push(position.costTotal);
    }

    return {
        chapter: joinText(chapter),
        lsrNumber: joinText(lsrNumber),
        lsrCode: joinText(lsrCode),
        header: joinText(header),
        code: joinText(code),
        humanHourCost: getFormatNumber({ value: sum(humanHourCost) }) as string,
        machineHourCost: getFormatNumber({ value: sum(machineHourCost) }) as string,
        costTotal: getFormatNumber({ value: sum(costTotal) }) as string,
    };
};

export const calculateTotalQuantityForUnitType = (positions: TEstimatePosition[]): number => {
    return positions
        .reduce((acc, position) => {
            if (!position.naturalQuantity) return acc;

            return acc.plus(Big(position.naturalQuantity));
        }, new Big(0))
        .toNumber();
};

export const getDefaultFormValues = (): TFormValues => {
    return {
        chapter: '',
        consolidatedName: '',
        lsrNumber: '',
        lsrCode: '',
        header: '',
        code: '',
        rawUnit: null,
        quantity: '0,00',
        humanHourCost: '0,00',
        machineHourCost: '0,00',
        costTotal: '0,00',
    };
};

const createDefaultResult = (editedFields: Set<TEditedFields>) => {
    const result: TGetEditedFormValuesResult = {};

    if (!editedFields.has('consolidatedName')) {
        result.consolidatedName = '';
    }

    if (!editedFields.has('rawUnit')) {
        result.rawUnit = '';
    }

    if (!editedFields.has('quantity')) {
        result.quantity = '0,00';
    }

    return result;
};

export const getEditedFormValues = (
    mainPositionId: Nullable<TEstimatePositionId>,
    mainPositions: TEstimateCheckedPositionMapValue[],
    editedFields: Set<TEditedFields>
) => {
    const position = mainPositions.find((v) => v.id === mainPositionId);
    if (!position) return createDefaultResult(editedFields);

    const result: TGetEditedFormValuesResult = {};

    if (!editedFields.has('consolidatedName')) {
        result.consolidatedName = position?.positionName ?? '';
    }

    const unitType = position?.unit?.type;

    if (!editedFields.has('rawUnit')) {
        result.rawUnit = unitType || '';
    }

    if (!editedFields.has('quantity') && !editedFields.has('rawUnit')) {
        if (unitType) {
            const filteredPositions = mainPositions.filter((pos) => pos.unit?.type === unitType);
            const quantity = getFormatNumber({
                value: calculateTotalQuantityForUnitType(filteredPositions),
            }) as string;

            result.quantity = quantity;
        } else {
            result.quantity = getFormatNumber({ value: position?.quantity }) || '0,00';
        }
    }

    return result;
};
