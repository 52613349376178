import { RowClassRules } from 'ag-grid-community';
import { useMemo } from 'react';

import { WorkOrWorkPosition } from "@/api/ksg/ksg.types";

// TODO:
// 1. Возможно не нужен use, только обычная функция
// 2. Сделать универсальной
// 3. Применить в мсг
const getRowClassRules = <T>(): RowClassRules<T> => ({
    level_zero: 'data.level === 0',
    level_one: 'data.level === 1',
    level_two: 'data.level === 2',
    level_three: 'data.level === 3',
    level_four: 'data.level >= 4 && data.level < 16',
    level_sixteen: 'data.level === 16 || !data.hasChildren',
});

export const useGetRowClassRules = () => {
    return useMemo<RowClassRules<WorkOrWorkPosition>>(() => getRowClassRules<WorkOrWorkPosition>(), []);
};
