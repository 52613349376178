import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EPageName } from '@/shared/constants/pages';

import { RootState } from '../store';

interface initialStateI {
    headerSearch: string;
    projectsTitles: string[];
    pageName: EPageName | null;
}

const initialState: initialStateI = {
    headerSearch: '',
    projectsTitles: [],
    pageName: null,
};

const slice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setHeaderSearch: (state, action) => {
            state.headerSearch = action.payload;
        },

        setPageName: (state, action: PayloadAction<EPageName>) => {
            state.pageName = action.payload;
        },
    },
});

export const { setHeaderSearch, setPageName } = slice.actions;
export default slice.reducer;

export const projectsSelector = (state: RootState) => state.project;
export const headerSearchSelector = (state: RootState) => state.project.headerSearch;
