import { AgGridReact } from 'ag-grid-react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { integrationStatusLoadingSelector, integrationStatusSelector } from '@/store/slices/integrationStatusSlice';
import { useTypedSelector } from '@/store/store';

import '../AgGrid/AgGrid.scss';
import { FlexColumnWrapper, FlexRowWrapper } from '../NewExecutorView/components/components.styles';
import { AG_GRID_DEFAULT_PARAMS_KSG } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { useAgGridUtils, useColumnDefsGet, useDataGet, useRowDataGet } from './AgGridKsgKC6A.model';
import { TAgGridKsgKC6AProps } from './AgGridKsgKC6A.types';

export const AgGridKsgKC6A: React.FC<TAgGridKsgKC6AProps> = () => {
    const { projectId } = useParams();
    const integrationStatus = useTypedSelector(integrationStatusSelector);
    const isIntegrationStatusLoading = useTypedSelector(integrationStatusLoadingSelector);
    const location = useLocation();

    const { getRowClass, setGridReady, gridRef, toggleGridOverlay, getRowId } = useAgGridUtils();

    const { columnDefs, isHeadersLoading, hiddenRows } = useColumnDefsGet();
    const { isWorksLoading } = useDataGet(toggleGridOverlay);
    const { rowData } = useRowDataGet(hiddenRows);

    const isPageLoading = isHeadersLoading || isWorksLoading || isIntegrationStatusLoading;

    if (isPageLoading) return null;
    if (!integrationStatus?.closedActsPresent) {
        return (
            <Navigate
                to={`/ksg/${projectId}`}
                replace
            />
        );
    }

    return (
        <FlexColumnWrapper>
            <FlexRowWrapper
                className='table-wrapper'
                height={'100%'}
                width={'100%'}
                gap={0}
                pb={2}
            >
                <div
                    className='ag-theme-alpine'
                    style={{ flex: '1 1 0', height: '100%', paddingRight: '0.5rem' }}
                >
                    <AgGridReact
                        ref={gridRef}
                        getRowId={getRowId}
                        getRowClass={getRowClass}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                        rowHeight={50}
                        headerHeight={30}
                        groupHeaderHeight={30}
                        onGridReady={() => {
                            setGridReady();
                        }}
                    />
                </div>
            </FlexRowWrapper>
        </FlexColumnWrapper>
    );
};
