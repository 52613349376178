import { IRowDragItem, IRowNode, RowDragEvent } from 'ag-grid-community';
import { useCallback, useMemo, useState } from 'react';
import { useSpinDelay } from 'spin-delay';

import { TStructureRowType } from '@/api/structure';

import { useLatest } from '@/hooks/useLatest';

import { getRowElementByIndex } from '@/shared/utils/agGrid.utils';

import { Nullable } from '@/types/common.types';

import { TContext, TData } from '../StructureManagementTable.types';
import { DropZone } from '../components/DropZone';
import { getFolderParentIdByPathArray } from '../utils';
import { useMoveConsolidatePriceMutation, useMoveFolderMutation } from './useQueries';

type TDragEndStateContext = {
    event: RowDragEvent<TData, TContext>;
    isDrop: boolean;
    nodeEntityID: Nullable<number>;
    overNodeEntityID: Nullable<number>;
    nodeRowType: Nullable<string>;
    overNodeRowType: Nullable<string>;
    isNodeSelected: boolean;
    selectedRowIds: number[];
};

export const useDrag = (projectId: number, consolidatedSelectSet: Set<string>) => {
    const [overNodeId, setOverNodeId] = useState<Nullable<string>>(null);
    const currentOverNodeId = useLatest(overNodeId);
    const [dropZoneNode, setDropZoneNode] = useState<Nullable<IRowNode<TData>>>(null);

    const [isDrop, setIsDrop] = useState(false);
    const isDropZoneVisible = useSpinDelay(Boolean(dropZoneNode), { delay: 400 });
    const latestConsolidatedSelectSet = useLatest(consolidatedSelectSet);

    const { mutate: mutateFolderMove } = useMoveFolderMutation(projectId!);
    const { mutate: mutateConsolidatedPriceMove } = useMoveConsolidatePriceMutation(projectId!);

    const latestIsDrop = useLatest(isDrop);

    const handleRowDragEnter = (event: RowDragEvent<TData, TContext>) => {
        console.log('onRowDragEnter', event);
    };

    const handleRowDragMove = (event: RowDragEvent<TData, TContext>) => {
        const node = event.node;
        const overNode = event.overNode;

        if (!overNode) return;
        if (node === overNode && getFolderParentIdByPathArray(overNode.data?.pathArray!, event.node.id!)) {
            setDropZoneNode(event.node);
            return;
        }
        setDropZoneNode(null);

        if (currentOverNodeId.current === overNode.id) return;
        if (overNode.data?.rowType !== 'a_folder') return;

        const prevOverNodeId = currentOverNodeId.current;
        setOverNodeId(overNode?.id!);

        const getRowNodes = () => {
            if (prevOverNodeId === null) return [overNode];
            const prevOverRowNode = event.api.getRowNode(prevOverNodeId);
            if (!prevOverRowNode) return [overNode];
            return [overNode, prevOverRowNode];
        };

        setTimeout(() => {
            event.api.refreshCells({
                rowNodes: getRowNodes(),
                force: true,
            });
        });
    };

    const handleRowDragEnd = (event: RowDragEvent<TData, TContext>) => {
        console.log('handleRowDragEnd', event);

        const context: TDragEndStateContext = {
            event: event,
            isDrop: latestIsDrop.current,
            nodeEntityID: (event.node?.data?.entityId as Nullable<number>) ?? null,
            overNodeEntityID: (event.overNode?.data?.entityId as Nullable<number>) ?? null,
            nodeRowType: event.node?.data?.rowType ?? null,
            overNodeRowType: event.overNode?.data?.rowType ?? null,
            isNodeSelected: event.node?.isSelected() ?? false,
            selectedRowIds: Array.from<string>(event.context.consolidatedSelectSet || []).map((row) =>
                Number(row.split('_')[2])
            ),
        };

        const transitions = {
            IDLE: () => {
                if (!transitions.CAN_MUTATE()) {
                    transitions.CLEANUP();
                    return;
                }

                transitions.SELECT_MUTATION();
            },
            CAN_MUTATE: () => {
                const { isDrop, overNodeRowType, event } = context;

                if (!event.node || !event.overNode) return false;
                if (!isDrop && event.node.id === event.overNode!.id) return false;
                if (!isDrop && overNodeRowType !== 'a_folder') return false;
                return true;
            },
            SELECT_MUTATION: () => {
                const { nodeRowType } = context;

                if (nodeRowType === 'a_folder') {
                    transitions.MOVE_FOLDER();
                    return;
                }
                transitions.MOVE_CONSOLIDATED_PRICE();
            },

            MOVE_FOLDER: () => {
                const { nodeEntityID, overNodeEntityID, isDrop } = context;

                mutateFolderMove({
                    projectId: projectId,
                    folderId: nodeEntityID!,
                    body: {
                        newParentId: isDrop ? null : overNodeEntityID!,
                    },
                });
                transitions.CLEANUP();
            },

            MOVE_CONSOLIDATED_PRICE: () => {
                const { nodeEntityID, overNodeEntityID, selectedRowIds, isNodeSelected, isDrop } = context;

                const consolidatedIds = selectedRowIds.length && isNodeSelected ? selectedRowIds : [nodeEntityID!];

                mutateConsolidatedPriceMove({
                    projectId: Number(projectId),
                    body: {
                        consolidatedIds,
                        folderId: isDrop ? null : overNodeEntityID!,
                    },
                });

                if (isNodeSelected && selectedRowIds.length) {
                    transitions.DESELECT_ALL();
                }
                transitions.CLEANUP();
            },

            DESELECT_ALL: () => {
                const { event } = context;

                event.api.deselectAll();
                event.context.resetConsolidatedSelect();
                setTimeout(() => {
                    event.api.refreshCells({
                        columns: ['edit'],
                        force: true,
                    });
                });
            },

            CLEANUP: () => {
                const { event } = context;

                setDropZoneNode(null);
                setOverNodeId(null);
                setIsDrop(false);

                setTimeout(() => {
                    event.api.refreshCells({
                        rowNodes: [event.overNode!],
                        force: true,
                    });
                });
            },
        };

        transitions.IDLE();
    };

    const rowDragText = useCallback((params: IRowDragItem) => {
        const data = params.rowNode?.data as TData;

        if (
            latestConsolidatedSelectSet.current.size > 1 &&
            latestConsolidatedSelectSet.current.has(params.rowNode?.id!)
        ) {
            return `Укрупненная расценка ${data.name ? `«${data.name}»` : ''} и еще +${
                latestConsolidatedSelectSet.current.size - 1
            }`;
        }

        type TDragRowType = Exclude<TStructureRowType, 'c_position'>;
        const rowType = data.rowType as TDragRowType;
        const messageByRowType: Record<TDragRowType, string> = {
            a_folder: 'Уровень',
            b_consolidated: 'Укрупненная расценка',
        };

        return `${messageByRowType[rowType]} ${data.name ? `«${data.name}»` : ''}`;
    }, []);

    const handleDragLeave = (event: RowDragEvent<TData, TContext>) => {
        console.log('onRowDragLeave', event);
        setOverNodeId(null);
        setDropZoneNode(null);

        setTimeout(() => {
            event.api.refreshCells({
                rowNodes: [event.overNode!],
                force: true,
            });
        });
    };

    const handleDrop = () => {
        setIsDrop(true);
    };

    const dropZone = useMemo(() => {
        if (dropZoneNode && isDropZoneVisible) {
            const rowElement = getRowElementByIndex(dropZoneNode.rowIndex!);
            const rect = rowElement!.getBoundingClientRect();

            return (
                <DropZone
                    top={rect.top}
                    left={rect.left}
                    onDrop={handleDrop}
                />
            );
        }

        return null;
    }, [isDropZoneVisible, dropZoneNode]);

    return {
        dropZone: dropZone,
        overNodeId: overNodeId,
        onRowDragEnter: handleRowDragEnter,
        onRowDragMove: handleRowDragMove,
        onRowDragEnd: handleRowDragEnd,
        onRowDragLeave: handleDragLeave,
        rowDragText: rowDragText,
    };
};
