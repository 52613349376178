interface IProps {}

export const OverflowMenuIcon: React.FC<IProps> = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.0007 6.66671C10.9173 6.66671 11.6673 5.91671 11.6673 5.00004C11.6673 4.08337 10.9173 3.33337 10.0007 3.33337C9.08398 3.33337 8.33398 4.08337 8.33398 5.00004C8.33398 5.91671 9.08398 6.66671 10.0007 6.66671ZM10.0007 8.33337C9.08398 8.33337 8.33398 9.08337 8.33398 10C8.33398 10.9167 9.08398 11.6667 10.0007 11.6667C10.9173 11.6667 11.6673 10.9167 11.6673 10C11.6673 9.08337 10.9173 8.33337 10.0007 8.33337ZM8.33398 15C8.33398 14.0834 9.08398 13.3334 10.0007 13.3334C10.9173 13.3334 11.6673 14.0834 11.6673 15C11.6673 15.9167 10.9173 16.6667 10.0007 16.6667C9.08398 16.6667 8.33398 15.9167 8.33398 15Z'
                fill='currentColor'
            />
        </svg>
    );
};
