import { memo, useDeferredValue } from 'react';

import { Project } from '@/api/projects/projects.types';

import EmptyPlaceholder from '@/shared/components/EmptyPlaceholder/EmptyPlaceholder';

import styles from '../Projects.module.scss';
import AdminCard from './AdminCard';
import UserCard from './UserCard';

interface IProps {
    isCharts: boolean;
    projects: Project[];
    placeholder: string;
}

export const ProjectList: React.FC<IProps> = memo(({ projects, isCharts, placeholder }) => {
    const deferredProjects = useDeferredValue(projects);

    if (!deferredProjects.length) return <EmptyPlaceholder text={placeholder} />;

    return (
        <section className={isCharts ? styles.user_cards : styles.admin_cards}>
            {deferredProjects.map((project) => (
                <div key={project.id}>
                    {isCharts ? <UserCard project={project} /> : <AdminCard project={project} />}
                </div>
            ))}
        </section>
    );
});
