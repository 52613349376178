import { CellEditingStoppedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Work } from '@/api/ksg/ksg.types';

import { drawersSelector, setWorkManagmetResourceGridApi } from '@/store/slices/drawersSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { KsgAPI } from '../../api/KsgAPI';
import { ConfirmDialog } from '../../components/ConfirmDialog/ConfirmDialog';
import { ResourceCard } from './ResourceCard/ResourceCard';
import {
    calculateMimTotalPlan,
    calculateMtoTotalPlan,
    calculateStaffTotalPlan,
    getMimAgGridProps,
    getMtoAgGridProps,
    getStaffAgGridProps,
} from './ResourceCard/ResourceCard.service';
import { ResourcesPageWrapper } from './ResourcesPage.styles';

export function ResourcesPage() {
    const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [cellEditingStoppedEvent, setCellEditingStoppedEvent] = useState<CellEditingStoppedEvent | null>(null);
    const { workManagmentResourcesGridRefs } = useTypedSelector(drawersSelector);
    const [workInfo, setWorkInfo] = useState<Work | null>(null);

    const dispatch = useAppDispatch();

    const { projectId, workID } = useParams();

    useEffect(() => {
        dispatch(
            setWorkManagmetResourceGridApi({
                setTriggerRefresh: setTriggerRefresh,
            })
        );
    }, []);

    useEffect(() => {
        KsgAPI.get({
            projectID: projectId,
            workID: workID,
        }).then(({ data, executor }) => {
            setWorkInfo(() => data);
        });
    }, []);

    useEffect(() => {}, [triggerRefresh]);

    return (
        <ResourcesPageWrapper>
            <ResourceCard
                triggerRefresh={triggerRefresh}
                key={'staff'}
                resourceType='staff'
                AgGridProps={getStaffAgGridProps(setIsConfirmDialogOpen, setCellEditingStoppedEvent)}
                calculateTotalPlan={calculateStaffTotalPlan}
                estimateTotal={workInfo?.humanHoursTotal}
            />

            <ResourceCard
                triggerRefresh={triggerRefresh}
                key={'mim'}
                resourceType='mim'
                AgGridProps={getMimAgGridProps(
                    setIsConfirmDialogOpen,
                    setCellEditingStoppedEvent,
                    workManagmentResourcesGridRefs.mim as AgGridReact
                )}
                calculateTotalPlan={calculateMimTotalPlan}
                estimateTotal={workInfo?.machineHoursTotal}
            />

            <ResourceCard
                triggerRefresh={triggerRefresh}
                key={'mto'}
                resourceType='mto'
                AgGridProps={getMtoAgGridProps()}
                calculateTotalPlan={calculateMtoTotalPlan}
            />
            <ConfirmDialog
                open={isConfirmDialogOpen}
                title={'Удалить строку?'}
                message='Вы не указали наименование ресурса, или пытаетесь добавить уже имеющийся ресурс. Строка будет удалена'
                onYes={{
                    action: (e) => {
                        cellEditingStoppedEvent?.api.applyTransaction({ remove: [{ id: 'draft' }] });
                        setIsConfirmDialogOpen(() => false);
                    },
                    btnText: 'Удалить',
                }}
                onNo={{
                    action: (e) => {
                        setIsConfirmDialogOpen(() => false);
                        cellEditingStoppedEvent?.api.setFocusedCell(
                            cellEditingStoppedEvent.rowIndex as number,
                            cellEditingStoppedEvent.context.resourceType === 'staff' ? 'nameProf' : 'name'
                        );
                        cellEditingStoppedEvent?.api.startEditingCell({
                            rowIndex: cellEditingStoppedEvent.rowIndex as number,
                            colKey: cellEditingStoppedEvent.context.resourceType === 'staff' ? 'nameProf' : 'name',
                        });
                    },
                    btnText: 'Отменить',
                }}
            />
        </ResourcesPageWrapper>
    );
}
