import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Skeleton } from '@mui/material';
import { CgDanger } from '@react-icons/all-files/cg/CgDanger';
import { Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';

import { Project } from '@/api/projects/projects.types';

import '../Projects.module.scss';
import s from '../Projects.module.scss';

const UserCardGraph = lazy(() => {
    return Promise.all([import('./UserCardGraph'), new Promise((resolve) => setTimeout(resolve, 800))]).then(
        ([moduleExports]) => ({ default: moduleExports.UserCardGraph })
    );
});

export default function UserCard({ project }: { project: Project }) {
    return (
        <Link to={`/ksg/${project.id}`}>
            <section className={s.user_card}>
                <div className={s.user_top}>
                    <h2>{project.title}</h2>
                    <div>
                        <h1>{(project.done || 0) + '%'}</h1>
                        <span>ВЫПОЛНЕНО</span>
                    </div>
                </div>

                <div className={s.user_middle}>
                    <div className={s.user_line}>
                        <div>
                            <CgDanger
                                fontSize={20}
                                color='#5c6e8c'
                            />
                            <p>Статус:</p>
                        </div>
                        <h4>{project.status}</h4>
                    </div>
                    <div className={s.user_line}>
                        <div>
                            <CalendarMonthOutlinedIcon
                                sx={{
                                    width: '1.25rem',
                                    height: '1.25rem',
                                    color: '#5c6e8c',
                                }}
                            />
                            <p>Срок реализации:</p>
                        </div>
                        <h3>{project.startDate + ' - ' + project.endDate}</h3>
                    </div>
                </div>

                <Suspense
                    fallback={
                        <Skeleton
                            animation='wave'
                            variant='rectangular'
                            sx={{ width: '100%', height: 230 }}
                        />
                    }
                >
                    <UserCardGraph project={project} />
                </Suspense>
            </section>
        </Link>
    );
}
