import { Stack } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateFullUserMutation, useInviteUserMutation, useUpdateUserAccessMutation } from '@/api/users/users.api';
import {
    CompanyUserAccessUpdateData,
    CreateUserRequest,
    CreateUserResponse,
    PublicUserProfile,
    SetAvatarData,
    UserBindCandidate,
    UserProfile,
} from '@/api/users/users.types';

import { profileSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

import { useForm } from '../../../../hooks/useForm';
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers';
import { validationEditProfile, validationProfile } from '../../UserManagement/validation';
import { mapFieldErrorByError } from '../../utils/mapFieldErrorByError';
import { FoundUserDialog } from '../FoundUserDialog';
import UserAccesses from '../UserAccesses/UserAccesses';
import UserData from '../UserData/UserData';
import { UserFormData, UserFormProps } from './UserForm.types';

const UserForm: React.FC<UserFormProps> = ({ userProfileToChange, isEditUser, onFormChange }) => {
    const { t } = useTranslation('user');
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { profile } = useTypedSelector(profileSelector);

    const [candidates, setCandidates] = useState<CreateUserResponse['candidates']>();
    const companyID = profile?.company?.companyID;

    const {
        avatar,
        firstName,
        lastName,
        middleName,
        email,
        role,
        login,
        phoneConfirmed,
        emailConfirmed,
        phone,
        company,
        access,
    } = userProfileToChange as PublicUserProfile;

    const { userPosition, userCompanyName } = company || {};

    const initialValues: UserFormData = useMemo(() => {
        return {
            lastName: lastName || '',
            firstName: firstName || '',
            middleName: middleName || '',
            companyName: userProfileToChange?.company?.userCompanyName || '',
            position: userPosition || '',
            phone: phone || '',
            email: email || '',
            login: login || '',
            // password: DEFAULT_DISPLAY_PASSWORD_VALUE as string | undefined,
            password: '',
            changeDataAll: false,
            avatar: avatar || '',
            role: role || 'none',
            projects: access?.projects || [],
        };
    }, [lastName, firstName, middleName, userCompanyName, userPosition, phone, email, login, avatar, role, access]);

    const [createFullUser, createFullUserResponse] = useCreateFullUserMutation();
    const [updateUserAccess, updateUserAccessResponse] = useUpdateUserAccessMutation();
    const [inviteUser, inviteUserResponse] = useInviteUserMutation();

    const onSubmit = useCallback(
        (values: UserFormData) => {
            const { projects, role } = values;
            const dataForSetAccess: CompanyUserAccessUpdateData = {
                newRole: role,
                access: projects
                    .map((project) => ({
                        projectID: project.id,
                        verFactPerms: project.verFactPerms,
                    }))
                    .filter((project) => !isNaN(project.projectID)),
            };

            if (isEditUser) {
                updateUserAccess({
                    body: dataForSetAccess,
                    userID: userProfileToChange.id!,
                    companyID,
                });
            } else {
                const { login, email, phone, password, companyName, position, firstName, lastName, middleName } =
                    values;

                const dataForCreate: CreateUserRequest = {
                    profile: {
                        email,
                        login,
                        password: password!,
                        phone,
                    },
                    employment: {
                        companyID,
                        companyName,
                        firstName,
                        lastName,
                        middleName,
                        position,
                    },
                };

                const dataForSetAvatar: SetAvatarData = {
                    file: values.avatar as Blob,
                };

                createFullUser({
                    companyID: companyID,
                    dataForCreate,
                    dataForSetAvatar,
                    dataForSetAccess,
                });
            }
        },
        [updateUserAccess, createFullUser]
    );

    const { formik } = useForm({
        validationSchema: isEditUser ? validationEditProfile : validationProfile,
        enableReinitialize: true,
        initialValues,
        onSubmit,
    });

    const { values, setFieldValue, setFieldError, dirty } = formik;
    useEffect(() => {
        onFormChange(dirty);
    }, [dirty]);

    useMutationHandlers(
        createFullUserResponse,
        (data: CreateUserResponse) => {
            if (!!data) {
                const { success: newUser, candidates } = data || {};

                if (!!newUser) {
                    navigate('/users');
                }
                if (!!candidates?.length) {
                    const notInvitedCandidates: UserBindCandidate[] = [];
                    candidates.forEach((candidate) => {
                        const { alreadyInvited, bindFields } = candidate;
                        if (alreadyInvited) {
                            bindFields?.forEach((field: 'email' | 'login' | 'phone') =>
                                setFieldError(field, t('status.coincidence'))
                            );
                        } else {
                            notInvitedCandidates.push(candidate);
                        }
                    });
                    setCandidates(notInvitedCandidates);
                }
            }
        },
        (error) => {
            const errorData = mapFieldErrorByError(error);
            if (errorData) {
                const { field, text, type } = errorData;
                if (type === 'phone') {
                    setFieldError(field, t(text));
                }
            } else {
                enqueueSnackbar(t('common:errors.request_error'), {
                    variant: 'error',
                });
            }
        }
    );

    useMutationHandlers(
        updateUserAccessResponse,
        () => {
            navigate('/users');
        },
        () => {
            enqueueSnackbar(t('common:errors.request_error'), {
                variant: 'error',
            });
        }
    );

    const handleCloseFoundUserDialog = useCallback(() => {
        setCandidates(undefined);
    }, []);

    const handleInviteUser = useCallback(
        (candidateId: UserProfile['id']) => {
            const selectedCandidate = candidates?.find((candidate) => candidate?.profile?.id === Number(candidateId));
            const getFieldBySelectedCandidate = (bindField: 'login' | 'email' | 'phone') => {
                if (selectedCandidate?.bindFields?.includes(bindField)) {
                    return values[bindField] || '';
                }
                return '';
            };

            inviteUser({
                userID: candidateId,
                employment: {
                    companyID,
                    companyName: values.companyName,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    middleName: values.middleName,
                    position: values.position,
                },
                profile: {
                    email: getFieldBySelectedCandidate('email'),
                    login: getFieldBySelectedCandidate('login'),
                    phone: getFieldBySelectedCandidate('phone'),
                    password: values.password!,
                },
            });
        },
        [inviteUser, values, candidates, companyID]
    );

    useMutationHandlers(
        inviteUserResponse,
        () => {
            navigate('/users');
            enqueueSnackbar(t('success.acceptInvitation'), {
                variant: 'success',
            });
        },
        () => {
            enqueueSnackbar(t('common:errors.request_error'), {
                variant: 'error',
            });
        }
    );

    return (
        <>
            <FormikProvider value={formik}>
                <Stack
                    component={Form}
                    alignItems='center'
                    px={7}
                    py={4}
                >
                    <Stack
                        spacing={5}
                        direction='row'
                        flexWrap='wrap'
                        justifyContent='space-between'
                        style={{ maxWidth: '1072px', width: '100%' }}
                    >
                        <UserData
                            values={values}
                            isEditUser={isEditUser}
                            setFieldValue={setFieldValue}
                            initialValues={initialValues}
                            phoneConfirmed={phoneConfirmed || false}
                            emailConfirmed={emailConfirmed || false}
                            name={userProfileToChange?.company?.userCompanyName}
                        />
                        {profile.role === 'admin' ? <UserAccesses isEditUser={isEditUser} /> : <span></span>}
                    </Stack>
                </Stack>
            </FormikProvider>
            <FoundUserDialog
                isOpen={!!candidates?.length}
                onCancel={handleCloseFoundUserDialog}
                candidates={candidates!}
                onSuccess={handleInviteUser}
            />
        </>
    );
};

export default UserForm;
