import { GridApi } from 'ag-grid-community';
import { isAfter, isBefore, isEqual, isValid, parse } from 'date-fns';
import isNull from 'lodash/isNull';

import { MAX_DATE, MIN_DATE } from '@/pages/WorkManagment/components/CellEditors/CellEditor/CellEditor.service';
import { ICustomCellEditorParams } from '@/pages/WorkManagment/components/CellEditors/CellEditor/CellEditor.types';

import { Nullable } from '@/types/common.types';

const invalidDate = (v: Nullable<Date>) => {
    return isNull(v) || (v && isValid(v)) || 'Неправильный формат даты';
};

const maxDate = (v: Nullable<Date>) => {
    return isNull(v) || isBefore(v, MAX_DATE) || 'Макс. 01.01.2050';
};

const minDate = (v: Nullable<Date>) => {
    return isNull(v) || isAfter(v, MIN_DATE) || 'Мин. 01.01.2000';
};

export const isBeforeEndDate = (gridApi: GridApi | undefined, key: string) => (startDate: Nullable<Date>) => {
    if (isNull(startDate)) return true;

    const endDateAsString = getCellValue(gridApi, key);
    if (!endDateAsString) return true;

    const endDate = parse(endDateAsString, 'dd.MM.yyyy', new Date());

    const isValid = isBefore(startDate, endDate) || isEqual(startDate, endDate);
    return isValid || 'Дата начала не должна быть позже даты окончания';
};

export const getCellValue = (gridApi: GridApi | undefined, key: string) => {
    return gridApi?.getDisplayedRowAtIndex(gridApi?.getEditingCells()[0]?.rowIndex)?.data[key];
};

export const isAfterStartDate = (gridApi: GridApi | undefined, key: string) => (endDate: Nullable<Date>) => {
    if (isNull(endDate)) return true;

    const startDateAsString = getCellValue(gridApi, key);
    if (!startDateAsString) return true;

    const startDate = parse(startDateAsString, 'dd.MM.yyyy', new Date());

    const isValid = isAfter(endDate, startDate) || isEqual(endDate, startDate);
    return isValid || 'Дата окончания не должна быть раньше даты начала';
};

export const getOptionalDateValidation = (validate?: { [key: string]: any }): ICustomCellEditorParams['validation'] => {
    return {
        required: false,
        validate: {
            invalidDate,
            maxDate,
            minDate,
            ...validate,
        },
    };
};
