import { AsyncThunkAction } from '@reduxjs/toolkit';
import { BodyScrollEndEvent, GridApi } from 'ag-grid-community';
import { useCallback, useEffect, useRef, useState } from 'react';

import { drawersSelector } from '@/store/slices/drawersSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

interface IInitialParams {
    limit: number;
    offset: number;
}

interface IConfigPagination<T> {
    totalCount: number;
    sendFn: (params: T & IInitialParams) => AsyncThunkAction<any, any, any>;
    initialParams: T & IInitialParams;
    thenFn?: () => void;
    catchFn?: () => void;
    requiredDeps?: any[];
    resetToInitialDeps?: any[];
    isAllDataFetched?: boolean;
}

/**
 * Хук для вертикальной динамической пагинации, работает через санки
 *
 * @param sendFn - экшен для диспатча события запроса
 * @param thenFn - необязательная функция, которая вызывается после успешного запроса
 * @param catchFn - необязательная функция, которая вызывается после обрушения запроса
 * @param resetToInitialDeps - массив зависимостей, который должны скинуть параметры запроса к начальным
 * @param initialParams - начальные данные для запроса
 * @param requiredDeps - массив зависимостей без которых не должен происходить запрос
 * @param totalCount - общее количество записей в БД
 *
 * Возвращает функцию для AgGrid
 *
 * @example
 *  const { scrollHandlerFoAG } = useVerticalPagination<IGetParamsKSG>({
 *         initialParams: { limit: LIMIT, offset: 0, id: Number(projectId) },
 *         sendFn: getData,
 *         totalCount: totalRows,
 *         requiredDeps: [Number(projectId), totalRows !== null, token],
 *         resetToInitialDeps: [filters,listModeOn],
 *     })
 *     return (
 *     <AgGridReact
 *               onBodyScrollEnd={scrollHandlerFoAG}
 *               { ...other_props }
 *     ></AgGridReact>
 *     )
 */
// TODO:
// 1. Удалить
export function useVerticalPagination<T>({
    sendFn,
    thenFn = () => {},
    catchFn = () => {},
    resetToInitialDeps = [],
    initialParams,
    requiredDeps = [],
    totalCount,
}: IConfigPagination<T>) {
    /* локальный стейт для перехвата параметров запроса снаружи, далее управляется в хуке */
    const [localParams, setLocalParams] = useState(initialParams);

    const dispatch = useAppDispatch();

    /* локальный стейт для запуска запроса */
    const [fetch, setFetch] = useState(false);

    /* локальный стейт для определения идет запрос в данный момент или нет */
    const [fetching, setFetcheing] = useState(false);

    /**
     * функция, которая возвращается из хука,
     * прослушивает событие скролла внутри таблицы AgGrid,
     * определяет положения конца таблицы и устанавливает новые параметры запроса,
     * чем тригерит запрос к БД
     */
    const scrollHandlerFoAG = useCallback(
        (event: BodyScrollEndEvent<any, any>) => {
            const lastRowNode = event.api.getDisplayedRowAtIndex(event.api.getDisplayedRowCount() - 1);
            const gridBody = document.querySelector('.ag-body-viewport') as HTMLElement;

            const lastRowTop = lastRowNode?.rowTop ?? 0;
            const lastRowHeight = lastRowNode?.rowHeight ?? 0;

            const gridBodyTop = gridBody.scrollTop;
            const gridBodyHeight = gridBody.clientHeight;
            const gridBodyBottom = gridBodyTop + gridBodyHeight;

            const isLastRowVisible = lastRowTop >= gridBodyTop && lastRowTop + lastRowHeight <= gridBodyBottom;

            if (isLastRowVisible) {
                setLocalParams((prevState) => ({
                    ...prevState,
                    limit: 1000,
                    offset: (prevState.offset += prevState.limit),
                }));
            }
        },
        [totalCount, localParams]
    );

    /**
     * функция запроса к БД, если индикатор загрузки не активен
     * переключает его в активное состояние и делает загрузку,
     * если вдруг случайно произойдет тригер вызова этой функции, запрос не повторится
     * после завершения запроса выключает индикатор загрузки
     * и готов к новому запросу
     */
    const send = useCallback(
        (indicator: boolean) => {
            if (!indicator) {
                setFetcheing(true);
                setFetch(false);
                dispatch(sendFn(localParams))
                    .then(thenFn)
                    .catch(catchFn)
                    .finally(() => setFetcheing(false));
            }
            //eslint-disable-next-line
        },
        [thenFn, catchFn, sendFn, localParams]
    );

    /**
     * Эффект следит за fetch, fetching и requiredDeps
     * сам вызов делается благодаря переключению fetch в активное состояние,
     * далее смотрится массив обязательных для вызова зависимостей,
     * если там все трушное делается запрос и внутрь передается текущее состояние загрузки (описано выше),
     * так как массив не обязателен, идет проверка на его длину, если ее нет, просто вызывается запрос
     */
    useEffect(() => {
        if (fetch) {
            if (requiredDeps?.length) {
                const flag = requiredDeps.every((_) => !!_);
                if (flag) {
                    send(fetching);
                }
            } else {
                send(fetching);
            }
        }
        //eslint-disable-next-line
    }, [fetch, fetching, ...requiredDeps]);

    /**
     * Эффект следит за массивом зависимостей для сброса параметров запроса, если вдруг что изменится
     * локальные параметры сбросятся до тех что передавались от родителя
     */
    useEffect(() => {
        setLocalParams(initialParams);
        //eslint-disable-next-line
    }, [...resetToInitialDeps]);

    /**
     * Эффект следит за локальными параметрами, когда они поменяются, триггерится вызов
     */
    useEffect(() => {
        setFetch(true);
    }, [localParams]);

    return {
        scrollHandlerFoAG,
        fetching,
    };
}

export const usePagination = <T>({
    sendFn,
    thenFn = () => {},
    catchFn = () => {},
    resetToInitialDeps = [],
    initialParams,
    requiredDeps = [],
    totalCount,
    isAllDataFetched,
}: IConfigPagination<T>) => {
    /* локальный стейт для перехвата параметров запроса снаружи, далее управляется в хуке */
    const [localParams, setLocalParams] = useState(initialParams);
    const { inView: isLastRowInView, reset: resetInView } = useAgGridLastRowInView();

    const dispatch = useAppDispatch();

    /* локальный стейт для запуска запроса */
    const [isFetch, setIsFetch] = useState(false);

    /* локальный стейт для определения идет запрос в данный момент или нет */
    const [isFetching, setIsFetching] = useState(false);

    /**
     * функция, которая возвращается из хука,
     * прослушивает событие скролла внутри таблицы AgGrid,
     * определяет положения конца таблицы и устанавливает новые параметры запроса,
     * чем тригерит запрос к БД
     */
    const fetchMoreData = useCallback(() => {
        setLocalParams((prevState) => ({
            ...prevState,
            limit: prevState.limit,
            offset: (prevState.offset += prevState.limit),
        }));
    }, [totalCount, localParams]);

    /**
     * функция запроса к БД, если индикатор загрузки не активен
     * переключает его в активное состояние и делает загрузку,
     * если вдруг случайно произойдет тригер вызова этой функции, запрос не повторится
     * после завершения запроса выключает индикатор загрузки
     * и готов к новому запросу
     */
    const send = useCallback(
        (indicator: boolean) => {
            if (!indicator) {
                setIsFetching(true);
                setIsFetch(false);
                dispatch(sendFn(localParams))
                    .then(() => {
                        thenFn();
                        resetInView();
                    })
                    .catch(catchFn)
                    .finally(() => setIsFetching(false));
            }
            //eslint-disable-next-line
        },
        [thenFn, catchFn, sendFn, localParams]
    );

    /**
     * Эффект следит за fetch, fetching и requiredDeps
     * сам вызов делается благодаря переключению fetch в активное состояние,
     * далее смотрится массив обязательных для вызова зависимостей,
     * если там все трушное делается запрос и внутрь передается текущее состояние загрузки (описано выше),
     * так как массив не обязателен, идет проверка на его длину, если ее нет, просто вызывается запрос
     */
    useEffect(() => {
        if (isFetch) {
            if (requiredDeps?.length) {
                const flag = requiredDeps.every((_) => !!_);
                if (flag) {
                    send(isFetching);
                }
            } else {
                send(isFetching);
            }
        }
        //eslint-disable-next-line
    }, [isFetch, isFetching, ...requiredDeps]);

    /**
     * Эффект следит за массивом зависимостей для сброса параметров запроса, если вдруг что изменится
     * локальные параметры сбросятся до тех что передавались от родителя
     */
    useEffect(() => {
        setLocalParams(initialParams);
        //eslint-disable-next-line
    }, [...resetToInitialDeps]);

    /**
     * Эффект следит за локальными параметрами, когда они поменяются, триггерится вызов
     */
    useEffect(() => {
        setIsFetch(true);
    }, [localParams]);

    const shouldFetchMoreData = !isAllDataFetched && isLastRowInView;

    useEffect(() => {
        if (!shouldFetchMoreData) return;
        fetchMoreData();
    }, [shouldFetchMoreData]);

    return {
        isFetching,
    };
};

const intersectionObserverOptions = {
    root: document.querySelector('.ag-body-viewport'),
    rootMargin: '0px 0px 120px 0px',
    threshold: 0,
};

const getAgGridLastRowElement = (api: GridApi) => {
    const gridBody = document.querySelector('.ag-body-viewport') as HTMLElement;

    const rowCount = api.getDisplayedRowCount();
    if (rowCount === 0) return null;

    const lastRowIndex = rowCount - 1;
    const lastRowNode = api.getDisplayedRowAtIndex(lastRowIndex);

    return gridBody.querySelectorAll(`[row-index="${lastRowNode!.rowIndex}"]`)[0];
};

const useAgGridLastRowInView = () => {
    const { AgGrid: grid } = useTypedSelector(drawersSelector);
    const gridApi = grid?.api;
    const [inView, setInView] = useState<boolean>(false);

    const lastRowElementRef = useRef<Element | null>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);

    const intersectionObserverCallback: IntersectionObserverCallback = ([entry], observe) => {
        if (!entry.isIntersecting) return;

        setInView(true);
        observe.unobserve(lastRowElementRef.current!);
        observerRef.current = null;
        lastRowElementRef.current = null;
    };

    useEffect(() => {
        if (!grid || !gridApi) return;

        const handleBodyScrollEnd = (event: BodyScrollEndEvent) => {
            const lastRowElement = getAgGridLastRowElement(event.api);

            if (!lastRowElement) return;
            if (lastRowElementRef.current === lastRowElement) return;

            if (observerRef.current && lastRowElementRef.current) {
                observerRef.current.unobserve(lastRowElementRef.current);
            }

            lastRowElementRef.current = lastRowElement;

            const observer = new IntersectionObserver(intersectionObserverCallback, intersectionObserverOptions);
            observer.observe(lastRowElementRef.current);
            observerRef.current = observer;
        };

        gridApi.addEventListener('bodyScrollEnd', handleBodyScrollEnd);

        return () => {
            gridApi.removeEventListener('bodyScrollEnd', handleBodyScrollEnd);
        };
    }, [grid, gridApi]);

    const reset = () => setInView(false);

    return {
        inView,
        reset,
    };
};
