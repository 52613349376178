export type TPluralizeTuple = [string, string, string];

enum EPluralForm {
    SINGULAR,
    FEW,
    MANY,
}

const getPluralForm = (num: number): number => {
    const lastDigit = num % 10;
    const lastTwoDigits = num % 100;

    if (lastDigit === 1 && lastTwoDigits !== 11) return EPluralForm.SINGULAR;
    if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 10 || lastTwoDigits >= 20)) return EPluralForm.FEW;
    return EPluralForm.MANY;
};

export const pluralize = (count: number, [singular, few, many]: TPluralizeTuple): string => {
    const normalizedCount = Math.abs(count);
    const form = getPluralForm(normalizedCount);

    if (form === EPluralForm.SINGULAR) return singular;
    if (form === EPluralForm.FEW) return few;
    return many;
};
