import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from '@/api/api';

import {
    TGetActiveTaskRequest,
    TGetActiveTaskResponse,
    TGetDistributeProgressRequest,
    TGetDistributeProgressResponse,
    TGetUploadProgressRequest,
    TGetUploadProgressResponse,
} from './pingedTasks.types';

export const pingedTasksApi = createApi({
    reducerPath: 'pingedTasksApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        getActiveTask: build.query<TGetActiveTaskResponse, TGetActiveTaskRequest>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/works/get-active-upload`,
                method: 'GET',
            }),
        }),

        getUploadProgress: build.query<TGetUploadProgressResponse, TGetUploadProgressRequest>({
            query: ({ projectId, uploadTask }) => ({
                url: `/projects/${projectId}/works/upload-get-active-progress?uuid=${uploadTask.uuid}`,
                method: 'GET',
            }),
        }),

        getDistributeProgress: build.query<TGetDistributeProgressResponse, TGetDistributeProgressRequest>({
            query: ({ projectId, distributionTask }) => ({
                url: `/projects/${projectId}/works/ksg/distribute/task-progress?uuid=${distributionTask.uuid}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetActiveTaskQuery, useGetUploadProgressQuery, useGetDistributeProgressQuery } = pingedTasksApi;
