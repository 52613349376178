import { useSnackbar } from 'notistack';
import { match } from 'ts-pattern';

import {
    TStructureListResponse,
    useAddAndGroupFolderStructureMutation,
    useAddFolderStructureMutation,
    useDeleteAllFoldersStructureMutation,
    useDeleteFolderStructureMutation,
    useInsertFolderStructureMutation,
    useMoveConsolidatedPriceStructureMutation,
    useMoveFolderStructureMutation,
    useUpdateFolderStructureMutation,
} from '@/api/structure';

import { queryClient } from '@/shared/constants/queryClient';
import { queryKeys } from '@/shared/constants/queryKeys';

export const useAddFolderMutation = (projectId: number) => {
    const { enqueueSnackbar } = useSnackbar();

    return useAddFolderStructureMutation({
        onSuccess: () => {
            enqueueSnackbar('Новый уровень добавлен', {
                variant: 'success',
            });
            queryClient.invalidateQueries({
                queryKey: [queryKeys.structure.list, projectId],
            });
        },
        onError: (data) => {
            // @ts-ignore
            const message = match(data).otherwise(() => 'Произошла ошибка при добавлении уровня');

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
    });
};

export const useAddAndGroupFolderMutation = (projectId: number) => {
    const { enqueueSnackbar } = useSnackbar();

    return useAddAndGroupFolderStructureMutation({
        onSuccess: () => {
            enqueueSnackbar('Новый уровень добавлен', {
                variant: 'success',
            });
            queryClient.invalidateQueries({
                queryKey: [queryKeys.structure.list, projectId],
            });
        },
        onError: (data) => {
            // @ts-ignore
            const message = match(data).otherwise(() => 'Произошла ошибка при добавлении уровня');

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
    });
};

export const useInsertFolderMutation = (projectId: number) => {
    const { enqueueSnackbar } = useSnackbar();

    return useInsertFolderStructureMutation({
        onSuccess: () => {
            enqueueSnackbar('Новый уровень добавлен', {
                variant: 'success',
            });
            queryClient.invalidateQueries({
                queryKey: [queryKeys.structure.list, projectId],
            });
        },
        onError: (data) => {
            // @ts-ignore
            const message = match(data).otherwise(() => 'Произошла ошибка при добавлении уровня');

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
    });
};

export const useUpdateFolderMutation = (projectId: number) => {
    const { enqueueSnackbar } = useSnackbar();

    return useUpdateFolderStructureMutation({
        onMutate: (data) => {
            const oldData = queryClient.getQueryData<TStructureListResponse>([queryKeys.structure.list, projectId]);
            const newData = oldData?.data?.map((item) => {
                if (item.entityId === data.folderId && item.rowType === 'a_folder') {
                    return {
                        ...item,
                        name: data.body.name,
                    };
                }
                return item;
            });
            queryClient.setQueryData([queryKeys.structure.list, projectId], {
                ...oldData,
                data: newData ?? oldData?.data,
            });

            return {
                oldData,
            };
        },
        onSuccess: () => {
            enqueueSnackbar('Уровень обновлен', {
                variant: 'success',
            });
        },
        onError: (error, _data, ctx) => {
            // @ts-ignore
            const message = match(error).otherwise(() => 'Произошла ошибка при обновлении уровня');

            enqueueSnackbar(message, {
                variant: 'error',
            });

            const context = ctx as { oldData: TStructureListResponse };
            queryClient.setQueryData([queryKeys.structure.list, projectId], context.oldData);
        },
    });
};

export const useDeleteFolderMutation = (projectId: number) => {
    const { enqueueSnackbar } = useSnackbar();

    return useDeleteFolderStructureMutation({
        onSuccess: () => {
            enqueueSnackbar('Уровень удален', {
                variant: 'success',
            });
            queryClient.invalidateQueries({
                queryKey: [queryKeys.structure.list, projectId],
            });
        },
        onError: (data) => {
            // @ts-ignore
            const message = match(data).otherwise(() => 'Произошла ошибка при удалении уровня');

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
    });
};

export const useMoveConsolidatePriceMutation = (projectId: number) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMoveConsolidatedPriceStructureMutation({
        onSuccess: () => {
            enqueueSnackbar('Укрупненная расценка перемещена', {
                variant: 'success',
            });
        },
        onError: (data) => {
            // @ts-ignore
            const message = match(data).otherwise(() => 'Произошла ошибка при перемещении укрупненной расценки');

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
        onSettled: async () => {
            queryClient.invalidateQueries({
                queryKey: [queryKeys.structure.list, projectId],
            });
        },
    });
};

export const useMoveFolderMutation = (projectId: number) => {
    const { enqueueSnackbar } = useSnackbar();

    return useMoveFolderStructureMutation({
        onSuccess: () => {
            enqueueSnackbar('Уровень перемещен', {
                variant: 'success',
            });
        },
        onError: (data) => {
            // @ts-ignore
            const message = match(data)
                .with(
                    {
                        response: {
                            status: 400,
                            data: 'circular folder reference detected',
                        },
                    },
                    () => 'Нельзя переместить уровень внутрь себя'
                )
                .otherwise(() => 'Произошла ошибка при перемещении уровня');

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
        onSettled: async () => {
            queryClient.invalidateQueries({
                queryKey: [queryKeys.structure.list, projectId],
            });
        },
    });
};

export const useDeleteAllFoldersMutation = (projectId: number) => {
    const { enqueueSnackbar } = useSnackbar();

    return useDeleteAllFoldersStructureMutation({
        onSuccess: () => {
            enqueueSnackbar('Все уровни удалены', {
                variant: 'success',
            });
        },
        onError: (data) => {
            // @ts-ignore
            const message = match(data).otherwise(() => 'Произошла ошибка при удалении всех уровней');

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
        onSettled: async () => {
            queryClient.invalidateQueries({
                queryKey: [queryKeys.structure.list, projectId],
            });
        },
    });
};
