import { isValidElement } from 'react';

import { LabelText } from './UIInput.styles';

export const getLabelText = (label: React.ReactNode) => {
    if (isValidElement(label)) {
        return label;
    }
    return <LabelText>{label}</LabelText>;
};
