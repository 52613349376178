import { format, isValid } from 'date-fns';

import { Nullable } from '@/types/common.types';

export const getDateAsString = (date: Nullable<string>) => {
    if (!date) return null;
    if (!isValid(new Date(date))) return null;

    return format(new Date(date), 'dd.MM.yyyy');
};
