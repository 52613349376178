import { CellClassParams, GridApi, IRowNode } from 'ag-grid-community';

import { TEstimatePositionId } from '@/api/estimatePositions';
import { TEstimatePositionChanges } from "@/api/estimatePositions/estimatePositions.types";

import { TEstimateCheckedPositionMap, TEstimateCheckedPositionMapValue } from '@/store/slices/estimatePositions/';

import {
    TCheckboxesRange,
    TData,
    TGetCheckboxesRangeProps,
    TSelectedPositionType,
} from './EstimatePositionsTable.types';

export const getCheckboxesRange = ({
    data,
    id,
    lastCheckedId,
    isShiftKey,
    type,
}: TGetCheckboxesRangeProps): TCheckboxesRange => {
    const position = data.find((v) => v.id === id);
    if (!isShiftKey) return [[id, type, position!]];

    const currentIdx = data.findIndex((item) => item.id === id);
    const lastIdx = lastCheckedId ? data.findIndex((item) => item.id === lastCheckedId) : 0;

    const [min, max] = [Math.min(currentIdx, lastIdx), Math.max(currentIdx, lastIdx)];
    return data.slice(min, max + 1).map((item) => [item.id, type, item]);
};

export const isChangedCell = (key: keyof TEstimatePositionChanges) => (params: CellClassParams<TData>) => {
    if (!params.data) return false;

    const data = params.data;
    if (data.changes.isDeleted) return false;
    if (data.changes.all) return false;
    return data.changes[key];
};

export const createCheckboxKey = (id: TEstimatePositionId, type: TSelectedPositionType) => `${id}-${type}`;

export const isShiftKey = (event: React.ChangeEvent<HTMLInputElement>): boolean =>
    // @ts-ignore
    'shiftKey' in event.nativeEvent && event.nativeEvent.shiftKey;

export const redrawRows = (gridApi: GridApi, data: TCheckboxesRange) => {
    if (!gridApi) return;

    const rowNodes = data.map(([rowId]) => gridApi.getRowNode(rowId.toString())) as IRowNode[];
    gridApi.redrawRows({ rowNodes });
};

export const redrawAllRows = (gridApi: GridApi) => {
    gridApi.forEachNodeAfterFilter((row) => {
        gridApi.redrawRows({ rowNodes: [row] });
    });
};

export const updateCheckboxesMap = (
    prevMap: TEstimateCheckedPositionMap,
    checked: boolean,
    id: TEstimatePositionId,
    range: TCheckboxesRange
): TEstimateCheckedPositionMap => {
    const map = new Map(prevMap);

    if (!checked) {
        map.delete(id);
        return map;
    }

    for (const [id, selectType, position] of range) {
        map.set(id, {
            ...position,
            selectType,
        } as TEstimateCheckedPositionMapValue);
    }

    return map;
};
