import qs from 'qs';

import { api } from '@/api/api';

import { setTotal } from '@/store/slices/paginationSlice';
import { store } from '@/store/store';

import { uploadExcelReq, uploadExcelRes } from '../works/works.types';
import {
    addKsgPeopleReqI,
    getKsgPeopleByIdResI,
    getKsgPeopleResI,
    updateKsgPeopleReqI,
    updatePeopleKsgChartsReqI,
    updatePeopleKsgChartsResI,
    updatePeopleKsgResI,
} from './ksgPeople.types';

export const peopleKsg = api.injectEndpoints({
    endpoints: (build) => ({
        getKsgPeople: build.query<getKsgPeopleResI, { id: number; limit?: number; offset?: number; filter?: string }>({
            query: ({ id, limit, offset, filter }) => ({
                url: `/projects/${id}/staffs/cpg?${qs.stringify(
                    { title: store.getState().filtersWorkersMim.titlesArr },
                    {
                        indices: false,
                    }
                )}`,
                method: 'GET',
                params: {
                    limit,
                    offset,
                    filter,
                },
            }),
            async onQueryStarted(arg, api) {
                const data = await api.queryFulfilled;

                api.dispatch(setTotal(data.data.data.length ? data.data.total : 1));
            },
            providesTags: ['KsgPeople'],
        }),

        updatePeopleKsgCharts: build.mutation<updatePeopleKsgChartsResI, updatePeopleKsgChartsReqI>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/staffs/update-charts`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(
                        peopleKsg.util.updateQueryData(
                            'getKsgPeople',
                            {
                                id,
                                limit: 9999,
                                offset: 0,
                                filter: store.getState().project.headerSearch,
                            },
                            (draft) => {
                                const indexChange = draft.data.findIndex((row) => {
                                    return row.id === updatedStatus.data[0].id;
                                });
                                draft.data.splice(indexChange, 1, updatedStatus.data[0]);
                            }
                        )
                    );
                } catch {}
            },
        }),

        getByIdKsgPeople: build.query<
            getKsgPeopleByIdResI,
            {
                id: number;
                staffId: number;
            }
        >({
            query: ({ id, staffId }) => ({
                url: `/projects/${id}/staffs/${staffId}/get`,
                method: 'GET',
            }),
            providesTags: ['KsgPeople'],
        }),

        addKsgPeople: build.mutation<updatePeopleKsgResI, addKsgPeopleReqI>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/staffs/add`,
                method: 'POST',
                body,
            }),
            // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: updatedStatus } = await queryFulfilled
            //         dispatch(
            //             peopleKsg.util.updateQueryData(
            //                 'getKsgPeople',
            //                 {
            //                     id,
            //                     limit: 9999,
            //                     offset: 0,
            //                     filter: store.getState().project.headerSearch,
            //                 },
            //                 (draft) => {
            //                     draft.data.unshift(updatedStatus.data)
            //                 }
            //             )
            //         )
            //     } catch {}
            // },
        }),

        updateKsgPeople: build.mutation<updatePeopleKsgResI, updateKsgPeopleReqI>({
            query: ({ id, staffId, body }) => ({
                url: `/projects/${id}/staffs/${staffId}/update`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(
                        peopleKsg.util.updateQueryData(
                            'getKsgPeople',
                            {
                                id,
                                limit: 9999,
                                offset: 0,
                                filter: store.getState().project.headerSearch,
                            },
                            (draft) => {
                                const indexChange = draft.data.findIndex((work) => {
                                    return work.id === updatedStatus.data.id;
                                });
                                draft.data.splice(indexChange, 1, updatedStatus.data);
                            }
                        )
                    );
                } catch {}
            },
        }),

        deleteKsgPeople: build.mutation<updatePeopleKsgResI, { id: number; workId: number }>({
            query: ({ id, workId }) => ({
                url: `/projects/${id}/staffs/${workId}/delete`,
                method: 'DELETE',
            }),
            // async onQueryStarted({ id, workId, ...patch }, { dispatch, queryFulfilled }) {
            //     try {
            //         await queryFulfilled
            //         dispatch(
            //             peopleKsg.util.updateQueryData(
            //                 'getKsgPeople',
            //                 {
            //                     id,
            //                     limit: 9999,
            //                     offset: 0,
            //                     filter: store.getState().project.headerSearch,
            //                 },
            //                 (draft) => {
            //                     draft.data = draft.data.filter((work) => work.id !== workId)
            //                 }
            //             )
            //         )
            //     } catch {}
            // },
        }),

        setKsgPeopleIsMain: build.mutation<
            unknown,
            {
                id: number;
                staffId: number;
                body: {
                    isMain: boolean;
                };
            }
        >({
            query: ({ id, staffId, body }) => ({
                url: `/projects/${id}/staffs/${staffId}/set-main`,
                method: 'POST',
                body,
            }),
            // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
            //     await queryFulfilled
            //     try {
            //         dispatch(
            //             peopleKsg.util.updateQueryData(
            //                 'getKsgPeople',
            //                 {
            //                     id,
            //                     limit: 9999,
            //                     offset: 0,
            //                     filter: store.getState().project.headerSearch,
            //                 },
            //                 (draft) => {
            //                     const itemToChange = draft.data.find((row) => {
            //                         return row.id === patch.staffId
            //                     })
            //                     if (!itemToChange) return
            //                     itemToChange.isMain = patch.body.isMain
            //                 }
            //             )
            //         )
            //     } catch {}
            // },
        }),

        getExcelLinkPeople: build.query<{ data: string }, void>({
            query: () => ({
                url: `/projects/staffs/link-example`,
                method: 'GET',
            }),
        }),

        uploadExcelPeople: build.mutation<uploadExcelRes, uploadExcelReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/staffs/upload`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Headers', 'KsgPeople'],
        }),

        downloadExcelPeople: build.mutation<string, { id: number }>({
            query: ({ id }) => ({
                url: `/projects/${id}/staffs/export-ksg`,
                method: 'POST',
                headers: {
                    'content-type': 'text/plain',
                },
                responseHandler: 'text',
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetKsgPeopleQuery,
    useUpdatePeopleKsgChartsMutation,
    // useGetByIdKsgPeopleQuery,
    useUpdateKsgPeopleMutation,
    useDeleteKsgPeopleMutation,
    useGetExcelLinkPeopleQuery,
    useUploadExcelPeopleMutation,
    useAddKsgPeopleMutation,
    useDownloadExcelPeopleMutation,
    useSetKsgPeopleIsMainMutation,
} = peopleKsg;
