import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetTemplatesListMsgQuery } from '@/api/templates/templates.api';
import { useResetPatternMsgMutation, useSetPatternMsgMutation } from '@/api/templatesGetSet/templatesGetSet.api';

import { toggleChangeMsgTemplate, toggleCreateMsgTemplate } from '@/store/slices/drawersSlice';
import { refreshTemplateMsg, setTemplateMsgState, setTemplateMsgStateView } from '@/store/slices/msgTemplatesSlice';
import { useAppDispatch } from '@/store/store';

import SharedTemplateMenu from './components/SharedTemplateMenu';

export default function TableTemplateMenuMsg({
    setAnchorElTemplateTable,
}: {
    setAnchorElTemplateTable: Dispatch<SetStateAction<HTMLElement | null>>;
}) {
    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    const { projectId } = useParams();

    const templatesList = useGetTemplatesListMsgQuery({
        id: Number(projectId),
    });

    const [setPatternReq] = useSetPatternMsgMutation();
    const [resetPatternReq] = useResetPatternMsgMutation();

    const setPatternMsg = (patId: number) => {
        setPatternReq({
            projectId: Number(projectId),
            patId,
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('template_msg_applied'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
    };

    const resetPatternMsg = () => {
        resetPatternReq({ projectId: Number(projectId) })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('template_msg_reset'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
    };

    return (
        <SharedTemplateMenu
            onRefresh={() => {
                setAnchorElTemplateTable(null);
                dispatch(refreshTemplateMsg());
                resetPatternMsg();
            }}
            onApplyMsg={(v) => {
                setAnchorElTemplateTable(null);
                dispatch(setTemplateMsgState(v));
                setPatternMsg(v.id);
            }}
            onApplyMsgView={(v) => {
                setAnchorElTemplateTable(null);
                dispatch(setTemplateMsgStateView(v));
            }}
            onChange={() => {
                dispatch(toggleChangeMsgTemplate());
                setAnchorElTemplateTable(null);
            }}
            onCreate={() => {
                dispatch(toggleCreateMsgTemplate());
                setAnchorElTemplateTable(null);
            }}
            msgList={templatesList.data?.data}
        />
    );
}
