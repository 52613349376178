import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';
import { Updater } from 'use-immer';

import g from '@/shared/stylesheets/TableGrid.module.scss';

import { UIFolder } from '../../../components/ui/UIFolder';

interface IProps {
    hiddenRowsIds: { id: number; code: string }[];
    setHiddenRowsIds: Updater<{ id: number; code: string }[]>;
    hasChildren: boolean;
    id: number;
    code: string;
    level: number | null;
}

export default function LevelRendererAgGridMsg({
    hiddenRowsIds,
    setHiddenRowsIds,
    hasChildren,
    id,
    code,
    level,
}: IProps) {
    const showArrows = () => {
        if (hasChildren) {
            if (!hiddenRowsIds?.find((item) => item.id === id))
                return (
                    <IconButton
                        sx={{
                            padding: 0,
                        }}
                        color='inherit'
                        component='button'
                    >
                        <KeyboardArrowDownIcon
                            sx={{
                                fontSize: 16,
                            }}
                        />
                    </IconButton>
                );
            return (
                <IconButton
                    sx={{
                        padding: 0,
                    }}
                    color='inherit'
                    component='button'
                >
                    <KeyboardArrowUpIcon
                        sx={{
                            fontSize: 16,
                        }}
                    />
                </IconButton>
            );
        }
        return null;
    };

    return (
        <div
            onClick={() => {
                setHiddenRowsIds((d) => {
                    const addIndex = d.findIndex((hrId) => hrId.id === id);
                    if (addIndex >= 0) {
                        d.splice(addIndex, 1);
                    } else {
                        d.push({ id: id, code: code });
                    }
                });
            }}
        >
            <div>
                <div className={g.arrow_level}>{showArrows()}</div>
                <UIFolder
                    level={level!}
                    hasChildren={hasChildren!}
                />
            </div>
        </div>
    );
}
