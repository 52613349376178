import { Typography } from '@mui/material';
import { useEffect } from 'react';

import { setGantLinkChoice } from '@/store/slices/msgGantSlice';
import { useAppDispatch } from '@/store/store';

import { OverflowableTypographyWithTooltip } from '../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { GantBar } from '../../../../pages/AgGridMsg/components/GantCell/GantBar';
import { getDateWithLeadingZero } from '../../../../pages/AgGridMsg/components/GantCell/GantCell.service';
import { FlexRowWrapper } from '../../../../pages/NewExecutorView/components/components.styles';
import { theme } from '../../../../styles/theme';
import { IGantLinkActiveChoiseProps } from './GantLinkActiveChoise.types';

export function GantLinkActiveChoise({ linkType, cellRendererParams, chartIndex }: IGantLinkActiveChoiseProps) {
    const work = cellRendererParams?.data;
    const dispatch = useAppDispatch();

    useEffect(() => {
        function leaveLinkMode(e: KeyboardEvent) {
            console.log(e);
            if (e.key === 'Escape') {
                return dispatch(setGantLinkChoice(null));
            }
        }
        work && document.addEventListener('keyup', leaveLinkMode);
        return () => {
            work && document.removeEventListener('keyup', leaveLinkMode);
        };
    }, [work]);

    return work ? (
        <FlexRowWrapper gap={2}>
            <Typography>{getDateWithLeadingZero(chartIndex + 1, work.month, work.year)}</Typography>
            <GantBar
                isChosen
                bgcolor={work.brigade?.color || 'white'}
                width={'100px !important'}
                innerText={
                    <Typography
                        // fontWeight={600}
                        color={theme.palette.grey[600]}
                    >
                        {linkType === 'start' ? 'СТАРТ' : 'ФИНИШ'}
                    </Typography>
                }
                placement={'center'}
                sx={{ position: 'relative !important', brightness: '0.5' }}
                startIndicatorProps={{
                    indicatorProps: {
                        sx: {
                            display: 'none !important',
                            // ...(linkType === 'finish' && { display: 'none !important' }),
                            position: 'relative !important',
                            // left: '8px !important',
                        },
                    },
                }}
                finishIndicatorProps={{
                    indicatorProps: {
                        sx: {
                            display: 'none !important',
                            // ...(linkType === 'start' && { display: 'none !important' }),
                            position: 'relative !important',
                            // right: '6px !important',
                        },
                    },
                }}
            />
            <OverflowableTypographyWithTooltip
                maxRows={1}
                TypographyProps={{ sx: { maxWidth: 300 } }}
            >
                {`${work.numOrder.toString()}. ${work.workName}`}
            </OverflowableTypographyWithTooltip>
        </FlexRowWrapper>
    ) : null;
}
