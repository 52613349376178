import { Button, CircularProgress, Dialog, Typography } from '@mui/material';
import { FaFilter } from '@react-icons/all-files/fa/FaFilter';
import { AgGridReact } from 'ag-grid-react';
import { memo, useEffect, useRef, useState } from 'react';
import { useSpinDelay } from 'spin-delay';

import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { StyledBtn } from '@/pages/WorkManagment/components/components.styles';

import { theme } from '@/styles/theme';

import { useFilterSearch } from './FilterDialog.model';
import { IFilterDialogTableOption, IProps } from './FilterDialog.types';
import { FilterOptionCellRenderer } from './FilterOptionCellRenderer';

export const FilterDialog = memo(
    ({ data, isLoading, open, onClose, name, HeaderCheckboxes, onFilterReset, onFilterApply }: IProps) => {
        const { SearchTextfieldComponent, search, onReset: onSearchReset } = useFilterSearch();
        const [options, setOptions] = useState<IFilterDialogTableOption[]>(data ?? []);
        const gridRef = useRef<AgGridReact>(null);
        const isLoadingWithMinDuration = useSpinDelay(isLoading, { delay: 0, minDuration: 700 });

        useEffect(() => {
            gridRef.current?.api.setQuickFilter(search);
        }, [search]);

        useEffect(() => {
            setOptions(data);
        }, [data]);

        const resetFilter = () => {
            gridRef.current?.api.applyTransaction({
                update: [
                    {
                        ...options.map((option) => ({
                            ...option,
                            checked: false,
                        })),
                    },
                ],
            });
            gridRef.current?.api.redrawRows();

            setOptions((prevState) =>
                prevState.map((option) => ({
                    ...option,
                    checked: false,
                }))
            );
            onFilterReset();
        };

        const applyFilter = () => {
            const checkedOptions: IFilterDialogTableOption[] = [];
            gridRef?.current?.api.forEachNode((row) => {
                row.data.checked && checkedOptions.push(row.data);
            });
            onFilterApply(checkedOptions);
            handleClose();
        };

        const handleClose = () => {
            onSearchReset();
            onClose();
        };

        return (
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <FlexColumnWrapper
                    p={2}
                    minWidth='373px'
                    height={'auto'}
                >
                    <FlexRowWrapper
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        alignContent={'center'}
                    >
                        <FlexRowWrapper>
                            <FaFilter color={theme.palette.primary.main} />
                            <Typography
                                variant={'body1'}
                                fontWeight={'bold'}
                                color={'primary'}
                            >
                                {name}
                            </Typography>
                        </FlexRowWrapper>
                        <Button
                            sx={{
                                color: '#7890B2',
                                padding: '0.1rem 0.5rem',
                                fontWeight: '400',
                            }}
                            onClick={resetFilter}
                        >
                            Сбросить фильтр
                        </Button>
                    </FlexRowWrapper>
                    {SearchTextfieldComponent}
                    {HeaderCheckboxes}
                    {isLoadingWithMinDuration ? (
                        <FlexColumnWrapper
                            height={300}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <CircularProgress
                                size={50}
                                sx={{ margin: '0 auto' }}
                            />
                        </FlexColumnWrapper>
                    ) : (
                        <FlexColumnWrapper
                            minHeight={'50px'}
                            height={300}
                        >
                            <AgGridReact
                                ref={gridRef}
                                rowData={options}
                                headerHeight={0}
                                rowHeight={42}
                                getRowId={(params) => {
                                    return params.data.value;
                                }}
                                columnDefs={[
                                    {
                                        cellStyle: {
                                            placeContent: 'unset',
                                        },
                                        cellClass: 'ag-cell-left',
                                        headerName: 'label',
                                        field: 'label',
                                        flex: 2,
                                        cellRenderer: FilterOptionCellRenderer,
                                    },
                                ]}
                                overlayNoRowsTemplate='Нет данных'
                            />
                        </FlexColumnWrapper>
                    )}
                    <StyledBtn
                        fullWidth
                        variant='contained'
                        onClick={applyFilter}
                    >
                        Применить
                    </StyledBtn>
                </FlexColumnWrapper>
            </Dialog>
        );
    }
);
