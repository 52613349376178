import { CellClassParams, ColDef, ICellRendererParams, IHeaderParams } from 'ag-grid-community';

import { getFormatNumber } from '@/shared/utils/formatNumber.utils';

import { TContext, TData } from './StructureManagementTable.types';
import {
    CheckboxCellRenderer,
    CollapseCellRenderer,
    ConsolidatedCheckboxCellRenderer,
    ConsolidatedOrdinalCellRenderer,
    DragCellRenderer,
    EditCellRenderer,
    EditHeaderComponent,
    FolderOrdinalCellRenderer,
    TextCellRenderer,
} from './components/TableCells';
import { cellClassRules, generateRowId } from './utils';

export const columnsDefs: ColDef<TData>[] = [
    {
        field: 'drag',
        headerName: '',
        width: 44,
        cellClass: 'p0',
        suppressMovable: true,
        cellRendererSelector: (params: ICellRendererParams<TData>) => {
            return ['a_folder', 'b_consolidated'].includes(params.data!?.rowType)
                ? {
                      component: DragCellRenderer,
                  }
                : undefined;
        },
        cellClassRules: cellClassRules,
    },
    {
        field: 'edit',
        width: 44,
        cellClass: 'p0',
        headerClass: 'p0',
        suppressMovable: true,
        headerComponent: EditHeaderComponent,
        headerComponentParams: (params: IHeaderParams<TData, TContext>) => ({
            isOpen: params.context.openedEditMenuRowId === 'header',
            onOpen: params.context.onEditMenuOpen('header'),
            onClose: params.context.onEditMenuClose('header'),
            list: [
                {
                    label: 'Разгруппировать все уровни',
                    onClick: () => {
                        params.context.onAllFoldersDeleteConfirm();
                        params.context.onEditMenuClose('header')();
                    },
                },
            ],
        }),
        cellRendererSelector: (params: ICellRendererParams<TData>) => {
            if (params.data!?.rowType === 'a_folder') {
                return {
                    component: EditCellRenderer,
                };
            }

            if (params.data!?.rowType === 'b_consolidated') {
                return {
                    component: ConsolidatedCheckboxCellRenderer,
                };
            }

            return;
        },
        cellRendererParams: (params: ICellRendererParams<TData, any, TContext>) => ({
            isOpen: params.context.openedEditMenuRowId === params?.data?.entityId,
            onOpen: params.context.onEditMenuOpen(params.data?.entityId!, params.data?.rowType!),
            onClose: params.context.onEditMenuClose(params.data?.entityId!, params.data?.rowType!),
            list: [
                {
                    label: 'Редактировать',
                    onClick: () => {
                        params.context.onRowEdit(params.data?.entityId!)();
                        params.context.onEditMenuClose(params.data?.entityId!, params.data?.rowType!)();
                    },
                },
                {
                    label: 'Разгруппировать',
                    onClick: () => {
                        params.context.onFolderDeleteConfirm(params.data?.entityId!, params.data?.name!);
                        params.context.onEditMenuClose(params.data?.entityId!, params.data?.rowType!)();
                    },
                },
            ],
        }),
        cellClassRules: cellClassRules,
    },
    {
        field: 'collapse',
        headerName: '',
        width: 44,
        cellClass: 'p0',
        cellClassRules: {
            collapse: (params: CellClassParams<TData>) =>
                params.context.collapsedRowIds.has(
                    generateRowId({ rowType: params.data?.rowType!, entityId: params.data?.entityId! })
                ),
            ...cellClassRules,
        },
        suppressMovable: true,
        cellRendererSelector: (params: ICellRendererParams<TData>) => {
            if (['a_folder', 'b_consolidated'].includes(params.data!?.rowType))
                return {
                    component: CollapseCellRenderer,
                };

            if (params.data!?.rowType === 'c_position') {
                return {
                    component: CheckboxCellRenderer,
                };
            }

            return undefined;
        },
        cellRendererParams: (params: ICellRendererParams<TData, any, TContext>) => ({
            onRowCreate: params.context.onRowCreate(params.data?.entityId!, params.data?.rowType!),
            onCollapse: params.context.onRowCollapse(params.data?.rowType!, params.data?.entityId!),
        }),
    },
    {
        field: 'ordinal',
        headerName: '№',
        width: 260,
        suppressMovable: true,
        cellClass: 'ag-cell-center ag-cell-without-border',
        cellRendererSelector: (params: ICellRendererParams<TData>) => {
            return params.data?.rowType === 'a_folder'
                ? {
                      component: FolderOrdinalCellRenderer,
                  }
                : {
                      component: ConsolidatedOrdinalCellRenderer,
                  };
        },

        colSpan: (params) => {
            if (params.data?.rowType === 'a_folder') return 10;
            return 1;
        },
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'Шифр ЛСР',
        field: 'lsrCode',
        minWidth: 180,
        maxWidth: 500,
        cellRenderer: TextCellRenderer,
        cellClassRules: cellClassRules,
    },
    {
        headerName: '№ по ЛСР',
        field: 'lsrNumber',
        minWidth: 160,
        cellClass: 'ag-cell-center',
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'Раздел',
        field: 'chapter',
        minWidth: 180,
        cellRenderer: TextCellRenderer,
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'Заголовок',
        field: 'header',
        minWidth: 150,
        cellRenderer: TextCellRenderer,
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'Код расценки',
        field: 'code',
        minWidth: 160,
        cellClass: 'ag-cell-center',
        cellClassRules: cellClassRules,
        cellRenderer: TextCellRenderer,
    },
    {
        headerName: 'Наименование',
        field: 'name',
        minWidth: 320,
        flex: 1,
        cellRenderer: TextCellRenderer,
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'Ед. изм',
        field: 'rawUnit',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        cellClass: 'ag-cell-center',
        cellRenderer: TextCellRenderer,
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'Кол-во',
        field: 'quantity',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        cellClass: 'ag-cell-center',
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'ТЗ ОР на ед, чел-ч',
        field: 'humanHourCostPerUnit',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        cellClass: 'ag-cell-right ag-cell-without-border',
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'ТЗ ОР, чел-ч',
        field: 'humanHourCost',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        cellClass: 'ag-cell-right ag-cell-without-border',
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'ТЗМ на ед, чел-ч',
        field: 'machineHourCostPerUnit',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        cellClass: 'ag-cell-right ag-cell-without-border',
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'ТЗМ, чел-ч',
        field: 'machineHourCost',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        cellClass: 'ag-cell-right ag-cell-without-border',
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'Стоимость ЕР, руб.',
        field: 'costEr',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 160,
        maxWidth: 160,
        width: 160,
        cellClass: 'ag-cell-right ag-cell-without-border',
        cellClassRules: cellClassRules,
    },
    {
        headerName: 'Всего стоимость',
        field: 'costTotal',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        flex: 1,
        minWidth: 140,
        cellClass: 'ag-cell-right',
        cellClassRules: cellClassRules,
    },
];
