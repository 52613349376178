import { ArrowBack } from '@mui/icons-material';
import DownloadingSharpIcon from '@mui/icons-material/DownloadingSharp';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import { GetRowIdParams, RowHeightParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api';
import { useDeleteKsgPeopleMutation, useGetKsgPeopleQuery } from '@/api/ksgPeople/ksgPeople.api';
import { KsgPeopleI } from '@/api/ksgPeople/ksgPeople.types';

import ConfirmationModal from '@/shared/components/ConfirmationModal/ConfirmationModal';
import EmptyPlaceholder from '@/shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { getWorkManagmentResourceRowSlyles, modalStyles } from '@/shared/utils';

import { setAgGrid, toggleUploadKsgPeople } from '@/store/slices/drawersSlice';
import { filtersWorkersMimSelector } from '@/store/slices/filtersWorkersMimSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { projectsSelector } from '@/store/slices/projectsSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import CheckboxPopoverWorkersMimKsg from '../../components/CheckboxPopoverWorkersMim/CheckboxPopoverWorkersMimKsg';
import '../AgGrid/AgGrid.scss';
import { req } from '../WorkManagment/api/api';
import { AG_GRID_DEFAULT_PARAMS_KSG } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { getAgGridKSGPeopleColDef } from './AgGridKSGPeople.colDef';
import { emptyWorkKsgPeq, submitCellData } from './AgGridKsgPeople.service';
import { MemoAgGrid } from './MemoAgGrid';

export default function AgGridKsgPeople() {
    const [warningModal, setWarningModal] = useState({ openState: false, deleteId: 0 });
    const [openModal, setOpenModal] = useState(false);

    const { projectId } = useParams();
    const navigate = useNavigate();

    const { titlesArr } = useTypedSelector(filtersWorkersMimSelector);
    const { headerSearch } = useTypedSelector(projectsSelector);
    const { profile } = useTypedSelector(profileSelector);

    const gridRef = useRef<AgGridReact>(null);

    const location = useLocation();

    const headers = useGetHeadersCPGQuery({
        id: Number(projectId),
        isStaff: true,
    });

    const peopleKsgData = useGetKsgPeopleQuery({
        id: Number(projectId),
        limit: 9999,
        offset: 0,
        filter: headerSearch,
    });

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');
    const { t: tStaffTable } = useTranslation('staffTable');

    const dispatch = useAppDispatch();

    const [deletePeopleReq, deletePeopleRes] = useDeleteKsgPeopleMutation();

    useEffect(() => {
        gridRef?.current && dispatch(setAgGrid(gridRef?.current));
        return () => {
            dispatch(setAgGrid(null));
        };
    }, [gridRef.current]);

    useEffect(() => {
        peopleKsgData?.refetch();
        //eslint-disable-next-line
    }, [titlesArr.length]);

    const arrayPeq = () => {
        return peopleKsgData.data
            ? [emptyWorkKsgPeq(peopleKsgData.data.data[0]?.monthlyCharts.length), ...peopleKsgData.data.data]
            : null;
    };

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params.data.id;
    }, []);

    const deleteWork = useCallback(
        (id: number) => {
            setWarningModal((d) => ({ ...d, openState: false }));
            const error = (e?: any) => {
                console.error(e);
                return enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                    variant: 'error',
                });
            };

            const success = () => {
                return enqueueSnackbar(t('work_deleted'), {
                    variant: 'success',
                });
            };

            deletePeopleReq({ id: Number(projectId), workId: id })
                .unwrap()
                .then(success)
                .then(() => {
                    gridRef.current?.api.applyTransaction({ remove: [{ id: id }] });
                })
                .catch(error);
        },
        [deletePeopleReq, enqueueSnackbar, projectId, t]
    );

    const mainRow = peopleKsgData.data?.main;

    if (!headers.data?.isCreatedKSG) {
        if (headers.isFetching) {
            return <CircularProgress />;
        }
        if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_KSG_STAFF')) {
            return (
                <EmptyPlaceholder
                    text={
                        <Typography>
                            Здравствуйте, {profile?.firstName}, КСГ еще не был создан. <br />
                            Создание КСГ не доступно для пользователей с Вашими правами. <br /> Обратитесь к
                            администратору
                        </Typography>
                    }
                    onClick={() => navigate(-1)}
                    icon={<ArrowBack />}
                    buttonText='Назад'
                />
            );
        }
        return (
            <EmptyPlaceholder
                text={`Здравствуйте, ${profile?.firstName}! Данные для отображения отсутствуют.`}
                onClick={() => dispatch(toggleUploadKsgPeople())}
                icon={<DownloadingSharpIcon />}
                buttonText='загрузить'
            />
        );
    }

    return (
        <>
            <ConfirmationModal
                openState={warningModal.openState}
                onCancel={() => setWarningModal((d) => ({ ...d, openState: false }))}
                onConfirm={() => deleteWork(warningModal.deleteId)}
                title='Вы уверены что хотите удалить работу?'
                content='После удаления работы эти данные будут безвозвратно утеряны.'
            />
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Box sx={modalStyles}>
                    <CheckboxPopoverWorkersMimKsg />
                </Box>
            </Modal>
            <div
                className='ag-theme-alpine'
                style={{ height: '100%', width: '100%', paddingRight: '0.5rem' }}
            >
                <MemoAgGrid
                    ref={gridRef}
                    getRowId={getRowId}
                    rowData={arrayPeq()}
                    columnDefs={getAgGridKSGPeopleColDef({
                        dispatch: dispatch,
                        profile: profile,
                        setWarningModal: setWarningModal,
                        deletePeopleRes: deletePeopleRes,
                        warningModal: warningModal,
                        setOpenModal: setOpenModal,
                        projectId: projectId,
                        mainRow: mainRow,
                        peopleKsgData: peopleKsgData,
                        location: location,
                        headers: headers,
                    })}
                    getRowHeight={(data: RowHeightParams<KsgPeopleI>) => {
                        return data.data?.id === 4353246543 ? 200 : 80;
                    }}
                    getRowClass={getWorkManagmentResourceRowSlyles}
                    {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                    onCellValueChanged={(event) => {
                        submitCellData({
                            request: req.post(`/projects/${projectId}/staffs/${event.data?.id}/update`, {
                                name: event.data.name,
                                category: event.data.category,
                                [event.colDef.field as string]: event.newValue,
                            }),
                            event: event,
                            enqueueSnackbar: enqueueSnackbar,
                            projectID: projectId as string,
                            dispatch: dispatch,
                            translate: tStaffTable,
                        });
                    }}
                    {...(!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_KSG_STAFF_ROW') && {
                        suppressClickEdit: true,
                    })}
                />
            </div>
        </>
    );
}
