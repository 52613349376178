import { api } from '@/api/api';

import { setIntegrationStatus, setIntegrationStatusLoading } from '@/store/slices/integrationStatusSlice';

import {
    IGetCollectionOfFactOfActReq,
    IGetCollectionOfFactOfActResponse,
    IGetIntegrationStatusReq,
    IGetIntegrationStatusResponse,
    IUpdateKSGReq,
    addWorkReqI,
    deleteWorkReq,
    editWorkReq,
    editWorkRes,
    getFiltersRoot,
    getMsgExecutorReq,
    getWorksMtoReqI,
    getWorksMtoResI,
    patchParentWorkReqI,
    uploadExcelReq,
    uploadExcelResV2,
} from './works.types';

export const worksApi = api.injectEndpoints({
    endpoints: (build) => ({
        deleteWork: build.mutation<editWorkRes, deleteWorkReq>({
            query: ({ id, workId }) => ({
                url: `/projects/${id}/works/${workId}/delete`,
                method: 'DELETE',
            }),
        }),

        editWork: build.mutation<editWorkRes, editWorkReq>({
            query: ({ id, workId, body }) => ({
                url: `/projects/${id}/works/${workId}/update`,
                method: 'PATCH',
                body,
            }),
        }),

        patchParentWork: build.mutation<unknown, patchParentWorkReqI>({
            query: ({ id, workId, body }) => ({
                url: `/projects/${id}/works/${workId}/update-parent`,
                method: 'PATCH',
                body,
            }),
        }),

        addWork: build.mutation<editWorkRes, addWorkReqI>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/works/add`,
                method: 'POST',
                body,
            }),
        }),

        getFiltersFirstLevel: build.query<getFiltersRoot, getMsgExecutorReq>({
            query: ({ id }) => ({
                url: `/projects/${id}/works/filter/root`,
                method: 'GET',
            }),
        }),

        getProjectIntegrationStatus: build.query<IGetIntegrationStatusResponse, IGetIntegrationStatusReq>({
            query: ({ id }) => ({
                url: `/projects/${id}/works/get-project-is`,
                method: 'GET',
            }),
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    dispatch(setIntegrationStatusLoading(true));
                    const { data } = await queryFulfilled;
                    dispatch(setIntegrationStatus(data));
                } catch (e) {
                    console.error(e);
                } finally {
                    dispatch(setIntegrationStatusLoading(false));
                }
            },
        }),

        getCollectionOfFactOfAct: build.query<IGetCollectionOfFactOfActResponse, IGetCollectionOfFactOfActReq>({
            query: ({ currentAct }) => ({
                url: `/projects/works/act/${currentAct}/calculate-completion-percentage/`,
                method: 'GET',
            }),
        }),

        uploadExcel: build.mutation<uploadExcelResV2, uploadExcelReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/works/upload`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Headers'],
        }),
        uploadExcelMost: build.mutation<uploadExcelResV2, uploadExcelReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/works/upload-most`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Headers'],
        }),

        getWorksMto: build.mutation<getWorksMtoResI, getWorksMtoReqI>({
            query: ({ projectId, workId, body }) => ({
                url: `/projects/${projectId}/works/${workId}/mto`,
                method: 'POST',
                body,
            }),
        }),

        getFilterNameValuesWorksMto: build.mutation<{ data: string[] }, { projectId?: string; workId?: string }>({
            query: ({ projectId, workId }) => ({
                url: `/projects/${projectId}/mto/filter/works/${workId}/name-by-rd`,
                method: 'POST',
            }),
        }),

        downloadExcelMto: build.mutation<string, { projectId?: string; workId?: string }>({
            query: ({ projectId, workId }) => ({
                url: `/projects/${projectId}/works/${workId}/mto-export`,
                method: 'POST',
                headers: {
                    'content-type': 'text/plain',
                },
                responseHandler: 'text',
            }),
        }),

        updateKSG: build.mutation<uploadExcelResV2, IUpdateKSGReq>({
            query: ({ id, body, variant }) => ({
                url:
                    variant === 'standart'
                        ? `/projects/${id}/works/v4/upload-update`
                        : `/projects/${id}/works/upload-most-update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Headers'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useDeleteWorkMutation,
    useEditWorkMutation,
    useAddWorkMutation,
    useUploadExcelMutation,
    useUploadExcelMostMutation,
    usePatchParentWorkMutation,
    useGetWorksMtoMutation,
    useDownloadExcelMtoMutation,
    useGetFilterNameValuesWorksMtoMutation,
    useUpdateKSGMutation,
    useGetProjectIntegrationStatusQuery,
    useGetCollectionOfFactOfActQuery,
    useLazyGetCollectionOfFactOfActQuery,
} = worksApi;
