import { Close } from '@mui/icons-material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { TabContext, TabList } from '@mui/lab';
import { Button, Checkbox, FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, Tab } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { useGetCompanyNamesQuery } from '@/api/company/company.api';
import { useGetSnzListQuery, useSendSnzToExecutorsMutation } from '@/api/snz/snz.api';
import { getSnzListWork } from '@/api/snz/snz.types';

import { SvgDigger, SvgThreeSquares } from '@/shared/SVG/Svg';

import { toggleSendSnz } from '@/store/slices/drawersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { FlexColumnWrapper } from '../../pages/NewExecutorView/components/components.styles';
import { theme } from '../../styles/theme';
import s from './Snz.module.scss';
import { inputSnzStyles, PeopleMachinesItemProps, tabSnzTerms, WorksContainerProps } from './SnzDrawer.types';

function PeopleMachinesItem(props: PeopleMachinesItemProps) {
    const [showInput, setShowInput] = useState(false);
    const [commentText, setCommentText] = useState('');

    function displayQuantity(d: number) {
        if (d === 1) return 'смена';
        if (d < 5) return 'смены';
        return 'смен';
    }

    return (
        <div className={s.people_equip}>
            <h4>{props.person ? 'Персонал:' : 'Механизмы:'}</h4>
            {props.data?.map((data) => (
                <div
                    className={s.pe_line}
                    key={data.id}
                >
                    <div>
                        <span>{props.person ? <EngineeringIcon /> : <SvgDigger />}</span>
                        <h5>{data.name}</h5>
                    </div>
                    <p>
                        {data.volumeToday} {props.person ? 'чел' : displayQuantity(data.volumeToday)}
                    </p>
                </div>
            ))}
            <div className={s.work_input}>
                {!showInput && commentText.length === 0 ? (
                    <button onClick={() => setShowInput(true)}>ДОБАВИТЬ КОММЕНТАРИЙ</button>
                ) : (
                    <div>
                        {commentText.length === 0 && (
                            <h4 onClick={() => setShowInput(false)}>
                                <Close color='primary' />
                            </h4>
                        )}
                        <OutlinedInput
                            fullWidth
                            multiline
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            sx={{ ...inputSnzStyles }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

// components

function WorksContainer(props: WorksContainerProps) {
    switch (props.tabTerm) {
        case 'works':
            return (
                <div className={s.list}>
                    {props.works?.works.map((v) => (
                        <ItemJsx
                            key={v.workID}
                            v={v}
                        />
                    ))}
                </div>
            );
        case 'people':
            return (
                <div className={s.list}>
                    <div className={s.pe_container}>
                        <PeopleMachinesItem
                            data={props.works?.staff}
                            person
                        />
                    </div>
                </div>
            );

        default:
            return (
                <div className={s.list}>
                    <div className={s.pe_container}>
                        <PeopleMachinesItem data={props.works?.tech} />
                    </div>
                </div>
            );
    }
}

function ItemJsx({ v }: { v: getSnzListWork }) {
    const [showInput, setShowInput] = useState(false);
    const [commentText, setCommentText] = useState('');

    return (
        <FlexColumnWrapper
            p={2}
            borderBottom={`1px solid ${theme.palette.bg.gray}`}
        >
            <div className={s.work_code}>{v.code}</div>
            <div className={s.work_name}>
                {v.workName.length > 37 ? v.workName.substring(0, 38) + '...' : v.workName}
            </div>
            <div className={s.work_line}>
                <div className={s.work_line_icon}>
                    <SvgThreeSquares
                        size={16}
                        fill='gray'
                    />
                    <h4>План на месяц:</h4>
                </div>
                <h5>{v.volumeMonth} тн</h5>
            </div>

            <div className={s.work_line}>
                <div className={s.work_line_icon}>
                    <SvgThreeSquares
                        size={16}
                        fill='gray'
                    />
                    <h4>План:</h4>
                </div>
                <h5>{v.volumeToday} тн</h5>
            </div>
            <div className={s.work_input}>
                {!showInput && commentText.length === 0 ? (
                    <button onClick={() => setShowInput(true)}>ДОБАВИТЬ КОММЕНТАРИЙ</button>
                ) : (
                    <div>
                        {commentText.length === 0 && (
                            <h4 onClick={() => setShowInput(false)}>
                                <Close color='primary' />
                            </h4>
                        )}
                        <OutlinedInput
                            fullWidth
                            multiline
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            sx={{
                                ...inputSnzStyles,
                            }}
                        />
                    </div>
                )}
            </div>
        </FlexColumnWrapper>
    );
}

//   components

// function

function SnzDrawer() {
    const [contractor, setContractor] = useState('');
    const [tabTerm, setTabTerm] = useState<tabSnzTerms>('works');
    const [checked, setChecked] = useImmer({ mail: true, sms: false });

    const { profile } = useTypedSelector(profileSelector);

    const dispatch = useAppDispatch();

    const { projectId } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    const [sendSnzToExecutorsReq] = useSendSnzToExecutorsMutation();
    const companyNames = useGetCompanyNamesQuery({
        companyID: profile?.company?.companyID,
    });
    const snzWorks = useGetSnzListQuery(
        {
            id: Number(projectId),
            contractor: contractor,
        },
        {
            skip: !contractor,
        }
    );

    useEffect(() => {
        setContractor(profile.company.companyName);
    }, [profile.company.companyName, snzWorks.data?.autoSendingSNZ]);

    const handleChange = (event: SelectChangeEvent) => {
        setContractor(event.target.value as string);
    };

    return (
        <div>
            <div className={s.title}>
                <h4>Отправить СНЗ исполнителю</h4>
                <TabContext value={tabTerm}>
                    <TabList
                        sx={{
                            '.MuiButtonBase-root': {
                                fontWeight: 600,
                                fontSize: 14,
                                width: '33%',
                            },
                        }}
                        onChange={(event: SyntheticEvent, newValue: tabSnzTerms) => setTabTerm(() => newValue)}
                        aria-label='lab API tabs example'
                    >
                        <Tab
                            label='Работы'
                            value='works'
                        />
                        <Tab
                            label='Персонал'
                            value='people'
                        />
                        <Tab
                            label='Механизмы'
                            value='equip'
                        />
                    </TabList>
                </TabContext>
            </div>
            <div className={s.container}>
                <WorksContainer
                    contractor={contractor}
                    tabTerm={tabTerm}
                    works={snzWorks.data}
                />

                <div className={s.bottom}>
                    <div className={s.contractor_bar}>
                        <div className={s.contractor_left}>
                            <EngineeringIcon
                                sx={{
                                    color: '#5c6e8c',
                                    height: '1.3rem',
                                    width: '1.3rem',
                                }}
                            />
                            <h4>Подрядчик:</h4>
                        </div>
                        <FormControl fullWidth>
                            <Select
                                sx={{
                                    '.MuiInputBase-input': {
                                        ...inputSnzStyles,
                                    },
                                }}
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={contractor}
                                onChange={handleChange}
                            >
                                {companyNames.data?.data.map((v, index) => (
                                    <MenuItem
                                        key={index}
                                        value={v.name}
                                    >
                                        {v.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className={s.checkboxes}>
                        <div>
                            <Checkbox
                                checked={checked.mail}
                                onChange={(e) =>
                                    setChecked((draft) => {
                                        draft.mail = e.target.checked;
                                    })
                                }
                            />
                            <p>E-mail</p>
                        </div>
                        <div>
                            <Checkbox
                                checked={checked.sms}
                                onChange={(e) =>
                                    setChecked((draft) => {
                                        draft.sms = e.target.checked;
                                    })
                                }
                            />
                            <p>СМС </p>
                        </div>
                    </div>
                    <div className={s.buttons}>
                        <Button
                            onClick={() => {
                                sendSnzToExecutorsReq({
                                    id: Number(projectId),
                                    body: {
                                        email: checked.mail,
                                        sms: checked.sms,
                                        works: snzWorks.data?.works.map((v) => +v.workID) || [],
                                        staff: snzWorks.data?.staff.map((v) => +v.id) || [],
                                        tech: snzWorks.data?.tech.map((v) => +v.id) || [],
                                    },
                                })
                                    .unwrap()
                                    .then(() => {
                                        enqueueSnackbar(t('successfulSend'), {
                                            variant: 'success',
                                        });
                                    });
                                dispatch(toggleSendSnz());
                            }}
                            sx={{
                                width: '9.5rem',
                            }}
                            variant='contained'
                            color='success'
                        >
                            отправить
                        </Button>
                        <Button
                            sx={{
                                width: '9.5rem',
                            }}
                            onClick={() => dispatch(toggleSendSnz())}
                            variant='contained'
                            color='primary'
                        >
                            отменить{' '}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SnzDrawer;
