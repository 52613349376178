import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

const isNullOrUndefined = (value: unknown): value is null | undefined => isNull(value) || isUndefined(value);

interface GetFormatNumberProps {
    value: number | string | null | undefined;
    localization?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    startText?: string;
    endText?: string;
}

function replaceStringNumber(value: string) {
    return Number(value.replace(',', '.').replace(/[^\d.-]/g, ''));
}

export function getFormatNumber({
    value,
    localization = 'ru',
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    startText,
    endText,
}: GetFormatNumberProps) {
    if (isNullOrUndefined(value)) return null;

    const numberFormat = new Intl.NumberFormat(localization, { minimumFractionDigits, maximumFractionDigits });
    const formatStartText = !isNullOrUndefined(startText) ? ` ${startText} ` : '';
    const formatEndText = !isNullOrUndefined(endText) ? ` ${endText}` : '';

    if (typeof value === 'string') {
        return `${formatStartText}${numberFormat.format(replaceStringNumber(value))}${formatEndText}`;
    }

    return `${formatStartText}${numberFormat.format(value)}${formatEndText}`;
}
