import { TextField } from '@mui/material';
import styled from 'styled-components';

export const StyledTextField = styled(TextField).attrs({
    size: 'small',
})`
    background: #f6f7fb;

    .MuiInputBase-input {
        padding: ${(props) => {
            if (props.select) {
                return props.theme.spacing(1, '39px', 1, 2);
            }
            if (props.multiline) {
                return props.theme.spacing(0);
            } else {
                switch (props.size) {
                    case 'small':
                        return `${props.theme.spacing(1, 2)} !important`;
                }
            }
        }};

        textarea {
            padding: 0px !important;
        }

        font-weight: ${(props) => props.theme.typography.body2.fontWeight};
        font-size: ${(props) => props.theme.typography.body2.fontSize};
        line-height: ${(props) => props.theme.typography.body2.lineHeight} !important;
        letter-spacing: ${(props) => props.theme.typography.body2.letterSpacing};
    }

    .MuiInputBase-root {
        padding: ${(props) => {
            if (props.multiline) {
                return props.theme.spacing(1, 2);
            }
        }};
    }

    .MuiInputBase-root:not(.Mui-focused):not(.Mui-error) {
        fieldset {
            border-color: rgba(92, 110, 140, 0.1) !important;
        }
    }
`;
