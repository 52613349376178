import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';

export interface ISetupViewGrid {
    verificationPlan: boolean;
    basicPlan: boolean;
    msgForecast: boolean;
    costDoneMode: boolean;
    msgDailyPercentage: boolean;
}
const initialState: ISetupViewGrid = {
    verificationPlan: false,
    basicPlan: false,
    msgForecast: false,
    costDoneMode: false,
    msgDailyPercentage: false,
};

export const {
    reducer: settingViewAgGridReducer,
    actions: { setSettings },
} = createSlice({
    name: 'settingsAgGrid',
    initialState,
    reducers: {
        setSettings(state, { payload }: PayloadAction<ISetupViewGrid>) {
            state.basicPlan = payload.basicPlan;
            state.msgForecast = payload.msgForecast;
            state.verificationPlan = payload.verificationPlan;
            state.costDoneMode = payload.costDoneMode;
            state.msgDailyPercentage = payload.msgDailyPercentage;
        },
    },
});
export const getSettings = (state: RootState) => state.settings.viewAgGrid;
