import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { monthlyChart } from '@/api/ksg/ksg.types';

import { HtmlTooltip } from '@/shared/components/HtmlTooltip';
import { tooltipHelperNumber } from '@/shared/utils';

import { Ks3Input } from './UpdateKs3';

export default function UpdateKs3Ag({
    charts,
    setMutationsLoading,
    idx,
}: {
    charts: monthlyChart[];
    setMutationsLoading: Dispatch<SetStateAction<boolean>>;

    idx: number;
}) {
    const [isInputMode, setIsInputMode] = useState('');

    const cell = charts[idx];
    return (
        <Fragment>
            {isInputMode !== `${cell?.month}${cell?.year}` ? (
                <div onClick={() => setIsInputMode(`${cell?.month}${cell?.year}`)}>
                    {cell?.kc3 === null ? (
                        '-'
                    ) : (
                        <HtmlTooltip title={tooltipHelperNumber(cell?.kc3)}>
                            <span>
                                <NumericFormat
                                    displayType={'text'}
                                    value={cell?.kc3}
                                    thousandSeparator={' '}
                                    decimalScale={2}
                                    decimalSeparator=','
                                />
                            </span>
                        </HtmlTooltip>
                    )}
                </div>
            ) : (
                <div>
                    <Ks3Input
                        charts={charts}
                        setIsInputMode={setIsInputMode}
                        setMutationsLoading={setMutationsLoading}
                        cell={{
                            month: cell?.month || 10,
                            year: cell?.year || 2012,
                        }}
                    />
                </div>
            )}
        </Fragment>
    );
}
