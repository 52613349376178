import { Stack } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';

import { ResetButton } from '@/components/ui/UIButton';

export const PositionNameCellContainer = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'space-between',
})``;

export const PositionNameButton = styled(ResetButton)`
    color: #d9cf15;
`;

export const OrdinalCellContainer = styled(Stack).attrs({
    alignItems: 'center',
    justifyContent: 'center',
})`
    height: 100%;
    width: 100%;
`;

export const OrdinalCellValue = styled(ResetButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: inherit;
    line-height: inherit;
`;

export const OrdinalCellTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))`
    & .${tooltipClasses.tooltip} {
        padding: 0;
        background-color: transparent;
    }
`;

export const OrdinalCellDeleteButton = styled(ResetButton)`
    background-color: #fff;
    height: 36px;
    width: 36px;
    border-radius: 4px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    color: #f46b6b;
    transition: all 0.2s;

    &:hover {
        background-color: #f46b6b;
        color: #fff;
        transform: scale(1.04);
    }

    &:active {
        background-color: #f46b6b;
        color: #fff;
        transform: scale(0.96);
    }
`;
