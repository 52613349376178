import NiceModal from '@ebay/nice-modal-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import { queryClient } from '@/shared/constants/queryClient';

import { store } from '@/store/store';

import '@/styles/styles.scss';

import { i18n } from '@/i18n';

import { RouterProvider } from './providers/router';
import { SnackbarProvider } from './providers/snackbar';
import { ThemeProvider } from './providers/theme';

export const App: React.FC = () => {
    return (
        // <React.StrictMode>
        <ThemeProvider>
            <I18nextProvider i18n={i18n}>
                <QueryClientProvider client={queryClient}>
                    <Provider store={store}>
                        <SnackbarProvider>
                            <NiceModal.Provider>
                                <div className='app'>
                                    <RouterProvider />
                                </div>
                            </NiceModal.Provider>
                        </SnackbarProvider>
                    </Provider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </I18nextProvider>
        </ThemeProvider>
        // </React.StrictMode>
    );
};
