import { ICellRendererParams } from 'ag-grid-community';

import { dailyChart } from '@/api/msg/msg.types';

import {
    onSubmitRejected,
    onSubmitSuccess,
    parseStringToNumber,
} from '@/components/EditChartCell/EditChartCell.service';
import { IEditChartCellProps, IOnSubmitChartDataArgs } from '@/components/EditChartCell/EditChartCell.types';

import { onEditAgMSG } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { store } from '@/store/store';

import { Nullable } from '@/types/common.types';

import { req } from '../../../WorkManagment/api/api';

export const calculatePercentage = (plan: Nullable<number>, fact: Nullable<number>) => {
    if (!plan) return null;
    if (!fact) return null;

    const result = (fact / plan) * 100;
    return parseFloat(result.toFixed(2));
};

export async function submitDailyChart(args: IOnSubmitChartDataArgs) {
    const { cellRendererParams, formData, projectID, chartIndex, chartValueKey } = args;

    cellRendererParams.eGridCell.style.pointerEvents = 'none';
    await req
        .post(
            `/projects/${projectID}/works/msg/update-charts?year=${cellRendererParams.data?.year}&month=${cellRendererParams.data?.month}`,
            {
                toUpdate: [
                    {
                        charts: [
                            {
                                ...cellRendererParams.api.getValue('dailyCharts', cellRendererParams.node)[
                                    chartIndex as number
                                ],
                                [chartValueKey]: parseStringToNumber(formData[chartValueKey]),
                            },
                        ],
                        workID: cellRendererParams.data?.id,
                    },
                ],
            }
        )
        .then(({ data }) => {
            cellRendererParams.api.applyTransaction({ update: data.data });
            store.dispatch(onEditAgMSG(data.data));
            onSubmitSuccess(args);
        })
        .catch((e) => {
            onSubmitRejected(args);
        });
    cellRendererParams.eGridCell.style.pointerEvents = 'auto';
}

export function getSaldoBetweenDailyChartAndVolumeTotalPerMonth(
    value: number | string | null | undefined,
    cellRendererParams: ICellRendererParams,
    chartValueKey: IEditChartCellProps['chartValueKey']
) {
    const currentValue =
        typeof value == 'string' ? Number(value?.replaceAll(' ', '')?.replaceAll(',', '.')) || 0 : value || 0;
    const volumeTotal = cellRendererParams?.data?.volumeTotal || 0;
    let totalVolumeExcludeEditingDay = 0;
    cellRendererParams?.api.getValue('dailyCharts', cellRendererParams.node).forEach((chart: dailyChart) => {
        if (chart.day != (cellRendererParams.colDef?.headerName as unknown as number)) {
            totalVolumeExcludeEditingDay += (chart[chartValueKey as keyof dailyChart] as null | number) || 0;
        }
    });
    const saldo = Number((totalVolumeExcludeEditingDay + currentValue - volumeTotal).toFixed(6));
    console.log(totalVolumeExcludeEditingDay, currentValue, volumeTotal);
    return isNaN(saldo) ? 0 : saldo;
}
