import { ICellRendererParams, IHeaderParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';

import { UserRolesEn } from '../../../Users/user';
import { COL_DEF_TEMPLATE } from '../../../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { IMimColDef } from '../../../WorkManagment/subPages/Resources/ResourceCard/ColDefs/MimTable.colDef';

function addNewRow(params: IHeaderParams) {
    params.api.applyTransaction({
        add: [
            {
                id: 'draft',
                name: '',
            },
        ],
    });
    setTimeout(() => {
        params.api.startEditingCell({
            rowIndex: params.api.getRowNode('draft')?.rowIndex as number,
            colKey: 'name',
        });
        params.api.setFocusedCell(params.api.getRowNode('draft')?.rowIndex as number, 'name');
        params.api.ensureNodeVisible(params.api.getRowNode('draft'));
    });
}

function removeRow(params: ICellRendererParams) {
    params.api.applyTransaction({ remove: [params.data] });
}

export const mimTableColumnDef = (AgGrid: AgGridReact | null, role: UserRolesEn): IMimColDef[] => {
    const COL_DEF = [
        {
            ...(COL_DEF_TEMPLATE.ADD_NEW_ROW_TABLE_COL(addNewRow) as any),
        },
        {
            headerName: 'Наименование техники',
            field: 'name',
            editable: true,
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
            cellEditorParams: {
                dataType: 'text',
                validation: {
                    validate: {
                        s: (v: string) => {
                            const excludeNames: string[] = [];
                            AgGrid?.api?.forEachNode((row) => excludeNames.push(row?.data?.name));
                            return !excludeNames.includes(v) || 'Уже есть такой ресурс';
                        },
                    },
                },
            },
            headerClass: 'ag-cell-left',
            cellClass: 'ag-cell-left ag-cell-editable',
        },
        {
            ...COL_DEF_TEMPLATE.DELETE_ROW_TABLE_COL((params) => removeRow(params)),
        },
    ];
    if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(role, 'CREATE_BRIGADE')) {
        COL_DEF[0] = {
            ...COL_DEF[0],
            headerName: '',
            headerComponent: undefined,
            cellRenderer: null,
        };
        COL_DEF[2] = {
            ...COL_DEF[2],
            headerName: '',
            headerComponent: undefined,
            cellRenderer: null,
        };
    }
    return COL_DEF;
};
