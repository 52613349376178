import { Button } from '@mui/material';
import { useState } from 'react';

import ConfirmationModal from '@/shared/components/ConfirmationModal/ConfirmationModal';

export default function UploadButtons({
    disableButtons,
    isLoading,
    onClose,
}: {
    disableButtons: boolean;
    isLoading: boolean;
    onClose: () => void;
}) {
    const [warningModal, setWarningModal] = useState(false);

    return (
        <div style={{ width: '100%' }}>
            {disableButtons ? (
                <Button
                    disabled={isLoading}
                    fullWidth
                    sx={{ height: '2.5rem' }}
                    onClick={() => setWarningModal(true)}
                    variant='contained'
                >
                    {isLoading ? 'загрузка' : 'закрыть'}
                </Button>
            ) : (
                <Button
                    fullWidth
                    color='success'
                    sx={{ height: '2.5rem' }}
                    onClick={onClose}
                    variant='contained'
                >
                    готово
                </Button>
            )}

            <ConfirmationModal
                openState={warningModal}
                onCancel={() => setWarningModal(false)}
                onConfirm={onClose}
                title='Подтвердить закрытие формы?'
                content='Форма будет закрыта, а все введенные данные безвозвратно утеряны.'
            />
        </div>
    );
}
