import { TypographyProps } from '@mui/material';

import { OverflowableTypography } from '@/pages/NewExecutorView/components/components.styles';

export function BreadCrumbTitle(props: TypographyProps) {
    return (
        <OverflowableTypography
            rows={1}
            maxWidth={'fit-content'}
            sx={{
                fontSize: '18px',
                fontWeight: 500,
                textAlign: 'left',
            }}
            color='#2b3648'
            variant='body1'
            {...props}
            // noWrap
        >
            {props.children}
        </OverflowableTypography>
    );
}
