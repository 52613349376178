import { Tab } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { executorSelector, setQueryParams } from '@/store/slices/executorSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { TabsContained } from '../../WorkManagment/components/components.styles';
import NewExecutorHeader from '../components/Header/Header';
import { SwitchListTypeTabs } from '../components/SwitchListTypeTabs/SwitchListTypeTabs';
import { FlexColumnWrapper, StyledControlPanel } from '../components/components.styles';

function NewExecutorLayout() {
    let [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const msgSlice = useTypedSelector(executorSelector);
    const location = useLocation();
    const navigate = useNavigate();

    const handleParams = () => {
        const paramsHas = () => {
            if (searchParams.has('token') && searchParams.has('id')) {
                const token = searchParams.get('token');
                const id = searchParams.get('id');
                return {
                    token,
                    id,
                };
            }
        };

        if (paramsHas()?.id && paramsHas()?.token) {
            const id = paramsHas()?.id;
            const token = paramsHas()?.token;
            if (!id || !token) return;

            dispatch(
                setQueryParams({
                    id,
                    token,
                })
            );

            searchParams.delete('id');
            searchParams.delete('token');
            setSearchParams(searchParams);
            navigate('/executor/works?listType=waitList', { replace: true });
        }
    };

    useEffect(() => {
        handleParams();
    }, [searchParams]);

    function getTargetLocation(subPage: 'works' | 'staffs' | 'mims') {
        return `/executor/${subPage}?listType=waitList`;
    }

    return msgSlice.queryParams ? (
        <Fragment>
            <NewExecutorHeader />
            <FlexColumnWrapper
                p={1.5}
                height={'100%'}
            >
                {!location.pathname.includes('resources') && (
                    <StyledControlPanel>
                        <TabsContained
                            value={location.pathname.split('/')[2]}
                            onChange={(e, newValue) => navigate(getTargetLocation(newValue))}
                        >
                            <Tab
                                value='works'
                                label='Работы'
                            />
                            <Tab
                                value='staffs'
                                label='Персонал'
                            />
                            <Tab
                                value='mims'
                                label='Механизмы'
                            />
                        </TabsContained>
                        <SwitchListTypeTabs />
                    </StyledControlPanel>
                )}
                <Outlet />
            </FlexColumnWrapper>
        </Fragment>
    ) : (
        <div>Нет информации для отображения</div>
    );
}

export default NewExecutorLayout;
