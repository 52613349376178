import { Button } from '@mui/material'
import styled, { css } from 'styled-components'
import { IStyledSpreadBtnParams } from "./SpreadPlanBtn.types"

export const StyledSpreadBtn = styled(Button)<IStyledSpreadBtnParams>`
    display: flex;
    align-items: center;
    background-color: ${({ active }) => (active ? '#14b8a6' : 'gray')};
    padding: 2px;
    line-height: 15px;
    text-transform: capitalize;
    border-radius: 0px !important;
    width: 100%;
    grid-column: 1 / -1;

    font-family: var(--font-family);
    font-weight: 500;
    font-size: 10px;
    color: #fff;
    margin-top: auto;

    ${({ active }) =>
        active
            ? css`
                  &:hover {
                      background-color: #14b8a6;
                      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
                  }
              `
            : css`
                  &:hover {
                      background-color: gray;
                      cursor: default;
                  }
              `}
`

StyledSpreadBtn.defaultProps = {
    disableTouchRipple: true,
    disableRipple: true,
    disableFocusRipple: true,
}
