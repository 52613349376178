import { api } from '@/api/api';

import { getHeadersRes } from './ksgKC6A.types';

export const ksgKC6AApi = api.injectEndpoints({
    endpoints: (build) => ({
        getHeadersKC6A: build.query<getHeadersRes, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/works/get-kc-6a/headers`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useGetHeadersKC6AQuery } = ksgKC6AApi;
