import { createBrowserRouter } from 'react-router-dom';

import { ConsolidatedLayout } from '@/layouts/ConsolidatedLayout';
import { ProjectGuardLayout } from '@/layouts/ProjectGuardLayout';
import { RootLayout } from '@/layouts/RootLayout';
import KsgLayout from '@/layouts/TableLayout/KsgLayout';
import MsgLayout from '@/layouts/TableLayout/MsgLayout';

import AgGrid from '@/pages/AgGrid/AgGrid';
import { AgGridKsgKC6A } from '@/pages/AgGridKsgKC6A/AgGridKsgKC6A';
import AgGridKsgMim from '@/pages/AgGridKsgMim/AgGridKsgMim';
import AgGridKsgPeople from '@/pages/AgGridKsgPeople/AgGridKsgPeople';
import AgGridMsg from '@/pages/AgGridMsg/AgGridMsg';
import AgGridMsgEquip from '@/pages/AgGridMsgEquip/AgGridMsgEquip';
import AgGridMsgPeople from '@/pages/AgGridMsgPeople/AgGridMsgPeople';
import { BrigadesPage } from '@/pages/BrigadesPage/BrigadesPage';
import { AddBrigadePage } from '@/pages/BrigadesPage/subPages/AddBrigadePage/AddBrigadePage';
import { ConsolidatedPricesTable } from '@/pages/ConsolidatedPricesTable';
import { EstimatePositionsTable } from '@/pages/EstimatePositionsTable';
import { KSGTable, KSGTableDnd } from '@/pages/KSGTable';
import { MSGPrint } from '@/pages/MSGPrint/MSGPrint';
import { MSGResourcePage } from '@/pages/NewExecutorView/MSGResourcePage/MSGResourcePage';
import { WorkResourcesListPage } from '@/pages/NewExecutorView/WorkResourcesListPage/WorkResourcesListPage';
import { WorksSubPage } from '@/pages/NewExecutorView/WorksSubPage/WorksSubPage';
import NewExecutorLayout from '@/pages/NewExecutorView/layouts/NewExecutorViewLayout';
import Projects from '@/pages/Projects/Projects';
import SocketTest from '@/pages/SocketTest/SocketTest';
import { StructureManagementTable } from '@/pages/StructureManagementTable';
import SufficiencyOfMaterials from '@/pages/SufficiencyOfMaterials/SufficiencyOfMaterials';
import AddUser from '@/pages/Users/AddUser/AddUser';
import EditUser from '@/pages/Users/EditUser/EditUser';
import Users from '@/pages/Users/Users';
import { WorkManagmentLayout } from '@/pages/WorkManagment/layouts/WorkManagmentLayout';
import { EstimatePositionsPage } from '@/pages/WorkManagment/subPages/Estimate/subPages/Positions/EstimatePositionsPage';
import { EstimateResourcesPage } from '@/pages/WorkManagment/subPages/Estimate/subPages/Resources/EstimateResourcesPage';
import { MobilizationSchedulePage } from '@/pages/WorkManagment/subPages/MobilizationSchedule/MobilizationSchedulePage';
import { ResourcesPage } from '@/pages/WorkManagment/subPages/Resources/ResourcesPage';

import { EPageName } from '@/shared/constants/pages';

import { resetFilterTitlesArr } from '@/store/slices/filtersWorkersMimSlice';
import { setMonthTerm } from '@/store/slices/monthMsgSlice';
import { refreshTemplateMsg } from '@/store/slices/msgTemplatesSlice';
import { setPage } from '@/store/slices/paginationSlice';
import { setPageName } from '@/store/slices/projectsSlice';
import { store } from '@/store/store';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        children: [
            { path: 'test/:id', element: <SocketTest /> },
            {
                path: '/',
                children: [
                    {
                        index: true,
                        loader: () => {
                            store.dispatch(setPageName(EPageName.Projects));
                            return null;
                        },
                        element: <Projects />,
                    },
                    {
                        path: 'brigades/:projectId',
                        loader: () => {
                            store.dispatch(setPageName(EPageName.Brigades));
                            return null;
                        },
                        element: <BrigadesPage />,
                    },
                    {
                        path: 'secretPathGanttDHTMLX/:projectId',
                        loader: () => {
                            store.dispatch(setPageName(EPageName.secretGantt));
                            return null;
                        },
                        element: <AgGrid />,
                    },
                    {
                        path: 'brigades/:projectId/add',
                        loader: () => {
                            store.dispatch(setPageName(EPageName.BrigadesAdd));
                            return null;
                        },
                        element: <AddBrigadePage />,
                        index: true,
                    },
                    {
                        path: 'brigades/:projectId/edit/:brigadeID',
                        loader: () => {
                            store.dispatch(setPageName(EPageName.BrigadesEdit));
                            return null;
                        },
                        element: <AddBrigadePage />,
                        index: true,
                    },
                    {
                        path: 'charts',
                        loader: () => {
                            store.dispatch(setPageName(EPageName.ProjectsCharts));
                            return null;
                        },
                        element: <Projects charts />,
                    },
                    {
                        path: 'dnd',
                        element: (
                            <ProjectGuardLayout>
                                <KsgLayout />
                            </ProjectGuardLayout>
                        ),
                        children: [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        index: true,
                                        loader: () => {
                                            store.dispatch(setPageName(EPageName.Ksg));
                                            store.dispatch(
                                                setMonthTerm({
                                                    month: new Date().getMonth(),
                                                    year: new Date().getFullYear(),
                                                })
                                            );
                                            store.dispatch(setPage(1));
                                            return null;
                                        },
                                        element: <KSGTableDnd />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'mgp',
                        element: (
                            <ProjectGuardLayout>
                                <ConsolidatedLayout />
                            </ProjectGuardLayout>
                        ),
                        children: [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        index: true,
                                        loader: () => {
                                            store.dispatch(setPageName(EPageName.Mgp));
                                            return null;
                                        },
                                        element: <EstimatePositionsTable />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'mofen',
                        element: (
                            <ProjectGuardLayout>
                                <ConsolidatedLayout />
                            </ProjectGuardLayout>
                        ),
                        children: [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        index: true,
                                        loader: () => {
                                            store.dispatch(setPageName(EPageName.Mofen));
                                            return null;
                                        },
                                        element: <ConsolidatedPricesTable />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'sm',
                        element: (
                            <ProjectGuardLayout>
                                <ConsolidatedLayout />
                            </ProjectGuardLayout>
                        ),
                        children: [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        index: true,
                                        loader: () => {
                                            store.dispatch(setPageName(EPageName.Sm));
                                            return null;
                                        },
                                        element: <StructureManagementTable />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'ksg',
                        element: (
                            <ProjectGuardLayout>
                                <KsgLayout />
                            </ProjectGuardLayout>
                        ),
                        children: [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        index: true,
                                        loader: () => {
                                            store.dispatch(setPageName(EPageName.Ksg));
                                            store.dispatch(
                                                setMonthTerm({
                                                    month: new Date().getMonth(),
                                                    year: new Date().getFullYear(),
                                                })
                                            );
                                            store.dispatch(setPage(1));
                                            return null;
                                        },
                                        element: <KSGTable />,
                                    },
                                ],
                            },

                            {
                                path: 'workers',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.KsgPeople));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridKsgPeople />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'ag_grid',
                                        children: [
                                            {
                                                path: ':projectId',
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.KsgPeople));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridKsgPeople />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'mim',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.KsgEquip));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridKsgMim />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'ag_grid',
                                        children: [
                                            {
                                                path: ':projectId',
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.KsgEquip));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridKsgMim />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'ag_grid',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.ag_grid));
                                                    return null;
                                                },
                                                element: <AgGrid />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'kc6a',
                                children: [
                                    {
                                        path: ':projectId',
                                        loader: () => {
                                            store.dispatch(setPageName(EPageName.KsgKc6a));
                                            store.dispatch(resetFilterTitlesArr());
                                            return null;
                                        },
                                        element: <AgGridKsgKC6A />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'ksg/:projectId/mto/:workId',
                        children: [
                            {
                                index: true,
                                loader: () => {
                                    store.dispatch(setPageName(EPageName.Mto));
                                    return null;
                                },
                                element: <SufficiencyOfMaterials />,
                            },
                        ],
                    },
                    {
                        path: 'msg',
                        element: (
                            <ProjectGuardLayout>
                                <MsgLayout />
                            </ProjectGuardLayout>
                        ),
                        children: [
                            {
                                path: ':projectId',
                                children: [
                                    {
                                        index: true,
                                        loader: () => {
                                            store.dispatch(setPageName(EPageName.Msg));
                                            store.dispatch(refreshTemplateMsg());
                                            return null;
                                        },
                                        element: <AgGridMsg />,
                                    },
                                ],
                            },
                            {
                                path: 'workers',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.MsgPeople));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridMsgPeople />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'ag_grid',
                                        children: [
                                            {
                                                path: ':projectId',
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.MsgPeople));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridMsgPeople />,
                                            },
                                        ],
                                    },
                                ],
                            },

                            {
                                path: 'mim',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.MsgEquip));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridMsgEquip />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'ag_grid',
                                        children: [
                                            {
                                                path: ':projectId',
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.MsgEquip));
                                                    store.dispatch(resetFilterTitlesArr());
                                                    return null;
                                                },
                                                element: <AgGridMsgEquip />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'ag_grid',
                                children: [
                                    {
                                        path: ':projectId',
                                        children: [
                                            {
                                                index: true,
                                                loader: () => {
                                                    store.dispatch(setPageName(EPageName.ag_grid_msg));
                                                    return null;
                                                },
                                                element: <AgGridMsg />,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'workManagment',
                        children: [
                            {
                                path: ':projectId/:workID',
                                element: <WorkManagmentLayout />,
                                loader: () => {
                                    store.dispatch(setPageName(EPageName.WorkManagement));
                                    // store.dispatch(setPage(1))
                                    return null;
                                },
                                children: [
                                    {
                                        path: 'estimate/resources',
                                        element: <EstimateResourcesPage />,
                                    },
                                    {
                                        path: 'estimate/positions',
                                        element: <EstimatePositionsPage />,
                                    },
                                    {
                                        path: 'resources',
                                        element: <ResourcesPage />,
                                    },
                                    {
                                        path: 'mobilization',
                                        element: <MobilizationSchedulePage />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '*',
                        loader: () => {
                            store.dispatch(setPageName(EPageName.Projects));
                            return null;
                        },
                        element: <Projects />,
                    },
                ],
            },
            {
                path: 'users',
                children: [
                    {
                        index: true,
                        loader: () => {
                            store.dispatch(setPageName(EPageName.Users));
                            return null;
                        },
                        element: <Users />,
                    },
                    {
                        path: 'add',
                        loader: () => {
                            store.dispatch(setPageName(EPageName.UsersAdd));
                            return null;
                        },
                        element: <AddUser />,
                    },
                    {
                        path: 'edit/:userId',
                        loader: () => {
                            store.dispatch(setPageName(EPageName.UsersEdit));
                            return null;
                        },
                        element: <EditUser />,
                    },
                    {
                        path: '*',
                        loader: () => {
                            store.dispatch(setPageName(EPageName.Users));
                            return null;
                        },
                        element: <Users />,
                    },
                ],
            },
            {
                path: '*',
                loader: () => {
                    store.dispatch(setPageName(EPageName.Projects));
                    return null;
                },
                element: <Projects />,
            },
        ],
    },
    {
        path: 'executor',
        element: <NewExecutorLayout />,
        children: [
            {
                path: 'works',
                index: true,
                loader: () => {
                    store.dispatch(setPageName(EPageName.Executor));
                    return null;
                },
                element: <WorksSubPage />,
            },
            {
                index: true,
                path: 'resources/:projectID/:workID*',
                element: <WorkResourcesListPage />,
            },
            {
                path: 'staffs',
                loader: () => {
                    store.dispatch(setPageName(EPageName.ExecutorPeople));
                    return null;
                },
                element: (
                    <MSGResourcePage
                        key='staffs'
                        resourceType='staffs'
                    />
                ),
            },
            {
                path: 'mims',
                loader: () => {
                    store.dispatch(setPageName(EPageName.ExecutorEquip));
                    return null;
                },
                element: (
                    <MSGResourcePage
                        key='mims'
                        resourceType='technics'
                    />
                ),
            },
        ],
    },
    {
        path: 'msg/:projectId/print',
        loader: () => {
            store.dispatch(setPageName(EPageName.MsgPrint));
            return null;
        },
        element: <MSGPrint />,
    },
]);
