import { useQuery } from '@tanstack/react-query';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useSpinDelay } from 'spin-delay';

import { getStructureListQueryOptions } from '@/api/structure';

import { TData } from '../StructureManagementTable.types';
import { filterRowDataByCollapseRowIds, generateRowId } from '../utils';
import { useCollapse } from './useCollapse';

export const useRowData = (projectId: number, useCollapseResult: ReturnType<typeof useCollapse>) => {
    const {
        data: structureList,
        isLoading: isDataLoading,
        isSuccess,
    } = useQuery({
        ...getStructureListQueryOptions({ projectId: Number(projectId) }),
    });
    const isLoading = useSpinDelay(isDataLoading, { delay: 0, minDuration: 1000 });

    useLayoutEffect(() => {
        if (!isSuccess) return;
        useCollapseResult.addCollapsedRowIds(
            structureList.data.filter((v) => v.rowType === 'b_consolidated').map((v) => generateRowId(v))
        );
    }, [isSuccess]);

    const rowData = useMemo(() => {
        const rawData = structureList?.data ?? [];
        return filterRowDataByCollapseRowIds(rawData, useCollapseResult.collapsedRowIds);
    }, [structureList, useCollapseResult.collapsedRowIds]);

    return {
        rowData,
        isLoading,
    };
};

export const useRefreshCellsByRowData = (rowData: TData[], gridRef: React.RefObject<AgGridReact<TData>>) => {
    useEffect(() => {
        if (!rowData) return;
        if (!rowData.length) return;
        if (!gridRef.current) return;
        if (!gridRef.current.api) return;

        setTimeout(() => {
            gridRef.current?.api.refreshCells({
                force: true,
            });
        });
    }, [rowData]);
};
