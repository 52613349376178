import { queryOptions, useMutation } from '@tanstack/react-query';

import { queryKeys } from '@/shared/constants/queryKeys';

import { TCustomHookMutationOptions } from '@/types/query.types';

import { estimatePositionsApi } from './estimatePositions.api';
import {
    TAckEstimatePositionsChangesRequest,
    TAckEstimatePositionsChangesResponse,
    TDeleteByIdRequest,
    TDeleteByIdResponse,
    TEstimatePositionsRequest,
    TFilterEstimatePositionsRequest,
    TRefreshEstimatePositionsRequest,
    TRefreshEstimatePositionsResponse,
    TTabCountersRequest,
} from "./estimatePositions.types";

export const useAckEstimatePositionsChanges = (
    options?: TCustomHookMutationOptions<TAckEstimatePositionsChangesResponse, TAckEstimatePositionsChangesRequest>
) =>
    useMutation({
        mutationFn: (payload) => estimatePositionsApi.ackChanges(payload),
        ...options,
    });

export const useRefreshEstimatePositions = (
    options?: TCustomHookMutationOptions<TRefreshEstimatePositionsResponse, TRefreshEstimatePositionsRequest>
) =>
    useMutation({
        mutationFn: (payload) => estimatePositionsApi.refreshFromOffer(payload),
        ...options,
    });

export const getEstimatePositionsQueryOptions = (props: TEstimatePositionsRequest) =>
    queryOptions({
        queryKey: [queryKeys.estimatePositions.list, props.projectId, props.listType, props.body],
        queryFn: () => estimatePositionsApi.fetchList(props),
    });

export const getEstimatePositionsTabCountersQueryOptions = (props: TTabCountersRequest) =>
    queryOptions({
        queryKey: [queryKeys.estimatePositions.tabCounters, props.projectId, props.body],
        queryFn: () => estimatePositionsApi.fetchTabCounters(props),
    });

export const getEstimatePositionsFilterQueryOptions = (props: TFilterEstimatePositionsRequest) =>
    queryOptions({
        queryKey: [queryKeys.estimatePositions.filter, props.projectId, props.column],
        queryFn: () => estimatePositionsApi.fetchFilters(props),
    });

export const useDeleteEstimatePositionById = (
    options?: TCustomHookMutationOptions<TDeleteByIdResponse, TDeleteByIdRequest>
) =>
    useMutation({
        mutationFn: (payload) => estimatePositionsApi.deleteById(payload),
        ...options,
    });
