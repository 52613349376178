import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '@/types/common.types';

import { IIntegrationStatus } from '../../api/works/works.types';
import { RootState } from '../store';

interface IInitialState {
    value: Nullable<IIntegrationStatus>;
    isLoading: boolean;
}

const initialState: IInitialState = {
    value: null,
    isLoading: false,
};

const slice = createSlice({
    name: 'integrationStatus',
    initialState,
    reducers: {
        setIntegrationStatus(state, action: PayloadAction<Nullable<IIntegrationStatus>>) {
            state.value = action.payload;
        },
        setIntegrationStatusLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
});

export const { setIntegrationStatus, setIntegrationStatusLoading } = slice.actions;
export default slice.reducer;

export const integrationStatusSelector = (state: RootState) => state.integrationStatus.value;
export const integrationStatusLoadingSelector = (state: RootState) => state.integrationStatus.isLoading;
