import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { RefObject, useEffect, useRef, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { cpgViewSelector } from '@/store/slices/cpgViewSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { useDidMountEffect } from '../../hooks/useDidMountEffect';
import { NumberCellRenderer } from '../../pages/WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer';
import { ControllerInputWrapper, StyledTooltip } from '../../pages/WorkManagment/components/components.styles';
import { parseStringToNumber } from './EditChartCell.service';
import { StyledCellWrapper, StyledNumericFormat } from './EditChartCell.styles';
import { IEditChartCellProps } from './EditChartCell.types';

export function EditChartCell({
    cellRendererParams,
    chartValueKey,
    chartIndex,
    isEditable,
    defaultValue,
    rules,
    viewComponent,
    onSubmitData,
    NumberCellRendererProps,
    TypographyProps,
    TooltipProps,
    Dialog,
    convertInputValue,
    isActive,
}: IEditChartCellProps) {
    const methods = useForm({
        mode: 'all',
    });

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const { projectId } = useParams();
    const { t } = useTranslation('chartKeys');
    const { enqueueSnackbar } = useSnackbar();
    const formRef = useRef() as RefObject<HTMLFormElement>;
    const { cpgView } = useTypedSelector(cpgViewSelector);
    const dispatch = useAppDispatch();
    const { profile } = useTypedSelector(profileSelector);

    const targetDefaultValue = convertInputValue ? convertInputValue(defaultValue) : defaultValue;

    useDidMountEffect(() => {
        inputRef && isEditMode && inputRef?.focus();
        inputRef && isEditMode && inputRef?.select();
    }, [inputRef]);

    useEffect(() => {
        !isFetching &&
            methods.reset({
                [chartValueKey]: targetDefaultValue,
            });
    }, [cpgView, isFetching, defaultValue]);

    function onSubmit(formData: FieldValues) {
        if (formData[chartValueKey] === '') {
            formData[chartValueKey] = null;
        }
        // Здесь специально нестрогое сравнение
        if (parseStringToNumber(formData[chartValueKey]) == defaultValue) {
            setIsEditMode(false);
            return;
        }
        setIsFetching(true);
        onSubmitData &&
            onSubmitData({
                cellRendererParams: cellRendererParams,
                formData: formData,
                dispatch: dispatch,
                chartValueKey: chartValueKey,
                setIsFetching: setIsFetching,
                setIsEditMode: setIsEditMode,
                enqueueSnackbar: enqueueSnackbar,
                methods: methods,
                defaultValue: defaultValue,
                translate: t,
                chartIndex: chartIndex,
                projectID: projectId,
                profile: profile,
            });
    }

    return (
        <>
            <StyledCellWrapper
                isEditable={isEditable}
                isActive={isActive}
                onClick={(e) => {
                    !isFetching && isEditable && setIsEditMode(true);
                }}
                sx={{ background: cellRendererParams.data?.hasChildren && 'inherit !important' }}
            >
                {!isEditMode && !isFetching && viewComponent}
                {!isEditMode && !isFetching && (
                    <ControllerInputWrapper error={false}>
                        <NumberCellRenderer
                            value={defaultValue === null ? '-' : defaultValue}
                            NumericFormatProps={{
                                ...(NumberCellRendererProps && NumberCellRendererProps(defaultValue)),
                            }}
                            TypographyProps={{ ...(TypographyProps && TypographyProps(defaultValue)) }}
                        ></NumberCellRenderer>
                    </ControllerInputWrapper>
                )}

                {isFetching && (
                    <CircularProgress
                        size={16}
                        color={'primary'}
                        sx={{ mx: 'auto' }}
                    />
                )}
                {!isFetching && isEditable && isEditMode && (
                    <form
                        ref={formRef}
                        onSubmit={methods.handleSubmit(onSubmit, () => {
                            methods.reset({
                                [chartValueKey]: defaultValue || 0,
                            });
                            setIsEditMode(false);
                        })}
                    >
                        <Controller
                            name={chartValueKey}
                            control={methods.control}
                            defaultValue={targetDefaultValue}
                            rules={{
                                max: {
                                    value: 999999.999999,
                                    message: 'Макс. 999 999,999999',
                                },
                                ...rules,
                            }}
                            render={({ field }) => {
                                const isInvalid = !!methods.formState.errors[chartValueKey];
                                const errorMessage =
                                    (methods.formState.errors[chartValueKey]?.message &&
                                        methods.formState.errors[chartValueKey]?.message?.toString()) ||
                                    '';
                                return (
                                    <StyledTooltip
                                        title={errorMessage || (TooltipProps && TooltipProps(field.value).title) || ''}
                                        open={true}
                                        color={errorMessage && 'error'}
                                        placement='top'
                                        PopperProps={{
                                            anchorEl: formRef.current?.parentElement?.parentElement?.parentElement,
                                        }}
                                    >
                                        <ControllerInputWrapper error={isInvalid}>
                                            <StyledNumericFormat
                                                {...field}
                                                displayType={isEditMode ? 'input' : 'text'}
                                                onBlur={() => {
                                                    setIsEditMode(false);
                                                    formRef?.current?.dispatchEvent(
                                                        new Event('submit', { cancelable: true, bubbles: true })
                                                    );
                                                }}
                                                getInputRef={(ref: HTMLInputElement) => {
                                                    ref && setInputRef(ref);
                                                    return ref;
                                                }}
                                                decimalScale={isEditMode ? 6 : 2}
                                                allowNegative={false}
                                                allowedDecimalSeparators={[',', '.']}
                                                decimalSeparator=','
                                                onKeyDownCapture={(event: any) => {
                                                    event.ctrlKey && event.keyCode === 65 && event.target?.select();
                                                    if (event.key === 'Enter') {
                                                        formRef?.current?.dispatchEvent(
                                                            new Event('submit', { cancelable: true, bubbles: true })
                                                        );
                                                    }
                                                    if (event.key === 'Escape') {
                                                        methods.reset({
                                                            [chartValueKey]: defaultValue || 0,
                                                        });
                                                        setIsEditMode(false);
                                                    }
                                                    event.stopPropagation();
                                                }}
                                            />
                                        </ControllerInputWrapper>
                                    </StyledTooltip>
                                );
                            }}
                        />
                    </form>
                )}
            </StyledCellWrapper>
            {Dialog &&
                Dialog({
                    cellRendererParams: cellRendererParams,
                    formData: {
                        [chartValueKey]: methods.getValues([chartValueKey]),
                    },
                    dispatch: dispatch,
                    chartValueKey: chartValueKey,
                    setIsFetching: setIsFetching,
                    setIsEditMode: setIsEditMode,
                    enqueueSnackbar: enqueueSnackbar,
                    methods: methods,
                    defaultValue: defaultValue,
                    translate: t,
                    chartIndex: chartIndex,
                    projectID: projectId,
                })}
        </>
    );
}
