import { ArrowBackIos } from '@mui/icons-material';
import { Popover } from '@mui/material';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { svgMainLogo } from '@/shared/SVG/Svg';

import { getProfileData } from '@/store/slices/executorSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { StyledBtn } from '../../../WorkManagment/components/components.styles';
import { ProfileInfo } from '../ProfileInfo/ProfileInfo';
import { Timer } from '../Timer/Timer';
import { CompanyName, FlexColumnWrapper, FlexRowWrapper, StyledHeader } from '../components.styles';

export default function NewExecutorHeader() {
    const dispatch = useAppDispatch();
    const { profile } = useTypedSelector(profileSelector);

    const location = useLocation();
    const navigate = useNavigate();

    const [openPopover, setOpenPopover] = useState<boolean>(false);

    const profileWrapperRef = useRef(null);

    useEffect(() => {
        dispatch(getProfileData());
    }, [dispatch]);

    return (
        <StyledHeader>
            <FlexRowWrapper>
                <FlexRowWrapper>{svgMainLogo}</FlexRowWrapper>
                {location.pathname.includes('resources') && (
                    <StyledBtn
                        onClick={() => navigate(`/executor/works?listType=waitList`)}
                        startIcon={<ArrowBackIos />}
                        variant='text'
                        sx={{
                            px: 2,
                        }}
                    >
                        Назад к списку работ
                    </StyledBtn>
                )}
            </FlexRowWrapper>
            <Timer />
            <FlexRowWrapper
                ref={profileWrapperRef}
                onClick={() => setOpenPopover((prevState) => !prevState)}
                sx={{
                    cursor: 'pointer',
                    position: 'relative',
                }}
                maxWidth={400}
                justifyContent={'flex-end'}
            >
                <ProfileInfo profile={profile} />
                <IoIosArrowDown
                    fontSize={'large'}
                    style={{ minWidth: 24 }}
                />
            </FlexRowWrapper>
            <Popover
                open={openPopover}
                anchorEl={profileWrapperRef.current}
                onClose={() => setOpenPopover(() => false)}
                disablePortal
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                PaperProps={{
                    sx: {
                        mt: 2,
                    },
                }}
            >
                <FlexColumnWrapper
                    p={2}
                    minWidth={250}
                    maxWidth={400}
                    minHeight={150}
                >
                    <CompanyName>{profile?.company?.companyName}</CompanyName>

                    <ProfileInfo
                        profile={profile}
                        withUserCompanyName
                        AvatarProps={{
                            sx: {
                                borderRadius: 2,
                                width: 65,
                                height: 65,
                            },
                        }}
                    />
                </FlexColumnWrapper>
            </Popover>
        </StyledHeader>
    );
}
