import { Box } from '@mui/material';
import React from 'react';

import { useToggleSearchParam } from '@/hooks/useToggleSearchParam';

import IntegrationOfferSVG from '@/shared/SVG/IntegrationOfferSVG';
import { HtmlTooltip } from '@/shared/components/HtmlTooltip';
import { IntegrationWithOfferSearchParam } from '@/shared/constants/integrationWithOffer';

import { StyledIconBtn } from '../../styles';
import { ISwitchProps } from './Switch.types';

export const Switch: React.FC<ISwitchProps> = () => {
    const { isActive, onToggle } = useToggleSearchParam(IntegrationWithOfferSearchParam);

    return (
        <HtmlTooltip title={''}>
            <Box>
                <StyledIconBtn
                    onClick={onToggle}
                    isActive={isActive}
                >
                    <IntegrationOfferSVG isActive={isActive} />
                </StyledIconBtn>
            </Box>
        </HtmlTooltip>
    );
};
