import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TKC6AWork } from '@/pages/AgGridKsgKC6A/AgGridKsgKC6A.types';

import { deployLinks } from '@/shared/config/config';
import { axiosRefetchToken } from '@/shared/utils';

import { RootState } from '../../../store';

interface IGetParamsKC6A {
    id: number;
    limit?: number;
    offset?: number;
}

export const getWorksKC6A = createAsyncThunk<
    { data: TKC6AWork[]; total: number },
    IGetParamsKC6A,
    {
        state: RootState;
    }
>('ag_grid_ksg_kc6a/worksList', async (paramsData, { getState, rejectWithValue }) => {
    try {
        const { id, ...params } = paramsData;
        const { data } = await axios<{ data: TKC6AWork[]; total: number }>({
            method: 'POST',
            url: deployLinks.server + `/projects/${id}/works/get-kc-6a`,
            headers: {
                Authorization: `Bearer ${getState().auth.token}`,
            },
            params,
            data: { filter: getState().project.headerSearch.toLowerCase(), ...getState().filters.filters },
        });
        let returnPayload = { ...data };
        if (params.offset !== 0) {
            const stateData = getState().ag_grid_ksg.kc6a.worksList;
            returnPayload = {
                ...returnPayload,
                data: [...(stateData ? stateData.data : []), ...data.data],
            };
        }
        return returnPayload;
    } catch (e: any) {
        console.error(e);
        axiosRefetchToken(e?.response?.status);
        return rejectWithValue(e);
    }
});
