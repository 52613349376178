import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { templateMsgBody } from "../../api/templates/templates.types"
import { fixShow } from './cpgViewSlice'

export type initMsgTemplateStateType = typeof initMsgTemplateState

const columnTerm = {
    fix: false,
    show: true,
}

export const initMsgTemplateState = {
    name: '',
    level: columnTerm,
    code: columnTerm,
    objTitle: columnTerm,
    objName: columnTerm,
    rdGroup: columnTerm,
    numOrder: columnTerm,
    rdCode: columnTerm,
    rdStatus: columnTerm,
    rdDateApproval: columnTerm,
    rdVersion: columnTerm,
    rdDate: columnTerm,
    workGroup: columnTerm,
    contractorCompany: columnTerm,
    executorName: columnTerm,
    workName: columnTerm,
    unit: columnTerm,
    volumeTotal: columnTerm,
    volumeDone: columnTerm,
    basePlan: columnTerm,
    startDate: columnTerm,
    endDate: columnTerm,
    operPlan: columnTerm,
    operationalStartDate: columnTerm,
    operationalEndDate: columnTerm,
    humanHourCost: columnTerm,
    humanHoursTotal: columnTerm,
    humanHoursProgressTZ: columnTerm,
    humanHoursProgress: columnTerm,
    humanHoursProgressPlan: columnTerm,
    humanHoursProgressFact: columnTerm,
    humanHoursProgressForecast: columnTerm,
    humanHoursProgressPercent: columnTerm,
    costER: columnTerm,
    costTotal: columnTerm,
    costRemaining: columnTerm,
    volumeDoneFactKsg: columnTerm,
    volumeTotalKsg: columnTerm,
    volumeDonePlan: columnTerm,
    volumeDoneForecast: columnTerm,
    volumeDoneFact: columnTerm,
}

interface initialStateI {
    templateMsgState: initMsgTemplateStateType
    templateMsgStateView: initMsgTemplateStateType
}

const initialState: initialStateI = {
    templateMsgState: initMsgTemplateState,
    templateMsgStateView: initMsgTemplateState,
}

const slice = createSlice({
    name: 'msgTemplates',
    initialState,
    reducers: {
        setTemplateMsgState(state, action: PayloadAction<templateMsgBody | null>) {
            const terms = action.payload
            if (!terms) return
            for (let k in state.templateMsgState) {
                if (k === 'executorName' || k === 'volumeDone' || k === 'humanHoursProgress') {
                    state.templateMsgState.executorName = terms.executor

                    state.templateMsgState.volumeDoneFact = terms.volumeDoneFact
                    state.templateMsgState.volumeDonePlan = terms.volumeDonePlan
                    state.templateMsgState.volumeDoneForecast = terms.volumeDoneForecast

                    state.templateMsgState.humanHoursProgress = terms.humanHoursProgressFact
                }
                else if (k === 'name') {
                    state.templateMsgState.name = terms.name
                }
                else if (k === 'basePlan') {
                    state.templateMsgState.basePlan = {
                        show: terms.startDate.show || terms.endDate.show,
                        fix: terms.startDate.fix || terms.endDate.fix
                    }
                    state.templateMsgState.startDate = terms.startDate;
                    state.templateMsgState.endDate = terms.endDate;
                }
                else if (k === 'operPlan') {
                    state.templateMsgState.operPlan = {
                        show: terms.operationalStartDate.show || terms.operationalEndDate.show,
                        fix: terms.operationalStartDate.fix || terms.operationalEndDate.fix
                    }
                    state.templateMsgState.operationalStartDate = terms.operationalStartDate;
                    state.templateMsgState.operationalEndDate = terms.operationalEndDate;
                }
                else {
                    state.templateMsgState[k as keyof Omit<initMsgTemplateStateType, 'name'>] =
                        terms[
                        k as keyof Omit<
                            initMsgTemplateStateType,
                            'executorName' | 'volumeDone' | 'humanHoursProgress' | 'name' | 'basePlan' | 'operPlan'
                        >
                        ]
                }
            }
            if (!terms.volumeDonePlan.show && !terms.volumeDoneFact.show && !terms.volumeDoneForecast.show) {
                state.templateMsgState.volumeDone = {
                    fix: terms.volumeDonePlan.fix || terms.volumeDoneFact.fix || terms.volumeDoneForecast.fix || false,
                    show: false
                }
            }
            if (terms.volumeDonePlan.show || terms.volumeDoneFact.show || terms.volumeDoneForecast.show) {
                state.templateMsgState.volumeDone = {
                    fix: terms.volumeDonePlan.fix || terms.volumeDoneFact.fix || terms.volumeDoneForecast.fix || false,
                    show: true
                }
            }

            if (!terms.humanHoursProgressPlan.show && !terms.humanHoursProgressPercent.show && !terms.humanHoursProgressFact.show && !terms.humanHoursProgressForecast.show) {
                state.templateMsgState.humanHoursProgress = {
                    fix: terms.humanHoursProgressPlan.fix || terms.humanHoursProgressPercent.fix || terms.humanHoursProgressFact.fix || terms.humanHoursProgressForecast.fix || false,
                    show: false
                }
            }
            if (terms.humanHoursProgressPlan.show || terms.humanHoursProgressPercent.show || terms.humanHoursProgressFact.show || terms.humanHoursProgressForecast.show) {
                state.templateMsgState.humanHoursProgress = {
                    fix: terms.humanHoursProgressPlan.fix || terms.humanHoursProgressPercent.fix || terms.humanHoursProgressFact.fix || terms.humanHoursProgressForecast.fix || false,
                    show: true
                }
            }
            if (!terms.startDate.show && !terms.endDate.show) {
                state.templateMsgState.basePlan = {
                    fix: terms.startDate.fix || terms.endDate.fix || false,
                    show: false
                }
            }
            if (terms.startDate.show || terms.endDate.show) {
                state.templateMsgState.basePlan = {
                    fix: terms.startDate.fix || terms.endDate.fix || false,
                    show: true
                }
            }

            if (!terms.operationalStartDate.show && !terms.operationalEndDate.show) {
                state.templateMsgState.operPlan = {
                    fix: terms.operationalStartDate.fix || terms.operationalEndDate.fix || false,
                    show: false
                }
            }
            if (terms.operationalStartDate.show || terms.operationalEndDate.show) {
                state.templateMsgState.operPlan = {
                    fix: terms.operationalStartDate.fix || terms.operationalEndDate.fix || false,
                    show: true
                }
            }

        },
        checkTemplateMsgItem: {
            reducer: (
                state,
                action: PayloadAction<{
                    term: keyof Omit<initMsgTemplateStateType, 'name'>
                    checked: boolean
                    type: fixShow
                }>
            ) => {
                const { term, checked, type } = action.payload
                if (type === 'show') {
                    state.templateMsgState[term as keyof Omit<initMsgTemplateStateType, 'name'>].show = checked
                    if (!checked) {
                        state.templateMsgState[term as keyof Omit<initMsgTemplateStateType, 'name'>].fix = false
                    }
                } else {
                    if (state.templateMsgState[term as keyof Omit<initMsgTemplateStateType, 'name'>].show === true) {
                        state.templateMsgState[term as keyof Omit<initMsgTemplateStateType, 'name'>].fix = checked
                        if (term === 'volumeDone') {
                            state.templateMsgState.volumeDoneFact.fix = checked;
                            state.templateMsgState.volumeDonePlan.fix = checked;
                            state.templateMsgState.volumeDoneForecast.fix = checked;
                        }
                        if (term === 'basePlan') {
                            state.templateMsgState.startDate.fix = checked;
                            state.templateMsgState.endDate.fix = checked;
                        }
                        if (term === 'operPlan') {
                            state.templateMsgState.operationalStartDate.fix = checked;
                            state.templateMsgState.operationalEndDate.fix = checked;
                        }
                    }
                }
            },
            prepare: (term: keyof Omit<initMsgTemplateStateType, 'name'>, checked: boolean, type: fixShow) => {
                return { payload: { term, checked, type } }
            },
        },

        setTemplateMsgStateView(state, action: PayloadAction<templateMsgBody | null>) {
            const terms = action.payload
            if (!terms) return
            for (let k in state.templateMsgStateView) {
                if (k === 'executorName' || k === 'volumeDone' || k === 'humanHoursProgress' || k === 'basePlan' || k === 'operPlan') {
                    state.templateMsgStateView.executorName = terms.executor

                    state.templateMsgStateView.volumeDoneFact = terms.volumeDoneFact
                    state.templateMsgStateView.volumeDonePlan = terms.volumeDonePlan
                    state.templateMsgStateView.volumeDoneForecast = terms.volumeDoneForecast

                    state.templateMsgStateView.humanHoursProgress = {
                        show: terms.humanHoursProgressPlan.show || terms.humanHoursProgressPercent.show || terms.humanHoursProgressFact.show || terms.humanHoursProgressForecast.show,
                        fix: terms.humanHoursProgressPlan.fix || terms.humanHoursProgressPercent.fix || terms.humanHoursProgressFact.fix || terms.humanHoursProgressForecast.fix
                    }
                    state.templateMsgStateView.humanHoursProgressPlan = terms.humanHoursProgressPlan
                    state.templateMsgStateView.humanHoursProgressPercent = terms.humanHoursProgressPercent
                    state.templateMsgStateView.humanHoursProgressFact = terms.humanHoursProgressFact
                    state.templateMsgStateView.humanHoursProgressForecast = terms.humanHoursProgressForecast


                    state.templateMsgState.basePlan = {
                        show: terms.startDate.show || terms.endDate.show,
                        fix: terms.startDate.fix || terms.endDate.fix
                    }
                    state.templateMsgState.startDate = terms.startDate;
                    state.templateMsgState.endDate = terms.endDate;

                    state.templateMsgState.operPlan = {
                        show: terms.operationalStartDate.show || terms.operationalEndDate.show,
                        fix: terms.operationalStartDate.fix || terms.operationalEndDate.fix
                    }
                    state.templateMsgState.operationalStartDate = terms.operationalStartDate;
                    state.templateMsgState.operationalEndDate = terms.operationalEndDate;


                } else if (k === 'name') {
                    state.templateMsgStateView.name = terms.name
                } else {
                    state.templateMsgStateView[k as keyof Omit<initMsgTemplateStateType, 'name'>] =
                        terms[
                        k as keyof Omit<
                            initMsgTemplateStateType,
                            'executorName' | 'volumeDone' | 'humanHoursProgress' | 'name' | 'basePlan' | 'operPlan'
                        >
                        ]
                }
            }
            if (!terms.volumeDonePlan.show && !terms.volumeDoneFact.show && !terms.volumeDoneForecast.show) {
                state.templateMsgStateView.volumeDone = {
                    fix: terms.volumeDonePlan.fix || terms.volumeDoneFact.fix || terms.volumeDoneForecast.fix || false,
                    show: false
                }
            }
            if (terms.volumeDonePlan.show || terms.volumeDoneFact.show || terms.volumeDoneForecast.show) {
                state.templateMsgStateView.volumeDone = {
                    fix: terms.volumeDonePlan.fix || terms.volumeDoneFact.fix || terms.volumeDoneForecast.fix || false,
                    show: true
                }
            }

            if (!terms.humanHoursProgressPlan.show && !terms.humanHoursProgressPercent.show && !terms.humanHoursProgressFact.show && !terms.humanHoursProgressForecast.show) {
                state.templateMsgStateView.humanHoursProgress = {
                    fix: terms.humanHoursProgressPlan.fix || terms.humanHoursProgressPercent.fix || terms.humanHoursProgressFact.fix || terms.humanHoursProgressForecast.fix || false,
                    show: false
                }
            }
            if (terms.humanHoursProgressPlan.show || terms.humanHoursProgressPercent.show || terms.humanHoursProgressFact.show || terms.humanHoursProgressForecast.show) {
                state.templateMsgStateView.humanHoursProgress = {
                    fix: terms.humanHoursProgressPlan.show || terms.humanHoursProgressPercent.show || terms.humanHoursProgressFact.fix || terms.humanHoursProgressForecast.fix || false,
                    show: true
                }
            }

            if (!terms.startDate.show && !terms.endDate.show) {
                state.templateMsgStateView.basePlan = {
                    fix: terms.startDate.fix || terms.endDate.fix || false,
                    show: false
                }
            }
            if (terms.startDate.show || terms.endDate.show) {
                state.templateMsgStateView.basePlan = {
                    fix: terms.startDate.fix || terms.endDate.fix || false,
                    show: true
                }
            }

            if (!terms.operationalStartDate.show && !terms.operationalEndDate.show) {
                state.templateMsgStateView.operPlan = {
                    fix: terms.operationalStartDate.fix || terms.operationalEndDate.fix || false,
                    show: false
                }
            }
            if (terms.operationalStartDate.show || terms.operationalEndDate.show) {
                state.templateMsgStateView.operPlan = {
                    fix: terms.operationalStartDate.fix || terms.operationalEndDate.fix || false,
                    show: true
                }
            }

        },

        checkTemplateMsgItemView: {
            reducer: (
                state,
                action: PayloadAction<{
                    term: keyof Omit<initMsgTemplateStateType, 'name'>
                    checked: boolean
                    type: fixShow
                }>
            ) => {
                const { term, checked, type } = action.payload
                if (type === 'show') {
                    state.templateMsgStateView[term as keyof Omit<initMsgTemplateStateType, 'name'>].show = checked
                    if (term === 'volumeDone') {
                        state.templateMsgStateView.volumeDonePlan.show = checked;
                        state.templateMsgStateView.volumeDoneFact.show = checked;
                        state.templateMsgStateView.volumeDoneForecast.show = checked;
                    }
                    if (term === 'humanHoursProgress') {
                        state.templateMsgStateView.humanHoursProgressPlan.show = checked
                        state.templateMsgStateView.humanHoursProgressPercent.show = checked
                        state.templateMsgStateView.humanHoursProgressFact.show = checked
                        state.templateMsgStateView.humanHoursProgressForecast.show = checked
                    }
                    if (term === 'basePlan') {
                        state.templateMsgStateView.startDate.show = checked
                        state.templateMsgStateView.endDate.show = checked
                    }
                    if (term === 'operPlan') {
                        state.templateMsgStateView.operationalStartDate.show = checked
                        state.templateMsgStateView.operationalEndDate.show = checked
                    }
                    if (!checked) {
                        state.templateMsgStateView[term as keyof Omit<initMsgTemplateStateType, 'name'>].fix = false
                        if (term === 'volumeDone') {
                            state.templateMsgStateView.volumeDonePlan.show = checked;
                            state.templateMsgStateView.volumeDoneFact.show = checked;
                            state.templateMsgStateView.volumeDoneForecast.show = checked;
                        }
                        if (term === 'humanHoursProgress') {
                            state.templateMsgStateView.humanHoursProgressPlan.show = checked
                            state.templateMsgStateView.humanHoursProgressPercent.show = checked
                            state.templateMsgStateView.humanHoursProgressFact.show = checked
                            state.templateMsgStateView.humanHoursProgressForecast.show = checked
                        }
                        if (term === 'basePlan') {
                            state.templateMsgStateView.startDate.show = checked
                            state.templateMsgStateView.endDate.show = checked
                        }
                        if (term === 'operPlan') {
                            state.templateMsgStateView.operationalStartDate.show = checked
                            state.templateMsgStateView.operationalEndDate.show = checked
                        }
                    }
                } else {
                    if (
                        state.templateMsgStateView[term as keyof Omit<initMsgTemplateStateType, 'name'>].show === true
                    ) {
                        state.templateMsgStateView[term as keyof Omit<initMsgTemplateStateType, 'name'>].fix = checked
                        if (term === 'volumeDone') {
                            state.templateMsgStateView.volumeDoneFact.fix = checked;
                            state.templateMsgStateView.volumeDonePlan.fix = checked;
                            state.templateMsgStateView.volumeDoneForecast.fix = checked;
                        }
                        if (term === 'humanHoursProgress') {
                            state.templateMsgStateView.humanHoursProgressPlan.fix = checked
                            state.templateMsgStateView.humanHoursProgressPercent.fix = checked
                            state.templateMsgStateView.humanHoursProgressFact.fix = checked
                            state.templateMsgStateView.humanHoursProgressForecast.fix = checked
                        }
                        if (term === 'basePlan') {
                            state.templateMsgStateView.startDate.fix = checked
                            state.templateMsgStateView.endDate.fix = checked
                        }
                        if (term === 'operPlan') {
                            state.templateMsgStateView.operationalStartDate.fix = checked
                            state.templateMsgStateView.operationalEndDate.fix = checked
                        }
                    }
                }

                if (!state.templateMsgStateView.volumeDonePlan.show && !state.templateMsgStateView.volumeDoneFact.show && !state.templateMsgStateView.volumeDoneForecast.show) {
                    state.templateMsgStateView.volumeDone.show = false
                    state.templateMsgStateView.volumeDone.fix = false
                }
                if (state.templateMsgStateView.volumeDonePlan.show || state.templateMsgStateView.volumeDoneFact.show || state.templateMsgStateView.volumeDoneForecast.show) {
                    state.templateMsgStateView.volumeDone.show = true
                }

                if (!state.templateMsgStateView.humanHoursProgressPlan.show && !state.templateMsgStateView.humanHoursProgressPercent.show && !state.templateMsgStateView.humanHoursProgressFact.show && !state.templateMsgStateView.humanHoursProgressForecast.show) {
                    state.templateMsgStateView.humanHoursProgress.show = false
                    state.templateMsgStateView.humanHoursProgress.fix = false
                }
                if (state.templateMsgStateView.humanHoursProgressPlan.show || state.templateMsgStateView.humanHoursProgressPercent.show || state.templateMsgStateView.humanHoursProgressFact.show || state.templateMsgStateView.humanHoursProgressForecast.show) {
                    state.templateMsgStateView.humanHoursProgress.show = true
                }
                if (!state.templateMsgStateView.startDate.show && !state.templateMsgStateView.endDate.show) {
                    state.templateMsgStateView.basePlan.show = false
                    state.templateMsgStateView.basePlan.fix = false
                }
                if (state.templateMsgStateView.startDate.show || state.templateMsgStateView.endDate.show) {
                    state.templateMsgStateView.basePlan.show = true
                }
                if (!state.templateMsgStateView.operationalStartDate.show && !state.templateMsgStateView.operationalEndDate.show) {
                    state.templateMsgStateView.operPlan.show = false
                    state.templateMsgStateView.operPlan.fix = false
                }
                if (state.templateMsgStateView.operationalStartDate.show || state.templateMsgStateView.operationalEndDate.show) {
                    state.templateMsgStateView.operPlan.show = true
                }
            },
            prepare: (term: keyof Omit<initMsgTemplateStateType, 'name'>, checked: boolean, type: fixShow) => {
                return { payload: { term, checked, type } }
            },
        },

        applyTemplateMsg: (state) => {
            state.templateMsgState = state.templateMsgStateView
        },

        refreshTemplateMsg(state) {
            state.templateMsgState = initMsgTemplateState
        },

        refreshTemplateMsgView(state) {
            state.templateMsgStateView = initMsgTemplateState
        },
    },
})

export const {
    setTemplateMsgState,
    refreshTemplateMsg,
    checkTemplateMsgItem,
    setTemplateMsgStateView,
    checkTemplateMsgItemView,
    applyTemplateMsg,
    refreshTemplateMsgView,
} = slice.actions
export default slice.reducer

export const msgTemplatesSelector = (state: RootState) => state.msgTemplates
