import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { EisReportI } from '@/api/eis/eis.types';

import { getDateTimeString } from '@/shared/utils';

import s from '../EisReportsDrawer.module.scss';
import SelectEl from './SelectEl';

export default function EisLineEl({
    report,
    repeatCb,
    setStatusCb,
    downloadCb,
    repeat,
}: {
    report: EisReportI;
    repeatCb: (reportId: number) => void;
    setStatusCb: (reportId: number, isActive: boolean) => void;
    downloadCb: (reportId: number) => void;
    repeat: boolean;
}) {
    const [status, setStatus] = useState<'active' | 'not_active'>(
        report.status === 'Актуальный' ? 'active' : 'not_active'
    );
    const [isVisibleSelect, setIsVisibleSelect] = useState(false);

    const ref = useRef<any>(null);

    useEffect(() => {
        function handleClickOutside(evt: any) {
            if (ref.current && !ref.current.contains(evt.target)) {
                setIsVisibleSelect(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <div className={s.line}>
            <div className={s.icon}>
                <DescriptionOutlinedIcon />
            </div>
            <div className={s.title}>
                <h4>{report.name}</h4>
                <SelectEl
                    status={status}
                    onClick={() => setIsVisibleSelect((p) => !p)}
                />
                {isVisibleSelect ? (
                    <span
                        ref={ref}
                        className={s.select_absolute}
                    >
                        <SelectEl
                            status={status === 'active' ? 'not_active' : 'active'}
                            onClick={() => {
                                setStatusCb(report.reportId, status !== 'active');
                                setStatus(status === 'active' ? 'not_active' : 'active');
                                setIsVisibleSelect((p) => !p);
                            }}
                        />
                    </span>
                ) : null}
            </div>
            <div className={s.date_time}>{getDateTimeString(report.createAt)?.replace('в ', '')}</div>
            {repeat ? (
                <IconButton
                    component='button'
                    sx={{
                        width: '2rem',
                        height: '2rem',
                    }}
                    onClick={() => repeatCb(report.reportId)}
                    className={s.right_icon}
                >
                    <RefreshIcon />
                </IconButton>
            ) : (
                <div></div>
            )}
            <IconButton
                component='button'
                sx={{
                    width: '2rem',
                    height: '2rem',
                }}
                onClick={() => downloadCb(report.reportId)}
                className={s.right_icon}
            >
                <DownloadIcon sx={{ fontSize: '1.3rem' }} />
            </IconButton>
        </div>
    );
}
