import NiceModal from '@ebay/nice-modal-react';
import { useCallback } from 'react';

import { TStructureRow, TStructureRowEntityId } from '@/api/structure';

import { ConfirmDialog } from '@/components/ConfirmDialog';

import { TRowId } from '../StructureManagementTable.types';
import { useDeleteAllFoldersMutation, useDeleteFolderMutation } from './useQueries';

export const useDelete = (projectId: number) => {
    const { mutate: mutateFolderDelete } = useDeleteFolderMutation(Number(projectId)!);
    const { mutate: mutateAllFoldersDelete } = useDeleteAllFoldersMutation(Number(projectId)!);

    const deleteFolder = useCallback(
        (entityID: TRowId) => () => {
            mutateFolderDelete({ projectId: Number(projectId), folderId: entityID as TStructureRowEntityId });
        },
        [projectId]
    );

    const handleFolderDeleteConfirm = useCallback((entityID: TRowId, name: TStructureRow['name']) => {
        NiceModal.show(ConfirmDialog, {
            title: 'Подтвердить удаление уровня?',
            body: 'Уровень «' + name + '» будет удален безвозвратно.',
            onSuccess: deleteFolder(entityID),
        });
    }, []);

    const deleteAllFolders = useCallback(() => {
        mutateAllFoldersDelete({ projectId: Number(projectId) });
    }, [projectId]);

    const handleAllFoldersDeleteConfirm = useCallback(() => {
        NiceModal.show(ConfirmDialog, {
            title: 'Подтвердить удаление всех уровней?',
            body: 'Все уровни будут удалены безвозвратно.',
            onSuccess: deleteAllFolders,
        });
    }, []);

    return {
        onFolderDeleteConfirm: handleFolderDeleteConfirm,
        onAllFoldersDeleteConfirm: handleAllFoldersDeleteConfirm,
    };
};
