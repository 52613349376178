import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    TEstimatePositionId,
    TEstimatePositionsFilterBodyValue,
    TEstimatePositionsFilterKeys,
} from '@/api/estimatePositions';

import {
    updateConsolidatedPricesFilterByAllMode,
    updateConsolidatedPricesFilterBySingleMode,
} from '@/shared/utils/consolidatedPrices.utils';

import { Nullable } from '@/types/common.types';

import { RootState } from '../../store';
import { TCheckedPositionMap, TInitialState, TListType, TMode } from './estimatePositionsSlice.types';

const defaultFilterValue = {
    selectAll: false,
    values: [],
};

export const defaultFilters = {
    lsrCode: defaultFilterValue,
    lsrNumber: defaultFilterValue,
    chapter: defaultFilterValue,
    header: defaultFilterValue,
    code: defaultFilterValue,
    positionName: defaultFilterValue,
    rawUnit: defaultFilterValue,
};

const initialState: TInitialState = {
    listType: 'available',
    filters: defaultFilters,
    checkedPositionsMap: new Map(),
    checkedPositionId: null,
    redrawAllRows: false,
};

const slice = createSlice({
    name: 'estimatePositions',
    initialState,
    reducers: {
        updateCheckedPositionsMap(state, action: PayloadAction<TCheckedPositionMap>) {
            state.checkedPositionsMap = action.payload;
        },
        setCheckedPositionId(state, action: PayloadAction<Nullable<TEstimatePositionId>>) {
            state.checkedPositionId = action.payload;
        },
        setListType(state, action: PayloadAction<TListType>) {
            state.listType = action.payload;
        },
        reset(state) {
            state.checkedPositionsMap = new Map();
            state.checkedPositionId = null;
            state.redrawAllRows = !state.redrawAllRows;
        },
        setFilter: (
            state,
            action: PayloadAction<{
                key: TEstimatePositionsFilterKeys;
                checked: boolean;
                values: TEstimatePositionsFilterBodyValue['values'];
                mode: TMode;
                length: number;
            }>
        ) => {
            const { key, values: newValues, mode, checked, length } = action.payload;

            if (mode === 'all') {
                updateConsolidatedPricesFilterByAllMode(state.filters, key, checked, newValues);
                return;
            }

            if (mode === 'single') {
                updateConsolidatedPricesFilterBySingleMode(state.filters, key, checked, newValues, length);
            }
        },
        resetFilter: (state, action: PayloadAction<TEstimatePositionsFilterKeys>) => {
            state.filters[action.payload].selectAll = false;
            state.filters[action.payload].values = [];
        },
    },
});

export const estimatePositionsReducer = slice.reducer;

export const estimatePositionsSelectors = {
    filters: (state: RootState) => state.estimatePositions.filters,
    checkedPositionsMap: (state: RootState) => state.estimatePositions.checkedPositionsMap,
    checkedPositionId: (state: RootState) => state.estimatePositions.checkedPositionId,
    listType: (state: RootState) => state.estimatePositions.listType,
    redrawAllRows: (state: RootState) => state.estimatePositions.redrawAllRows,
};

export const estimatePositionsActions = slice.actions;
