import { TStructureRowFolderContent } from '@/api/structure';

import { pluralize, TPluralizeTuple } from '@/shared/utils/text.utils';

import { Nullable } from '@/types/common.types';

import { TData } from '../../../StructureManagementTable.types';

export const OFFSET_SIZE = 6;

const getMessage = (count: number, tuple: TPluralizeTuple) => {
    return count !== 0 ? `${count} ${pluralize(count, tuple)}` : '';
};

export const getFolderContentMessage = (foldersCount: number, consolidatedCount: number) => {
    return (
        [
            getMessage(foldersCount, ['уровень', 'уровня', 'уровней']),
            getMessage(consolidatedCount, ['позиция', 'позиции', 'позиций']),
        ]
            .filter(Boolean)
            .join(' и ') ?? ''
    );
};

export const canFolderContentVisible = (folderContent: Nullable<TStructureRowFolderContent>) => {
    if (!folderContent) return false;
    return folderContent.folders > 0 || folderContent.consolidated > 0;
};

export const calculateDotsCountForFolder = (data: TData) => {
    return Math.max(data?.pathArray!?.length! - 1, 0);
};

export const calculateDotsCountForConsolidated = (data: TData) => {
    const pathLengthOffset = data?.rowType === 'c_position' ? 3 : 2;
    return Math.max(data?.pathArray!?.length! - pathLengthOffset, 0);
};

export const calculateMinColumnWidthForConsolidated = (dotsCount: number) => {
    return dotsCount * 16 + 100;
};
