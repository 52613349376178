import { Work } from "../../../api/ksg/ksg.types"
import { IKsgWorkResponse } from "../api/api.types"
import { EMPTY_STRING_PLUG } from "../global/variables"

export function getWorkPeriod(workInfo: Work) {
    if (!workInfo) {
        return EMPTY_STRING_PLUG
    }
    if (workInfo.startDate && workInfo.endDate) {
        return workInfo.startDate + ' - ' + workInfo.endDate
    }
    if (workInfo.startDate && !workInfo.endDate) {
        return workInfo.startDate + ' - не определено'
    }
    if (!workInfo.startDate && workInfo.endDate) {
        return 'не определено - ' + workInfo.endDate
    }
    if (!workInfo.startDate && !workInfo.endDate) {
        return EMPTY_STRING_PLUG
    }
}

export function getExecutorFullName(executor: IKsgWorkResponse['executor'] | null) {
    if (!executor) {
        return EMPTY_STRING_PLUG
    } else {
        let fullName = ''
        fullName += executor?.lastName + ' '
        fullName += executor?.firstName[0] + '.'
        fullName += executor?.middleName ? ' ' + executor?.middleName[0] + '.' : ' '
        return fullName
    }
}