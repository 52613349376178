import { useSnackbar } from 'notistack';

import { useDeleteConsolidatedPriceMutation, useUpdateConsolidatedPriceMutation } from '@/api/consolidatedPrices';

import { queryClient } from '@/shared/constants/queryClient';
import { queryKeys } from '@/shared/constants/queryKeys';

import { TUseDeleteConsolidatedPrice, TUseUpdateConsolidatedPrice } from './EditDialog.types';

export const useUpdateConsolidatedPrice = (props: TUseUpdateConsolidatedPrice) => {
    const { enqueueSnackbar } = useSnackbar();

    return useUpdateConsolidatedPriceMutation({
        onSuccess: () => {
            props.onClose();
            enqueueSnackbar('Укрупненная расценка отредактирована', {
                variant: 'success',
            });
            Promise.all([
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.consolidatedPrices.list],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.consolidatedPrices.filter],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.tabCounters],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.consolidatedPrices.item, props.projectId, props.consolidatedId],
                }),
            ]);
        },
        onError: () => {
            const message = 'Произошла ошибка при редактировании укрупненной расценки';

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
    });
};

export const useDeleteConsolidatedPrice = (props: TUseDeleteConsolidatedPrice) => {
    const { enqueueSnackbar } = useSnackbar();

    return useDeleteConsolidatedPriceMutation({
        onMutate: () => {
            props.onClose();
        },
        onSuccess: () => {
            enqueueSnackbar('Укрупненная расценка удалена', {
                variant: 'success',
            });
            Promise.all([
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.consolidatedPrices.list],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.consolidatedPrices.filter],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.tabCounters],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.consolidatedPrices.item, props.projectId, props.consolidatedId],
                }),
            ]);
        },
        onError: () => {
            const message = 'Произошла ошибка при удалении укрупненной расценки';

            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
    });
};
