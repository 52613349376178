import { CellClassRules } from 'ag-grid-community';

import { TStructureRow } from '@/api/structure';

import { TContext, TData, TRowId } from '../StructureManagementTable.types';

type TGenerateRowId = {
    entityId: TData['entityId'] | string;
    rowType: TData['rowType'];
};

export const generateRowId = ({ rowType, entityId }: TGenerateRowId) => {
    return `${rowType}_${entityId}`;
};

export const getNewFolder = (referenceId: string, level = 1): TData => {
    return {
        modifyMode: 'create',
        entityId: 'new',
        rowType: 'a_folder',
        level: level,
        positionType: null,
        lsrCode: null,
        lsrNumber: null,
        chapter: null,
        header: null,
        code: null,
        name: '',
        rawUnit: null,
        quantity: null,
        humanHourCost: null,
        humanHourCostPerUnit: null,
        machineHourCost: null,
        machineHourCostPerUnit: null,
        costTotal: null,
        ordinal: null,
        parentId: null,
        pathArray: [],
        hasChildren: false,
        folderContent: null,
        folderRow: null,
        referenceId: referenceId,
    };
};

export const filterRowDataByCollapseRowIds = (data: TStructureRow[], collapsedRowIds: Set<TRowId>) => {
    const filteredData = data.filter(({ pathArray }) => {
        let result = true;

        pathArray.forEach((item, index, arr) => {
            const isLast = index === arr.length - 1;

            const has = collapsedRowIds.has(item);
            if (has && !isLast) {
                result = false;
            }
        });
        return result;
    });

    return filteredData;
};

export const cellClassRules: CellClassRules<TData> = {
    'ag-cell-hover-over': (params) => {
        const context = params.context as TContext;
        return context.overNodeId === params.node.id;
    },
};

export const hasParent = (pathArray: string[], targetId: string) => {
    return Boolean(getParentIdByPathArray(pathArray, targetId));
};

export const getParentIdByPathArray = (pathArray: string[], targetId: string, offset = 1) => {
    const index = pathArray.indexOf(targetId);
    return index > 0 ? pathArray[index - offset] ?? null : null;
};

export const getFolderParentIdByPathArray = (pathArray: string[], targetId: string) => {
    const isPosition = targetId.startsWith('c_position_');
    return getParentIdByPathArray(pathArray, targetId, isPosition ? 2 : 1);
};
