import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import { FC, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

import { usePortal } from '@/hooks/usePortal';

import { Container } from './DropZone.styles';
import { TDropZoneProps } from './DropZone.types';

const isInside = (mouseEvent: MouseEvent, dropZoneRect: DOMRect) => {
    return (
        mouseEvent.clientX >= dropZoneRect.left &&
        mouseEvent.clientX <= dropZoneRect.right &&
        mouseEvent.clientY >= dropZoneRect.top &&
        mouseEvent.clientY <= dropZoneRect.bottom
    );
};

export const DropZone: FC<TDropZoneProps> = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const Portal = usePortal(document.body);
    const dropZoneRef = useRef<HTMLButtonElement>(null);

    const handleMouseMove = (e: MouseEvent) => {
        if (!dropZoneRef.current) return;
        const dropZoneRect = dropZoneRef.current!.getBoundingClientRect();

        if (!dropZoneRect) return;

        if (isInside(e, dropZoneRect)) {
            setIsHovered(true);
            return;
        }
        setIsHovered(false);
    };

    const handleMouseUp = () => {
        if (!isHovered) return;
        props.onDrop();
    };

    useEventListener('mousemove', handleMouseMove);
    useEventListener('mouseup', handleMouseUp);

    return (
        <Portal>
            <Container
                ref={dropZoneRef}
                $isHovered={isHovered}
                $top={props.top}
                $left={props.left}
            >
                <VerticalAlignTopOutlinedIcon />
            </Container>
        </Portal>
    );
};
