import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import styled from 'styled-components';

import { StyledBtn } from '@/pages/WorkManagment/components/components.styles';

export const Container = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    gap: 1.25,
})``;

export const FetchPositionButton = styled(LoadingButton).attrs({
    size: 'small',
    variant: 'outlined',
})`
    border-color: #8cbbe8;
`;

const Button = styled(StyledBtn)`
    height: 30px;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 1;
    height: 30px;
    padding-inline: 10px;
    width: fit-content;
    border-radius: 6px;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s;
    overflow: hidden;
    display: block;
`;

export const CancelButton = styled(Button).attrs({
    size: 'small',
    variant: 'outlined',
    color: 'secondary',
})``;

export const CreateButton = styled(Button).attrs({
    size: 'small',
    variant: 'outlined',
    color: 'secondary',
})`
    color: #19681d;
    border-color: #19681d;
    background-color: #8dd4c826;
`;
