import { GetRowIdParams, IsFullWidthRowParams, RowHeightParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';

import { TEstimatePositionId } from '@/api/estimatePositions';

import { UIAgGridContainerSecondary } from '@/components/ui/UIAgGridContainerSecondary';

import { useLatest } from '@/hooks/useLatest';

import { TSelectedPositionType } from '@/pages/EstimatePositionsTable/EstimatePositionsTable.types';

import { FullWidthCellRenderer } from '../TableCell';
import { columnsDef } from './Table.columnDefs';
import { defaultColDef } from './Table.config';
import { useRowClassRules } from './Table.model';
import { AgGridContainerCss } from './Table.styles';
import { TContext, TData, TTableProps } from './Table.types';
import { redrawAllRows, redrawRows } from './Table.utils';

export const Table: FC<TTableProps> = memo(
    ({ rowData, positionsByUnitDifference, selectedMainPosition, onSetMainPosition, onDeleteRow }) => {
        const gridRef = useRef<AgGridReact<TData>>(null);

        const latestCheckedId = useLatest(selectedMainPosition);

        useEffect(() => {
            if (!gridRef.current?.api) return;

            redrawAllRows(gridRef.current?.api!);
        }, [positionsByUnitDifference]);

        const handleCheckboxChange = useCallback(
            (id: number) => () => {
                if (latestCheckedId.current === id) return;

                const ids = [id];

                if (latestCheckedId.current) ids.push(latestCheckedId.current);

                onSetMainPosition(id);
                redrawRows(gridRef.current?.api!, ids);
            },
            []
        );

        const rowClassRules = useRowClassRules();

        const handleRowDelete = useCallback(
            (id: TEstimatePositionId, selectType: TSelectedPositionType) => () => {
                onDeleteRow(id, selectType);
            },
            []
        );

        const getRowId = useCallback(({ data }: GetRowIdParams<TData>) => {
            return data?.id.toString();
        }, []);

        const context: TContext = useMemo(() => {
            return {
                checkedId: selectedMainPosition,
                positionsByUnitDifference: positionsByUnitDifference,
                onCheckboxChange: handleCheckboxChange,
                onRowDelete: handleRowDelete,
            };
        }, [selectedMainPosition, positionsByUnitDifference, handleCheckboxChange]);

        const isFullWidthRow = useCallback((params: IsFullWidthRowParams<TData, TContext>) => {
            if (!params.rowNode.data) return true;
            if ('isFull' in params.rowNode.data) return true;

            return false;
        }, []);

        const getRowHeight = useCallback((params: RowHeightParams<TData, TContext>) => {
            if (!params.data) return 64;
            if ('isFull' in params.data) return 48;
            return 64;
        }, []);

        return (
            <UIAgGridContainerSecondary customStyles={AgGridContainerCss}>
                <AgGridReact
                    ref={gridRef}
                    rowClassRules={rowClassRules}
                    getRowId={getRowId}
                    rowData={rowData}
                    columnDefs={columnsDef}
                    context={context}
                    defaultColDef={defaultColDef}
                    headerHeight={40}
                    rowHeight={64}
                    isFullWidthRow={isFullWidthRow}
                    fullWidthCellRenderer={FullWidthCellRenderer}
                    getRowHeight={getRowHeight}
                />
            </UIAgGridContainerSecondary>
        );
    }
);
