import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';

import { theme } from '@/styles/theme';

import { TContext, TData } from './ConsolidatedPriceFilterDialog.types';

export function FilterOptionCellRenderer(props: ICellRendererParams<TData, any, TContext>) {
    return (
        <FormGroup>
            <FormControlLabel
                sx={{
                    '.MuiTypography-root': {
                        textAlign: 'left',
                    },
                    '.MuiCheckbox-root': {
                        pr: 1.5,
                    },
                }}
                control={
                    <Checkbox
                        disableRipple
                        color={'primary'}
                        checked={props.data?.checked}
                        onChange={(e, checked) => {
                            if (!props.data) return;

                            props.context.onCheckboxChange(props.data.value, checked);
                        }}
                        sx={{
                            svg: {
                                path: {
                                    fill: theme.palette.primary.main,
                                },
                            },
                            padding: 0,
                        }}
                    />
                }
                label={
                    <OverflowableTypographyWithTooltip maxRows={1}>
                        {props.data?.label}
                    </OverflowableTypographyWithTooltip>
                }
            />
        </FormGroup>
    );
}
