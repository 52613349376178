export type TColors = 'error' | 'warning' | 'default';

export const COLORS = {
    error: {
        color: '#F23C3C',
        backgroundColor: 'rgba(242, 60, 60, 0.1)',
    },
    warning: {
        color: '#FE9B3F',
        backgroundColor: 'rgba(254, 155, 63, 0.1)',
    },
    default: {
        color: '#5C87CE',
        backgroundColor: 'rgba(92, 135, 206, 0.1)',
    },
};
