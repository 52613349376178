import { useCallback, useState } from 'react';

import { Nullable } from '@/types/common.types';

type THandleCheckboxChangeProps = {
    checked: boolean;
    lastId: string;
    ids: string[];
};

export const useConsolidatedSelect = () => {
    const [selectSet, setSelectSet] = useState<Set<string>>(new Set());
    const [lastSelectRowId, setLastSelectRowId] = useState<Nullable<string>>(null);

    const handleSelect = useCallback(({ lastId, checked, ids }: THandleCheckboxChangeProps) => {
        setLastSelectRowId(lastId);
        setSelectSet((prevSet) => {
            const newSet = new Set(prevSet);

            for (const id of ids) {
                if (checked) {
                    newSet.add(id);
                } else {
                    newSet.delete(id);
                }
            }

            return newSet;
        });
    }, []);

    const reset = useCallback(() => {
        setSelectSet(new Set());
        setLastSelectRowId(null);
    }, []);

    return {
        consolidatedSelectSet: selectSet,
        lastConsolidatedSelectRowId: lastSelectRowId,
        onConsolidatedSelect: handleSelect,
        resetConsolidatedSelect: reset,
    } as const;
};
