import NiceModal from '@ebay/nice-modal-react';
import DriveFileMoveOutlined from '@mui/icons-material/DriveFileMoveOutlined';
import { animated } from '@react-spring/web';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCreateBulkConsolidatedPriceMutation } from '@/api/consolidatedPrices';
import { useRefreshEstimatePositions } from '@/api/estimatePositions';

import { ConfirmDialog } from '@/components/ConfirmDialog';

import { useBoop } from '@/hooks/useBoop';

import {
    CreateConsolidatedPriceDialog,
    useGetPositionsBySelectType,
    useHasSelectEstimatePositions,
} from '@/pages/EstimatePositionsTable';

import { EPageName } from '@/shared/constants/pages';
import { queryClient } from '@/shared/constants/queryClient';
import { queryKeys } from '@/shared/constants/queryKeys';

import { estimatePositionsActions } from '@/store/slices/estimatePositions';
import { useAppDispatch } from '@/store/store';

import { Container, CreateBulkButton, CreateButton, FetchPositionButton } from './EstimatePositionsControls.styles';
import { IEstimatePositionsControlsProps } from './EstimatePositionsControls.types';
import { isNoChanges } from './EstimatePositionsControls.utils';

const AnimatedDriveFileMoveOutlined = animated(DriveFileMoveOutlined);

export const EstimatePositionsControls: FC<IEstimatePositionsControlsProps> = () => {
    const { projectId } = useParams();
    const { hasPrimary: hasMain, hasSecondary: hasExtra } = useHasSelectEstimatePositions({
        projectId: Number(projectId),
    });
    const [mainPositions] = useGetPositionsBySelectType({
        projectId: Number(projectId),
    });
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [createBulkStyle, createBulkTrigger] = useBoop({ rotation: 4, timing: 200 });

    const { mutate: mutateRefreshEstimatePositions, isPending: isRefreshPending } = useRefreshEstimatePositions({
        onSuccess: (data) => {
            if (!data.success) {
                enqueueSnackbar('Произошла ошибка при получении позиций', {
                    variant: 'error',
                });
                return;
            }

            if (isNoChanges(data)) {
                enqueueSnackbar('Новых позиций нет', {
                    variant: 'info',
                });
                return;
            }

            enqueueSnackbar('Есть новые позиции', {
                variant: 'success',
            });
            Promise.all([
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.list],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.consolidatedPrices.estimatePositionsList],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.filter],
                }),
                queryClient.invalidateQueries({
                    queryKey: [queryKeys.estimatePositions.tabCounters],
                }),
            ]);
        },
        onError: () => {
            enqueueSnackbar('Произошла ошибка при получении позиций', {
                variant: 'error',
            });
        },
    });

    const { mutate: mutateCreateBulkConsolidatedPrices, isPending: isCreateBulkPending } =
        useCreateBulkConsolidatedPriceMutation({
            onSuccess: () => {
                dispatch(estimatePositionsActions.reset());
                enqueueSnackbar('Массовое создание укрупненных расценок прошло успешно', {
                    variant: 'success',
                });
                navigate(`/${EPageName.Mofen}/${projectId}`);

                Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.estimatePositions.list],
                    }),
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.estimatePositions.filter],
                    }),
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.estimatePositions.tabCounters],
                    }),
                    queryClient.invalidateQueries({
                        queryKey: [queryKeys.consolidatedPrices.list],
                    }),
                ]);
            },
            onError: () => {
                enqueueSnackbar('Произошла ошибка при сохранении укрупненных расценок', {
                    variant: 'error',
                });
            },
        });

    const handleCreate = () => {
        NiceModal.show(CreateConsolidatedPriceDialog, {
            projectId: Number(projectId),
            navigate: navigate,
        });
    };

    const createBulk = () => {
        mutateCreateBulkConsolidatedPrices({
            projectId: Number(projectId),
            body: {
                positionIDs: mainPositions.map((v) => v.id),
            },
        });
    };

    const handleConfirmCreateBulk = () => {
        NiceModal.show(ConfirmDialog, {
            title: 'Подтвердить формирование расценки?',
            body: 'По выбранным позициям будут сформированы единичные расценки.',
            onSuccess: createBulk,
        });
    };

    const handleRefresh = async () => {
        mutateRefreshEstimatePositions({
            projectId: Number(projectId),
        });
    };

    const isFetchPositionButtonDisabled = isRefreshPending;
    const isCreateButtonDisabled = !hasMain;
    const isCreateBulkButtonDisabled = !hasMain || hasExtra || isCreateBulkPending;

    return (
        <Container>
            <FetchPositionButton
                disabled={isFetchPositionButtonDisabled}
                loading={isFetchPositionButtonDisabled}
                onClick={handleRefresh}
            >
                Получить позиции
            </FetchPositionButton>
            <CreateButton
                disabled={isCreateButtonDisabled}
                onClick={handleCreate}
            >
                Сформировать укрупненную расценку
            </CreateButton>
            <CreateBulkButton
                onClick={handleConfirmCreateBulk}
                disabled={isCreateBulkButtonDisabled}
                onMouseEnter={createBulkTrigger}
            >
                <AnimatedDriveFileMoveOutlined
                    sx={{
                        fontSize: '16px',
                    }}
                    style={createBulkStyle}
                />
            </CreateBulkButton>
        </Container>
    );
};
