interface IProps {}

export const CollapseArrowIcon: React.FC<IProps> = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
        >
            <path
                d='M10 6.66667L5 11.6667L6.175 12.8417L10 9.025L13.825 12.8417L15 11.6667L10 6.66667Z'
                fill='currentColor'
            />
        </svg>
    );
};
