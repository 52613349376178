import { Box, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)``;

export const Tabs = styled(MuiTabs)`
    min-height: 40px;
    height: 40px;
`;

export const Tab = styled(MuiTab)`
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #5c6e8c;
    padding: 12px 8px;
    height: 40px;
    min-height: 40px;
    line-height: 1;
`;
