import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    FormLabel,
    ListItem,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import cn from 'classnames';
import { format, parse } from 'date-fns';
import { FormikHelpers, useFormik } from 'formik';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Work } from '@/api/ksg/ksg.types';
import { useAddWorkMutation } from '@/api/works/works.api';
import { workStatuses } from '@/api/works/works.types';

import { useIsMounted } from '@/hooks/useIsMounted';

import { stylesLevelAg } from '@/pages/AgGrid/AgGrid.service';
import { StyledCheckbox } from '@/pages/AgGrid/StyledCheckbox';
import { FormError } from '@/pages/Projects/component/FormikFormC';
import { req } from '@/pages/WorkManagment/api/api';

import { Buttons } from '@/shared/components/ButtonsAndWarning';

import { onAddWorkKsgAg } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { useAppDispatch } from '@/store/store';

import { Nullable } from '@/types/common.types';

import { OverflowableTypographyWithTooltip } from '../OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { ICreateKSGInitialFormValues, createKSGInitialFormValues, createKSGValidationSchema } from './AddLevel.service';
import styles from './AddLevelKsg.module.scss';
import DateLine from './components/DateLine';
import TextLineEl from './components/TextLineEl';
import WideTextLineEl from './components/WideTextLineEl';

const useGetDebouncedValue = ({ wait = 500 }: { wait?: number } = {}) => {
    const [value, setValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');

    const debounceFn = useCallback(debounce(setDebouncedValue, wait), []);

    useEffect(() => {
        debounceFn(value);
    }, [debounceFn, value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

    return {
        value,
        debouncedValue,
        onChange: handleChange,
    };
};

type TWorksAutocompleteProps = {
    onWorkSelect: (value: Nullable<Work>) => void;
};

const WorksAutocomplete: React.FC<TWorksAutocompleteProps> = ({ onWorkSelect }) => {
    const { projectId } = useParams();
    const { value, debouncedValue, onChange } = useGetDebouncedValue();

    const [isOpenAutocomplete, setIsOpenAutocomplete] = useState(false);
    const [isLoadingAutocomplete, setIsLoadingAutocomplete] = useState(false);
    const [options, setOptions] = useState<Work[]>([]);

    useEffect(() => {
        if (!isOpenAutocomplete) return;

        fetchWorks(debouncedValue);
    }, [isOpenAutocomplete, debouncedValue]);

    const fetchWorks = async (value = '') => {
        try {
            setIsLoadingAutocomplete(true);
            const { data } = await req.get(`/projects/${projectId}/works/add/search?filter=${value}`);
            setOptions(data.data);
        } catch (error) {
        } finally {
            setIsLoadingAutocomplete(false);
        }
    };

    return (
        <Autocomplete
            sx={{
                backgroundColor: '#F6F7FB',
                fontSize: '14px',

                '.MuiOutlinedInput-root': {
                    padding: '0 0.5rem',
                },
            }}
            onClose={() => setIsOpenAutocomplete(false)}
            open={isOpenAutocomplete}
            onOpen={() => {
                setIsOpenAutocomplete(true);
            }}
            onChange={(_, value) => {
                onWorkSelect(value);
                setIsOpenAutocomplete(false);
            }}
            disableClearable
            options={options}
            getOptionLabel={(option: Work) => `${option.numOrder} ${option.workName}`}
            fullWidth
            loading={isLoadingAutocomplete}
            loadingText='Загрузка...'
            noOptionsText='Нет значений'
            filterOptions={(options, _state) => options}
            renderOption={(props, option) => {
                return (
                    <ListItem {...props}>
                        <OverflowableTypographyWithTooltip
                            maxRows={2}
                            TooltipProps={{
                                sx: {
                                    background: 'transparent !important',
                                },
                            }}
                        >
                            {option.numOrder} {option.workName}
                        </OverflowableTypographyWithTooltip>
                    </ListItem>
                );
            }}
            renderInput={(params) => (
                <>
                    <TextField
                        {...params}
                        value={value}
                        placeholder='Введите или выберите значение'
                        onChange={onChange}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoadingAutocomplete ? (
                                        <CircularProgress
                                            color='inherit'
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                </>
            )}
        />
    );
};

type levelsNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7;

type TProps = {
    addLevelData: {
        id: number | null;
        index: number;
        level: number | null;
    };
    grid: AgGridReact | null;
    onClose: (isCloseWithoutConfirm?: boolean) => void;
    setHasValues: (value: boolean) => void;
};

export const AddLevelKsg = ({ grid, addLevelData, setHasValues, onClose }: TProps) => {
    const [tag, setTag] = useState(false);
    const isMounted = useIsMounted();

    const dispatch = useAppDispatch();

    const { projectId } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    const [worksReq] = useAddWorkMutation();

    const onSubmit = useCallback(
        (values: ICreateKSGInitialFormValues, actions: FormikHelpers<ICreateKSGInitialFormValues>) => {
            worksReq({
                id: Number(projectId),
                body: {
                    ...values,
                    id_parent: addLevelData.id,
                    costER: values.costER ? Number(values.costER) : null,
                    humanHourCost: values.humanHourCost ? Number(values.humanHourCost) : null,
                    machineHourCost: values.machineHourCost ? Number(values.machineHourCost) : null,
                    volumeTotal: values.volumeTotal ? Number(values.volumeTotal) : null,
                    rdVersion: values.rdVersion ? Number(values.rdVersion) : null,
                    rdStatus: values.rdStatus ? (values.rdStatus as workStatuses) : null,
                    rdDate: values.rdDate ? format(values.rdDate as Date, 'dd.MM.yyyy') : null,
                    rdDateApproval: values.rdDateApproval ? format(values.rdDateApproval as Date, 'dd.MM.yyyy') : null,
                    startDate: values.startDate ? format(values.startDate as Date, 'dd.MM.yyyy') : null,
                    endDate: values.endDate ? format(values.endDate as Date, 'dd.MM.yyyy') : null,
                    operationalStartDate: values.operationalStartDate
                        ? format(values.operationalStartDate as Date, 'dd.MM.yyyy')
                        : null,
                    operationalEndDate: values.operationalEndDate
                        ? format(values.operationalEndDate as Date, 'dd.MM.yyyy')
                        : null,
                    tag: tag,
                },
            })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(t('work_added'), {
                        variant: 'success',
                    });

                    dispatch(onAddWorkKsgAg({ works: res.data, parentId: addLevelData.id }));

                    grid && grid.api.applyTransaction({ update: res.data.slice(0, -1) as Work[] });
                    onClose(true);
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                        variant: 'error',
                    });
                    console.error(e);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                    actions.resetForm();
                });
        },
        [addLevelData.id, addLevelData.index, dispatch, enqueueSnackbar, projectId, t, worksReq]
    );

    const formik = useFormik<ICreateKSGInitialFormValues>({
        initialValues: createKSGInitialFormValues,
        onSubmit,
        validationSchema: createKSGValidationSchema,
    });

    const hasValues = Object.values(formik.values).some((v) => v);

    useEffect(() => {
        setHasValues(hasValues);
    }, [hasValues]);

    useLayoutEffect(() => {
        (() => formik.validateForm())();
    }, []);

    const handleWorkSelect = (work: Nullable<Work>) => {
        if (!work) return;

        formik.setValues({
            objTitle: work.objTitle || '',
            objName: work.objName || '',
            rdGroup: work.rdGroup || '',
            rdCode: work.rdCode || '',
            rdStatus: work.rdStatus || '',
            rdDateApproval: work.rdDateApproval ? parse(work.rdDateApproval as string, 'dd.MM.yyyy', new Date()) : null,
            rdVersion: work.rdVersion ? String(work.rdVersion) : '',
            rdDate: work.rdDate ? parse(work.rdDate as string, 'dd.MM.yyyy', new Date()) : null,
            workGroup: work.workGroup || '',
            contractorCompany: work.contractorCompany || '',
            workName: work.workName || '',
            unit: work.unit || '',
            volumeTotal: work.volumeTotal ? String(work.volumeTotal) : '',
            startDate: work.startDate ? parse(work.startDate as string, 'dd.MM.yyyy', new Date()) : null,
            endDate: work.endDate ? parse(work.endDate as string, 'dd.MM.yyyy', new Date()) : null,
            operationalStartDate: work.operationalStartDate
                ? parse(work.operationalStartDate as string, 'dd.MM.yyyy', new Date())
                : null,
            operationalEndDate: work.operationalEndDate
                ? parse(work.operationalEndDate as string, 'dd.MM.yyyy', new Date())
                : null,
            humanHourCost: work.humanHourCost ? String(work.humanHourCost) : '',
            machineHourCost: work.machineHourCost ? String(work.machineHourCost) : '',
            costER: work.costER ? String(work.costER) : '',
            costERGK: work.costERGK ? String(work.costERGK) : '',
            costERSub: work.costERSub ? String(work.costERSub) : '',
            groupOfWorks: work.groupOfWorks ? String(work.groupOfWorks) : '',
            typeOfWorks: work.typeOfWorks ? String(work.typeOfWorks) : '',
            mainGroup: work.mainGroup ? String(work.mainGroup) : '',
        });
    };

    const isDisabled = () => {
        if (!isMounted) return true;
        return (!formik.isValid && Object.values(formik.touched).every((item) => item)) || formik.isSubmitting;
    };

    const numberProps = { type: 'number', step: 0.000001, min: 0, max: 99999999 };

    return (
        <>
            <div className={styles.top}>
                <h4>Добавление подуровня</h4>
                {addLevelData.level !== null && addLevelData.level > 1 ? (
                    <div className={styles.levels}>
                        <div className={stylesLevelAg((addLevelData.level - 1) as levelsNumbers)}>
                            УР {addLevelData.level - 1}
                        </div>
                        <ArrowForwardIcon
                            sx={{
                                color: '#9AA2B0',
                                fontSize: '1rem',
                            }}
                        />
                        <div className={stylesLevelAg(addLevelData.level as levelsNumbers)}>
                            УР {addLevelData.level}
                        </div>
                    </div>
                ) : (
                    <div className={stylesLevelAg(addLevelData.level as levelsNumbers)}>УР {addLevelData.level}</div>
                )}
            </div>

            <div className={styles.form}>
                <FormControl fullWidth>
                    <FormLabel
                        sx={{
                            color: '#5c6e8c',
                            fontWeight: '500',
                            fontSize: '14px',
                            marginBottom: '0.4rem',
                        }}
                    >
                        Скопировать данные из уровня
                    </FormLabel>

                    <WorksAutocomplete onWorkSelect={handleWorkSelect} />
                </FormControl>
            </div>

            <div className={cn(styles.main, styles.mainCreate)}>
                <h4 className={styles.main_title}>Заполните обязательные поля</h4>
                <form
                    onSubmit={formik.handleSubmit}
                    className={styles.main_form}
                >
                    <TextLineEl
                        title='Титул'
                        errorTitle={formik.errors.objTitle}
                        touched={formik.touched.objTitle}
                        {...formik.getFieldProps('objTitle')}
                    />

                    <TextLineEl
                        title='Объект'
                        errorTitle={formik.errors.objName}
                        touched={formik.touched.objName}
                        {...formik.getFieldProps('objName')}
                    />

                    <TextLineEl
                        title='Раздел РД'
                        errorTitle={formik.errors.rdGroup}
                        touched={formik.touched.rdGroup}
                        {...formik.getFieldProps('rdGroup')}
                    />

                    <TextLineEl
                        title='Шифр РД'
                        errorTitle={formik.errors.rdCode}
                        touched={formik.touched.rdCode}
                        {...formik.getFieldProps('rdCode')}
                    />

                    <div className={styles.input_line}>
                        <p>Статус выдачи РД:</p>

                        <FormControl
                            sx={{
                                width: '10.375rem',
                            }}
                        >
                            <Select
                                sx={{
                                    backgroundColor: '#F6F7FB',

                                    '.MuiSelect-select': {
                                        padding: '0.3rem .5rem',
                                        borderRadius: '6px',
                                        fontSize: 14,
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(43, 54, 72, 0.12)',
                                        borderWidth: '1px !important',
                                    },
                                }}
                                {...formik.getFieldProps('rdStatus')}
                                error={!!formik.errors.rdStatus?.length}
                            >
                                {[
                                    'Согласовано',
                                    'На согласовании',
                                    'Корректировка',
                                    'Разработка',
                                    'В производстве',
                                    'Не в работе',
                                ].map((v) => (
                                    <MenuItem
                                        key={v}
                                        value={v}
                                    >
                                        {v} проект
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormError
                                isTouched={formik.touched.rdStatus}
                                error={formik.errors.rdStatus}
                                right
                            />
                        </FormControl>
                    </div>
                    <DateLine
                        errorTitle={formik.errors.rdDateApproval}
                        touched={formik.touched.rdDateApproval}
                        title='Дата согласования РД'
                        onChange={(newValue) => {
                            formik.setFieldValue('rdDateApproval', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                rdDateApproval: true,
                            })
                        }
                        value={formik.values.rdDateApproval}
                    />
                    <TextLineEl
                        title='Версия РД'
                        errorTitle={formik.errors.rdVersion}
                        touched={formik.touched.rdVersion}
                        {...formik.getFieldProps('rdVersion')}
                        {...numberProps}
                    />
                    <DateLine
                        errorTitle={formik.errors.rdDate}
                        touched={formik.touched.rdDate}
                        title='Дата выдачи РД'
                        onChange={(newValue) => {
                            formik.setFieldValue('rdDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                rdDate: true,
                            })
                        }
                        value={formik.values.rdDate}
                    />
                    <WideTextLineEl
                        title='Наименование работы'
                        errorTitle={formik.errors.workName}
                        touched={formik.touched.workName}
                        {...formik.getFieldProps('workName')}
                        required
                    />
                    <TextLineEl
                        title='Головная группа работ'
                        errorTitle={formik.errors.groupOfWorks}
                        touched={formik.touched.groupOfWorks}
                        {...formik.getFieldProps('groupOfWorks')}
                    />
                    <TextLineEl
                        title='Конструктив (Вид работ)'
                        errorTitle={formik.errors.typeOfWorks}
                        touched={formik.touched.typeOfWorks}
                        {...formik.getFieldProps('typeOfWorks')}
                    />
                    <div className={styles.input_line}>
                        <p>Прочие работы:</p>
                        <StyledCheckbox
                            checked={tag}
                            onChange={(e) => setTag(e.target.checked)}
                        />
                    </div>
                    <TextLineEl
                        title='Ключевые объемы'
                        errorTitle={formik.errors.mainGroup}
                        touched={formik.touched.mainGroup}
                        {...formik.getFieldProps('mainGroup')}
                    />
                    <WideTextLineEl
                        title='Группа работ'
                        errorTitle={formik.errors.workGroup}
                        touched={formik.touched.workGroup}
                        {...formik.getFieldProps('workGroup')}
                    />
                    <TextLineEl
                        title='Подрядчик'
                        errorTitle={formik.errors.contractorCompany}
                        touched={formik.touched.contractorCompany}
                        {...formik.getFieldProps('contractorCompany')}
                    />

                    <TextLineEl
                        title='Единица измерения'
                        errorTitle={formik.errors.unit}
                        touched={formik.touched.unit}
                        required
                        {...formik.getFieldProps('unit')}
                    />
                    <TextLineEl
                        title='Общий объем'
                        errorTitle={formik.errors.volumeTotal}
                        touched={formik.touched.volumeTotal}
                        required
                        {...formik.getFieldProps('volumeTotal')}
                        {...numberProps}
                    />

                    <DateLine
                        errorTitle={formik.errors.operationalStartDate}
                        touched={formik.touched.operationalStartDate}
                        title='Опер.план дата начала'
                        onChange={(newValue) => {
                            formik.setFieldValue('operationalStartDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                operationalStartDate: true,
                            })
                        }
                        value={formik.values.operationalStartDate}
                    />
                    <DateLine
                        errorTitle={formik.errors.operationalEndDate}
                        touched={formik.touched.operationalEndDate}
                        title='Опер.план дата окончания'
                        onChange={(newValue) => {
                            formik.setFieldValue('operationalEndDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                operationalEndDate: true,
                            })
                        }
                        value={formik.values.operationalEndDate}
                    />
                    <DateLine
                        errorTitle={formik.errors.startDate}
                        touched={formik.touched.startDate}
                        title='Баз.план дата начала'
                        onChange={(newValue) => {
                            formik.setFieldValue('startDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                startDate: true,
                            })
                        }
                        value={formik.values.startDate}
                    />
                    <DateLine
                        errorTitle={formik.errors.endDate}
                        touched={formik.touched.endDate}
                        title='Баз.план дата окончания'
                        onChange={(newValue) => {
                            formik.setFieldValue('endDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                endDate: true,
                            })
                        }
                        value={formik.values.endDate}
                    />
                    <TextLineEl
                        title='Трудозатраты на ед. изм., чел-час'
                        titleAsNode={
                            <>
                                Трудозатраты на ед. изм., <br /> чел-час
                            </>
                        }
                        errorTitle={formik.errors.humanHourCost}
                        touched={formik.touched.humanHourCost}
                        {...formik.getFieldProps('humanHourCost')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title='Затраты МиМ на ед.изм., маш-час'
                        titleAsNode={
                            <>
                                Затраты МиМ на ед.изм., <br /> маш-час
                            </>
                        }
                        errorTitle={formik.errors.machineHourCost}
                        touched={formik.touched.machineHourCost}
                        {...formik.getFieldProps('machineHourCost')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title='Стоимость ЕР (Дог.+ДР), руб.'
                        titleAsNode={
                            <>
                                Стоимость ЕР (Дог.+ДР), <br /> руб.
                            </>
                        }
                        errorTitle={formik.errors.costER}
                        touched={formik.touched.costER}
                        {...formik.getFieldProps('costER')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title='Стоимость ЕР (Договор), руб.'
                        titleAsNode={
                            <>
                                Стоимость ЕР (Договор), <br /> руб.
                            </>
                        }
                        errorTitle={formik.errors.costERGK}
                        touched={formik.touched.costERGK}
                        {...formik.getFieldProps('costERGK')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title='Стоимость ЕР (Расчет), руб.'
                        errorTitle={formik.errors.costERSub}
                        touched={formik.touched.costERSub}
                        {...formik.getFieldProps('costERSub')}
                        {...numberProps}
                    />

                    <button
                        type='submit'
                        style={{
                            display: 'none',
                        }}
                    ></button>
                </form>
            </div>
            <div className={styles.buttons}>
                <Buttons
                    loading={formik.isSubmitting}
                    onClose={onClose}
                    onSubmit={formik.handleSubmit}
                    submitText='сохранить'
                    cancelText='отменить'
                    disabled={isDisabled()}
                />
            </div>
        </>
    );
};
