import { Stack } from '@mui/material';
import styled from 'styled-components';

export const ChangesSummaryContainer = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'row',
    gap: '8px',
})`
    height: 100%;
`;
