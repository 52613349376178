import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
    IColumnTemplate,
    TPatternsListType,
    TUseUpdateDefaultTemplatesProps,
} from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.types';

import { req } from '@/pages/WorkManagment/api/api';

import { refreshList, setCurrentTemplate, templatesSelector } from '@/store/slices/templatesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { Nullable } from '@/types/common.types';

import { getTemplatesToUpdate, isPageWithDefaultTemplates } from './ConfigureAgGridColDefTemplate.service';

export const updateTemplateMutation = async (
    projectId: string,
    currentTemplateId: number,
    payload: Partial<IColumnTemplate>
) => {
    const result = await req.post(`/projects/${projectId}/patterns/${currentTemplateId}/update`, payload);
    return result;
};

type TTemplateUpdateOptions = {
    showSuccessSnackbar?: boolean;
};
type TTemplateUpdate = (payload: Partial<IColumnTemplate>, options?: TTemplateUpdateOptions) => Promise<void>;

export const useUpdateCurrentTemplate = () => {
    const { currentTemplate } = useTypedSelector(templatesSelector);
    const { projectId } = useParams();
    const dispatch = useAppDispatch();

    const update: TTemplateUpdate = async (payload, options = {}) => {
        if (currentTemplate?.isCommon) return;
        if (!projectId) return;
        if (!currentTemplate?.id) return;

        try {
            const updatedTemplate = {
                ...currentTemplate,
                ...payload,
            };
            await updateTemplateMutation(projectId!, currentTemplate?.id, updatedTemplate);

            // showSuccessSnackbar && enqueueSnackbar('Шаблон успешно изменен', { variant: 'success' })
            dispatch(setCurrentTemplate(updatedTemplate));
            dispatch(refreshList());
        } catch (error) {
            console.log('error', error);
        }
    };

    return { update };
};

export const useGetCurrentTemplateColumnWidths = () => {
    const { currentTemplate } = useTypedSelector(templatesSelector);

    const columnWidths = useMemo(() => {
        if (!currentTemplate) return new Map<string, any>();
        return new Map<string, Nullable<number>>(currentTemplate.columns.map((col) => [col.name, col.width]));
    }, [currentTemplate]);

    return { columnWidths };
};

export const useUpdateDefaultTemplates = ({
    type,
    templateList,
    isPageLoading,
    activeTemplate,
    updateActiveTemplate,
    updateTemplateList,
}: TUseUpdateDefaultTemplatesProps) => {
    const { projectId } = useParams();

    useEffect(() => {
        if (isPageLoading || !isPageWithDefaultTemplates(type)) return;
        if (!templateList.length) return;

        const templatesToUpdate = getTemplatesToUpdate(templateList, type as TPatternsListType);

        if (!templatesToUpdate.length) return;
        updateTemplates(templatesToUpdate);
    }, [isPageLoading, type, templateList]);

    const updateTemplates = async (templates: IColumnTemplate[]) => {
        try {
            const updatedTemplates = await Promise.all(
                templates.map(async (item) => {
                    try {
                        await updateTemplateMutation(projectId!, item.id, item);
                        return item;
                    } catch (error) {}
                })
            );

            const updatedActiveTemplate = updatedTemplates.find((v) => v?.name === activeTemplate?.name);

            if (updatedActiveTemplate) {
                updateActiveTemplate(updatedActiveTemplate);
            }

            updateTemplateList(updatedTemplates.filter((v) => Boolean(v)) as IColumnTemplate[]);
        } catch (error) {
            console.log('error', error);
        }
    };
};
