import { Popover, Tooltip, tooltipClasses } from '@mui/material';
import styled from 'styled-components';

import { ResetButton } from '@/components/ui/UIButton';

export const ConsolidatedCheckboxCellRendererContainer = styled('div')`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BaseButton = styled(ResetButton)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7890b2;
    transition: color 0.3s;

    &:hover {
        color: #0044b4;
    }
`;

export const DragCellRenderButton = styled(BaseButton)``;

export const EditCellRenderButton = styled(BaseButton)``;
export const CollapseCellRenderButton = styled(BaseButton)`
    color: #2b3648;
`;

export const EditCellRendererTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))`
    & .${tooltipClasses.tooltip} {
        padding: 0;
        background-color: blue;
    }
`;

export const EditCellRendererPopover = styled(Popover)``;

export const EditCellRendererPopoverList = styled('ul')`
    display: flex;
    width: fit-content;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`;

export const EditCellRendererPopoverItem = styled('li')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
`;

export const EditCellRendererPopoverButton = styled(ResetButton)`
    height: 40px;
    padding: 5px 16px;
    background: #f6f7fb;
    flex: 1;
    color: #2b3648;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    line-height: normal;
    white-space: nowrap;
    cursor: pointer;
    transition: filter 0.3s;

    &:hover {
        filter: brightness(96%);
    }
`;

const BaseContainer = styled('div')`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const CollapseCellRendererContainer = styled(BaseContainer)``;

export const CheckboxCellRendererContainer = styled(BaseContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CollapseCellRendererIconContainer = styled('div')`
    transform: rotate(180deg);
    transition: transform 0.3s;
    height: 20px;
    width: 20px;

    .collapse & {
        transform: rotate(90deg);
    }
`;

export const CreateRowButtonLine = styled('div')`
    position: fixed;
    left: 12px;
    right: 26px;
    background: #0044b4;
    height: 1px;
`;

export const CreateRowButton = styled(BaseButton)`
    color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-50%);
    z-index: 100;
    border-radius: 100px;
    display: flex;
    width: 28px;
    height: 28px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #0044b4;
    box-shadow: -11px 18px 6px 0px rgba(0, 53, 138, 0), -7px 11px 5px 0px rgba(0, 53, 138, 0.02),
        -4px 6px 4px 0px rgba(0, 53, 138, 0.08), -2px 3px 3px 0px rgba(0, 53, 138, 0.13),
        0px 1px 2px 0px rgba(0, 53, 138, 0.15), 0px 0px 0px 0px rgba(0, 53, 138, 0.15);
    transition: color 0.3s, background-color 0.3s;

    svg {
        width: 12px;
        height: 12px;
    }

    &:hover {
        color: #0044b4;
        background-color: #fff;
    }
`;
