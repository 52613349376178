import { ColDef, ICellRendererParams, IHeaderParams } from 'ag-grid-community';

import { getFormatNumber } from '@/shared/utils/formatNumber.utils';

import { TContext, TData } from './EstimatePositionsTable.types';
import { createCheckboxKey, isChangedCell } from './EstimatePositionsTable.utils';
import { CheckboxCellRenderer, HeaderFilter, TextCellRenderer } from './components/TableCell';

export const columnsDef: ColDef<TData>[] = [
    {
        headerName: 'Осн',
        field: 'main',
        minWidth: 44,
        maxWidth: 44,
        width: 44,
        resizable: false,
        suppressMovable: true,
        headerClass: 'ag-header-cell-center',
        cellClass: 'ag-cell-center',
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: (params: ICellRendererParams<TData, any, TContext>) => {
            if (!params.data?.id) throw Error('id is undefined');
            const id = params.data.id;

            return {
                isConsolidated: params.data.consolidatedID !== null,
                checked: params.context.checkboxesSet.has(createCheckboxKey(id, 'primary')),
                color: '#0288d1',
                onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    params.context.onCheckboxChange(id, 'primary')(event, checked);
                },
            };
        },
    },
    {
        headerName: 'Всп',
        field: 'extra',
        minWidth: 44,
        maxWidth: 44,
        width: 44,
        resizable: false,
        suppressMovable: true,
        headerClass: 'ag-header-cell-center',
        cellClass: 'ag-cell-center',
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: (params: ICellRendererParams<TData, any, TContext>) => {
            if (!params.data?.id) throw Error('id is undefined');
            const id = params.data.id;

            return {
                isConsolidated: params.data.consolidatedID !== null,
                checked: params.context.checkboxesSet.has(createCheckboxKey(id, 'secondary')),
                color: '#ed6c02',
                onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    params.context.onCheckboxChange(id, 'secondary')(event, checked);
                },
            };
        },
    },
    {
        headerName: '№',
        minWidth: 44,
        maxWidth: 44,
        width: 44,
        resizable: false,
        field: 'ordinal',
        suppressMovable: true,
        headerClass: 'ag-header-cell-center',
        cellClass: 'ag-cell-center',
    },
    {
        headerName: 'Шифр ЛСР',
        field: 'lsrCode',
        minWidth: 180,
        maxWidth: 500,
        cellClassRules: {
            'changed-cell': isChangedCell('lsrCode'),
        },
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData, TContext>) => ({
            isApplied: params.context.filters['lsrCode'].values.length > 0,
            onClick: params.context.onFilterVisible('lsrCode'),
        }),
    },
    {
        headerName: '№ по ЛСР',
        field: 'lsrNumber',
        minWidth: 160,
        cellClassRules: {
            'changed-cell': isChangedCell('lsrNumber'),
        },
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['lsrNumber'].values.length > 0,
            textAlign: 'center',
            onClick: params.context.onFilterVisible('lsrNumber'),
        }),
        cellClass: 'ag-cell-center',
    },
    {
        headerName: 'Раздел',
        field: 'chapter',
        minWidth: 180,
        cellClassRules: {
            'changed-cell': isChangedCell('chapter'),
        },
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['chapter'].values.length > 0,
            onClick: params.context.onFilterVisible('chapter'),
        }),
    },
    {
        headerName: 'Заголовок',
        field: 'header',
        minWidth: 150,
        cellClassRules: {
            'changed-cell': isChangedCell('header'),
        },
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['header'].values.length > 0,
            onClick: params.context.onFilterVisible('header'),
        }),
    },
    {
        headerName: 'Код расценки',
        field: 'code',
        minWidth: 180,
        cellClassRules: {
            'changed-cell': isChangedCell('code'),
        },
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['code'].values.length > 0,
            onClick: params.context.onFilterVisible('code'),
        }),
    },
    {
        headerName: 'Наименование',
        field: 'positionName',
        minWidth: 320,
        flex: 1,
        cellClassRules: {
            'changed-cell': isChangedCell('positionName'),
        },
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['positionName'].values.length > 0,
            onClick: params.context.onFilterVisible('positionName'),
        }),
    },
    {
        headerName: 'Ед. изм',
        field: 'rawUnit',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClassRules: {
            'changed-cell': isChangedCell('rawUnit'),
        },
        cellClass: 'ag-cell-center',
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData, TContext>) => ({
            isApplied: params.context.filters['rawUnit'].values.length > 0,
            onClick: params.context.onFilterVisible('rawUnit'),
        }),
    },
    {
        headerName: 'Кол-во',
        field: 'quantity',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClassRules: {
            'changed-cell': isChangedCell('quantity'),
        },
        cellClass: 'ag-cell-center',
    },
    {
        headerName: 'Натур. ед. изм',
        field: 'naturalUnit',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClass: 'ag-cell-center',
        cellRenderer: TextCellRenderer,
        cellClassRules: {
            'changed-cell': isChangedCell('rawUnit'),
        },
    },
    {
        headerName: 'Натур. кол-во',
        field: 'naturalQuantity',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClass: 'ag-cell-center',
        cellClassRules: {
            'changed-cell': isChangedCell('quantity') || isChangedCell('rawUnit'),
        },
    },
    {
        headerName: 'ТЗ ОР, чел-ч',
        field: 'humanHourCost',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClassRules: {
            'changed-cell': isChangedCell('humanHourCost'),
        },
        cellClass: 'ag-cell-right',
    },
    {
        headerName: 'ТЗМ, чел-ч',
        field: 'machineHourCost',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClassRules: {
            'changed-cell': isChangedCell('machineHourCost'),
        },
        cellClass: 'ag-cell-right',
    },
    {
        headerName: 'Всего стоимость',
        field: 'costTotal',
        suppressMovable: true,
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        flex: 1,
        minWidth: 140,
        cellClassRules: {
            'changed-cell': isChangedCell('costTotal'),
        },
        cellClass: 'ag-cell-right',
    },
];
