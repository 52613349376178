import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
    useGetActiveTaskQuery,
    useGetDistributeProgressQuery,
    useGetUploadProgressQuery,
} from '@/api/pingedTasks/pingedTasks.api';

import { usePrevState } from '@/hooks/usePrevState';
import { useUpdateAgGridRowNodes } from '@/hooks/useUpdateAgGridRowNodes';

import { onEditAgKsg, onKSGUpload } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { drawersSelector, toggleDistributionProgress, toggleUploadProgress } from '@/store/slices/drawersSlice';
import {
    distributionTaskSelector,
    resetPingedTasks,
    runningTaskSelector,
    uploadTaskSelector,
} from '@/store/slices/pingedTasksSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { IActiveUploadTask } from '@/types/common.types';

type TUseManageUploadProgressDialogProps = {
    suppressOnClose: boolean;
    suppressWatchBtn: boolean;
};

const POLLING_INTERVAL = 1000;

const useProcessUploadTask = ({ suppressOnClose, suppressWatchBtn }: TUseManageUploadProgressDialogProps) => {
    const runningTask = useTypedSelector(runningTaskSelector);
    const prevRunningTask = usePrevState(runningTask);
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useAppDispatch();
    const drawers = useTypedSelector(drawersSelector);

    const task = useTypedSelector(uploadTaskSelector);
    const result = task?.result;

    const isUploadKSGDrawerHide = !drawers.uploadKsg;
    const isUpdateKSGDrawerHide = !drawers.updateKSG;
    const isUploadProgressDialogNotWrapped = drawers.uploadProgress.openDialog !== 'wrapped';

    const areAllKSGDrawersAndDialogHidden =
        isUploadKSGDrawerHide && isUpdateKSGDrawerHide && isUploadProgressDialogNotWrapped;

    useEffect(() => {
        if (runningTask === 'upload' && areAllKSGDrawersAndDialogHidden) {
            openUploadProgress(task!, suppressOnClose, suppressWatchBtn);
            return;
        }

        if (runningTask !== 'upload') {
            closeUploadProgress();
        }
    }, [runningTask, suppressOnClose, suppressWatchBtn, areAllKSGDrawersAndDialogHidden, task]);

    useEffect(() => {
        if (prevRunningTask === 'upload' && runningTask !== 'upload') {
            if (result && result?.success) {
                dispatch(onKSGUpload());
                enqueueSnackbar('Загрузка завершена', { variant: 'success' });
                return;
            }

            enqueueSnackbar('Возникла ошибка при загрузке', { variant: 'error' });
        }
    }, [runningTask, result]);

    const openUploadProgress = (uploadTask: IActiveUploadTask, suppressOnClose: boolean, suppressWatchBtn: boolean) => {
        dispatch(
            toggleUploadProgress({
                openDialog: true,
                supressOnClose: suppressOnClose,
                supressWatchBtn: suppressWatchBtn,
                activeTask: uploadTask!,
            })
        );
    };

    const closeUploadProgress = () => {
        dispatch(
            toggleUploadProgress({
                openDialog: false,
                supressOnClose: false,
                supressWatchBtn: false,
                activeTask: undefined,
            })
        );
    };
};

const useProcessDistributeTask = () => {
    const runningTask = useTypedSelector(runningTaskSelector);
    const prevRunningTask = usePrevState(runningTask);
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const updateAgGridRowNodes = useUpdateAgGridRowNodes();

    const task = useTypedSelector(distributionTaskSelector);
    const result = task?.result;

    useEffect(() => {
        if (runningTask === 'distribution' && task) {
            dispatch(
                toggleDistributionProgress({
                    open: true,
                    distributionTask: task,
                })
            );
            return;
        }

        dispatch(
            toggleDistributionProgress({
                open: false,
                distributionTask: undefined,
            })
        );
    }, [runningTask, task]);

    useEffect(() => {
        if (prevRunningTask === 'distribution' && runningTask !== 'distribution') {
            if (result && result?.success) {
                dispatch(onEditAgKsg(result.data));
                updateAgGridRowNodes(result.data, (v) => v.id.toString());

                enqueueSnackbar('План успешно распределен', { variant: 'success' });
                return;
            }

            const error = result?.error as string[];
            enqueueSnackbar(error[0] ?? 'Возникла ошибка при распределении', { variant: 'error' });
        }
    }, [runningTask, result]);
};

const getOptions = (skip: boolean) => ({
    pollingInterval: POLLING_INTERVAL,
    skip,
    selectFromResult: () => ({}),
});

const useRunPingedTasks = () => {
    const runningTask = useTypedSelector(runningTaskSelector);
    const { projectId } = useParams();
    const uploadTask = useTypedSelector(uploadTaskSelector);
    const distributionTask = useTypedSelector(distributionTaskSelector);

    useGetActiveTaskQuery({ projectId: projectId! }, getOptions(runningTask !== 'main'));
    useGetUploadProgressQuery(
        {
            projectId: projectId!,
            uploadTask: uploadTask!,
        },
        getOptions(runningTask !== 'upload')
    );
    useGetDistributeProgressQuery(
        { projectId: projectId!, distributionTask: distributionTask! },
        getOptions(runningTask !== 'distribution')
    );
};

export const usePingedTasks = (isKSGCreated: boolean) => {
    const dispatch = useAppDispatch();

    useRunPingedTasks();
    useProcessUploadTask({ suppressOnClose: !isKSGCreated, suppressWatchBtn: !isKSGCreated });
    useProcessDistributeTask();

    useEffect(() => {
        return () => {
            dispatch(resetPingedTasks());
        };
    }, []);
};

export const PingedTasksManage = ({ isKSGCreated }: { isKSGCreated: boolean }) => {
    usePingedTasks(isKSGCreated);

    return null;
};
