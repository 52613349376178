import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { PatternFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';

import { useSendAccessRequestMutation } from '@/api/bingingExternalModules/bindingExternalModules.api';
import { pickSyncModuleType } from '@/api/importingData/importingData.types';

import { ButtonsAndWarningEl } from '@/shared/components/ButtonsAndWarning/ButtonsAndWarning';

import { toggleSynchronize } from '@/store/slices/drawersSlice';
import { useAppDispatch } from '@/store/store';

import { FormError } from '../../../pages/Projects/component/FormikFormC';
import s from '../Synchronize.module.scss';
import { validationSchemaSynchronize } from '../Synchronize.service';

const initialValuesRequestAccessEl = {
    name: '',
    email: '',
    tel: '',
    desc: '',
};

export default function RequestAccessEl({ moduleName }: { moduleName: pickSyncModuleType }) {
    const [accessRequestReq] = useSendAccessRequestMutation();

    const dispatch = useAppDispatch();

    const { projectId } = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = (values: typeof initialValuesRequestAccessEl) => {
        formik.resetForm();
        accessRequestReq({
            body: {
                description: values.desc,
                email: values.email,
                name: values.name,
                phone: values.tel,
                module: moduleName || '',
                projectID: Number(projectId),
            },
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(`Запрос на подключение ${moduleName} отправлен`, {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
        dispatch(toggleSynchronize());
    };

    const formik = useFormik({
        initialValues: initialValuesRequestAccessEl,
        onSubmit,
        validationSchema: validationSchemaSynchronize,
    });

    const inputError = (term: 'name' | 'email' | 'tel' | 'desc') =>
        !!formik.errors[term]?.length ? { backgroundColor: '#fef2f2' } : {};

    return (
        <>
            <p className={s.description}>
                Для отправки запроса на подключение {moduleName} и получение отчетов в последующем по api пожалуйста
                заполните все поля ниже и мы свяжемся с Вами в ближайшее время
            </p>

            <form
                className={s.request_access_wrapper}
                onSubmit={formik.handleSubmit}
            >
                <div className={s.input_sync}>
                    <label htmlFor='name'>Контактное лицо:</label>
                    <input
                        id='name'
                        placeholder='Фамилия Имя Отчество'
                        style={inputError('name')}
                        {...formik.getFieldProps('name')}
                    />
                    <FormError
                        right
                        isTouched={formik.touched.name}
                        error={formik.errors.name}
                    />
                </div>
                <div className={s.input_sync}>
                    <label htmlFor='email'>Почта:</label>
                    <input
                        id='email'
                        placeholder='Info@pragmacore.com'
                        style={inputError('email')}
                        {...formik.getFieldProps('email')}
                    />
                    <FormError
                        right
                        isTouched={formik.touched.email}
                        error={formik.errors.email}
                    />
                </div>
                <div className={s.input_sync}>
                    <label htmlFor='tel'>Телефон:</label>
                    <PatternFormat
                        id='tel'
                        onPaste={(e: any) => e.preventDefault()}
                        placeholder='+7 (900) 000-00-00'
                        style={inputError('tel')}
                        {...formik.getFieldProps('tel')}
                        format='+7 (###) ### ## ##'
                    />

                    <FormError
                        right
                        isTouched={formik.touched.tel}
                        error={formik.errors.tel}
                    />
                </div>

                <div className={s.input_sync}>
                    <label htmlFor='desc'>Описание желаемой интеграции:</label>
                    <textarea
                        id='desc'
                        placeholder='Опишите Ваши пожелания по интеграции'
                        rows={5}
                        style={inputError('desc')}
                        {...formik.getFieldProps('desc')}
                    />
                    <FormError
                        right
                        isTouched={formik.touched.desc}
                        error={formik.errors.desc}
                    />
                </div>
                <div
                    style={{
                        alignSelf: 'flex-end',
                    }}
                >
                    <ButtonsAndWarningEl
                        submitText='отправить'
                        onClose={() => dispatch(toggleSynchronize())}
                        cancelText='закрыть'
                    />
                </div>
            </form>
        </>
    );
}
