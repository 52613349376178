import React from 'react';

type TProps = {
    style?: React.CSSProperties;
};

export const DownloadDocumentIcon: React.FC<TProps> = ({ style }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            style={style}
        >
            <path
                d='M9.33366 1.33337H4.00033C3.26699 1.33337 2.67366 1.93337 2.67366 2.66671L2.66699 13.3334C2.66699 14.0667 3.26033 14.6667 3.99366 14.6667H12.0003C12.7337 14.6667 13.3337 14.0667 13.3337 13.3334V5.33337L9.33366 1.33337ZM12.0003 13.3334H4.00033V2.66671H8.66699V6.00004H12.0003V13.3334Z'
                fill='currentColor'
            />
            <path
                d='M5.33301 9.99329L6.27301 9.05329L7.33301 10.1066V7.33329H8.66634V10.1066L9.72634 9.04663L10.6663 9.99329L8.00634 12.6666L5.33301 9.99329Z'
                fill='currentColor'
            />
        </svg>
    );
};
