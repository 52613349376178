import { AgGridReact } from 'ag-grid-react';
import { MutableRefObject, useCallback, useState } from 'react';

import { TStructureRowType } from '@/api/structure';

import { refreshCell } from '@/shared/utils/agGrid.utils';

import { Nullable } from '@/types/common.types';

import { TData, TEditMenuId } from '../StructureManagementTable.types';
import { generateRowId } from '../utils';

export const useEditMenu = (gridRef: MutableRefObject<AgGridReact<TData> | null>) => {
    const [openedEditMenuRowId, setOpenedEditMenuRowId] = useState<Nullable<TEditMenuId>>(null);

    const handleEditMenuOpen = useCallback(
        (entityId: TEditMenuId, type?: TStructureRowType) => () => {
            setOpenedEditMenuRowId(entityId);

            if (entityId === 'header') {
                gridRef?.current?.api?.refreshHeader();
                return;
            }

            setTimeout(() => {
                refreshCell(gridRef?.current?.api, generateRowId({ rowType: type!, entityId }), 'edit');
            });
        },
        []
    );

    const handleEditMenuClose = useCallback(
        (entityId: TEditMenuId, type?: TStructureRowType) => () => {
            setOpenedEditMenuRowId(null);

            if (entityId === 'header') {
                gridRef?.current?.api?.refreshHeader();
                return;
            }
            setTimeout(() => {
                refreshCell(gridRef?.current?.api, generateRowId({ rowType: type!, entityId }), 'edit');
            });
        },
        []
    );

    return {
        openedEditMenuRowId,
        onEditMenuOpen: handleEditMenuOpen,
        onEditMenuClose: handleEditMenuClose,
    };
};
