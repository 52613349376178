import { CancelOutlined } from '@mui/icons-material';
import { Dialog, DialogProps, Typography } from '@mui/material';
import { SyntheticEvent } from 'react';

import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles';
import { CloseIconBtn, DialogBtn, DialogWrapper } from './ConfirmDialog.styles';

interface IConfirmDialog {
    open: boolean;
    title: string | undefined;
    message?: string;
    onNo?: {
        action?: (e: SyntheticEvent) => void;
        btnText?: string;
        disabled?: boolean;
    } | null;
    onYes: {
        action: (e: SyntheticEvent, switchOpen?: (e?: SyntheticEvent) => void) => void;
        btnText?: string;
        disabled?: boolean;
    } | null;
    DialogProps?: Partial<DialogProps>;
}

export function ConfirmDialog(props: IConfirmDialog) {
    const { open, title, message, onNo, onYes, DialogProps } = props;

    return (
        <Dialog
            open={open}
            {...DialogProps}
        >
            <CloseIconBtn
                size='small'
                disabled={onNo?.disabled}
                onClick={(e) => {
                    onNo?.action && onNo?.action(e);
                }}
            >
                <CancelOutlined />
            </CloseIconBtn>
            <DialogWrapper>
                <Typography
                    color='primary'
                    fontWeight={500}
                >
                    {title}
                </Typography>
                {message && (
                    <Typography
                        fontSize={14}
                        letterSpacing={0.4}
                    >
                        {message}
                    </Typography>
                )}
                <FlexRowWrapper width={'100%'}>
                    <DialogBtn
                        disabled={onYes?.disabled}
                        color='success'
                        onClick={onYes?.action}
                    >
                        {onYes?.btnText || 'Да'}
                    </DialogBtn>
                    <DialogBtn
                        disabled={onNo?.disabled}
                        color='primary'
                        onClick={onNo?.action}
                    >
                        {onNo?.btnText || 'Нет'}
                    </DialogBtn>
                </FlexRowWrapper>
            </DialogWrapper>
        </Dialog>
    );
}
