import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import filtersApi, { queryTermsType } from '@/api/filters/filters.api';

import { Nullable } from '@/types/common.types';

import { RootState } from '../store';

export interface levelsUnlimitedI {
    1?: string[];
    2?: string[];
    3?: string[];
    4?: string[];
    5?: string[];
    6?: string[];
    7?: string[];
    8?: string[];
    9?: string[];
    10?: string[];
    11?: string[];
    12?: string[];
    13?: string[];
    14?: string[];
    15?: string[];
    16?: string[];
}

export interface IMonthPlanFilter {
    date: {
        first: number; // year
        second: number; // month
    };
    status: 'exist' | 'not_exist';
}

interface ICritPathFilter {
    enable: boolean;
    showParent: boolean;
}

export interface filtersKsgUnlimitedI {
    hideFilled: boolean;
    contractorCompany: levelsUnlimitedI;
    contractors: string[];
    objName: levelsUnlimitedI;
    objTitle: levelsUnlimitedI;
    rdCode: levelsUnlimitedI;
    rdStatus: levelsUnlimitedI;
    workName: levelsUnlimitedI;
    workFilter: 'completed' | 'in_process' | 'before_thirty_days' | 'next_month' | null;
    planFilter: IMonthPlanFilter[] | [];
    workGroup: levelsUnlimitedI;
    rdCodes: string[];
    workGroupList: string[];
    showEmptyWorkGroups: boolean;
    criticalFilter: ICritPathFilter;
    workGroupSpecial: boolean;
    brigades: string[];
    undistributedBaseVolume: boolean;
    undistributedOperationalVolume: boolean;
}

const levelsUnlimited = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
};

const filtersKsg: filtersKsgUnlimitedI = {
    hideFilled: false,
    contractorCompany: levelsUnlimited,
    contractors: [],
    objName: levelsUnlimited,
    objTitle: levelsUnlimited,
    rdCode: levelsUnlimited,
    rdCodes: [],
    rdStatus: levelsUnlimited,
    workName: levelsUnlimited,
    workFilter: null,
    planFilter: [],
    workGroup: levelsUnlimited,
    workGroupList: [],
    showEmptyWorkGroups: false,
    criticalFilter: {
        enable: false,
        showParent: true,
    },
    workGroupSpecial: false,
    brigades: [],
    undistributedBaseVolume: false,
    undistributedOperationalVolume: false,
};

export enum FilterLevels {
    One = '1',
    Two = '2',
    Three = '3',
    Four = '4',
    Five = '5',
    Six = '6',
    Seven = '7',
    Eight = '8',
    Nine = '9',
    Ten = '10',
    Eleven = '11',
    Twelve = '12',
    Thirteen = '13',
    Fourteen = '14',
    Fifteen = '15',
    Sixteen = '16',
}

export type TSelectedColumn = Nullable<queryTermsType | 'volumeTotal' | 'brigade'>;

interface initialStateI {
    filters: filtersKsgUnlimitedI;
    queryTerm: queryTermsType;
    selectedColumn: TSelectedColumn;
    openedRowsArr: number[];
    filtersListUnlimited: Record<queryTermsType, levelsUnlimitedI>;
}

const initialFiltersListUnlimited: Record<queryTermsType, levelsUnlimitedI> = {
    objTitle: levelsUnlimited,
    workName: levelsUnlimited,
    rdStatus: levelsUnlimited,
    workGroup: levelsUnlimited,
    objName: levelsUnlimited,
    rdCode: levelsUnlimited,
    contractorCompany: levelsUnlimited,
};

const initialState: initialStateI = {
    filters: filtersKsg,
    queryTerm: 'objTitle',
    selectedColumn: null,
    openedRowsArr: [],
    filtersListUnlimited: initialFiltersListUnlimited,
};

const slice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        refreshFilters(state) {
            state.filters = filtersKsg;
            state.openedRowsArr = [];
            state.filtersListUnlimited = initialFiltersListUnlimited;
        },
        handleChangeWorkFilter(state, action: PayloadAction<filtersKsgUnlimitedI['workFilter']>) {
            state.filters.workFilter = action.payload;
        },
        handleChangeHideFilled(state, action: PayloadAction<boolean>) {
            state.filters.hideFilled = action.payload;
        },
        handleChangeUndistributedBaseVolume(state, action: PayloadAction<boolean>) {
            state.filters.undistributedBaseVolume = action.payload;
        },
        handleChangeUndistributedOperationalVolume(state, action: PayloadAction<boolean>) {
            state.filters.undistributedOperationalVolume = action.payload;
        },
        handleChangeMonthPlanfilter(state, action: PayloadAction<IMonthPlanFilter>) {
            const alreadySetFilterIndex = state.filters.planFilter.findIndex((monthPlanFilter, index) => {
                return (
                    monthPlanFilter.date.first === action.payload.date.first &&
                    monthPlanFilter.date.second === action.payload.date.second
                );
            });
            if (alreadySetFilterIndex >= 0) {
                const newState = [...state.filters.planFilter];
                newState[alreadySetFilterIndex] = action.payload;
                state.filters.planFilter = newState;
                return;
            } else {
                state.filters.planFilter = [...state.filters.planFilter, ...[action.payload]];
            }
        },
        handleResetMonthPlanfilter(state, action: PayloadAction<IMonthPlanFilter['date']>) {
            state.filters.planFilter = state.filters.planFilter.filter((monthPlanFilter) => {
                if (monthPlanFilter.date.first === action.payload.first) {
                    if (monthPlanFilter.date.second === action.payload.second) {
                        return false;
                    }
                }
                return true;
            });
        },
        handleChangeCritPathFilter(state, action: PayloadAction<Partial<filtersKsgUnlimitedI['criticalFilter']>>) {
            state.filters.criticalFilter = { ...state.filters.criticalFilter, ...action.payload };
        },
        handleChangeFilters(
            state,
            action: PayloadAction<{
                checked: boolean;
                level: FilterLevels;
                key?: string;
                arr?: string[];
            }>
        ) {
            const { checked, level, key, arr } = action.payload;
            state.openedRowsArr = [];
            if (key) {
                if (checked) {
                    state.filters[state.queryTerm][level]?.push(key);
                } else {
                    const index = (state.filters[state.queryTerm][level] || [])?.indexOf(key);
                    if (index > -1) {
                        state.filters[state.queryTerm][level]?.splice(index, 1);
                    }
                }
            } else {
                if (arr && checked) {
                    state.filters[state.queryTerm][level] = arr;
                } else {
                    state.filters[state.queryTerm][level] = [];
                }
            }
        },

        setWorkGroup: (
            state,
            action: PayloadAction<{
                workGroupList: filtersKsgUnlimitedI['workGroupList'];
                showEmptyWorkGroups: filtersKsgUnlimitedI['showEmptyWorkGroups'];
            }>
        ) => {
            state.filters.workGroupList = action.payload.workGroupList;
            state.filters.showEmptyWorkGroups = action.payload.showEmptyWorkGroups;
        },

        setRdCodes: (
            state,
            action: PayloadAction<{
                rdCodes: filtersKsgUnlimitedI['rdCodes'];
            }>
        ) => {
            state.filters.rdCodes = action.payload.rdCodes;
        },

        setContractors: (
            state,
            action: PayloadAction<{
                contractors: filtersKsgUnlimitedI['contractors'];
            }>
        ) => {
            state.filters.contractors = action.payload.contractors;
        },

        setQueryTerm(state, action: PayloadAction<queryTermsType>) {
            state.queryTerm = action.payload;
        },
        setSelectedColumn(state, action: PayloadAction<TSelectedColumn>) {
            state.selectedColumn = action.payload;
        },
        setOpenedRowsArr(state, action: PayloadAction<number>) {
            if (!state.openedRowsArr.includes(action.payload)) {
                state.openedRowsArr.push(action.payload);
            } else {
                state.openedRowsArr = state.openedRowsArr.filter((e) => e !== action.payload);
            }
        },
        setWorkGroupSpecialFilter(state, action: PayloadAction<boolean>) {
            state.filters.workGroupSpecial = action.payload;
            if (action.payload === true) {
                state.filters.workGroup = levelsUnlimited;
            }
        },
        setBrigades(state, action: PayloadAction<string[]>) {
            state.filters.brigades = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(filtersApi.endpoints.getFilterListUnlimited.matchFulfilled, (state, action) => {
            state.filtersListUnlimited[state.queryTerm] = action.payload.data;
        });
        builder.addMatcher(filtersApi.endpoints.getFilterListMsgUnlimited.matchFulfilled, (state, action) => {
            state.filtersListUnlimited[state.queryTerm] = action.payload.data;
        });
    },
});

export const {
    setSelectedColumn,
    setWorkGroup,
    setRdCodes,
    setContractors,
    handleChangeFilters,
    handleChangeHideFilled,
    handleChangeUndistributedBaseVolume,
    handleChangeUndistributedOperationalVolume,
    setQueryTerm,
    refreshFilters,
    setOpenedRowsArr,
    handleChangeWorkFilter,
    handleChangeMonthPlanfilter,
    handleResetMonthPlanfilter,
    handleChangeCritPathFilter,
    setWorkGroupSpecialFilter,
    setBrigades,
} = slice.actions;
export default slice.reducer;

export const filtersSelector = (state: RootState) => state.filters;
export const selectedColumnSelector = (state: RootState) => state.filters.selectedColumn;
export const showEmptyWorkGroupsSelector = (state: RootState) => state.filters.filters.showEmptyWorkGroups;
export const workGroupListSelector = (state: RootState) => state.filters.filters.workGroupList;
