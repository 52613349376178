import { CellClassParams, GridApi, IRowNode } from 'ag-grid-community';

import { TEstimatePositionId } from '@/api/estimatePositions';
import { TEstimatePositionChanges } from "@/api/estimatePositions/estimatePositions.types";

import { TData } from './Table.types';

export const redrawRows = (gridApi: GridApi, data: TEstimatePositionId[]) => {
    if (!gridApi) return;

    const rowNodes = data.map((rowId) => gridApi.getRowNode(rowId.toString())) as IRowNode[];
    gridApi.redrawRows({ rowNodes });
};

export const redrawAllRows = (gridApi: GridApi) => {
    gridApi.forEachNodeAfterFilter((row) => {
        gridApi.redrawRows({ rowNodes: [row] });
    });
};

export const isChangedCell = (key: keyof TEstimatePositionChanges) => (params: CellClassParams<TData>) => {
    if (!params.data) return false;
    if ('isFull' in params.data) return false;

    const data = params.data;
    if (data.changes.isDeleted) return false;
    if (data.changes.all) return false;
    return data.changes[key];
};
