import { Autocomplete, AutocompleteProps } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { theme } from '../../styles/theme';
import { DEFAULT_VALIDATION } from './ControlableInputs.service';
import { StyledTextField } from './ControlableInputs.styles';
import { IValidatableInputProps } from './ControlableInputs.types';

interface IAutocompleteProps
    extends Omit<AutocompleteProps<any, undefined, boolean, undefined>, 'renderInput'>,
        IValidatableInputProps {}

export function AutocompleteInput(props: IAutocompleteProps) {
    const { fieldName, validation } = props;

    const formContext = useFormContext();

    const defaultValue = formContext?.formState?.defaultValues && formContext?.formState?.defaultValues[fieldName];

    useEffect(() => {}, [defaultValue]);

    return (
        <Controller
            name={fieldName}
            rules={{ ...DEFAULT_VALIDATION.autocomplete, ...validation }}
            control={formContext.control}
            render={({ field }) => {
                return (
                    //@ts-ignore
                    <Autocomplete
                        {...props}
                        ref={field.ref}
                        value={field.value}
                        onChange={(e, value, reason) => {
                            field.onChange(value);
                            props.onChange && props.onChange(e, value, reason);
                        }}
                        defaultValue={defaultValue}
                        disableClearable
                        fullWidth
                        disablePortal
                        noOptionsText={'Нет значений'}
                        sx={{
                            zIndex: 2,
                            '.MuiAutocomplete-endAdornment': {
                                svg: {
                                    fontSize: '24px',
                                    fill: theme.palette.primary.main,
                                },
                            },
                        }}
                        getOptionLabel={(option) => option.label}
                        loading={props.options == null}
                        loadingText='Загрузка'
                        renderInput={(props) => (
                            <StyledTextField
                                {...props}
                                size='small'
                                fullWidth
                                error={!!formContext?.formState?.errors[fieldName]?.message}
                                sx={{
                                    '.MuiInputBase-root': {
                                        padding: '0px !important',
                                    },
                                    input: {
                                        padding: `${theme.spacing(1, 2)} !important`,
                                    },
                                }}
                            />
                        )}
                    />
                );
            }}
        />
    );
}
