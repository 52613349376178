import { TFolderSVGProps } from './UIFolder.types';

export const FolderSVG: React.FC<TFolderSVGProps> = ({ colors }) => {
    return (
        <svg
            width='21'
            height='18'
            viewBox='0 0 21 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M11.5527 5.91925H17.1667H18.8333H19.3889C19.7206 5.91925 20 6.1932 20 6.54421V16.8751C20 17.2261 19.7206 17.5 19.3889 17.5H1.61111C1.27943 17.5 1 17.2261 1 16.8751V4.2568C1 3.90578 1.27943 3.63184 1.61111 3.63184H2.16667H3.83333H8.19733C8.34689 3.63184 8.49215 3.68799 8.60478 3.79105L10.4702 5.49782C10.766 5.76849 11.1517 5.91925 11.5527 5.91925Z'
                fill={colors.base}
                stroke={colors.baseStroke}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.83301 1.96874C3.83301 1.81342 3.95737 1.6875 4.11079 1.6875H16.8886C17.042 1.6875 17.1663 1.81342 17.1663 1.96874V5.41929H11.5523C11.2771 5.41929 11.0116 5.31583 10.8074 5.12897L8.94198 3.42219C8.73775 3.23533 8.47227 3.13187 8.197 3.13187H3.83301V1.96874Z'
                fill={colors.back}
            />
            <path
                d='M3.83366 1.96869C3.83366 1.81336 3.95802 1.68745 4.11144 1.68745H16.8892C17.0426 1.68745 17.167 1.81336 17.167 1.96869V5.41923H18.8337V1.96869C18.8337 0.881413 17.9631 0 16.8892 0H4.11144C3.03755 0 2.16699 0.88141 2.16699 1.96869V3.13182H3.83366V1.96869Z'
                fill={colors.handle}
            />
        </svg>
    );
};
