import { forwardRef, PropsWithChildren } from 'react';
import { CSSProp } from 'styled-components';

import { Container } from './UIAgGridContainerSecondary.styles';

interface IProps extends PropsWithChildren {
    customStyles?: CSSProp;
}

export const UIAgGridContainerSecondary = forwardRef<HTMLDivElement, IProps>(({ children, customStyles }, ref) => {
    return (
        <Container
            ref={ref}
            className='ag-theme-alpine'
            customStyles={customStyles}
        >
            {children}
        </Container>
    );
});
