import { queryOptions, useMutation } from '@tanstack/react-query';

import { queryKeys } from '@/shared/constants/queryKeys';

import { TCustomHookMutationOptions } from '@/types/query.types';

import { estimatePositionsApi, TEstimatePositionsRequest, TFilterEstimatePositionsRequest } from '../estimatePositions';
import { consolidatedPricesApi } from './consolidatedPrices.api';
import {
    TConsolidatedPricesRequest,
    TCreateBulkConsolidatedPricesRequest,
    TCreateBulkConsolidatedPricesResponse,
    TCreateConsolidatedPricesRequest,
    TCreateConsolidatedPricesResponse,
    TDeleteByIdRequest,
    TDeleteByIdResponse,
    TFetchByIdRequest,
    TFilterConsolidatedPricesRequest,
    TUpdateConsolidatedPricesRequest,
    TUpdateConsolidatedPricesResponse,
} from "./consolidatedPrices.types";

export const getConsolidatedPricesQueryOptions = (props: TConsolidatedPricesRequest) =>
    queryOptions({
        queryKey: [queryKeys.consolidatedPrices.list, props.projectId, props.body],
        queryFn: () => consolidatedPricesApi.fetchList(props),
    });

export const getConsolidatedPricesFilterQueryOptions = (props: TFilterConsolidatedPricesRequest) =>
    queryOptions({
        queryKey: [queryKeys.consolidatedPrices.filter, props.projectId, props.column],
        queryFn: () => consolidatedPricesApi.fetchFilters(props),
    });

export const getConsolidatedPriceQueryOptions = (props: TFetchByIdRequest) =>
    queryOptions({
        queryKey: [queryKeys.consolidatedPrices.item, props.projectId, props.id],
        queryFn: () => consolidatedPricesApi.fetchById(props),
    });

export const useCreateConsolidatedPriceMutation = (
    options?: TCustomHookMutationOptions<TCreateConsolidatedPricesResponse, TCreateConsolidatedPricesRequest>
) =>
    useMutation({
        mutationFn: (payload) => consolidatedPricesApi.create(payload),
        ...options,
    });

export const useCreateBulkConsolidatedPriceMutation = (
    options?: TCustomHookMutationOptions<TCreateBulkConsolidatedPricesResponse, TCreateBulkConsolidatedPricesRequest>
) =>
    useMutation({
        mutationFn: (payload) => consolidatedPricesApi.createBulk(payload),
        ...options,
    });

export const useUpdateConsolidatedPriceMutation = (
    options?: TCustomHookMutationOptions<TUpdateConsolidatedPricesResponse, TUpdateConsolidatedPricesRequest>
) =>
    useMutation({
        mutationFn: (payload) => consolidatedPricesApi.update(payload),
        ...options,
    });

export const useDeleteConsolidatedPriceMutation = (
    options?: TCustomHookMutationOptions<TDeleteByIdResponse, TDeleteByIdRequest>
) =>
    useMutation({
        mutationFn: (payload) => consolidatedPricesApi.deleteById(payload),
        ...options,
    });

export const getConsolidatedPricesEstimatePositionsQueryOptions = (props: TEstimatePositionsRequest) =>
    queryOptions({
        queryKey: [queryKeys.consolidatedPrices.estimatePositionsList, props.listType, props.body],
        queryFn: () => estimatePositionsApi.fetchList(props),
        staleTime: 10000,
    });

export const getConsolidatedPricesEstimatePositionsFilterQueryOptions = (props: TFilterEstimatePositionsRequest) =>
    queryOptions({
        queryKey: [queryKeys.consolidatedPrices.estimatePositionsFilter, props.column],
        queryFn: () => estimatePositionsApi.fetchFilters(props),
        staleTime: 60000,
    });
