import { useQuery } from '@tanstack/react-query';

import { getConsolidatedPricesFilterQueryOptions } from '@/api/consolidatedPrices';

import { consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { useTypedSelector } from '@/store/store';

import { TFilterDialogProps } from './FilterDialog.types';

export const useGetConsolidatedPricesFilter = (props: TFilterDialogProps) => {
    const filters = useTypedSelector(consolidatedPricesSelectors.filters);

    return useQuery({
        ...getConsolidatedPricesFilterQueryOptions({
            projectId: props.projectId,
            column: props.filterKey,
            body: filters,
        }),
    });
};
