import { Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { onEditAgKsg } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { monthMsgSelector } from '@/store/slices/monthMsgSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { updateRowDataMSG } from '../../../AgGridMsg/AgGridMsg.service';
import { req } from '../../../WorkManagment/api/api';
import {
    areDatesInsideMonthlyChartsTimeline,
    getTooltipDescriptionMsgIfBtnDisabled,
    workPlanDatesKeys,
} from './SpreadPlanBtn.service';
import { StyledSpreadBtn } from './SpreadPlanBtn.styles';
import { ISpreadPlanBtnCompProps } from './SpreadPlanBtn.types';

export function SpreadPlanBtn({
    url,
    cellRendererParams,
    typeOfPlan,
    typeOfReport = 'ksg',
    spreadPlanFunc,
}: ISpreadPlanBtnCompProps) {
    const { projectId } = useParams();
    let { data: workData, api: gridApi } = cellRendererParams;
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');
    const isBtnActive =
        Boolean(workData[workPlanDatesKeys[typeOfPlan].startDate]) &&
        areDatesInsideMonthlyChartsTimeline(cellRendererParams, typeOfPlan, typeOfReport);

    const { month, year } = useTypedSelector(monthMsgSelector);
    const dispatch = useAppDispatch();

    const getTargetURL = () => {
        let targetUrl = `/projects/${projectId}/works${
            workData.level === 0 ? '' : '/' + workData?.id
        }/distribute/${typeOfReport}`;

        switch (typeOfPlan) {
            case 'base':
                targetUrl += '/base';
                break;
            case 'operational':
                targetUrl += '/plan-fact' + (workData?.level === 0 ? '-all' : '');
                break;
        }

        switch (typeOfReport) {
            case 'msg':
                targetUrl += `?month=${month + 1}&year=${year}`;
                break;
        }

        return targetUrl;
    };

    function spreadPlan() {
        gridApi.showLoadingOverlay();
        document.getElementsByClassName('ag-overlay-wrapper')[0].innerHTML = 'Идет распределение плана...';
        req.post(url ?? getTargetURL())
            .then(({ data }) => {
                if (typeOfReport === 'ksg') {
                    dispatch(onEditAgKsg(data.data));
                } else {
                    updateRowDataMSG(data.data);
                }
                gridApi.applyTransaction({ update: data.data });
            })
            .then(() => {
                enqueueSnackbar('План успешно распределен', {
                    variant: 'success',
                });
                gridApi?.hideOverlay();
            })
            .catch((e) => {
                enqueueSnackbar(t('bind_errors.' + e.response.data), {
                    variant: 'error',
                });
                gridApi?.hideOverlay();
            });
    }

    return (
        <Tooltip
            title={
                isBtnActive ? '' : getTooltipDescriptionMsgIfBtnDisabled(cellRendererParams, typeOfPlan, typeOfReport)
            }
        >
            <StyledSpreadBtn
                active={isBtnActive}
                onClickCapture={(event) => {
                    event.nativeEvent.stopPropagation();

                    if (!isBtnActive) return event;
                    if (spreadPlanFunc) {
                        spreadPlanFunc();
                        return event;
                    }

                    spreadPlan();
                    return event;
                }}
            >
                Распределить
            </StyledSpreadBtn>
        </Tooltip>
    );
}
