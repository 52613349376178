import { ICellEditorParams, ICellRendererParams, IHeaderParams, SuppressKeyboardEventParams } from "ag-grid-community"
import { COL_DEF_TEMPLATE } from "../../../WorkManagment/components/AgGridService/AgGridColumnDef.service"
import { IAutocompleteOption } from "../../../WorkManagment/components/CellEditors/CellEditor/CellEditor.types"
import { IStaffColDefs } from "../../../WorkManagment/subPages/Resources/ResourceCard/ColDefs/StaffTable.colDef"

function addNewRow(params: IHeaderParams) {
    params.api.applyTransaction({
        add: [
            {
                id: "draft",
                nameProf: '',
                profId: undefined,
                plan: 0
            },
        ],
    })
    setTimeout(() => {
        params.api.startEditingCell({
            rowIndex: params.api.getRowNode("draft")?.rowIndex as number,
            colKey: 'nameProf',
        })
        params.api.setFocusedCell(params.api.getRowNode("draft")?.rowIndex as number, 'nameProf')
        params.api.ensureNodeVisible(params.api.getRowNode("draft"))
    })
}

function removeRow (params: ICellRendererParams) {
    params.api.applyTransaction({ remove: [params.data] })
}

export const staffTableColumnDef: IStaffColDefs[] = [
    {
        ...COL_DEF_TEMPLATE.ADD_NEW_ROW_TABLE_COL(addNewRow),
    },
    {
        headerName: 'Специальность',
        field: 'nameProf',
        editable: true,
        flex: 2,
        headerClass: 'ag-cell-left',
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.AUTOCOMPLETE,
        cellClass: 'ag-cell-left ag-cell-editable',
        cellEditorParams: (params: ICellEditorParams) => {
            const excludedNames: string[] = []
            params.api.forEachNode((row) => {
                excludedNames.push(row.data.nameProf.label)
            })
            const excludeDoublesOptions = params.context.proffList.filter((proff: IAutocompleteOption) => !excludedNames.includes(proff.label))
            return {
                options: excludeDoublesOptions,
                dataType: 'autocomplete',
                withNewOptionCreation: true,
                validation: {
                    maxLength: {
                        value: 150,
                        message: 'Макс 150',
                    },
                },
            }
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
            if (document.getElementsByClassName('MuiAutocomplete-popper')[0]) {
                return true
            } else {
                return false
            }
        },
    },
    {
        ...COL_DEF_TEMPLATE.DELETE_ROW_TABLE_COL((params) => removeRow(params)),
    },
]