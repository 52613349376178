import { useInfiniteQuery } from '@tanstack/react-query';

import { consolidatedPricesApi } from '@/api/consolidatedPrices';

import { queryKeys } from '@/shared/constants/queryKeys';

import { consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { useTypedSelector } from '@/store/store';

import { LIMIT } from './ConsolidatedPricesTable.config';
import { TUseGetConsolidatedPrices } from './ConsolidatedPricesTable.types';

export const useGetConsolidatedPrices = ({ projectId, limit = LIMIT }: TUseGetConsolidatedPrices) => {
    const filters = useTypedSelector(consolidatedPricesSelectors.filters);

    return useInfiniteQuery({
        queryKey: [queryKeys.consolidatedPrices.list, projectId, filters],
        queryFn: ({ pageParam = 0 }) => {
            return consolidatedPricesApi.fetchList({
                body: filters,
                limit,
                offset: pageParam,
                projectId: projectId,
            });
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, _, pageParam) => {
            const total = lastPage.total;
            const nextPageParam = pageParam + limit;

            return nextPageParam <= total ? nextPageParam : undefined;
        },
        select: (data) => {
            const consolidated = data.pages.flatMap((page) => page.consolidated);
            return {
                ...data,
                consolidated,
                total: data.pages[0].total,
            };
        },
    });
};
