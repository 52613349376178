import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';

import { ResetButton } from '@/components/ui/UIButton';

export const Container = styled(Stack).attrs({
    direction: 'row',
    alignItems: 'center',
    gap: 0.5,
})`
    background: rgba(255, 246, 0, 0.15);
    border-radius: 4px;
    height: 35px;
`;

export const Text = styled(Typography)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #2b3648;
    font-weight: 500;
    border-radius: 6px;
    width: 235px;
    height: 100%;
`;

export const Button = styled(ResetButton)`
    background: #d9cf15;
    border-radius: 6px;
    padding: 4px 8px;
    width: 110px;
    height: 36px;
    font-weight: 500;
    font-size: 13px;
    line-height: 169%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #2b3648;
    transition: all 0.3s;
    height: 100%;

    &:hover {
        filter: opacity(80%);
    }

    &:disabled {
        filter: opacity(50%);
        pointer-events: none;
    }
`;
