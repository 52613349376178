import { Modal } from '@mui/material';
import { ColumnResizedEvent, ColumnState, GetRowIdParams, GridApi, RowHeightParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import { Suspense, lazy, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api';
import { Work, WorkOrWorkPosition } from '@/api/ksg/ksg.types';

import {
    useGetCurrentTemplateColumnWidths,
    useUpdateCurrentTemplate,
} from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.model';
import { getFilteredColumnsState } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.service';
import { TTemplateColumn } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.types';
import { useOpenDeleteZeroValueFactDialog } from '@/components/DeleteZeroValueFactDialog';
import GanttRelations from '@/components/GanttRelations/GanttRelations';

import { useLatest } from '@/hooks/useLatest';
import { useToggleSearchParam } from '@/hooks/useToggleSearchParam';
import { usePagination } from '@/hooks/useVerticalPagination';

import { submitCellData, transformWorkPositionToWork } from '@/pages/AgGrid/AgGrid.service';
import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { AG_GRID_DEFAULT_PARAMS_KSG } from '@/pages/WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { LoadingOverlay } from '@/pages/WorkManagment/components/AgGridService/components/LoadingOverlay';

import { HEADER_ROW_HEIGHT } from '@/shared/constants/agGrid';
import { IntegrationWithOfferSearchParam } from '@/shared/constants/integrationWithOffer';
import { isWorkPosition } from '@/shared/guards/works.guards';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { preserveScroll } from '@/shared/utils';
import { getPaginationInitialParams } from '@/shared/utils/works.utils';

import { agGridKsgSelector, agGridListMode } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { IGetParamsKSG, getWorksAgGrid, getWorksAgGridOrphan } from '@/store/slices/agGridKsgMsgSlices/ksgThunks';
import { authSelector } from '@/store/slices/authSlice';
import { drawersSelector, setAgGrid, toggleUploadProgress } from '@/store/slices/drawersSlice';
import { filtersSelector } from '@/store/slices/filtersSlice';
import { integrationStatusSelector } from '@/store/slices/integrationStatusSlice';
import { runningTaskSelector } from '@/store/slices/pingedTasksSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { getSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { WORKS_FETCH_LIMIT } from './KSGTable.constants';
import './KSGTable.scss';
import { TAgGridContext } from './KSGTable.types';
import { KsgAddLevelDialog } from './components/KSGAddLevelDialog';
import { FilterDialogs } from './components/KSGFilterDialogs';
import { KSGPlaceholder } from './components/KSGPlaceholder';
import { useCollectionOfFactOfAct } from './model/useCollectionOfFactOfAct';
import { useDeleteRow } from './model/useDeleteRow';
import { useGetAppliedFilters } from './model/useGetAppliedFilters';
import { useGetMinMaxLevels } from './model/useGetMinMaxLevels';
import { useGetRowClassRules } from './model/useGetRowClassRules';
import { useHiddenRows } from './model/useHiddenRows';
import { PingedTasksManage } from './model/usePingedTasks';
import { useToggleFilterDialog } from './model/useToggleFilterDialog';
import { useToggleTag } from './model/useToggleTag';
import { getColDefs } from './utils';

const GanttTable = lazy(() => import('./components/GanttTable').then((c) => ({ default: c.GanttTable })));

const Table = () => {
    const {
        hiddenRows,
        isRowHidden,
        update: updateHiddenRows,
        reset: resetHiddenRows,
        setNewData: setHiddenRows,
    } = useHiddenRows();

    const [mutationsLoading, setMutationsLoading] = useState(false);
    const [isOpenAddLevel, setIsOpenAddLevel] = useState(false);
    const [addLevelData, setAddLevelData] = useState<{
        id: number | null;
        index: number;
        level: number | null;
    }>({
        id: 0,
        index: 0,
        level: null,
    });
    const [relationsOpened, setRelationsOpened] = useState<boolean>(false);
    const [relationData, setRelationData] = useState<null | {
        workName: string;
        startDate: string | null;
        endDate: string | null;
        id: number;
    }>(null);
    const [rowHeights, setRowHeights] = useState<{
        [key: number]: number;
    }>({});
    const openDeleteZeroValueFactDialog = useOpenDeleteZeroValueFactDialog();

    const { filters } = useTypedSelector(filtersSelector);
    const {
        worksList,
        isKSGUpdatedByExcel,
        isKSGUploaded,
        rowHeight: globalRowHeight,
        minRowHeight,
        isOfferResynchronized,
    } = useTypedSelector(agGridKsgSelector);
    const { token } = useTypedSelector(authSelector);
    const { profile } = useTypedSelector(profileSelector);
    const listMode = useTypedSelector(agGridListMode);
    const location = useLocation();

    /* Данные с стора для базового плана */
    const { costDoneMode } = useTypedSelector(getSettings);

    const { syncModuleData, triggerIfBackupRestored } = useTypedSelector(drawersSelector);

    const dispatch = useAppDispatch();

    const gridRef = useRef<AgGridReact>(null);
    const rowHeightsRef = useRef<{
        [key: number]: number;
    }>({});
    const columnState = useRef<any[]>([]);

    // TODO:
    // Проверить как будет работать useEffect
    if (gridRef?.current) {
        dispatch(setAgGrid(gridRef?.current));
    }

    const { projectId } = useParams();
    const { isActive } = useToggleSearchParam(IntegrationWithOfferSearchParam);
    const integrationStatus = useTypedSelector(integrationStatusSelector);
    const isOfferActive = integrationStatus?.isImportFromOffer && isActive;

    const [urlSearchParams] = useSearchParams();

    const { update: updateCurrentTemplate } = useUpdateCurrentTemplate();
    const { columnWidths } = useGetCurrentTemplateColumnWidths();

    const { enqueueSnackbar } = useSnackbar();
    const { t: tCPG } = useTranslation('cpg');

    const { refetch: refetchCollectionOfFactOfAct } = useCollectionOfFactOfAct();

    const [isLoading, setIsLoading] = useState(true);

    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
    });

    const { minLevelByListMode } = useGetMinMaxLevels(worksList?.data, listMode);

    useLayoutEffect(() => {
        if (!worksList || !listMode) return;
        if (!minLevelByListMode) {
            resetHiddenRows();
            return;
        }

        setHiddenRows(
            worksList.data
                .filter((v) => v.level === minLevelByListMode)
                .map((v) => ({
                    id: v.id,
                    code: v.code,
                }))
        );
    }, [worksList, listMode, minLevelByListMode]);

    const isVisibleGantt = Boolean(gridRef.current && urlSearchParams.has('g'));

    const isKSGCreated = Boolean(headersCPG?.data?.isCreatedKSG);

    const runningTask = useTypedSelector(runningTaskSelector);
    const isUploadTaskRunning = runningTask === 'upload';

    useEffect(() => {
        if (!gridRef.current) return;
        dispatch(setAgGrid(gridRef?.current));
    });

    useEffect(() => {
        return () => {
            dispatch(
                toggleUploadProgress({
                    openDialog: false,
                    supressOnClose: false,
                    supressWatchBtn: false,
                    activeTask: undefined,
                })
            );
            dispatch(setAgGrid(null));
        };
    }, [location.pathname, projectId]);

    useEffect(() => {
        mutationsLoading && console.info('loading');
    }, [mutationsLoading]);

    /* возвращает сразу массив данных учитывая фильтры либо null */
    const [filteredWorks, setFilterWorks] = useState<Work[] | null>([]);

    const rowData = useMemo(() => {
        if (!isOfferActive || listMode) return filteredWorks;

        return filteredWorks?.flatMap((item) => {
            if (!item.workPosition) return item;

            if (
                !hiddenRows.some((v) =>
                    isEqual(v, {
                        id: item.id,
                        code: item.code,
                    })
                )
            ) {
                return item;
            }

            return [item, ...item?.workPosition?.map((position) => transformWorkPositionToWork(item, position))];
        });
    }, [filteredWorks, listMode, isOfferActive]) as WorkOrWorkPosition[];

    useLayoutEffect(() => {
        setFilterWorks(() => {
            if (worksList) {
                const filtArr = worksList?.data.reduce((acc: Work[], prev) => {
                    const idx = hiddenRows.every((filItem) => {
                        if (prev.code === filItem.code) {
                            return true;
                        }

                        return !filItem.code.split('-').every((v) => prev.code.split('-').includes(v));
                    });

                    return idx ? [...acc, prev] : acc;
                }, []);

                return filtArr?.length ? filtArr : worksList.data;
            } else {
                return null;
            }
        });
        return () => {
            setFilterWorks(null);
        };
    }, [hiddenRows, urlSearchParams, worksList]);

    const getData = useCallback(
        (params: IGetParamsKSG) => {
            setIsLoading(true);
            const sendingFunc = listMode ? getWorksAgGridOrphan : getWorksAgGrid;
            gridRef.current?.api?.showLoadingOverlay();
            return sendingFunc(params);
        },
        [listMode]
    );

    const scrollParams = JSON.parse(localStorage.getItem('scrollParams')!);
    const lastRenderedRow = scrollParams && scrollParams[location.pathname]?.lastRenderedRow;

    const totalRows = worksList?.total ?? 0;
    const isAllDataFetched = totalRows === worksList?.data?.length;

    usePagination<IGetParamsKSG>({
        initialParams: getPaginationInitialParams(lastRenderedRow, projectId!, WORKS_FETCH_LIMIT),
        sendFn: getData,
        thenFn: () => {
            gridRef.current?.api?.hideOverlay();
            setIsLoading(false);
        },
        catchFn: () => {
            gridRef.current?.api?.hideOverlay();
            setIsLoading(false);
        },
        totalCount: totalRows,
        requiredDeps: [projectId, totalRows !== null, token],
        resetToInitialDeps: [
            isOfferResynchronized,
            filters,
            listMode,
            projectId,
            isKSGUpdatedByExcel,
            isKSGUploaded,
            syncModuleData,
            triggerIfBackupRestored,
        ],
        isAllDataFetched,
    });

    const appliedFilters = useGetAppliedFilters();
    const { isOpenFilterDialog, openFilterDialog, closeFilterDialog } = useToggleFilterDialog();

    const onDeleteRow = useDeleteRow(gridRef.current?.api);
    const onToggleTag = useToggleTag();

    const getRowId = useCallback(({ data }: GetRowIdParams<WorkOrWorkPosition>) => {
        if (isWorkPosition(data)) return `${data?.parentId}_${data?.id}`;
        return data?.id.toString();
    }, []);

    // TODO:
    // 1. Добавить также в МСГ
    const rowClassRules = useGetRowClassRules();

    //=== RESIZE ROW =======//

    const actualGlobalRowHeight = useLatest(globalRowHeight);

    const getRowHeight = (params: RowHeightParams<WorkOrWorkPosition, TAgGridContext>): number | null | undefined => {
        if (params.node.rowIndex === null) return actualGlobalRowHeight.current;
        return rowHeightsRef.current[params.node.rowIndex] ?? actualGlobalRowHeight.current;
    };

    const [getRowHeightEnabled, setGetRowHeightEnabled] = useState(false);
    const enableGetRowHeight = (gridApi: GridApi) => {
        setGetRowHeightEnabled(true);
        gridApi.resetRowHeights();
    };

    useEffect(() => {
        if (isLoading) return;

        const timerId = setTimeout(() => enableGetRowHeight(gridRef.current?.api!), 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [isLoading]);

    const getRowHeightIfEnabled = useMemo(() => {
        if (!getRowHeightEnabled) return {};

        return {
            getRowHeight,
        };
    }, [getRowHeightEnabled]);

    const handleRowHeightChange = useCallback((rowIndex: number, deltaY: number) => {
        const newHeight = (rowHeightsRef.current[rowIndex] || actualGlobalRowHeight.current) + deltaY;
        rowHeightsRef.current = {
            ...rowHeightsRef.current,
            [rowIndex]: Math.max(newHeight, minRowHeight),
        };
        setRowHeights((prevHeights) => {
            return {
                ...prevHeights,
                [rowIndex]: Math.max(newHeight, minRowHeight),
            };
        });
        if (gridRef.current?.api) {
            gridRef.current.api.resetRowHeights();
        }
    }, []);

    const handleColumnResized = (event: ColumnResizedEvent) => {
        if (event.source !== 'api' && event.source !== 'uiColumnDragged') {
            return;
        }
        if (event.finished && gridRef.current) {
            const columnApi = event.columnApi;
            const newState = columnApi.getColumnState();

            columnState.current = newState;
            updateCurrentTemplate({
                columns: getFilteredColumnsState(gridRef.current).map((columnState) => ({
                    name: (columnState as ColumnState).colId,
                    hide: (columnState as ColumnState).hide,
                    pinned: (columnState as ColumnState).pinned,
                    width: (columnState as ColumnState).width,
                })) as TTemplateColumn[],
            });
        }
    };

    const getSubmitCellDataThenFn = (prop: string | undefined) => {
        const map: Record<string, () => void> = {
            toClose: () => refetchCollectionOfFactOfAct(),
            default: () => {},
        };

        if (!prop) return map.default;
        return map[prop] ?? map.default;
    };

    useEffect(() => {
        if (columnState.current.length && gridRef.current) {
            gridRef.current.columnApi.applyColumnState({
                state: columnState.current,
                applyOrder: true,
            });
        }
    }, [gridRef.current, columnState.current]);

    useEffect(() => {
        rowHeightsRef.current = {};
        if (gridRef.current?.api) {
            gridRef.current.api.resetRowHeights();
        }
    }, [globalRowHeight]);

    //======================//

    useEffect(() => {
        headersCPG.refetch();
    }, [isKSGUploaded]);

    const context = useMemo(() => {
        return {
            projectId: projectId!,
            appliedFilters,
            openFilterDialog,
            openDeleteZeroValueFactDialog,
            onDeleteRow,
            onToggleTag,
            isRowHidden,
            hiddenRows,
            updateHiddenRows,
        } as TAgGridContext;
    }, [appliedFilters, projectId]);

    useEffect(() => {
        gridRef.current?.api?.refreshCells();
        gridRef?.current?.api?.refreshHeader();
    }, [context]);

    const columnDefs = useMemo(() => {
        return getColDefs({
            isVisibleGantt: isVisibleGantt,
            setAddLevelData: setAddLevelData,
            setIsOpenAddLevel: setIsOpenAddLevel,
            location: location,
            profile: profile,
            gridRef: gridRef,
            setRelationsOpened: setRelationsOpened,
            setRelationData: setRelationData,
            headersCPG: headersCPG,
            costDoneMode: costDoneMode,
            setMutationsLoading: setMutationsLoading,
            isOfferActive: isOfferActive,
            integrationStatus: integrationStatus,
            handleHeightChange: handleRowHeightChange,
            refetchCollectionOfFactOfAct: refetchCollectionOfFactOfAct,
            columnWidths: columnWidths,
        });
    }, [
        isVisibleGantt,
        setAddLevelData,
        setIsOpenAddLevel,
        location,
        profile,
        gridRef,
        hiddenRows,
        setRelationsOpened,
        setRelationData,
        headersCPG,
        costDoneMode,
        setMutationsLoading,
        isOfferActive,
        integrationStatus,
        handleRowHeightChange,
        columnWidths,
        appliedFilters,
    ]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
        };
    }, []);

    if (!isKSGCreated) return <KSGPlaceholder />;

    return (
        <>
            <LoadingOverlay
                key='overlay'
                open={isLoading}
                transitionDuration={0}
                sx={{
                    zIndex: 999,
                }}
            />

            <KsgAddLevelDialog
                data={addLevelData}
                isOpen={isOpenAddLevel}
                grid={gridRef.current}
                onClose={() => setIsOpenAddLevel(false)}
            />

            <FilterDialogs
                projectId={projectId!}
                isOpen={isOpenFilterDialog}
                onClose={closeFilterDialog}
            />

            <Modal
                open={relationsOpened}
                onClose={() => {
                    setRelationsOpened(false);
                    setRelationData(null);
                }}
            >
                <span>
                    <GanttRelations
                        setRelationsOpened={setRelationsOpened}
                        increaseRelationsCount={() => {}}
                        initialWorkData={relationData!}
                    />
                </span>
            </Modal>
            <PingedTasksManage isKSGCreated={isKSGCreated} />

            <FlexColumnWrapper>
                <FlexRowWrapper
                    className='table-wrapper ksg'
                    style={
                        {
                            '--ag-body-vertical-scroll-display': `${isVisibleGantt ? 'none' : 'flex'}`,
                        } as React.CSSProperties
                    }
                    height={'100%'}
                    width={'100%'}
                    gap={0}
                    pb={2}
                >
                    <div
                        className='ag-theme-alpine'
                        style={{
                            flex: '1 1 0',
                            height: '100%',
                            paddingRight: '0.5rem',
                        }}
                        onClickCapture={(e) => {
                            if (isUploadTaskRunning) {
                                e.stopPropagation();
                                e.preventDefault();
                            }
                        }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            getRowId={getRowId}
                            rowClassRules={rowClassRules}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            context={context}
                            {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                            onCellValueChanged={(params) => {
                                submitCellData({
                                    params: params,
                                    projectID: projectId as string,
                                    currentAct: integrationStatus?.currentAct ?? null,
                                    enqueueSnackbar: enqueueSnackbar,
                                    translate: tCPG,
                                    dispatch: dispatch,
                                    thenFn: getSubmitCellDataThenFn(params.colDef.field),
                                    openDeleteZeroValueFactDialog: openDeleteZeroValueFactDialog,
                                });
                            }}
                            onCellClicked={(params) => {
                                params.event?.preventDefault();
                                params.event?.stopImmediatePropagation();
                            }}
                            {...((!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_KSG') ||
                                isUploadTaskRunning) && {
                                suppressClickEdit: true,
                            })}
                            rowHeight={globalRowHeight}
                            // getRowHeight={isVisibleGantt ? null : getRowHeight}
                            defaultColDef={defaultColDef}
                            onColumnResized={handleColumnResized}
                            headerHeight={HEADER_ROW_HEIGHT}
                            groupHeaderHeight={HEADER_ROW_HEIGHT}
                            loadingOverlayComponentParams={{
                                loading: true,
                            }}
                            onFirstDataRendered={(event) => {
                                preserveScroll(event, location);
                                enableGetRowHeight(event.api);
                            }}
                            {...getRowHeightIfEnabled}
                            // TODO:
                            // 1. В дальнейшем нужно добавить. Проблема что при включении, в cellEditor с date нельзя открыть календарь
                            // stopEditingWhenCellsLoseFocus
                            // debug
                        />
                    </div>
                    {isVisibleGantt && (
                        <Suspense fallback={null}>
                            <GanttTable
                                filteredWorks={filteredWorks}
                                grid={gridRef.current!}
                                rowHeights={rowHeights}
                                setRowHeights={setRowHeights}
                            />
                        </Suspense>
                    )}
                </FlexRowWrapper>
            </FlexColumnWrapper>
        </>
    );
};

export const KSGTable = () => {
    const { projectId } = useParams();

    return <Table key={projectId} />;
};
