import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { Checkbox } from './UICircleCheckbox.styles';
import { IUICircleCheckboxProps } from './UICircleCheckbox.types';

export const UICircleCheckbox: React.FC<IUICircleCheckboxProps> = ({
    checked,
    onChange,
    checkedIconProps = {},
    checkboxProps = {},
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onChange(event, checked);
    };

    return (
        <Checkbox
            checked={checked}
            onChange={handleChange}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon {...checkedIconProps} />}
            {...checkboxProps}
        />
    );
};
