import { ICellRendererParams } from 'ag-grid-community';
import cn from 'classnames';

import { cpgViewEnum, cpgViewSelector } from '@/store/slices/cpgViewSlice';
import { getSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { useTypedSelector } from '@/store/store';

import '../../AgGrid/AgGrid.scss';
import { useIsCashViewWithAllSettings } from '../AgGridMsg.model';

interface IProps {
    cellRendererParams: ICellRendererParams<any>;
    isGantt?: boolean;
    isResourceTable?: boolean;
    customContainerClassName?: string;
}

export default function RowMonthHeaderRendererMsg({
    cellRendererParams,
    isGantt = false,
    isResourceTable = false,
    customContainerClassName = '',
}: IProps) {
    const data = cellRendererParams.data;
    const { msgView } = useTypedSelector(cpgViewSelector);
    const { verificationPlan, msgForecast, msgDailyPercentage } = useTypedSelector(getSettings);
    const isCashViewWithAllSettings = useIsCashViewWithAllSettings();

    const isRoot = data?.level === 0;
    const hasChildren = data?.hasChildren;
    const isVolumeFloatedToParent = data?.unit && hasChildren;
    const isChild = !data?.hasChildren && !isRoot;
    const background = {
        style: {
            backgroundColor: 'inherit',
        },
    };

    if (isGantt) {
        return <div className='grid_center'>Гант</div>;
    }

    if (msgView !== cpgViewEnum.blank && !isResourceTable) {
        return (
            <div
                className={cn('month_container', customContainerClassName, {
                    'month_container--large': isRoot && isCashViewWithAllSettings,
                })}
            >
                <div
                    className='cell_body_plan not_month bb'
                    {...(hasChildren && background)}
                >
                    Опер. план
                </div>
                <div
                    className={`cell_body_fact ${isChild ? 'cell_body_fact--white' : ''} not_month bb`}
                    {...(hasChildren && background)}
                >
                    Факт
                </div>

                {msgForecast && (
                    <div
                        className={`cell_body_plan not_month bb`}
                        {...(hasChildren && background)}
                    >
                        Прогноз
                    </div>
                )}
                {verificationPlan && (
                    <div
                        className={`cell_body_forecast not_month bb`}
                        {...(hasChildren && background)}
                    >
                        Вер. факт
                    </div>
                )}

                {isRoot && msgDailyPercentage && msgView === 'cash' && (
                    <div
                        className={`cell_body_plan not_month`}
                        {...(hasChildren && background)}
                    >
                        % суточный
                    </div>
                )}
            </div>
        );
    }

    if (isRoot && !isGantt) {
        return <div className='grid_center'>КС3</div>;
    }

    if (msgView === cpgViewEnum.blank && (isVolumeFloatedToParent || isChild)) {
        return (
            <div className={cn('month_container', customContainerClassName)}>
                <div
                    className='cell_body_plan not_month bb'
                    {...(hasChildren && background)}
                >
                    Опер. план
                </div>
                <div
                    className={`cell_body_fact ${isChild ? 'cell_body_fact--white' : ''} not_month bb`}
                    {...(hasChildren && background)}
                >
                    Факт
                </div>
                {msgForecast && (
                    <div
                        className={`cell_body_plan not_month bb`}
                        {...(hasChildren && background)}
                    >
                        Прогноз
                    </div>
                )}
                {verificationPlan && (
                    <div
                        className={`cell_body_forecast not_month bb`}
                        {...(hasChildren && background)}
                    >
                        Вер. факт
                    </div>
                )}
            </div>
        );
    }

    return <div className={cn('month_container', customContainerClassName)} />;
}
