import React from 'react';

import { FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';

import { CloudSyncIcon } from '@/shared/SVG/Svg';

import { StatusIconContainer, StatusText } from './ControlPanel.styles';
import { ISyncStatusProps } from './ControlPanel.types';

export const SyncStatus: React.FC<ISyncStatusProps> = () => {
    return (
        <FlexRowWrapper gap={1}>
            <StatusIconContainer>
                <CloudSyncIcon />
            </StatusIconContainer>
            <StatusText>Синхронизован с Estimation</StatusText>
        </FlexRowWrapper>
    );
};
