import { ColDef, IHeaderParams } from 'ag-grid-community';

import { getFormatNumber } from '@/shared/utils/formatNumber.utils';

import { TContext, TData } from './ConsolidatedPricesTable.types';
import { ChangesSummaryCellRenderer, HeaderFilter, TextCellRenderer } from './components/TableCell';

export const columnsDef: ColDef<TData>[] = [
    {
        headerName: '№',
        minWidth: 44,
        maxWidth: 44,
        width: 44,
        resizable: false,
        field: 'ordinal',
        suppressMovable: true,
        headerClass: 'ag-header-cell-center',
        cellClass: 'ag-cell-center',
    },
    {
        headerName: 'Шифр ЛСР',
        field: 'lsrCode',
        minWidth: 180,
        maxWidth: 500,
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData, TContext>) => ({
            isApplied: params.context.filters['lsrCode'].values.length > 0,
            onClick: params.context.onFilterVisible('lsrCode'),
        }),
    },
    {
        headerName: '№ по ЛСР',
        field: 'lsrNumber',
        minWidth: 160,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['lsrNumber'].values.length > 0,
            textAlign: 'center',
            onClick: params.context.onFilterVisible('lsrNumber'),
        }),
        cellClass: 'ag-cell-center',
    },
    {
        headerName: 'Раздел',
        field: 'chapter',
        minWidth: 180,
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['chapter'].values.length > 0,
            onClick: params.context.onFilterVisible('chapter'),
        }),
    },
    {
        headerName: 'Заголовок',
        field: 'header',
        minWidth: 150,
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['header'].values.length > 0,
            onClick: params.context.onFilterVisible('header'),
        }),
    },
    {
        headerName: 'Код расценки',
        field: 'code',
        minWidth: 180,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['code'].values.length > 0,
            onClick: params.context.onFilterVisible('code'),
        }),
    },
    {
        headerName: 'Наименование',
        field: 'consolidatedName',
        minWidth: 320,
        flex: 1,
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData>) => ({
            isApplied: params.context.filters['consolidatedName'].values.length > 0,
            onClick: params.context.onFilterVisible('consolidatedName'),
        }),
    },
    {
        headerName: '',
        field: 'changesSummary',
        minWidth: 130,
        maxWidth: 130,
        cellRenderer: ChangesSummaryCellRenderer,
    },
    {
        headerName: 'Ед. изм',
        field: 'rawUnit',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClass: 'ag-cell-center',
        cellRenderer: TextCellRenderer,
        headerComponent: HeaderFilter,
        headerComponentParams: (params: IHeaderParams<TData, TContext>) => ({
            isApplied: params.context.filters['rawUnit'].values.length > 0,
            onClick: params.context.onFilterVisible('rawUnit'),
        }),
    },
    {
        headerName: 'Кол-во',
        field: 'quantity',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClass: 'ag-cell-center',
    },
    {
        headerName: 'ТЗ ОР, чел-ч',
        field: 'humanHourCost',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClass: 'ag-cell-right',
    },
    {
        headerName: 'ТЗМ, чел-ч',
        field: 'machineHourCost',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        cellClass: 'ag-cell-right',
    },
    {
        headerName: 'Всего стоимость',
        field: 'costTotal',
        suppressMovable: true,
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        flex: 1,
        minWidth: 140,
        cellClass: 'ag-cell-right',
    },
];
