import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

import { FlexRowWrapper } from '../../../../NewExecutorView/components/components.styles';
import { RowGroupExpandRenderer } from '../RowGroupExpandRenderer/RowGroupExpandRenderer';

interface IRowGroupRendererPrarms extends ICellRendererParams {
    children?: React.ReactNode | React.ReactNode[];
}

export function RowGroupRenderer({ children, ...rest }: IRowGroupRendererPrarms) {
    return (
        <FlexRowWrapper gap={1}>
            <RowGroupExpandRenderer {...rest} />
            {children}
        </FlexRowWrapper>
    );
}
