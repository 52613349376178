import { Checkbox, FormControlLabel } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useMemo, useState } from 'react';

import { useGetFilterListUnlimitedV2Query } from '@/api/filters/filters.api';

import { ShowEmptyCheckbox } from '@/components/CheckboxPopover/CheckboxPopover';
import type { IFilterDialogTableOption } from '@/components/FilterDialog';

import { FlexColumnWrapper } from '@/pages/NewExecutorView/components/components.styles';

import { agGridListMode, changeListMode } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { TSelectedColumn, filtersKsgUnlimitedI, filtersSelector } from '@/store/slices/filtersSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

export const useFilterDialogLogic = (projectId: string, endpoint: string, filterKey: keyof filtersKsgUnlimitedI) => {
    const { filters } = useTypedSelector(filtersSelector);
    const listMode = useTypedSelector(agGridListMode);
    const [isShowEmpty, setIsShowEmpty] = useState(filters.showEmptyWorkGroups ?? false);
    const [isListMode, setIsListMode] = useState(listMode ?? false);
    const dispatch = useAppDispatch();

    const query = useGetFilterListUnlimitedV2Query({
        id: Number(projectId),
        filters: {
            ...filters,
            [filterKey]: [],
        },
        endpoint: endpoint,
    });

    const handleReset = () => {
        setIsShowEmpty(false);
        setIsListMode(false);
    };

    const handleApply = <
        T extends {
            [key: string]: string[] | boolean;
        }
    >(
        options: IFilterDialogTableOption[],
        action: ActionCreatorWithPayload<T, string>
    ) => {
        dispatch(changeListMode(isListMode));

        const payload = {
            [filterKey]: options.map((option) => option.label.toString()),
            ...(filterKey === 'workGroupList' && { showEmptyWorkGroups: isShowEmpty }),
        } as T & { showEmptyWorkGroups?: boolean };

        dispatch(action(payload));
    };

    const checkboxes = useMemo(
        () => (
            <FlexColumnWrapper gap={0}>
                {filterKey === 'workGroupList' && (
                    <ShowEmptyCheckbox
                        checked={isShowEmpty}
                        onChange={setIsShowEmpty}
                    />
                )}
                <FormControlLabel
                    label='Списком'
                    sx={{ '.MuiSvgIcon-root': { fill: '#0044B4' } }}
                    control={
                        <Checkbox
                            checked={isListMode}
                            onChange={(e) => setIsListMode(e.target.checked)}
                        />
                    }
                />
            </FlexColumnWrapper>
        ),
        [isListMode, isShowEmpty, filterKey]
    );

    const data = useMemo(() => {
        if (query.data) {
            return query.data.data.map((item: string) => ({
                id: item,
                label: item,
                value: item,
                checked: (filters[filterKey] as string[]).includes(item),
            }));
        }
        return [];
    }, [query.data]);

    return {
        data: data,
        isLoading: query.isLoading,
        handleReset,
        handleApply,
        checkboxes,
        isListMode,
        isShowEmpty,
    };
};

type TDialogKeysValue = 'workGroup' | 'rdCode' | 'contractorCompany' | 'volumeTotal' | 'default';
type TDialogKeys = Record<string, TDialogKeysValue>;

const dialogKeyByColumn: TDialogKeys = {
    workGroup: 'workGroup',
    rdCode: 'rdCode',
    workName: 'default',
    contractorCompany: 'contractorCompany',
    objName: 'default',
    objTitle: 'default',
    rdStatus: 'default',
    volumeTotal: 'volumeTotal',
    default: 'default',
};

export const getDialogKey = (key: TSelectedColumn): TDialogKeysValue => {
    if (key === null) return dialogKeyByColumn.default;
    if (key in dialogKeyByColumn) return dialogKeyByColumn[key];

    return dialogKeyByColumn.default;
};
