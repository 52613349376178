import debounce from 'lodash/debounce';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { useGetGraphProjectsQuery, useGetProjectsQuery } from '@/api/projects/projects.api';
import { Project } from "@/api/projects/projects.types";

import { headerSearchSelector } from '@/store/slices/projectsSlice';
import { useTypedSelector } from '@/store/store';

import { projectsTerms } from './Projects.service';

const termForPlaceholder = {
    Активный: 'активных',
    Приостановлен: 'приостановленных',
    Завершенный: 'завершенных',
};

export const useGetData = (charts?: boolean) => {
    const { data: regularProjects, isLoading: isLoadingRegularProjects } = useGetProjectsQuery({}, { skip: charts });
    const { data: graphProjects, isLoading: isLoadingGraphProjects } = useGetGraphProjectsQuery({}, { skip: !charts });

    const projects = charts ? graphProjects : regularProjects;
    const isLoading = charts ? isLoadingGraphProjects : isLoadingRegularProjects;

    return { projects: projects?.data ?? [], isLoading };
};

const useSearchValue = () => {
    const headerSearch = useTypedSelector(headerSearchSelector);
    const [searchValue, setSearchValue] = useState(headerSearch ?? '');

    const debounceFn = useCallback(debounce(setSearchValue, 200), []);

    useEffect(() => {
        debounceFn(headerSearch);
    }, [headerSearch]);

    return searchValue;
};

export const useGetFilteredData = (projects: Project[], tab: projectsTerms) => {
    const searchValue = useSearchValue();

    const data = useMemo(() => {
        const data = projects.filter((v) =>
            `${v.title} ${v.description}`.toLowerCase().includes(searchValue.toLowerCase())
        );

        const text = 'Ничего не найдено, измените текст поиска.';

        if (tab !== 'Все') {
            return {
                data: data?.filter((v) => v?.status === tab),
                placeholder: data?.length
                    ? `Нет ${termForPlaceholder[tab]} проектов, используйте другую вкладку`
                    : text,
            };
        }
        return { data, placeholder: text };
    }, [searchValue, projects, tab]);

    return { filteredProjects: data.data, placeholder: data.placeholder };
};

export const useTabs = () => {
    const [tab, setTab] = useState<projectsTerms>('Активный');

    const handleChange = useCallback((_event: SyntheticEvent, newValue: projectsTerms) => setTab(newValue), [setTab]);

    return { tab, onChange: handleChange };
};
