import { ICellRendererParams } from 'ag-grid-community';
import { useParams } from 'react-router-dom';

import { DOES_ROLE_HAS_ACCESS_TO_PROJECT_TABLE, PERMISSIONS } from '@/shared/rolePermissions';

import { profileSelector } from '@/store/slices/profileSlice';
import { getSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { useTypedSelector } from '@/store/store';

import { EditChartCell } from '../../../../components/EditChartCell/EditChartCell';
import { TResourceType } from '../../../WorkManagment/api/api.types';
import { submitResourceDailyChart } from './MonthRendererMsgPeqAg.service';

export default function MonthRendererMsgPeqAg({
    cellRendererParams,
    chartIndex,
    resourceType,
}: {
    cellRendererParams: ICellRendererParams;
    chartIndex: number;
    resourceType: TResourceType;
}) {
    const { msgForecast, verificationPlan } = useTypedSelector(getSettings);

    const { profile } = useTypedSelector(profileSelector);
    const { projectId } = useParams();

    return (
        <div className='month_container'>
            <EditChartCell
                cellRendererParams={cellRendererParams}
                chartValueKey={'plan'}
                chartIndex={chartIndex}
                isEditable={
                    !cellRendererParams.data?.isResource &&
                    PERMISSIONS.msg[resourceType]?.plan?.roles.includes(profile?.role)
                }
                defaultValue={cellRendererParams.data?.dailyCharts[chartIndex]?.plan}
                onSubmitData={(args) => submitResourceDailyChart({ ...args, resourceType: resourceType })}
            />
            <EditChartCell
                cellRendererParams={cellRendererParams}
                chartValueKey={'fact'}
                chartIndex={chartIndex}
                isEditable={
                    !cellRendererParams.data?.isResource &&
                    PERMISSIONS.msg[resourceType]?.fact?.roles.includes(profile?.role)
                }
                defaultValue={cellRendererParams.data?.dailyCharts[chartIndex]?.fact}
                onSubmitData={(args) => submitResourceDailyChart({ ...args, resourceType: resourceType })}
            />
            {msgForecast && (
                <EditChartCell
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'forecast'}
                    chartIndex={chartIndex}
                    isEditable={
                        !cellRendererParams.data?.isResource &&
                        PERMISSIONS.msg[resourceType]?.forecast?.roles.includes(profile?.role)
                    }
                    defaultValue={cellRendererParams.data?.dailyCharts[chartIndex]?.forecast}
                    onSubmitData={(args) => submitResourceDailyChart({ ...args, resourceType: resourceType })}
                />
            )}
            {verificationPlan && (
                <EditChartCell
                    cellRendererParams={cellRendererParams}
                    chartValueKey={'verifiedFact'}
                    chartIndex={chartIndex}
                    isEditable={
                        !cellRendererParams.data?.isResource &&
                        PERMISSIONS.msg[resourceType]?.verifiedFact?.roles.includes(profile?.role) &&
                        DOES_ROLE_HAS_ACCESS_TO_PROJECT_TABLE(
                            projectId as string,
                            profile,
                            resourceType as 'staff' | 'mim'
                        )
                    }
                    defaultValue={cellRendererParams.data?.dailyCharts[chartIndex]?.verifiedFact}
                    onSubmitData={(args) => submitResourceDailyChart({ ...args, resourceType: resourceType })}
                />
            )}
        </div>
    );
}
