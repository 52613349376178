import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { useSnackbar } from 'notistack';
import { RefObject, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { msgGantSelector, setGantLinkChoice } from '@/store/slices/msgGantSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { IGantLinkActiveChoiseProps } from '../../../../layouts/TableLayout/components/GantLinkActiveChoise/GantLinkActiveChoise.types';
import { req } from '../../../WorkManagment/api/api';
import { CANVAS_ID } from '../../AgGridMsg.service';
import { GantBar } from './GantBar';
import {
    createPathArrowV2,
    getDateFromCellParams,
    getDateWithLeadingZero,
    getGantBarPlacement,
    getGantDepId,
    getHideIndicatorConditionArray,
    getIndicatorCoords,
} from './GantCell.service';
import { IGantCellProps, TLinkSide } from './GantCell.types';
import { DeleteDepsMenu } from './components/DeleteDepsMenu/DeleteDepsMenu';

export function GantCell({ cellRendererParams, chartIndex, supressEditRelationships }: IGantCellProps) {
    const [deleteDepsMenuSide, setDeleteDepsMenuSide] = useState<TLinkSide | undefined>(undefined);

    const { activeGantChoice } = useTypedSelector(msgGantSelector);
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const wrapperRef = useRef() as RefObject<HTMLDivElement>;

    function bindWorks(
        cellRendererParams: ICellRendererParams,
        activeGantChoice: IGantLinkActiveChoiseProps | null,
        projectId: string
    ): Promise<any> {
        const firstWorkDate = getDateFromCellParams(activeGantChoice?.cellRendererParams, activeGantChoice!.chartIndex);
        const secondWorkDate = getDateFromCellParams(cellRendererParams, chartIndex);

        return req.post(`/projects/${projectId}/dependencies/works/msg/bind`, {
            from: {
                date: firstWorkDate,
                side: activeGantChoice?.linkType,
                workId: activeGantChoice?.cellRendererParams?.data?.id,
            },
            to: {
                date: secondWorkDate,
                side: 'start',
                workId: cellRendererParams?.data?.id,
            },
        });
    }

    function onRightMouseBtnClickHandler(e: React.MouseEvent<HTMLDivElement>, side: TLinkSide) {
        if (supressEditRelationships) {
            return;
        }
        e.preventDefault();
        if (e.button !== 2) {
            e.stopPropagation();
        } else {
            setTimeout(() => setDeleteDepsMenuSide(() => side));
        }
    }

    function handleIndicatorClick(
        event: React.MouseEvent<HTMLDivElement>,
        side: TLinkSide,
        activeGantChoice: IGantLinkActiveChoiseProps | null,
        projectId: string
    ) {
        if (supressEditRelationships) {
            return;
        }
        const workToDay = chartIndex + 1;
        const { month, year } = cellRendererParams?.data || {};

        if (activeGantChoice?.cellRendererParams) {
            const pathID = getGantDepId({
                workFromID: activeGantChoice?.cellRendererParams?.data?.id.toString() || '',
                workToID: cellRendererParams?.data?.id.toString() || '',
                workFromDate: getDateWithLeadingZero(activeGantChoice.chartIndex + 1, month || 0, year || 0),
                workToDate: getDateWithLeadingZero(workToDay, month || 0, year || 0),
            });

            const doesPathAlreadyExist = document.getElementById(pathID);

            if (!doesPathAlreadyExist) {
                bindWorks(cellRendererParams, activeGantChoice, projectId as string)
                    .then((e) => {
                        addPathArrowToCanvasV2(activeGantChoice, cellRendererParams);
                    })
                    .catch((e) => {
                        enqueueSnackbar('Ошибка', {
                            variant: 'error',
                        });
                    });
            } else {
                enqueueSnackbar('Такая связь уже существует', { variant: 'error' });
                dispatch(setGantLinkChoice(null));
            }
        } else {
            chooseActiveGantWork(side);
        }
    }

    function chooseActiveGantWork(indicatorSide: TLinkSide) {
        dispatch(
            setGantLinkChoice({
                chartIndex: chartIndex,
                cellRendererParams: cellRendererParams,
                linkType: indicatorSide,
            })
        );
    }

    function addPathArrowToCanvasV2(
        activeGantChoice: IGantLinkActiveChoiseProps,
        cellRendererParams: ICellRendererParams
    ) {
        const color = activeGantChoice?.cellRendererParams?.data?.brigade?.color || 'grey';
        if (activeGantChoice?.cellRendererParams && activeGantChoice.linkType) {
            const workFromID = activeGantChoice.cellRendererParams?.data?.id || '';
            const workFromRowIndex = cellRendererParams.api.getRowNode(workFromID.toString())?.rowIndex || 0;
            const workFromChartIndex = activeGantChoice.chartIndex + 1;
            const workFromSide = activeGantChoice.linkType;
            const workFromDay = activeGantChoice.chartIndex + 1;
            const workToID = cellRendererParams?.data?.id || '';
            const workToRowIndex = cellRendererParams.api.getRowNode(workToID.toString())?.rowIndex || 0;
            const workToChartIndex = chartIndex + 1;
            const workToDay = chartIndex + 1;
            const { month, year } = cellRendererParams?.data;
            const pathID = getGantDepId({
                workFromID: workFromID.toString(),
                workToID: workToID.toString() || '',
                workFromDate: getDateWithLeadingZero(workFromDay, month, year),
                workToDate: getDateWithLeadingZero(workToDay, month, year),
            });

            createPathArrowV2({
                pathID: pathID,
                from: getIndicatorCoords(workFromRowIndex, workFromChartIndex, workFromSide),
                to: getIndicatorCoords(workToRowIndex, workToChartIndex, 'start'),
                color: color,
                canvasID: CANVAS_ID,
            });
            dispatch(setGantLinkChoice(null));
        }
    }

    const {
        doesWorkHaveNoBrigade,
        isWorkActive,
        isAnotherBrigade,
        isStartStartDateConflict,
        isEndDateConflict,
        isChosenWorkStart,
        isChosenWorkFinish,
    } = getHideIndicatorConditionArray(cellRendererParams, chartIndex, activeGantChoice);

    const HIDE_INDICATOR_CONDITIONS = useMemo(() => {
        return {
            start: [doesWorkHaveNoBrigade, isWorkActive, isAnotherBrigade, isStartStartDateConflict, isEndDateConflict],
            finish: [doesWorkHaveNoBrigade, isWorkActive, isAnotherBrigade, isChosenWorkStart, isChosenWorkFinish],
        };
    }, [
        doesWorkHaveNoBrigade,
        isWorkActive,
        isAnotherBrigade,
        isStartStartDateConflict,
        isChosenWorkStart,
        isEndDateConflict,
        isChosenWorkFinish,
    ]);

    return (
        <Box
            id={cellRendererParams.data?.id + '_' + chartIndex}
            ref={wrapperRef}
        >
            <GantBar
                isChosen={cellRendererParams.data?.id === activeGantChoice?.cellRendererParams?.data?.id}
                placement={getGantBarPlacement(cellRendererParams, 'plan', chartIndex)}
                hidden={!!!cellRendererParams?.data?.dailyCharts[chartIndex]!['plan']}
                startIndicatorProps={{
                    hidden: !!HIDE_INDICATOR_CONDITIONS.start.find((condition) => condition === true),
                    indicatorProps: {
                        onClick: (e) => handleIndicatorClick(e, 'start', activeGantChoice, projectId as string),
                        onContextMenu: (e) => onRightMouseBtnClickHandler(e, 'start'),
                        bgcolor: cellRendererParams.data?.brigade?.color,
                        sx: {
                            cursor: supressEditRelationships ? 'default !important' : 'pointer',
                        },
                    },
                }}
                finishIndicatorProps={{
                    hidden: !!HIDE_INDICATOR_CONDITIONS.finish.find((condition) => condition === true),
                    indicatorProps: {
                        onClick: (e) => handleIndicatorClick(e, 'finish', activeGantChoice, projectId as string),
                        onContextMenu: (e) => onRightMouseBtnClickHandler(e, 'finish'),
                        bgcolor: cellRendererParams.data?.brigade?.color,
                        sx: {
                            cursor: supressEditRelationships ? 'default !important' : 'pointer',
                        },
                    },
                }}
                bgcolor={cellRendererParams.data?.brigade?.color || 'white'}
            />
            <GantBar
                placement={getGantBarPlacement(cellRendererParams, 'fact', chartIndex)}
                hidden={!!!cellRendererParams?.data?.dailyCharts[chartIndex]!['fact']}
                size={'small'}
                bgcolor={cellRendererParams.data?.brigade?.color}
                variant={'inner'}
            />
            {deleteDepsMenuSide && (
                <DeleteDepsMenu
                    side={deleteDepsMenuSide}
                    cellRendererParams={cellRendererParams}
                    open={!!deleteDepsMenuSide}
                    chartIndex={chartIndex}
                    setDeleteDepsMenuSide={setDeleteDepsMenuSide}
                    anchorEl={wrapperRef.current}
                />
            )}
        </Box>
    );
}
