import { useMutation } from '@tanstack/react-query';

import { TCustomHookMutationOptions } from '@/types/query.types';

import { TRefreshEstimatePositionsResponse } from '../estimatePositions';
import { TRefreshEstimatePositionsRequest } from "../estimatePositions/estimatePositions.types";
import { importApi } from './importingData.api';

export const useImportEstimatePositionsMutation = (
    options?: TCustomHookMutationOptions<TRefreshEstimatePositionsResponse, TRefreshEstimatePositionsRequest>
) =>
    useMutation({
        mutationFn: (payload) => importApi.importEstimatePositions(payload),
        ...options,
    });
