export const DEFAULT_DISPLAY_PASSWORD_VALUE = '* * * * * *';

export const NUMBER_OF_ROWS_PER_USERS_PAGE = [
    { value: 100, title: '100' },
    { value: 50, title: '50' },
    { value: 25, title: '25' },
];

export const ERRORS_PHONE = ['company.user_creation.invalid_phone', 'profile.invalid_phone'];

export const ERRORS_COINCIDENCE = {
    'company.user_creation.phone_taken': 'phone' as const,
    'company.user_creation.email_taken': 'email' as const,
    'company.user_creation.login_taken': 'login' as const,
    'profile.phone_taken': 'phone' as const,
    'profile.email_taken': 'email' as const,
    'profile.login_taken': 'login' as const,
};
