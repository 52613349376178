import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GanttChartSearchParams } from '@/shared/constants/gantt';

import {
    agGridKsgSelector,
    onEditAgKsgMinRowHeight,
    onEditAgKsgRowHeight,
} from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { getSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { templatesSelector } from '@/store/slices/templatesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { useGetProjectsQuery } from '../../api/projects/projects.api';
import { Project } from '../../api/projects/projects.types';
import { useUpdateCurrentTemplate } from '../../components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.model';
import { MIN_ROW_HEIGHT } from '../../shared/constants/agGrid';

export const useGetProject = (projectId: number) => {
    const { data: projects } = useGetProjectsQuery({});

    const project = useMemo(() => {
        if ([!projectId, !projects, !projects?.data].includes(true)) return null;

        return projects?.data.find((project: Project) => project.id === projectId) ?? null;
    }, [projectId, projects]);

    return project;
};

const calculateMinRowHeight = (count: number) => MIN_ROW_HEIGHT + (MIN_ROW_HEIGHT / 2) * count;

const useUpdateMinRowHeight = () => {
    const { basicPlan, verificationPlan } = useTypedSelector(getSettings);
    const dispatch = useAppDispatch();

    useEffect(() => {
        updateMinRowHeight([basicPlan, verificationPlan]);
    }, [basicPlan, verificationPlan]);

    const updateMinRowHeight = async (planSwitchValues: boolean[]) => {
        const count = planSwitchValues.filter((v) => v).length;
        const minRowHeight = calculateMinRowHeight(count);
        dispatch(onEditAgKsgMinRowHeight({ minRowHeight }));
    };
};

export const useUpdateRowHeight = () => {
    const { rowHeight: globalRowHeight, minRowHeight } = useTypedSelector(agGridKsgSelector);
    const dispatch = useAppDispatch();
    const { currentTemplate } = useTypedSelector(templatesSelector);
    const { update: updateCurrentTemplate } = useUpdateCurrentTemplate();

    const updateRowHeightByMinRowHeight = async (minRowHeight: number) => {
        if (minRowHeight <= globalRowHeight) return;

        updateCurrentTemplate({ rowHeight: minRowHeight }, { showSuccessSnackbar: false });
        dispatch(onEditAgKsgRowHeight({ rowHeight: minRowHeight }));
    };

    const updateRowHeightByCurrentTemplate = async (rowHeight: number) => {
        dispatch(onEditAgKsgRowHeight({ rowHeight: rowHeight }));
    };

    useUpdateMinRowHeight();

    useEffect(() => {
        updateRowHeightByMinRowHeight(minRowHeight);
    }, [minRowHeight]);

    useEffect(() => {
        if (!currentTemplate) return;

        const rowHeight = currentTemplate.rowHeight ?? minRowHeight;
        updateRowHeightByCurrentTemplate(rowHeight);
    }, [currentTemplate]);
};

export const useUpdateGanttModeByCurrentTemplate = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { currentTemplate } = useTypedSelector(templatesSelector);

    useEffect(() => {
        if (!currentTemplate) return;
        if (currentTemplate.type !== 'ksg') return;
        if (currentTemplate.isCommon) return;

        if (currentTemplate.isGanttOn) {
            if (searchParams.has(GanttChartSearchParams.Plan)) return;

            setSearchParams((searchParams) => {
                searchParams.append(GanttChartSearchParams.Plan, 'on');
                return searchParams;
            });
            return;
        }

        setSearchParams((searchParams) => {
            Object.values(GanttChartSearchParams).forEach((p) => searchParams.delete(p));
            return searchParams;
        });
    }, [currentTemplate]);
};
