import { Stack } from '@mui/material';

import { Rect, Text } from './GanttLegend.styles';
import { IGanttLegendProps } from './GanttLegend.types';

export const GanttLegend: React.FC<IGanttLegendProps> = () => {
    return (
        <Stack
            direction={'row'}
            gap={2.5}
            justifyContent={'center'}
        >
            <Stack
                direction={'row'}
                gap={1}
                alignItems={'center'}
            >
                <Rect
                    backgroundColor='rgba(43, 54, 72, 0.12)'
                    border='1px solid rgba(43, 54, 72, 0.38)'
                />
                <Text>Базовый план</Text>
            </Stack>
            <Stack
                direction={'row'}
                gap={1}
                alignItems={'center'}
            >
                <Rect
                    backgroundColor='#C4B5FD'
                    border='1px solid #8a7bc6'
                />
                <Text>Оперативный план</Text>
            </Stack>
            <Stack
                direction={'row'}
                gap={1}
                alignItems={'center'}
            >
                <Rect
                    backgroundColor='#8dd4c8'
                    border='1px solid #8a7bc6'
                />
                <Text>Факт</Text>
            </Stack>
        </Stack>
    );
};
