import { useQuery } from '@tanstack/react-query';

import { getConsolidatedPricesEstimatePositionsFilterQueryOptions } from '@/api/consolidatedPrices';

import { estimatePositionsSelectors } from '@/store/slices/estimatePositions';
import { useTypedSelector } from '@/store/store';

import { TFilterDialogProps } from './FilterDialog.types';

export const useGetEstimatePositionsFilter = (props: TFilterDialogProps) => {
    const filters = useTypedSelector(estimatePositionsSelectors.filters);

    return useQuery({
        ...getConsolidatedPricesEstimatePositionsFilterQueryOptions({
            projectId: props.projectId,
            column: props.filterKey,
            listType: 'available',
            body: filters,
        }),
    });
};
