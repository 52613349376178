import { AgGridReact } from 'ag-grid-react';
import styled, { css } from 'styled-components';

export const AgGridContainerCSS = css`
    .p0 {
        padding: 0;
    }
`;

export const AgGrid = styled(AgGridReact)``;
