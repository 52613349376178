import { LegendColors } from '../global';

export type UserRoles = 'Администратор' | 'Заказчик' | 'Планировщик' | 'Исполнитель' | 'Эксперт' | 'Без роли';
export type UserRolesEn = 'admin' | 'client' | 'scheduler' | 'executor' | 'controller' | 'none';
export type UserRoleTabLabels =
    | 'Все пользователи'
    | 'Администраторы'
    | 'Заказчики'
    | 'Планировщики'
    | 'Исполнители'
    | 'Эксперт'
    | 'Без роли';

interface UserRolesEnToRuProps extends Record<UserRolesEn, UserRoles> {}

interface UserRolesRuToEnProps extends Record<UserRoles, UserRolesEn> {}

export const UserRolesRuToEn: UserRolesRuToEnProps = {
    Администратор: 'admin',
    Заказчик: 'client',
    Планировщик: 'scheduler',
    Исполнитель: 'executor',
    Эксперт: 'controller',
    'Без роли': 'none',
};

export const UserRolesEnToRu: UserRolesEnToRuProps = {
    admin: 'Администратор',
    client: 'Заказчик',
    scheduler: 'Планировщик',
    executor: 'Исполнитель',
    controller: 'Эксперт',
    none: 'Без роли',
};

export const userRolesArray = Object.keys(UserRolesRuToEn) as Array<keyof typeof UserRolesRuToEn>;
export const userTabNamesArray: UserRoleTabLabels[] = [
    'Все пользователи',
    'Администраторы',
    'Заказчики',
    'Планировщики',
    'Исполнители',
    'Эксперт',
    'Без роли',
];

export const UserRoleByTabLabel: Record<Exclude<UserRoleTabLabels, 'Все пользователи'>, UserRolesEn> = {
    Администраторы: 'admin',
    Заказчики: 'client',
    Планировщики: 'scheduler',
    Исполнители: 'executor',
    Эксперт: 'controller',
    'Без роли': 'none',
};

export const UserRolesColors: Record<UserRoles, LegendColors> = {
    Администратор: 'blue',
    Заказчик: 'purple',
    Планировщик: 'lightgreen',
    Исполнитель: 'orange',
    Эксперт: 'yellow',
    'Без роли': 'disabled',
};
