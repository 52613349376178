import { Dialog as MuiDialog, Stack } from '@mui/material';
import styled from 'styled-components';

export const Dialog = styled(MuiDialog)`
    .MuiDialog-container {
        padding: 30px;
    }

    .MuiDialog-paper {
        border-radius: 16px;
        margin: 0;
        padding: 0px 0px 20px 0px;
        max-width: 1960px;
        max-height: 1020px;
        width: 100%;
        height: 100%;
        box-shadow: -8px 0 8px 0 rgba(0, 0, 0, 0.06);
        background: #fff;
    }
`;

export const LoaderContainer = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
})``;
