import { ArrowBack } from '@mui/icons-material';
import DownloadingSharpIcon from '@mui/icons-material/DownloadingSharp';
import { Box, Dialog, Modal, Typography } from '@mui/material';
import { GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetExecutorsQuery } from '@/api/company/company.api';
import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api';
import { useGetMsgPeopleQuery } from '@/api/msgPeople/msgPeople.api';

import EmptyPlaceholder from '@/shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { getWorkManagmentResourceRowSlyles, modalStyles } from '@/shared/utils';

import { setAgGrid, toggleUploadKsgPeople } from '@/store/slices/drawersSlice';
import { filtersWorkersMimSelector } from '@/store/slices/filtersWorkersMimSlice';
import { monthMsgSelector } from '@/store/slices/monthMsgSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { projectsSelector } from '@/store/slices/projectsSlice';
import { getSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import CheckboxPopoverWorkersMimKsg from '../../components/CheckboxPopoverWorkersMim/CheckboxPopoverWorkersMimKsg';
import ShowMsgDialog from '../../components/ShowMsgDialog/ShowMsgDialog';
import { AG_GRID_DEFAULT_PARAMS_KSG } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { getMSGPeopleColDefs } from './AgGridMsgPeople.colDef';

export default function AgGridMsgPeople() {
    const [openModal, setOpenModal] = useState(false);

    const { titlesArr } = useTypedSelector(filtersWorkersMimSelector);
    const { month, year } = useTypedSelector(monthMsgSelector);
    const { headerSearch } = useTypedSelector(projectsSelector);
    const { profile } = useTypedSelector(profileSelector);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();

    const { msgForecast } = useTypedSelector(getSettings);

    const { projectId } = useParams();
    const gridRef = useRef<AgGridReact>(null);

    const { enqueueSnackbar } = useSnackbar();

    const headers = useGetHeadersCPGQuery({
        id: Number(projectId),
        isStaff: true,
    });

    const getExecutors = useGetExecutorsQuery(
        {
            companyID: profile?.company?.companyID,
        },
        {
            skip: !profile?.company?.companyID,
        }
    );

    const msgPeople = useGetMsgPeopleQuery({
        id: Number(projectId),
        year: year,
        month: month + 1,
        filter: headerSearch,
        limit: 9999,
        offset: 0,
    });

    useEffect(() => {
        gridRef?.current && dispatch(setAgGrid(gridRef?.current));
        return () => {
            dispatch(setAgGrid(null));
        };
    }, [gridRef?.current]);

    useEffect(() => {
        msgPeople?.refetch();
        //eslint-disable-next-line
    }, [titlesArr.length]);

    const isCreatedMsg = useMemo(() => {
        return headers.data?.allHeaders.filter((header) => {
            if (header.isCreatedMSG) {
                return header.month === month + 1 && header.year === year;
            }
        });
    }, [headers.data?.allHeaders, month]);

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params.data.id;
    }, []);

    if ((headers.data?.allHeaders || []).length === 0) {
        if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_KSG_STAFF')) {
            return (
                <EmptyPlaceholder
                    text={
                        <Typography>
                            Здравствуйте, {profile?.firstName}, КСГ еще не был создан. <br />
                            Создание КСГ не доступно для пользователей с Вашими правами. <br /> Обратитесь к
                            администратору
                        </Typography>
                    }
                    onClick={() => navigate(-1)}
                    icon={<ArrowBack />}
                    buttonText='Назад'
                />
            );
        }
        return (
            <EmptyPlaceholder
                text={`Здравствуйте, ${profile?.firstName}! Данные для отображения отсутствуют.`}
                onClick={() => dispatch(toggleUploadKsgPeople())}
                icon={<DownloadingSharpIcon />}
                buttonText='загрузить'
            />
        );
    }

    if (isCreatedMsg?.length === 0 && DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_MSG_STAFF')) {
        return (
            <Dialog
                open={true}
                onClose={() => {}}
            >
                <ShowMsgDialog people />
            </Dialog>
        );
    }

    return (
        <>
            {' '}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Box sx={modalStyles}>
                    <CheckboxPopoverWorkersMimKsg />
                </Box>
            </Modal>
            <div
                className='ag-theme-alpine'
                style={{ height: '100%', width: '100%', paddingRight: '0.5rem' }}
            >
                {msgPeople.data?.data && (
                    <AgGridReact
                        ref={gridRef}
                        getRowId={getRowId}
                        rowData={msgPeople.data?.data}
                        columnDefs={getMSGPeopleColDefs({
                            projectId: projectId as string,
                            setOpenModal: setOpenModal,
                            getExecutors: getExecutors,
                            enqueueSnackbar: enqueueSnackbar,
                            msgForecast: msgForecast,
                            msgPeople: msgPeople,
                        })}
                        getRowClass={getWorkManagmentResourceRowSlyles}
                        {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                    ></AgGridReact>
                )}
            </div>
        </>
    );
}
