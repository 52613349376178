import { useQuery } from '@tanstack/react-query';

import { getEstimatePositionsFilterQueryOptions } from '@/api/estimatePositions';

import { estimatePositionsSelectors } from '@/store/slices/estimatePositions';
import { useTypedSelector } from '@/store/store';

import { TFilterDialogProps } from './FilterDialog.types';

export const useGetEstimatePositionsFilter = (props: TFilterDialogProps) => {
    const listType = useTypedSelector(estimatePositionsSelectors.listType);
    const filters = useTypedSelector(estimatePositionsSelectors.filters);

    return useQuery({
        ...getEstimatePositionsFilterQueryOptions({
            projectId: props.projectId,
            column: props.filterKey,
            listType: listType,
            body: filters,
        }),
    });
};
