import { SnackbarProvider as Provider, SnackbarOrigin } from 'notistack';

const snackbarAnchorOrigin: SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

interface IProps extends React.PropsWithChildren {}

export const SnackbarProvider: React.FC<IProps> = ({ children }) => {
    return (
        <Provider
            anchorOrigin={snackbarAnchorOrigin}
            maxSnack={3}
            autoHideDuration={2500}
            style={{
                maxWidth: 500,
            }}
        >
            {children}
        </Provider>
    );
};
