import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TEstimatePositionUnitType } from '@/api/estimatePositions';

import { UIInput, UIInputSelect, UINumberInput } from '@/components/ConsolidatedPriceDialog/components/UIInput';

import { TFormValues } from '../../ConsolidatedPriceDialog.types';
import { ChangeInfo } from '../ChangeInfo';
import { options, unitTypeEnToRu } from './Form.config';
import { Button, Container, Left, Right } from './Form.styles';
import { TFormProps } from './Form.types';

export const Form: FC<TFormProps> = ({ changesSummary, mode, updateEditedFields, onAddPositions, onDelete }) => {
    const { control } = useFormContext<TFormValues>();

    return (
        <Container>
            <Left>
                <Controller
                    name='chapter'
                    control={control}
                    defaultValue='-'
                    render={({ field }) => (
                        <UIInput
                            label='Раздел'
                            inputProps={{
                                disabled: true,
                                value: field.value,
                                sx: {
                                    width: '420px',
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name='consolidatedName'
                    control={control}
                    render={({ field }) => (
                        <UIInput
                            label='Наименование укрупненной расценки'
                            inputProps={{
                                value: field.value,
                                onChange: (e) => {
                                    updateEditedFields('consolidatedName')(e.target.value);
                                    field.onChange(e);
                                },
                                placeholder: 'Выберите ключевую работу в списке или укажите наименование',
                                sx: {
                                    width: '758px',
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name='lsrNumber'
                    control={control}
                    defaultValue='-'
                    render={({ field }) => (
                        <UIInput
                            label='Номер по ЛСР'
                            inputProps={{
                                disabled: true,
                                value: field.value,
                                sx: {
                                    width: '200px',
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name='header'
                    control={control}
                    defaultValue='-'
                    render={({ field }) => (
                        <UIInput
                            label='Заголовок'
                            inputProps={{
                                disabled: true,
                                value: field.value,
                                sx: {
                                    width: '200px',
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name='code'
                    control={control}
                    defaultValue='-'
                    render={({ field }) => (
                        <UIInput
                            label='Код расценки'
                            inputProps={{
                                disabled: true,
                                value: field.value,
                                sx: {
                                    width: '200px',
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name='rawUnit'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <UIInputSelect
                            label='Единица измерения'
                            options={options}
                            selectProps={{
                                placeholder: 'Укажите единицу изм.',
                                value: field.value ?? '',
                                onChange: (e) => {
                                    const value = e.target.value as TEstimatePositionUnitType;
                                    updateEditedFields('rawUnit')(value);
                                    field.onChange(value);
                                },
                                renderValue: (selected) => {
                                    if (selected) return unitTypeEnToRu[selected as TEstimatePositionUnitType];
                                    return 'Укажите единицу изм.';
                                },
                                sx: {
                                    width: '200px',
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name='quantity'
                    control={control}
                    defaultValue={'0,00'}
                    render={({ field }) => (
                        <UINumberInput
                            label='Количество'
                            value={field.value}
                            inputProps={{
                                onChange: (e) => {
                                    updateEditedFields('quantity')(e.target.value.trim());
                                    field.onChange(e);
                                },
                                onBlur: (e) => {
                                    const value = e.target.value.trim();

                                    if (!value) {
                                        field.onChange('0,00');
                                    }
                                },
                                style: {
                                    width: '120px',
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name='humanHourCost'
                    control={control}
                    render={({ field }) => (
                        <UINumberInput
                            label='ТЗ ОР чел-час'
                            value={field.value}
                            inputProps={{
                                disabled: true,
                                style: {
                                    width: '120px',
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name='machineHourCost'
                    control={control}
                    render={({ field }) => (
                        <UINumberInput
                            label='ТЗМ чел-час'
                            value={field.value}
                            inputProps={{
                                disabled: true,
                                style: {
                                    width: '120px',
                                },
                            }}
                        />
                    )}
                />

                <Controller
                    name='costTotal'
                    control={control}
                    render={({ field }) => (
                        <UINumberInput
                            label='Всего стоимость'
                            value={field.value}
                            inputProps={{
                                disabled: true,
                                style: {
                                    width: '120px',
                                },
                            }}
                        />
                    )}
                />

                {mode === 'edit' && changesSummary && (
                    <ChangeInfo
                        changedCount={changesSummary.changed}
                        deletedCount={changesSummary.deleted}
                    />
                )}
            </Left>
            {mode === 'edit' && (
                <Right>
                    <Button
                        onClick={onAddPositions}
                        startIcon={<AddIcon />}
                    >
                        Добавить позиции в список
                    </Button>
                    <Button
                        startIcon={<DeleteForeverOutlinedIcon />}
                        onClick={onDelete}
                    >
                        Удалить группу работ
                    </Button>
                </Right>
            )}
        </Container>
    );
};
