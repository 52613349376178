import { Checkbox } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';

import { useSetKsgPeopleIsMainMutation } from '@/api/ksgPeople/ksgPeople.api';

import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';

import { profileSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

export default function CheckboxEl({
    cellRendererParams,
    projectId,
}: {
    cellRendererParams: ICellRendererParams;
    projectId?: string;
}) {
    const [setMainReq] = useSetKsgPeopleIsMainMutation();
    const { profile } = useTypedSelector(profileSelector);

    const handleSetMain = (isMain: boolean) => {
        setMainReq({
            id: Number(projectId),
            staffId: cellRendererParams.data.id,
            body: {
                isMain: isMain,
            },
        })
            .unwrap()
            .then(() =>
                cellRendererParams.api.applyTransactionAsync({
                    update: [
                        { ...cellRendererParams.api.getRowNode(cellRendererParams.node.data.id)?.data, isMain: isMain },
                    ],
                })
            );
    };

    return (
        <Checkbox
            checked={cellRendererParams?.data?.isMain}
            disabled={profile.role === 'client'}
            onChange={(e) => {
                DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_KSG_STAFF_ROW') && handleSetMain(e.target.checked);
            }}
            sx={{
                color: '#0044b4',
                '&.Mui-checked': {
                    color: '#0044b4',
                },
            }}
        />
    );
}
