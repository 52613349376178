import { ArrowBack } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DownloadingSharpIcon from '@mui/icons-material/DownloadingSharp';
import { Button, Drawer, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetHeadersCPGQuery } from '@/api/ksg/ksg.api';
import { Work } from '@/api/ksg/ksg.types';
import { useGetProjectByIdQuery } from '@/api/projects/projects.api';
import { workStatuses } from '@/api/works/works.types';

import { CreateKsg } from '@/components/AddLevelKsg';
import { ICreateKSGInitialFormValues } from '@/components/AddLevelKsg/AddLevel.service';

import useConfirmDialog from '@/hooks/useConfirmDialog';
import { usePermissionsByRole } from '@/hooks/usePermissionsByRole';

import { req } from '@/pages/WorkManagment/api/api';

import EmptyPlaceholder from '@/shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { EPageName } from '@/shared/constants/pages';

import { onKsgInit } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { toggleUploadKsg } from '@/store/slices/drawersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { PingedTasksManage } from '../../model/usePingedTasks';

interface IButtonsProps {
    isImportFromOffer: boolean;
    onAdd: () => void;
    onUpload: () => void;
    navigateToEstimatePositionsPage: () => void;
}

const Buttons: React.FC<IButtonsProps> = ({ isImportFromOffer, navigateToEstimatePositionsPage, onAdd, onUpload }) => {
    return (
        <Stack
            direction={'row'}
            gap={1.25}
            justifyContent={'center'}
            marginTop={'1.25rem'}
        >
            <Button
                onClick={onAdd}
                variant='contained'
                startIcon={<AddIcon />}
                sx={{
                    borderRadius: '6px',
                    minWidth: '162px',
                    minHeight: '40px',
                }}
            >
                Создать
            </Button>

            {isImportFromOffer ? (
                <Button
                    onClick={navigateToEstimatePositionsPage}
                    variant='contained'
                    sx={{
                        borderRadius: '6px',
                        minWidth: '162px',
                        minHeight: '40px',
                    }}
                >
                    Позиции сметы
                </Button>
            ) : (
                <Button
                    onClick={onUpload}
                    variant='contained'
                    startIcon={<DownloadingSharpIcon />}
                    sx={{
                        borderRadius: '6px',
                        minWidth: '162px',
                        minHeight: '40px',
                    }}
                >
                    Загрузить
                </Button>
            )}
        </Stack>
    );
};

interface IProps {}

export const KSGPlaceholder: React.FC<IProps> = () => {
    const { projectId } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
    });
    const { data } = useGetProjectByIdQuery({ id: Number(projectId) });

    const isImportFromOffer = data?.data?.isImportFromOffer ?? false;

    const { profile } = useTypedSelector(profileSelector);
    const { doesNotHaveFeatureAccess } = usePermissionsByRole();
    const { enqueueSnackbar } = useSnackbar();

    const [isOpenInitDialog, setIsInitDialog] = useState(false);
    const [hasValues, setHasValues] = useState(false);

    const handleConfirm = (confirm: boolean) => {
        if (confirm) {
            setIsInitDialog(false);
        }
    };
    const { ConfirmDialog, openConfirm } = useConfirmDialog({ handleConfirm });

    const handleSubmit = (values: ICreateKSGInitialFormValues, tag: boolean) => {
        req.post(`/projects/${projectId}/works/ksg-init`, {
            ...values,
            costER: values.costER ? Number(values.costER) : null,
            humanHourCost: values.humanHourCost ? Number(values.humanHourCost) : null,
            machineHourCost: values.machineHourCost ? Number(values.machineHourCost) : null,
            volumeTotal: values.volumeTotal ? Number(values.volumeTotal) : null,
            rdVersion: values.rdVersion ? Number(values.rdVersion) : null,
            rdStatus: values.rdStatus ? (values.rdStatus as workStatuses) : null,
            rdDate: values.rdDate ? format(values.rdDate as Date, 'dd.MM.yyyy') : null,
            rdDateApproval: values.rdDateApproval ? format(values.rdDateApproval as Date, 'dd.MM.yyyy') : null,
            startDate: values.startDate ? format(values.startDate as Date, 'dd.MM.yyyy') : null,
            endDate: values.endDate ? format(values.endDate as Date, 'dd.MM.yyyy') : null,
            operationalEndDate: values.operationalEndDate
                ? format(values.operationalEndDate as Date, 'dd.MM.yyyy')
                : null,
            operationalStartDate: values.operationalStartDate
                ? format(values.operationalStartDate as Date, 'dd.MM.yyyy')
                : null,
            tag: tag,
        })
            .then(({ data }) => {
                enqueueSnackbar('КСГ успешно создан', {
                    variant: 'success',
                });
                setIsInitDialog(false);
                headersCPG.refetch();
                dispatch(
                    onKsgInit({
                        works: data.data as Work[],
                    })
                );
            })
            .catch((e) => {
                enqueueSnackbar('Произошла ошибка при создании КСГ', {
                    variant: 'success',
                });
            })
            .finally(() => {});
    };

    const navigateToEstimatePositionsPage = () => {
        navigate(`/${EPageName.Mgp}/${projectId}`);
    };

    const handleClose = () => {
        if (hasValues) {
            openConfirm();
            return;
        }

        setIsInitDialog(false);
    };

    if (headersCPG.isFetching) return null;

    if (doesNotHaveFeatureAccess('CREATE_KSG_SMR')) {
        return (
            <EmptyPlaceholder
                text={
                    <Typography>
                        Здравствуйте, {profile?.firstName}, КСГ еще не был создан. <br />
                        Создание КСГ не доступно для пользователей с Вашими правами. <br /> Обратитесь к администратору
                    </Typography>
                }
                onClick={() => navigate(-1)}
                icon={<ArrowBack />}
                buttonText='Назад'
            />
        );
    }

    return (
        <>
            <PingedTasksManage isKSGCreated={false} />
            <ConfirmDialog />
            <Drawer
                anchor='right'
                open={isOpenInitDialog}
                onClose={handleClose}
            >
                <CreateKsg
                    onClose={handleClose}
                    setHasValues={setHasValues}
                    onSubmit={handleSubmit}
                />
            </Drawer>
            <EmptyPlaceholder
                text={
                    <>
                        Здравствуйте, {profile?.firstName}, у вас еще нет целевого план-графика реализации проекта.{' '}
                        <br /> Давайте создадим ваш первый КСГ
                    </>
                }
                contentStyle={{ width: '702px' }}
                buttons={
                    <Buttons
                        isImportFromOffer={isImportFromOffer}
                        onAdd={() => setIsInitDialog(true)}
                        onUpload={() => dispatch(toggleUploadKsg())}
                        navigateToEstimatePositionsPage={navigateToEstimatePositionsPage}
                    />
                }
            />
        </>
    );
};
