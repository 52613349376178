import { CellValueChangedEvent } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AG_GRID_DEFAULT_PARAMS } from '../../WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { StyledSNZAgGrid } from '../AgGridService/AgGrid.styles';
import { MSGResourceAPI } from '../api/MSGResourceAPI';
import { IMSGResourceItem, ISNZRequestParams } from '../api/api.types';
import { switchToMobileView } from '../components/components.service';
import { FlexColumnWrapper } from '../components/components.styles';
import { HIDDEN_MOBILE_COLUMNS, MSGResourceTableColDef } from './MSGResourceTable.colDef';

interface IMSGResoursePageProps {
    resourceType: ISNZRequestParams['resourceType'];
}

export function MSGResourcePage({ resourceType }: IMSGResoursePageProps) {
    const executorAuth = JSON.parse(localStorage.getItem('executorAuth')!);
    const [waitList, setWaitList] = useState<IMSGResourceItem[]>();
    const [doneList, setDoneList] = useState<IMSGResourceItem[]>();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        executorAuth &&
            MSGResourceAPI.getSNZ({ projectID: executorAuth!.id, resourceType: resourceType })
                // .then((data) => console.log(data))
                .then(({ doneList, waitList }) => {
                    setWaitList(() => waitList);
                    setDoneList(() => doneList);
                });
    }, []);

    function moveRowFromWaitToDoneList(params: CellValueChangedEvent) {
        setWaitList((prevValue) =>
            prevValue?.filter((listItem) => {
                return params.data.id != listItem.id;
            })
        );
        setDoneList((prevValue) => [...(prevValue || []), params.data]);
    }

    function moveRowFromDoneListToWaitList(params: CellValueChangedEvent) {
        setDoneList((prevValue) =>
            prevValue?.filter((listItem) => {
                return params.data.id != listItem.id;
            })
        );
        setWaitList((prevValue) => [
            ...(prevValue || []),
            { ...params.data, dailyChart: { ...params.data.dailyChart, fact: params.oldValue } },
        ]);
    }

    function editVolumeFact(params: CellValueChangedEvent, withMoveToDoneList: boolean) {
        withMoveToDoneList && moveRowFromWaitToDoneList(params);
        MSGResourceAPI.updateItemFact({
            projectID: executorAuth!.id || '',
            resourceType: resourceType,
            body: {
                fact: params.newValue,
                id: params.data.id,
            },
        }).catch(() => moveRowFromDoneListToWaitList(params));
    }

    return (
        <FlexColumnWrapper height={'calc(100% - 67px)'}>
            <FlexColumnWrapper
                height={'100%'}
                gap={2}
            >
                <StyledSNZAgGrid
                    rowData={searchParams.get('listType') == 'waitList' ? waitList : doneList}
                    columnDefs={MSGResourceTableColDef}
                    {...AG_GRID_DEFAULT_PARAMS()}
                    overlayNoRowsTemplate='Пока нет записей'
                    onCellValueChanged={(params: CellValueChangedEvent) =>
                        editVolumeFact(params, searchParams.get('listType') == 'waitList')
                    }
                    context={{
                        projectID: executorAuth!.id,
                    }}
                    onGridSizeChanged={(params) => switchToMobileView(params, HIDDEN_MOBILE_COLUMNS)}
                    onGridReady={(params) => switchToMobileView(params, HIDDEN_MOBILE_COLUMNS)}
                />
            </FlexColumnWrapper>
        </FlexColumnWrapper>
    );
}
