import { Step1Image, Step2Image, Step3Image } from './Guide.icons';
import { Minus, Spacer, Text } from './Guide.styles';

export enum Direction {
    Back = 'Back',
    Forward = 'Forward',
}

export enum Step {
    One = 'One',
    Two = 'Two',
    Three = 'Three',
}

export const dataByStep = {
    [Step.One]: {
        title: 'Выбор “Ключевой работы”',
        image: <Step1Image />,
        text: (
            <Text>
                Отметьте одну ключевую работу, в списке основных работ. При необходимости измените или заполните данные,
                установите единицу измерения, а так же наименование укрупненной расценки.
                <Spacer />
                После заполнения данных, нажмите кнопку “СОХРАНИТЬ”
            </Text>
        ),
    },
    [Step.Two]: {
        title: 'Добавление позиций в список',
        image: <Step2Image />,
        text: (
            <Text>
                Нажмите кнопку “Добавить позиции в список”, окно укрупненной расценки закроется и Вы перейдете на
                несортированный список работ. <br /> Отметьте “Основные и дополнительные работы” в списке. После нажмите
                кнопку “Добавить выбранные позиции в список”.
            </Text>
        ),
    },
    [Step.Three]: {
        title: 'Удаление укрупненной расценки',
        image: <Step3Image />,
        text: (
            <Text>
                Для удаления работы или материалов из списка нажмите <Minus /> после подтверждения, позиция будет
                удалена из списка.
                <br />
                Для удаления всей группы расценки, нажмите “УДАЛИТЬ ГРУППУ РАБОТ”. После подтверждения удаления,
                укрупненная расценка будет безвозвратно удалена.
            </Text>
        ),
    },
};
