import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import { EPageName } from '@/shared/constants/pages';

import { consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { projectsSelector } from '@/store/slices/projectsSlice';
import { useTypedSelector } from '@/store/store';

import { ConsolidatedPricesControls } from './components/ConsolidatedPricesControls';
import { Container } from './components/Container';
import { EstimatePositionsControls } from './components/EstimatePositionsControls';
import { EstimatePositionsNotice } from './components/EstimatePositionsNotice';
import { EstimatePositionsSwitcher } from './components/EstimatePositionsSwitcher';
import { ConsolidatedPriceEditPageTabs, PageTabs } from './components/PageTabs';
import { StructureManagementControls } from './components/StructureManagementControls';

interface IProps {}

export const ConsolidatedLayout: React.FC<IProps> = () => {
    const projects = useTypedSelector(projectsSelector);
    const editDialogMode = useTypedSelector(consolidatedPricesSelectors.editDialogMode);

    return (
        <Container>
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                {/* @ts-ignore */}
                {match([projects.pageName, editDialogMode])
                    .with([EPageName.Mofen, 'addPositions'], () => <ConsolidatedPriceEditPageTabs />)
                    .otherwise(() => (
                        <PageTabs />
                    ))}

                {/* @ts-ignore */}
                {match([projects.pageName, editDialogMode])
                    .with([EPageName.Mgp, P._], () => <EstimatePositionsControls />)
                    .with([EPageName.Mofen, 'addPositions'], () => <ConsolidatedPricesControls />)
                    .otherwise(() => null)}
            </Stack>

            {/* @ts-ignore */}
            {match(projects.pageName)
                .with(EPageName.Mofen, () => null)
                .otherwise(() => (
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        {/* @ts-ignore */}
                        {match(projects.pageName)
                            .with(EPageName.Mgp, () => (
                                <>
                                    <EstimatePositionsSwitcher />
                                    <EstimatePositionsNotice />
                                </>
                            ))
                            .with(EPageName.Sm, () => <StructureManagementControls />)
                            .otherwise(() => null)}
                    </Stack>
                ))}

            <Outlet />
        </Container>
    );
};
