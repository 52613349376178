import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useBanUserInModuleMutation } from '@/api/users/users.api';
import { PublicUserProfile } from '@/api/users/users.types';

import useConfirmDialog, { UseExitConfirmProps } from '@/hooks/useConfirmDialog';
import { useMutationHandlers } from '@/hooks/useMutationHandlers';

import UserForm from '../components/UserForm/UserForm';
import UserTopBar from '../components/UserTopBar/UserTopBar';
import { UserManagementProps } from './UserManagement.types';
import { UserManagementWrapper } from './styles';

function UserManagement({ profile = {} as PublicUserProfile }: UserManagementProps) {
    const { t } = useTranslation('user');
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const isEditUser = !!profile?.company;
    const userID = profile.id;
    const companyID = profile?.company?.companyID;

    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'delete' | 'exit'>('exit');

    function onFormChange(dirty: boolean) {
        setIsFormDirty(dirty);
    }

    const [banUserInModule, banUserInModuleResponse] = useBanUserInModuleMutation();

    const handleExitConfirm = useCallback((confirm: boolean) => {
        if (confirm) {
            navigate('/users');
        }
    }, []);

    const handleDeleteConfirm = useCallback((confirm: boolean) => {
        if (confirm) {
            banUserInModule({ userID, companyID });
        }
    }, []);

    const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
        delete: {
            handleConfirm: handleDeleteConfirm,
            title: 'Удалить пользователя?',
            body: (
                <>
                    Пользователь будет полностью удален <br /> из модуля Pragma.ResourceManagement
                </>
            ),
        },
        exit: {
            handleConfirm: handleExitConfirm,
        },
    };

    const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger]);

    const onReturnClick = useCallback(() => {
        setConfirmDialogTrigger('exit');

        isFormDirty ? openConfirm() : navigate('/users');
    }, [isFormDirty]);

    const onDeleteClick = useCallback(() => {
        setConfirmDialogTrigger('delete');
        openConfirm();
    }, []);

    useMutationHandlers(banUserInModuleResponse, () => {
        navigate('/users');
        enqueueSnackbar(t('success.profileDelete'), {
            variant: 'success',
        });
    });

    return (
        <>
            <UserManagementWrapper>
                <UserTopBar
                    onReturnClick={onReturnClick}
                    onDeleteClick={onDeleteClick}
                    isEditUser={isEditUser}
                />
                <UserForm
                    userProfileToChange={profile}
                    isEditUser={isEditUser}
                    onFormChange={onFormChange}
                />
            </UserManagementWrapper>
            <ConfirmDialog />
        </>
    );
}

export default UserManagement;
