import '@mui/material';

export const tabsData = [
    {
        value: 'mgp',
        label: 'Позиции сметы',
    },
    {
        value: 'mofen',
        label: 'Укрупненные расценки',
    },
    {
        value: 'sm',
        label: 'Ведомость укрупненных расценок',
    },
];

export const tabsDataIfAddPositions = [
    {
        value: 'mofen',
        label: 'Позиции сметы',
    },
];
