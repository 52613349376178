import FeedbackIcon from '@mui/icons-material/Feedback';
import React from 'react';

import { INotification } from '@/api/notificationsCenter/notificationsCenter.types';

import { getDateTimeString } from '@/shared/utils';

import s from '../NotificationsCenter.module.scss';
import { ButtonsNotificationCenter } from './ButtonsNotificationsCenter';

type TNotificationProps = {
    notification: INotification;
    isVolumeButtonDisabled: boolean;
    isPeriodButtonDisabled: boolean;
    increaseVolume: () => void;
    increasePeriod: () => void;
};

export const Notification: React.FC<TNotificationProps> = ({
    notification,
    isPeriodButtonDisabled,
    isVolumeButtonDisabled,
    increasePeriod,
    increaseVolume,
}) => {
    const disabledVolume = !notification.availableInc.volume || isVolumeButtonDisabled;
    const disabledPeriod = !notification.availableInc.period || isPeriodButtonDisabled;

    return (
        <div
            className={s.item_grid_container}
            key={notification.alertId}
        >
            <div className={s.item_container}>
                <FeedbackIcon
                    sx={{
                        color: '#ED6C02',
                        fontSize: '1.25rem',
                    }}
                />
                <div className={s.right_container}>
                    <div className={s.title_container}>
                        <p className={s.blue_title}>Сообщение системы</p>
                        <p className={s.gray_date}>{getDateTimeString(notification.createdAt)?.replace(/ в/g, ',')}</p>
                    </div>
                    <p className={s.description}>
                        {notification.valueMonth
                            ? `Внимание! Необходимо увеличить месячную выработку по работе “${notification.workName}” 
        строка “${notification.workNumOrder}” на “${notification.valueMonth}” иначе с текущей выработкой 
        Вы закончите ${notification.dateDone}`
                            : `Внимание! С текущей выработкой вы закончите ${notification.dateDone} по работе “${notification.workName}”,
                     строка “${notification.workNumOrder}” или спланируйте работы на последующие месяца.`}
                    </p>
                </div>
            </div>
            <ButtonsNotificationCenter
                disabledVolume={disabledVolume}
                disabledPeriod={disabledPeriod}
                increaseVolume={increaseVolume}
                increasePeriod={increasePeriod}
                border='none'
            />
        </div>
    );
};
