import { CloseOutlined, Delete } from '@mui/icons-material';
import { ClickAwayListener, IconButton, Menu, Paper, Tab, Tabs } from '@mui/material';
import { GetRowIdParams, ILoadingOverlayParams } from 'ag-grid-community';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IMSGGantBond, WorkMsg } from '@/api/msg/msg.types';

import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { req } from '@/pages/WorkManagment/api/api';
import { StyledAgGrid } from '@/pages/WorkManagment/components/AgGridService/AgGrid.styles';
import { AG_GRID_DEFAULT_PARAMS } from '@/pages/WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { LoadingOverlay } from '@/pages/WorkManagment/components/AgGridService/components/LoadingOverlay';
import { ConfirmDialog } from '@/pages/WorkManagment/components/ConfirmDialog/ConfirmDialog';
import { StyledBtn } from '@/pages/WorkManagment/components/components.styles';

import { getDateWithLeadingZero, getGantDepId } from '../../GantCell.service';
import { DepsTab } from '../DepsTab';
import { DeleteDepsTableColDef } from './DeleteDepsMenu.colDef';
import { IAgGridBond, IDeleteDepsMenuProps, IMSGBondRowData } from './DeleteDepsMenu.types';

export function DeleteDepsMenu({
    cellRendererParams,
    chartIndex,
    side,
    setDeleteDepsMenuSide,
    ...rest
}: IDeleteDepsMenuProps) {
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [linkType, setLinkType] = useState<'from' | 'to'>('from');
    const [bond, setBond] = useState<IAgGridBond>();
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        const day = chartIndex + 1;
        const { month, year } = cellRendererParams.data as WorkMsg;
        req.post(`/projects/${projectId}/dependencies/works/msg/key-info`, {
            date: getDateWithLeadingZero(day, month, year),
            side: side,
            workId: cellRendererParams.data?.id,
        })
            .then(({ data }) =>
                setBond(() => ({
                    ...data,
                    from: data?.from?.map((fromBond: IMSGGantBond) => ({ ...fromBond, checked: false })),
                    to: data?.to?.map((bondTo: IMSGGantBond) => ({ ...bondTo, checked: false })),
                }))
            )
            .catch((e) => {
                enqueueSnackbar('Ошибка', { variant: 'error' });
                setDeleteDepsMenuSide(() => undefined);
            });
    }, []);

    function getRowIDTo(params: GetRowIdParams<unknown, any>) {
        const { workId, date } = params.data as IMSGBondRowData;
        const rowID = getGantDepId({
            workFromID: bond?.key?.workId?.toString() || '',
            workToID: workId.toString(),
            workFromDate: bond?.key.date || '',
            workToDate: date,
        });

        return rowID;
    }

    function getRowIDFrom(params: GetRowIdParams<unknown, any>) {
        const { workId, date } = params.data as IMSGBondRowData;
        const rowID = getGantDepId({
            workFromID: workId.toString(),
            workToID: bond?.key?.workId?.toString() || '',
            workFromDate: date,
            workToDate: bond?.key.date || '',
        });

        return rowID;
    }

    function deleteChosenDepsMass() {
        const from = bond?.from.filter((dep) => !!dep.checked);
        const to = bond?.to.filter((dep) => !!dep.checked);
        const fromAndToArr: { [key in 'from' | 'to']: Partial<IMSGBondRowData> }[] = [];
        from?.forEach((fromBond) => {
            fromAndToArr.push({
                from: {
                    ...fromBond,
                    checked: undefined,
                },
                to: {
                    ...bond?.key,
                    checked: undefined,
                },
            });
        });
        to?.forEach((toBond) => {
            fromAndToArr.push({
                from: {
                    ...bond?.key,
                    checked: undefined,
                },
                to: {
                    ...toBond,
                    checked: undefined,
                },
            });
        });
        req.post(`/projects/${projectId}/dependencies/works/msg/clear-keys`, {
            from: from,
            key: bond?.key,
            to: to,
        })
            .then(() => {
                fromAndToArr.forEach((bond) => {
                    document
                        .getElementById(
                            getGantDepId({
                                workFromID: bond?.from?.workId?.toString() || '',
                                workToID: bond?.to?.workId?.toString() || '',
                                workFromDate: bond?.from?.date?.toString() || '',
                                workToDate: bond?.to?.date?.toString() || '',
                            })
                        )
                        ?.remove();
                    setDeleteDepsMenuSide(() => undefined);
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', { variant: 'error' });
                setDeleteDepsMenuSide(() => undefined);
            });
    }

    return bond ? (
        <Menu
            {...rest}
            sx={{ '.MuiList-root': { p: 0 } }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <ClickAwayListener
                onClickAway={(e) => {
                    setDeleteDepsMenuSide(() => undefined);
                }}
            >
                <Paper
                    elevation={8}
                    sx={{ width: 600, height: 400, p: 2 }}
                >
                    <FlexColumnWrapper height={'100%'}>
                        <FlexRowWrapper justifyContent={'space-between'}>
                            <Tabs
                                sx={{
                                    '.MuiButtonBase-root': {
                                        p: 1,
                                        minHeight: '40px',
                                    },
                                    minHeight: '40px !important',
                                }}
                                value={linkType}
                                onChange={(e, value) => {
                                    setLinkType((prevState) => value);
                                }}
                            >
                                <Tab
                                    value='from'
                                    label='Предшественники'
                                    icon={
                                        <DepsTab
                                            isActive={linkType === 'from'}
                                            depsCount={bond?.from?.length || 0}
                                            linkType={'from'}
                                        />
                                    }
                                    iconPosition='end'
                                />
                                <Tab
                                    value='to'
                                    label='Последователи'
                                    icon={
                                        <DepsTab
                                            isActive={linkType === 'to'}
                                            depsCount={bond?.to?.length || 0}
                                            linkType={'to'}
                                        />
                                    }
                                    iconPosition='end'
                                />
                            </Tabs>
                            <IconButton
                                sx={{ borderRadius: 1, background: 'rgba(255, 177, 177, 0.15)' }}
                                disableRipple
                                color='error'
                                component='span'
                                onClick={() => setDeleteDepsMenuSide(() => undefined)}
                            >
                                <CloseOutlined />
                            </IconButton>
                        </FlexRowWrapper>
                        {/* @ts-ignore */}
                        {linkType === 'from' && (
                            <StyledAgGrid
                                rowData={bond?.from}
                                getRowId={getRowIDFrom}
                                //@ts-ignore
                                columnDefs={DeleteDepsTableColDef}
                                {...AG_GRID_DEFAULT_PARAMS()}
                                loadingOverlayComponent={(props: ILoadingOverlayParams) => <LoadingOverlay open />}
                                overlayNoRowsTemplate='Нет данных'
                                context={{
                                    linkType: linkType,
                                    side: side,
                                    setBond: setBond,
                                }}
                            />
                        )}
                        {linkType === 'to' && (
                            <StyledAgGrid
                                rowData={bond?.to}
                                getRowId={getRowIDTo}
                                //@ts-ignore
                                columnDefs={DeleteDepsTableColDef}
                                {...AG_GRID_DEFAULT_PARAMS()}
                                loadingOverlayComponent={(props: ILoadingOverlayParams) => <LoadingOverlay open />}
                                overlayNoRowsTemplate='Нет данных'
                                context={{
                                    linkType: linkType,
                                    side: side,
                                    setBond: setBond,
                                }}
                            />
                        )}
                        <StyledBtn
                            color={'error'}
                            startIcon={<Delete />}
                            fullWidth
                            onClick={() => setIsConfirmDeleteDialogOpen(() => true)}
                            disabled={
                                !(bond?.from.find((bond) => bond.checked) || bond?.to.find((bond) => bond.checked))
                            }
                        >
                            Удалить связи
                        </StyledBtn>
                    </FlexColumnWrapper>
                </Paper>
            </ClickAwayListener>
            <ConfirmDialog
                open={isConfirmDeleteDialogOpen}
                title={'Вы уверены, что хотите удалить выбранные связи?'}
                onYes={{
                    action: deleteChosenDepsMass,
                    btnText: 'Удалить связи',
                }}
                onNo={{
                    action: (e) => setIsConfirmDeleteDialogOpen(() => false),
                }}
            />
        </Menu>
    ) : null;
}
