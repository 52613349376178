import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { api } from '@/api/api';
import { pingedTasksApi } from '@/api/pingedTasks/pingedTasks.api';

import agGridKsgReducer from './slices/agGridKsgMsgSlices/agGridKsgSlice';
import authReducer from './slices/authSlice';
import basePlanReducer from './slices/basePlan';
import { consolidatedPricesReducer } from './slices/consolidatedPricesSlice';
import cpgReducer from './slices/cpgSlice';
import cpgViewReducer from './slices/cpgViewSlice';
import drawersReducer from './slices/drawersSlice';
import { estimatePositionsReducer } from './slices/estimatePositions';
import msgReducer from './slices/executorSlice';
import filtersReducer from './slices/filtersSlice';
import filtersSomReducer from './slices/filtersSomSlice';
import filtersWorkersMimReducer from './slices/filtersWorkersMimSlice';
import integrationStatusReducer from './slices/integrationStatusSlice';
import ksgTemplatesReducer from './slices/ksgTemplatesSlice';
import monthMsgReducer from './slices/monthMsgSlice';
import msgGantReducer from './slices/msgGantSlice';
import msgTemplatesReducer from './slices/msgTemplatesSlice';
import paginationReducer from './slices/paginationSlice';
import pingedTasksReducer from './slices/pingedTasksSlice';
import priceSettingsReducer from './slices/priceSettings';
import profileReducer from './slices/profileSlice';
import projectReducer from './slices/projectsSlice';
import { settingViewAgGridReducer } from './slices/settings/settingsViewAgGrid';
import templatesSharedReducer from './slices/templatesSharedSlice';
import templatesReducer from './slices/templatesSlice';
import workManagementReducer from './slices/workManagementSlice';

const settingsCombine = combineReducers({
    viewAgGrid: settingViewAgGridReducer,
});

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        project: projectReducer,
        cpg: cpgReducer,
        drawers: drawersReducer,
        msg: msgReducer,
        cpgView: cpgViewReducer,
        filters: filtersReducer,
        pagination: paginationReducer,
        msgTemplates: msgTemplatesReducer,
        ksgTemplates: ksgTemplatesReducer,
        templates: templatesReducer,
        monthMsg: monthMsgReducer,
        templatesShared: templatesSharedReducer,
        filtersWorkersMim: filtersWorkersMimReducer,
        profile: profileReducer,
        basePlan: basePlanReducer,
        ag_grid_ksg: agGridKsgReducer,
        filters_som: filtersSomReducer,
        settings: settingsCombine,
        priceSettings: priceSettingsReducer,
        msgGant: msgGantReducer,
        integrationStatus: integrationStatusReducer,
        workManagement: workManagementReducer,
        pingedTasks: pingedTasksReducer,
        estimatePositions: estimatePositionsReducer,
        consolidatedPrices: consolidatedPricesReducer,
        [pingedTasksApi.reducerPath]: pingedTasksApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(api.middleware, pingedTasksApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
