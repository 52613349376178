import { useEffect, useRef, useState } from 'react';

import { LoadingOverlay } from '@/pages/WorkManagment/components/AgGridService/components/LoadingOverlay';

export const useAgGridLoader = (isLoading: boolean) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [rect, setRect] = useState<DOMRect | null>(null);

    useEffect(() => {
        if (!ref.current) return;

        const rect = ref.current.getBoundingClientRect();
        setRect(rect);
    }, [ref.current]);

    return {
        ref,
        LoadingComponent: (
            <LoadingOverlay
                key='overlay'
                open={isLoading}
                transitionDuration={0}
                sx={{
                    zIndex: 999,
                    left: rect?.left,
                    top: rect?.top,
                    width: rect?.width,
                    height: rect?.height,
                }}
            />
        ),
    };
};
