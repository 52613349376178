import NiceModal from '@ebay/nice-modal-react';
import { animated } from '@react-spring/web';
import { FC } from 'react';

import { useBoop } from '@/hooks/useBoop';

import { TMode } from '../../ConsolidatedPriceDialog.types';
import { GuideDialog } from '../Guide';
import {
    ArrowBackIcon,
    BackButton,
    Container,
    HelpButton,
    HelpIcon,
    Left,
    Right,
    SaveButton,
    Title,
} from './Header.styles';

const AnimatedHelpIcon = animated(HelpIcon);
const AnimatedArrowBackIcon = animated(ArrowBackIcon);

const titleByMode: Record<TMode, string> = {
    create: 'Создание укрупненной расценки',
    edit: 'Редактирование укрупненной расценки',
};

interface IProps {
    mode: TMode;
    isSaveButtonDisabled: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const Header: FC<IProps> = ({ mode, isSaveButtonDisabled, onSubmit, onClose }) => {
    const handleGuideVisible = () => {
        NiceModal.show(GuideDialog);
    };

    const [helpButtonStyle, helpButtonTrigger] = useBoop({ rotation: -20, scale: 1.1, timing: 200 });
    const [backButtonStyle, backButtonTrigger] = useBoop({ x: -2, timing: 200 });

    return (
        <Container>
            <Left>
                <BackButton
                    onClick={onClose}
                    startIcon={<AnimatedArrowBackIcon style={backButtonStyle} />}
                    onMouseEnter={backButtonTrigger}
                >
                    Назад
                </BackButton>
                <Title>{titleByMode[mode]}</Title>
            </Left>
            <Right>
                <HelpButton
                    onClick={handleGuideVisible}
                    onMouseEnter={helpButtonTrigger}
                >
                    <AnimatedHelpIcon style={helpButtonStyle} />
                </HelpButton>
                <SaveButton
                    onClick={onSubmit}
                    disabled={isSaveButtonDisabled}
                >
                    Сохранить
                </SaveButton>
            </Right>
        </Container>
    );
};
