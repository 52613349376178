import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    TConsolidatedPriceId,
    TConsolidatedPricesFilterBodyValue,
    TConsolidatedPricesFilterKeys,
} from '@/api/consolidatedPrices';
import {
    TEstimatePositionId,
    TEstimatePositionsFilterBodyValue,
    TEstimatePositionsFilterKeys,
} from '@/api/estimatePositions';

import {
    updateConsolidatedPricesFilterByAllMode,
    updateConsolidatedPricesFilterBySingleMode,
} from '@/shared/utils/consolidatedPrices.utils';

import { Nullable } from '@/types/common.types';

import { RootState } from '../../store';
import { TEstimateCheckedPositionMap } from '../estimatePositions';
import { TEditDialogMode, TInitialState, TMode } from './consolidatedPricesSlice.types';

const defaultFilterValue = {
    selectAll: false,
    values: [],
};

export const defaultFilters = {
    lsrCode: defaultFilterValue,
    lsrNumber: defaultFilterValue,
    chapter: defaultFilterValue,
    header: defaultFilterValue,
    code: defaultFilterValue,
    consolidatedName: defaultFilterValue,
    rawUnit: defaultFilterValue,
};

const initialState: TInitialState = {
    filters: defaultFilters,
    editDialogMode: 'hide',
    editConsolidatedPriceId: null,

    estimatePositionsFilters: {
        lsrCode: defaultFilterValue,
        lsrNumber: defaultFilterValue,
        chapter: defaultFilterValue,
        header: defaultFilterValue,
        code: defaultFilterValue,
        positionName: defaultFilterValue,
        rawUnit: defaultFilterValue,
    },
    estimatePositionsCheckedPositionsMap: new Map(),
    estimatePositionsCheckedPositionsMapForEdit: new Map(),
    estimatePositionsCheckedPositionId: null,
    estimatePositionsRedrawAllRows: false,
};

const slice = createSlice({
    name: 'consolidatedPrices',
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<{
                key: TConsolidatedPricesFilterKeys;
                checked: boolean;
                values: TConsolidatedPricesFilterBodyValue['values'];
                mode: TMode;
                length: number;
            }>
        ) => {
            const { key, values: newValues, mode, checked, length } = action.payload;

            if (mode === 'all') {
                updateConsolidatedPricesFilterByAllMode(state.filters, key, checked, newValues);
                return;
            }

            if (mode === 'single') {
                updateConsolidatedPricesFilterBySingleMode(state.filters, key, checked, newValues, length);
            }
        },
        resetFilter: (state, action: PayloadAction<TConsolidatedPricesFilterKeys>) => {
            state.filters[action.payload].selectAll = false;
            state.filters[action.payload].values = [];
        },
        setEditDialogMode: (state, action: PayloadAction<TEditDialogMode>) => {
            state.editDialogMode = action.payload;
        },
        setEditConsolidatedPriceId: (state, action: PayloadAction<Nullable<TConsolidatedPriceId>>) => {
            state.editConsolidatedPriceId = action.payload;
        },
        setEstimatePositionsFilter: (
            state,
            action: PayloadAction<{
                key: TEstimatePositionsFilterKeys;
                checked: boolean;
                values: TEstimatePositionsFilterBodyValue['values'];
                mode: TMode;
                length: number;
            }>
        ) => {
            const { key, values: newValues, mode, checked, length } = action.payload;

            if (mode === 'all') {
                updateConsolidatedPricesFilterByAllMode(state.estimatePositionsFilters, key, checked, newValues);
                return;
            }

            if (mode === 'single') {
                updateConsolidatedPricesFilterBySingleMode(
                    state.estimatePositionsFilters,
                    key,
                    checked,
                    newValues,
                    length
                );
            }
        },
        resetEstimatePositionsFilter: (state, action: PayloadAction<TEstimatePositionsFilterKeys>) => {
            state.estimatePositionsFilters[action.payload].selectAll = false;
            state.estimatePositionsFilters[action.payload].values = [];
        },
        updateEstimatePositionsCheckedPositionsMap(state, action: PayloadAction<TEstimateCheckedPositionMap>) {
            state.estimatePositionsCheckedPositionsMap = action.payload;
        },
        updateEstimatePositionsCheckedPositionsMapForEdit(state, action: PayloadAction<TEstimateCheckedPositionMap>) {
            state.estimatePositionsCheckedPositionsMapForEdit = action.payload;
        },
        setEstimatePositionsCheckedPositionId(state, action: PayloadAction<Nullable<TEstimatePositionId>>) {
            state.estimatePositionsCheckedPositionId = action.payload;
        },
        resetEstimatePositions(state) {
            state.estimatePositionsCheckedPositionsMap = new Map();
            state.estimatePositionsCheckedPositionId = null;
            state.estimatePositionsRedrawAllRows = !state.estimatePositionsRedrawAllRows;
            state.estimatePositionsFilters = initialState.estimatePositionsFilters;
        },
        reset: () => initialState,
    },
});

export const consolidatedPricesReducer = slice.reducer;

export const consolidatedPricesSelectors = {
    filters: (state: RootState) => state.consolidatedPrices.filters,
    editDialogMode: (state: RootState) => state.consolidatedPrices.editDialogMode,
    editConsolidatedPriceId: (state: RootState) => state.consolidatedPrices.editConsolidatedPriceId,
    estimatePositionsFilters: (state: RootState) => state.consolidatedPrices.estimatePositionsFilters,
    estimatePositionsCheckedPositionsMapForEdit: (state: RootState) =>
        state.consolidatedPrices.estimatePositionsCheckedPositionsMapForEdit,
    estimatePositionsCheckedPositionsMap: (state: RootState) =>
        state.consolidatedPrices.estimatePositionsCheckedPositionsMap,
    estimatePositionsCheckedPositionId: (state: RootState) =>
        state.consolidatedPrices.estimatePositionsCheckedPositionId,
    estimatePositionsRedrawAllRows: (state: RootState) => state.consolidatedPrices.estimatePositionsRedrawAllRows,
};

export const consolidatedPricesActions = slice.actions;
