import { Tab } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { withRecalculatedTotalRow } from '@/shared/components/AgGrid/AgGrid.service';
import { ITotalRow } from '@/shared/components/AgGrid/AgGrid.types';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';

import { profileSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

import { FlexRowWrapper } from '../../../../../NewExecutorView/components/components.styles';
import { EstimateAPI } from '../../../../api/EstimateAPI';
import { IEstimateResourseTotal } from '../../../../api/api.types';
import { StyledAgGrid } from '../../../../components/AgGridService/AgGrid.styles';
import {
    AG_GRID_DEFAULT_PARAMS,
    disableAddAndDelete,
} from '../../../../components/AgGridService/AgGridColumnDef.service';
import { CardTitle, CardWrapper, TabsContained } from '../../../../components/components.styles';
import { EstimateResourcesPageWrapper } from './EstimateResourcesPage.styles';
import { IEstimateResource } from './EstimateResourcesPage.types';
import { EstimateResourceTable } from './EstimateResourcesTable/EstimateResourceTable';
import { estimateTotalColDef } from './TotalTable/TotalTable.colDef';

export function EstimateResourcesPage() {
    const { workID, projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { profile } = useTypedSelector(profileSelector);

    // const [totalData, setTotalData] = useState<IEstimateResourseTotal[] | null>(null)
    const [totalRow, setTotalRow] = useState<IEstimateResourseTotal | null>(null);
    const [rowData, setRowData] = useState<IEstimateResource[] | null>(null);
    const [pageType, setPageType] = useState<'indicators' | 'totals'>('indicators');

    useEffect(() => {
        setRowData(() => null);
        setTotalRow(() => null);
        EstimateAPI.getResources({
            projectID: projectId,
            workID: workID,
            pageType: pageType,
        })
            .then(({ data, total }) => {
                setRowData(() => data);
                pageType === 'totals' &&
                    setTimeout(() => {
                        setTotalRow(() => total);
                    });
            })
            .catch((error) =>
                enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                    variant: 'error',
                })
            );
    }, [pageType]);

    return (
        <EstimateResourcesPageWrapper>
            <CardWrapper gridColumn={'1/4'}>
                <FlexRowWrapper justifyContent={'space-between'}>
                    <CardTitle size='small'>Смета</CardTitle>
                    <TabsContained
                        value={pageType}
                        onChange={(e, value: typeof pageType) => {
                            setPageType(() => value);
                        }}
                    >
                        <Tab
                            value={'indicators'}
                            label='Показатели'
                        ></Tab>
                        <Tab
                            value={'totals'}
                            label='Итого'
                        ></Tab>
                    </TabsContained>
                </FlexRowWrapper>
                {pageType === 'indicators' && <EstimateResourceTable rowData={rowData} />}
                {pageType === 'totals' && totalRow && (
                    <StyledAgGrid
                        key='totals'
                        rowData={rowData}
                        columnDefs={estimateTotalColDef}
                        {...withRecalculatedTotalRow(
                            {
                                ...totalRow,
                                id: 'totalRow',
                                type: 'ИТОГО',
                            } as unknown as ITotalRow<IEstimateResourseTotal>,
                            [
                                { field: 'costBaseTotal', totalFunc: 'sum' },
                                { field: 'costBaseTotalVolume', totalFunc: 'sum' },
                                { field: 'costCurrTotal', totalFunc: 'sum' },
                                { field: 'costCurrTotalVolume', totalFunc: 'sum' },
                            ],
                            'type',
                            'staticTotal'
                        )}
                        getRowId={(params) => (params?.data as IEstimateResourseTotal).type}
                        {...AG_GRID_DEFAULT_PARAMS()}
                        {...((!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_MANAGMENT') ||
                            JSON.parse(localStorage.getItem('work')!)?.hasChildren) && {
                            suppressClickEdit: true,
                            ...disableAddAndDelete(true),
                        })}
                    />
                )}
            </CardWrapper>
        </EstimateResourcesPageWrapper>
    );
}
