import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useCreateProjectMutation } from '@/api/projects/projects.api';
import { ProjectFullStatuses } from '@/api/projects/projects.types';

import { toggleAddProject } from '@/store/slices/drawersSlice';

import FormikFormC, { formObjType } from './FormikFormC';
import s from './ProjectsDrawer.module.scss';

const AddProjectDrawer = () => {
    const [createProject] = useCreateProjectMutation();

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    const onSubmit = useCallback(
        (values: formObjType) => {
            createProject({
                abbr: values.abbr || '',
                description: values.description || '',
                endDate: format(values.dateEnd as Date, 'dd.MM.yyyy'),
                startDate: format(values.dateStart as Date, 'dd.MM.yyyy'),
                status: (values.status as ProjectFullStatuses) || 'Активный',
                timezone: values.timezone || 3,
                title: values.title || '',
            })
                .unwrap()
                .then((res) => {
                    if (!res.success) {
                        return enqueueSnackbar(String(res.description), {
                            variant: 'error',
                        });
                    } else {
                        dispatch(toggleAddProject());
                        enqueueSnackbar(t('project_added'), {
                            variant: 'success',
                        });
                    }
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', {
                        variant: 'error',
                    });
                    console.error(e);
                });
        },
        [createProject, dispatch, enqueueSnackbar, t]
    );

    return (
        <React.Fragment>
            <div className={s.title}>Добавление проекта</div>
            <main className={s.main}>
                <FormikFormC
                    onSubmit={onSubmit}
                    onClose={() => dispatch(toggleAddProject())}
                />
            </main>
        </React.Fragment>
    );
};

export default AddProjectDrawer;
