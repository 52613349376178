import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Popover } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Work } from '@/api/ksg/ksg.types';
import { IIntegrationStatus } from '@/api/works/works.types';

import { ResetButton } from '@/components/ui/UIButton';
import { UIFolder } from '@/components/ui/UIFolder';

import ConfirmationModal from '@/shared/components/ConfirmationModal/ConfirmationModal';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import g from '@/shared/stylesheets/TableGrid.module.scss';

import { profileSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

import { Nullable } from '@/types/common.types';

import { MassVolumeDistributionMenu } from './MassVolumeDistributionMenu/MassVolumeDistributionMenu';

const addButtonStyle = {
    1: {
        color: '#fff',
        backgroundColor: '#0044b4',
    },
    2: {
        color: '#fff',
        backgroundColor: '#3B74D4',
    },
    3: {
        color: '#fff',
        backgroundColor: '#6C9BEA',
    },
    4: {
        color: '#fff',
        backgroundColor: '#98BFFF',
    },
    5: {
        color: '#0044B4',
        backgroundColor: '#C2D9FF',
    },
    6: {
        color: '#0044B4',
        backgroundColor: '#D5E6FF',
    },
    7: {
        color: '#0044B4',
        backgroundColor: '#E3EEFF',
    },
};

const AddButton = styled(ResetButton)<{ level: keyof typeof addButtonStyle }>`
    font-size: 10px;
    padding: 0.2rem 0.3rem;
    border-radius: 2px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 2.8rem;
    padding: 0.15rem 0.3rem 0.15rem 0.1rem;

    color: #fff;
    background-color: #0044b4;

    color: ${({ level }) => addButtonStyle[level]?.color || '#fff'};
    background-color: ${({ level }) => addButtonStyle[level]?.backgroundColor || '#0044b4'};

    transition: all 0.3s ease;

    &:hover {
        filter: opacity(0.8);
    }
`;

const DeleteButton = styled(ResetButton)<{ isBorder: boolean }>`
    border-left: ${({ isBorder }) => (isBorder ? '2px solid rgba(122, 145, 247, 0.3)' : 'none')};
    padding-left: ${({ isBorder }) => (isBorder ? '0.3rem' : '0')};

    transition: all 0.3s ease;

    &:hover {
        filter: opacity(0.8);
    }
`;

interface IProps {
    arrowDown: boolean;
    isOfferActive?: boolean;
    onDeleteRow: (id: number) => void;
    setAddWorkId: () => void;
    data: Work | undefined;
    integrationStatus?: Nullable<IIntegrationStatus>;
    refetchCollectionOfFactOfAct: () => void;
    onRowHideToggle: () => void;
}

// TODO:
// 1. Переписать компонент
export function LevelRenderer({
    onRowHideToggle,
    integrationStatus,
    arrowDown,
    onDeleteRow,
    setAddWorkId,
    data,
    isOfferActive,
    refetchCollectionOfFactOfAct,
}: IProps) {
    const [openActionBar, setOpenActionBar] = useState(false);
    const [warningModal, setWarningModal] = useState(false);

    const { profile } = useTypedSelector(profileSelector);

    const ref = useRef<any>(null);

    const [massVolumeDistributionMenuAnchor, setMassVolumeDistributionMenuAnchor] =
        useState<Nullable<HTMLButtonElement>>(null);

    const [levelAnchor, setLevelAnchor] = useState<Nullable<HTMLButtonElement>>(null);

    const handleLevelAnchorSet = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (profile.role === 'client') return;

        setLevelAnchor(event.currentTarget);
        setOpenActionBar(true);
    };

    const handleMassVolumeDistributionMenuAnchorSet = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMassVolumeDistributionMenuAnchor(event.currentTarget);
    };

    const showArrows = useMemo(() => {
        if (data?.hasChildren || (data?.workPosition && isOfferActive)) {
            if (!arrowDown) {
                return (
                    <IconButton
                        sx={{
                            padding: 0,
                        }}
                        color='inherit'
                        component='button'
                    >
                        <KeyboardArrowDownIcon
                            sx={{
                                fontSize: 16,
                            }}
                        />
                    </IconButton>
                );
            }
            return (
                <IconButton
                    sx={{
                        padding: 0,
                    }}
                    color='inherit'
                    component='button'
                >
                    <KeyboardArrowUpIcon
                        sx={{
                            fontSize: 16,
                        }}
                    />
                </IconButton>
            );
        }
        return null;
    }, [arrowDown, data?.hasChildren]);

    useEffect(() => {
        function handleClickOutside(evt: any) {
            if (ref.current && !ref.current.contains(evt.target)) {
                setOpenActionBar(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const deleteWork = () => {
        setWarningModal(false);

        onDeleteRow(data?.id || 1);
    };

    const AddActionBarEl = () => {
        return (
            <AddButton
                onClick={() => {
                    setLevelAnchor(null);
                    setAddWorkId();
                }}
                level={((data?.level || 0) + 1) as keyof typeof addButtonStyle}
                // className={stylesLevel((data?.level || 0) + 1)}
                style={{}}
            >
                <AddIcon
                    sx={{
                        color: '#7890b2',
                        fontSize: '1rem',
                    }}
                />
                УР{(data?.level || 0) + 1}
            </AddButton>
        );
    };

    const DeleteActionBarEl = () => {
        return (
            <DeleteButton
                isBorder={data?.level !== 7}
                onClick={() => {
                    setLevelAnchor(null);
                    setWarningModal(true);
                }}
            >
                <DeleteOutlineOutlinedIcon
                    sx={{
                        color: '#7890b2',
                        fontSize: '1.2rem',
                        marginTop: '0.1rem',
                    }}
                />
            </DeleteButton>
        );
    };

    const actionBar = () => {
        const jsxElements = {
            addAndDelete: DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_WORK_KSG') && (
                <Popover
                    anchorOrigin={{
                        vertical: 0,
                        horizontal: 'left',
                    }}
                    anchorEl={levelAnchor}
                    open={Boolean(levelAnchor)}
                    onClose={() => setLevelAnchor(null)}
                >
                    <div
                        ref={ref}
                        className={g.action_bar}
                    >
                        <AddActionBarEl />
                        <DeleteActionBarEl />
                    </div>
                </Popover>
            ),
            delete: DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_WORK_KSG') && (
                <Popover
                    anchorOrigin={{
                        vertical: 0,
                        horizontal: 'left',
                    }}
                    anchorEl={levelAnchor}
                    open={Boolean(levelAnchor)}
                    onClose={() => setLevelAnchor(null)}
                >
                    <div
                        ref={ref}
                        style={{
                            left: '3rem',
                        }}
                        className={g.action_bar}
                    >
                        <DeleteActionBarEl />
                    </div>
                </Popover>
            ),
        };

        return data?.level === 7 ? jsxElements.delete : jsxElements.addAndDelete;
    };

    const getConfirmationModalContent = () => {
        return `После удаления работы${data?.objTitle ? `  «${data?.objTitle}»` : ''}, ${
            data?.level
        } уровня эти данные будут безвозвратно утеряны.`;
    };

    return (
        <>
            <ConfirmationModal
                openState={warningModal}
                onCancel={() => setWarningModal(false)}
                onConfirm={deleteWork}
                title='Вы уверены что хотите удалить работу?'
                content={getConfirmationModalContent()}
            />

            <MassVolumeDistributionMenu
                preparedAct={data?.preparedAct!}
                workId={data?.id!}
                currentAct={integrationStatus?.currentAct!}
                anchor={massVolumeDistributionMenuAnchor}
                onClose={() => setMassVolumeDistributionMenuAnchor(null)}
                refetchCollectionOfFactOfAct={refetchCollectionOfFactOfAct}
            />

            <div className={profile.role !== 'client' ? g.arrow_level_container : ''}>
                <div
                    onClick={onRowHideToggle}
                    className={g.arrow_level}
                >
                    {showArrows}
                </div>

                <div className={g.relative}>
                    {data?.level !== 0 && isOfferActive && (
                        <div className={g.mass_volume_distribution}>
                            <button
                                className={g.mass_volume_distribution_button}
                                onClick={handleMassVolumeDistributionMenuAnchorSet}
                            >
                                <span />
                                <span />
                                <span />
                            </button>
                        </div>
                    )}

                    <ResetButton onClick={handleLevelAnchorSet}>
                        <UIFolder
                            level={data?.level!}
                            hasChildren={data?.hasChildren!}
                        />
                    </ResetButton>
                </div>
                {openActionBar && actionBar()}
            </div>
        </>
    );
}
