export const enum EPageName {
    Projects = 'projects',
    ProjectsCharts = 'projects_charts',
    Users = 'users',
    UsersAdd = 'users_add',
    UsersEdit = 'users_edit',
    Ksg = 'ksg',
    KsgPeople = 'ksg_people',
    KsgEquip = 'ksg_equip',
    KsgKc6a = 'ksg_kc6a',
    Msg = 'msg',
    MsgPeople = 'msg_people',
    MsgEquip = 'msg_equip',
    Executor = 'executor',
    ExecutorDetailsMim = 'executor_details_mim',
    ExecutorDetailsStaff = 'executor_details_staff',
    ExecutorPeople = 'executor_people',
    ExecutorEquip = 'executor_equip',
    Mgp = 'mgp',
    Mofen = 'mofen',
    Sm = 'sm',
    ag_grid = 'ag_grid',
    ag_grid_msg = 'ag_grid_msg',
    Mto = 'mto',
    WorkManagement = 'work_management',
    Brigades = 'brigades',
    BrigadesAdd = 'brigades_add',
    BrigadesEdit = 'brigades_edit',
    MsgPrint = 'msg_print',
    secretGantt = 'secretGantt',
}
