import qs from 'qs';

import { api } from '@/api/api';

import { setTotal } from '@/store/slices/paginationSlice';
import { store } from '@/store/store';

import {
    getExecutorsListMsgPeopleResI,
    getMsgPeopleReqI,
    getMsgPeopleResI,
    initMsgReqI,
    patchChartsMsgPeopleReqI,
    patchChartsMsgPeopleResI,
    setExecutorMsgPeopleReqI,
    setExecutorMsgPeopleResI,
    updateExecutorPlanMsgPeopleReqI,
    updateExecutorPlanMsgPeopleResI,
} from './msgPeople.types';

export const msgPeopleApi = api.injectEndpoints({
    endpoints: (build) => ({
        getMsgPeople: build.query<getMsgPeopleResI, getMsgPeopleReqI>({
            query: ({ id, year, month, limit, offset, filter }) => ({
                url: `/projects/${id}/staffs/msg/list?${qs.stringify(
                    { title: store.getState().filtersWorkersMim.titlesArr },
                    {
                        indices: false,
                    }
                )}`,
                params: { year, month, limit, offset, filter },
                method: 'GET',
            }),
            async onQueryStarted(arg, api) {
                const data = await api.queryFulfilled;

                api.dispatch(setTotal(data.data.data.length ? data.data.total : 1));
            },
            providesTags: ['MsgPeople'],
        }),

        initMsgPeople: build.mutation<{ success: boolean }, initMsgReqI>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/staffs/msg/init`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['MsgPeople', 'Headers'],
        }),

        updateChartsMsgPeople: build.mutation<patchChartsMsgPeopleResI, patchChartsMsgPeopleReqI>({
            query: ({ id, year, month, body }) => ({
                url: `/projects/${id}/staffs/msg/update-charts`,
                params: { year, month },
                method: 'PATCH',
                body,
            }),
            async onQueryStarted({ id, year, month, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(
                        msgPeopleApi.util.updateQueryData(
                            'getMsgPeople',
                            {
                                id,
                                year,
                                month,
                                limit: +store.getState().pagination.rowsPerPage,
                                offset:
                                    (store.getState().pagination.page - 1) * +store.getState().pagination.rowsPerPage,
                                filter: store.getState().project.headerSearch,
                            },
                            (draft) => {
                                const indexChange = draft.data.findIndex((work) => {
                                    return work.id === updatedStatus.data[0].id;
                                });
                                draft.data.splice(indexChange, 1, updatedStatus.data[0]);
                            }
                        )
                    );
                } catch {}
            },
        }),

        setExecutorMsgPeople: build.mutation<setExecutorMsgPeopleResI, setExecutorMsgPeopleReqI>({
            query: ({ id, year, month, body }) => ({
                url: `/projects/${id}/staffs/msg/set-executor`,
                params: { year, month },
                method: 'POST',
                body,
            }),
            async onQueryStarted({ id, year, month, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(
                        msgPeopleApi.util.updateQueryData(
                            'getMsgPeople',
                            {
                                id,
                                year,
                                month,
                                limit: +store.getState().pagination.rowsPerPage,
                                offset:
                                    (store.getState().pagination.page - 1) * +store.getState().pagination.rowsPerPage,
                                filter: store.getState().project.headerSearch,
                            },
                            (draft) => {
                                const indexChange = draft.data.findIndex((work) => {
                                    return work.id === updatedStatus.data.id;
                                });
                                draft.data.splice(indexChange, 1, updatedStatus.data);
                            }
                        )
                    );
                } catch {}
            },
        }),

        getExecutorsListMsgPeople: build.query<
            getExecutorsListMsgPeopleResI,
            { id: number; limit?: number; offset?: number }
        >({
            query: ({ id, limit, offset }) => ({
                url: `/projects/${id}/staffs/msg/executor`,
                params: { limit, offset },
                method: 'GET',
            }),
        }),

        updateExecutorPlanMsgPeople: build.mutation<updateExecutorPlanMsgPeopleResI, updateExecutorPlanMsgPeopleReqI>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/staffs/msg/executor/update-fact`,
                method: 'POST',
                body,
            }),
        }),
    }),
    overrideExisting: false,
});

export const { useInitMsgPeopleMutation, useGetMsgPeopleQuery } = msgPeopleApi;
