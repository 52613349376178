import { ColDef, ColumnState } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { req } from '@/pages/WorkManagment/api/api';

import { EPageName } from '@/shared/constants/pages';

import { DEFAULT_TEMPLATES_BY_TYPE, DEFAULT_TEMPLATE_NAME } from './ConfigureAgGridColDefTemplate.config';
import {
    IColumnTemplate,
    IConfigureTemplateListBtnProps,
    TAddDefaultTemplatesProps,
    TPatternsListType,
} from './ConfigureAgGridColDefTemplate.types';

/**
 * На основе AgGrid columnsDef собирает массив для списка доступных колонок, игнорируя колонки без colId,
 * или где colId число в строке по типу "10" и т.п.
 * @param name - имя свойства объекта
 */

function isPartialyIncluded(array: string[], searchValue: string) {
    if (array.length === 0) return false;
    return (
        array?.filter(
            (arrayItem) =>
                arrayItem?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                searchValue?.toLowerCase().includes(arrayItem?.toLowerCase())
        )?.length > 0
    );
}

export function getFilteredColumns(
    columns: Array<ColDef | ColumnState | undefined>,
    excludeColIds?: IConfigureTemplateListBtnProps['excludeColIds']
) {
    if (!columns) return [];
    return (
        columns?.filter((column) => {
            if (excludeColIds && isPartialyIncluded(excludeColIds, (column?.colId as string) || '')) return false;
            return !!column?.colId && isNaN(Number(column?.colId));
        }) || []
    );
}

export function getCurrentColDefsState(
    AgGrid: AgGridReact | null,
    excludeColIds?: IConfigureTemplateListBtnProps['excludeColIds']
) {
    // console.log(AgGrid)
    if (!AgGrid) return [];
    return getFilteredColumns((AgGrid?.api?.getColumnDefs() as ColDef[]) || [], excludeColIds);
}

export function getFilteredColumnsState(
    AgGrid: AgGridReact | null,
    excludeColIds?: IConfigureTemplateListBtnProps['excludeColIds']
) {
    if (!AgGrid) return [];
    return getFilteredColumns(AgGrid?.columnApi?.getColumnState(), excludeColIds) || [];
}

export const hasDefaultTemplates = (type: TPatternsListType, templates: IColumnTemplate[] = []) => {
    if (templates.length === 0) return false;

    const defaultTemplateNames = DEFAULT_TEMPLATES_BY_TYPE[type].map((item) => item.name);
    return defaultTemplateNames.map((v) => Boolean(templates.find((p) => p.name === v && p.isCommon))).every((v) => v);
};

export const getSortedTemplates = (templates: IColumnTemplate[] = [], type: TPatternsListType) => {
    if (!isPageWithDefaultTemplates(type as EPageName)) return templates;

    const commonTemplates = templates.filter((template) => template.isCommon);
    const restTemplates = templates.filter((template) => !template.isCommon);

    const defaultTemplateNames = DEFAULT_TEMPLATES_BY_TYPE[type]?.map((item) => item.name) ?? [];

    const orderMap: { [key: string]: number } = {};
    defaultTemplateNames.forEach((item, index) => {
        orderMap[item] = index;
    });

    commonTemplates.sort((a, b) => {
        const indexA = orderMap[a.name] !== undefined ? orderMap[a.name] : defaultTemplateNames.length;
        const indexB = orderMap[b.name] !== undefined ? orderMap[b.name] : defaultTemplateNames.length;
        return indexA - indexB;
    });

    return [...commonTemplates, ...restTemplates];
};

export const addDefaultTemplates = async ({
    type,
    projectId,
    hasActiveTemplate,
    refreshTemplates,
    enqueueSnackbar,
}: TAddDefaultTemplatesProps) => {
    try {
        const defaultTemplatesByType = DEFAULT_TEMPLATES_BY_TYPE[type];
        const defaultTemplatesData = await Promise.all(
            defaultTemplatesByType.map(async (item) => {
                const { data } = await req.post(`/projects/${projectId}/patterns/add`, item);
                return data as IColumnTemplate;
            })
        );

        if (!hasActiveTemplate) {
            const activeTemplate = defaultTemplatesData.find((v) => v?.name === DEFAULT_TEMPLATE_NAME[type]);
            await req.post(`/projects/${projectId}/patterns/${activeTemplate?.id}/set`);
        }
        refreshTemplates();
    } catch (error) {
        console.log('error', error);
        enqueueSnackbar('Не удалось добавить шаблоны по умолчанию', {
            variant: 'error',
        });
    }
};

export const isPageWithDefaultTemplates = (type: EPageName) => ['msg', 'ksg'].includes(type);

export const filterDefaultTemplate = (template: IColumnTemplate) => template.isCommon;

export const getTemplatesToUpdate = (templateList: IColumnTemplate[], type: TPatternsListType) => {
    const userDefaultTemplates = templateList.filter(filterDefaultTemplate);

    const result: IColumnTemplate[] = [];
    const defaultTemplateByType = DEFAULT_TEMPLATES_BY_TYPE[type];

    for (const template of userDefaultTemplates) {
        const defaultTemplate = defaultTemplateByType.find(
            (item) => item.name.toLowerCase() === template.name.toLowerCase()
        );

        if (!defaultTemplate) continue;
        if (defaultTemplate.version === template.version) continue;

        result.push({
            ...template,
            version: defaultTemplate.version,
            rowHeight: defaultTemplate.rowHeight,
            columns: defaultTemplate.columns,
        });
    }

    return result;
};
