import { CSSProp } from 'styled-components';

import { COLORS_FOR_FOLDER_SVG_BY_LEVEL } from './UIFolder.config';
import { FolderContainer, FolderLabel } from './UIFolder.styles';
import { FolderSVG } from './UIFolderIcon';

interface IProps {
    level: number;
    hasChildren: boolean;
    containerStyles?: CSSProp;
}

export const UIFolder: React.FC<IProps> = ({ containerStyles, level, hasChildren }) => {
    const getColors = () => {
        if (!hasChildren) return COLORS_FOR_FOLDER_SVG_BY_LEVEL[16];

        return COLORS_FOR_FOLDER_SVG_BY_LEVEL[level] ?? COLORS_FOR_FOLDER_SVG_BY_LEVEL[5];
    };

    return (
        <FolderContainer customStyles={containerStyles}>
            <FolderSVG colors={getColors()} />
            <FolderLabel colorValue={getColors().color}>{level}</FolderLabel>
        </FolderContainer>
    );
};
