import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useGetEstimatePositions } from '@/pages/EstimatePositionsTable';

import { useAckEstimatePositionsChangesMutation } from './EstimatePositionsNotice.model';
import { Button, Container, Text } from './EstimatePositionsNotice.styles';

interface IProps {}

export const EstimatePositionsNotice: FC<IProps> = () => {
    const { projectId } = useParams();

    const { data } = useGetEstimatePositions({
        projectId: Number(projectId),
    });
    const requireAttention = data?.requireAttention ?? { changed: 0, deleted: 0 };

    const count = requireAttention.changed + requireAttention.deleted;

    const { mutate: mutateAckEstimatePositionsChanges, isPending } = useAckEstimatePositionsChangesMutation();

    const handleAck = async () => {
        mutateAckEstimatePositionsChanges({
            projectId: Number(projectId),
            body: {
                positionIDs: [],
            },
        });
    };

    return (
        <Container>
            <Text>Позиции требующие внимания: {count}</Text>
            <Button
                disabled={isPending}
                onClick={handleAck}
            >
                Ознакомлен
            </Button>
        </Container>
    );
};
