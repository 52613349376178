import { CircularProgress } from '@mui/material';
import { FC, memo } from 'react';
import { useSpinDelay } from 'spin-delay';
import { match } from 'ts-pattern';

import { Dialog, LoaderContainer } from './ConsolidatedPriceDialog.styles';
import { TConsolidatedPriceDialogProps } from './ConsolidatedPriceDialog.types';
import { Form } from './components/Form';
import { Header } from './components/Header';
import { Table } from './components/Table';

export const ConsolidatedPriceDialog: FC<TConsolidatedPriceDialogProps> = memo((props) => {
    const isLoading = useSpinDelay(Boolean(props?.isLoading), { delay: 0, minDuration: 700 });

    return (
        <Dialog
            open={props.isVisible}
            onClose={props.onClose}
        >
            <Header
                isSaveButtonDisabled={props.isSaveButtonDisabled}
                mode={props.mode}
                onClose={props.onClose}
                onSubmit={props.onSubmit}
            />

            {/* @ts-ignore */}
            {match(isLoading)
                .with(true, () => (
                    <LoaderContainer>
                        <CircularProgress />
                    </LoaderContainer>
                ))
                .otherwise(() => (
                    <>
                        <Form
                            mode={props.mode}
                            changesSummary={props.changesSummary}
                            updateEditedFields={props.updateEditedFields}
                            onDelete={props.onDelete}
                            onAddPositions={props.onAddPositions}
                        />
                        <Table
                            rowData={props.rowData}
                            selectedMainPosition={props.mainPositionId}
                            positionsByUnitDifference={props.positionsByUnitDifference}
                            onSetMainPosition={props.onSetMainPosition}
                            onDeleteRow={props.onDeleteRow}
                        />
                    </>
                ))}
        </Dialog>
    );
});
