import { ICellRendererParams } from 'ag-grid-community'
import { isWithinInterval, lastDayOfMonth, parse } from 'date-fns'
import { Work } from "../../../../api/ksg/ksg.types"
import { IPlanDates, TTypeOfPlan, TTypeOfReport } from "./SpreadPlanBtn.types"

export const workPlanDatesKeys = {
    base: {
        startDate: 'startDate',
        endDate: 'endDate',
    },
    operational: {
        startDate: 'operationalStartDate',
        endDate: 'operationalEndDate',
    },
} as IPlanDates

export function areDatesInsideMonthlyChartsTimeline(
    cellRendererParams: ICellRendererParams<Work, any, any>,
    typeOfPlan: TTypeOfPlan,
    typeOfReport: TTypeOfReport
) {
    if (typeOfReport === 'msg') return true
    if (cellRendererParams.data?.level === 0) return true

    const { data: workData, node, api: gridApi } = cellRendererParams

    let isStartDateInsideTimeline = false
    let isEndDateInsideTimeline = false
    const timelineStartMonth = workData?.monthlyCharts[0]?.month
    const timelineStartYear = workData?.monthlyCharts[0]?.year
    const timelineStartDate = parse(timelineStartMonth + '.' + timelineStartYear, 'MM.yyyy', new Date())
    const timelineEndMonth = workData?.monthlyCharts[workData.monthlyCharts.length - 1]?.month
    const timelineEndYear = workData?.monthlyCharts[workData.monthlyCharts.length - 1]?.year
    const timelineEndDate = lastDayOfMonth(parse(timelineEndMonth + '.' + timelineEndYear, 'MM.yyyy', new Date()))
    if (workPlanDatesKeys[typeOfPlan].startDate) {
        const startDate = parse(
            gridApi.getValue(workPlanDatesKeys[typeOfPlan].startDate, node),
            'dd.MM.yyyy',
            new Date()
        )
        isStartDateInsideTimeline = isWithinInterval(startDate, {
            start: timelineStartDate,
            end: timelineEndDate,
        })
    }
    if (workPlanDatesKeys[typeOfPlan].endDate) {
        const endDate = parse(gridApi.getValue(workPlanDatesKeys[typeOfPlan].endDate, node), 'dd.MM.yyyy', new Date())
        isEndDateInsideTimeline = isWithinInterval(endDate, {
            start: timelineStartDate,
            end: timelineEndDate,
        })
    }
    return isStartDateInsideTimeline && isEndDateInsideTimeline
}

export function getTooltipDescriptionMsgIfBtnDisabled(
    cellRendererParams: ICellRendererParams<Work, any, any>,
    typeOfPlan: TTypeOfPlan,
    typeOfReport: TTypeOfReport
) {
    const { data: workData } = cellRendererParams

    if (!workData) {
        return ''
    }
    if (
        !!workData[workPlanDatesKeys[typeOfPlan].startDate] === false &&
        !!workData[workPlanDatesKeys[typeOfPlan].endDate] === false
    ) {
        return 'Не указаны даты'
    }
    if (!!workData[workPlanDatesKeys[typeOfPlan].startDate] === false) {
        return 'Не указана дата начала'
    }
    if (!!workData[workPlanDatesKeys[typeOfPlan].endDate] === false) {
        return 'Не указана дата окончания'
    }
    // if (typeOfReport === 'msg') {
    //     return ''
    // }
    if (
        typeOfReport !== 'msg' &&
        workData &&
        !areDatesInsideMonthlyChartsTimeline(cellRendererParams, typeOfPlan, typeOfReport)
    ) {
        return 'Одна из дат вышла за пределы доступного периода'
    }
    return ''
}
