import { ColDef, ColGroupDef, GridApi } from 'ag-grid-community';

import { isColGroupDef } from '@/shared/guards/common.guards';

export const extractColIds = (colDef: ColGroupDef | ColDef, acc: string[] = []) => {
    if (isColGroupDef(colDef)) {
        colDef.children.forEach((v) => extractColIds(v, acc));
        return acc;
    }

    colDef?.colId && acc.push(colDef.colId);
    return acc;
};

export const redrawAllRows = (gridApi: GridApi) => {
    gridApi.forEachNodeAfterFilter((row) => {
        gridApi.redrawRows({ rowNodes: [row] });
    });
};

export const redrawRow = (gridApi: GridApi | undefined, id: string) => {
    if (!gridApi) {
        throw new Error('api not found');
    }

    const row = gridApi.getRowNode(id);

    if (!row) {
        throw new Error('row not found');
    }

    gridApi.redrawRows({ rowNodes: [row] });
};

export const refreshCell = (gridApi: GridApi | undefined, rowId: string | null, column: string) => {
    if (!gridApi) {
        throw new Error('api not found');
    }

    const rowNodes = [];

    if (rowId) {
        const rowNode = gridApi.getRowNode(rowId);

        if (rowNode) {
            rowNodes.push(rowNode);
        }
    }

    gridApi.refreshCells({ rowNodes, columns: [column], force: true });
};

export const getRowData = <T extends Record<string, any>>(gridApi: GridApi | undefined) => {
    if (!gridApi) {
        throw new Error('api not found');
    }

    const rowData: T[] = [];
    gridApi.forEachNode(function (node) {
        rowData.push(node.data);
    });

    return rowData;
};

export const getAgGridCellElement = (rowIndex: number, columnId: string) => {
    return document.querySelector(`.ag-center-cols-container [row-index="${rowIndex}"] [col-id="${columnId}"]`);
};

export const getRowElementByIndex = (rowIndex: number) => {
    const gridBody = document.querySelector('.ag-body-viewport') as HTMLElement;
    if (!gridBody) return null;
    const rowElement = gridBody.querySelectorAll(`[row-index="${rowIndex}"]`)[0];
    if (!rowElement) return null;
    return rowElement;
};
