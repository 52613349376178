import { FC } from 'react';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';

import { TCellRendererProps } from './TableCells.types';

export const TextCellRenderer: FC<TCellRendererProps> = ({ value }) => {
    return (
        <OverflowableTypographyWithTooltip
            maxRows={1}
            TypographyProps={{
                sx: {
                    background: 'transparent !important',
                },
            }}
        >
            {value}
        </OverflowableTypographyWithTooltip>
    );
};
