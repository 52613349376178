import NiceModal from '@ebay/nice-modal-react';
import { GetRowIdParams, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { TConsolidatedPriceId, TConsolidatedPricesFilterKeys } from '@/api/consolidatedPrices';

import { useAgGridLastRowInView } from '@/hooks/useAgGridLastRowInView';

import { consolidatedPricesActions, consolidatedPricesSelectors } from '@/store/slices/consolidatedPricesSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { columnsDef } from './ConsolidatedPricesTable.columnDefs';
import { defaultColDef } from './ConsolidatedPricesTable.config';
import { useGetConsolidatedPrices } from './ConsolidatedPricesTable.model';
import { TConsolidatedPricesTableProps, TContext, TData } from './ConsolidatedPricesTable.types';
import { EditDialog } from './components/EditDialog';
import { EstimatePositionsTable } from './components/EstimatePositionsTable';
import { FilterDialog } from './components/FilterDialog';
import { Table } from './components/Table';

export const ConsolidatedPricesTable: React.FC<TConsolidatedPricesTableProps> = (props) => {
    const editDialogMode = useTypedSelector(consolidatedPricesSelectors.editDialogMode);
    const dispatch = useAppDispatch();
    const { projectId } = useParams();

    useEffect(() => {
        return () => {
            dispatch(consolidatedPricesActions.reset());
        };
    }, [projectId]);

    if (editDialogMode === 'addPositions') {
        return <EstimatePositionsTable />;
    }

    return <MainTable {...props} />;
};

const MainTable: React.FC<TConsolidatedPricesTableProps> = () => {
    const gridRef = useRef<AgGridReact<TData>>(null);
    const { projectId } = useParams();
    const filters = useTypedSelector(consolidatedPricesSelectors.filters);
    const dispatch = useAppDispatch();

    const { inView: isLastRowInView, reset: resetInView } = useAgGridLastRowInView(gridRef.current?.api);

    const { data, isLoading, fetchNextPage } = useGetConsolidatedPrices({
        projectId: Number(projectId),
    });

    useEffect(() => {
        if (!isLastRowInView) return;

        fetchNextPage().finally(resetInView);
    }, [isLastRowInView]);

    const rowData = useMemo(() => data?.consolidated ?? [], [data]);

    const getRowId = useCallback(({ data }: GetRowIdParams<TData>) => {
        return data?.id.toString();
    }, []);

    const handleFilterVisible = useCallback(
        (key: TConsolidatedPricesFilterKeys) => () => {
            NiceModal.show(FilterDialog, {
                filterKey: key,
                projectId: Number(projectId),
            });
        },
        []
    );

    const handleRowClick = useCallback((event: RowClickedEvent<TData>) => {
        const consolidatedId = event.data!.id as TConsolidatedPriceId;

        dispatch(consolidatedPricesActions.setEditConsolidatedPriceId(consolidatedId));
        dispatch(consolidatedPricesActions.setEditDialogMode('edit'));
        NiceModal.show(EditDialog, {
            projectId: Number(projectId),
            consolidatedPriceId: consolidatedId,
        });
    }, []);

    const context: TContext = useMemo(() => {
        return {
            filters,
            onFilterVisible: handleFilterVisible,
        };
    }, [filters]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        gridRef?.current?.api?.refreshHeader();
    }, [filters]);

    return (
        <Table
            isLoading={isLoading}
            getRowId={getRowId}
            gridRef={gridRef}
            rowData={rowData}
            context={context}
            columnDefs={columnsDef}
            defaultColDef={defaultColDef}
            headerHeight={40}
            rowHeight={64}
            onRowClicked={handleRowClick}
            overlayNoRowsTemplate='Нет данных'
        />
    );
};
