import { profileSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

import UserCardContent from '../UserCardContent/UserCardContent';
import { UserCardContentData } from '../UserCardContent/UserCardContent.types';
import UserCardHeader from '../UserCardHeader/UserCardHeader';
import { UserCardHeaderData } from '../UserCardHeader/UserCardHeader.types';
import Divider from '../components/Divider';
import { UserCardProps } from './UserCard.types';
import { StyledCard } from './styles';

function UserCard({ data, onClick }: UserCardProps) {
    const { id, firstName, middleName, lastName, avatarPreview, company, email, phone, role, access } = data;

    const { profile } = useTypedSelector(profileSelector);

    const headerData: UserCardHeaderData = {
        firstName,
        middleName,
        lastName,
        avatarPreview,
        email,
        phone,
        role,
    };
    const contentData: UserCardContentData = { access, company };

    return (
        <StyledCard
            onClick={() => onClick(id)}
            textcolor='dark'
            profile={profile}
        >
            <UserCardHeader data={headerData} />
            <Divider />
            <UserCardContent data={contentData} />
        </StyledCard>
    );
}

export default UserCard;
