import { PublicUserProfile, TVerFactPermTerms } from '@/api/users/users.types';

import { UserRolesEn } from '@/pages/Users/user';

import { EPageName } from '@/shared/constants/pages';

const DEFAULT_FEATURE_ROLES: UserRolesEn[] = ['admin', 'scheduler'];
const DEFAULT_PAGE_ACCESS_ROLES: UserRolesEn[] = ['admin', 'client', 'scheduler', 'controller'];
export type TFeatureName =
    | 'CREATE_PROJECT'
    | 'EDIT_PROJECT'
    | 'DELETE_PROJECT'
    | 'VIEW_BRIGADES'
    | 'CREATE_BRIGADE'
    | 'EDIT_BRIGADE'
    | 'DELETE_BRIGADE'
    | 'SEND_SNZ_AUTO'
    | 'SEND_SNZ_MANUALLY'
    | 'SEND_EMAIL_ON_MSG_FACT_DIFFERENCE'
    | 'NOTIFICATION_CENTER'
    | 'CREATE_KSG_SMR'
    | 'CREATE_WORK_KSG'
    | 'EDIT_WORK_KSG'
    | 'DELETE_WORK_KSG'
    | 'EDIT_OPER_PLAN_KSG_SMR'
    | 'EDIT_FACT_KSG_SMR'
    | 'EDIT_BASE_PLAN_KSG_SMR'
    | 'EDIT_VER_FACT_KSG_SMR'
    | 'SYNC_SETTINGS'
    | 'SAVE_BASE_PLAN'
    | 'EIS_REPORT'
    | 'DOWNLOAD_XLS'
    | 'UPDATE_KSG'
    | 'VIEW_GANT_SMR'
    | 'CREATE_LINK_GANT_SMR'
    | 'CREATE_LINE_GANT_SMR'
    | 'KSG_GANTT_LINK_ADD'
    | 'KSG_GANTT_LINK_DELETE'
    | 'KSG_GANTT_TASK_DRAG'
    | 'KSG_GANTT_TASK_OPEN'
    | 'CREATE_MSG_SMR'
    | 'PRINT_MSG_SMR'
    | 'DOWNLOAD_MSG_SMR'
    | 'VIEW_GANT_BRIGADES'
    | 'CREATE_LINK_GANT_BRIGADES'
    | 'EDIT_WORK_MSG'
    | 'EDIT_OPER_PLAN_MSG_SMR'
    | 'EDIT_FACT_MSG_SMR'
    | 'EDIT_FORECAST_MSG_SMR'
    | 'EDIT_VER_FACT_MSG_SMR'
    | 'CREATE_KSG_STAFF'
    | 'CREATE_KSG_STAFF_ROW'
    | 'EDIT_KSG_STAFF_ROW'
    | 'DELETE_KSG_STAFF_ROW'
    | 'EDIT_PLAN_KSG_STAFF'
    | 'EDIT_FACT_KSG_STAFF'
    | 'EDIT_VER_FACT_KSG_STAFF'
    | 'CREATE_MSG_STAFF'
    | 'EDIT_PLAN_MSG_STAFF'
    | 'EDIT_FACT_MSG_STAFF'
    | 'EDIT_FORECAST_MSG_STAFF'
    | 'EDIT_VER_FACT_MSG_STAFF'
    | 'CREATE_KSG_MIM'
    | 'CREATE_KSG_MIM_ROW'
    | 'EDIT_KSG_MIM_ROW'
    | 'DELETE_KSG_MIM_ROW'
    | 'EDIT_PLAN_KSG_MIM'
    | 'EDIT_FACT_KSG_MIM'
    | 'EDIT_VER_FACT_KSG_MIM'
    | 'CREATE_MSG_MIM'
    | 'EDIT_PLAN_MSG_MIM'
    | 'EDIT_FACT_MSG_MIM'
    | 'EDIT_FORECAST_MSG_MIM'
    | 'EDIT_VER_FACT_MSG_MIM'
    | 'VIEW_USERS'
    | 'CREATE_USERS'
    | 'EDIT_USERS'
    | 'DELETE_USERS'
    | 'VIEW_WORK_MANAGEMENT'
    | 'EDIT_WORK_MANAGMENT'
    | 'SPREAD_OPER_PLAN'
    | 'SPREAD_BASE_PLAN'
    | 'PROJECT_BACKUPS'
    | 'DATA_IMPORT_FROM_EXTERNAL_MODULES';

function findAllByKey(obj: Object, keyToFind: string): any {
    return Object.entries(obj).reduce(
        (acc, [key, value]) =>
            key === keyToFind
                ? acc.concat(value)
                : typeof value === 'object'
                ? acc.concat(findAllByKey(value, keyToFind))
                : acc,
        []
    );
}

export function DOES_ROLE_HAS_ACCESS_TO_FEATURE(role: UserRolesEn | undefined | null, featureName: TFeatureName) {
    if (!role) return false;
    return findAllByKey(FEATURE_ACCESS_SETTINGS, featureName)?.includes(role);
}
export function DOES_ROLE_HAS_ACCESS_TO_PAGE(role: UserRolesEn, pageName: EPageName) {
    return findAllByKey(PAGE_ACCESS_SETTINGS, pageName)?.includes(role);
}
export function GET_FEATURE_PERMITTED_ROLES(featureName: TFeatureName): UserRolesEn[] {
    return findAllByKey(FEATURE_ACCESS_SETTINGS, featureName);
}

export function DOES_ROLE_HAS_ACCESS_TO_PROJECT_TABLE(
    projectID: string | number,
    profile: PublicUserProfile,
    tableType: 'smr' | 'staff' | 'mim'
) {
    let targetPermission = 'canEditCMP' as TVerFactPermTerms;
    let doesRoleHasAccess = false;
    switch (tableType) {
        case 'smr':
            targetPermission = 'canEditCMP';
            break;
        case 'staff':
            targetPermission = 'canEditStaff';
            break;
        case 'mim':
            targetPermission = 'canEditMim';
            break;
    }

    switch (profile.role) {
        case 'controller':
            doesRoleHasAccess = !!profile?.access?.projects?.find((project) => project.id === Number(projectID))
                ?.verFactPerms[targetPermission];
            break;
        default:
            doesRoleHasAccess = !!profile?.access?.projects?.find((project) => project.id === Number(projectID));
    }

    return !!(profile?.access?.allProjects || doesRoleHasAccess);
}

const FEATURE_ACCESS_SETTINGS: Record<TFeatureName, UserRolesEn[]> = {
    // PROJECT: {
    CREATE_PROJECT: ['admin'], // +
    EDIT_PROJECT: ['admin'], // +
    DELETE_PROJECT: ['admin'], // +
    PROJECT_BACKUPS: ['admin'],
    DATA_IMPORT_FROM_EXTERNAL_MODULES: ['admin'],
    // },
    // BRIGADES: {
    VIEW_BRIGADES: DEFAULT_FEATURE_ROLES, // +
    CREATE_BRIGADE: DEFAULT_FEATURE_ROLES, // +
    EDIT_BRIGADE: DEFAULT_FEATURE_ROLES, // +
    DELETE_BRIGADE: DEFAULT_FEATURE_ROLES, // +
    // },
    // COMMON: {
    SEND_SNZ_AUTO: DEFAULT_FEATURE_ROLES,
    SEND_SNZ_MANUALLY: DEFAULT_FEATURE_ROLES,
    NOTIFICATION_CENTER: DEFAULT_FEATURE_ROLES,
    // },
    // KSG_SMR: {
    CREATE_KSG_SMR: DEFAULT_FEATURE_ROLES,
    CREATE_WORK_KSG: DEFAULT_FEATURE_ROLES,
    EDIT_WORK_KSG: DEFAULT_FEATURE_ROLES,
    DELETE_WORK_KSG: DEFAULT_FEATURE_ROLES,
    // CHARTS: {
    EDIT_OPER_PLAN_KSG_SMR: DEFAULT_FEATURE_ROLES,
    EDIT_FACT_KSG_SMR: DEFAULT_FEATURE_ROLES,
    EDIT_BASE_PLAN_KSG_SMR: DEFAULT_FEATURE_ROLES,
    EDIT_VER_FACT_KSG_SMR: ['admin', 'controller'],
    // },
    // COMMON: {
    SYNC_SETTINGS: DEFAULT_FEATURE_ROLES,
    SAVE_BASE_PLAN: DEFAULT_FEATURE_ROLES,
    EIS_REPORT: DEFAULT_FEATURE_ROLES,
    DOWNLOAD_XLS: ['admin', 'client', 'scheduler', 'controller'],
    UPDATE_KSG: DEFAULT_FEATURE_ROLES,
    // },
    // GANT: {
    VIEW_GANT_SMR: ['admin', 'client', 'scheduler', 'controller'],
    CREATE_LINK_GANT_SMR: DEFAULT_FEATURE_ROLES,
    CREATE_LINE_GANT_SMR: DEFAULT_FEATURE_ROLES,

    KSG_GANTT_LINK_ADD: DEFAULT_FEATURE_ROLES,
    KSG_GANTT_LINK_DELETE: DEFAULT_FEATURE_ROLES,
    KSG_GANTT_TASK_DRAG: DEFAULT_FEATURE_ROLES,
    KSG_GANTT_TASK_OPEN: DEFAULT_FEATURE_ROLES,

    // },
    // SPREAD_PLAN: {
    SPREAD_OPER_PLAN: DEFAULT_FEATURE_ROLES,
    SPREAD_BASE_PLAN: DEFAULT_FEATURE_ROLES,
    // },
    // },
    // MSG_SMR: {
    CREATE_MSG_SMR: DEFAULT_FEATURE_ROLES,
    PRINT_MSG_SMR: ['admin', 'client', 'scheduler', 'controller'],
    DOWNLOAD_MSG_SMR: ['admin', 'client', 'scheduler', 'controller'],
    // GANT_BRIGADES: {
    VIEW_GANT_BRIGADES: ['admin', 'client', 'scheduler', 'controller'],
    CREATE_LINK_GANT_BRIGADES: DEFAULT_FEATURE_ROLES,
    // },
    EDIT_WORK_MSG: DEFAULT_FEATURE_ROLES,
    // CHARTS: {
    EDIT_OPER_PLAN_MSG_SMR: DEFAULT_FEATURE_ROLES,
    EDIT_FACT_MSG_SMR: DEFAULT_FEATURE_ROLES,
    EDIT_FORECAST_MSG_SMR: DEFAULT_FEATURE_ROLES,
    EDIT_VER_FACT_MSG_SMR: ['admin', 'controller'],
    SEND_EMAIL_ON_MSG_FACT_DIFFERENCE: ['admin', 'controller'],
    // },
    // },
    // KSG_STAFF: {
    CREATE_KSG_STAFF: DEFAULT_FEATURE_ROLES,
    CREATE_KSG_STAFF_ROW: DEFAULT_FEATURE_ROLES,
    EDIT_KSG_STAFF_ROW: DEFAULT_FEATURE_ROLES,
    DELETE_KSG_STAFF_ROW: DEFAULT_FEATURE_ROLES,
    // CHARTS: {
    EDIT_PLAN_KSG_STAFF: DEFAULT_FEATURE_ROLES,
    EDIT_FACT_KSG_STAFF: DEFAULT_FEATURE_ROLES,
    EDIT_VER_FACT_KSG_STAFF: ['admin', 'controller'],
    // },
    // },
    // MSG_STAFF: {
    CREATE_MSG_STAFF: DEFAULT_FEATURE_ROLES,
    // CHARTS: {
    EDIT_PLAN_MSG_STAFF: DEFAULT_FEATURE_ROLES,
    EDIT_FACT_MSG_STAFF: DEFAULT_FEATURE_ROLES,
    EDIT_FORECAST_MSG_STAFF: DEFAULT_FEATURE_ROLES,
    EDIT_VER_FACT_MSG_STAFF: ['admin', 'controller'],
    // },
    // },
    // KSG_MIM: {
    CREATE_KSG_MIM: DEFAULT_FEATURE_ROLES,
    CREATE_KSG_MIM_ROW: DEFAULT_FEATURE_ROLES,
    EDIT_KSG_MIM_ROW: DEFAULT_FEATURE_ROLES,
    DELETE_KSG_MIM_ROW: DEFAULT_FEATURE_ROLES,
    // CHARTS: {
    EDIT_PLAN_KSG_MIM: DEFAULT_FEATURE_ROLES,
    EDIT_FACT_KSG_MIM: DEFAULT_FEATURE_ROLES,
    EDIT_VER_FACT_KSG_MIM: ['admin', 'controller'],
    //     },
    // },
    // MSG_MIM: {
    CREATE_MSG_MIM: DEFAULT_FEATURE_ROLES,
    //     CHARTS: {
    EDIT_PLAN_MSG_MIM: DEFAULT_FEATURE_ROLES,
    EDIT_FACT_MSG_MIM: DEFAULT_FEATURE_ROLES,
    EDIT_FORECAST_MSG_MIM: DEFAULT_FEATURE_ROLES,
    EDIT_VER_FACT_MSG_MIM: ['admin', 'controller'],
    // },
    // },
    // USERS: {
    VIEW_USERS: ['admin', 'client', 'scheduler', 'controller'],
    CREATE_USERS: ['admin'],
    EDIT_USERS: ['admin'],
    DELETE_USERS: ['admin'],
    // },
    // WORK_MANAGEMENT: {
    VIEW_WORK_MANAGEMENT: ['admin', 'client', 'scheduler', 'controller'],
    EDIT_WORK_MANAGMENT: DEFAULT_FEATURE_ROLES,
    // },
};

const PAGE_ACCESS_SETTINGS: Record<EPageName, UserRolesEn[]> = {
    [EPageName.Projects]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.ProjectsCharts]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.Users]: GET_FEATURE_PERMITTED_ROLES('VIEW_USERS'),
    [EPageName.UsersAdd]: GET_FEATURE_PERMITTED_ROLES('CREATE_USERS'),
    [EPageName.UsersEdit]: GET_FEATURE_PERMITTED_ROLES('EDIT_USERS'),
    [EPageName.Ksg]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.Mgp]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.Mofen]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.Sm]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.KsgPeople]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.KsgEquip]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.KsgKc6a]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.Msg]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.MsgPeople]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.MsgEquip]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.Executor]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.ExecutorDetailsMim]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.ExecutorDetailsStaff]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.ExecutorPeople]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.ExecutorEquip]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.ag_grid]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.ag_grid_msg]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.Mto]: DEFAULT_PAGE_ACCESS_ROLES,
    [EPageName.WorkManagement]: GET_FEATURE_PERMITTED_ROLES('VIEW_WORK_MANAGEMENT'),
    [EPageName.Brigades]: GET_FEATURE_PERMITTED_ROLES('VIEW_GANT_BRIGADES'),
    [EPageName.BrigadesAdd]: GET_FEATURE_PERMITTED_ROLES('VIEW_GANT_BRIGADES'),
    [EPageName.BrigadesEdit]: GET_FEATURE_PERMITTED_ROLES('VIEW_GANT_BRIGADES'),
    [EPageName.MsgPrint]: GET_FEATURE_PERMITTED_ROLES('PRINT_MSG_SMR'),
    [EPageName.secretGantt]: ['admin'],
};

export const PERMISSIONS = {
    ksg: {
        smr: {
            plan: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_OPER_PLAN_KSG_SMR'),
                cpgViews: ['blank'],
            },
            basePlan: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_BASE_PLAN_KSG_SMR'),
                cpgViews: ['blank'],
            },
            fact: {
                roles: undefined,
                cpgViews: undefined,
            },
            verifiedFact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_VER_FACT_KSG_SMR'),
                cpgViews: ['blank'],
            },
        },
        staff: {
            plan: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_PLAN_KSG_STAFF'),
            },
            fact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_FACT_KSG_STAFF'),
            },
            verifiedFact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_VER_FACT_KSG_STAFF'),
            },
        },
        mim: {
            plan: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_PLAN_KSG_MIM'),
            },
            fact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_FACT_KSG_MIM'),
            },
            verifiedFact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_VER_FACT_KSG_MIM'),
            },
        },
    },
    msg: {
        smr: {
            plan: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_OPER_PLAN_MSG_SMR'),
            },
            fact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_FACT_MSG_SMR'),
            },
            forecast: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_FORECAST_MSG_SMR'),
            },
            verifiedFact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_VER_FACT_MSG_SMR'),
            },
        },
        staff: {
            plan: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_PLAN_MSG_STAFF'),
            },
            fact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_FACT_MSG_STAFF'),
            },
            forecast: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_FORECAST_MSG_STAFF'),
            },
            verifiedFact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_VER_FACT_MSG_STAFF'),
            },
        },
        mim: {
            plan: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_PLAN_MSG_MIM'),
            },
            fact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_FACT_MSG_MIM'),
            },
            forecast: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_FORECAST_MSG_MIM'),
            },
            verifiedFact: {
                roles: GET_FEATURE_PERMITTED_ROLES('EDIT_VER_FACT_MSG_MIM'),
            },
        },
    },
} as any;
