import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { FC } from 'react';

import { UIAgGridContainerSecondary } from '@/components/ui/UIAgGridContainerSecondary';

import { useAgGridLoader } from '@/hooks/useAgGridLoader';

import { AgGrid } from './Table.styles';

interface IProps extends AgGridReactProps {
    gridRef: React.RefObject<AgGridReact>;
    isLoading: boolean;
}

export const Table: FC<IProps> = ({ gridRef, isLoading, ...props }) => {
    const { ref, LoadingComponent } = useAgGridLoader(isLoading);

    return (
        <>
            {LoadingComponent}

            <UIAgGridContainerSecondary ref={ref}>
                <AgGrid
                    ref={gridRef}
                    {...props}
                />
            </UIAgGridContainerSecondary>
        </>
    );
};
