import styled from 'styled-components';

import { ResetButton } from '@/components/ui/UIButton';

import { TContainerProps } from './DropZone.types';

export const Container = styled(ResetButton)<TContainerProps>`
    position: absolute;
    top: ${({ $top }) => $top ?? 0}px;
    left: ${({ $left }) => $left ?? 0}px;
    width: 42px;
    height: 38px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    background-color: ${({ $isHovered }) => ($isHovered ? '#8DD4C8' : '#fff')};
    color: ${({ $isHovered }) => ($isHovered ? '#fff' : '#0044b4')};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
`;
