import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useParams } from 'react-router-dom';

import { useGetFilterNameValuesWorksMtoMutation } from '@/api/works/works.api';

import { filtersSomSelector, setTextFiltersSom } from '@/store/slices/filtersSomSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import s from '../CheckboxPopoverSufficiency.module.scss';

export default function FilterNameEl() {
    const [inputValue, setInputValue] = useState('');

    const { titlesArr } = useTypedSelector(filtersSomSelector);

    const { projectId, workId } = useParams();
    const dispatch = useAppDispatch();

    const [getFiltersReq, getFiltersRes] = useGetFilterNameValuesWorksMtoMutation();

    useEffect(() => {
        getFiltersReq({
            projectId,
            workId,
        })
            .unwrap()
            .catch((e) => console.error(e));
    }, [getFiltersReq, projectId, workId]);

    const filteredCheckboxesList = useMemo(() => {
        return getFiltersRes?.data?.data.filter((item) => item.toLowerCase().includes(inputValue.toLowerCase()));
    }, [getFiltersRes?.data?.data, inputValue]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>, arrItem: string) => {
        dispatch(setTextFiltersSom({ title: arrItem, checked: event.target.checked }));
    };

    return (
        <div className={s.name_filter_wrapper}>
            <div className={s.input}>
                <DebounceInput
                    value={inputValue}
                    placeholder='Поиск'
                    debounceTimeout={300}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <span>
                    <SearchOutlinedIcon
                        sx={{
                            color: '#7890B2',
                        }}
                    />
                </span>
            </div>
            <div className={s.checkboxes_peq}>
                <FormGroup>
                    {filteredCheckboxesList?.map((checkbox, index) => (
                        <FormControlLabel
                            control={<Checkbox />}
                            label={checkbox}
                            key={`${checkbox}${index}`}
                            sx={{
                                '.MuiTypography-root': {
                                    color: '#0044B4',
                                    fontWeight: 500,
                                    inlineSize: 260,
                                    overflowWrap: ' break-word',
                                },
                                '.MuiSvgIcon-root': {
                                    fill: '#0044B4',
                                },
                            }}
                            checked={titlesArr.includes(checkbox)}
                            onChange={(e) => handleChange(e as ChangeEvent<HTMLInputElement>, checkbox)}
                        />
                    ))}
                </FormGroup>
            </div>
        </div>
    );
}
