import { CircularProgress, Stack } from '@mui/material';
import React, { useEffect } from 'react';

import { Nullable } from '@/types/common.types';

import { Button, Marker, RedText, Separator, Text } from './OperationalDateNotificator.styles';

const UpArrowIcon = () => (
    <svg
        width='17'
        height='18'
        viewBox='0 0 17 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M9.60165 1.16917C9.46475 1.01134 9.29554 0.884766 9.10547 0.79802C8.9154 0.711274 8.70891 0.666382 8.49998 0.666382C8.29106 0.666382 8.08457 0.711274 7.8945 0.79802C7.70443 0.884766 7.53521 1.01134 7.39832 1.16917L0.527484 9.08917C-0.292516 10.0333 0.378317 11.5033 1.62832 11.5033H4.33332V13.375C4.33332 14.18 4.98665 14.8333 5.79165 14.8333H11.2083C11.5951 14.8333 11.966 14.6797 12.2395 14.4062C12.513 14.1327 12.6667 13.7618 12.6667 13.375V11.5033H15.3708C16.6208 11.5033 17.2916 10.0333 16.4725 9.08917L9.60165 1.16917ZM4.95832 16.0833C4.79256 16.0833 4.63359 16.1492 4.51638 16.2664C4.39917 16.3836 4.33332 16.5426 4.33332 16.7083C4.33332 16.8741 4.39917 17.0331 4.51638 17.1503C4.63359 17.2675 4.79256 17.3333 4.95832 17.3333H12.0417C12.2074 17.3333 12.3664 17.2675 12.4836 17.1503C12.6008 17.0331 12.6667 16.8741 12.6667 16.7083C12.6667 16.5426 12.6008 16.3836 12.4836 16.2664C12.3664 16.1492 12.2074 16.0833 12.0417 16.0833H4.95832Z'
            fill='currentColor'
        />
    </svg>
);

const CalendarIcon = () => (
    <svg
        width='18'
        height='20'
        viewBox='0 0 18 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M10.5 10.8333H11.75V13.1833L13.7833 14.3583L13.1583 15.4417L10.5 13.9083V10.8333ZM13.8333 6.66666H2.16667V15.8333H6.05833C5.7 15.075 5.5 14.225 5.5 13.3333C5.5 11.7862 6.11458 10.3025 7.20854 9.20854C8.30251 8.11458 9.78624 7.5 11.3333 7.5C12.225 7.5 13.075 7.7 13.8333 8.05833V6.66666ZM2.16667 17.5C1.72464 17.5 1.30072 17.3244 0.988155 17.0118C0.675595 16.6993 0.5 16.2754 0.5 15.8333V4.16666C0.5 3.24166 1.24167 2.49999 2.16667 2.49999H3V0.833328H4.66667V2.49999H11.3333V0.833328H13V2.49999H13.8333C14.2754 2.49999 14.6993 2.67559 15.0118 2.98815C15.3244 3.30071 15.5 3.72463 15.5 4.16666V9.25C16.5333 10.3 17.1667 11.7417 17.1667 13.3333C17.1667 14.8804 16.5521 16.3642 15.4581 17.4581C14.3642 18.5521 12.8804 19.1667 11.3333 19.1667C9.74167 19.1667 8.3 18.5333 7.25 17.5H2.16667ZM11.3333 9.29166C10.2614 9.29166 9.2334 9.71748 8.47544 10.4754C7.71748 11.2334 7.29167 12.2614 7.29167 13.3333C7.29167 15.5667 9.1 17.375 11.3333 17.375C11.8641 17.375 12.3897 17.2705 12.88 17.0673C13.3704 16.8642 13.8159 16.5665 14.1912 16.1912C14.5665 15.8159 14.8642 15.3704 15.0673 14.88C15.2705 14.3896 15.375 13.8641 15.375 13.3333C15.375 11.1 13.5667 9.29166 11.3333 9.29166Z'
            fill='currentColor'
        />
    </svg>
);

interface ITooltipContentProps {
    days: number;
    isPeriodAlert: boolean;
    isOverdueAlert: Nullable<boolean>;
    isAvailableIncreaseVolume?: boolean;
    isAvailableIncreasePeriod?: boolean;
    isIncreasePeriodDisabled: boolean;
    isIncreaseVolumeDisabled: boolean;
    isOverdueAlertLoading: boolean;
    onVolumeIncrease: () => void;
    onPeriodIncrease: () => void;
    fetchNotification: () => void;
}

export const TooltipContent: React.FC<ITooltipContentProps> = ({
    days,
    isPeriodAlert,
    isOverdueAlert,
    isAvailableIncreasePeriod,
    isAvailableIncreaseVolume,
    isIncreaseVolumeDisabled,
    isIncreasePeriodDisabled,
    isOverdueAlertLoading,
    onVolumeIncrease,
    onPeriodIncrease,
    fetchNotification,
}) => {
    const isSeparatorVisible = isPeriodAlert && isOverdueAlert;

    useEffect(() => {
        fetchNotification();
    }, []);

    const renderOverdueAlert = () => {
        if (isOverdueAlertLoading) {
            return (
                <Stack
                    width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    minHeight={'100px'}
                    minWidth={'300px'}
                >
                    <CircularProgress size={'1.5rem'} />
                </Stack>
            );
        }

        return (
            <Stack gap={2}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                >
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                    >
                        <Marker>•</Marker>
                        <Text>Найдено отставание по задаче:</Text>
                    </Stack>
                    <RedText>+{days} дн</RedText>
                </Stack>
                <Stack gap={1}>
                    {isAvailableIncreaseVolume && (
                        <Button
                            variant='contained'
                            startIcon={<UpArrowIcon />}
                            disabled={isIncreaseVolumeDisabled}
                            onClick={onVolumeIncrease}
                        >
                            Увеличить выработку
                        </Button>
                    )}
                    {isAvailableIncreasePeriod && (
                        <Button
                            variant='contained'
                            startIcon={<CalendarIcon />}
                            disabled={isIncreasePeriodDisabled}
                            onClick={onPeriodIncrease}
                        >
                            Увеличить срок
                        </Button>
                    )}
                </Stack>
            </Stack>
        );
    };

    return (
        <Stack sx={{ flex: 1, gap: '10px' }}>
            {isPeriodAlert && (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                >
                    <Marker>•</Marker>
                    <Text>Даты не совпадают с чартами КСГ</Text>
                </Stack>
            )}

            {isSeparatorVisible && <Separator />}

            {isOverdueAlert && renderOverdueAlert()}
        </Stack>
    );
};
