import { FC } from 'react';

import { ChangedCount, DeletedCount } from '@/components/ConsolidatedPriceDialog';
import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { UIHeaderFilter } from '@/components/ui/UIHeaderFilter';

import { ChangesSummaryContainer } from './TableCell.styles';
import { TCellRendererProps, THeaderFilterProps } from './TableCell.types';

export const TextCellRenderer: FC<TCellRendererProps> = ({ value }) => {
    return (
        <OverflowableTypographyWithTooltip
            maxRows={2}
            TypographyProps={{
                sx: {
                    background: 'transparent !important',
                },
            }}
        >
            {value}
        </OverflowableTypographyWithTooltip>
    );
};

export const HeaderFilter: FC<THeaderFilterProps> = ({
    isApplied,
    column,
    displayName,
    textAlign = 'left',
    onClick,
}) => {
    return (
        <UIHeaderFilter
            width={column.getActualWidth()}
            name={displayName}
            isApplied={isApplied}
            onClick={onClick}
            textProps={{
                sx: {
                    textAlign: `${textAlign} !important`,
                },
            }}
            iconColor='#9aa2b0'
            appliedIconColor='#8edd92'
        />
    );
};

export const ChangesSummaryCellRenderer: FC<TCellRendererProps> = ({ data }) => {
    const changedCount = data?.changesSummary.changed ?? 0;
    const deletedCount = data?.changesSummary.deleted ?? 0;

    return (
        <ChangesSummaryContainer>
            <ChangedCount>{changedCount}</ChangedCount>
            <DeletedCount>{deletedCount}</DeletedCount>
        </ChangesSummaryContainer>
    );
};
