import { IWorkForCopy } from "../CopyResourcesDrawer.types";
import { IAddWorkToListArgs, ISwitchResourceArgs } from "./WorkList.types";

const checkOnlyOneWork = ({ params, checked }: Omit<IAddWorkToListArgs, 'isCopyCosts'>) => {
    params.api.forEachNode((row) => {
        if (row.data?.workId === params.data?.workId) {
            params.api.applyTransaction({
                update: [{ ...(params.data as IWorkForCopy), checked: checked }],
            });
            params.api.redrawRows({ rowNodes: [row] });
            return;
        }

        const updatedRow = {
            ...row.data,
            checked: false,
            choice: {
                ...row.data?.choice,
                ...row.data?.initialChoice,
            },
        } as IWorkForCopy;
        params.api.applyTransaction({
            update: [updatedRow],
        });
        params.api.redrawRows({ rowNodes: [row] });
    });
};

export function addWorkToList({ params, checked, isCopyCosts = false }: IAddWorkToListArgs) {
    if (isCopyCosts) {
        checkOnlyOneWork({ params, checked });
        return;
    }

    params.api.applyTransaction({
        update: [{ ...(params.data as IWorkForCopy), checked: checked }],
    });
    params.api.redrawRows({ rowNodes: [params.node] });
}

export function switchResource({ params, checked, resourceType }: ISwitchResourceArgs) {
    const updatedWork = {
        ...(params.data as IWorkForCopy),
        choice: { ...(params.data as IWorkForCopy).choice, [resourceType]: checked },
    };
    params.api.applyTransaction({
        update: [updatedWork],
    });
    params.api.redrawRows({ rowNodes: [params.node] });
}

export function switchWork({ params, checked }: IAddWorkToListArgs) {
    params.api.forEachNode((node) => {
        node.data?.checked &&
            params.api.applyTransaction({
                update: [{ ...(node.data as IWorkForCopy), checked: false }],
            });
    });
    params.api.applyTransaction({
        update: [{ ...(params.data as IWorkForCopy), checked: true }],
    });
    params.api.redrawRows({ rowNodes: [params.node] });
}
