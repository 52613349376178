import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { projectsSelector } from '@/store/slices/projectsSlice';
import { useTypedSelector } from '@/store/store';

import { PublicUserProfile } from '../../api/users/users.types';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions';
import { UserRoleByTabLabel, UserRoleTabLabels, UserRolesEn } from './user';

const MIN_SEARCH_LENGTH = 2;

export const useSearchValue = () => {
    const { headerSearch } = useTypedSelector(projectsSelector);
    const [searchValue, setSearchValue] = useState(headerSearch ?? '');

    const debounceFn = useCallback(debounce(setSearchValue, 1000), []);

    useEffect(() => {
        debounceFn(headerSearch);
    }, [headerSearch]);

    return searchValue;
};

const calculateOffset = (limitValue: number, page: number) => limitValue * (page - 1);

type UseDataFilterProps = {
    userData: PublicUserProfile[];
    currentTab: UserRoleTabLabels;
    searchValue: string;
    limitValue: number;
    page: number;
};

export const useDataFilter = ({ userData, currentTab, searchValue, limitValue, page }: UseDataFilterProps) => {
    const filteredDataByRole = useMemo(() => {
        if (currentTab === 'Все пользователи') return userData;

        return userData?.filter((user) => user.role === UserRoleByTabLabel[currentTab]);
    }, [currentTab, userData]);

    const searchedData = useMemo(() => {
        const value = searchValue.toLowerCase();

        if (value.length < MIN_SEARCH_LENGTH) return filteredDataByRole;

        return filteredDataByRole?.filter((v) => {
            const search = (term: 'firstName' | 'email' | 'lastName' | 'phone') => {
                return v[term]?.toLowerCase().includes(value);
            };
            return search('firstName') || search('email') || search('lastName') || search('phone');
        });
    }, [searchValue, filteredDataByRole]);

    const offset = calculateOffset(limitValue, page);
    const pagedUserData = searchedData?.slice(offset, offset + limitValue);
    const countPagination = Math.ceil(filteredDataByRole?.length / limitValue) ?? 1;

    return {
        pagedUserData,
        countPagination,
    };
};

export const useUserNavigation = (profileRole: UserRolesEn) => {
    const navigate = useNavigate();

    const handleUserCardClick = (userId: number) => {
        if (!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profileRole, 'EDIT_USERS')) return;
        return navigate(`edit/${userId}`);
    };

    const handleUserAdd = useCallback(() => {
        navigate('add');
    }, [navigate]);

    return {
        handleUserCardClick,
        handleUserAdd,
    };
};
