import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { FaFilter } from '@react-icons/all-files/fa/FaFilter';
import { GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSpinDelay } from 'spin-delay';

import { useFilterSearch } from '@/components/FilterDialog';

import { FlexColumnWrapper } from '@/pages/NewExecutorView/components/components.styles';

import { theme } from '@/styles/theme';

import { columnDefs } from './ConsolidatedPriceFilterDialog.config';
import {
    Container,
    Dialog,
    Header,
    LoaderContainer,
    ResetButton,
    Title,
    TitleContainer,
} from './ConsolidatedPriceFilterDialog.styles';
import { TContext, TFilterDialogProps } from './ConsolidatedPriceFilterDialog.types';
import { SearchField } from './SearchField';

export const ConsolidatedPriceFilterDialog: FC<TFilterDialogProps> = (props) => {
    const selectAll = props.filter.selectAll;

    const values = props.values ?? [];

    const isResetButtonDisabled = props.filter.values.length === 0;

    const { search, onReset: onSearchReset, onChange: onSearchChange } = useFilterSearch();
    const gridRef = useRef<AgGridReact>(null);
    const isLoadingWithMinDuration = useSpinDelay(props.isLoading, { delay: 0, minDuration: 700 });

    useEffect(() => {
        gridRef.current?.api.setQuickFilter(search);
    }, [search]);

    const rowData = useMemo(() => {
        return values.map((value) => ({
            id: value,
            label: value,
            value: value,
            checked: props.filter.values.includes(value),
        }));
    }, [values, props.filter]);

    const getRowId = useCallback((params: GetRowIdParams<any, any>) => {
        return params.data.value;
    }, []);

    const updateFilterEffect = () => {
        gridRef.current?.api.redrawRows();
    };

    const handleClose = () => {
        props.onClose();
        onSearchReset();
    };

    const handleReset = () => {
        props.onReset();
        updateFilterEffect();
    };

    const handleCheckboxChange = useCallback((value: string, checked: boolean) => {
        props.onCheckboxChange(value, checked);
        updateFilterEffect();
    }, []);

    const handleAllCheckboxChange = useCallback((checked: boolean) => {
        props.onAllCheckboxChange(checked);
        updateFilterEffect();
    }, []);

    const context: TContext = useMemo(() => {
        return {
            onCheckboxChange: handleCheckboxChange,
        };
    }, [handleCheckboxChange]);

    return (
        <Dialog
            open={props.isVisible}
            onClose={handleClose}
        >
            <Container>
                <Header>
                    <TitleContainer>
                        <FaFilter color={theme.palette.primary.main} />
                        <Title>{props.title}</Title>
                    </TitleContainer>
                    <ResetButton
                        disabled={isResetButtonDisabled}
                        onClick={handleReset}
                    >
                        Сбросить фильтр
                    </ResetButton>
                </Header>
                <SearchField
                    value={search}
                    onReset={onSearchReset}
                    onChange={onSearchChange}
                />

                {isLoadingWithMinDuration ? (
                    <LoaderContainer>
                        <CircularProgress
                            size={50}
                            sx={{ margin: '0 auto' }}
                        />
                    </LoaderContainer>
                ) : (
                    <FlexColumnWrapper
                        gap={0}
                        flex={1}
                        width={'100%'}
                    >
                        {values.length > 0 && (
                            <FlexColumnWrapper gap={0}>
                                <FormControlLabel
                                    label='Выбрать все'
                                    sx={{
                                        '.MuiSvgIcon-root': { fill: '#0044B4' },
                                        minHeight: '46px',
                                        userSelect: 'none',
                                    }}
                                    control={
                                        <Checkbox
                                            checked={selectAll}
                                            onChange={(_, checked) => handleAllCheckboxChange(checked)}
                                        />
                                    }
                                />
                            </FlexColumnWrapper>
                        )}

                        <FlexColumnWrapper
                            flex={1}
                            width={'100%'}
                        >
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                context={context}
                                headerHeight={0}
                                rowHeight={46}
                                getRowId={getRowId}
                                columnDefs={columnDefs}
                                overlayNoRowsTemplate='Нет данных'
                            />
                        </FlexColumnWrapper>
                    </FlexColumnWrapper>
                )}
            </Container>
        </Dialog>
    );
};
