import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const WrapperButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 40px;
    align-content: center;
    border-radius: 4px;
    min-width: 253px;
`;
export const WrapperCell = styled.div`
    padding: 7px 8px;
`;
export const Option = styled(FormControlLabel)`
    width: 100%;
    display: flex;
    margin: 0;
    align-items: center;
    height: 100%;
    justify-content: space-between;
`;
export const StyledIconBtn = styled(FormControlLabel)`
    border-radius: ${({ theme }) => theme.spacing(0.75)};
    background: #f6f7fb;
    color: #0044b4;
    :hover {
        background-color: none;
    }
`;
