import { ICellRendererParams } from 'ag-grid-community';

import { UIColoredPercentage } from '@/components/ui/UIColoredPercentage';

interface IPercentageCellRendererProps extends Partial<ICellRendererParams> {}

export const PercentageCellRenderer: React.FC<IPercentageCellRendererProps> = ({ value, getValue }) => {
    if (value === null || (getValue && getValue() == null)) return <></>;

    return <UIColoredPercentage value={value} />;
};
