import { RadioGroup, RadioGroupProps } from '@mui/material';
import { useField } from 'formik';
import { FieldHookConfig } from 'formik/dist/Field';
import { useCallback } from 'react';

type RadioGroupFieldFormProps = {
    name: string;
    fieldProps?: Partial<FieldHookConfig<string>>;
} & Omit<RadioGroupProps, 'name'>;

export function RadioGroupFieldForm({ fieldProps, onBlur, children, ...props }: RadioGroupFieldFormProps) {
    const [{ onBlur: onFieldBlur, ...field }] = useField({
        name: props.name,
        ...fieldProps,
    });

    const handleBlur = useCallback(
        (e: any) => {
            onFieldBlur?.(e);
            onBlur?.(e);
        },
        [onBlur, onFieldBlur]
    );

    return (
        <RadioGroup
            {...field}
            onBlur={handleBlur}
            {...props}
        >
            {children}
        </RadioGroup>
    );
}
