import * as Yup from 'yup'
import { modulesStatusesType, pickSyncModuleType } from "../../api/importingData/importingData.types"
import oracle from './images/oracle.png'
import design from './images/design.png'
import procurement from './images/procurement.png'
import offer from './images/offer.png'

export interface listDataI {
    isLoading: boolean
    data: { id: number; name: string }[]
}

export const validationSchemaSynchronize = Yup.object({
    name: Yup.string().required('Введите имя').min(3, 'Не меньше 3 символов').max(50, 'Не больше 50 символов'),
    email: Yup.string().required('Введите почту').email('Неправильный формат').max(50, 'Не больше 50 символов'),
    tel: Yup.string()
        .required('Введите телефон')
        .test({
            name: 'min',
            message: 'Слишком короткий номер',
            test: (value) => value?.split('').filter((item) => /\d/.test(item)).length === 11,
        }),
    desc: Yup.string().required('Заполните описание').min(3, 'Не меньше 3 символов').max(100, 'Не больше 100 символов'),
})

export const syncInput = {
    background: 'white',
    borderRadius: '8px',
    padding: '0.2rem 0.5rem',
    border: '1px solid #F6F7FB',
    '.MuiInput-input': {
        marginLeft: '0.6rem',
    },
}

export const moduleCardPicture: Record<pickSyncModuleType, string> = {
    PragmaOffer: offer,
    Primavera: oracle,
    PragmaDesign: design,
    PragmaProcurement: procurement,
}

export const modulesNames: Partial<Record<pickSyncModuleType, string>> = {
    Primavera: 'Primavera',
    PragmaDesign: 'Pragma.Design',
    PragmaProcurement: 'Pragma.Procurement',
}

export const widthAndColorByStatus: Record<modulesStatusesType, { width: number; color: string }> = {
    АКТИВНО: { width: 72, color: '#00BFA5' },
    ПОДКЛЮЧЕНО: { width: 100, color: '#FE9B3F' },
    ДОСТУПНО: { width: 80, color: '#6D9ADC' },
}
