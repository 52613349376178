import { ColDef, ICellRendererParams } from 'ag-grid-community';

import { TEstimatePositionId } from '@/api/estimatePositions';

import { getFormatNumber } from '@/shared/utils/formatNumber.utils';

import { TEstimateCheckedPositionMapValue } from '@/store/slices/estimatePositions';

import { CheckboxCellRenderer, DeleteCellRenderer, TextCellRenderer } from '../TableCell';
import { UnitCellRenderer } from '../TableCell/TableCell';
import { TContext, TData } from './Table.types';
import { isChangedCell } from './Table.utils';

export const columnsDef: ColDef<TData>[] = [
    {
        headerName: '',
        field: 'main',
        minWidth: 44,
        maxWidth: 44,
        width: 44,
        headerClass: 'ag-header-cell-center ag-cell-left',
        cellClass: 'ag-cell-center ag-cell-left',
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: (params: ICellRendererParams<TData, any, TContext>) => {
            if (!params.data?.id) throw Error('id is undefined');
            const id = params.data.id as TEstimatePositionId;

            return {
                checked: params.context.checkedId === id,
                color: '#0044b4',
                onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    params.context.onCheckboxChange(id, 'main')(event, checked);
                },
            };
        },
    },
    {
        headerName: '№ п/п',
        field: 'ordinal',
        minWidth: 56,
        maxWidth: 56,
        width: 56,
        headerClass: 'ag-header-cell-center p0',
        cellClass: 'ag-cell-center',
    },
    {
        headerName: 'Шифр ЛСР',
        field: 'lsrCode',
        minWidth: 180,
        width: 180,
        maxWidth: 180,
        cellClassRules: {
            'changed-cell': isChangedCell('lsrCode'),
        },
        cellRenderer: TextCellRenderer,
    },
    {
        headerName: '№ по ЛСР',
        field: 'lsrNumber',
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        cellClassRules: {
            'changed-cell': isChangedCell('lsrNumber'),
        },
        headerClass: 'ag-header-cell-center p0',
        cellClass: 'ag-cell-center',
    },
    {
        headerName: 'Раздел',
        field: 'chapter',
        minWidth: 180,
        width: 180,
        maxWidth: 180,
        cellClassRules: {
            'changed-cell': isChangedCell('chapter'),
        },
        cellRenderer: TextCellRenderer,
    },
    {
        headerName: 'Заголовок',
        field: 'header',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        cellClassRules: {
            'changed-cell': isChangedCell('header'),
        },
        cellRenderer: TextCellRenderer,
    },
    {
        headerName: 'Код расценки',
        field: 'code',
        minWidth: 180,
        width: 180,
        maxWidth: 180,
        cellClassRules: {
            'changed-cell': isChangedCell('code'),
        },
    },
    {
        headerName: 'Наименование',
        field: 'positionName',
        cellRenderer: TextCellRenderer,
        minWidth: 390,
        flex: 1,
        cellClassRules: {
            'changed-cell': isChangedCell('positionName'),
        },
    },
    {
        headerName: 'Ед. изм',
        field: 'rawUnit',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        headerClass: 'ag-header-cell-center p0',
        cellClass: 'ag-cell-center',
        cellClassRules: {
            'changed-cell': isChangedCell('rawUnit'),
        },
        cellRenderer: UnitCellRenderer,
    },
    {
        headerName: 'Кол-во',
        field: 'quantity',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        headerClass: 'ag-header-cell-center p0',
        cellClass: 'ag-cell-center',
        cellClassRules: {
            'changed-cell': isChangedCell('quantity'),
        },
    },
    {
        headerName: 'ТЗ ОР, чел-ч',
        field: 'humanHourCost',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        headerClass: 'ag-header-cell-center p0',
        cellClass: 'ag-cell-right',
        cellClassRules: {
            'changed-cell': isChangedCell('humanHourCost'),
        },
    },
    {
        headerName: 'ТЗМ, чел-ч',
        field: 'machineHourCost',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        headerClass: 'ag-header-cell-center p0',
        cellClass: 'ag-cell-right',
        cellClassRules: {
            'changed-cell': isChangedCell('machineHourCost'),
        },
    },
    {
        headerName: 'Всего стоимость',
        field: 'costTotal',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        flex: 1,
        minWidth: 140,
        headerClass: 'ag-header-cell-center p0',
        cellClass: 'ag-cell-right',
        cellClassRules: {
            'changed-cell': isChangedCell('costTotal'),
        },
    },
    {
        headerName: '',
        field: 'delete',
        valueFormatter: ({ value }) => getFormatNumber({ value }) ?? '',
        cellRenderer: DeleteCellRenderer,
        cellRendererParams: (params: ICellRendererParams<TData, any, TContext>) => {
            if (!params.data?.id) throw Error('id is undefined');
            const { id, selectType } = params.data as TEstimateCheckedPositionMapValue;

            return {
                onClick: params.context.onRowDelete(id, selectType),
            };
        },

        minWidth: 44,
        maxWidth: 44,
        width: 44,
        headerClass: 'ag-header-cell-center p0',
        cellClass: 'ag-cell-center p0',
    },
];
