import { FC } from 'react';

import { OverflowMenuIcon } from '@/shared/SVG/OverflowMenuIcon';

import {
    EditCellRenderButton,
    EditCellRendererPopover,
    EditCellRendererPopoverButton,
    EditCellRendererPopoverItem,
    EditCellRendererPopoverList,
} from './TableCells.styles';
import { TEditCellProps, TEditCellRendererProps, TEditHeaderComponentProps } from './TableCells.types';

const EditCell: FC<TEditCellProps> = ({ isOpen, list, anchorEl, onOpen, onClose }) => (
    <>
        <EditCellRenderButton onClick={onOpen}>
            <OverflowMenuIcon />
        </EditCellRenderButton>

        <EditCellRendererPopover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >
            <EditCellRendererPopoverList>
                {list.map(({ onClick, label }, index) => (
                    <EditCellRendererPopoverItem key={index}>
                        <EditCellRendererPopoverButton onClick={onClick}>{label}</EditCellRendererPopoverButton>
                    </EditCellRendererPopoverItem>
                ))}
            </EditCellRendererPopoverList>
        </EditCellRendererPopover>
    </>
);

export const EditCellRenderer: FC<TEditCellRendererProps> = (props) => (
    <EditCell
        isOpen={props.isOpen}
        list={props.list ?? []}
        anchorEl={props.eGridCell}
        onOpen={props.onOpen}
        onClose={props.onClose}
    />
);

export const EditHeaderComponent: FC<TEditHeaderComponentProps> = (props) => (
    <EditCell
        isOpen={props.isOpen}
        list={props.list ?? []}
        anchorEl={props.eGridHeader}
        onOpen={props.onOpen}
        onClose={props.onClose}
    />
);
