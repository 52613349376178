import { ICellRendererParams, IHeaderParams } from 'ag-grid-community';

import { ITotalRow } from '@/shared/components/AgGrid/AgGrid.types';

import { EstimateAPI } from '../../../../api/EstimateAPI';
import { IEstimateResourseTotal, TResourceType } from '../../../../api/api.types';
import { IAutocompleteOption } from '../../../../components/CellEditors/CellEditor/CellEditor.types';
import { IEstimateResource } from './EstimateResourcesPage.types';

export const ESTIMATE_RESOURCE_TYPE_OPTIONS: IAutocompleteOption[] = [
    {
        label: 'Трудозатраты',
        value: 'Трудозатраты',
    },
    {
        label: 'МиМ',
        value: 'МиМ',
    },
    {
        label: 'Материалы',
        value: 'Материалы',
    },
    {
        label: 'ТЗМ',
        value: 'ТЗМ',
    },
];

export function addNewEstimateRow(templateRow: any, params: IHeaderParams) {
    let rowDataCurrent: any[] = [];
    params.api.forEachNode((node) => {
        rowDataCurrent.push(node.data);
    });
    EstimateAPI.addNewItem({
        estimateType: params.context.estimateType,
        projectID: params.context.projectID,
        workID: params.context.workID,
        body: templateRow,
    }).then((data) => {
        rowDataCurrent.push(data);
        params.api.setRowData(rowDataCurrent);
    });
}

export function removeEstimateRow(params: ICellRendererParams) {
    EstimateAPI.deleteItem({
        estimateType: params.context.estimateType,
        projectID: params.context.projectID,
        workID: params.context.workID,
        estimateItemID: params.data.id,
    }).then(() => {
        params.api.applyTransaction({ remove: [params.data] });
    });
}

export function getResourceType(data: IEstimateResource): TResourceType {
    switch (data.type) {
        case 'Трудозатраты':
            return 'staff';
        case 'МиМ':
            return 'mim';
        case 'Материалы':
            return 'mto';
    }
    return 'mto';
}

export const TOTAL_ESTIMATE_RESOURCE_ROW: ITotalRow<IEstimateResourseTotal> = {
    id: 'totalRow',
    type: 'ИТОГО',
    unit: '',
    qty: 0,
    costBase: 0,
    costCurr: 0,
    qtyTotal: 0,
    costBaseTotal: 0,
    costCurrTotal: 0,
};
