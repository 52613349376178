import { CSSProperties } from 'react';

interface IProps {
    style?: CSSProperties;
}

export const PlusIcon: React.FC<IProps> = ({ style }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='13'
            height='12'
            viewBox='0 0 13 12'
            fill='none'
            style={style}
        >
            <path
                d='M5.41699 11.8333V6.83334H0.416992V5.16667H5.41699V0.166672H7.08366V5.16667H12.0837V6.83334H7.08366V11.8333H5.41699Z'
                fill='currentColor'
            />
        </svg>
    );
};
