import { ArrowBack as MuiArrowBack, ArrowForward as MuiArrowForward } from '@mui/icons-material';
import { Button, IconButton, Dialog as MuiDialog, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

import { ResetButton } from '../../../ui/UIButton';

export const Dialog = styled(MuiDialog)`
    .MuiDialog-paper {
        max-width: 600px;
        max-height: 320px;
        box-shadow: 0 8px 38px 7px rgba(0, 0, 0, 0.12), 0 20px 31px 3px rgba(0, 0, 0, 0.14),
            0 10px 13px -6px rgba(0, 0, 0, 0.2);
        background: #fff;
        border-radius: 16px;
    }
`;

export const Container = styled(Stack)`
    position: relative;
    padding: 30px 30px 20px 30px;
    height: 320px;
`;

export const Content = styled(Stack).attrs({ direction: 'row', justifyContent: 'space-between', alignItems: 'center' })`
    gap: 30px;
`;

export const Title = styled(Typography).attrs({ variant: 'h3' })`
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #0044b4;
`;

export const Text = styled(Typography)`
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #2b3648;
`;

export const Buttons = styled(Stack).attrs({
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
})`
    margin-top: auto;
`;

export const ArrowButton = styled(Button).attrs({
    variant: 'text',
    type: 'button',
    disableRipple: true,
})`
    font-weight: 500;
    font-size: 13px;
    line-height: 169%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #0044b4;
    width: 90px;
`;

export const ArrowBackIcon = styled(MuiArrowBack)`
    font-size: 16px !important;
`;

export const ArrowForwardIcon = styled(MuiArrowForward)`
    font-size: 16px !important;
`;

export const DotsContainer = styled(Stack).attrs({
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 0.5,
})``;

export const Dot = styled(ResetButton)<{ $isActive: boolean }>`
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: ${({ $isActive }) => ($isActive ? '#0044b4' : '#d2dcff')};
    transition: background-color 0.3s;
`;

export const Spacer = styled('span')`
    display: block;
    height: 20px;
`;

export const CloseButton = styled(Button).attrs({
    disableRipple: true,
})`
    font-weight: 500;
    font-size: 13px;
    line-height: 169%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #2b3648;
    width: 90px;
`;

export const DoneButton = styled(Button).attrs({
    disableRipple: true,
})`
    font-weight: 500;
    font-size: 13px;
    line-height: 169%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #0044b4;
    width: 90px;
`;

export const Minus = styled('span')`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 177, 177, 0.15);
    pointer-events: none;
    margin-inline: 5px;

    &::before {
        content: '';
        background-color: #f46b6b;
        width: 12px;
        height: 2px;
    }
`;

export const CloseIconButton = styled(IconButton)`
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
    color: #7890b2;

    svg {
        width: 20px;
        height: 20px;
        font-size: 1.25rem;
    }
`;
