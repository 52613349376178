import { TextField } from '@mui/material';

import { filtersSomSelector, setMaxCompletion, setMinCompletion } from '@/store/slices/filtersSomSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import s from '../CheckboxPopoverSufficiency.module.scss';
import { minPercentCompletion } from '../CheckboxPopoverSufficiency.service';

export default function FilterCompletionEl() {
    const { completion } = useTypedSelector(filtersSomSelector);

    const dispatch = useAppDispatch();

    return (
        <div className={s.inputs_wrapper}>
            <TextField
                id='min'
                label='От'
                size='small'
                variant='outlined'
                value={completion.min}
                inputProps={{
                    min: 0,
                }}
                error={(() => {
                    try {
                        minPercentCompletion.validateSync(Number(completion.min));
                        return false;
                    } catch {
                        return true;
                    }
                })()}
                sx={{
                    backgroundColor: '#f6f7fb',
                    '.MuiInputLabel-root': { color: '#5C6E8C' },
                }}
                onChange={(e) => dispatch(setMinCompletion(e.target.value))}
            />
            <TextField
                id='max'
                label='До'
                size='small'
                variant='outlined'
                value={completion.max}
                sx={{
                    backgroundColor: '#f6f7fb',
                    '.MuiInputLabel-root': { color: '#5C6E8C' },
                }}
                onChange={(e) => dispatch(setMaxCompletion(e.target.value))}
            />
        </div>
    );
}
