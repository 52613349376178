import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetTemplatesListQuery } from '@/api/templates/templates.api';
import { templateBody } from '@/api/templates/templates.types';
import { useResetPatternKsgMutation, useSetPatternKsgMutation } from '@/api/templatesGetSet/templatesGetSet.api';

import { toggleChangeCpgTemplate, toggleCreateCpgTemplate } from '@/store/slices/drawersSlice';
import { refreshTemplateCpg, setTemplateCpgState, setTemplateCpgStateView } from '@/store/slices/ksgTemplatesSlice';
import { useAppDispatch } from '@/store/store';

import { TableTemplateMenuProps } from './TableTemplate.types';
import SharedTemplateMenu from './components/SharedTemplateMenu';

export default function TableTemplateMenu(p: TableTemplateMenuProps) {
    const dispatch = useAppDispatch();

    const { projectId } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');

    const templatesList = useGetTemplatesListQuery({
        id: Number(projectId),
    });

    const [setPatternReq] = useSetPatternKsgMutation();
    const [resetPatternReq] = useResetPatternKsgMutation();

    const setPatternKsg = (patId: number) => {
        setPatternReq({
            projectId: Number(projectId),
            patId,
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('template_ksg_applied'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
    };

    const resetPatternKsg = () => {
        resetPatternReq({ projectId: Number(projectId) })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('template_ksg_reset'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
                console.error(e);
            });
    };

    const applyTemplate = (terms: templateBody) => {
        p.setAnchorElTemplateTable(null);
        dispatch(setTemplateCpgState(terms));
    };

    const applyTemplateView = (terms: templateBody) => {
        p.setAnchorElTemplateTable(null);
        dispatch(setTemplateCpgStateView(terms));
    };
    return (
        <SharedTemplateMenu
            onRefresh={() => {
                p.setAnchorElTemplateTable(null);
                dispatch(refreshTemplateCpg());
                resetPatternKsg();
            }}
            onApplyKsg={(v) => {
                applyTemplate(v);
                setPatternKsg(v.id);
            }}
            onApplyKsgView={(v) => {
                applyTemplateView(v);
            }}
            onChange={() => {
                dispatch(toggleChangeCpgTemplate());
                p.setAnchorElTemplateTable(null);
            }}
            onCreate={() => {
                dispatch(toggleCreateCpgTemplate());
                p.setAnchorElTemplateTable(null);
            }}
            ksgList={templatesList.data?.data}
        />
    );
}
