import { TypographyProps } from '@mui/material';
import { FaFilter } from '@react-icons/all-files/fa/FaFilter';

import { Button, Container, Text } from './UIHeaderFilter.styles';

interface IUIHeaderFilterProps {
    width?: number;
    name: string;
    isApplied: boolean;
    onClick: () => void;
    containerPadding?: string;
    textProps?: TypographyProps;
    iconColor?: string;
    appliedIconColor?: string;
}

const ICON_COLOR = '#fff';
const ICON_APPLIED_COLOR = '#6FCCBC';

export const UIHeaderFilter: React.FC<IUIHeaderFilterProps> = ({
    width,
    name,
    containerPadding,
    isApplied,
    onClick,
    textProps = {},
    iconColor = ICON_COLOR,
    appliedIconColor = ICON_APPLIED_COLOR,
}) => {
    const color = isApplied ? appliedIconColor : iconColor;

    return (
        <Container
            width={(width || 32) - 32}
            padding={containerPadding}
        >
            <Text {...textProps}>{name}</Text>
            <Button onClick={onClick}>
                <FaFilter
                    fontSize={12}
                    fill={color}
                />
            </Button>
        </Container>
    );
};
