import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { currentCompanyName, deployLinks } from '@/shared/config/config';

const apiInstance = axios.create({
    baseURL: deployLinks.server,
});

interface ITokenData {
    refreshDurationMs: number;
    refreshToken: string;
    token: string;
    tokenDurationMs: number;
}

const shouldIntercept = (error: AxiosError) => {
    try {
        return error?.response?.status === 401;
    } catch (e) {
        return false;
    }
};

const setTokenData = (tokenData = {} as ITokenData, axiosClient: any) => {
    localStorage.setItem('auth', JSON.stringify(tokenData));
};

const handleTokenRefresh = () => {
    const refreshToken = JSON.parse(localStorage.getItem('auth')!).refreshToken;
    return new Promise((resolve, reject) => {
        apiInstance
            .post('/auth/refresh', { token: refreshToken })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const attachTokenToRequest = (request: AxiosRequestConfig, token: string) => {
    if (request.headers) request.headers['Authorization'] = 'Bearer ' + token;
};

function applyAppTokenRefreshInterceptor(axiosInstance: AxiosInstance, customOptions = {}) {
    let isRefreshing = false;
    let failedQueue: { resolve: (value: unknown) => void; reject: (reason?: any) => void }[] = [];

    const options = {
        attachTokenToRequest,
        handleTokenRefresh,
        setTokenData,
        shouldIntercept,
        ...customOptions,
    };
    const processQueue = (error: AxiosError | null, token = null as string | null) => {
        failedQueue.forEach((prom) => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    const getRequestInterceptor = (config: AxiosRequestConfig) => {
        const token = JSON.parse(localStorage!.getItem('auth')!).token;

        if (token) {
            (config.headers ?? {}).Authorization = `Bearer ${token}`;
        }

        return config;
    };

    const getResponseInterceptor = (error: any) => {
        if (!options.shouldIntercept(error)) {
            if (error.config.url.includes('/auth/refresh')) {
                localStorage.setItem('auth', '{}');
                window.location.replace(
                    `${deployLinks.core}?source=finance${currentCompanyName ? `&company=${currentCompanyName}` : ''}`
                );
                isRefreshing = false;
                return;
            } else {
                console.log(error.message);
            }
            return Promise.reject(error);
        }

        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }

        const originalRequest = error.config;
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
            })
                .then((token: unknown) => {
                    originalRequest._queued = true;
                    options.attachTokenToRequest(originalRequest, token as string);
                    return axiosInstance.request(originalRequest);
                })
                .catch((err) => {
                    return Promise.reject(error); // Игнорирует перехватчик, и возвращает ошибку с кодом != 401 дальше в промис
                });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
            options.handleTokenRefresh
                .call(options.handleTokenRefresh)
                .then((tokenData) => {
                    options.setTokenData(tokenData as ITokenData, axiosInstance);
                    options.attachTokenToRequest(originalRequest, (tokenData as ITokenData).token);
                    processQueue(null, (tokenData as ITokenData).token);
                    resolve(axiosInstance.request(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                });
        });
    };

    axiosInstance.interceptors.request.use(getRequestInterceptor);
    axiosInstance.interceptors.response.use(undefined, getResponseInterceptor);
}

applyAppTokenRefreshInterceptor(apiInstance);

export const createInstance = async <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
    const response = await apiInstance({
        ...config,
        ...options,
    });

    return response.data as T;
};
