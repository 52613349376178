import { api } from '@/api/api';

import { setTemplateCpgState } from '@/store/slices/ksgTemplatesSlice';
import { setTemplateMsgState } from '@/store/slices/msgTemplatesSlice';
import { setSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { setTemplatesKsgNamesList, setTemplatesMsgNamesList } from '@/store/slices/templatesSharedSlice';

import {
    addTemplateMsgReq,
    addTemplateReq,
    getTemplatesListRes,
    getTemplatesMsgListRes,
    getTemplatesStatusResI,
    IProjectFieldSettings,
    templateBody,
    templateMsgBody,
    updateTemplateMsgReq,
    updateTemplateReq,
} from './templates.types';

export const templateApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTemplatesList: build.query<getTemplatesListRes, { id: number }>({
            query: ({ id }) => ({
                url: `/projects/${id}/patterns/ksg/list`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(setTemplatesKsgNamesList(updatedStatus.data.map((v) => v.name.toLowerCase())));
                } catch {}
            },
            providesTags: ['GetTemplatesState'],
        }),

        addTemplate: build.mutation<templateBody, addTemplateReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/patterns/ksg/add`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(
                        templateApi.util.updateQueryData('getTemplatesList', { id }, (draft) => {
                            draft.data.push(updatedStatus);
                        })
                    );

                    dispatch(
                        templateApi.util.updateQueryData('getTemplatesStatus', { projectId: id }, (draft) => {
                            draft.hasPatternKSG = true;
                        })
                    );
                } catch {}
            },
            invalidatesTags: ['GetTemplatesState'],
        }),

        getSingleTemplate: build.query<templateBody, { id: number; temId: number }>({
            query: ({ id, temId }) => ({
                url: `/projects/${id}/patterns/ksg/${temId}/get`,
                method: 'GET',
            }),
        }),

        updateTemplate: build.mutation<any, updateTemplateReq>({
            query: ({ id, temId, body }) => ({
                url: `/projects/${id}/patterns/ksg/${temId}/update`,
                method: 'POST',
                body,
            }),
        }),

        deleteTemplate: build.mutation<any, { id: number; temId: number }>({
            query: ({ id, temId }) => ({
                url: `/projects/${id}/patterns/ksg/${temId}/delete`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id, temId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        templateApi.util.updateQueryData('getTemplatesList', { id }, (draftList) => {
                            const list = draftList.data.filter((template) => template.id !== temId);
                            draftList.data = list;
                            dispatch(
                                templateApi.util.updateQueryData('getTemplatesStatus', { projectId: id }, (draft) => {
                                    if (!list.length) {
                                        draft.hasPatternKSG = false;
                                    }
                                })
                            );
                        })
                    );
                } catch {}
            },
            invalidatesTags: ['GetTemplatesState'],
        }),

        getTemplatesListMsg: build.query<getTemplatesMsgListRes, { id: number }>({
            query: ({ id }) => ({
                url: `/projects/${id}/patterns/msg/list`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(setTemplatesMsgNamesList(updatedStatus.data.map((v) => v.name.toLowerCase())));
                } catch {}
            },
            providesTags: ['GetTemplatesState', 'ListNameMsg'],
        }),

        addTemplateMsg: build.mutation<templateMsgBody, addTemplateMsgReq>({
            query: ({ id, body }) => ({
                url: `/projects/${id}/patterns/msg/add`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedStatus } = await queryFulfilled;
                    dispatch(
                        templateApi.util.updateQueryData('getTemplatesListMsg', { id }, (draft) => {
                            draft.data.push(updatedStatus);
                        })
                    );

                    dispatch(
                        templateApi.util.updateQueryData('getTemplatesStatus', { projectId: id }, (draft) => {
                            draft.hasPatternMSG = true;
                        })
                    );
                } catch {}
            },
            invalidatesTags: ['GetTemplatesState'],
        }),

        getSingleTemplateMsg: build.query<templateMsgBody, { id: number; temId: number }>({
            query: ({ id, temId }) => ({
                url: `/projects/${id}/patterns/msg/${temId}/get`,
                method: 'GET',
            }),
        }),

        updateTemplateMsg: build.mutation<any, updateTemplateMsgReq>({
            query: ({ id, temId, body }) => ({
                url: `/projects/${id}/patterns/msg/${temId}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['GetTemplatesState', 'ListNameMsg'],
        }),

        deleteTemplateMsg: build.mutation<any, { id: number; temId: number }>({
            query: ({ id, temId }) => ({
                url: `/projects/${id}/patterns/msg/${temId}/delete`,
                method: 'DELETE',
            }),
            async onQueryStarted({ id, temId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        templateApi.util.updateQueryData('getTemplatesList', { id }, (draftList) => {
                            const list = draftList.data.filter((template) => template.id !== temId);
                            draftList.data = list;
                            dispatch(
                                templateApi.util.updateQueryData('getTemplatesStatus', { projectId: id }, (draft) => {
                                    if (!list.length) {
                                        draft.hasPatternMSG = false;
                                    }
                                })
                            );
                        })
                    );
                } catch {}
            },
            invalidatesTags: ['GetTemplatesState'],
        }),

        getTemplatesStatus: build.query<getTemplatesStatusResI, { projectId: number }>({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/patterns/status`,
                method: 'GET',
            }),
            async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
                const data = await queryFulfilled;
                dispatch(setTemplateMsgState(data.data.currentPatternMSG));
                dispatch(setTemplateCpgState(data.data.currentPatternKSG));
            },
            /**
             * добавил прослушивание добавления данных в кеш
             * после получения вытаскиваю данные о настройках таблицы и засовываю в слайс
             */
            async onCacheEntryAdded(args, { dispatch, cacheDataLoaded, getCacheEntry }) {
                await cacheDataLoaded;
                const fulfilled = getCacheEntry();
                if (fulfilled.data) {
                    dispatch(setSettings(fulfilled.data.projectFieldsSettings));
                }
            },
            providesTags: ['GetTemplatesState'],
        }),

        setTemplatesStatus: build.mutation<
            IProjectFieldSettings,
            { projectId: number; body: Partial<IProjectFieldSettings> }
        >({
            query: ({ projectId, body }) => {
                return {
                    url: `/projects/${projectId}/update-settings`,
                    method: 'POST',
                    body,
                };
            },

            async onCacheEntryAdded(args, { dispatch, cacheDataLoaded, getCacheEntry }) {
                await cacheDataLoaded;
                const fulfilled = getCacheEntry();
                if (fulfilled.data) {
                    dispatch(setSettings(fulfilled.data));
                }
            },
            invalidatesTags: ['GetTemplatesState'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetTemplatesListQuery,
    useAddTemplateMutation,
    useUpdateTemplateMutation,
    useDeleteTemplateMutation,
    useGetTemplatesListMsgQuery,
    useAddTemplateMsgMutation,
    useUpdateTemplateMsgMutation,
    useDeleteTemplateMsgMutation,
    useGetTemplatesStatusQuery,
    useSetTemplatesStatusMutation,
} = templateApi;
