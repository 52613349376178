import { Button, Dialog as MuiDialog, TextField, Typography } from '@mui/material';
import styled from 'styled-components';

import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';

export const Dialog = styled(MuiDialog)`
    .MuiDialog-container {
        padding: 30px;
    }

    .MuiDialog-paper {
        box-shadow: 0 3px 14px 2px rgba(16, 24, 40, 0.08), 0 8px 10px 1px rgba(16, 24, 40, 0.06),
            0 5px 5px -3px rgba(16, 24, 40, 0.08);
        background: #fff;
        border-radius: 6px;
        padding: 16px;
        width: 400px;
        height: 538px;
    }
`;

export const Container = styled(FlexColumnWrapper)`
    height: 100%;
    width: 100%;
`;

export const Header = styled(FlexRowWrapper).attrs({
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
})`
    gap: 16px;
    border-bottom: 1px solid #d1d8fa;
    padding-bottom: 8px;
`;

export const TitleContainer = styled(FlexRowWrapper)`
    font-size: 14px;
    color: #0044b4;
    gap: 8px;
`;

export const Title = styled(Typography).attrs({ variant: 'h3' })`
    font-weight: 500;
    font-size: 16px;
    color: #0044b4;
    line-height: 16px;
`;

export const ResetButton = styled(Button)`
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #7890b2;
    border-radius: 6px;
    padding: 5px 10px;
    width: 160px;
    height: 30px;
`;

export const SearchTextField = styled(TextField)`
    .MuiOutlinedInput-root {
        background: #f6f7fb;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 8px;
        height: 40px;
        width: 100%;
    }

    fieldset {
        border: none;
    }

    input {
        padding-left: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.01em;
        color: #2b3648;
    }
`;

export const LoaderContainer = styled(FlexColumnWrapper)`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;
