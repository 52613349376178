import { RefObject, createRef, useEffect, useState } from 'react';

import { StyledTooltip } from '@/shared/components/StyledTooltip';

import { OverflowableTypography } from '../../pages/WorkManagment/components/components.styles';
import { IOverflowableTypographyWithTooltipProps } from './OverflowableTypographyWithTooltip.types';

export function OverflowableTypographyWithTooltip({
    TooltipProps,
    children,
    TypographyProps,
    maxRows,
    onOverflowCheck,
}: IOverflowableTypographyWithTooltipProps) {
    const ref = createRef() as RefObject<HTMLSpanElement | HTMLParagraphElement>;
    const [isOverflown, setIsOverflown] = useState<boolean>(false);
    const noTooltipConditions = [!children, children === 0, children === '-', children === '0'];

    function checkIsElementOverflown(element: HTMLSpanElement | HTMLParagraphElement | null) {
        return element
            ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
            : false;
    }

    useEffect(() => {
        ref.current &&
            setIsOverflown(() => {
                const isOverflow = checkIsElementOverflown(ref.current);
                onOverflowCheck && onOverflowCheck(isOverflow);
                return isOverflow;
            });
    }, [ref]);

    if (noTooltipConditions.includes(true)) {
        return (
            <OverflowableTypography
                ref={ref}
                {...TypographyProps}
                rows={maxRows}
            >
                {children}
            </OverflowableTypography>
        );
    }

    return (
        <StyledTooltip
            title={isOverflown ? (children ? children : '') : ''}
            disableInteractive
            {...TooltipProps}
        >
            <OverflowableTypography
                ref={ref}
                {...TypographyProps}
                rows={maxRows}
            >
                {children}
            </OverflowableTypography>
        </StyledTooltip>
    );
}
