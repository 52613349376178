import { useQuery } from '@tanstack/react-query';
import { IRowNode } from 'ag-grid-community';
import { FC, FormEvent, useRef, useState } from 'react';
import { useEventListener, useOnClickOutside } from 'usehooks-ts';

import {
    getStructureAddAndGroupPreviewFolderQueryOptions,
    getStructureInsertFolderPreviewQueryOptions,
} from '@/api/structure';

import { UIFolder } from '@/components/ui/UIFolder';

import { useLatest } from '@/hooks/useLatest';

import { Nullish } from '@/types/common.types';

import { TCreateSavePayload, TData, TEditSavePayload } from '../../StructureManagementTable.types';
import { generateRowId } from '../../utils';
import {
    Buttons,
    CancelButton,
    Container,
    Form,
    Input,
    Label,
    SaveButton,
    Text,
    Title,
    UIFolderContainerCss,
} from './FullWidthCellRenderer.styles';
import { canFolderContentVisible, getFolderContentMessage } from './OrdinalCellRenderer';
import { TFullCellRendererContentProps, TFullCellRendererModeProps, TFullCellRendererProps } from './TableCells.types';

const Content: FC<TFullCellRendererContentProps> = ({
    containerRef,
    level,
    title,
    value,
    folderContent,
    isDisabled,
    onChange,
    onFocus,
    onSubmit,
    onCancel,
}) => (
    <Container ref={containerRef}>
        <UIFolder
            level={level}
            hasChildren
            containerStyles={UIFolderContainerCss}
        />
        <Form onSubmit={onSubmit}>
            <Label>
                <Title>{title}</Title>
                <Input
                    type='text'
                    placeholder='Введите название уровня'
                    value={value}
                    onChange={onChange}
                    autoFocus
                    onFocus={onFocus}
                />
            </Label>

            {canFolderContentVisible(folderContent) && (
                <Text>
                    Сгруппировано: {getFolderContentMessage(folderContent!.folders, folderContent!.consolidated)}
                </Text>
            )}

            <Buttons>
                <SaveButton
                    type='submit'
                    disabled={isDisabled}
                >
                    Сохранить
                </SaveButton>
                <CancelButton
                    onClick={onCancel}
                    type='button'
                >
                    Отменить
                </CancelButton>
            </Buttons>
        </Form>
    </Container>
);

const Mode: FC<TFullCellRendererModeProps> = ({ title, initialData, ...props }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState<string>(props.data?.name ?? '');
    const [isDirty, setIsDirty] = useState(false);
    const isDisabled = value.trim().length === 0 || !isDirty;

    const latestValue = useLatest(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDirty(true);
        setValue(event.target.value);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit(latestValue.current);
    };

    const handleCancel = () => {
        props.onCancel(props.data?.entityId!);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') handleCancel();
    };

    useEventListener('keydown', handleKeyDown);
    useOnClickOutside(containerRef, handleCancel);

    return (
        <Content
            containerRef={containerRef}
            level={initialData.level}
            title={title}
            value={value}
            folderContent={initialData.folderContent}
            isDisabled={isDisabled}
            onChange={handleChange}
            onFocus={handleFocus}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    );
};

const Edit: FC<TFullCellRendererProps> = (props) => {
    const { onSubmit, ...rest } = props;
    const handleSubmit = (value: string) =>
        onSubmit('edit', { name: value, folderId: props.data?.entityId } as TEditSavePayload);

    return (
        <Mode
            title='Редактирование уровня'
            initialData={{
                level: props.data?.level ?? 1,
                folderContent: props.data?.folderContent ?? null,
            }}
            onSubmit={handleSubmit}
            {...rest}
        />
    );
};

const useCreateData = (
    projectId: number,
    referenceRow: Nullish<IRowNode<TData>>,
    folderRow: Nullish<number>,
    parentId: Nullish<number>
) => {
    const { data: addAndGroupPreviewData } = useQuery({
        ...getStructureAddAndGroupPreviewFolderQueryOptions({
            projectId: projectId,
            body: {
                folderRow: folderRow!,
                parentId: parentId!,
            },
        }),
        staleTime: 0,
        enabled: Boolean(referenceRow) && referenceRow?.data?.rowType === 'b_consolidated',
    });

    const { data: insertPreviewData } = useQuery({
        ...getStructureInsertFolderPreviewQueryOptions({
            projectId: projectId,
            body: {
                replacedFolderId: Number(referenceRow?.data?.entityId!),
            },
        }),
        staleTime: 0,
        enabled: Boolean(referenceRow) && referenceRow?.data?.rowType === 'a_folder',
    });

    if (referenceRow?.data?.rowType === 'a_folder') {
        return {
            level: insertPreviewData?.level,
            folderContent: insertPreviewData?.folderContent,
        };
    }

    return {
        level: addAndGroupPreviewData?.level,
        folderContent: addAndGroupPreviewData?.folderContent,
    };
};

const Create: FC<TFullCellRendererProps> = (props) => {
    const referenceRow = props.data?.referenceId ? props.api.getRowNode(props.data.referenceId) : null;
    const parentId = referenceRow?.data?.parentId ?? null;
    const parentNode = parentId
        ? props.api.getRowNode(generateRowId({ rowType: 'a_folder', entityId: parentId }))
        : null;

    const folderRow = referenceRow?.data?.folderRow;

    const level = parentNode?.data?.level ? parentNode.data.level + 1 : 1;
    const { onSubmit, ...rest } = props;

    const data = useCreateData(props.projectId, referenceRow, folderRow, parentId);

    const handleSubmit = (value: string) =>
        onSubmit('create', {
            name: value,
            folderRow,
            parentId: parentId,
            referenceId: props.data?.referenceId,
        } as TCreateSavePayload);

    return (
        <Mode
            title='Создание уровня'
            initialData={{
                level: data?.level ?? level,
                folderContent: data?.folderContent ?? null,
            }}
            onSubmit={handleSubmit}
            {...rest}
        />
    );
};

export const FullWidthCellRenderer: FC<TFullCellRendererProps> = (props) =>
    props.data?.modifyMode === 'edit' ? <Edit {...props} /> : <Create {...props} />;
